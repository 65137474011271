import { api } from "../../common/setupApi"
import { addAlert } from "../alerts/alertsSlice"
import { selectTeams } from "./selectors"

export function updateProfile(
    id, 
    firstName, 
    lastName, 
    email, 
    isTester,
    gender,
    status,
    phone,
    rsac
) {
    return async (dispatch, getState) => {
        dispatch({ type: "auth/update_profile_loading" })

        const payload = {
            id,
            first_name: firstName,
            last_name: lastName,
            email,
            is_tester: isTester,
            gender,
            status,
            phone,
            rsac
        }

        return api
            .patch("v1/profile/me/", payload)
            .then((res) => {
                //console.log(res.data)
                dispatch({ 
                    type: "auth/update_profile_success",
                    payload: res.data
                })
                dispatch(addAlert("Votre profil a bien été modifié", "success"))
            })
            .catch(err => {
                dispatch({ 
                    type: "auth/update_profile_failure",
                    payload: err.response
                })
                dispatch(addAlert(err.response, "error"))
            })
    }
}

export function updatePassword(oldPassword, newPassword) {
    return async (dispatch, getState) => {
        dispatch({ type: "auth/update_password_loading" })

        const payload = {
            old_password: oldPassword,
            new_password: newPassword
        }

        return api.patch("v1/profile/me/change-password/", payload)
    }
}

export function getOrgInfos() {
    return async (dispatch, getState) => {
        const state = getState()
        const fetched = state.account.org.fetched

        if (fetched) {
            return
        }

        dispatch({ type: "account/get_org_loading" })

        return api
            .get("v1/profile/org-infos/")
            .then(res => {
                //console.log(res.data)
                dispatch({ 
                    type: "account/get_org_success", 
                    payload: res.data 
                })
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/get_org_failure", 
                    payload: err.response?.data
                })
            })
    }
}

export function updateOrgInfos(
    orgName,
    tCard,
    email,
    address,
    phone,
    website,
    desc,
    color,
    org_statut,
    org_capital,
    org_rcs,
    org_siret,
    assurance_nom,
    assurance_adresse,
    garantie_maniement_fonds,
    garantie_organisme,
    garantie_montant_caution,
    frais_agence_absolu_minimum,
    org_cci,
    org_directeur_nom,
    org_directeur_prenom,
    org_directeur_email,
    org_mediateur_nom,
    org_mediateur_site,
    org_rgpd_email
) {
    return async (dispatch, getState) => {

        const payload = {
            org_name: orgName,
            t_card: tCard,
            org_email: email,
            org_address: address,
            org_phone: phone,
            org_website: website,
            org_description: desc,
            org_color: color,
            org_statut,
            org_capital,
            org_rcs,
            org_siret,
            assurance_nom,
            assurance_adresse,
            garantie_maniement_fonds,
            garantie_organisme,
            garantie_montant_caution,
            frais_agence_absolu_minimum,
            org_cci,
            org_directeur_nom,
            org_directeur_prenom,
            org_directeur_email,
            org_mediateur_nom,
            org_mediateur_site,
            org_rgpd_email
        }

        dispatch({ type: "account/update_org_loading" })

        return api
            .patch("v1/profile/org-infos/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({
                    type: "account/update_org_success",
                    payload: res.data
                })
                dispatch(addAlert("L'organisation a bien été modifiée", "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ type: "account/update_org_failure", payload: err.response })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function getCustomerPortal() {
    return async (dispatch, getState) => {

        function redirectTo(url) {
            let a = document.createElement('a')
            a.href = url
            document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click()
            a.remove() // afterwards we remove the element again
        }

        dispatch({ type: "account/get_customer_portal_loading" })

        return api
            .get("v1/stripe/customerportal/")
            .then(res => {
                console.log(res.data)
                const url = res.data.url

                dispatch({ type: "account/get_customer_portal_success" })

                redirectTo(url)                
            })
            .catch(err => {
                console.log(err.response)
                dispatch({
                    type: "account/get_customer_portal_failure",
                    payload: err.response
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function changeInvoicingDay(invoicingDay) {
    return async (dispatch, getState) => {
        dispatch({ type: "auth/set_invoicing_day_loading" })

        const payload = {
            invoicing_day: invoicingDay
        }

        return api
            .post("v1/stripe/invoicing-day/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ type: "auth/set_invoicing_day_success", payload: invoicingDay })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)

                dispatch({ 
                    type: "auth/set_invoicing_day_failure", 
                    payload: err.response 
                })

                const message = err.response?.data || "Une erreur est survenue, veuillez réessayer."

                dispatch(addAlert(message, "error"))
            })
    }
}

export function cancelSub() {
    return async (dispatch, getState) => {
        dispatch({ type: "account/cancel_sub_loading" })

        const payload = {
            cancel_sub: true
        }

        return api
            .post("v1/stripe/cancel-sub/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ type: "account/cancel_sub_success" })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ type: "account/cancel_sub_failure", payload: err.response })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function getTeams() {
    return async (dispatch, getState) => {
        const fetched = selectTeams(getState()).fetched

        if (fetched) {
            return
        }
        
        dispatch({ type: "account/get_teams_loading" })

        return api
            .get("v1/teams-management/teams-list/")
            .then(res => {
                //console.log(res.data)
                dispatch({
                    type: "account/get_teams_success",
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: "account/get_teams_failure",
                    payload: err.response?.data
                })
            })
    }
}

export function addTeam(name) {
    return async (dispatch, getState) => {
        dispatch({ type: "account/add_team_loading" })

        const payload = {
            name
        }

        return api
            .post("v1/teams-management/add-team/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/add_team_success",
                    payload: {
                        id: res.data.id,
                        name
                    }
                })
                dispatch(addAlert(`L'agence ${name} a bien été ajoutée !`, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/add_team_failure",
                    payload: err.response
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function deleteTeam(teamId, transferTeamId, users) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/delete_team_loading",
            payload: {
                teamId
            }
        })

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/delete_team_success",
        //         payload: {
        //             teamId,
        //             transferTeamId,
        //             users
        //         }
        //     })
        // }, [1000])

        const payload = {
            "team_id": teamId,
            "transfer_profiles_team_id": transferTeamId
        }

        return api
            .post("v1/teams-management/delete-team/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/delete_team_success",
                    payload: {
                        teamId,
                        transferTeamId,
                        users
                    }
                })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/delete_team_failure",
                    payload: {
                        teamId,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function renameTeam(teamId, newTeamName) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/rename_team_loading",
            payload: {
                teamId
            }
        })

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/rename_team_failure",  
        //         payload: {
        //             teamId,
        //             error: "err.response"
        //         }
        //     })
        // }, [7000])

        const payload = {
            team_id: teamId,
            new_name: newTeamName
        }

        return api
            .post("v1/teams-management/rename-team/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/rename_team_success",  
                    payload: { teamId, newTeamName }
                })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/rename_team_failure",  
                    payload: {
                        teamId,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function sendEmails(teamId, emails) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/send_emails_loading",
            payload: {
                teamId
            }
        })

        const payload = {
            emails: emails.map(d => d.value)
        }

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/send_emails_failure",
        //         payload: {
        //             teamId,
        //             error: "err.response"
        //         }
        //     })
        // }, [7000])

        return api
            .post(`v1/registration/send-email-link/${teamId}/`, payload)
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/send_emails_success",
                    payload: {
                        teamId
                    }
                })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/send_emails_failure",  
                    payload: {
                        teamId,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function getRegisterLink(teamId) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/get_register_link_loading",
            payload: {
                teamId
            }
        })

        // return setTimeout(() => {
        //     dispatch({
        //         type: "account/get_register_link_failure",
        //         payload: {
        //             teamId,
        //             error: "err.response"
        //         }
        //     })
        // }, [7000])

        return api
            .get(`v1/registration/link/${teamId}/`)
            .then(res => {
                console.log(res.data)
                dispatch({
                    type: "account/get_register_link_success",
                    payload: { 
                        teamId, 
                        link: res.data 
                    }
                })
                dispatch(addAlert("Lien généré", "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({
                    type: "account/get_register_link_failure",
                    payload: {
                        teamId,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function invalidateLink(teamId) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/invalidate_link_loading",
            payload: {
                teamId
            }
        })

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/invalidate_link_failure",
        //         payload: {
        //             teamId,
        //             error: "err.response"
        //         }
        //     })
        // }, [7000])

        return api
            .get("v1/registration/invalidate-link/")
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/invalidate_link_success",
                    payload: { 
                        teamId 
                    }
                })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/invalidate_link_failure",
                    payload: {
                        teamId,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
        }
}

export function activateUser(userId, teamId) {
    return async (dispatch, getState) => {
        dispatch({
            type: "account/activate_user_loading",
            payload: {
                userId,
                teamId
            }
        })

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/activate_user_failure",
        //         payload: {
        //             userId,
        //             teamId
        //         }
        //     })
        // }, [7000])

        const payload = {
            user_id: userId
        }

        return api
            .post("v1/teams-management/activate-account/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/activate_user_success",
                    payload: {
                        userId,
                        teamId
                    }
                })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({
                    type: "account/activate_user_failure",
                    payload: {
                        userId,
                        teamId,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function changeTeamUser(user, newTeamId, oldTeamId) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/change_team_loading",
            payload: {
                oldTeamId,
                userId: user.id
            }
        })

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/change_team_failure",
        //         payload: {
        //             oldTeamId,
        //             userId: user.id,
        //             error: "err.response"
        //         }
        //     })
        // }, [7000])

        const payload = {
            user_id: user.id,
            new_team_id: newTeamId
        }

        return api
            .post("v1/teams-management/change-teams/", payload)
            .then(res => {
                console.log(res.data)
                dispatch(addAlert(res.data, "success"))
                dispatch({ 
                    type: "account/change_team_success/remove",
                    payload: {
                        oldTeamId,
                        userId: user.id
                    }
                })
                dispatch({ 
                    type: "account/change_team_success/add",
                    payload: {
                        newTeamId,
                        user
                    }
                })
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/change_team_failure",
                    payload: {
                        oldTeamId,
                        userId: user.id,
                        error: err.response
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}

export function deleteUser(userId, teamId) {
    return async (dispatch, getState) => {
        dispatch({ 
            type: "account/delete_user_loading",
            payload: {
                userId,
                teamId
            }
        })

        // return setTimeout(() => {
        //     dispatch({ 
        //         type: "account/delete_user_failure",
        //         payload: {
        //             userId,
        //             teamId
        //         }
        //     })
        // }, [2000])

        const payload = {
            user_id: userId
        }

        return api
            .post("v1/teams-management/delete-account/", payload)
            .then(res => {
                console.log(res.data)
                dispatch({ 
                    type: "account/delete_user_success",
                    payload: {
                        userId,
                        teamId
                    }
                })
                dispatch(addAlert(res.data, "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "account/delete_user_failure", 
                    payload: {
                        userId,
                        teamId,
                        error: err.response 
                    }
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }
}