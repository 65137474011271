import { Dialog, DialogActions, DialogContent, SwipeableDrawer, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, useEffect, useState } from "react"
import { scssVar } from "../../app/scssVar"
import { appName, faviconUrl } from "../../common/utils"
import Button from "../../common/components/Button"
import Sab from "../../common/components/Sab"

const initialState = {
    open: false,
    deferredPrompt: null
}

const InstallPrompt = () => {
    const [state, setState] = useState(initialState)

    useEffect(() => {
        function askForInstall(event) {
            event.preventDefault()

            setState(s => ({
                ...s,
                deferredPrompt: event,
                open: !localStorage.getItem("A2HS_preference")
            }))

            return
        }

        window.addEventListener('beforeinstallprompt', askForInstall)

        return () => {
            window.removeEventListener('beforeinstallprompt', askForInstall)
        }
    }, [])

    const handleClose = () => {
        setState({ ...state, open: false })
        localStorage.setItem("A2HS_preference", "User dismissed the A2HS prompt")
    }

    const handleInstall = e => {
        state.deferredPrompt.prompt()

        state.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
                localStorage.setItem("A2HS_preference", "User accepted the A2HS prompt")
            } else {
                localStorage.setItem("A2HS_preference", "User dismissed the A2HS prompt")
            }

            setState({ ...state, open: false, deferredPrompt: null })
        })
    }

    return (
        <Fragment>
            <Dialog 
                open={state.open}
                onClose={handleClose}
                sx={{
                    display: { xs: "none", sm: "block" }
                }}
            >
                <DialogContent>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 2
                    }}>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: { xs: 18, sm: 24 },
                            fontFamily: scssVar.fontFamily,
                        }}>
                            {appName}
                        </Typography>
                        <img 
                            src={faviconUrl} 
                            alt="icône"
                            style={{
                                width: 50,
                                height: 50,
                            }}
                        />
                    </Box>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black"
                    }}>
                        Installez {appName} en 5 secondes. Ajoutez une icône sur l'écran d'accueil pour accéder facilement à l'application.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                    >
                        Fermer
                    </Button>
                    <Button onClick={handleInstall}>
                        Installer
                    </Button>
                </DialogActions>
            </Dialog>
            <SwipeableDrawer
                open={state.open}
                anchor="bottom"
                onClose={handleClose}
                onOpen={() => {}}
                disableSwipeToOpen
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiPaper-root": {
                        backgroundColor: "white",
                        backgroundImage: "none",
                        width: "99vw",
                        maxWidth: 1000,
                        margin: "0 auto",
                        zIndex: 1301,
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                    }
                }}
            >
                <div 
                    role="presentation" 
                    style={{
                        width: "100%",
                    }}
                >
                    <Box sx={{
                        p: "20px"
                    }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2
                        }}>
                            <Typography sx={{
                                fontWeight: 700,
                                fontSize: { xs: 18, sm: 24 },
                                fontFamily: scssVar.fontFamily,
                            }}>
                                {appName}
                            </Typography>
                            <img 
                                src={faviconUrl} 
                                alt="icône"
                                style={{
                                    width: 50,
                                    height: 50,
                                }}
                            />
                        </Box>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black"
                        }}>
                            Installez {appName} en 5 secondes. Ajoutez une icône sur l'écran d'accueil pour accéder facilement à l'application.
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Button
                                variant="light"
                                onClick={handleInstall}
                                sx={{
                                    mt: "20px",
                                    mb: "20px",
                                    width: 150
                                }}
                            >
                                Installer
                            </Button>
                            <Button
                                onClick={handleClose}
                                sx={{
                                    width: 150,
                                    mb: "10px",
                                }}
                            >
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                    <Sab />
                </div>
            </SwipeableDrawer>
        </Fragment>
    )
}

export default InstallPrompt