import { useSelector } from "react-redux"
import { selectEstimation } from "../../../estimate/selectors"
import { Fragment } from "react"
import Margin from "../../components/Margin"
import Type from "./Type"
import Exclusivity from "./Exclusivity"
import GreyBox from "../../components/GreyBox"
import Mandataire from "./Mandataire"
import { numberWithSpaces } from "../../../../common/utils"
import NumberMandat from "./NumberMandat"
import MandataireName from "./MandataireName"
import RSAC from "./RSAC"

const HeaderPage01 = () => {
    const fraisPer = useSelector(selectEstimation).frais_agence_pourcent
    const fraisAbs = useSelector(selectEstimation).frais_agence_absolu

    return (
        <Fragment>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div style={{
                    width: "39%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <div>
                        <Margin m={50} />
                        <Type />
                    </div>
                    <div>
                        <p style={{ fontWeight: 600, fontSize: 13 }}>
                            Type de mandat et honoraires
                        </p>
                        <Exclusivity />
                        <Margin m={3} />
                        <div style={{
                            color: "black",
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <GreyBox style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0 5px",
                                height: 30,
                                fontSize: 17
                            }}>
                                {fraisPer} %
                            </GreyBox>
                            <p style={{ marginLeft: 5 }}>
                                TTC
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{
                    width: "59%"
                }}>
                    <Mandataire />
                </div>
            </div>

            <Margin m={5} />
            
            <div style={{ 
                display: "flex",
                alignItems: "center"
            }}>
                <p style={{
                    fontWeight: 600,
                    marginRight: 3,
                    fontSize: 14,
                    whiteSpace: "nowrap"
                }}>
                    ou Forfait : 
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 100,
                    marginRight: 3,
                    whiteSpace: "nowrap"
                }}>
                    {numberWithSpaces(fraisAbs)} €
                </GreyBox>
                <p style={{
                    fontWeight: 600,
                    marginRight: 3,
                    fontSize: 14,
                    whiteSpace: "nowrap"
                }}>
                    N° de MANDAT : 
                </p>
                <NumberMandat />
                <MandataireName />
                <RSAC />
            </div>
        </Fragment>
    )
}

export default HeaderPage01