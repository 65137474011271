import { useSelector } from "react-redux";
import { numberWithSpaces } from "../../../../common/utils";
import HousingName from "./HousingName"
import { Box, CircularProgress, Typography } from "@mui/material";
import { selectValues } from "../../selectors";
import { scssVar } from "../../../../app/scssVar";
import LeafletMap from "../../../map/LeafletMap";

const ResultCard = () => {
    const address = useSelector(state => state.estimate.housing.adresse)
    const value = useSelector(selectValues).estimationValue
    const working = useSelector(state => state.estimate.working)
    const saving = useSelector(state => state.estimate.saving)

    return (
        <Box sx={{
            boxShadow: scssVar.shadow2,
            WebkitBoxShadow: scssVar.shadow2,
            bgcolor: "white",
            p: "12px",
            borderRadius: "8px",
            mb: "20px",
            width: "100%",
            maxWidth: { 
                xs: "100%", 
                sm: "100%", 
                md: "890px", 
            },
            display: "flex",
            alignItems: "center",
            "@media (max-width: 750px)": {
                flexDirection: "column",
                "& > p": {
                    textAlign: "center"
                }
            }
        }}>
            <Box sx={{
                width: "184px",
                height: "210px",
                mr: 4,
                "@media (max-width: 750px)": {
                    width: "100%",
                    height: "159px",
                    flexGrow: 1,
                    mr: 0,
                    mb: 3
                }
            }}>
                <LeafletMap height="100%" />
            </Box>
            <Box sx={{ 
                flexGrow: 1,
                "@media (max-width: 750px)": {
                    width: "100%",
                    "& > p": {
                        textAlign: "center"
                    }
                }
            }}>
                <HousingName />
                <p style={{
                    marginTop: 10,
                    marginBottom: 20,
                    color: "black"
                }}>
                    {address}
                </p>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    "@media (max-width: 750px)": {
                        flexDirection: "column"
                    }
                }}>
                    <Box sx={{
                        backgroundColor: scssVar.greyLighter,
                        height: 87,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "33%",
                        mr: "10px",
                        "@media (max-width: 750px)": {
                            mr: 0,
                            mb: "10px",
                            width: "100%"
                        }
                    }}>
                        <p style={{ color: "black", fontSize: 14 }}>
                            Basse
                        </p>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            fontSize: 18,
                            fontWeight: 600,
                            color: "#A6A6A6"
                        }}>
                            {working && !saving ? (
                                <CircularProgress 
                                    size={15} 
                                    sx={{
                                        color: scssVar.primary
                                    }}
                                />
                            ):(
                                numberWithSpaces(value - (value * 7 / 100)) + "€"
                            )}
                        </Typography>
                    </Box>
                    <Box sx={{
                        backgroundColor: scssVar.greyLighter,
                        height: 87,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "33%",
                        mr: "10px",
                        "@media (max-width: 750px)": {
                            mr: 0,
                            mb: "10px",
                            width: "100%"
                        }
                    }}>
                        <p style={{ color: "black", fontSize: 14 }}>
                            Médiane
                        </p>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            fontSize: { xs: 27, sm: 18, md: 26, lg: 30 },
                            fontWeight: 700,
                            color: scssVar.primary,
                            whiteSpace: "nowrap",
                        }}>
                            {working && !saving ? (
                                <CircularProgress 
                                    size={15} 
                                    sx={{
                                        color: scssVar.primary
                                    }}
                                />
                            ):(
                                numberWithSpaces(value) + "€"
                            )}
                        </Typography>
                    </Box>
                    <Box sx={{
                        backgroundColor: scssVar.greyLighter,
                        height: 87,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "33%",
                        "@media (max-width: 750px)": {
                            width: "100%"
                        }
                    }}>
                        <p style={{ color: "black", fontSize: 14 }}>
                            Haute
                        </p>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            fontSize: 18,
                            fontWeight: 600,
                            color: "#A6A6A6"
                        }}>
                            {working && !saving ? (
                                <CircularProgress 
                                    size={15} 
                                    sx={{
                                        color: scssVar.primary
                                    }}
                                />
                            ):(
                                numberWithSpaces(value + (value * 7 / 100)) + "€"
                            )}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ResultCard