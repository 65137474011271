import { useSelector } from "react-redux"
import { selectOrg } from "../../../account/selectors"

const SectionTitle = ({ children, style }) => {
    const userColor = useSelector(selectOrg).color

    return (
        <p style={Object.assign({}, {
            background: userColor,
            color: "white",
            fontWeight: 600,
            width: "fit-content",
            padding: "0px 4px",
            fontSize: 14,
            lineHeight: "16px",
            height: 17,
        }, style)}>
            {children}
        </p>
    )
}

export default SectionTitle