import { Box, Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import BetaVersionAlertText from "../../components/BetaVersionAlertText"
import ButtonReset from "../../components/ButtonReset"
import DownloadIconButton from "../../components/DownloadIconButton"
import LoadingMask from "../../components/LoadingMask"
import MissingFieldAlertText from "../../../../common/components/MissingFieldAlertText"
import { selectMandatEditor2 } from "../../mandat/mandatSlice"
import { buyOfferActions } from "./buyOfferSlice"
import Page01 from "./pages/Page01"

const OfferPdf = () => {
    const dispatch = useDispatch()
    const numberMandat = useSelector(selectMandatEditor2).mandat_id
    const houseType = useSelector(selectMandatEditor2).type_bien_detail
    const [loading, setLoading] = useState(false)
    const [openReset, setOpenReset] = useState(false)
    const [openMissingValues, setOpenMissingValues] = useState(false)
    
    const emptyNumberMandat = numberMandat === "" || numberMandat === null || numberMandat === undefined
    const emptyHouseType = houseType === "" || houseType === null || houseType === undefined
    const scale = loading 
        ? 1.0 
        : 1.0
        // : 0.665 // --> Lecteur PDF Google : 77%

    const handleDownload = () => {
        if (emptyHouseType || emptyNumberMandat) {
            setOpenMissingValues(true)
            return
        }

        const pdf = new jsPDF("p", "mm", "a4", true)

        const generatePage = async (html, addPage) => {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true // Pour la map leaflet
            })
                .then(canvas => {
                    const img = canvas.toDataURL("img/png", 1.0)
                    const imgWidth = 210
                    const imgHeight = canvas.height * imgWidth / canvas.width + 3
        
                    pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST")
        
                    if (addPage) {
                        pdf.addPage()
                    }
                })
        }

        setLoading(true)

        setTimeout(async () => {
            const page = document.getElementById("buy_p1")
    
            await generatePage(page, false)

            setLoading(false)
    
            return pdf.save("offre_d_achat")
        }, [1500])
    }

    return (
        <Fragment>
            <LoadingMask loading={loading} />

            <Box sx={{ width: "fit-content", margin: "0 auto" }}>
                <BetaVersionAlertText />

                <Box sx={{ m: 4 }} />

                <MissingFieldAlertText visible={emptyHouseType} sx={{ mb: 4 }}>
                    Veuillez renseigner la <u>nature du bien</u> dans l'onglet "Mandat"
                </MissingFieldAlertText>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <DownloadIconButton disabled={loading} onClick={handleDownload} />
                    <p>Offre d'achat</p>
                </Box>

                <ButtonReset onClick={() => setOpenReset(true)} />
                <Page01 scale={scale} />
            </Box>

            <Dialog open={openReset} onClose={() => setOpenReset(false)} fullWidth>
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Effacer la saisie en cours ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="light"
                        sx={{ width: 150 }}
                        onClick={() => setOpenReset(false)} 
                    >
                        Fermer
                    </Button>
                    <Button
                        variant="danger"
                        sx={{ width: 150 }}
                        onClick={() => {
                            dispatch(buyOfferActions.reset())
                            setOpenReset(false)
                        }}
                    >
                        Effacer
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={openMissingValues} onClose={() => setOpenMissingValues(false)} fullWidth>
                <DialogContent sx={{ pb: 0 }}>
                    <MissingFieldAlertText visible={emptyNumberMandat}>
                        Veuillez renseigner le <u>numéro de mandat</u> dans l'onglet "Mandat"
                    </MissingFieldAlertText>
                    <MissingFieldAlertText visible={emptyHouseType}>
                        Veuillez renseigner la <u>nature du bien</u> dans l'onglet "Mandat"
                    </MissingFieldAlertText>
                </DialogContent>
                <DialogActions>
                    <Button variant="light" onClick={() => setOpenMissingValues(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default OfferPdf