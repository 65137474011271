import { Box, Grid, SwipeableDrawer, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import Logo from "../../../common/components/Logo"
import { getDateToString } from "../../../common/utils"
import BottomBar from "../../pdfReader/components/BottomBar"
import Link from "../../pdfReader/components/Link"
import Reader from "../../pdfReader/components/Reader"
import Title from "../../pdfReader/components/Title"
import Zoom from "../../pdfReader/components/Zoom"
import usePdfDoc from "../../pdfReader/hooks/usePdfDoc"
import usePdfState from "../../pdfReader/hooks/usePdfState"
import { registerOrgActions, selectRegisterOrgForm } from "../registerOrgSlice"

const OneMonthFree = ({ xs, sm, order }) => {
    const Message = () => (
        <Box sx={{
            height: 33,
            bgcolor: scssVar.yellow,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Typography sx={{
                color: scssVar.primary,
                fontFamily: scssVar.fontFamily,
                fontSize: { xs: 14, sm: 18 },
                fontWeight: 700,
                textAlign: "center",
                letterSpacing: { xs: "0.5px", sm: "1px" },
                whiteSpace: "nowrap"
            }}>
                1 mois gratuit sur toutes nos offres
            </Typography>
        </Box>
    ) 

    if (sm) {
        return (
            <Grid 
                item xs={12} 
                order={order}
                sx={{
                    display: { xs: "none", sm: "block", lg: "none" }
                }}
            >
                <Message />
            </Grid>
        )
    }

    if (xs) {
        return (
            <Grid 
                item xs={12} 
                order={order}
                sx={{
                    display: { xs: "block", sm: "none" }
                }}
            >
                <Message />
            </Grid>
        )
    }

    return (
        <Grid 
            item lg={12} 
            order={4}
            sx={{
                display: { xs: "none", lg: "block" }
            }}
        >
            <Message />
        </Grid>
    )
}

const PlanName = ({ children }) => (
    <Typography sx={{
        color: scssVar.primary,
        fontFamily: scssVar.fontFamily,
        fontSize: 22,
        fontWeight: 700,
        textAlign: "center",
        lineHeight: "23px"
    }}>
        {children}
    </Typography>
)

const PlanSubName = ({ children }) => (
    <Typography sx={{
        fontFamily: scssVar.fontFamily,
        color: scssVar.primary,
        fontSize: 18,
        lineHeight: "21px",
        textAlign: "center",
    }}>
        {children}
    </Typography>
)

const Price = ({ price, unit }) => (
    <Box sx={{ display: "flex", mt: 2 }}>
        <Typography sx={{
            fontFamily: scssVar.fontFamily,
            color: "black",
            whiteSpace: "nowrap",
            fontSize: 25,
            fontWeight: 700
        }}>
            {price} €
        </Typography>
        <Typography sx={{
            fontFamily: scssVar.fontFamily,
            color: "black",
            fontSize: 13,
            fontWeight: 700,
            ml: 1,
            mt: 1
        }}>
            {unit}
        </Typography>
    </Box>
)

const InfoPrice = () => (
    <Typography sx={{
        fontFamily: scssVar.fontFamily,
        color: scssVar.primary,
        //whiteSpace: "nowrap",
        fontSize: { xs: 12.5, md: 14 },
        width: "fit-content"
    }}>
        <i>Tarif mensuel au terme si souscription</i>
    </Typography>
)

const Detail = ({ children }) => (
    <Box sx={{ display: "flex", mt: .25 }}>
        <i
            className="iconoir-check"
            style={{
                color: "black",
                marginRight: 5,
                fontSize: 32
            }}
        />
        <Typography sx={{
            fontFamily: scssVar.fontFamily,
            color: "black",
            fontSize: { xs: 12.5, md: 14 },
            mt: .8,
            whiteSpace: "nowrap"
        }}>
            {children}
        </Typography>
    </Box>
)

const SubButton = ({ priceId }) => {
    const dispatch = useDispatch()
    const selectedPriceId = useSelector(selectRegisterOrgForm).price_id
    const isSelected = priceId === selectedPriceId

    const handleSub = () => {
        const formEl = document.getElementById("register_org_form")
        formEl.scrollIntoView({ behavior: "smooth" })

        dispatch(registerOrgActions.setField({ name: "price_id", value: priceId }))
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            mb: 1.5
        }}>
            <Button
                onClick={handleSub}
                type="button"
                variant={isSelected ? "success" : "primary"}
            >
                {isSelected ? "Sélectionnée" : "Essai gratuit"}
                {isSelected && (
                    <i
                        className="iconoir-check"
                        style={{
                            color: "white",
                            marginLeft: 5,
                            fontSize: 24,
                        }}
                    />
                )}
            </Button>
        </Box>
    )
}

function getOneMonthAfter() {
    const today = new Date()
    const res = new Date(today.setMonth(today.getMonth() + 1))

    return getDateToString(res)
}

const ExpirationDate = () => {
    return (
        <Typography sx={{
            fontFamily: scssVar.fontFamily,
            color: "black",
            fontSize: 10,
            textAlign: "center",
            mb: 3
        }}>
            Offre gratuite expire le {getOneMonthAfter()}
        </Typography>
    )
}

const Conditions = ({ onClick }) => {
    return (
        <Typography sx={{
            fontFamily: scssVar.fontFamily,
            color: "black",
            fontSize: 10,
            mb: 4
        }}>
            Offre d'un mois gratuit non valable pour les
            <br/>utilisateurs qui ont déjà essayé Estimia. 
            <br/>
            <Button
                type="button"
                variant="text"
                onClick={onClick}
                sx={{
                    color: "black",
                    fontSize: 10,
                    textDecoration: "underline",
                }}
            >
                Soumise aux Conditions générales.
            </Button>
        </Typography>
    )
}

const plans = {
    agentcommercial: "price_1L050CAHflHlYLXuVVNpQ3X2",
    agence: "price_1L8A4VAHflHlYLXurHorjA7f",
    multiagences: "price_1L0Cq7AHflHlYLXu0aafUXEP",
    reseau: "price_1L0CxcAHflHlYLXujxQKRmWv"
}

const Plans = () => {
    const dispatch = useDispatch()
    const firstPdf = usePdfState()
    const secondPdf = usePdfState()
    const getPdfDoc = usePdfDoc()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const [openTerms, setOpenTerms] = useState(false)
    const offre = searchParams.get("offre")

    const handleOpenTerms = () => {
        setOpenTerms(true)
    }

    const handleCloseTerms = () => {
        setOpenTerms(false)
    }

    useEffect(() => {
        if (plans[offre]) {
            const formEl = document.getElementById("register_org_form")
            formEl.scrollIntoView({ behavior: "smooth" })

            dispatch(registerOrgActions.setField({ name: "price_id", value: plans[offre] }))
        }
    }, [offre, dispatch])

    return (
        <Fragment>
            <Box sx={{
                width: 246,
                fontWeight: 700,
                fontSize: 22.91,
                bgcolor: scssVar.greyDark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 31,
                color: "white",
            }}>
                App Web & Mobile
            </Box>
            <Grid container spacing={0} sx={{
                border: 1,
                borderColor: scssVar.greyDark
            }}>
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 0, sm: 0, lg: 0 }} 
                    sx={{
                        borderRight: { 
                            xs: 0, 
                            sm: "1px solid " + scssVar.greyDark
                        },
                        height: 97,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 1,
                        bgcolor: scssVar.greyLighter
                    }}
                >
                    <PlanName>
                        Agent commercial
                    </PlanName>
                </Grid>
                <OneMonthFree xs order={1} />
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 3, sm: 1, lg: 1 }} 
                    sx={{
                        borderRight: { 
                            xs: 0, 
                            lg: "1px solid " + scssVar.greyDark
                        },
                        borderTop: { 
                            xs: "1px solid " + scssVar.greyDark,
                            sm: 0 
                        },
                        borderColor: scssVar.greyDark,
                        height: 97,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 1,
                        bgcolor: scssVar.greyLighter
                    }}
                >
                    <PlanName>
                        Agence
                    </PlanName>
                    <PlanSubName>
                        1 à 5 PV (Points de Vente)
                    </PlanSubName>
                </Grid>
                <OneMonthFree xs order={4} />
                <OneMonthFree sm order={2} />
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 6, sm: 5, lg: 2 }} 
                    sx={{
                        borderTop: { 
                            xs: "1px solid " + scssVar.greyDark,
                            lg: 0 
                        },
                        borderRight: { 
                            xs: 0, 
                            sm: "1px solid " + scssVar.greyDark
                        },
                        borderColor: scssVar.greyDark,
                        height: 97,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 1,
                        bgcolor: scssVar.greyLighter
                    }}
                >
                    <PlanName>
                        Multi Agences
                    </PlanName>
                    <PlanSubName>
                        6 à 50 PV (Points de Vente)
                    </PlanSubName>
                </Grid>
                <OneMonthFree xs order={7} />
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 9, sm: 6, lg: 3 }}
                    sx={{
                        borderTop: { 
                            xs: "1px solid " + scssVar.greyDark,
                            lg: 0 
                        },
                        height: 97,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 1,
                        bgcolor: scssVar.greyLighter
                    }}
                >
                    <PlanName>
                        Réseau 
                        <br />de mandataires
                    </PlanName>
                </Grid>
                <OneMonthFree xs order={10} />
                <OneMonthFree sm order={7} />
                <OneMonthFree />
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 2, sm: 3, lg: 4 }} 
                    sx={{
                        borderRight: { 
                            xs: 0,
                            sm: "1px solid " + scssVar.greyDark,
                        },
                        borderColor: scssVar.greyDark,
                    }}
                >
                    <Box sx={{ width: "fit-content", margin: "0 auto", p: "0 4px" }}>
                        <Price price={60} unit="HT/mois" />
                        <InfoPrice />
                        <Box sx={{ m: 3 }} />
                        <Detail>
                            Évaluations illimitées
                        </Detail>
                        <Detail>
                            1 utilisateur
                        </Detail>
                        <Box sx={{ m: 5 }} />
                        <SubButton priceId={plans["agentcommercial"]} />
                        <ExpirationDate />
                        <Conditions onClick={handleOpenTerms} />
                    </Box>
                </Grid>
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 5, sm: 4, lg: 5 }} 
                    sx={{
                        borderRight: { 
                            xs: 0,
                            lg: "1px solid " + scssVar.greyDark,
                        },
                        borderColor: scssVar.greyDark
                    }}
                >
                    <Box sx={{ width: "fit-content", margin: "0 auto", p: "0 4px" }}>
                        <Price price={250} unit="HT/mois/PV" />
                        <InfoPrice />
                        <Box sx={{ m: 3 }} />
                        <Detail>
                            Évaluations illimitées
                        </Detail>
                        <Detail>
                            5 users par PV + 
                            <br />35 € HT/user supp/mois
                        </Detail>
                        <Box sx={{ m: 3 }} />
                        <SubButton priceId={plans["agence"]} />
                        <ExpirationDate />
                        <Conditions onClick={handleOpenTerms} />
                    </Box>
                </Grid>
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 8, sm: 8, lg: 6 }} 
                    sx={{
                        borderRight: { 
                            xs: 0,
                            sm: "1px solid " + scssVar.greyDark,
                        },
                        borderColor: scssVar.greyDark
                    }}
                >
                    <Box sx={{ width: "fit-content", margin: "0 auto", p: "0 4px" }}>
                        <Price price={300} unit="HT/mois/PV" />
                        <InfoPrice />
                        <Box sx={{ m: 3 }} />
                        <Detail>
                            Évaluations illimitées
                        </Detail>
                        <Detail>
                            8 users par PV + 
                            <br />32 € HT/user supp/mois
                        </Detail>
                        <Box sx={{ m: 3 }} />
                        <SubButton priceId={plans["multiagences"]} />
                        <ExpirationDate />
                        <Conditions onClick={handleOpenTerms} />
                    </Box>
                </Grid>
                <Grid 
                    item xs={12} sm={6} lg={3} 
                    order={{ xs: 11, sm: 9, lg: 7 }}
                >
                    <Box sx={{ width: "fit-content", margin: "0 auto", p: "0 4px" }}>
                        <Price price={350} unit="HT/mois" />
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            mt: -.5,
                            fontSize: { xs: 11.85, md: 13 },
                            whiteSpace: "nowrap",
                            mb: .5
                        }}>
                            pour la tête de réseau (forfait initial)
                        </Typography>
                        <InfoPrice />
                        <Box sx={{ m: "5px" }} />
                        <Detail>
                            Évaluations illimitées
                        </Detail>
                        <Detail>
                            10 mandataires par Réseau + 
                            <br />30 € HT/mois/mandataire
                        </Detail>
                        <Box sx={{ m: 3 }} />
                        <SubButton priceId={plans["reseau"]} />
                        <ExpirationDate />
                        <Conditions onClick={handleOpenTerms} />
                    </Box>
                </Grid>
            </Grid>
            <SwipeableDrawer
                open={openTerms}
                anchor="bottom"
                onClose={handleCloseTerms}
                onOpen={() => {}}
                disableSwipeToOpen
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: "white",
                        backgroundImage: "none",
                        width: "100%",
                        zIndex: 1301,
                    }
                }}
            >
                <BottomBar>
                    <Button
                        variant="light"
                        type="button"
                        onClick={handleCloseTerms}
                        sx={{
                            width: 150,
                        }}
                    >
                        Fermer
                    </Button>
                </BottomBar>
                <Zoom
                    onZoomIn={firstPdf.handleZoomIn}
                    onZoomOut={firstPdf.handleZoomOut}
                    scale={firstPdf.pdfState.scale}
                    loadingDoc={firstPdf.pdfState.loadingDoc || secondPdf.pdfState.loadingDoc}
                    loadingPage={firstPdf.pdfState.loadingPage || secondPdf.pdfState.loadingPage}
                />
                <div
                    role="presentation" 
                    style={{
                        height: window.innerHeight,
                        overflowY: "scroll",
                        width: "100%",
                    }}
                >
                    <Box sx={{ 
                        pt: { xs: 3, sm: 8 }, 
                        pb: 21
                    }}>
                        <Box sx={{
                            maxWidth: "1341px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px",
                                sm: "0 35px",
                                lg: "0 60px"
                            }
                        }}>
                            <Logo sx={{ wrapper: { mb: 3 } }} />
                            <Title>{getPdfDoc("cgu").title}</Title>
                            <Link href={getPdfDoc("cgu").src} />
                        </Box>
                        <Box sx={{ m: 1 }} />
                        <Reader
                            onDocumentLoadSuccess={firstPdf.handleDocumentLoadSuccess}
                            onPageLoadSuccess={firstPdf.handlePageLoadSuccess}
                            src={getPdfDoc("cgu").src}
                            loadingPage={firstPdf.pdfState.loadingPage}
                            numberOfPages={firstPdf.pdfState.numPages}
                            scale={firstPdf.pdfState.scale}
                        />
                        <Box sx={{ m: 3 }} />
                        <Box sx={{
                            maxWidth: "1341px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px",
                                sm: "0 35px",
                                lg: "0 60px"
                            }
                        }}>
                            <Title>{getPdfDoc("cgv").title}</Title>
                            <Link href={getPdfDoc("cgv").src} />
                        </Box>
                        <Box sx={{ m: 1 }} />
                        <Reader
                            onDocumentLoadSuccess={secondPdf.handleDocumentLoadSuccess}
                            onPageLoadSuccess={secondPdf.handlePageLoadSuccess}
                            src={getPdfDoc("cgv").src}
                            loadingPage={secondPdf.pdfState.loadingPage}
                            numberOfPages={secondPdf.pdfState.numPages}
                            scale={firstPdf.pdfState.scale}
                        />
                    </Box>
                </div>
            </SwipeableDrawer>
        </Fragment>
    )
}

export default Plans