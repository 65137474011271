const BLOCKS = [
    { value: "A", colors: { gse: "#CBF4FF", dpe: "#11A16B" } },
    { value: "B", colors: { gse: "#94E0F5", dpe: "#51B15F" } },
    { value: "C", colors: { gse: "#2ABAE3", dpe: "#44C275" } },
    { value: "D", colors: { gse: "#1096BD", dpe: "#FCE940" } },
    { value: "E", colors: { gse: "#452774", dpe: "#E9A642" } },
    { value: "F", colors: { gse: "#003D4F", dpe: "#F88757" } },
    { value: "G", colors: { gse: "#002029", dpe: "#FC3858" } },
]

const DpeGseGradient = ({ palette, value }) => {
    if (value === "NC" || value === "nc" || !value) {
        return null
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            marginTop: 5
        }}>
            {BLOCKS.map((d, i) => {
                const selected = value ? (d.value.toUpperCase() === value.toUpperCase()) : false
                
                return (
                    <span 
                        key={i}
                        style={{
                            height: selected ? 25 : 10,
                            borderTopLeftRadius: i === 0 || selected ? 3 : 0,
                            borderBottomLeftRadius: i === 0 || selected ? 3 : 0,
                            borderTopRightRadius: i === BLOCKS.length - 1 || selected ? 3 : 0,
                            borderBottomRightRadius: i === BLOCKS.length - 1 || selected ? 3 : 0,
                            lineHeight: "20px",
                            marginLeft: 3,
                            width: `calc(${90/BLOCKS.length}%)`,
                            backgroundColor: d.colors[palette],
                            color: "white",
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {selected && (
                            <span>
                                {d.value.toUpperCase()}
                            </span> 
                        )}
                    </span>
                )
            })}
        </div>
    )
}

export default DpeGseGradient