import { Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../app/scssVar"
import DesktopNav from "../../common/components/nav/DesktopNav"
import MobileNav from "../../common/components/nav/MobileNav"
import PageWrapper from "../../common/components/PageWrapper"
import { appCompany, appName, appVersion } from "../../common/utils"
import Legal from "./sections/Legal"
import Organization from "./sections/Organization"
import Password from "./sections/Password"
import Profile from "./sections/Profile"
import Sub from "./sections/Sub"
import SubError from "./sections/SubError"
import Teams from "./sections/Teams"
import Update from "./sections/Update"

const Account = () => {
    const isAdmin = useSelector(state => state.auth.user.isAdmin)
    return (
        <PageWrapper>
            <MobileNav />
            <DesktopNav />
            <Box sx={{ m: { xs: 0, sm: 2 } }} />
            <Box sx={{
                width: "100%",
                maxWidth: "885px",
                margin: "0 auto",
            }}>
                <SubError />
                <Profile />
                <hr />
                <Password />
                <hr />
                <Update />
                <hr />
                {isAdmin && (
                    <Fragment>
                        <Organization />
                        <hr />
                        <Sub />
                        <hr />
                        <Teams />
                        <hr />
                    </Fragment>
                )}
                <Legal />
                <Typography sx={{
                    mt: 7,
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDark,
                    fontSize: 14,
                    textAlign: "center"
                }}>
                    {appName} v{appVersion}
                </Typography>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDark,
                    fontSize: 14,
                    textAlign: "center"
                }}>
                    © {appCompany}
                </Typography>
            </Box>
        </PageWrapper>
    )
}

export default Account