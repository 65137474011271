import { Box, CircularProgress, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import UsageCost from "./UsageCost"
import { numberWithSpaces } from "../../../../common/utils"
import { selectAddress, selectValues } from "../../selectors"
import Button from "../../../../common/components/Button"
import { scssVar } from "../../../../app/scssVar"
import LeafletMap from "../../../map/LeafletMap"
// import { useEffect, useRef, useState } from "react"

const RightResult = ({ 
    canCompute, 
    showUseCost, 
    onOpenShapley 
}) => {
    const name = useSelector(state => state.estimate.housing.name)
    const working = useSelector(state => state.estimate.working)
    const saving = useSelector(state => state.estimate.saving)
    const value = useSelector(selectValues).estimationValue
    const currentStep = useSelector(state => state.estimate.estimation.current_step)
    const { rue, commune, codePostal } = useSelector(selectAddress)

    // let interval = useRef(null)
    // const prevValue = useSelector(state => state.estimate.estimation.prevValue)
    // const [currentValue, setCurrentValue] = useState(0)
    
    // useEffect(() => {
    //     const updated = () => {
    //         if (!start || start === value) {
    //             clearInterval(interval.current)
    //             setCurrentValue(value)
    //         } else {
    //             start = prevValue < value ? (start + 100) : (start - 100)
    //             setCurrentValue(start)
    //         }
    //     }

    //     interval.current = setInterval(updated)
    //     let start = prevValue
    // }, [value, prevValue])

    return (
        <Box sx={{ width: "100%" }}>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "33.6px",
                color: "black",
                mb: "40px"
            }}>
                Évaluation en cours
            </Typography>
            <Box sx={{
                padding: "4px 8px",
                width: "fit-content",
                backgroundColor: scssVar.greyLighter,
                color: scssVar.primary,
                fontSize: "14px",
                borderRadius: "100px",
                mb: "20px"
            }}>
                Prix estimé
            </Box>
            <Box>
                {working && !saving ? (
                    <Box sx={{ 
                        height: currentStep === "Step1" ? "125px" : "100px",
                        transition: "height 0.2s ease"
                    }}>
                        <CircularProgress 
                            size={30} 
                            sx={{ 
                                color: scssVar.primary 
                            }} 
                        />
                    </Box>
                ):( canCompute && value > 0.01 ? (
                    <Box sx={{ 
                        height: currentStep === "Step1" ? "125px" : "100px",
                        transition: "height 0.2s ease"
                    }}>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            fontSize: "36px",
                            fontWeight: 700,
                            color: scssVar.primary,
                            lineHeight: "30px",
                        }}>
                            {numberWithSpaces(value)}€
                        </Typography>
                        {currentStep === "Step1" && (
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                fontSize: "14px",
                                color: "black",
                            }}>
                                Base référentiel DVF
                            </Typography>
                        )}
                        <Button
                            onClick={onOpenShapley}
                            variant="light"
                            sx={{
                                mt: 1,
                                width: "100%",
                                borderRadius: "6px",
                            }}
                        >
                            Voir le détail du prix
                        </Button>
                    </Box>
                ):(
                    <Box sx={{
                        mb: "25px",
                        border: 1,
                        borderColor: scssVar.danger,
                        color: scssVar.danger,
                        padding: "10px",
                        borderRadius: "18px",
                        display: "flex",
                    }}>
                        <Typography sx={{ 
                            fontFamily: scssVar.fontFamily,
                            fontSize: "14px",
                            flexGrow: 1
                        }}>
                            Renseignez la surface habitable et le nombre de pièces afin de lancer le calcul de l'estimation.
                        </Typography>
                    </Box>
                ))}
            </Box>

            {showUseCost && canCompute && (
                <Box sx={{ mb: "30px" }}>
                    <UsageCost />
                </Box>
            )}

            <LeafletMap height={174} />

            <Box sx={{ m: 2 }} />

            <Box sx={{
                display: "flex",
                alignItems: "center"
            }}>
                <i className="iconoir-pin-alt" style={{ marginRight: 3, color: "black" }}></i>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: "14px",
                    color: "black",
                    fontWeight: 500
                }}>
                    {name}
                </Typography>
            </Box>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                fontSize: "14px",
                color: "black",
            }}>
                {rue},
                <br/>
                {codePostal} {commune}
            </Typography>
        </Box>
    )
}

export default RightResult