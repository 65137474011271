import { useSelector } from "react-redux"
import useAuth from "../../../../auth/hooks/useAuth"
import Empty from "../../../components/Empty"
import Footer from "../../../components/Footer"
import GreyBox from "../../../components/GreyBox"
import Margin from "../../../components/Margin"
import OrgLogo from "../../../components/OrgLogo"
import Wrapper from "../../../components/Wrapper"
import { selectMandatEditor2 } from "../../../mandat/mandatSlice"
import Buyers from "../components/Buyers"
import Conditions from "../components/Conditions"
import Engagement from "../components/Engagement"
import Offer from "../components/Offer"
import OfferType from "../components/OfferType"

const Page01 = ({ scale }) => {
    const { user } = useAuth()
    const numberMandat = useSelector(selectMandatEditor2).mandat_id

    return (
        <Wrapper id="buy_p1" scale={scale}>
            <OrgLogo wrapperStyle={{ margin: "0 auto", width: 400 }} />

            <Margin m={15} />

            <OfferType />

            <Margin m={21} />

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: 3, fontWeight: 600, fontSize: 16 }}>Numéro de mandat :</p>
                    {numberMandat !== null ? (
                        <GreyBox style={{ padding: "0 4px", fontSize: 16, display: "flex", alignItems: "center" }}>{numberMandat}</GreyBox>
                    ):(
                        <Empty>N° mandat</Empty>
                    )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: 3, fontWeight: 600, fontSize: 16 }}>Négociateur / E.I. :</p>
                    <GreyBox style={{ padding: "0 4px", fontSize: 16, display: "flex", alignItems: "center" }}>
                        {user.lastName} {user.firstName}
                    </GreyBox>
                </div>
            </div>

            <Margin m={21} />

            <Buyers />

            <Margin m={5} />

            <Offer />

            <Margin m={2} />

            <Conditions />

            <Margin m={2} />

            <Engagement />
            
            <Footer />
        </Wrapper>
    )
}

export default Page01