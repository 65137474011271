import { Dialog, DialogActions, DialogTitle } from "@mui/material"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"

const DialogDelete = ({
    open,
    onClose,
    loading,
    onDelete
}) => {
    return (
        <Dialog
            open={open} 
            onClose={onClose} 
            fullWidth
        >
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
                display: "flex",
                alignItems: "center",
            }}>
                Voulez-vous supprimer cette photo ?
            </DialogTitle>
            <DialogActions>
                <Button
                    variant="light"
                    onClick={onClose}
                    sx={{
                        width: 150
                    }}
                >
                    Annuler
                </Button>
                <Button
                    loading={loading}
                    disabled={loading}
                    onClick={onDelete}
                    sx={{
                        width: 150
                    }}
                >
                    Supprimer
                    <i
                        className="iconoir-trash"
                        style={{
                            fontSize: 24,
                            marginLeft: 5,
                        }}
                    ></i>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogDelete