import { Typography } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const ResultTitle = ({ title }) => (
    <Typography sx={{
        fontFamily: scssVar.fontFamily,
        color: "black",
        textAlign: "center",
        fontSize: "48px",
        fontWeight: 700,
        lineHeight: "60px",
        "@media (max-width: 800px)": {
            fontSize: "40px",
            lineHeight: "42px"
        },
        "@media (max-width: 600px)": {
            fontSize: "34px",
            lineHeight: "38px"
        }
    }}>
        {title}
    </Typography>
)

export default ResultTitle