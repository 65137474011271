import { Box, Grid, Typography } from "@mui/material"
import OrganizationForm from "./components/OrganizationForm"
import Plans from "./components/Plans"
import WrapperRegisterForm from "./components/WrapperRegisterForm"
import illustration from "../../common/assets/Group39.png"
import { scssVar } from "../../app/scssVar"
import { useDispatch, useSelector } from "react-redux"
import Discover from "./components/Discover"
import { registerOrgActions, selectRegisterOrgErrFields } from "./registerOrgSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../auth/hooks/useAuth"
import SectionTitle from "./components/SectionTitle"

const RegisterOrganization = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const noPriceIdError = useSelector(selectRegisterOrgErrFields).priceId
    const registered = useSelector(state => state.registerOrg.freeTrial.registered)
    const { isAuth } = useAuth()

    useEffect(() => {
        if (registered && isAuth) {
            navigate("/", { replace: true })
        }
    }, [registered, isAuth, navigate])

    useEffect(() => {
        return () => {
            dispatch(registerOrgActions.reset())
        }
    }, [dispatch])

    return (
        <WrapperRegisterForm>
            <Box sx={{ m: 3 }} />
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: "black",
                textAlign: "center",
                fontSize: { xs: 25, sm: 36 },
                fontWeight: 700
            }}>
                Simplicité, confiance, performance
            </Typography>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: "black",
                textAlign: "center",
                fontSize: { xs: 22, sm: 25 },
            }}>
                Essayez-nous
                <span style={{ 
                    fontWeight: 700, 
                    backgroundColor: scssVar.yellow,
                    margin: "0 4px"
                }}>
                    {" "}gratuitement{" "}
                </span>
                pendant 1 mois (inscription sans CB)
            </Typography>
            <Box sx={{ m: 4 }} />
            <SectionTitle
                n={1} 
                id="register_org_sub"
                isDanger={noPriceIdError.length}
            >
                <span style={{ fontWeight: 300 }}>Choisissez votre offre de test</span>
                <span style={{ fontWeight: 700 }}>{" "}sans engagement :</span>
            </SectionTitle>
            <Box sx={{ m: 3 }} />
            <Plans />
            <Box sx={{ m: 5.5 }} />
            <Box sx={{
                width: "100%",
                margin: "0 auto",
            }}>
                <SectionTitle n={2} id="register_org_form">
                    <span style={{ fontWeight: 700 }}>
                        Inscrivez-vous :
                    </span>
                </SectionTitle>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7} lg={7}>
                        <OrganizationForm />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} sx={{
                        display: { xs: "none", md: "flex" },
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}>
                        <img
                            src={illustration}
                            alt="register illustration"
                            style={{
                                width: "90%",
                                maxWidth: "420px"
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ m: 8 }} />
            <Discover />
        </WrapperRegisterForm>
    )
}

export default RegisterOrganization