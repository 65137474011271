import { useSelector } from "react-redux"

const useEnvEtServicesItems = () => {
    const estimation = useSelector(state => state.estimate.estimation)

    return [
        { label: "Orientation (pièce principale)", value: estimation.orientation, displayed: true, nd: "Non définie" },
        { label: "Environnement sonore", value: estimation.environnement_sonore, displayed: true, nd: "Non défini" },
        { label: "Installation numérique", value: estimation.installation_numerique, displayed: true, nd: "Non définie" },
        { label: "Qualité numérique", value: estimation.qualite_numerique, displayed: estimation.installation_numerique !== "Sans internet", nd: "Non définie" },
        { label: "Attrait général : bien et quartier", value: estimation.attrait, displayed: true, nd: "Non défini" },
    ]
}

export default useEnvEtServicesItems