import { scssVar } from '../../../../../app/scssVar';
import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TooltipStyled from '../../../../../common/components/TooltipStyled';
import { useSelector } from 'react-redux';
import { ReactComponent as PdfExportIcon } from '../../../../../common/assets/pdf-export.svg';
import { selectHousing, selectIsWorking } from '../../../selectors';
import { Fragment, useState } from 'react';
import Button from '../../../../../common/components/Button';
import MissingFieldAlertText from '../../../../../common/components/MissingFieldAlertText';

const Export = () => {
    const navigate = useNavigate()
    const working = useSelector(selectIsWorking)
    const loadingEstimations = useSelector(state => state.estimate.estimations.loading)
    const sellerLastName = useSelector(selectHousing).nom_vendeur
    const sellerFirstName = useSelector(selectHousing).prenom_vendeur
    const [open, setOpen] = useState(false)
    const disabled = working || loadingEstimations

    const handleGoToPreview = () => {
        if (!sellerFirstName && !sellerLastName) {
            setOpen(true)
            return
        }

        navigate("/estimer/synthese-pdf")
    }

    return (
        <Fragment>
            <TooltipStyled arrow title="Exporter vers PDF">
                <span style={{ marginRight: 16 }}>
                    <IconButton
                        onClick={handleGoToPreview}
                        disabled={disabled}
                        sx={{
                            bgcolor: scssVar.greyLighter,
                            color: scssVar.primary,
                            opacity: disabled ? .5 : 1,
                            "&:hover": {
                                bgcolor: scssVar.greyLight,
                            }
                        }}
                    >
                        <PdfExportIcon />
                    </IconButton>
                </span>
            </TooltipStyled>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogContent>
                    <MissingFieldAlertText visible>
                        Veuillez renseigner l'<u>identité du vendeur</u> avant d'exporter l'estimation en PDF
                    </MissingFieldAlertText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Export