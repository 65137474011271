import { useEffect } from "react"
import { useDispatch } from "react-redux"
import useAuth from "../auth/hooks/useAuth"
import useHistory from "../history/hooks/useHistory"
import { trackOnRouteChanged } from "./actions"
import { isInIframe } from "../../common/utils"

const Tracker = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(trackOnRouteChanged())
    }, [history, dispatch])

    return null
}

const NavTracker = () => {
    const { isAuth } = useAuth()
    const history = useHistory()
    
    const isActive = 
        // disabled si ouvert dans un iframe
        !isInIframe
            // L'user doit être connecté
            && isAuth 
            // Eviter de track le login
            // L'user peut se connecter si session pas expirée en arrivant sur "/", dans ce cas là: from = "/" et to = "/"
            && history.from !== history.to 
            // Eviter de track le login
            && history.from !== "/login"
            // Eviter de track le login
            && history.to !== "/login"

    return isActive ? <Tracker /> : null
}

export default NavTracker