import { useSelector } from "react-redux"
import { selectMandatSaving } from "../mandat/mandatSlice"
import useJssInput from "../hooks/useJssInput"
import { selectTracfinSaving } from "../buy/tracfin/tracfinSlice"

const InputText = ({
    value,
    onChange,
    name,
    style,
    placeholder,
    required,
    disabled
}) => {
    const jss = useJssInput()
    const savingMandat = useSelector(selectMandatSaving)
    const savingTracin = useSelector(selectTracfinSaving)

    return (
        <input 
            required={required}
            type="text"
            value={value}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            autoComplete="off"
            disabled={savingMandat || savingTracin || disabled}
            style={Object.assign({}, jss, style)}
        />
    )
}

export default InputText