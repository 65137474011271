import { api } from "../../common/setupApi"
import { setLocalParam } from "../../common/utils"
import { addAlert } from "../alerts/alertsSlice"
import { trackOnLogin, trackOnLoginSessionNotExpired, trackOnLogout } from "../userTracker/actions"
import { selectAuth } from "./selectors"

export const loginAdminAsUser = (sesame) => {
    return async (dispatch) => {
        return api
            .get(`v1/core/login-admin?sesame=${sesame}`)
            .then(async (authRes) => {
                dispatch({ type: "auth/get_user_infos_loading" })
                
                const config = {
                    headers: {
                        Authorization: `Bearer ${authRes.data.access}`
                    }
                }

                return api
                    .get("v1/profile/me/", config)
                    .then(userRes => {
                        //console.log(userRes.data)

                        dispatch({
                            type: "auth/get_user_infos_success",
                            payload: userRes.data
                        })

                        dispatch(trackOnLogin("/"))

                        dispatch({ 
                            type: "auth/login_success", 
                            payload: authRes.data 
                        })

                        setLocalParam("accessToken", authRes.data.access)
                        setLocalParam("refreshToken", authRes.data.refresh)
                    })
                    .catch(err => {
                        dispatch({ 
                            type: "auth/get_user_infos_failure",
                            payload: err.response?.data
                        })

                        dispatch({ type: "auth/login_failure", payload: "err" })
                        dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                    })
            })
            .catch(err => {
                try {
                    const errorMessage = err.response.data.detail

                    dispatch({ type: "auth/login_failure", payload: errorMessage })
                    dispatch(addAlert(errorMessage, "error"))

                } catch (unknownErrorException) {
                    const errorMessage = "Nos services sont en maintenance, veuillez réessayer ultérieurement."

                    dispatch({ type: "auth/login_failure", payload: errorMessage })
                    dispatch(addAlert(errorMessage, "error"))
                }
            })
    }
}

export function loginUser(email, password, to) {
    return async dispatch => {
        dispatch({ type: "auth/login_loading" })

        return api
            .post("auth/token/", { username: email, password })
            .then(async (authRes) => {
                dispatch({ type: "auth/get_user_infos_loading" })
                
                const config = {
                    headers: {
                        Authorization: `Bearer ${authRes.data.access}`
                    }
                }

                return api
                    .get("v1/profile/me/", config)
                    .then(userRes => {
                        //console.log(userRes.data)

                        dispatch({
                            type: "auth/get_user_infos_success",
                            payload: userRes.data
                        })

                        dispatch(trackOnLogin(to))

                        dispatch({ 
                            type: "auth/login_success", 
                            payload: authRes.data 
                        })

                        setLocalParam("accessToken", authRes.data.access)
                        setLocalParam("refreshToken", authRes.data.refresh)
                    })
                    .catch(err => {
                        dispatch({ 
                            type: "auth/get_user_infos_failure",
                            payload: err.response?.data
                        })

                        dispatch({ type: "auth/login_failure", payload: "err" })
                        dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                    })
            })
            .catch(err => {
                try {
                    const errorMessage = err.response.data.detail

                    dispatch({ type: "auth/login_failure", payload: errorMessage })
                    dispatch(addAlert(errorMessage, "error"))

                } catch (unknownErrorException) {
                    const errorMessage = "Nos services sont en maintenance, veuillez réessayer ultérieurement."

                    dispatch({ type: "auth/login_failure", payload: errorMessage })
                    dispatch(addAlert(errorMessage, "error"))
                }
            })
    }
}

export function getUserInfos() {
    return async (dispatch, getState) => {
        const state = getState()
        const fetched = state.auth.user.fetched

        if (fetched) {
            return
        }

        dispatch({ type: "auth/get_user_infos_loading" })

        return api
            .get("v1/profile/me/")
            .then(res => {
                dispatch({ 
                    type: "auth/get_user_infos_success",
                    payload: res.data
                })

                dispatch(trackOnLoginSessionNotExpired())
            })
            .catch(err => {
                // Ne pas déconnecter si error sub 
                console.log(err.response)
                
                dispatch({ 
                    type: "auth/get_user_infos_failure",
                    payload: err.response?.data
                })

                setLocalParam("accessToken", "")
                setLocalParam("refreshToken", "")

                dispatch({ type: "auth/logout" })

                // en cas d'ouverture de l'app alors que les tokens sont dans le localStorage,
                // si les tokens ont expirés, ça fera une erreur 401, 
                // MAIS on ne veut pas afficher une alerte, on veut juste déconnecter sans alerter
                if (err.response?.status !== 401) {
                    dispatch(addAlert(
                        "Une erreur est survenue lors de la récupération de votre profil.", 
                        "error"
                    ))
                }
            })
    }
}

export function refreshUserToken() {
    return async (dispatch, getState) => {
        const refreshToken = selectAuth(getState()).tokens.refresh

        return api
            .post("auth/token/refresh/", { refresh: refreshToken })
            .then(res => {
                setLocalParam("accessToken", res.data.access)
                setLocalParam("refreshToken", res.data.refresh)

                dispatch({
                    type: "auth/refresh_token_success",
                    payload: res.data
                })

                //if (isDev) dispatch(addAlert("token has been refreshed", "success"))
            })
            .catch(err => {
                console.log(err.response)
                // Si le token refresh a expiré
                // l'interceptor déconnectera l'user
            })
    }
}

export function logoutUser() {
    return async (dispatch) => {
        dispatch({ type: "auth/logout_loading" })

        // obligé de mettre le tracking là car ils ne sont pas authorized si pas de token
        dispatch(trackOnLogout())

        return api
            .post("v1/profile/logout/")
            .then(() => {
                // console.log("user logged out")
                // attention user non authentifié ! -> err 401

                setLocalParam("accessToken", "")
                setLocalParam("refreshToken", "")

                dispatch({ type: "auth/logout" })
            })
            .catch(err => {
                console.log(err.response)
            })
            // .finally => attention user non authentifié ! -> err 401
    }
}

export function logoutExpiredUserSession() {
    return dispatch => {
        setLocalParam("accessToken", "")
        setLocalParam("refreshToken", "")

        dispatch({ type: "auth/logout" })
    }
}