import { getBackendDateFormat, getDateToString } from "../../../../common/utils"
import { useDispatch, useSelector } from "react-redux"
import { getApiKpis, selectApiKpisPeriod } from "../../apiOfferSlice"
import { scssVar } from "../../../../app/scssVar"
import { useEffect, useState } from "react"
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Button from "../../../../common/components/Button"
import { Grid } from "@mui/material"
import Datepicker from "../../../../common/components/datepicker/Datepicker"

const Period = () => {
    const dispatch = useDispatch()
    const { start, end } = useSelector(selectApiKpisPeriod)
    const [open, setOpen] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        if (!open && start && end) {
            setStartDate(new Date(start))
            setEndDate(new Date(end))
        }
    }, [open, start, end])

    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
    }

    const handleClick = () => {
        setOpen((prev) => !prev)
    }
  
    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = () => {
        dispatch(getApiKpis({ 
            ignoreCondition: true, 
            start: getBackendDateFormat(startDate), 
            end: getBackendDateFormat(endDate) 
        }))
        .then(() => setOpen(false))
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ position: 'relative' }}>
                <Button 
                    type="button" 
                    variant="primary-light"
                    onClick={handleClick}
                    sx={{
                        borderRadius: "6px",
                        border: "1px solid " + scssVar.primary,
                        width: "100%",
                        justifyContent: "space-between",
                        pl: 1,
                        pr: 1,
                        fontSize: 14,
                        height: 32
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <i className="iconoir-calendar" style={{ fontSize: 19, marginRight: 7 }} />
                        {start && end && (
                            `${getDateToString(start)} - ${getDateToString(end)}`
                        )}
                    </Box>
                    <i 
                        className="iconoir-nav-arrow-down" 
                        style={{ 
                            fontSize: 19,
                            transform: `rotate(${open ? 180 : 0}deg)`,
                            transition: "transform .2s"
                        }} 
                    />
                </Button>
                {open ? (
                    <Box sx={{
                        position: 'absolute',
                        top: 28,
                        right: 0,
                        left: 0,
                        marginLeft: "auto",
                        zIndex: 1,
                        borderRadius: "10px",
                        bgcolor: scssVar.greyLighter,
                        boxShadow: scssVar.shadow2,
                        width: "fit-content"
                    }}>
                        <Box sx={{ pt: 2, pr: 4, pl: 4 }}>
                            <Datepicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </Box>
                        <Grid container spacing={0} sx={{
                            fontSize: 13,
                            bgcolor: scssVar.primaryLight,
                            borderTop: "1px solid " + scssVar.grey,
                            borderBottom: "1px solid " + scssVar.grey,
                        }}>
                            <Grid item xs={6} sx={{ 
                                textAlign: "center", 
                                fontWeight: 600,
                                borderBottom: "1px solid " + scssVar.grey,
                                borderRight: "1px solid " + scssVar.grey,
                            }}>
                                Début
                            </Grid>
                            <Grid item xs={6} sx={{ 
                                textAlign: "center", 
                                fontWeight: 600,
                                borderBottom: "1px solid " + scssVar.grey,
                            }}>
                                Fin
                            </Grid>
                            <Grid item xs={6} sx={{
                                textAlign: "center",
                                borderRight: "1px solid " + scssVar.grey,
                                p: "1px 0"
                            }}>
                                {startDate ? getDateToString(startDate) : ""}
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "center", p: "1px 0" }}>
                                {endDate ? getDateToString(endDate) : ""}
                            </Grid>
                        </Grid>
                        <Button 
                            variant="text"
                            type="button" 
                            onClick={handleSubmit}
                            disabled={!endDate || !startDate}
                            noShadow
                            sx={{
                                width: "100%",
                                height: 40,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                fontWeight: 600
                            }}
                        >
                            Envoyer
                        </Button>
                    </Box>
                ) : null}
            </Box>
        </ClickAwayListener>
    )
}

export default Period