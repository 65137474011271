import { AddRounded } from "@mui/icons-material"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useMediaQuery } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import Checkbox from "../../../../common/components/Checkbox"
import FieldControl from "../../../../common/components/FieldControl"
import { api } from "../../../../common/setupApi"
import { selectOrg } from "../../../account/selectors"
import { addAlert } from "../../../alerts/alertsSlice"
import { apiOfferActions, selectApiLegalDocs } from "../../apiOfferSlice"

const initialValues = { name: "", url: "" }

const LegalDocs = () => {
    const dispatch = useDispatch()
    const legalDocs = useSelector(selectApiLegalDocs)
    const orgName = useSelector(selectOrg).name
    const isSmallScreen = useMediaQuery("(max-width: 599px)")
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState(null)
    const [selected, setSelected] = useState({})
    const [values, setValues] = useState(initialValues)

    const handleClose = () => {
        setMode(null)
        setSelected({})
        setValues(initialValues)
    }

    const handleSubmit = async () => {
        let payload

        if (mode === "add") {
            payload = { api_legal_docs: [...legalDocs, values] }
        } 
        
        if (mode === "edit") {
            payload = { api_legal_docs: legalDocs.map(d => d.name === selected.name ? values : d)}
        }

        if (mode === "delete") {
            payload = { api_legal_docs: legalDocs.filter(d => d.name !== selected.name) }
        }

        setLoading(true)

        return api
            .patch("v1/api/infos/", payload)
            .then(res => {
                dispatch(apiOfferActions.setDocs(res.data.api_legal_docs))
                if (mode === "add") {
                    dispatch(addAlert("Le document a bien été ajouté", "success"))
                } 
                if (mode === "edit") {
                    dispatch(addAlert("Le document a bien été modifié", "success"))
                }
                if (mode === "delete") {
                    dispatch(addAlert("Le document a bien été supprimé", "success"))
                }
                setMode(null)
                setSelected({})
                setValues(initialValues)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                const error = err.response?.data?.api_legal_docs || []
                if (error.length) {
                    error.forEach(d => dispatch(addAlert(d, "error")))
                } else {
                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                }
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <p style={{ fontWeight: 800, marginBottom: 15 }}>
                Documents légaux & RGPD
            </p>
            <p style={{ color: scssVar.greyDarker, marginBottom: 15 }}>
                Renseignez les documents légaux que le lead devra lire et accepter afin d'utiliser l'API.
                Avec ces réglages, vous pouvez générer des cases à cocher comme : "J'accepte les conditions générales de {orgName}".
                <br />Cette étape est facultative mais recommandée.
            </p>
            {legalDocs.length ? (
                <Fragment>
                    <List sx={{ bgcolor: scssVar.greyLighter, borderRadius: "12px" }}>
                        {legalDocs.map((d, i) => 
                            <ListItem 
                                key={i} 
                                sx={{ 
                                    borderBottom: i < legalDocs.length - 1 ? `1px solid ${scssVar.greyLight}` : "none",
                                    pr: "100px"
                                }}
                            >
                                <ListItemText primary={d.name} secondary={d.url} sx={{
                                    ".MuiTypography-root": {
                                        fontFamily: scssVar.fontFamily
                                    },
                                    ".MuiListItemText-secondary": {
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                    }
                                }} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => {
                                        setMode("edit")
                                        setSelected(d)
                                        setValues(d)
                                    }}>
                                        <i
                                            className="iconoir-edit-pencil edit-icon"
                                            style={{ 
                                                color: scssVar.primary
                                            }}
                                        ></i>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setMode("delete")
                                        setSelected(d)
                                        setValues(d)
                                    }}>
                                        <i
                                            className="iconoir-trash"
                                            style={{ 
                                                color: scssVar.primary
                                            }}
                                        ></i>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>

                    <Button onClick={() => setMode("add")} sx={{ ml: "auto", mt: 2 }}>
                        Ajouter un document
                        <AddRounded sx={{ ml: 1 }} />
                    </Button>
                </Fragment>
            ):(
                <Fragment>
                    <Box sx={{ p: "10px 20px", bgcolor: scssVar.greyLighter, borderRadius: "8px", mb: "20px" }}>
                        <p style={{ fontWeight: 500 }}>
                            Vous n'avez ajouté aucun document légal.
                        </p>
                    </Box>
                    <Button onClick={() => setMode("add")} sx={{ ml: "auto" }}>
                        Ajouter un document
                        <AddRounded sx={{ ml: 1 }} />
                    </Button>
                </Fragment>
            )}
            <Dialog
                open={mode !== null} 
                onClose={handleClose}
                fullScreen={(mode === "add" || mode === "edit") && isSmallScreen}
                fullWidth
                transitionDuration={0}
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}>
                    {mode === "add" && "Ajouter un document"}
                    {mode === "edit" && `Modifier ${selected.name}`}
                    {mode === "delete" && `Supprimer ${selected.name} ?`}
                </DialogTitle>
                {(mode === "add" || mode === "edit") && (
                    <DialogContent>
                        <FieldControl
                            type="text"
                            label="Nom du document"
                            placeholder={`Exemple: les Conditions Générales de ${orgName}`}
                            value={values?.name || ""}
                            onChange={e => setValues({ ...values, name: e.target.value })}
                        />
                        <Box sx={{ m: 3 }} />
                        <p style={{ fontSize: 14, marginBottom: 1 }}>URL du document</p>
                        <p style={{ color: scssVar.greyDarker, fontSize: 11, lineHeight: "15px", marginBottom: 10 }}>
                            Exemples de formats autorisés :
                            <br/><span style={{ marginLeft: 15 }}>- https://mon-site-web.com/cgu</span>
                            <br/><span style={{ marginLeft: 15 }}>- https://www.mon-site-web.fr/cgv</span>
                        </p>
                        <FieldControl
                            type="text"
                            placeholder="Exemple: https://mon-site-web.com/cgu"
                            value={values?.url || ""}
                            onChange={e => setValues({ ...values, url: e.target.value })}
                        />
                        <Box sx={{ 
                            mt: 2, 
                            fontSize: 14, 
                            opacity: values?.name ? 1 : 0, 
                            transition: "opacity .2s",
                            p: 1,
                            bgcolor: scssVar.greyLighter,
                            borderRadius: "8px"
                        }}>
                            <p style={{ fontWeight: 500 }}>
                                Cette case à cocher sera ajoutée dans l'API :
                            </p>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Checkbox checked />
                                <p>
                                    J'accepte{" "}
                                    <span style={{ textDecoration: "underline", color: scssVar.primary }}>
                                        {values?.name}
                                    </span>
                                </p>
                            </Box>
                        </Box>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button sx={{ width: 150 }} variant="light" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button
                        variant={mode === "delete" ? "danger" : "primary"}
                        sx={{ width: 150 }} 
                        onClick={handleSubmit} 
                        loading={loading}
                        disabled={loading || ((mode === "add" || mode === "edit") && (!values?.name?.trim()?.length || !values?.url?.trim()?.length))} 
                    >
                        {(mode === "add" || mode === "edit") && "Enregistrer"}
                        {mode === "delete" && "Supprimer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default LegalDocs