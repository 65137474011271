import { Fragment, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateEstimation } from "../../actions"
import { Box, Grid } from "@mui/material"
import StepTitle from "./StepTitle"
import FieldControl from "../../../../common/components/FieldControl"
import { selectHousingEstimations } from "../../selectors"

export const choices_nb_chambres = [
    { label: "Inconnu", value: null },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4 et plus", value: "4 et plus" },
]

const choices_surface_piece_de_vie = [
    { label: "Inconnue", value: null },
    { label: "8 à 15", value: "8 à 15" },
    { label: "16 à 20", value: "16 à 20" },
    { label: "21 à 30", value: "21 à 30" },
    { label: "31 et plus", value: "31 et plus" },
]

const Step01Form = ({ stepName, noProgressBarDisplay }) => {
    const dispatch = useDispatch()
    const housing = useSelector(state => state.estimate.housing)
    const working = useSelector(state => state.estimate.working)
    const estimation = useSelector(state => state.estimate.estimation)
    const estimationsOfHousing = useSelector(selectHousingEstimations).data
    const loadingEstimationsOfHousing = useSelector(selectHousingEstimations).isLoading
    const timeout = useRef(null)

    const onUpdateEstimation = (delay) => {
        timeout.current = setTimeout(() => {
            dispatch(updateEstimation(stepName))
        }, [delay])
    }

    const handleChangeHousingValue = e => {
        const { type, name, value } = e.target

        clearTimeout(timeout.current)

        dispatch({
            type: "estimate/set_housing_value",
            payload: { name, value }
        })
            
        onUpdateEstimation(type === "select-one" ? 0 : 1000)
    }

    const handleChangeEstimationValue = e => {
        const { type, name, value } = e.target

        clearTimeout(timeout.current)

        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        onUpdateEstimation(type === "select-one" ? 0 : 1000)
    }

    const handleChangeButtonCheckbox = (name, value) => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        onUpdateEstimation(0)
    }

    return (
        <Fragment>
            <StepTitle 
                title="Surfaces et pièces" 
                step={stepName} 
                noProgressBarDisplay={noProgressBarDisplay}
            />
            <div style={{ marginBottom: 50 }} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Surface habitable en m²" 
                        isSuccess={housing.surface_reelle_bati > 0} 
                        onChange={e => {
                            const value = e.target.value
                            if (value < 0 || value === "0") return
                            handleChangeHousingValue(e)
                        }}
                        value={housing.surface_reelle_bati || ""}
                        type="number" 
                        placeholder="Surface"
                        name="surface_reelle_bati"
                        disabled={working || estimationsOfHousing.length > 1 || loadingEstimationsOfHousing}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        label="Surface du terrain en m²" 
                        toolTip="Surface du lot total bien inclu. Par exemple la surface du rez de chaussée + surface jardin, ou patio, ou veranda..."
                        isSuccess={housing.surface_terrain_totale > 0}
                        onChange={e => {
                            const value = e.target.value
                            if (value < 0 || value === "0") return
                            handleChangeHousingValue(e)
                        }}
                        value={housing.surface_terrain_totale || ""}
                        type="number" 
                        placeholder="Surface"
                        name="surface_terrain_totale"
                        disabled={working || estimationsOfHousing.length > 1 || loadingEstimationsOfHousing}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        toolTip="Une pièce peut être considérée comme telle à partir de 8m² de surface"
                        label="Nombre de pièces"
                        isSuccess={housing.nombre_pieces_principales}
                        type="select"
                        value={housing.nombre_pieces_principales || ""}
                        onChange={handleChangeHousingValue}
                        name="nombre_pieces_principales"
                        disabled={working || estimationsOfHousing.length > 1 || loadingEstimationsOfHousing}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                {[...Array(50).keys()].map((d, i) => 
                                    <option key={i}>
                                        {d + 1}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        label="Nombre de chambres"
                        isSuccess={estimation.nb_chambres}
                        type="select"
                        value={estimation.nb_chambres || ""}
                        onChange={handleChangeEstimationValue}
                        name="nb_chambres"
                        disabled={working}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                {choices_nb_chambres.map((d, i) => 
                                    <option 
                                        key={i} 
                                        value={d.value}
                                    >
                                        {d.label}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        label="Surface de la pièce de vie en m²" 
                        isSuccess={estimation.surface_piece_de_vie}
                        onChange={handleChangeEstimationValue}
                        value={estimation.surface_piece_de_vie || ""}
                        type="select"
                        name="surface_piece_de_vie"
                        disabled={working}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                {choices_surface_piece_de_vie.map((d, i) => 
                                    <option key={i} value={d.value}>
                                        {d.label}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid>
                {housing.is_flat && (
                    <Fragment>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <FieldControl
                                    label="S'agit-il d'un duplex ?"
                                    isSuccess={estimation.duplex !== null && estimation.duplex !== undefined && estimation.duplex !== ""}
                                    type="checkbox"
                                    choices={[{ label: "Oui", value: true }, { label: "Non", value: false }]}
                                    selectedValue={estimation.duplex}
                                    name="duplex"
                                    onChange={handleChangeButtonCheckbox}
                                    xs={6}
                                    disabled={working}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldControl 
                                toolTip="Indiquez à quel étage se situe l'appartement"
                                label="Étage de l'appartement"
                                isSuccess={estimation.etage_bien}
                                type="select"
                                value={estimation.etage_bien || ""}
                                onChange={handleChangeEstimationValue}
                                name="etage_bien"
                                disabled={working}
                                options={
                                    <Fragment>
                                        <option value="">Sélectionner</option>
                                        {[...Array(50).keys()].map((d, i) => 
                                            <option 
                                                key={i} 
                                                value={d}
                                                disabled={
                                                    estimation.etages_batiment !== undefined
                                                    && estimation.etages_batiment !== null
                                                    && estimation.etages_batiment !== ""
                                                    && d > estimation.etages_batiment
                                                }
                                            >
                                                {d === 0 ? "Rez-de-chaussée" : d}
                                            </option>
                                        )}
                                    </Fragment>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldControl 
                                label="Nombre d'étages du bâtiment"
                                isSuccess={estimation.etages_batiment}
                                type="select"
                                value={estimation.etages_batiment || ""}
                                onChange={handleChangeEstimationValue}
                                name="etages_batiment"
                                disabled={working}
                                options={
                                    <Fragment>
                                        <option value="">Sélectionner</option>
                                        {[...Array(50).keys()].map((d, i) => 
                                            <option 
                                                key={i}
                                                disabled={
                                                    estimation.etage_bien !== undefined
                                                    && estimation.etage_bien !== null
                                                    && estimation.etage_bien !== ""
                                                    && d < estimation.etage_bien
                                                }
                                            >
                                                {d}
                                            </option>
                                        )}
                                    </Fragment>
                                }
                            />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Fragment>
    )
}

export default Step01Form