import { CloseRounded } from "@mui/icons-material"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"

const ButtonReset = ({ onClick }) => (
    <Button
        onClick={onClick}
        variant="text"
        sx={{
            color: scssVar.danger,
            mb: 1,
            fontWeight: 600,
            // borderRadius: 0
            "&:hover": {
                color: scssVar.danger,
                textDecoration: "underline"
            }
        }}
    >
        {<CloseRounded />}
        Effacer la saisie
    </Button>
)

export default ButtonReset