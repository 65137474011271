import { Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../../../app/scssVar"
import MandatPdf from "../../../../pdfCreator/mandat/MandatPdf"

const TabMandat = () => {
    return (
        <Fragment>
            <Box sx={{
                "@media (max-width: 1100px)": {
                    display: "none"
                }
            }}>
                <MandatPdf />
            </Box>
            <Typography sx={{
                display: "none",
                fontFamily: scssVar.fontFamily,
                color: scssVar.danger,
                "@media (max-width: 1100px)": {
                    display: "block"
                }
            }}>
                Fonctionnalité non disponible.
            </Typography>
            <Typography sx={{
                display: "none",
                fontFamily: scssVar.fontFamily,
                color: "black",
                "@media (max-width: 1100px)": {
                    display: "block"
                }
            }}>
                Vous devez disposer d'un écran plus grand.
            </Typography>
        </Fragment>
    )
}

export default TabMandat