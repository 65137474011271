import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../../app/scssVar"
import Button from "../../../../../../common/components/Button"
import { api } from "../../../../../../common/setupApi"
import { selectEstimation, selectHousing, selectHousingEstimations } from "../../../../selectors"
import { updateEstimationsList } from "../../../../actions"
import { addAlert } from "../../../../../alerts/alertsSlice"
import { Fragment, useState } from "react"
import MissingFieldAlertText from "../../../../../../common/components/MissingFieldAlertText"

const Publish = () => {
    const dispatch = useDispatch()
    const housing = useSelector(selectHousing)
    const estimation = useSelector(selectEstimation)
    const working = useSelector(state => state.estimate.working)
    const housingEstimations = useSelector(selectHousingEstimations).data
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [openErrorDesc, setOpenErrorDesc] = useState(false)
    
    const adPublished = estimation.ad_published
    const description = estimation.description_estimation
    const emptyDescription = description === "" || description === null
    const otherAdPublished = housingEstimations.filter(d => d.id !== estimation.id).find(d => d.ad_published === true)

    const handleOpen = () => {
        if (!adPublished && emptyDescription) {
            setOpenErrorDesc(true)
        } else {
            setOpen(true)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = async () => {
        dispatch({ type: "estimate/set_working", payload: true })
        setLoading(true)

        const estimationCopy = {
            ...estimation,
            ad_published: !adPublished
        }

        // Si remplacement (feat non dispo pour le moment)
        // dispatch({ type: "estimate/set_estimation", payload: res.data }) // set current estimation ad_published = true
        // dispatch({ type: ""estimate/publish_ads"", payload: res.data })

        return api
            .post("v1/housing/estimation/compute", { housing, estimation: estimationCopy })
            .then(res => {                
                dispatch({ type: "estimate/set_estimation", payload: res.data })
                dispatch({ type: "estimate/edit_estimation", payload: res.data })
                dispatch(updateEstimationsList("Synthesis"))
                dispatch(addAlert(res.data.ad_published ? "Annonce publiée" : "Annonce retirée", "success"))
                dispatch({ type: "estimate/set_working", payload: false })
                setLoading(false)
                setOpen(false)
            })
            .catch(err => {
                console.log(err.response)
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <Box sx={{
                borderRadius: "16px",
                bgcolor: scssVar.greyLighter, //"#F7FCFD",
                p: 2,
                //border: 1,
                mb: 4,
                maxWidth: 650
            }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.greyDarker,
                        mr: 1
                    }}>
                        Publication sur <a href="https://lardv.fr" target="_blank" rel="noreferrer"><u>La Rose des Ventes</u></a>
                    </Typography>
                </Box>
                {otherAdPublished ? (
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.danger
                    }}>
                        <b>Une annonce est déjà publiée pour ce bien</b>
                        <br/>Pour mettre en ligne cette annonce, vous devez d'abord retirer celle publiée.
                    </Typography>
                ):(
                    <Fragment>
                        <Button 
                            variant={adPublished ? "primary-light" : "primary"}
                            onClick={handleOpen}
                            sx={{
                                bgcolor: adPublished ? "white" : scssVar.primary,
                                color: adPublished ? scssVar.primary : "white",
                                boxShadow: scssVar.shadow2
                            }}
                        >
                            {adPublished ? "J’annule mon annonce" : "Je publie mon annonce"}
                        </Button>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.black,
                            fontSize: 12,
                            mt: 2
                        }}>
                            En cas de modification de votre estimation, l'annonce publiée sera mise à jour dans un délai maximal de 6 heures.
                        </Typography>
                    </Fragment>
                )}
            </Box>
            <Dialog
                open={open} 
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    {adPublished ? "Retirer l'annonce ?" : "Publier l'annonce ?"}
                </DialogTitle>
                    {/* {!adPublished && otherAdPublished && (
                        <DialogContent>
                            <DialogContentText sx={{
                                fontFamily: scssVar.fontFamily,
                            }}>
                                Une annonce est déjà publiée pour ce bien, la remplacer ?
                            </DialogContentText>
                        </DialogContent>
                    )} */}
                    {!adPublished && (
                        <DialogContent>
                            <DialogContentText sx={{
                                fontFamily: scssVar.fontFamily,
                            }}>
                                Cette annonce sera publiée dans un délai de 6 heures maximum.
                            </DialogContentText>
                        </DialogContent>
                    )}
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        loading={loading || working}
                        disabled={loading || working}
                        sx={{
                            width: 150
                        }}
                    >
                        {/* {adPublished ? "Retirer" : otherAdPublished ? "Remplacer" : "Publier"} */}
                        {adPublished ? "Retirer" : "Publier"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openErrorDesc}
                onClose={() => setOpenErrorDesc(false)}
                fullWidth
            >
                <DialogContent sx={{ pb: 0 }}>
                    <MissingFieldAlertText visible>
                        Votre annonce n'est pas finalisée ! Veuillez renseigner une <u>description</u> pour ce bien (onglet "Fiche du bien")
                    </MissingFieldAlertText>
                </DialogContent>
                <DialogActions>
                    <Button variant="light" onClick={() => setOpenErrorDesc(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Publish