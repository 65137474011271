import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { api } from "../../common/setupApi";

export const getApiLeads = createAsyncThunk("apiOffer/getLeads", async (_, { rejectWithValue }) => {
    return api
        .get("v1/api/leads/")
        .then(res => res.data)
        .catch(err => rejectWithValue(err.response?.data))
},{
    condition: (_, { getState }) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
        const { status } = selectApiLeads(getState())
        if (status === "fulfilled" || status === "pending") {
            // Already fetched or in progress, don't need to re-fetch
            return false
        }
    }
})

export const getApiConfig = createAsyncThunk("apiOffer/getConfig", async (_, { rejectWithValue }) => {
    return api
        .get("v1/api/infos/")
        .then(res => res.data)
        .catch(err => rejectWithValue(err.response?.data))
},{
    condition: (_, { getState }) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
        const { status } = selectApiConfig(getState())
        if (status === "fulfilled" || status === "pending") {
            // Already fetched or in progress, don't need to re-fetch
            return false
        }
    }
})

export const getApiKpis = createAsyncThunk("apiOffer/getKpi", async (params, { rejectWithValue }) => {
    let url = "v1/api/kpis/"

    if (params?.start && params?.end) {
        url = url + "?start=" + params.start + "&end=" + params.end
    }

    return api
        .get(url)
        .then(res => res.data)
        .catch(err => rejectWithValue(err.response?.data))
},{
    condition: (params, { getState }) => {
        if (params?.ignoreCondition) {
            return true
        }
        // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
        const { status } = selectApiKpis(getState())
        if (status === "fulfilled" || status === "pending") {
            // Already fetched or in progress, don't need to re-fetch
            return false
        }
    }
})

export const getApiLongitudinalKpis = createAsyncThunk("apiOffer/getLongitudinalKpi", async (_, { rejectWithValue }) => {
    return api
        .get("v1/api/billing/")
        .then(res => res.data)
        .catch(err => rejectWithValue(err.response?.data))
},{
    condition: (_, { getState }) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
        const { status } = selectApiLongitudinalKpis(getState())
        if (status === "fulfilled" || status === "pending") {
            // Already fetched or in progress, don't need to re-fetch
            return false
        }
    }
})

const initialState = {
    tabIndex: 0,
    config: {
        status: "idle",
        data: {},
        error: null
    },
    leads: {
        status: "idle",
        data: [],
        error: null,
        selected: {},
        filters: {
            order: "desc",
            orderBy: "created_on",
            search: "",
            selected: [],
            page: 0,
            rowsPerPage: 5
        }
    },
    kpis: {
        status: "idle",
        data: {},
        error: null,
        selectedMember: ""
    },
    longitKpis: {
        status: "idle",
        data: {},
        error: null,
        selectedStackedType: "day",
        displayBillingDetails: false
    }
}

const apiOfferSlice = createSlice({
    name: "apiOffer",
    initialState,
    reducers: {
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload
        },
        setDomains: (state, action) => {
            state.config.data.domains = action.payload
        },
        setDocs: (state, action) => {
            state.config.data.api_legal_docs = action.payload
        },
        setDepartement: (state, action) => {
            state.config.data.api_departments = action.payload
        },
        setLeads: (state, action) => {
            const pks = action.payload.pk

            state.leads.data = state.leads.data.map(d => pks.includes(d.pk) ? {
                ...d,
                [action.payload.name]: action.payload.value
            }: d)
        },
        setLeadAfterUpdateInSynthesis: (state, action) => {
            state.leads.data = state.leads.data.map(d => d.pk === action.payload.pk ? {
                ...d,
                ...action.payload
            }: d)
        },
        setLeadsOrder: (state, action) => {
            state.leads.filters.order = action.payload.order
            state.leads.filters.orderBy = action.payload.orderBy
        },
        setLeadsSearch: (state, action) => {
            state.leads.filters.search = action.payload
        },
        setLeadsSelected: (state, action) => {
            state.leads.filters.selected = action.payload
        },
        setLeadsPage: (state, action) => {
            state.leads.filters.page = action.payload
        },
        setLeadsRowsPerPage: (state, action) => {
            state.leads.filters.rowsPerPage = action.payload
        },
        setSelectedLead: (state, action) => {
            state.leads.selected = action.payload
        },
        setSelectedKpisMember: (state, action) => {
            state.kpis.selectedMember = action.payload
        },
        setSelectedLongitKpisStackedType: (state, action) => {
            state.longitKpis.selectedStackedType = action.payload
        },
        setDisplayBillingDetails: (state, action) => {
            state.longitKpis.displayBillingDetails = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getApiConfig.pending, (state) => {
                state.config.status = "pending"
                state.config.error = null
            })
            .addCase(getApiConfig.fulfilled, (state, action) => {
                state.config.status = "fulfilled"
                state.config.data = action.payload
            })
            .addCase(getApiConfig.rejected, (state, action) => {
                state.config.status = "rejected"
                state.config.error = action.payload
            })
            .addCase(getApiLeads.pending, (state) => {
                state.leads.status = "pending"
                state.leads.error = null
            })
            .addCase(getApiLeads.fulfilled, (state, action) => {
                state.leads.status = "fulfilled"
                state.leads.data = action.payload
            })
            .addCase(getApiLeads.rejected, (state, action) => {
                state.leads.status = "rejected"
                state.leads.error = action.payload
            })
            .addCase(getApiKpis.pending, (state) => {
                state.kpis.status = "pending"
                state.kpis.error = null
            })
            .addCase(getApiKpis.fulfilled, (state, action) => {
                state.kpis.status = "fulfilled"
                state.kpis.data = action.payload
                state.kpis.selectedMember = 
                    action.payload.profile_stats && action.payload.profile_stats.length
                        ? action.payload.profile_stats[0].team_member
                        : ""
            })
            .addCase(getApiKpis.rejected, (state, action) => {
                state.kpis.status = "rejected"
                state.kpis.error = action.payload
            })
            .addCase(getApiLongitudinalKpis.pending, (state) => {
                state.longitKpis.status = "pending"
                state.longitKpis.error = null
            })
            .addCase(getApiLongitudinalKpis.fulfilled, (state, action) => {
                state.longitKpis.status = "fulfilled"
                state.longitKpis.data = action.payload
            })
            .addCase(getApiLongitudinalKpis.rejected, (state, action) => {
                state.longitKpis.status = "rejected"
                state.longitKpis.error = action.payload
            })
            .addCase("auth/logout", () => initialState)
    }
})

export const apiOfferActions = apiOfferSlice.actions

export const selectApiTabIndex = (state) => state.apiOffer.tabIndex

export const selectApiDisplayBillingDetails = (state) => state.apiOffer.tabIndex

export const selectApiConfig = (state) => {
    const data = state.apiOffer.config.data
    const status = state.apiOffer.config.status
    const error = state.apiOffer.config.error
    const isLoading = status === "pending"
    return { data, status, error, isLoading }
}

export const selectApiDomains = (state) => selectApiConfig(state).data.domains || []

export const selectApiMissingDomain = (state) => {
    const domains = selectApiConfig(state).data.domains
    return domains !== undefined && !domains.length
}

export const selectApiLegalDocs = (state) => selectApiConfig(state).data.api_legal_docs || []

// memo ok
export const selectApiDepartements = createSelector([
    (state) => selectApiConfig(state).data.api_departments || []
], (departements) => {
    const sorted = [...departements].sort((a, b) => Number(a) - Number(b))
    return sorted
})

export const selectApiLeads = (state) => {
    const data = state.apiOffer.leads.data
    const status = state.apiOffer.leads.status
    const error = state.apiOffer.leads.error
    const isLoading = status === "pending"
    return { data, status, error, isLoading }
}

export const selectApiLeadsMissingAssignments = (state) => {
    const { data } = selectApiLeads(state)
    return data.length > 0 
        && data.filter(d => d.profile === null || d.profile === "")?.length
}

export const selectApiLeadsFilters = (state) => state.apiOffer.leads.filters

// memo ok
export const selectSelectedLead = createSelector([
    (state) => selectApiLeads(state).data,
    (state) => state.apiOffer.leads.selected
], (leads, pk) => {
    return leads.find(d => d.pk === pk) || {}
})

export const selectApiKpis = (state) => {
    const data = state.apiOffer.kpis.data
    const status = state.apiOffer.kpis.status
    const error = state.apiOffer.kpis.error
    const selectedMember = state.apiOffer.kpis.selectedMember
    const isLoading = status === "pending"
    return { data, status, error, isLoading, selectedMember }
}

export const selectApiKpisPeriod = (state) => {
    const { data } = selectApiKpis(state)
    return { 
        start: data.start || "", 
        end: data.end || "" 
    }
}

export const selectApiKpisFunnelData = (state) => selectApiKpis(state).data.funnel || []

// memo ok ?
export const selectApiKpisProfileStats = createSelector([
    (state) => selectApiKpis(state).data.profile_stats || [],
    (state) => selectApiKpis(state).selectedMember
], (all, selectedMember) => {
    const data = all.find(d => d.team_member === selectedMember) || {}
    const options = all.map(d => d.team_member)
    return { data, selectedMember, options }
})

// export const selectApiKpisProfileStats_ = (state) => {
//     const all = selectApiKpis(state).data.profile_stats || []
//     const { selectedMember } = selectApiKpis(state)
//     const data = all.find(d => d.team_member === selectedMember) || {}
//     const options = all.map(d => d.team_member)

//     return {
//         data,
//         selectedMember,
//         options
//     }
// }

export const selectApiKpisHouseType = (state) => {
    const { data } = selectApiKpis(state)
    return {
        houses: data.houses || 0,
        flats: data.flats || 0
    }
}

export const selectApiKpisHouseTypeLeads = (state) => {
    const { data } = selectApiKpis(state)
    return {
        houses: data.leads_houses || 0,
        flats: data.leads_flats || 0
    }
}

export const selectApiKpisAttributionLeads = (state) => {
    const { data } = selectApiKpis(state)
    return {
        attributed: data.attributed || 0,
        unattributed: data.unattributed || 0
    }
}

export const selectApiLongitudinalKpis = (state) => {
    const data = state.apiOffer.longitKpis.data
    const status = state.apiOffer.longitKpis.status
    const error = state.apiOffer.longitKpis.error
    const selectedStackedType = state.apiOffer.longitKpis.selectedStackedType
    const displayBillingDetails = state.apiOffer.longitKpis.displayBillingDetails
    const isLoading = status === "pending"
    return { 
        data, 
        groups: data.groups || ['Leads vérifiés', 'Leads non vérifiés', 'Sans coordonnées'],
        dataDaily: data.daily || [],
        dataWeekly: data.weekly || [],
        dataMonthly: data.monthly || [],
        dataBilling: data.billing || {},
        selectedStackedType,
        displayBillingDetails,
        status, 
        error, 
        isLoading 
    }
}

export default apiOfferSlice.reducer