import { useEffect, useRef } from "react"
import * as d3 from "d3"
import { scssVar } from "../../../../app/scssVar";

const draw = (data, svgRef, {
    width = 150,
    height = width,
    margin = 0,
    radius = Math.min(width, height) / 2 - margin,
    titleFontSize = 11
} = {}) => {
    // if component remount, remove chart and redraw
    d3.select(svgRef.current).selectAll("*").remove();

    const dataReady = d3.pie()
        .value(d => d.value)(data)
        .filter(d => d.value > 0)
        // .map(d => ({ ...d, label: `${d.data.name}\n${d.value}` }))

    // Init svg
    const svg = d3.select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", `-${width/2} -${height/2} ${width} ${height}`)
        .attr("text-anchor", "middle")
        .attr("font-family", scssVar.fontFamily);

    const arc = d3
        .arc()
        // Radius extérieur
        .innerRadius(radius / 1.75)
        // Radius intérieur
        .outerRadius(radius)
        // Espace entre les portions
        .padRadius(radius)
        .padAngle(2 / radius)
        // Radius des coins des portions
        .cornerRadius(width * 2/100);

    svg
        .selectAll("path")
        .data(dataReady)
        .join("path")
        .attr("d", d => arc(d))
        .attr("fill", d => d.data.color);

    svg
        .selectAll("text")
        .data(dataReady)
        .join("text")
        .attr("fill", "white")
        .attr("transform", d => `translate(${arc.centroid(d)})`)
        .append("tspan")
        .attr("x", "0")
        .attr("dy", ".3em")
        .attr("font-size", titleFontSize)
        .attr("font-weight", 600)
        .text(d => d.value);

    return svg.node()
}

const Doughnut = ({ data, params }) => {
    const svgRef = useRef(null)

    useEffect(() => {
        draw(data, svgRef, params)
    }, [data, params])

    return <svg ref={svgRef} />
}

export default Doughnut