import { useSelector } from "react-redux"

const useEnergieItems = () => {
    const estimation = useSelector(state => state.estimate.estimation)
    
    return [
        { 
            label: "Eau", 
            value: estimation.cout_eau_an, 
            unit: "€", 
            nd: "Non définie" 
        },
        { 
            label: "Coût énergétique global", 
            labelInPdf: "Coût énergétique",
            value: estimation.total_cout_energetique_an, 
            unit: "€", 
            nd: "Non défini" 
        },
    ]
}

export default useEnergieItems