import { Fragment } from "react"
import { useSelector } from "react-redux"
import Margin from "../../components/Margin"

const A = ({ color }) => (
    <svg width="47" height="20" viewBox="0 0 47 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H39.9912L47 10L39.9912 20H0Z" fill={color} />
        <path d="M13.483 13.892H9.029L8.315 16H5.272L9.59 4.066H12.956L17.274 16H14.197L13.483 13.892ZM12.735 11.648L11.256 7.279L9.794 11.648H12.735Z" fill="white"/>
    </svg>
)

const B = ({ color }) => (
    <svg width="62" height="20" viewBox="0 0 62 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H54L62 10L54 20H0Z" fill={color} />
        <path d="M13.279 9.88C13.9703 10.0273 14.5257 10.373 14.945 10.917C15.3643 11.4497 15.574 12.0617 15.574 12.753C15.574 13.7503 15.2227 14.5437 14.52 15.133C13.8287 15.711 12.8597 16 11.613 16H6.054V4.066H11.426C12.6387 4.066 13.585 4.34367 14.265 4.899C14.9563 5.45433 15.302 6.208 15.302 7.16C15.302 7.86267 15.115 8.44633 14.741 8.911C14.3783 9.37567 13.891 9.69867 13.279 9.88ZM8.961 8.894H10.865C11.341 8.894 11.7037 8.792 11.953 8.588C12.2137 8.37267 12.344 8.061 12.344 7.653C12.344 7.245 12.2137 6.93333 11.953 6.718C11.7037 6.50267 11.341 6.395 10.865 6.395H8.961V8.894ZM11.103 13.654C11.5903 13.654 11.9643 13.5463 12.225 13.331C12.497 13.1043 12.633 12.7813 12.633 12.362C12.633 11.9427 12.4913 11.614 12.208 11.376C11.936 11.138 11.5563 11.019 11.069 11.019H8.961V13.654H11.103Z" fill="white"/>
    </svg>
)

const C = ({ color }) => (
    <svg width="78" height="20" viewBox="0 0 78 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H70L78 10L70 20H0Z" fill={color} />
        <path d="M5.561 10.016C5.561 8.83733 5.816 7.789 6.326 6.871C6.836 5.94167 7.54433 5.222 8.451 4.712C9.369 4.19067 10.406 3.93 11.562 3.93C12.9787 3.93 14.1913 4.304 15.2 5.052C16.2087 5.8 16.883 6.82 17.223 8.112H14.027C13.789 7.61333 13.449 7.23367 13.007 6.973C12.5763 6.71233 12.0833 6.582 11.528 6.582C10.6327 6.582 9.90733 6.89367 9.352 7.517C8.79667 8.14033 8.519 8.97333 8.519 10.016C8.519 11.0587 8.79667 11.8917 9.352 12.515C9.90733 13.1383 10.6327 13.45 11.528 13.45C12.0833 13.45 12.5763 13.3197 13.007 13.059C13.449 12.7983 13.789 12.4187 14.027 11.92H17.223C16.883 13.212 16.2087 14.232 15.2 14.98C14.1913 15.7167 12.9787 16.085 11.562 16.085C10.406 16.085 9.369 15.83 8.451 15.32C7.54433 14.7987 6.836 14.079 6.326 13.161C5.816 12.243 5.561 11.1947 5.561 10.016Z" fill="white"/>
    </svg>
)

const D = ({ color }) => (
    <svg width="93" height="20" viewBox="0 0 93 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H85L93 10L85 20H0Z" fill={color} />
        <path d="M10.525 4.066C11.783 4.066 12.8823 4.31533 13.823 4.814C14.7637 5.31267 15.489 6.01533 15.999 6.922C16.5203 7.81733 16.781 8.85433 16.781 10.033C16.781 11.2003 16.5203 12.2373 15.999 13.144C15.489 14.0507 14.758 14.7533 13.806 15.252C12.8653 15.7507 11.7717 16 10.525 16H6.054V4.066H10.525ZM10.338 13.484C11.4373 13.484 12.293 13.1837 12.905 12.583C13.517 11.9823 13.823 11.1323 13.823 10.033C13.823 8.93367 13.517 8.078 12.905 7.466C12.293 6.854 11.4373 6.548 10.338 6.548H8.961V13.484H10.338Z" fill="white"/>
    </svg>
)

const E = ({ color }) => (
    <svg width="109" height="20" viewBox="0 0 109 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H101L109 10L101 20H0Z" fill={color} />
        <path d="M8.961 6.395V8.809H12.854V11.053H8.961V13.671H13.364V16H6.054V4.066H13.364V6.395H8.961Z" fill="white"/>
    </svg>
)

const F = ({ color }) => (
    <svg width="124" height="20" viewBox="0 0 124 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H117L124 10L117 20H0Z" fill={color} />
        <path d="M13.823 4.066V6.395H8.961V8.911H12.599V11.172H8.961V16H6.054V4.066H13.823Z" fill="white"/>
    </svg>
)

const G = ({ color }) => (
    <svg width="140" height="20" viewBox="0 0 140 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 20V0H132.097L140 10L132.097 20H0Z" fill={color} />
        <path d="M13.976 7.84C13.7607 7.44333 13.449 7.143 13.041 6.939C12.6443 6.72367 12.174 6.616 11.63 6.616C10.6893 6.616 9.93567 6.92767 9.369 7.551C8.80233 8.163 8.519 8.98467 8.519 10.016C8.519 11.1153 8.81367 11.9767 9.403 12.6C10.0037 13.212 10.8253 13.518 11.868 13.518C12.582 13.518 13.1827 13.3367 13.67 12.974C14.1687 12.6113 14.5313 12.09 14.758 11.41H11.069V9.268H17.393V11.971C17.1777 12.6963 16.8093 13.3707 16.288 13.994C15.778 14.6173 15.1263 15.1217 14.333 15.507C13.5397 15.8923 12.6443 16.085 11.647 16.085C10.4683 16.085 9.41433 15.83 8.485 15.32C7.567 14.7987 6.84733 14.079 6.326 13.161C5.816 12.243 5.561 11.1947 5.561 10.016C5.561 8.83733 5.816 7.789 6.326 6.871C6.84733 5.94167 7.567 5.222 8.485 4.712C9.403 4.19067 10.4513 3.93 11.63 3.93C13.058 3.93 14.2593 4.27567 15.234 4.967C16.22 5.65833 16.8717 6.616 17.189 7.84H13.976Z" fill="white"/>
    </svg>
)

const Selector = ({ value, color }) => (
    <div style={{ position: "relative" }}>
        <svg width="141" height="25" viewBox="0 0 141 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M140.828 25V0H108.828L100.828 12.5L108.828 25H140.828Z" fill={color} />
            <path d="M0 12.5H101.02" stroke="black" strokeWidth="0.5"/>
        </svg>
        <p style={{
            position: "absolute",
            right: 10,
            top: 7,
            fontSize: 18,
            lineHeight: "11px",
            color: "white",
            fontWeight: 700,
        }}>
            {value}
        </p>
    </div>
)

const Title = ({ variant }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <p style={{
            fontSize: 14,
            fontWeight: 500,
            color: userColor,
            lineHeight: "20px"
        }}>
           {variant === "dpe" ? (
                <Fragment>
                    Diagnostic de Performance
                    <br />Energétique (DPE)
                </Fragment>
           ):(
                <Fragment>
                    Emission de Gaz
                    <br />à Effet de Serre  (GES)
                </Fragment>
           )}
        </p>
    )
}

// const BorneInf = ({ variant }) => {
//     const userColor = useSelector(state => state.account.org.color)
//     const borneInf = 
//         variant === "dpe" 
//             ? "Logement économe"
//             : "Faible émission de GES"

//     return (
//         <p style={{
//             color: userColor,
//             fontSize: 9,
//             fontWeight: 500
//         }}>
//             {borneInf}
//         </p>
//     )
// }

// const BornSup = ({ variant }) => {
//     const userColor = useSelector(state => state.account.org.color)
//     const bornSup = 
//         variant === "dpe" 
//             ? "Logement énergivore"
//             : "Forte émission de GES"

//     return (
//         <p style={{
//             color: userColor,
//             fontSize: 9,
//             fontWeight: 500
//         }}>
//             {bornSup}
//         </p>
//     )
// }

const getSelectorCoord = (value) => {
    const val = value.toUpperCase()

    switch (val) {
        default:
            return { display: "none" }
        case "A":
            return { top: -2.5 }
        case "B":
            return { top: 20.5 }
        case "C":
            return { top: 43.5 }
        case "D":
            return { top: 66.5 }
        case "E":
            return { top: 89.5 }
        case "F":
            return { top: 112.5 }
        case "G":
            return { top: 135.5 }
    }
}

const BLOCKS = [
    { value: "A", colors: { gse: "#EDD1EF", dpe: "#33AF8A" }, component: A },
    { value: "B", colors: { gse: "#D9C1DB", dpe: "#75C176" }, component: B },
    { value: "C", colors: { gse: "#C6A8CC", dpe: "#93CA91" }, component: C },
    { value: "D", colors: { gse: "#B696BF", dpe: "#F6EC3F" }, component: D },
    { value: "E", colors: { gse: "#9D76AD", dpe: "#F4C340" }, component: E },
    { value: "F", colors: { gse: "#835A9B", dpe: "#EF9B5D" }, component: F },
    { value: "G", colors: { gse: "#6A418F", dpe: "#DF4D4D" }, component: G },
]

const Scale = ({ variant, value }) => {
    const selectorColor = BLOCKS.find(d => d.value === value.toUpperCase())?.colors[variant] || "black"
    const selectorCoord = getSelectorCoord(value)

    return (
        <div style={{
            position: "relative",
            width: 183,
            zIndex: 1,
        }}>
            <div style={{ 
                zIndex: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
            }}>
                {BLOCKS.map((d, i) => {
                    const Letter = d.component

                    return (
                        <Fragment key={i}>
                            <Letter color={d.colors[variant]} />
                            <Margin m={1.5} />
                        </Fragment>
                    )
                })} 
            </div>
            <div style={Object.assign({}, {
                zIndex: 0,
                position: "absolute",
                right: 0,
            }, selectorCoord)}>
                <Selector value={value.toUpperCase()} color={selectorColor} />
            </div>
        </div>
    )
}

const AdScalePerformance = () => {
    const userColor = useSelector(state => state.account.org.color)
    const dpe = useSelector(state => state.estimate.estimation.dpe)
    const gse = useSelector(state => state.estimate.estimation.gse)

    const DPE = () => {
        if (dpe === "NC" || dpe === "nc" || !dpe) {
            return (
                <div>
                    <Title variant="dpe" />
                    <Margin m={8} />
                    <Scale variant="dpe" value="" />
                    <Margin m={2} />
                    <p style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: userColor
                    }}>
                        Non défini
                    </p>
                </div>
            )
        }

        return (
            <div>
                <Title variant="dpe" />
                <Margin m={8} />
                <Scale variant="dpe" value={dpe} />
                {(dpe?.toUpperCase() === "F" || dpe?.toUpperCase() === "G") 
                && gse?.toUpperCase() !== "F" && gse?.toUpperCase() !== "G"
                && (
                    <Fragment>
                        <Margin m={2} />
                        <p style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: userColor
                        }}>
                            Logement énergivore
                        </p>
                    </Fragment>
                )}
            </div>
        )
    }

    const GSE = () => {
        if (gse === "NC" || gse === "nc" || !gse) {
            return (
                <div>
                    <Title variant="gse" />
                    <Margin m={8} />
                    <Scale variant="gse" value="" />
                    <Margin m={2} />
                    <p style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: userColor
                    }}>
                        Non défini
                    </p>
                </div>
            )
        }

        return (
            <div>
                <Title variant="gse" />
                <Margin m={8} />
                <Scale variant="gse" value={gse} />
                {(gse?.toUpperCase() === "F" || gse?.toUpperCase() === "G") 
                && dpe?.toUpperCase() !== "F" && dpe?.toUpperCase() !== "G" 
                && (
                    <Fragment>
                        <Margin m={2} />
                        <p style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: userColor
                        }}>
                            Logement énergivore
                        </p>
                    </Fragment>
                )}
            </div>
        )
    }

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <DPE />
                <GSE />
            </div>
            {(gse?.toUpperCase() === "F" || gse?.toUpperCase() === "G") 
            && (dpe?.toUpperCase() === "F" || dpe?.toUpperCase() === "G") 
            && (
                <Fragment>
                    <Margin m={2} />
                    <p style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: userColor,
                        textAlign: "center"
                    }}>
                        Logement énergivore
                    </p>
                </Fragment>
            )}
        </div>
    )
}

export default AdScalePerformance