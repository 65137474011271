import { useDispatch, useSelector } from "react-redux"
import InputSelect from "../../components/InputSelect"
import InputText from "../../components/InputText"
import { selectDemandeVisiteState, visitsActions } from "../visitsSlice"

const Infos = () => {
    const dispatch = useDispatch()
    const state = useSelector(selectDemandeVisiteState)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(visitsActions.setDemandeField({ name, value }))
    }

    return (
        <div>
            <p style={{ fontWeight: 600, fontSize: 16}}>
                Afin de vous aider dans votre recherche merci de compléter les informations suivantes :
            </p>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Vous êtes :</p>
                <InputSelect
                    value={state.statut}
                    name="statut"
                    onChange={handleChange}
                    style={{
                        marginRight: 20
                    }}
                >
                    <option value="" />
                    <option>Propriétaire</option>
                    <option>Locataire</option>
                </InputSelect>
                <p style={{ marginRight: 3 }}>Votre acquisition correspond :</p>
                <InputSelect
                    value={state.acquisition_type}
                    name="acquisition_type"
                    onChange={handleChange}
                    style={{
                        flexGrow: 1
                    }}
                >
                    <option value="" />
                    <option>Premier achat</option>
                    <option>Changement résidence principale</option>
                    <option>Changement résidence secondaire</option>
                    <option>Achat résidence secondaire</option>
                </InputSelect>
            </div>
            
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Votre acquisition se fera :</p>
                <InputSelect
                    value={state.acquisition_apport}
                    name="acquisition_apport"
                    onChange={handleChange}
                    style={{
                        width: 250
                    }}
                >
                    <option value="" />
                    <option>Sans condition de prêt</option>
                    <option>Avec le concours d'un prêt à 100%</option>
                    <option>Avec un apport de 10%</option>
                    <option>Avec un apport de 20%</option>
                    <option>Avec un apport de 20% à 40%</option>
                    <option>Avec un apport de + 50%</option>
                </InputSelect>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Votre acquisition est-elle conditionnée par la vente d'un bien :</p>
                <InputSelect
                    value={state.acquisition_vente}
                    name="acquisition_vente"
                    onChange={handleChange}
                    style={{
                        width: 50
                    }}
                >
                    <option value="" />
                    <option>Non</option>
                    <option>Oui</option>
                </InputSelect>
            </div>

            <p>Dans le cadre de la Loi Tracfin, si vous avez de l'apport personnel, merci si vous le souhaitez, de préciser :</p>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>l'origine des fonds :</p>
                <InputText 
                    value={state.acquisition_origine}
                    name="acquisition_origine"
                    onChange={handleChange}
                    style={{ 
                        flexGrow: 1 
                    }}
                />
            </div>
        </div>
    )
}

export default Infos