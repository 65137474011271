import { Dialog, DialogActions, DialogContent, DialogTitle, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { cancelSub } from "../actions"

const initialState = {
    open: false
}

const CancelSub = () => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.account.cancelSub.loading)
    const [state, setState] = useState(initialState)

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleCancelSub = () => {
        dispatch(cancelSub())
    }

    return (
        <Fragment>
            <ListItemButton onClick={handleOpen}>
                <ListItemText
                    primary="Se désabonner"
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: scssVar.danger
                        },
                    }}
                />
                <i 
                    className="iconoir-arrow-right"
                    style={{
                        fontSize: 24,
                        color: scssVar.danger
                    }}
                ></i>
            </ListItemButton>
            <Dialog 
                open={state.open} 
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Se désabonner ?
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                    }}>
                        La suppression de votre compte prendra effet 
                        à la fin de la période d'engagement. En attendant, 
                        vous pourrez toujours vous connecter.  
                        Si vous le réactivez, votre jour de prélèvement est susceptible d'être réinitialisé.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="danger"
                        loading={loading}
                        disabled={loading}
                        onClick={handleCancelSub}
                    >
                        Se désabonner
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default CancelSub