import { useSelector } from "react-redux"
import { selectOrg } from "../../account/selectors"

export const useJssInput = () => {
    const userColor = useSelector(selectOrg).color
    const background = "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"

    return {
        background,
        color: "black",
        border: "none",
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",
        width: 175,
        padding: "0px 3px",
        fontSize: 13,
        height: 20,
        borderRadius: 0
    }
}

export default useJssInput