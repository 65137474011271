import { Fragment } from "react"
import { Box, Grid } from "@mui/material"
import { useSelector } from "react-redux"
import { selectApiKpis } from "../apiOfferSlice"
import Mean from "./components/Mean"
import Period from "./components/Period"
import Funnel from "./components/Funnel"
import HouseType from "./components/HouseType"
import AttributionLeads from "./components/AttributionLeads"
import LeadsCount from "./components/LeadsCount"
import ProfileStats from "./components/ProfileStats"
import { scssVar } from "../../../app/scssVar"
import useAuth from "../../auth/hooks/useAuth"

const Wrapper = ({ children }) => <Box sx={{ width: "100%", maxWidth: 600 }}>{children}</Box>

const TabKpi = () => {
    const { data, isLoading, error } = useSelector(selectApiKpis)
    const { isAdmin } = useAuth().user

    if (error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer</p>
    }

    if (!isAdmin) {
        return (
            <Fragment>
                <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: 300 } }}>
                    <Period />
                </Box>

                <Box sx={{ m: 3 }} />

                <Funnel />

                <Box sx={{ m: 3 }} />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Wrapper>
                            <ProfileStats />
                        </Wrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Wrapper>
                            <HouseType />
                        </Wrapper>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: 300 } }}>
                <Period />
            </Box>

            <Box sx={{ m: 3 }} />

            <Funnel />

            <Box sx={{ m: 3 }} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Wrapper>
                        <LeadsCount />
                    </Wrapper>
                    <Box sx={{ m: 2 }} />
                    <Wrapper>
                        <AttributionLeads />
                    </Wrapper>
                    <Box sx={{ m: 2 }} />
                    <Wrapper>
                        <Mean mean={data.mean_value} isLoading={isLoading} />
                    </Wrapper>
                    <Box sx={{ display: { xs: "block", lg: "none" }}}>
                        <Box sx={{ m: 2 }} />
                        <Wrapper>
                            <ProfileStats />
                        </Wrapper>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ display: { xs: "none", lg: "block" }}}>
                    <Wrapper>
                        <ProfileStats />
                    </Wrapper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Wrapper>
                        <HouseType />
                    </Wrapper>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default TabKpi