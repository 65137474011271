import { Fragment } from "react"
import Margin from "../../components/Margin"
import Type from "./Type"
import Exclusivity from "./Exclusivity"
import Mandataire from "./Mandataire"
import NumberMandat from "./NumberMandat"
import MandataireName from "./MandataireName"
import RSAC from "./RSAC"

const HeaderAvenant = () => {
    return (
        <Fragment>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div style={{
                    width: "39%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <div>
                        <Margin m={50} />
                        <Type readOnly />
                    </div>
                    <div>
                        <p style={{ fontWeight: 600, fontSize: 13 }}>
                            Type de mandat
                        </p>
                        <Exclusivity readOnly />
                    </div>
                </div>
                <div style={{
                    width: "59%"
                }}>
                    <Mandataire />
                </div>
            </div>

            <Margin m={5} />
            
            <div style={{ 
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{ 
                    display: "flex",
                    alignItems: "center",
                }}>
                    <p style={{
                        fontWeight: 600,
                        marginRight: 3,
                        fontSize: 14,
                        whiteSpace: "nowrap"
                    }}>
                        N° de MANDAT : 
                    </p>
                    <NumberMandat readOnly />
                </div>
                <div style={{ 
                    display: "flex",
                    alignItems: "center",
                }}>
                    <MandataireName />
                </div>
                <div style={{ 
                    display: "flex",
                    alignItems: "center",
                }}>
                    <RSAC />
                </div>
            </div>
        </Fragment>
    )
}

export default HeaderAvenant