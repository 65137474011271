import { Box, Typography } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const ProgressBar3 = ({ label, value }) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{
                    fontSize: 14,
                    color: scssVar.greyDark,
                    fontFamily: scssVar.fontFamily,
                }}>
                    <span style={{ color: scssVar.primary }}>{label.left}</span> {value.left || "0"}
                </Typography>
                <Typography sx={{
                    fontSize: 14,
                    color: scssVar.greyDark,
                    fontFamily: scssVar.fontFamily
                }}>
                    <span style={{ color: scssVar.danger }}>{label.right}</span> {value.right || "0"}
                </Typography>
            </Box>
            <Box sx={{
                width: "100%",
                maxWidth: "100%",
                height: 8,
                bgcolor: "transparent",
                display: "flex",
                mt: "3px",
            }}>
                <Box sx={{
                    width: `${value.left / (value.left + value.right) * 100}%`,
                    maxWidth: "100%",
                    height: "100%",
                    bgcolor: scssVar.primary,
                    borderRadius: "16px",
                }} />
                <Box sx={{
                    width: `${value.right / (value.left + value.right) * 100}%`,
                    maxWidth: "100%",
                    height: "100%",
                    bgcolor: scssVar.danger,
                    borderRadius: "16px",
                }} />
            </Box>
        </Box>
    )
}

export default ProgressBar3