import { Avatar, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { deleteTeam } from "../actions"

const initialState = {
    open: false,
    newTeam: {},
    surfacture: 0
}

const DeleteTeam = ({ team }) => {
    const dispatch = useDispatch()
    const teams = useSelector(state => state.account.teams.items)

    const [state, setState] = useState(initialState)
    const loading = team.deleteLoading || false
    const usersCount = team.users.length
    const maxUsers = teams.max_users_per_team
    const additionalPrice = teams.new_user_price
    const proraPrice = teams.new_user_prorated_price

    useEffect(() => {
        return () => {
            setState(initialState)
        }
    }, [])

    const handleOpen = () => {
        setState({ ...state, open: true, newTeam: team })
    }

    const handleClose = () => {
        setState(initialState)
    }

    const handleSelectNewTeam = (newTeam) => {
        // if (newTeam.team_id !== team.team_id) {
        //     if (newTeam.users.length + usersCount > maxUsers) {

        //         if (newTeam.users.length > maxUsers) {
        //             console.log("ça dépasse déja donc : +", team.users.length)
        //         } else {
        //             console.log("surfacture de", newTeam.users.length + usersCount - maxUsers, "users")
        //         }
        //     }
        // }

        setState(s => { 
            let surfacture = 0

            if (newTeam.team_id !== team.team_id) {
                const newUsersCount = newTeam.users.length + usersCount

                if (newUsersCount > maxUsers) {
                    if (newTeam.users.length > maxUsers) {
                        // ça dépasse déjà
                        surfacture = team.users.length
                    } else {
                        surfacture = newUsersCount - maxUsers
                    }
                }
            }

            return {
                ...s, 
                newTeam,
                surfacture
            }
        })
    }

    const handleDelete = () => {
        dispatch(deleteTeam(
            team.team_id, 
            state.newTeam.team_id,
            team.users
        ))
    }

    return (
        <Fragment>
            <ListItemButton
                component="div"
                onClick={handleOpen}
                sx={{
                    pl: 0,
                    pr: 0
                }}
            >
                <ListItemText
                    primary="Supprimer cette agence"
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: scssVar.danger,
                            fontWeight: 700
                        },
                    }}
                />
                <i 
                    className="iconoir-cancel"
                    style={{
                        fontSize: 24,
                        color: scssVar.danger,
                    }}
                ></i>
            </ListItemButton>
            <Dialog
                open={state.open} 
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Supprimer {team.team_name} ?
                </DialogTitle>
                {usersCount > 0 && (
                    <DialogContent>
                        <DialogContentText sx={{
                            fontFamily: scssVar.fontFamily,
                            fontSize: 14,
                        }}>
                            Si vous supprimer cette agence, vous devez en sélectionner 
                            une nouvelle afin d'y transférer les utilisateurs.
                            Cette action peut engendrer des coûts supplémentaires.
                        </DialogContentText>
                        <List sx={{ p: 0 }}>
                            {teams?.data?.map((d, i) => 
                                <ListItem
                                    key={i}
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: scssVar.greyLight,
                                    }}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={() => handleSelectNewTeam(d)}
                                            checked={state.newTeam.team_id === d.team_id}
                                            inputProps={{ 'aria-labelledby': "checkbox-new-team-selected" }}
                                            sx={{
                                                color: scssVar.grey,
                                                '&.Mui-checked': {
                                                    color: scssVar.primary
                                                },
                                            }}
                                        />
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{
                                            bgcolor: scssVar.primaryLighter,
                                            color: "black"
                                        }}>
                                            <i className="iconoir-building" style={{ fontSize: 24 }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={d.team_name} 
                                        secondary={
                                            <span style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexWrap: "wrap"
                                            }}>
                                                <span>
                                                    {d.users.length} utilisateur{d.users.length > 1 ? "s" : ""}
                                                </span>
                                                {state.newTeam.team_id === d.team_id && d.team_id !== team.team_id ? (
                                                    <span style={{
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <i
                                                            className="iconoir-arrow-right"
                                                            style={{
                                                                marginRight: 2
                                                            }}
                                                        ></i>
                                                        {state.newTeam.users.length + usersCount} utilisateur{state.newTeam.users.length + usersCount > 1 ? "s" : ""}
                                                    </span>
                                                ): null}
                                            </span>
                                        } 
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontFamily: scssVar.fontFamily
                                            },
                                            "& .MuiListItemText-primary": {
                                                color: "black",
                                                fontWeight: 700
                                            },
                                        }}
                                    />
                                </ListItem>
                            )}
                        </List>
                        {state.surfacture > 0 && (
                            <Typography sx={{
                                color: scssVar.danger,
                                fontFamily: scssVar.fontFamily,
                                fontSize: 14,
                                mt: 2
                            }}>
                                Cette action engendrera un coût supplémentaire de {(state.surfacture * additionalPrice).toFixed(2)}€ par mois.
                                Le montant proratisé de {(state.surfacture * proraPrice).toFixed(2)}€, pour le mois en cours, sera reporté sur votre prochaine facture.
                            </Typography>
                        )}
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        loading={loading}
                        disabled={
                            loading 
                            || (usersCount > 0 && state.newTeam.team_id === team.team_id)
                        }
                        onClick={handleDelete}
                        sx={{
                            width: 150
                        }}
                    >
                        {usersCount > 0 ? "Enregistrer" : "Supprimer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteTeam