import { Box, Grid, IconButton } from "@mui/material"
import { Fragment, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FieldControl from "../../../../common/components/FieldControl"
import { updateEstimation } from "../../actions"
import CostInfo from "./CostInfo"
import StepTitle from "./StepTitle"
import { scssVar } from "../../../../app/scssVar"

export const periodicityChoices = [
    { label: "Mensuel", value: "month" },
    { label: "Annuel", value: "year" },
]

const initialState = {
    showInfos: true
}

const Step11Form = ({ stepName, noProgressBarDisplay }) => {
    const timeout = useRef(null)
    const dispatch = useDispatch()
    const estimation = useSelector(state => state.estimate.estimation)
    const periodicity = useSelector(state => state.estimate.periodicity)
    const working = useSelector(state => state.estimate.working)
    const [state, setState] = useState(initialState)

    // console.log("charge_copro_mois", estimation.charge_copro_mois)
    // console.log("mois", estimation.charge_copro_mois_saisie)
    // console.log("an", estimation.charge_copro_an_saisie)

    // console.log("travaux_entretien_mois", estimation.travaux_entretien_mois)
    // console.log("mois", estimation.travaux_entretien_mois_saisie)
    // console.log("an", estimation.travaux_entretien_an_saisie)

    // console.log("taxe_fonciere_mois", estimation.taxe_fonciere_mois)
    // console.log("mois", estimation.taxe_fonciere_mois_saisie)
    // console.log("an", estimation.taxe_fonciere_an_saisie)

    const onUpdateEstimation = (delay) => {
        timeout.current = setTimeout(() => {
            dispatch(updateEstimation(stepName))
        }, [delay])
    }

    const toggleInfos = () => {
        setState({ ...state, showInfos: !state.showInfos })
    }

    const handleChangePeriodicity = (name, newValue) => {
        dispatch({ type: "estimate/set_periodicity", payload: newValue })
    }

    const handleChangeButtonCheckbox = (name, value) => {
        clearTimeout(timeout.current)

        dispatch({
            type: "estimate/set_estimation_costs_chauffage_compris_dans_copro",
            payload: { name, value }
        })

        onUpdateEstimation(0)
    }

    const handleChange = (e) => {
        clearTimeout(timeout.current)

        const target = e.target
        const name = target.name
        const value = target.value

        dispatch({
            type: "estimate/set_estimation_costs_value",
            payload: { name, value: Number(value) }
        })

        onUpdateEstimation(1000)
    }

    // console.log(estimation.charge_copro_chauffage)
    // console.log(estimation.charge_copro_chauffage_saisie)

    return (
        <Fragment>
            <StepTitle
                title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                        Dépassez la notion du prix au m²
                        <IconButton onClick={toggleInfos}>
                            <i className="iconoir-info-empty" style={{ fontSize: 20, color: scssVar.black }}></i>
                        </IconButton>
                    </span>
                }
                subTitle="Appréciez le budget d’usage via l’impact des charges"
                step={stepName} 
                isCostDisplay
                noProgressBarDisplay={noProgressBarDisplay}
            />
            <div style={{ marginBottom: 50 }} />
            <CostInfo show={state.showInfos} onClose={toggleInfos} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Périodicité"
                        type="checkbox"
                        disallowDeselect
                        choices={periodicityChoices}
                        selectedValue={periodicity}
                        onChange={handleChangePeriodicity}
                        xs={6}
                    />
                </Grid>
                <Grid item xs={0} md={6} sx={{ display: { xs: "none", md: "block" } }}>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <FieldControl 
                            label="Charge de copropriété" 
                            isSuccess={periodicity === "month" ? estimation.charge_copro_mois : estimation.charge_copro_an}
                            onChange={handleChange}
                            name={periodicity === "month" ? "charge_copro_mois" : "charge_copro_an"}
                            value={periodicity === "month" ? (estimation.charge_copro_mois || "") : (estimation.charge_copro_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Chauffage compris dans les charges de copropriété"
                        isSuccess={estimation.charge_copro_chauffage !== null}
                        type="checkbox"
                        disallowDeselect
                        choices={[
                            { label: "Oui", value: true },
                            { label: "Non", value: false },
                        ]}
                        selectedValue={estimation.charge_copro_chauffage}
                        name="charge_copro_chauffage"
                        onChange={handleChangeButtonCheckbox}
                        disabled={working}
                        xs={6}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        label="Travaux d'entretien" 
                        isSuccess={periodicity === "month" ? estimation.travaux_entretien_mois : estimation.travaux_entretien_an}
                        onChange={handleChange}
                        name={periodicity === "month" ? "travaux_entretien_mois" : "travaux_entretien_an"}
                        value={periodicity === "month" ? (estimation.travaux_entretien_mois || "") : (estimation.travaux_entretien_an || "") }
                        type="number"
                        placeholder="Montant"
                        icon={{ position: "right", icon: "fas fa-euro-sign" }}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        label="Taxe foncière" 
                        isSuccess={periodicity === "month" ? estimation.taxe_fonciere_mois : estimation.taxe_fonciere_an}
                        onChange={handleChange}
                        name={periodicity === "month" ? "taxe_fonciere_mois" : "taxe_fonciere_an"}
                        value={periodicity === "month" ? (estimation.taxe_fonciere_mois || "") : (estimation.taxe_fonciere_an || "") }
                        type="number"
                        placeholder="Montant"
                        icon={{ position: "right", icon: "fas fa-euro-sign" }}
                        disabled={working}
                    />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Step11Form