import { Fragment } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Margin from "../../components/Margin"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputCheckbox from "../../components/InputCheckbox"

const Panneau = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const panneau = useSelector(selectMandatEditor2).mandat_pose_panneau
    const publicite = useSelector(selectMandatEditor2).mandat_publicite

    const handleChange = (e) => {
        const { name, checked } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value: checked }))
        saveMandat(0)
    }

    return (
        <Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px",
                    marginRight: 3
                }}>
                    Pose d’un panneau
                </p>
                <InputCheckbox name="mandat_pose_panneau" checked={panneau || ""} onChange={handleChange} />
            </div>
            <Margin m={3} />
            <div style={{ display: "flex" }}>
                <InputCheckbox name="mandat_publicite" checked={publicite || ""} onChange={handleChange} />
                <p style={{
                    fontSize: 13,
                    lineHeight: "15px",
                    marginLeft: 3,
                }}>
                    Le mandant souhaite que le bien soit mis en publicité immédiatement (sans attendre le délai de 14 jours de réflexion pour démarchage à
                    domicile) selon les dispositions de l’article L 221-25. Par ailleurs
                    <span style={{ fontWeight: 600 }}>
                        {" "}Dans le cadre de la Loi Tracfin{" "}
                    </span>
                    (sur le blanchiment d’argent et le financement du
                    terrorisme), le mandant atteste que les fonds qu’il va récupérer ne serviront pas à des fins illégales. 
                </p>
                {/* <p style={{
                    fontSize: 13,
                    lineHeight: "15px",
                    marginLeft: 3,
                }}>
                    Le mandant souhaite que le bien soit mis en publicité immédiatement. Par ailleurs
                    <span style={{ fontWeight: 600 }}>
                        {" "}Dans le cadre de la Loi Tracfin{" "}
                    </span>
                    (sur le blanchiment d’argent et le financement du
                    terrorisme), le mandant atteste que les fonds qu’il va récupérer ne serviront pas à des fins illégales. 
                </p> */}
            </div>
        </Fragment>
    )
}

export default Panneau