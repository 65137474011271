import { Grid } from "@mui/material"
import { scssVar } from "../../app/scssVar"
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';

const Button = styled(ButtonUnstyled)`
    position: relative;
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    max-width: 400px;
    height: auto;
    padding: 0 5px;
    color: ${scssVar.greyDark};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;

    &:hover {
        border: 1px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.active} {
        border: 2px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const ButtonCheckboxImage = ({
    choices,
    selectedValue,
    name,
    onChange,
    xs,
    sm,
    md,
    lg,
    disabled
}) => {

    const handleClick = (newValue) => {
        const value = selectedValue === newValue ? null : newValue
        onChange(name, value)
    }

    return (
        <Grid container spacing={1}>
            {choices.map((d, i) => 
                <Grid key={i} item xs={xs} sm={sm} md={md} lg={lg}>
                    <Button
                        disabled={disabled}
                        onClick={() => handleClick(d.value)}
                        sx={{
                            color: d.value === selectedValue ? scssVar.success : scssVar.greyDark,
                            bgcolor: d.value === selectedValue ? "#EDF7EF" : "transparent",
                        }}
                    >
                        {d.value === selectedValue && (
                            <i 
                                className="iconoir-check-circled-outline"
                                style={{
                                    position: "absolute",
                                    top: 2,
                                    right: 2,
                                    color: scssVar.success
                                }}
                            ></i>
                        )}
                        {d.label}
                        <div style={{ width: "100%" }}>
                            <img
                                src={d.img}
                                alt={`décoration ${d.label}`}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default ButtonCheckboxImage