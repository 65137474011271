import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getEstimationsOfHousing, getUnfinished } from "../estimate/actions"
import StepsProgress from "../estimate/steps/components/StepsProgress"
import { Box, Grid, Typography } from "@mui/material"
import useStep from "../estimate/hooks/useStep"
import { useEffect } from "react"
import Button from "../../common/components/Button"
import { scssVar } from "../../app/scssVar"
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';

const EvalButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    background-color: ${scssVar.greyLighter};
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid ${scssVar.greyLighter};
    text-align: start;

    &:hover {
        border: 1px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.active} {}

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`

const Unfinished = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const items = useSelector(state => state.estimations.unfinished.items)
    //const loading = useSelector(state => state.estimations.unfinished.loading)
    const error = useSelector(state => state.estimations.unfinished.error)
    const working = useSelector(state => state.estimate.working)
    const loadingDelete = useSelector(state => state.estimations.estimations.loadingDelete)
    const checkedEstimations = useSelector(state => state.estimations.estimations.selected)
    const loadingEstimationsList = useSelector(state => state.estimations.estimations.loading)
    const getStep = useStep()

    useEffect(() => {
        if (!working) {
            dispatch(getUnfinished())
        }
    }, [working, dispatch])

    const loadEstimation = (estimation) => {
        dispatch({ type: "estimate/set_housing", payload: estimation.housing })
        dispatch({ type: "estimate/set_estimation", payload: estimation })
        dispatch({ type: "estimate/set_is_lead_estimation", payload: false })
        dispatch(getEstimationsOfHousing())
        
        navigate(`/estimer/${getStep(estimation.current_step).path}`)
    }

    if (error) {
        return (
            <Box sx={{
                flexGrow: 1
            }}>
                <Typography sx={{
                    fontSize: "34px",
                    fontWeight: 700,
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    mb: "30px",
                    mt: "50px"
                }}>
                    Continuer l'évaluation
                </Typography>
                <span className="has-text-danger">Une erreur est survenue</span>
            </Box>
        )
    }

    if (items.length === 0) {
        return null
    }

    return (
        <Box sx={{
            flexGrow: 1
        }}>
            <Typography sx={{
                fontSize: "34px",
                fontWeight: 700,
                fontFamily: scssVar.fontFamily,
                color: "black",
                mb: "30px",
                mt: "50px"
            }}>
                Continuer l'évaluation
            </Typography>
            <Box sx={{
                maxHeight: "420px",
                overflowY: "auto",
            }}>
                <Grid container spacing={2}>
                    {items.map((item, index) => 
                        <Grid item xs={12} md={6} lg={4} key={index}>
                            <EvalButton
                                onClick={() => loadEstimation(item)} 
                                disabled={working || (loadingDelete && checkedEstimations.includes(item.id)) || loadingEstimationsList}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "8px",
                                    padding: {
                                        xs: "10px",
                                        sm: "10px 50px 10px 10px"
                                    },
                                }}
                            >
                                <Box sx={{
                                    width: "46px",
                                    height: "28px",
                                    backgroundColor: scssVar.primaryLighter,
                                    borderRadius: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: scssVar.primary,
                                    fontSize: "14px",
                                    mb: "8px"
                                }}>
                                    {getStep(item.current_step).progress}%
                                </Box>
                                <StepsProgress 
                                    step={item.current_step} 
                                    noValue 
                                    width="179px"
                                />
                                <Typography sx={{
                                    mt: "5px",
                                    fontFamily: scssVar.fontFamily,
                                    color: "black",
                                    fontWeight: 600
                                }}>
                                    {item.housing.name}
                                </Typography>
                                <Typography sx={{ 
                                    fontFamily: scssVar.fontFamily,
                                    color: scssVar.greyDark,
                                    fontSize: "14px"
                                }}>
                                    {item.housing.adresse
                                        .replace(item.housing.code_postal, "")
                                        .replace(item.housing.nom_commune, "")
                                        .trim()
                                    }
                                </Typography>
                                <Typography sx={{ 
                                    fontFamily: scssVar.fontFamily,
                                    color: scssVar.greyDark,
                                    fontSize: "14px"
                                }}>
                                    {item.housing.code_postal} {item.housing.nom_commune}
                                </Typography>
                            </EvalButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box sx={{
                mt: 3,
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" }
            }}>
                <Button 
                    variant="light"
                    sx={{
                        width: 275,
                        height: 50,
                    }}
                    onClick={() => navigate("/mes-evaluations")}
                >
                    Voir toutes mes évaluations
                </Button>
            </Box>
        </Box>
    )
}

export default Unfinished