import { Box } from "@mui/material"
import { selectApiLongitudinalKpis } from "../../apiOfferSlice"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import { getDateToString } from "../../../../common/utils"
import StatBox from "../../../../common/components/dashboard/StatBox"

// PERIODE
// "start" : début du cycle actuel de facturation
// "end": fin du cycle actuel de facturation

// FACTURE
// 'additionnal_request_amount_ttc' : somme à payer pour les requetes supplémentaires (si le client a dépassé le requests_threshold)
// "total_amount_ttc": somme totale à payer à la fin du cycle de facturation actuel (base_amount + additionnal_request_amount_ttc en gros si tu as bien suivi).

const NextBill = () => {
    const data = useSelector(selectApiLongitudinalKpis).dataBilling

    return (
        <StatBox 
            bgcolor={scssVar.greyLighter}
            icon="iconoir-journal"
            title="Votre prochaine facture"
        >
            <Box sx={{ flexGrow: 1 }}>
                <p style={{ fontSize: 14, color: scssVar.greyDark, marginBottom: 20 }}>
                    du {getDateToString(data.start)} au {getDateToString(data.end)}
                </p>

                <p style={{ fontSize: 40, fontWeight: 800, marginBottom: 15 }}>
                    {data.total_amount_ttc || "0"} € <span style={{ fontSize: 10 }}>TTC</span>
                </p>
                
                <p style={{ 
                    fontSize: 14, 
                    lineHeight: "17px", 
                    width: "100%", 
                    padding: "7px", 
                    background: scssVar.primaryLighter, 
                    color: scssVar.primary,
                    borderRadius: "3px",
                    marginBottom: 15
                }}>
                    dont <span style={{ fontWeight: 600 }}>{data.additionnal_request_amount_ttc || "0"} € TTC</span> pour les requêtes supplémentaires
                </p>

                <p style={{ color: scssVar.greyDark, fontSize: 14, lineHeight: "17px" }}>
                    Somme à payer à la fin du cycle de facturation actuel
                </p>
            </Box>
        </StatBox>
    )
}

export default NextBill