import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import loaderGif from "../../../common/assets/loader.gif"
import EstimationsItem from "./EstimationsItem"

const EstimationsItems = () => {
    const items = useSelector(state => state.estimations.estimations.filtered)
    const loading = useSelector(state => state.estimations.estimations.loading)

    return (
        <Box>
            {loading ? (
                <Box sx={{
                    width: { xs: "50px", sm: "100px" },
                    margin: "0 auto",
                    textAlign: "center",
                }}>
                    <img 
                        src={loaderGif}
                        alt="Chargement..."
                        style={{
                            width: "100%",
                        }}
                    />
                </Box>
            ):(
                items.length ? (
                    <Box sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, calc(33.333% - 6.666px))", // 10px grid / 3 * (3 - 1) = 6.666px -> gridGap / nb item * (nb item - 1)
                        gridGap: "10px",
                        "@media (max-width: 1400px)": {
                            gridTemplateColumns: "repeat(2, calc(50% - 5px))", // 10px / 2 * (2 - 1) = 5px
                        },
                        "@media (max-width: 1000px)": {
                            gridTemplateColumns: "repeat(1, 100%)", // 10px / 1 * (1 - 1) = 0px
                        },
                        "@media (max-width: 899px)": {
                            gridTemplateColumns: "repeat(2, calc(50% - 5px))",
                        },
                        "@media (max-width: 700px)": {
                            gridTemplateColumns: "repeat(1, 100%)",
                        }
                    }}>
                        {items.map((d, i) => 
                            <EstimationsItem key={i} estimation={d} />
                        )}
                    </Box>
                ):(
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        textAlign: "center"
                    }}>
                        Aucune évaluation !
                    </Typography>
                )
            )}
        </Box>
    )
}

export default EstimationsItems