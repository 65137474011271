import { useDispatch, useSelector } from "react-redux"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import variables from "../../../app/App.scss"
import { appName } from "../../../common/utils";
import { opinionClosed, opinionFieldChanged, sendUserOpinion } from "../userOpinionSlice";
import Button from "../../../common/components/Button";

const OpinionForm = () => {
    const dispatch = useDispatch()
    const open = useSelector(state => state.userOpinion.open)
    const subject = useSelector(state => state.userOpinion.subject)
    const detail = useSelector(state => state.userOpinion.detail)
    const loading = useSelector(state => state.userOpinion.loading)
    const emptySubject = !subject.trim().length
    const emptyDetail = !detail.trim().length
    const emptyField = emptySubject || emptyDetail

    const handleClose = () => {
        dispatch(opinionClosed())
    }

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        dispatch(opinionFieldChanged({ name, value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(sendUserOpinion())
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle sx={{ 
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: variables.fontFamily,
                display: "flex",
                justifyContent: "space-between",
            }}>
                Vous avez un retour à nous faire ?
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    fontSize: 14,
                    fontFamily: variables.fontFamily
                }}>
                    Tous vos avis sont précieux et nous permettent d'améliorer votre expérience {appName}
                </DialogContentText>
                <div className="field" style={{ marginTop: 20 }}>
                    <label 
                        className={`label has-text-weight-normal ${!emptySubject && "has-text-success"}`}
                        style={{ fontSize: 14 }}
                    >
                        Objet du retour
                    </label>
                    <div className="control">
                        <input 
                            className={`input ${!emptySubject && "is-success"}`}
                            value={subject}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                            name="subject"
                            placeholder="Exemple: élément manquant"
                        />
                    </div>
                </div>
                <div className="field">
                    <label 
                        className={`label has-text-weight-normal ${!emptyDetail && "has-text-success"}`}
                        style={{ fontSize: 14 }}
                    >
                        Détails
                    </label>
                    <div className="control">
                        <textarea
                            className={`textarea ${!emptyDetail && "is-success"}`}
                            value={detail}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                            name="detail"
                            placeholder="Détaillez au maximum le retour que vous souhaitez nous envoyer"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="light" onClick={handleClose}>Annuler</Button>
                <Button
                    disabled={loading || emptyField}
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Envoyer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OpinionForm