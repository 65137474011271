import InputDate from "../../../components/InputDate"
import InputText from "../../../components/InputText"
import Margin from "../../../components/Margin"
import SectionTitle from "./SectionTitle"
import { useDispatch, useSelector } from "react-redux"
import { buyOfferActions, selectBuyOfferEditor } from "../buyOfferSlice"

const Engagement = () => {
    const dispatch = useDispatch()
    const buyState = useSelector(selectBuyOfferEditor)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(buyOfferActions.setField({ name, value }))
    }

    return (
        <div>
            <SectionTitle>
                L’ENGAGEMENT DES PARTIES 
            </SectionTitle>

            <Margin m={2} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>L’acquéreur s’engage au versement de la somme de</p>
                <InputText 
                    value={buyState.versement_montant}
                    name="versement_montant"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
                <p style={{ marginLeft: 3 }}>€ lors de la promesse de</p>
            </div>

            <p>
                vente, au titre du séquestre (<span style={{ fontWeight: 600 }}>et 300 euros d’avance sur frais de mutation pour l’étude notariale</span>),
                ce par virement sur compte séquestre du notaire, au plus tard la veille de la signature de la promesse
            </p>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>de vente, dont la date doit être au plus tard le</p>
                <InputDate 
                    value={buyState.versement_date}
                    name="versement_date"
                    onChange={handleChange}
                />
            </div>

            <Margin m={7} />
            
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Fait à</p>
                <InputText 
                    value={buyState.document_lieu}
                    name="document_lieu"
                    onChange={handleChange}
                    style={{ width: 250 }}
                />
                <p style={{ marginRight: 3, marginLeft: 3 }}>le</p>
                <InputDate 
                    value={buyState.document_date}
                    name="document_date"
                    onChange={handleChange}
                />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "50%" }}>
                    <p>
                        Cette offre est valable
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginRight: 3, fontWeight: 600 }}>Jusqu'au</p>
                        <InputDate 
                            value={buyState.document_validite}
                            name="document_validite"
                            onChange={handleChange}
                        />
                    </div>
                    <p style={{ fontWeight: 600, fontSize: 13 }}><u><i>Signature(s) ACQUEREUR(S)</i></u></p>
                    <p style={{ fontSize: 13 }}><i>Précédées de la mention <span style={{ fontWeight: 600 }}><u>« bon pour offre »</u></span></i></p>
                </div>

                <div style={{ width: "50%" }}>
                    <p>Offre acceptée à la condition qu'une promesse soit</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginRight: 3, fontWeight: 600 }}><u>signée avant le :</u></p>
                        <InputDate 
                            value={buyState.promesse_date}
                            name="promesse_date"
                            onChange={handleChange}
                        />
                    </div>
                    <p style={{ fontWeight: 600, fontSize: 13 }}>
                        <i><u>Signature(s) VENDEUR(S), propriétaire(s) du bien</u></i>
                    </p>
                    <p style={{ fontSize: 13 }}>
                        <i>Précédées de la mention <span style={{ fontWeight: 600 }}><u>« offre acceptée le »</u></span></i>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Engagement