import { Box, Typography } from "@mui/material"
import { scssVar } from "../../app/scssVar"
import illustr from "../../common/assets/discover_digipi_illustr.png"
import { ReactComponent as Digipi } from "../../common/assets/digipi.svg"
import DOMPurify from 'dompurify'
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { appCompany, appName } from "../../common/utils"
import Button from "../../common/components/Button"
import { getDiscoverDigipiContent } from "./fetchedContentSlice"

const DiscoverDigipi = () => {
    const dispatch = useDispatch()
    const item = useSelector(state => state.fetchedContent.discoverDigipi.html)
    const loading = useSelector(state => state.fetchedContent.discoverDigipi.loading)
    const error = useSelector(state => state.fetchedContent.discoverDigipi.error)
    const ref = useRef(null)

    useEffect(() => {
        dispatch(getDiscoverDigipiContent())
            .then(() => {
                if (item !== "") {
                    ref.current.innerHTML = DOMPurify.sanitize(item)
                }
            })
    }, [
        dispatch,
        item
    ])

    return (
        <Box sx={{ position: "relative" }}>
            <Box sx={{
                bgcolor: scssVar.greyLighter,
                borderRadius: "16px",
                p: "65px 100px",
                width: "89%",
                "@media (max-width: 1300px)": {
                    p: "30px 30px"
                },
                "@media (max-width: 900px)": {
                    p: "20px",
                    width: "100%"
                },
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 4,
                    "@media (max-width: 599px)": {
                        flexDirection: "column"
                    }
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        fontWeight: 700,
                        fontSize: "24px",
                        color: "black",
                        mr: 2,
                        "@media (max-width: 599px)": {
                            mr: 0,
                            mb: 1,
                            textAlign: "center",
                        }
                    }}>
                        {appName} : une appli by
                    </Typography>
                    <Digipi />
                </Box>
                <Box sx={{ 
                    width: "630px",
                    "@media (max-width: 1300px)": {
                        width: "396px"
                    },
                    "@media (max-width: 900px)": {
                        width: "53%"
                    },
                    "@media (max-width: 800px)": {
                        width: "100%"
                    }
                }}>
                    {loading || error ? (
                        <Box sx={{
                            height: "270px",
                            display: "flex",
                            alignItems: "center",
                            "@media (max-width: 1300px)": {
                                height: "396px"
                            },
                            "@media (max-width: 800px)": {
                                height: "290px",
                            },
                            "@media (max-width: 599px)": {
                                justifyContent: "center",
                            }
                        }}>
                            {loading ? (
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    fontWeight: 700,
                                    color: scssVar.black,
                                }}>
                                    Chargement...
                                </Typography>
                            ): error && (
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    fontWeight: 700,
                                    color: scssVar.danger,
                                }}>
                                    Une erreur est survenue
                                </Typography>
                            )}
                        </Box>
                    ):(
                        <div
                            ref={ref}
                            style={{
                                color: "black",
                                marginBottom: "40px",
                            }}
                        >
                        </div>
                    )}
                    <Box sx={{
                        "@media (max-width: 599px)": {
                            display: "flex",
                            justifyContent: "center"
                        }
                    }}>
                        <a
                            href="https://www.digipi.fr/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button>
                                Découvrir {appCompany}
                                <i 
                                    className="iconoir-open-new-window"
                                    style={{
                                        fontSize: 24,
                                        marginLeft: 10
                                    }}
                                ></i>
                            </Button>
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                position: "absolute",
                top: 30,
                right: 0,
                width: 358,
                "@media (max-width: 1300px)": {
                    top: 70,
                },
                "@media (max-width: 1000px)": {
                    top: 100,
                    width: 300,
                },
                "@media (max-width: 800px)": {
                    display: "none"
                }
            }}>
                <img
                    src={illustr}
                    alt="Discover DigiPi"
                    style={{
                        width: "100%"
                    }}
                />
            </Box>
        </Box>
    )
}

export default DiscoverDigipi