import { Box, Checkbox, SwipeableDrawer, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import Logo from "../../../common/components/Logo"
import { appName } from "../../../common/utils"
import BottomBar from "../../pdfReader/components/BottomBar"
import Link from "../../pdfReader/components/Link"
import Reader from "../../pdfReader/components/Reader"
import Title from "../../pdfReader/components/Title"
import Zoom from "../../pdfReader/components/Zoom"
import usePdfDoc from "../../pdfReader/hooks/usePdfDoc"
import usePdfState from "../../pdfReader/hooks/usePdfState"

const CguCheckbox = ({
    checked,
    onChange,
    onAccept
}) => {
    const firstPdf = usePdfState()
    const secondPdf = usePdfState()
    const getPdfDoc = usePdfDoc()
    const [state, setState] = useState({
        open: false
    })

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleAccept = () => {
        onAccept()
        setState({ ...state, open: false })
    }

    return (
        <Fragment>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: { xs: "flex-start", sm: "flex-start" },
                width: "fit-content"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        name="cgu"
                        required
                        sx={{
                            p: 0,
                            mr: 1,
                            color: scssVar.grey,
                            '&.Mui-checked': {
                                color: scssVar.primary
                            },
                        }}
                    />
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        fontSize: "14px",
                        color: scssVar.success,
                    }}>
                        J’accepte les{" "}
                        <Box
                            component="span"
                            onClick={handleOpen}
                            sx={{
                                fontSize: 14,
                                color: scssVar.success,
                                textDecoration: "underline",
                                cursor: "pointer",
                                transition: "all .3s ease",
                                "&:hover": {
                                    color: scssVar.primary
                                }
                            }}
                        >
                            termes et conditions d’utilisation d’{appName}
                        </Box>
                    </Typography>
                </Box>
            </Box>
            <SwipeableDrawer
                open={state.open}
                anchor="bottom"
                onClose={handleClose}
                onOpen={() => {}}
                disableSwipeToOpen
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: "white",
                        backgroundImage: "none",
                        width: "100%",
                        zIndex: 1301,
                    }
                }}
            >
                <BottomBar>
                    <Button
                        variant="light"
                        type="button"
                        onClick={handleClose}
                        sx={{
                            width: 150,
                            mr: "10px"
                        }}
                    >
                        Fermer
                    </Button>
                    <Button
                        onClick={handleAccept}
                        type="button"
                        sx={{
                            width: 150,
                        }}
                    >
                        Accepter
                    </Button>
                </BottomBar>
                <Zoom
                    onZoomIn={firstPdf.handleZoomIn}
                    onZoomOut={firstPdf.handleZoomOut}
                    scale={firstPdf.pdfState.scale}
                    loadingDoc={firstPdf.pdfState.loadingDoc || secondPdf.pdfState.loadingDoc}
                    loadingPage={firstPdf.pdfState.loadingPage || secondPdf.pdfState.loadingPage}
                />
                <div
                    role="presentation" 
                    style={{
                        height: window.innerHeight,
                        overflowY: "scroll",
                        width: "100%",
                    }}
                >
                    <Box sx={{ 
                        pt: { xs: 3, sm: 8 }, 
                        pb: 21
                    }}>
                        <Box sx={{
                            maxWidth: "1341px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px",
                                sm: "0 35px",
                                lg: "0 60px"
                            }
                        }}>
                            <Logo sx={{ wrapper: { mb: 3 } }} />
                            <Title>{getPdfDoc("cgu").title}</Title>
                            <Link href={getPdfDoc("cgu").src} />
                        </Box>
                        <Box sx={{ m: 1 }} />
                        <Reader
                            onDocumentLoadSuccess={firstPdf.handleDocumentLoadSuccess}
                            onPageLoadSuccess={firstPdf.handlePageLoadSuccess}
                            src={getPdfDoc("cgu").src}
                            loadingPage={firstPdf.pdfState.loadingPage}
                            numberOfPages={firstPdf.pdfState.numPages}
                            scale={firstPdf.pdfState.scale}
                        />
                        <Box sx={{ m: 3 }} />
                        <Box sx={{
                            maxWidth: "1341px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px",
                                sm: "0 35px",
                                lg: "0 60px"
                            }
                        }}>
                            <Title>{getPdfDoc("cgv").title}</Title>
                            <Link href={getPdfDoc("cgv").src} />
                        </Box>
                        <Box sx={{ m: 1 }} />
                        <Reader
                            onDocumentLoadSuccess={secondPdf.handleDocumentLoadSuccess}
                            onPageLoadSuccess={secondPdf.handlePageLoadSuccess}
                            src={getPdfDoc("cgv").src}
                            loadingPage={secondPdf.pdfState.loadingPage}
                            numberOfPages={secondPdf.pdfState.numPages}
                            scale={firstPdf.pdfState.scale}
                        />
                    </Box>
                </div>
            </SwipeableDrawer>
        </Fragment>
    )
}

export default CguCheckbox