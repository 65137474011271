import { Grid } from "@mui/material"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import FieldControl from "../../../../common/components/FieldControl"
import { updateEstimation } from "../../actions"
import StepTitle from "./StepTitle"
import { selectEstimation, selectIsWorking } from "../../selectors"

// Pour la rétro compatibilité (année construction)
const RetrocompatConstructionOption = () => {
    const value = useSelector(selectEstimation).annee_construction

    const isOldValue = value
        && !choices_annee_construction
            .map(d => d.value)
            .includes(value)

    if (isOldValue) {
        const found = [
            { label: "Inconnue", value: "inconnue" },
            { label: "Neuf - 0 an", value: "neuf - 0 an" },
            { label: "Récent - 0 à 10 ans", value: "récent - 0 à 10 ans" },
            { label: "10 à 40 ans", value: "10 à 40 ans" },
            { label: "41 à 60 ans", value: "41 à 60 ans" },
            { label: "61 à 75 ans", value: "61 à 75 ans" },
            { label: "Art déco - années 30", value: "art déco - années 30" },
            { label: "1880-1920", value: "1880-1920" },
            { label: "Avant 1880", value: "avant 1880" },
        ]
        .find(d => d.value === value)

        return (
            <option disabled value={found.value}>
                {found.label}
            </option>
        )
    }

    return null
}

const choices_annee_construction = [
    { label: "Inconnue", value: "inconnue" },
    { label: "Neuf", value: "neuf" },
    { label: "Après 2020", value: "apres 2020" },
    { label: "2010-2020", value: "2010-2020" },
    { label: "2000-2010", value: "2000-2010" },
    { label: "1990-2000", value: "1990-2000" },
    { label: "1980-1990", value: "1980-1990" },
    { label: "1970-1980", value: "1970-1980" },
    { label: "1960-1970", value: "1960-1970" },
    { label: "1940-1960", value: "1940-1960" },
    { label: "1920-1940", value: "1920-1940" },
    { label: "1900-1920", value: "1900-1920" },
    { label: "Avant 1900", value: "avant 1900" },
]

export const choices_etat_du_bien = [
    { label: "De gros travaux à prévoir", value: "de gros travaux à prévoir" },
    { label: "À rénover", value: "à rénover" },
    { label: "À rafraîchir", value: "à rafraîchir" },
    { label: "Standard", value: "standard" },
    { label: "Bon état", value: "bon état" },
    { label: "Très bon état général", value: "très bon état général" },
    { label: "Refait à neuf", value: "refait à neuf" },
]

const choices_gse = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
    { label: "F", value: "F" },
    { label: "G", value: "G" },
    { label: "NC", value: "NC" },
]

const choices_dpe = [
    { label: "A", value: "a" },
    { label: "B", value: "b" },
    { label: "C", value: "c" },
    { label: "D", value: "d" },
    { label: "E", value: "e" },
    { label: "F", value: "f" },
    { label: "G", value: "g" },
    { label: "NC", value: "nc" },
]

const Step04Form = ({ stepName, noProgressBarDisplay }) => {
    const dispatch = useDispatch()
    const estimation = useSelector(selectEstimation)
    const working = useSelector(selectIsWorking)

    const handleChangeButtonCheckbox = (name, value) => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        dispatch(updateEstimation(stepName))
    }

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        dispatch(updateEstimation(stepName))
    }

    // console.log("Etat du bien", estimation.etat_du_bien)
    // console.log("Etat du bien défaut", estimation.etat_du_bien_default)
    // console.log("Année de construction", estimation.annee_construction)
    // console.log("Année de construction défaut", estimation.annee_construction_default)
    // console.log("GSE", estimation.gse)
    // console.log("DPE", estimation.dpe)
    // console.log("DPE default", estimation.dpe_default)

    // console.log("charge_copro_mois", estimation.charge_copro_mois)
    // console.log("charge_copro_an", estimation.charge_copro_an)
    // console.log("charge_copro_an défaut", estimation.charge_copro_an_default)

    return (
        <Fragment>
            <StepTitle 
                title="Etat du bien" 
                step={stepName} 
                noProgressBarDisplay={noProgressBarDisplay}
            />
            <div style={{ marginBottom: 50 }} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Votre appréciation"
                        isSuccess={estimation.etat_du_bien}
                        type="select"
                        value={estimation.etat_du_bien || ""}
                        onChange={handleChange}
                        name="etat_du_bien"
                        disabled={working}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                {choices_etat_du_bien.map((d, i) =>
                                    <option
                                        key={i}
                                        value={d.value}
                                    >
                                        {d.label}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid> 
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Année de construction"
                        isSuccess={estimation.annee_construction}
                        type="select"
                        value={estimation.annee_construction || ""}
                        onChange={handleChange}
                        name="annee_construction"
                        disabled={working}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                <RetrocompatConstructionOption />
                                {choices_annee_construction.map((d, i) => 
                                    <option 
                                        key={i} 
                                        value={d.value}
                                    >
                                        {d.label}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Emission de Gaz à Effet de Serre (GSE)"
                        isSuccess={estimation.gse !== null}
                        type="checkbox"
                        choices={choices_gse}
                        selectedValue={estimation.gse}
                        name="gse"
                        onChange={handleChangeButtonCheckbox}
                        xs={(12/8)*2} 
                        md={12/8}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Diagnostic de Performance Energétique (DPE)"
                        isSuccess={estimation.dpe !== null}
                        type="checkbox"
                        choices={choices_dpe}
                        selectedValue={estimation.dpe}
                        name="dpe"
                        onChange={handleChangeButtonCheckbox}
                        xs={(12/8)*2} 
                        md={12/8}
                        disabled={working}
                    />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Step04Form