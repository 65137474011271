import { useMemo } from "react"
import { m2 } from "../../../common/utils"

const useCharacteristicItems = (estimation) => {
    const items = useMemo(() => {
        return [
            {
                category: "equip",
                bg: "rgba(51, 175, 138, 0.12)",
                name: "avec_ascenseur",
                checked: estimation.avec_ascenseur,
                text: "Ascenseur",
                icon: "iconoir-closet",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 14L8 14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 14L15 14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 2H20.4C20.7314 2 21 2.26863 21 2.6V21.4C21 21.7314 20.7314 22 20.4 22H12M12 2H3.6C3.26863 2 3 2.26863 3 2.6V21.4C3 21.7314 3.26863 22 3.6 22H12M12 2V22" stroke="currentColor" strokeWidth="1.5"/>
                    </svg>
                ,                
                displayed: true,
                value: estimation.avec_ascenseur,
                unit: null
            },
            {
                category: "ext",
                bg: "#F5F5FF",
                name: "avec_balcon",
                checked: estimation.avec_balcon,
                text: "Balcon",
                icon: "iconoir-building",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 9.01L10.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 9.01L14.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 13.01L10.01 12.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 13.01L14.01 12.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 17.01L10.01 16.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 17.01L14.01 16.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 20.4V5.6C6 5.26863 6.26863 5 6.6 5H12V3.6C12 3.26863 12.2686 3 12.6 3H17.4C17.7314 3 18 3.26863 18 3.6V20.4C18 20.7314 17.7314 21 17.4 21H6.6C6.26863 21 6 20.7314 6 20.4Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                //displayed: housing.is_flat,
                displayed: true,
                value: estimation.avec_balcon,
                unit: null
            },
            {
                category: "ext",
                bg: "#F5F5FF",
                name: "avec_terrasse",
                checked: estimation.avec_terrasse,
                text: "Terrasse",
                icon: "iconoir-cloud-sunny",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 13C4.33333 13 1 14 1 18C1 22 4.33333 23 6 23H18C19.6667 23 23 22 23 18C23 14 19.6667 13 18 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19 9L20 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 2V1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.5 3.5L17.5 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.5 3.5L6.5 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 9L5 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.surface_terrasse,
                unit: m2
            },
            {
                category: "depend",
                bg: "#FFF7EB",
                name: "avec_stationnement",
                checked: estimation.avec_stationnement,
                text: "Stationnement",
                icon: "iconoir-parking",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 15.5V12.7M10 12.7C10.4762 12.7 11.7143 12.7 12.8571 12.7C13.5714 12.7 15 12.7 15 10.6C15 8.5 13.5714 8.5 12.8571 8.5L10 8.5V12.7Z" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round"/>
                        <circle cx="12" cy="12" r="10" stroke="currentColor" />
                    </svg>,
                displayed: true,
                value: estimation.avec_stationnement,
                unit: null
            },
            {
                category: "depend",
                bg: "#FFF7EB",
                name: "avec_garage",
                checked: estimation.avec_garage,
                text: "Garage",
                icon: "iconoir-garage",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 20H3V6L12 4L21 6V20H18M6 20H18M6 20V16M18 20V16M6 12V8L18 8V12M6 12L18 12M6 12V16M18 12V16M6 16H18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_garage,
                unit: null
            },
            {
                category: "ext",
                bg: "#F5F5FF",
                name: "avec_jardin",
                checked: estimation.avec_jardin,
                text: "Jardin",
                icon: "iconoir-cloud-sunny",
                iconSvg: <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 13C4.33333 13 1 14 1 18C1 22 4.33333 23 6 23H18C19.6667 23 23 22 23 18C23 14 19.6667 13 18 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M19 9L20 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 2V1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18.5 3.5L17.5 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.5 3.5L6.5 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4 9L5 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                //displayed: housing.is_flat,
                displayed: true,
                value: estimation.surface_jardin,
                unit: m2
            },
            {
                category: "depend",
                bg: "#FFF7EB",
                name: "avec_grenier",
                checked: estimation.avec_grenier,
                text: "Grenier",
                icon: "iconoir-box-iso",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.6954 7.18536L11.6954 11.1854L12.3046 9.81464L3.3046 5.81464L2.6954 7.18536ZM12.75 21.5V10.5H11.25V21.5H12.75ZM12.3046 11.1854L21.3046 7.18536L20.6954 5.81464L11.6954 9.81464L12.3046 11.1854Z" fill="currentColor"/>
                        <path d="M3 17.1101V6.88992C3 6.65281 3.13964 6.43794 3.35632 6.34164L11.7563 2.6083C11.9115 2.53935 12.0885 2.53935 12.2437 2.6083L20.6437 6.34164C20.8604 6.43794 21 6.65281 21 6.88992V17.1101C21 17.3472 20.8604 17.5621 20.6437 17.6584L12.2437 21.3917C12.0885 21.4606 11.9115 21.4606 11.7563 21.3917L3.35632 17.6584C3.13964 17.5621 3 17.3472 3 17.1101Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.5 4.5L16.1437 8.34164C16.3604 8.43794 16.5 8.65281 16.5 8.88992V12.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_grenier,
                unit: null
            },
            {
                category: "install",
                bg: "rgba(164, 204, 115, 0.15)",
                name: "avec_piscine",
                checked: estimation.avec_piscine,
                text: "Piscine",
                icon: "iconoir-sea-waves",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 10C5.48276 10 7.34483 7 7.34483 7C7.34483 7 9.2069 10 11.6897 10C14.1724 10 16.6552 7 16.6552 7C16.6552 7 19.1379 10 21 10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 17C5.48276 17 7.34483 14 7.34483 14C7.34483 14 9.2069 17 11.6897 17C14.1724 17 16.6552 14 16.6552 14C16.6552 14 19.1379 17 21 17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_piscine,
                unit: null
            },
            {
                category: "ext",
                bg: "#F5F5FF",
                name: "avec_rooftop",
                checked: estimation.avec_rooftop,
                text: "Rooftop",
                icon: "iconoir-move-up",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C10.8954 22 10 21.1046 10 20C10 18.8954 10.8954 18 12 18C13.1046 18 14 18.8954 14 20C14 21.1046 13.1046 22 12 22Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 15L12 2M12 2L15 5M12 2L9 5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.surface_rooftop,
                unit: m2
            },
            {
                name: "avec_salledejeux",
                checked: estimation.avec_salledejeux,
                text: "Salle de jeux",
                icon: "iconoir-bowling-ball",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.5 8C11.7761 8 12 7.77614 12 7.5C12 7.22386 11.7761 7 11.5 7C11.2239 7 11 7.22386 11 7.5C11 7.77614 11.2239 8 11.5 8Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.5 11C7.77614 11 8 10.7761 8 10.5C8 10.2239 7.77614 10 7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.5 13C11.7761 13 12 12.7761 12 12.5C12 12.2239 11.7761 12 11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_salledejeux,
                unit: null
            },
            {
                name: "avec_salledesport",
                checked: estimation.avec_salledesport,
                text: "Salle de sport",
                icon: "iconoir-gym",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.4 7H4.6C4.26863 7 4 7.26863 4 7.6V16.4C4 16.7314 4.26863 17 4.6 17H7.4C7.73137 17 8 16.7314 8 16.4V7.6C8 7.26863 7.73137 7 7.4 7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.4 7H16.6C16.2686 7 16 7.26863 16 7.6V16.4C16 16.7314 16.2686 17 16.6 17H19.4C19.7314 17 20 16.7314 20 16.4V7.6C20 7.26863 19.7314 7 19.4 7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 14.4V9.6C1 9.26863 1.26863 9 1.6 9H3.4C3.73137 9 4 9.26863 4 9.6V14.4C4 14.7314 3.73137 15 3.4 15H1.6C1.26863 15 1 14.7314 1 14.4Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M23 14.4V9.6C23 9.26863 22.7314 9 22.4 9H20.6C20.2686 9 20 9.26863 20 9.6V14.4C20 14.7314 20.2686 15 20.6 15H22.4C22.7314 15 23 14.7314 23 14.4Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 12H16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_salledesport,
                unit: null
            },
            {
                category: "install",
                bg: "rgba(164, 204, 115, 0.15)",
                name: "avec_spa",
                checked: estimation.avec_spa,
                text: "SPA",
                icon: "iconoir-yoga",
                iconSvg: 
                <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5714 15.0036L15.4286 16.8486C15.4286 16.8486 19.2857 17.6678 19.2857 19.6162C19.2857 21 17.5714 21 17.5714 21H13L10.75 19.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.42864 15.0036L8.5715 16.8486C8.5715 16.8486 4.71436 17.6678 4.71436 19.6162C4.71436 21 6.42864 21 6.42864 21H8.50007L10.7501 19.75L13.5001 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3 15.9261C3 15.9261 5.14286 15.4649 6.42857 15.0036C7.71429 8.54595 11.5714 9.00721 12 9.00721C12.4286 9.00721 16.2857 8.54595 17.5714 15.0036C18.8571 15.4649 21 15.9261 21 15.9261" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                displayed: true,
                value: estimation.avec_spa,
                unit: null
            },
            {
                category: "depend",
                bg: "#FFF7EB",
                name: "avec_cave",
                checked: estimation.avec_cave,
                text: "Cave",
                icon: "iconoir-move-down",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6C10.8954 6 10 5.10457 10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4C14 5.10457 13.1046 6 12 6Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 9L12 22M12 22L15 19M12 22L9 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_cave,
                unit: null
            },
            {
                category: "depend",
                bg: "#FFF7EB",
                name: "avec_cabanon",
                checked: estimation.avec_cabanon,
                text: "Cabanon",
                icon: "iconoir-home-simple-door",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 21H7C4.79086 21 3 19.2091 3 17V10.7076C3 9.30887 3.73061 8.01175 4.92679 7.28679L9.92679 4.25649C11.2011 3.48421 12.7989 3.48421 14.0732 4.25649L19.0732 7.28679C20.2694 8.01175 21 9.30887 21 10.7076V17C21 19.2091 19.2091 21 17 21H15M9 21V17C9 15.3431 10.3431 14 12 14V14C13.6569 14 15 15.3431 15 17V21M9 21H15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_cabanon,
                unit: null
            },
            {
                category: "install",
                bg: "rgba(164, 204, 115, 0.15)",
                name: "avec_teletravail",
                checked: estimation.avec_teletravail,
                text: "Télétravail",
                icon: "iconoir-laptop",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.2 14.2222V4C3.2 2.89543 4.09543 2 5.2 2H18.8C19.9046 2 20.8 2.89543 20.8 4V14.2222M3.2 14.2222H20.8M3.2 14.2222L1.71969 19.4556C1.35863 20.7321 2.31762 22 3.64418 22H20.3558C21.6824 22 22.6414 20.7321 22.2803 19.4556L20.8 14.2222" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M11 19L13 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.localisation_teletravail,
                unit: null
            },
            {
                category: "env",
                bg: "rgba(98, 179, 224, 0.12)",
                name: "avec_mitoyennete",
                checked: estimation.avec_mitoyennete,
                text: "Mitoyenneté",
                icon: "iconoir-city",
                iconSvg: 
                <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 9.01L7.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11 9.01L11.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 13.01L7.01 12.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11 13.01L11.01 12.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 17.01L7.01 16.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11 17.01L11.01 16.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15 21H3.6C3.26863 21 3 20.7314 3 20.4V5.6C3 5.26863 3.26863 5 3.6 5H9V3.6C9 3.26863 9.26863 3 9.6 3H14.4C14.7314 3 15 3.26863 15 3.6V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15M15 21V17M15 9V13M15 13H17M15 13V17M15 17H17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                displayed: true,
                value: estimation.avec_mitoyennete,
                unit: null
            },
            {
                name: "avec_double_vitrage",
                checked: estimation.avec_double_vitrage,
                text: "Double vitrage",
                icon: "iconoir-yoga",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5714 15.0036L15.4286 16.8486C15.4286 16.8486 19.2857 17.6678 19.2857 19.6162C19.2857 21 17.5714 21 17.5714 21H13L10.75 19.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.42864 15.0036L8.5715 16.8486C8.5715 16.8486 4.71436 17.6678 4.71436 19.6162C4.71436 21 6.42864 21 6.42864 21H8.50007L10.7501 19.75L13.5001 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 15.9261C3 15.9261 5.14286 15.4649 6.42857 15.0036C7.71429 8.54595 11.5714 9.00721 12 9.00721C12.4286 9.00721 16.2857 8.54595 17.5714 15.0036C18.8571 15.4649 21 15.9261 21 15.9261" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_double_vitrage,
                unit: null
            },
            {
                name: "avec_tennis",
                checked: estimation.avec_tennis,
                text: "Tennis",
                icon: "iconoir-tennis-ball",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.5716 4.46234C15.905 8.99175 15.9049 14.1847 18.5716 19.5377" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.42834 4.46234C8.09501 8.99175 8.09513 14.1847 5.42834 19.5377" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_tennis,
                unit: null
            },
            {
                category: "ext",
                bg: "#F5F5FF",
                name: "avec_veranda",
                checked: estimation.avec_veranda,
                text: "Véranda",
                icon: "iconoir-home-user",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 9.5L12 4L21.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 21V20C7 17.2386 9.23858 15 12 15V15C14.7614 15 17 17.2386 17 20V21" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_veranda,
                unit: null
            },
            {
                category: "env",
                bg: "rgba(98, 179, 224, 0.12)",
                name: "avec_vue_exceptionnelle",
                checked: estimation.avec_vue_exceptionnelle,
                text: "Vue exceptionnelle",
                icon: "iconoir-media-image",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 16L10 13L21 18" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true,
                value: estimation.avec_vue_exceptionnelle,
                unit: null
            },
            {
                category: "install",
                bg: "rgba(164, 204, 115, 0.15)",
                name: "avec_home_cinema",
                checked: estimation.avec_home_cinema,
                text: "Home-cinéma",
                icon: "iconoir-modern-tv",
                displayed: true,
                value: estimation.avec_home_cinema,
                unit: null
            },
            {
                category: "install",
                bg: "rgba(164, 204, 115, 0.15)",
                name: "avec_salle_activite",
                checked: estimation.avec_salle_activite,
                text: "Salle d’activités",
                icon: "iconoir-bowling-ball",
                displayed: true,
                value: estimation.avec_salle_activite,
                unit: null
            },
            {
                category: "equip",
                bg: "rgba(51, 175, 138, 0.12)",
                name: "avec_alarme",
                checked: estimation.avec_alarme,
                text: "Alarme",
                icon: "iconoir-antenna-signal",
                displayed: true,
                value: estimation.avec_alarme,
                unit: null
            },
            {
                category: "equip",
                bg: "rgba(51, 175, 138, 0.12)",
                name: "avec_climatisation",
                checked: estimation.avec_climatisation,
                text: "Climatisation",
                icon: "iconoir-media-image",
                displayed: true,
                value: estimation.avec_climatisation,
                unit: null
            },
            {
                category: "equip",
                bg: "rgba(51, 175, 138, 0.12)",
                name: "avec_poele_bois",
                checked: estimation.avec_poele_bois,
                text: "Poêle à bois",
                icon: "iconoir-bonfire",
                displayed: true,
                value: estimation.avec_poele_bois,
                unit: null
            },
            {
                category: "env",
                bg: "rgba(98, 179, 224, 0.12)",
                name: "avec_residence_securisee",
                checked: estimation.avec_residence_securisee,
                text: "Résidence sécurisée",
                icon: "iconoir-lock",
                displayed: true,
                value: estimation.avec_residence_securisee,
                unit: null
            },
            {
                category: "env",
                bg: "rgba(98, 179, 224, 0.12)",
                name: "avec_droit_passage",
                checked: estimation.avec_droit_passage,
                text: "Droit de passage",
                icon: "iconoir-check-circled-outline",
                displayed: true,
                value: estimation.avec_droit_passage,
                unit: null
            },
        ]
    }, [
        estimation, 
        //housing.is_flat
    ])

    return items
}

export default useCharacteristicItems