import { useSelector } from "react-redux"
import { getDateToString } from "../../../../common/utils"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"

const Header = () => {
    const createdOn = useSelector(state => state.estimate.housing.created_on)
    const address = useSelector(state => state.estimate.housing.adresse)

    return (
        <div style={{
            backgroundColor: "#f8f8f8",
            height: 160,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "0 70px 25px"
        }}>
            <OrgLogo
                wrapperStyle={{
                    width: 180,
                    height: 93
                }} 
                textStyle={{
                    fontSize: 12
                }}
            />
            <div style={{
                textAlign: "end",
                height: 84,
            }}>
                <p style={{
                    fontSize: 23,
                    fontWeight: 700
                }}>
                    RAPPORT D'ÉVALUATION
                </p>
                <Margin m={3} />
                <p style={{
                    fontSize: 18,
                    fontWeight: 700
                }}>
                    du {getDateToString(createdOn)}
                </p>
                <p style={{
                    fontSize: 18,
                    fontWeight: 700
                }}>
                    {address}
                </p>
            </div>
        </div>
    )
}

export default Header