import { createSelector } from "@reduxjs/toolkit"

export const selectEstimation = (state) => state.estimate.estimation
export const selectHousing = (state) => state.estimate.housing

export const selectIsWorking = (state) => state.estimate.working

export function selectCanComputeStep01(state) {
    const housing = state.estimate.housing

    const isSurfaceReelleValid = 
        housing.surface_reelle_bati !== null
        && housing.surface_reelle_bati !== ""
        && housing.surface_reelle_bati > 0

    const isNbPieceValid = 
        housing.nombre_pieces_principales !== null
        && housing.nombre_pieces_principales !== ""

    return isSurfaceReelleValid && isNbPieceValid
}

// memo ok
export const selectAddress = createSelector([
    (state) => selectHousing(state).adresse || "",
    (state) => selectHousing(state).code_postal,
    (state) => selectHousing(state).nom_commune,
], (address, codePostal, commune) => {
    const rue = address.replace(codePostal, "").replace(commune, "").trim()
    return { rue, codePostal, commune }
})

export const selectValues = (state) => {
    const value = selectEstimation(state).value
    const displayedValue = selectEstimation(state).value_displayed
    const prixNetVendeur = selectEstimation(state).prix_net_vendeur
    const customValue = selectEstimation(state).value_custom
    const estimationValue = displayedValue || value
    return { estimationValue, prixNetVendeur, customValue }
}

// memo ok
export const selectCosts = createSelector([
    (state) => selectEstimation(state).frais_agence_absolu,
    (state) => selectEstimation(state).frais_notaire_absolu,
    (state) => selectEstimation(state).prix_net_vendeur,
], (fraisAgence, fraisNotaire, prixNetVendeur) => {
    const prixPresentation = Number(prixNetVendeur) + Number(fraisAgence)
    const coutGlobal = prixPresentation + Number(fraisNotaire)
    return { prixPresentation, coutGlobal }
})

export const selectHousingEstimations = (state) => {
    const data = state.estimate.estimations.items
    const isLoading = state.estimate.estimations.loading
    const error = state.estimate.estimations.error
    return { data, isLoading, error }
}

export const selectShapleyData = (state) => {
    const data = selectEstimation(state).shapley_data || []
    const weightings = selectEstimation(state).weightings_data || []
    const weightingsRef = selectEstimation(state).weightings_ref_data || []
    return { data, weightings, weightingsRef }
}

export const selectIsLeadEstimation = (state) => state.estimate.isLeadEstimation

export const selectHousingPosition = (state) => {
    const latitude = selectHousing(state).latitude
    const longitude = selectHousing(state).longitude
    return [latitude, longitude]
}

export const selectCurrentStep = (state) => selectEstimation(state).current_step

export const selectDVF = (state) => selectHousing(state).dvf_records || {}