import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import GreyBox from "../../components/GreyBox"
import InputNumber from "../../components/InputNumber"

const NumberMandat = ({ readOnly }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const numberMandat = useSelector(selectMandatEditor2).mandat_id

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(1000)
    }

    if (readOnly) {
        return (
            <GreyBox style={{
                marginRight: 3,
                width: 75,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 4px"
            }}>
                {numberMandat}
            </GreyBox>
        )
    }

    return (
        <InputNumber
            required
            name="mandat_id"
            value={numberMandat || ""}
            onChange={handleChange}
            style={{
                marginRight: 3,
                width: 75,
                textAlign: "center"
            }}
        />
    )
}

export default NumberMandat