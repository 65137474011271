import { CheckCircleRounded, RadioButtonUncheckedRounded } from "@mui/icons-material"
import { Checkbox as MuiCheckbox } from "@mui/material"
import { scssVar } from "../../app/scssVar"

const Checkbox = ({ 
    fontSize, 
    disabled, 
    onChange, 
    checked, 
    required, 
    name,
    color
}) => {
    const iconStyle = { 
        color: color || scssVar.primary, 
        opacity: disabled ? .5 : 1 
    }

    return (
        <MuiCheckbox
            icon={<RadioButtonUncheckedRounded fontSize={fontSize} style={iconStyle} />}
            checkedIcon={<CheckCircleRounded fontSize={fontSize} style={iconStyle} />}
            disabled={disabled}
            onChange={onChange}
            checked={checked}
            required={required}
            name={name}
        />
    )
}

export default Checkbox