import { Typography } from "@mui/material"
import { scssVar } from "../../../app/scssVar"

const BetaVersionAlertText = () => (
    <Typography sx={{
        fontFamily: scssVar.fontFamily,
        color: "black",
        p: "10px",
        borderRadius: "12px",
        textAlign: "center",
        fontWeight: 600,
        bgcolor: scssVar.yellow
    }}>
        Version bêta. Votre saisie ne sera pas enregistrée, pensez à télécharger votre document une fois finalisé !
    </Typography>
)

export default BetaVersionAlertText