import { Fragment, useState } from "react"
import PhotoUploader from "../../../../../photoUploader/PhotoUploader"
import { getCompressedImg, getCroppedImg } from "../../../../../photoUploader/settings"
import { CircularProgress, Grid } from "@mui/material"
import { scssVar } from "../../../../../../app/scssVar"
import { getGalleryBlobs } from "../../../../../photoUploader/photosSlice"
import { useDispatch, useSelector } from "react-redux"
import { selectHousing } from "../../../../selectors"
import { v4 as uuid } from 'uuid';
import { api } from "../../../../../../common/setupApi"
import { updateHousing } from "../../../../actions"
import { addAlert } from "../../../../../alerts/alertsSlice"
import { defaultErrorMessage } from "../../../../../../common/utils"
import useGalleryBlobs from "../../../../../photoUploader/hooks/useGalleryBlobs"
import GalleryLayout from "../../../../../photoUploader/components/GalleryLayout"
import UploadButton from "./UploadButton"

const GalleryPhotos = () => {
    const dispatch = useDispatch()
    const housing = useSelector(selectHousing)
    const pics = useGalleryBlobs()
    const [loading, setLoading] = useState(false)

    const housingId = housing.id

    const handleChange = async (cropArea, rotation, aspect, file) => {
        setLoading(true)

        return getCroppedImg(URL.createObjectURL(file), cropArea, rotation)
            .then(async (croppedImg) => {
                console.log(`originalFile size ${croppedImg.size / 1024 / 1024} MB`)

                return getCompressedImg(croppedImg)
                    .then(async (compressedImg) => {
                        console.log(`compressedFile size ${compressedImg.size / 1024 / 1024} MB`)

                        const fileId = uuid()
                        const formData = new FormData()
                        formData.append("housing_id", housingId)
                        formData.append("aspect", aspect)
                        formData.append("position", "gallery")
                        formData.append("photos", compressedImg, fileId)

                        return api
                            .post("v1/housing/photos/", formData, { headers: { "content-type": "multipart/form-data" } })
                            .then(async (res) => {
                                dispatch(updateHousing({ ...housing, photos_2: res.data }))
                                dispatch(getGalleryBlobs())
                                dispatch(addAlert("La photo a bien été ajoutée", "success"))
                                setLoading(false)
                            })
                            .catch(err => {
                                console.log(err.response)
                                dispatch(addAlert(defaultErrorMessage, "error"))
                                setLoading(false)
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(addAlert("Une erreur est survenue lors de la compression de l'image, veuillez réessayer", "error"))
                        setLoading(false)
                    })
            })
            .catch(err => {
                console.log(err)
                dispatch(addAlert("Une erreur est survenue lors du redimensionnement de l'image, veuillez réessayer", "error"))
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <p>Photos présentes sur la page 2 de la synthèse PDF et sur les annonces ({pics.length}/3)</p>
            <div style={{ margin: 10 }} />
            <Grid container spacing={.5}>
                <GalleryLayout pics={pics} />
            </Grid>
            {pics.length === 0 && (
                <Grid container spacing={1}>
                    {[...Array(3).keys()].map((_, i) =>
                        <Grid item xs={4} key={i}>
                            <div style={{ 
                                // border: "1px solid " + scssVar.grey, 
                                background: "#ececec",
                                borderRadius: "4px",
                                height: "25vh", 
                                display: "flex", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                textAlign: "center", 
                            }}>
                                Aucune photo
                            </div>
                        </Grid>
                    )}
                </Grid>
            )}
            {pics.length < 3 && (
                <Fragment>
                    <div style={{ margin: 10 }} />
                    <PhotoUploader
                        withCropper
                        onSubmit={handleChange}
                        newPhotoLoading={loading}
                        ButtonEmpty={({ newPhotoLoading }) => {
                            if (newPhotoLoading) {
                                return (
                                    <div style={{ width: "fit-content", marginLeft: "auto" }}>
                                        <CircularProgress size={15} sx={{ color: scssVar.primary }} />
                                    </div>
                                )
                            }

                            return <UploadButton />
                        }}
                    />
                    <div style={{ margin: 10 }} />
                </Fragment>
            )}
        </Fragment>
    )
}

export default GalleryPhotos