import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import FieldControl from "../../../../common/components/FieldControl"

const Seller = ({ firstName, lastName, onChange }) => {
    const working = useSelector(state => state.estimate.working)

    return (
        <Box sx={{ 
            width: "100%",
            mb: 2,
            display: "flex",
            alignItems: { xs: "flex-start", sm: "flex-end" },
            justifyContent: { xs: "flex-start", sm: "space-between" },
            flexDirection: { xs: "column", sm: "row" }
        }}>
            <Box sx={{ 
                width: { xs: "100%", sm: "48%" },
                mb: { xs: 1, sm: 0 }
            }}>
                <FieldControl
                    type="text"
                    label="Vendeur"
                    placeholder="Prénom"
                    isSuccess={firstName}
                    value={firstName || ""}
                    name="prenom_vendeur"
                    onChange={onChange}
                    maxLength={20}
                    disabled={working}
                />
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "48%" } }}>
                <FieldControl
                    type="text"
                    placeholder="Nom"
                    isSuccess={lastName}
                    value={lastName || ""}
                    name="nom_vendeur"
                    onChange={onChange}
                    maxLength={20}
                    disabled={working}
                />
            </Box>
        </Box>
    )
}

export default Seller