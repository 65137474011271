import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const ProgressBar1 = ({ p, bgcolor, color }) => {
    return (
        <Box sx={{
            width: "100%",
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            height: 8,
            bgcolor: bgcolor || scssVar.greyLight
        }}>
            <Box sx={{
                width: `${p}%`,
                maxWidth: "100%",
                height: "100%",
                bgcolor: color || scssVar.primary,
                borderRadius: "16px",
            }} />
        </Box>
    )
}

export default ProgressBar1