import { useDispatch, useSelector } from "react-redux"
import { buyOfferActions, selectBuyOfferEditor } from "../buyOfferSlice"
import { numberWithSpaces } from "../../../../../common/utils"
import { selectAddress, selectCosts, selectEstimation, selectValues } from "../../../../estimate/selectors"
import Empty from "../../../components/Empty"
import GreyBox from "../../../components/GreyBox"
import InputNumber from "../../../components/InputNumber"
import InputText from "../../../components/InputText"
import Margin from "../../../components/Margin"
import { selectMandatEditor2 } from "../../../mandat/mandatSlice"
import SectionTitle from "./SectionTitle"

const Offer = () => {
    const dispatch = useDispatch()
    const houseType = useSelector(selectMandatEditor2).type_bien_detail
    const { rue, codePostal, commune } = useSelector(selectAddress)
    const fraisAgence = useSelector(state => state.estimate.estimation.frais_agence_absolu)
    const acquereurVendeur = useSelector(selectEstimation).a_charge_vendeur_ou_acquereur
    const { prixPresentation } = useSelector(selectCosts)
    const { prixNetVendeur } = useSelector(selectValues)
    const buyState = useSelector(selectBuyOfferEditor)
    
    const offerType = buyState.offre_type // -> nego | prix
    const acquereurVendeurText = 
        acquereurVendeur === "acquereur"
            ? "de l'acquéreur"
            : "du vendeur"

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(buyOfferActions.setField({ name, value }))
    }
    
    return (
        <div>
            <SectionTitle>
                L’OFFRE
            </SectionTitle>

            <Margin m={4} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Se propose d’acquérir le bien suivant :</p>
                {houseType ? (
                    <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center" }}>
                        {houseType}
                    </GreyBox>
                ):(
                    <Empty>Nature du bien</Empty>
                )}
            </div>

            <Margin m={4} />

            <div style={{ display: "flex"}}>
                <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center", flexGrow: 1, marginRight: 5 }}>
                    {rue}
                </GreyBox>
                <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center", width: 75 }}>
                    {codePostal}
                </GreyBox>
            </div>

            <Margin m={4} />
            
            <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center", width: "100%" }}>
                {commune}
            </GreyBox>

            <Margin m={4} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Au prix de :</p>
                {offerType === "nego" ? (
                    <InputNumber
                        value={buyState.nego_prix_presentation}
                        name="nego_prix_presentation"
                        onChange={handleChange}
                        style={{ width: 250 }}
                    />
                ):(
                    <GreyBox style={{ width: 250, display: "flex", alignItems: "center", padding: "0 4px" }}>
                        {numberWithSpaces(prixPresentation)}
                    </GreyBox>
                )}
                <p style={{ marginLeft: 3 }}>€</p>
            </div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginRight: 3, fontWeight: 600 }}>Net vendeur :</p>
                    {offerType === "nego" ? (
                        <InputNumber
                            value={buyState.nego_prix_net}
                            name="nego_prix_net"
                            onChange={handleChange}
                            style={{ width: 250 }}
                        />
                    ):(
                        <GreyBox style={{ width: 250, display: "flex", alignItems: "center", padding: "0 4px" }}>
                            {numberWithSpaces(prixNetVendeur)}
                        </GreyBox>
                    )}
                    <p style={{ marginLeft: 3, fontWeight: 600 }}>€</p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: 30, marginRight: 3, fontWeight: 600 }}>Honoraires d’agence :</p>
                    {offerType === "nego" ? (
                        <InputNumber
                            value={buyState.nego_frais_agence}
                            name="nego_frais_agence"
                            onChange={handleChange}
                            style={{ width: 183 }}
                        />
                    ):(
                        <GreyBox style={{ width: 183, display: "flex", alignItems: "center", padding: "0 4px" }}>
                            {numberWithSpaces(fraisAgence)}
                        </GreyBox>
                    )}
                    <p style={{ marginLeft: 3, fontWeight: 600 }}>€</p>
                </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Nous reconnaissons que les honoraires de négociation sont à la charge :</p>
                <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center", flexGrow: 1 }}>
                    {acquereurVendeurText}
                </GreyBox>
            </div>

            <p>Le financement de cette acquisition se réalisera aux conditions suivantes :</p>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Prêt bancaire de</p>
                <InputNumber 
                    value={buyState.pret_montant}
                    name="pret_montant"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
                <p style={{ marginLeft: 3, marginRight: 3 }}>€ sur</p>
                <InputNumber 
                    value={buyState.pret_duree}
                    name="pret_duree"
                    onChange={handleChange}
                    style={{ width: 75 }}
                />
                <p style={{ marginLeft: 3, marginRight: 3 }}>mois au taux de</p>
                <InputNumber 
                    value={buyState.pret_taux}
                    name="pret_taux"
                    onChange={handleChange}
                    style={{ width: 75 }}
                />
                <p style={{ marginLeft: 3 }}>%</p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Apport personnel de :</p>
                <InputNumber 
                    value={buyState.apport_montant}
                    name="apport_montant"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
                <p style={{ marginLeft: 3 }}>€</p>
            </div>

            <Margin m={3} />
            
            <p style={{ fontWeight: 600, lineHeight: "17px" }}>
                Conformément à la loi TRACFIN, si vous disposez d’un apport personnel pour acquérir le bien,
            </p>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontWeight: 600, marginRight: 3, lineHeight: "17px" }}>
                    merci d’indiquer l’origine de ces fonds ci-après :
                </p>
                <InputText 
                    value={buyState.apport_origine}
                    name="apport_origine"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
            </div>

            <Margin m={25} />

            <p style={{ lineHeight: "23px" }}>
                Cependant, j’ai ou nous avons pris connaissance que cette offre ne sera valable que 45 jours au maximum,
                si je ou nous ne nous présentions pas à la signature de la promesse dans ce délai. A l’issue de cette période, le
                vendeur reprendra sa liberté s’il le souhaite. 
            </p>
        </div>
    )
}

export default Offer