import { Typography, Box } from "@mui/material"
import { scssVar } from "../../app/scssVar"

const MissingFieldAlertText = ({ visible, children, sx }) => visible && (
    <Box sx={sx}>
        <i
            className="iconoir-warning-triangle-outline"
            style={{
                color: scssVar.danger,
                verticalAlign: "middle",
                display: "inline-block",
                marginRight: 5,
                fontSize: 20
            }}
        ></i>
        <Typography component="span" sx={{ 
            color: scssVar.danger, 
            fontFamily: scssVar.fontFamily,
            verticalAlign: "middle"
        }}>
            {children}
        </Typography>
    </Box>
)

export default MissingFieldAlertText