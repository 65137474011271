import { CircularProgress, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../../app/scssVar"
import TooltipStyled from "../../../../common/components/TooltipStyled"
import { api } from "../../../../common/setupApi"
import { defaultErrorMessage, numberWithSpaces } from "../../../../common/utils"
import { addAlert } from "../../../alerts/alertsSlice"
import { getEstimationsOfHousing, updateEstimationsList, updateUnfinishedsList } from "../../actions"
import { emptyEstimation } from "../../reducer"
import useFinishedEstimationsOfHousing from "../../hooks/useFinishedEstimationsOfHousing"
import DialogEditHistory from "./components/DialogEditHistory"
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Button from "../../../../common/components/Button"

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 35,
        top: 0,
        background: "red",
        color: "white",
        padding: '0 4px',
    },
}));

const initialState = {
    openEditEstimationInfos: false,
    openDelete: false,
    loadingDuplicate: false,
    openStatutBien: false,
    scrolledOnMount: false
}

const History = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const items = useFinishedEstimationsOfHousing()
    const estimationId = useSelector(state => state.estimate.estimation.id)
    const housingId = useSelector(state => state.estimate.housing.id)
    const loading = useSelector(state => state.estimate.estimations.loading)
    const error = useSelector(state => state.estimate.estimations.error)
    const saving = useSelector(state => state.estimate.saving)
    const working = useSelector(state => state.estimate.working)
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (!saving) {
            dispatch(getEstimationsOfHousing())
        }
    }, [dispatch, saving])

    useEffect(() => {
        let interval
        function getEl() {
            return document.getElementById("eval_buttons")
        }

        if (items.length && !state.scrolledOnMount) {
            interval = setInterval(() => {
                const el = getEl()
                if (el) {
                    const index = items.map(d => d.id).indexOf(estimationId)
                    el.scrollLeft += index * 115
                    clearInterval(interval)
                    setState(s => ({ ...s, scrolledOnMount: true }))
                }
            }, [500])
        }

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [estimationId, items, state.scrolledOnMount])

    const handleClickOnEstimation = (estimation) => {
        if (estimation.id === estimationId) {
            setState({ ...state, openEditEstimationInfos: true })
        } else {
            dispatch({ type: "estimate/set_estimation", payload: estimation })
        }
    }

    const handleCreateForHousing = async () => {
        dispatch({ type: "estimate/set_working", payload: true })

        const estimationCopy = {
            ...emptyEstimation,
            housing_id: housingId
        }

        return api
            .post("v1/housing/estimation/upsert", estimationCopy)
            .then(async res => {
                const _estimation = {
                    ...res.data,
                    current_step: "Step1"
                }

                return api
                    .post("v1/housing/estimation/compute", { housing: res.data.housing, estimation: _estimation })
                    .then(res2 => {
                        dispatch({ type: "estimate/set_estimation", payload: res2.data })
                        dispatch({ type: "estimate/edit_estimation", payload: res2.data })

                        dispatch({ type: "estimate/set_working", payload: false })

                        dispatch(getEstimationsOfHousing())
                        dispatch(updateEstimationsList("Step1"))
                        dispatch(updateUnfinishedsList("Step1"))

                        navigate("/estimer/step-1")
                    })
                    .catch(err => {
                        console.log(err.response)
                        dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                        dispatch({ type: "estimate/set_working", payload: false })
                    })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }

    return (
        <Fragment>
            <Typography sx={{ fontFamily: scssVar.fontFamily, color: scssVar.greyDarker, mb: 2 }}>
                Historique des évaluations
            </Typography>
            {loading ? (
                <CircularProgress size={24} sx={{ color: scssVar.primary  }} />
            ): !error ? (
                <Fragment>
                    <div style={{ display: "flex" }}>
                        <div id="eval_buttons" style={{ overflowX: "auto", whiteSpace: "nowrap", padding: "10px 0", marginRight: "10px" }}>
                            {items.map(d =>
                                <StyledBadge key={d.id} badgeContent={d.ad_published ? "publiée" : 0}>
                                    <Button
                                        id={d.id}
                                        onClick={() => handleClickOnEstimation(d)}
                                        variant={d.id === estimationId ? "primary" : "light"}
                                        disabled={working || saving}
                                        noShadow
                                        sx={{
                                            height: 56,
                                            width: d.id === estimationId ? 139 : 115,
                                            borderRadius: "6px",
                                            p: "0 5px",
                                            mr: d.ad_published ? "20px" : "10px",
                                        }}
                                    >
                                        <div style={{ 
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            color: d.id === estimationId ? "white" : scssVar.greyDarker,
                                            flexGrow: 1
                                        }}>
                                            <div style={{ fontSize: 14 }}>{d.name}</div>
                                            <div style={{ fontWeight: 600 }}>
                                                {numberWithSpaces(d.value_custom || d.value_displayed || d.value)}€
                                            </div>
                                        </div>
                                        {d.id === estimationId && (
                                            <div style={{ width: 24 }}>
                                                <i className="iconoir-edit-pencil" style={{ fontSize: 24 }}></i>
                                            </div>
                                        )}
                                    </Button>
                                </StyledBadge>
                            )}
                        </div>
                        <div style={{ padding: "10px 0" }}>
                            <TooltipStyled arrow title="Nouvelle évaluation pour ce bien">
                                <span>
                                    <Button
                                        variant="light"
                                        onClick={handleCreateForHousing}
                                        disabled={working || loading}
                                        sx={{
                                            height: 56,
                                            width: 56,
                                            borderRadius: "6px"
                                        }}
                                    >
                                        <i className="iconoir-plus" style={{ color: "black", fontSize: 24 }}></i>
                                    </Button>
                                </span>
                            </TooltipStyled>
                        </div>
                    </div>
                    <DialogEditHistory open={state.openEditEstimationInfos} onClose={() => setState({ ...state, openEditEstimationInfos: false })} />
                </Fragment>
            ):(
                <p style={{ color: scssVar.danger }}>{defaultErrorMessage}</p>
            )}
        </Fragment>
    )
}

export default History