import { DoneRounded, PhoneRounded } from "@mui/icons-material"
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../../app/scssVar"
import FieldControl from "../../../../../../common/components/FieldControl"
import TooltipStyled from "../../../../../../common/components/TooltipStyled"
import { api } from "../../../../../../common/setupApi"
import { capitalizeFirstLetter } from "../../../../../../common/utils"
import { selectAllTeamsUsers } from "../../../../../account/selectors"
import { addAlert } from "../../../../../alerts/alertsSlice"
import { apiOfferActions, selectSelectedLead } from "../../../../../apiOffer/apiOfferSlice"
import useAuth from "../../../../../auth/hooks/useAuth"
import { selectIsWorking } from "../../../../selectors"

const CardLead = () => {
    const dispatch = useDispatch()
    const lead = useSelector(selectSelectedLead)
    const working = useSelector(selectIsWorking)
    const users = useSelector(selectAllTeamsUsers)
    const { isAdmin } = useAuth().user

    const handleChangeSeen = async (name, value) => {
        if (value === null) {
            return
        }

        dispatch({ type: "estimate/set_working", payload: true })
    
        return api
            .patch("v1/api/leads/", { pk: [lead.pk], seen_by_profile: value })
            .then(() => {
                dispatch(apiOfferActions.setLeads({ pk: [lead.pk], name: "seen_by_profile", value }))
                dispatch(addAlert("Le lead a bien été modifié", "success"))
                dispatch({ type: "estimate/set_working", payload: false })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }

    const handleChangeAssignedTo = async (e) => {
        const { value } = e.target

        dispatch({ type: "estimate/set_working", payload: true })

        return api
            .patch("v1/api/leads/", { pk: [lead.pk], profile: value })
            .then(() => {
                dispatch(apiOfferActions.setLeads({ pk: [lead.pk], name: "profile", value }))
                dispatch(addAlert("Le lead a bien été modifié", "success"))
                dispatch({ type: "estimate/set_working", payload: false })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }

    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ 
                p: "16px",
                bgcolor: scssVar.greyLighter, 
                borderRadius: "12px", 
                mb: 3,
                position: "relative",
                display: "flex",
                alignItems: { xs: "flex-start", md: "center" },
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" },
            }}>
                <List sx={{ p: 0 }}>
                    <ListItem sx={{ p: 0 }}>
                        <ListItemIcon sx={{ minWidth: 61,  }}>
                            <i
                                className="iconoir-profile-circled"
                                style={{
                                    fontSize: 47,
                                    color: scssVar.primary
                                }}
                            ></i>
                        </ListItemIcon>
                        <ListItemText 
                            primary={`${lead.lead_last_name} ${lead.lead_first_name}`} 
                            secondary={
                                <span style={{ display: "flex", flexWrap: "wrap" }}>
                                    <a 
                                        href={`mailto:${lead.lead_email}`}
                                        style={{ display: "flex", alignItems: "center", color: scssVar.primary, marginRight: "6px", marginBottom: "4px" }}
                                    >
                                        {lead.verified ? (
                                            <DoneRounded sx={{ color: scssVar.success, fontSize: 18, mr: "4px" }} />
                                        ):(
                                            <TooltipStyled 
                                                arrow 
                                                title={`${capitalizeFirstLetter(lead.lead_first_name)} ${capitalizeFirstLetter(lead.lead_last_name)} n'a pas vu le résultat final de l'estimation`}
                                                enterTouchDelay={0}
                                                leaveTouchDelay={5000}
                                            >
                                                <i
                                                    className="iconoir-info-empty"
                                                    style={{
                                                        color: scssVar.danger,
                                                        fontSize: 18,
                                                        marginRight: 4
                                                    }}
                                                ></i>
                                            </TooltipStyled>
                                        )}
                                        {lead.lead_email}
                                    </a>
                                    {lead.lead_phone && (
                                        <a 
                                            href={`tel:${lead.lead_phone}`}
                                            style={{ display: "flex", alignItems: "center", color: scssVar.primary, marginBottom: "4px" }}
                                        >
                                            {<PhoneRounded style={{ fontSize: 16, marginRight: 1 }} />}
                                            {lead.lead_phone}
                                        </a>
                                    )}
                                </span>
                            }
                            sx={{
                                ".MuiTypography-root": {
                                    fontFamily: scssVar.fontFamily
                                },
                                ".MuiListItemText-primary": {
                                    fontWeight: 800,
                                    fontSize: 18,
                                    color: scssVar.primary
                                }
                            }}
                        />
                    </ListItem>
                </List>
                <Box sx={{  width: { xs: "100%", md: "unset" } }}>
                    <Typography sx={{ 
                        fontFamily: scssVar.fontFamily,
                        fontSize: 14, 
                        color: scssVar.greyDarker,
                        textAlign: { xs: "start", sm: "end" },
                        ml: { xs: 1, sm: 0 }
                    }}>
                        <i>
                            "{lead.contact || "Je ne souhaite pas être contacté"}"
                            <br/>
                            "{lead.project || "Je n'ai aucun projet"}"
                        </i>
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={3}>
                {isAdmin && (
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="select"
                            label="Attribué à"
                            disabled={working}
                            value={lead.profile || ""}
                            isSuccess={lead.profile}
                            onChange={handleChangeAssignedTo}
                            options={
                                <Fragment>
                                    <option value="" />
                                    {users.map((d, i) => <option value={d.id} key={i}>{d.name}</option>)}
                                </Fragment>
                            }
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <FieldControl 
                        type="checkbox"
                        label="Marquer comme"
                        isSuccess={lead.seen_by_profile === true || lead.seen_by_profile === false}
                        selectedValue={lead.seen_by_profile}
                        onChange={handleChangeSeen}
                        choices={[
                            {value: true, label: "Vu"},
                            {value: false, label: "Non vu"},
                        ]}
                        xs={6}
                        disabled={working}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CardLead