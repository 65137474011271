import { AddRounded, ArrowDropDownRounded, SubdirectoryArrowRightRounded } from "@mui/icons-material"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useMediaQuery } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import { api } from "../../../../common/setupApi"
import { addAlert } from "../../../alerts/alertsSlice"
import { apiOfferActions, selectApiDepartements } from "../../apiOfferSlice"
import Autocomplete from '@mui/material/Autocomplete';
import { franceDepartmentsSuggestions, getDepartmentByCode } from "../../../../common/utils"

const Departements = () => {
    const dispatch = useDispatch()
    const departements = useSelector(selectApiDepartements)
    const isSmallScreen = useMediaQuery("(max-width: 599px)")
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState(null)
    const [selected, setSelected] = useState(null)
    const [value, setValue] = useState(franceDepartmentsSuggestions[0])

    const handleClose = () => {
        setMode(null)
        setSelected(null)
        setValue(franceDepartmentsSuggestions[0])
    }

    const handleSubmit = async () => {
        let payload

        if (mode === "add") {
            payload = { api_departments: [...departements, value.code] }
        }

        if (mode === "edit") {
            payload = { api_departments: departements.map(d => d === selected.code ? value.code : d) }
        } 

        if (mode === "delete") {
            payload = { api_departments: departements.filter(d => d !== selected.code) }
        }

        setLoading(true)

        return api
            .patch("v1/api/infos/", payload)
            .then(res => {
                dispatch(apiOfferActions.setDepartement(res.data.api_departments))
                if (mode === "add") {
                    dispatch(addAlert("Le département a bien été ajouté", "success"))
                } 
                if (mode === "edit") {
                    dispatch(addAlert("Le département a bien été modifié", "success"))
                }
                if (mode === "delete") {
                    dispatch(addAlert("Le département a bien été supprimé", "success"))
                }
                setMode(null)
                setSelected(null)
                setValue(franceDepartmentsSuggestions[0])
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                const error = err.response?.data?.api_departments || []
                if (error.length) {
                    error.forEach(d => dispatch(addAlert(d, "error")))
                } else {
                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                }
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <p style={{ fontWeight: 800, marginBottom: 15 }}>
                Limite de départements dans la recherche d'adresse
            </p>
            <p style={{ color: scssVar.greyDarker, marginBottom: 15 }}>
                Affichez seulement certains départements dans les résultats lorsque le lead recherche l'adresse d'un bien. 
                Si vous n'en renseignez aucun, tous les départements apparaîtrons dans les résultats.
                <br />Cette étape est facultative.
            </p>
            {departements.length ? (
                <Fragment>
                    <List sx={{ bgcolor: scssVar.greyLighter, borderRadius: "12px" }}>
                        {departements.map((d, i) => 
                            <ListItem
                                key={i} 
                                sx={{ 
                                    borderBottom: i < departements.length - 1 ? `1px solid ${scssVar.greyLight}` : "none",
                                    pr: "100px"
                                }}
                            >
                                <ListItemText 
                                    primary={`${d} - ${getDepartmentByCode(d)?.nom || ""}`} 
                                    sx={{
                                        ".MuiTypography-root": {
                                            fontFamily: scssVar.fontFamily
                                        },
                                        ".MuiListItemText-primary": {
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }
                                    }} 
                                />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => {
                                        setMode("edit")
                                        setSelected(getDepartmentByCode(d))
                                        setValue(getDepartmentByCode(d))
                                    }}>
                                        <i
                                            className="iconoir-edit-pencil edit-icon"
                                            style={{ 
                                                color: scssVar.primary
                                            }}
                                        ></i>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setMode("delete")
                                        setSelected(getDepartmentByCode(d))
                                        setValue(getDepartmentByCode(d))
                                    }}>
                                        <i
                                            className="iconoir-trash"
                                            style={{ 
                                                color: scssVar.primary
                                            }}
                                        ></i>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    <p style={{ fontWeight: 500, marginBottom: 20, marginTop: 15, display: "flex" }}>
                        <SubdirectoryArrowRightRounded sx={{ fontSize: 17, mt: "5px", mr: "2px" }} />
                        Actuellement, les résultats de la recherche d'adresse sont limités à 
                        ce{departements.length > 1 ? "s" :""}{" "}
                        département{departements.length > 1 ? "s" :""} :{" "}
                        {departements.map(
                            (d, i) => 
                                `${getDepartmentByCode(d).code} - ${getDepartmentByCode(d).nom}${i < departements.length - 1 ? ", " : ""}`
                        )}
                        .
                    </p>
                    <Button onClick={() => setMode("add")} sx={{ ml: "auto", mt: 2 }}>
                        Ajouter un département
                        <AddRounded sx={{ ml: 1 }} />
                    </Button>
                </Fragment>
            ):(
                <Fragment>
                    <Box sx={{ p: "10px 20px", bgcolor: scssVar.greyLighter, borderRadius: "8px", mb: "20px" }}>
                        <p style={{ fontWeight: 500 }}>
                            Actuellement, tous les départements apparaissent dans les résultats de la recherche d'adresse.
                        </p>
                    </Box>
                    <Button onClick={() => setMode("add")} sx={{ ml: "auto" }}>
                        Ajouter un département
                        <AddRounded sx={{ ml: 1 }} />
                    </Button>
                </Fragment>
            )}
            <Dialog
                open={mode !== null} 
                onClose={handleClose}
                fullScreen={(mode === "add" || mode === "edit") && isSmallScreen}
                fullWidth
                transitionDuration={0}
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}>
                    {mode === "add" && "Ajouter un département"}
                    {mode === "edit" && `Modifier ${selected.code} - ${selected.nom}`}
                    {mode === "delete" && `Supprimer ${selected.code} - ${selected.nom} ?`}
                </DialogTitle>
                {(mode === "add" || mode === "edit") && (
                    <DialogContent>
                        <Box sx={{ height: 350, position: "relative" }}>
                            <Autocomplete
                                disablePortal 
                                noOptionsText="Aucune suggestion"
                                id="departments-autocomplete"
                                options={franceDepartmentsSuggestions}
                                getOptionDisabled={(option) => departements.includes(option.code)}
                                value={value}
                                onChange={(e, newValue) => setValue(newValue)}
                                sx={{
                                    width: "100%",
                                    "& + .MuiAutocomplete-popper .MuiAutocomplete-paper": {
                                        boxShadow: scssVar.shadow2,
                                        borderRadius: "8px",
                                        bgcolor: scssVar.greyLighter,
                                    },
                                    "& + .MuiAutocomplete-popper .MuiAutocomplete-listbox": {
                                        maxHeight: 325
                                    },
                                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                                        fontFamily: scssVar.fontFamily
                                    },
                                    "& + .MuiAutocomplete-popper .MuiAutocomplete-noOptions": {
                                        fontFamily: scssVar.fontFamily
                                    }
                                }}
                                renderInput={(params) => {
                                    return (
                                        <div className="field" ref={params.InputProps.ref}>
                                            <div className="control has-icons-right">
                                                <input type="text" {...params.inputProps} className={`input ${value?.code && "is-success"}`} />
                                                <span className="icon is-small is-right">
                                                    <ArrowDropDownRounded sx={{ color: scssVar.greyDark }} />
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </Box>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button sx={{ width: 150 }} variant="light" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button 
                        variant={mode === "delete" ? "danger" : "primary"}
                        sx={{ width: 150 }} 
                        onClick={handleSubmit} 
                        loading={loading}
                        disabled={
                            loading 
                            || ((mode === "add" || mode === "edit") && !value?.code)
                            || ((mode === "add" || mode === "edit") && departements.includes(value?.code))
                        } 
                    >
                        {(mode === "add" || mode === "edit") && "Enregistrer"}
                        {mode === "delete" && "Supprimer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Departements
