import { scssVar } from "../../app/scssVar"
import { Box } from "@mui/material"
import { Fragment, useState } from 'react';
import UploadButtonContainer from '../../common/components/UploadButtonContainer';
import PhotosButton from "./components/PhotosButton";
import DialogDelete from "./components/DialogDelete";
import PhotoCropper from "./components/PhotoCropper";
import DialogDisplayPhoto from "./components/DialogDisplayPhoto";
import MenuPhoto from "./components/MenuPhoto";
import Badge from "./components/Badge";

const PhotoUploader = ({
    onSubmit,
    newPhotoLoading,
    onDelete,
    deleteLoading,
    blob,
    loadingBlob,
    ButtonEmpty,
    ButtonBlob,
    withCropper,
    aspect,
    onUpdate,
    noUpdate
}) => {
    const [newPhotoValue, setNewPhotoValue] = useState("") // pour l'input
    const [newPhotoPreview, setNewPhotoPreview] = useState(null) // pour le crop
    const [newPhotoFile, setNewPhotoFile] = useState(null) // pour la requête

    const [openCropper, setOpenCropper] = useState(false)
    const [openDisplay, setOpenDisplay] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [anchorMenu, setAnchorMenu] = useState(null)
    const openMenu = Boolean(anchorMenu)

    const handleChange = (e, mode) => {
        const value = e.target.value
        const file = e.target.files

        setNewPhotoValue(value)

        if (anchorMenu) {
            setAnchorMenu(null)
        }

        if (file.length) {
            if (withCropper) {
                setNewPhotoFile(file[0])
                setNewPhotoPreview(URL.createObjectURL(file[0]))
                setOpenCropper(true)
            } else {
                if (mode === "new") {
                    onSubmit(file[0])
                } else {
                    onUpdate(file[0])
                }
                setNewPhotoValue("")
                setNewPhotoFile(null)
            }
        }

        return
    }

    // console.log(newPhotoValue)
    // console.log(newPhotoFile)
    // console.log(newPhotoPreview)

    const handleSubmit = (cropArea, rotation, aspect, callback, mode) => {
        if (mode === "new") {
            onSubmit(cropArea, rotation, aspect, newPhotoFile)
        } else {
            onUpdate(cropArea, rotation, aspect, newPhotoFile)
        }
        callback()
        setOpenCropper(false)
        setNewPhotoValue("")
        setNewPhotoPreview(null)
        setNewPhotoFile(null)
    }

    const handleCloseCropper = (callback) => {
        callback()
        setOpenCropper(false)
        setNewPhotoValue("")
        setNewPhotoPreview(null)
        setNewPhotoFile(null)
    }

    if (blob) {
        const handleOpenMenu = (e) => {
            setAnchorMenu(e.currentTarget)
        }
    
        const handleCloseMenu = () => {
            setAnchorMenu(null)
        }
    
        const handleOpenDisplay = () => {
            setAnchorMenu(null)
            setOpenDisplay(true)
        }
    
        const handleCloseDisplay = () => {
            setOpenDisplay(false)
        }
    
        const handleOpenDelete = () => {
            setAnchorMenu(null)
            setOpenDelete(true)
        }
    
        const handleCloseDelete = () => {
            setOpenDelete(false)
        }
    
        const handleDelete = async () => {
            setOpenDelete(false)
            onDelete()
        }

        return (
            <Fragment>
                {ButtonBlob ? (
                    ButtonBlob({ 
                        handleOpenMenu, 
                        newPhotoLoading, 
                        loadingBlob, 
                        blob, 
                        deleteLoading 
                    })
                ):(
                    <Box sx={{ 
                        position: "relative", 
                        width: "fit-content"
                    }}>
                        <Badge
                            onClick={handleOpenMenu} 
                            loading={newPhotoLoading || loadingBlob} 
                            photoPreview 
                        />
                        <PhotosButton
                            onClick={handleOpenMenu}
                            disabled={newPhotoLoading || loadingBlob}
                            sx={{
                                backgroundImage: `url(${blob})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundColor: "transparent",
                                border: "1px solid transparent",
                                height: 184,
                                width: 184
                            }}
                        />
                    </Box>
                )}
                <MenuPhoto
                    noUpdate={noUpdate}
                    open={openMenu}
                    anchorEl={anchorMenu}
                    onClose={handleCloseMenu}
                    onOpenDisplay={handleOpenDisplay}
                    onChange={e => handleChange(e, "set")}
                    newPhotoValue={newPhotoValue}
                    onOpenDelete={handleOpenDelete}
                />
                <DialogDisplayPhoto
                    blob={blob}
                    open={openDisplay}
                    onClose={handleCloseDisplay}
                />
                <PhotoCropper
                    aspect={aspect}
                    open={openCropper}
                    onClose={handleCloseCropper}
                    image={newPhotoPreview}
                    onSubmit={(cropArea, rotation, aspect, cb) => handleSubmit(cropArea, rotation, aspect, cb, "set")}
                />
                <DialogDelete
                    open={openDelete} 
                    onClose={handleCloseDelete} 
                    loading={deleteLoading}
                    onDelete={handleDelete}
                />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <UploadButtonContainer
                accept="image/*"
                onChange={e => handleChange(e, "new")}
                value={newPhotoValue}
                disabled={newPhotoLoading || loadingBlob}
            >
                {ButtonEmpty ? (
                    ButtonEmpty({ newPhotoLoading, loadingBlob })
                ):(
                    <Box sx={{ 
                        position: "relative", 
                        width: "fit-content"
                    }}>
                        <Badge 
                            component="span" 
                            loading={newPhotoLoading || loadingBlob} 
                        />
                        <PhotosButton
                            component="span"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 184,
                                width: 184
                            }}
                        >
                            <i
                                className="iconoir-home-alt-slim"
                                style={{
                                    fontSize: 170,
                                    color: scssVar.greyLight
                                }}
                            ></i>
                        </PhotosButton>
                    </Box>
                )}
            </UploadButtonContainer>
            <PhotoCropper
                aspect={aspect}
                open={openCropper}
                onClose={handleCloseCropper}
                image={newPhotoPreview}
                onSubmit={(cropArea, rotation, aspect, cb) => handleSubmit(cropArea, rotation, aspect, cb, "new")}
            />
        </Fragment>
    )
}

export default PhotoUploader