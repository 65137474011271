import { createSelector } from "@reduxjs/toolkit"

export const selectOrg = (state) => state.account.org

export const selectTeams = (state) => {
    const items = state.account.teams.items || {}
    const isLoading = state.account.teams.loading
    const error = state.account.teams.error
    const fetched = state.account.teams.fetched
    return { items, isLoading, error, fetched }
}

// memo ok
export const selectAllTeamsUsers = createSelector([
    (state) => selectTeams(state).items.data || []
], (teams) => {
    const data = []
    teams.forEach(d => d.users.forEach(u => data.push(u)))
    return data
})