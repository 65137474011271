import { Grid } from "@mui/material"
import { Fragment } from "react"
import FieldControl from "../../../../common/components/FieldControl"
import useCharacteristicItems from "../../../estimate/hooks/useCharacteristicItems"
import { scssVar } from "../../../../app/scssVar"

const Step03 = ({ 
    estimation, 
    onCheck, 
    orgColor 
}) => {
    const charact = useCharacteristicItems(estimation)
    const items = [...charact].map(d => ({ ...d, bg: scssVar.greyLighter, bgChecked: orgColor.light, colorChecked: orgColor.main }))

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FieldControl
                        label="Espaces extérieurs"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "ext")}
                        onChange={(name, checked) => onCheck(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Dépendances"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "depend")}
                        onChange={(name, checked) => onCheck(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Installations"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "install")}
                        onChange={(name, checked) => onCheck(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Équipements"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "equip")}
                        onChange={(name, checked) => onCheck(name, checked)}
                        xs={6}
                        md={4}
                        lg={3}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Environnement"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "env")}
                        onChange={(name, checked) => onCheck(name, checked)}
                        xs={6}
                        md={4}
                        lg={3}
                    />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Step03