import { Box } from "@mui/material"
import DesktopNav from "../../common/components/nav/DesktopNav"
import MobileNav from "../../common/components/nav/MobileNav"
import PageWrapper from "../../common/components/PageWrapper"
import DiscoverDigipi from "../fetchedContent/DiscoverDigipi"
import Intro from "./components/Intro"

const Home = () => {
    return (
        <PageWrapper>
            <DesktopNav />
            <Box sx={{ m: { xs: 2, sm: 6 } }} />
            <Intro />
            <Box sx={{ m: 10 }} />
            <DiscoverDigipi />
            <MobileNav />
        </PageWrapper>
    )
}

export default Home