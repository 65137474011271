import Wrapper from "../../components/Wrapper"
import Margin from "../../components/Margin"
import MandantsList from "../components/MandantsList"
import Footer from "../../components/Footer"
import SectionTitle from "../components/SectionTitle"
import { NatureAndAddress } from "../components/HousingInfos"
import AvenantObject from "../components/AvenantObject"
import AvenantSignature from "../components/AvenantSignature"
import HeaderAvenant from "../components/HeaderAvenant"

const Avenant = ({ scale, docId, id, disabled }) => {
    return (
        <Wrapper id={docId} scale={scale}>
            <p style={{ fontWeight: 800, fontSize: 20 }}><u>AVENANT AU MANDAT DE VENTE</u></p>

            <HeaderAvenant />

            <Margin m={30} />

            <MandantsList readOnly />

            <Margin m={30} />

            <SectionTitle style={{ width: "100%" }}>
                BIEN CONCERNÉ
            </SectionTitle>

            <Margin m={7} />

            <NatureAndAddress readOnly />

            <Margin m={30} />

            <AvenantObject id={id} readOnly={disabled} />

            <AvenantSignature id={id} readOnly={disabled} />

            <Footer />
        </Wrapper>
    )
}

export default Avenant