import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import Margin from "../../components/Margin"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputSelect from "../../components/InputSelect"

const Duration = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const value = useSelector(selectMandatEditor2).duree_mandat

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(0)
    }

    return (
        <Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{
                    fontSize: 12,
                    lineHeight: "12px",
                    marginRight: 3
                }}>
                    Le présent mandat aura une durée de :
                </p>
                <InputSelect
                    value={value || ""}
                    onChange={handleChange}
                    name="duree_mandat"
                    style={{
                        width: 35
                    }}
                >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>24</option>
                </InputSelect>
                <p style={{
                    fontSize: 12,
                    lineHeight: "12px",
                    marginLeft: 3,
                }}>
                    <span style={{ fontWeight: 600 }}>mois</span> à compter de sa signature. Il se reconduira par tacite reconduction pour une période
                </p>
            </div>
            <Margin m={3} />
            <p style={{
                fontSize: 12,
                lineHeight: "12px",
            }}>
                identique à celle inscrite au mandat. 
                <span style={{
                    fontWeight: 600
                }}>
                    {" "}Un mandat ne pourra pas dépasser 24 mois
                </span>
            </p>
        </Fragment>
    )
}

export default Duration