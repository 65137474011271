import { useSelector } from "react-redux"
import { m2 } from "../../../common/utils"

const useSurfacesEtPiecesItems = () => {
    const housing = useSelector(state => state.estimate.housing)
    const estimation = useSelector(state => state.estimate.estimation)

    return [
        { label: "Surface habitable", value: housing.surface_reelle_bati, unit: m2, displayed: true, nd: "Non définie" },
        { label: "Surface du terrain", value: housing.surface_terrain_totale, unit: m2, displayed: true, nd: "Non définie" },
        { label: "Nombre de pièces", value: housing.nombre_pieces_principales, unit: null, displayed: true, nd: "Non défini" },
        { label: "Nombre de chambres", value: estimation.nb_chambres, unit: null, displayed: true, nd: "Non défini" },
        { label: "Surface de la pièce de vie", value: estimation.surface_piece_de_vie, unit: null, displayed: true, nd: "Non définie" },
        { 
            label: "Duplex", 
            value: estimation.duplex ? "Oui" : "Non", 
            unit: null, 
            displayed: housing.is_flat, 
            // displayed: true,
            nd: "Non défini" 
        },
        { 
            label: "Étage de l'appartement", 
            value: estimation.etage_bien === "0" ? "Rez-de-chaussée" : estimation.etage_bien, 
            unit: null, 
            displayed: housing.is_flat, 
            // displayed: true,
            nd: "Non défini" 
        },
        { 
            label: "Nombre d'étages du bâtiment", 
            value: estimation.etages_batiment, 
            unit: null, 
            displayed: housing.is_flat, 
            // displayed: true,
            nd: "Non défini" 
        },
    ]
}

export default useSurfacesEtPiecesItems