import { useDispatch, useSelector } from "react-redux"
import PhotoUploader from "../../../photoUploader/PhotoUploader"
import { getCroppedImg } from "../../../photoUploader/settings"
import Margin from "../../components/Margin"
import { selectDemandeVisiteState, visitsActions } from "../visitsSlice"
import { addAlert } from "../../../alerts/alertsSlice"

const Upload = ({ visitorId, isExporting }) => {
    const dispatch = useDispatch()
    const visitor = useSelector(selectDemandeVisiteState)[visitorId]
    const blob = visitor.cardId

    const handleSubmit = async (cropArea, rotation, aspect, file) => {
        try {
            const croppedImage = await getCroppedImg(URL.createObjectURL(file), cropArea, rotation)
            const croppedBlob = URL.createObjectURL(croppedImage)
            dispatch(visitsActions.setVisitor({ name: "cardId", value: croppedBlob, pageId: "demande_visite", visitor: visitorId }))
        } catch (e) {
            console.error(e)
            dispatch(addAlert("Une erreur est survenue lors du redimensionnement de l'image, veuillez réessayer", "error"))
        }
    }

    const handleDelete = () => {
        dispatch(visitsActions.setVisitor({ name: "cardId", value: null, pageId: "demande_visite", visitor: visitorId }))
    }

    return (
        <PhotoUploader
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            blob={blob}
            withCropper
            noUpdate
            aspect={3/2}
            ButtonEmpty={() => {
                return (
                    <div style={{ 
                        cursor: "pointer", 
                        width: 270, 
                        height: 180, 
                        border: "1px solid black",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {!isExporting && <p>Cliquez pour importer une image</p>}
                    </div>
                )
            }}
            ButtonBlob={({ handleOpenMenu }) => {
                return (
                    <img
                        onClick={handleOpenMenu}
                        src={blob}
                        alt="cni 1"
                        style={{
                            cursor: "pointer",
                            width: 270
                        }}
                    />
                )
            }}
        />
    )
} 

const CardId = ({ isExporting }) => {
    return (
        <div>
            <p style={{ fontWeight: 600, lineHeight: "17px" }}>
                Dans le respect des protocoles sanitaires et de la loi Tracfin, merci d'insérer les copies de vos pièces d'identité.
            </p>
            <Margin m={10} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Upload visitorId="visiteur1" isExporting={isExporting} />
                <Margin m={10} />
                <Upload visitorId="visiteur2" isExporting={isExporting} />
            </div> 
        </div>
    )
}

export default CardId