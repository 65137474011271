import { useSelector } from "react-redux"
import { selectOrg } from "../../../account/selectors"
import Empty from "../../components/Empty"
import Footer from "../../components/Footer"
import GreyBox from "../../components/GreyBox"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"
import Wrapper from "../../components/Wrapper"
import { selectMandatEditor2 } from "../../mandat/mandatSlice"
import CardId from "../components/CardId"
import Description from "../components/Description"
import Infos from "../components/Infos"
import Visitors from "../components/Visitors"

const pageId = "demande_visite"

const Page01 = ({ scale, isExporting }) => {
    const orgName = useSelector(selectOrg).name
    const numberMandat = useSelector(selectMandatEditor2).mandat_id

    return (
        <Wrapper id={pageId} scale={scale}>
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <OrgLogo wrapperStyle={{ width: "100%" }} />
                </div>
                <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ fontWeight: 600, fontSize: 35 }}>DEMANDE DE VISITE</p>
                </div>
            </div>

            <Margin m={40} />

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
            }}>
                <p style={{ marginRight: 3, fontWeight: 600 }}>Numéro de mandat du bien à vendre :</p>
                {numberMandat !== null ? (
                    <GreyBox style={{ padding: "0 2px", fontSize: 16, display: "flex", alignItems: "center" }}>{numberMandat}</GreyBox>
                ):(
                    <Empty>N° mandat</Empty>
                )}
            </div>

            <Margin m={22} />
            
            <p style={{ fontWeight: 600, lineHeight: "18px", fontSize: 16 }}>
                Dans le cadre de sa mission d’intermédiaire (prévue par la loi Hoguet), {orgName}, 
                informe ses mandants des visites qui seront organisées dans son logement.
            </p>

            <Margin m={22} />

            <p style={{ fontWeight: 600, lineHeight: "18px", fontSize: 16 }}>
                A ce titre, pour le respect de nos engagements, nous vous remercions de bien vouloir 
                compléter ce document en nous indiquant vos coordonnées.
            </p>

            <Margin m={25} />

            <Visitors pageId={pageId} />

            <Margin m={10} />

            <Description />

            <Margin m={20} />

            <Infos />

            <Margin m={5} />

            <div style={{ display: "flex"}}>
                <p style={{ fontWeight: 600, width: 300 }}>Signatures :</p>
                <CardId isExporting={isExporting} />
            </div>

            <Margin m={10} />

            <p style={{ lineHeight: "17px" }}>
                « Le visitant est informé en signant ce document, qu’il s’interdit de contacter directement le vendeur afin d’acquérir ce
                bien. Dans le cas contraire, le visitant (acquéreur potentiel) est conscient et accepte sans conditions, qu’en
                cas d’acquisition sans informer l’agent immobilier qui lui a présenté le bien, qu’il sera redevable des
                honoraires de l'agence quelque soit celui qui en a la charge sur le mandat. »
            </p>

            <Margin m={20} />

            <p style={{ lineHeight: "17px" }}>
                «<b>MESURES BARRIERES</b> : Vous confirmez être informé(s) en signant ce document, que vous êtes incité(s) à respecter les
                obligations liées aux risques de propagation de tout virus. Si possible en venant à la visite, merci de vous équiper d'un
                masque, de ne pas toucher les meubles et les portes, si le vendeur le demande de retirer ses chaussures. Nous
                disposerons de gel hydro-alcoolique à l'entrée comme à la sortie de cette visite. »
            </p>

            <Margin m={20} />

            <p style={{ lineHeight: "17px" }}>
                <b>CHARTE QUALITÉ & LOI TRACFIN</b> : Dans le cadre de nos engagements envers les vendeurs, nous vous remercions de
                nous adresser la copie de votre pièce d'identité en accompagnement de cette demande.
            </p>

            <Footer />
        </Wrapper>
    )
}

export default Page01