import { scssVar } from "../../../app/scssVar"
import Footer from "../synthesis/components/Footer"
import Header from "../synthesis/components/Header"

const PDFContainer = ({ 
    scale, 
    children, 
    orientation, 
    id, 
    style 
}) => {
    const width = orientation === "landscape" ? 1295 : 925 
    const height = orientation === "landscape" ? 925 : 1295

    if (scale === undefined) console.error("missing scale")

    return (
        <div style={{
            width: `calc(${width}px * ${scale})`,
            height: `calc(${height}px * ${scale})`,
            overflow: "hidden",
            display: "block",
            boxShadow: scssVar.shadow2,
            WebkitBoxShadow: scssVar.shadow2,
        }}>
            <div style={{
                width,
                height,
                background: "white",
                transform: `scale(${scale})`,
                transition: "transform .6",
                transformOrigin: "left top"
            }}>
                <div id={id} style={Object.assign({}, {
                    width: "100%",
                    height: "100%",
                    color: "black",
                    position: "relative",
                    fontSize: 15
                }, style)}>
                    {children}
                </div>
            </div>
        </div>
    )
}

const Wrapper = ({ 
    children, 
    id, 
    scale 
}) => {
    if (id.includes("avenant_mandat")) {
        return (
            <PDFContainer 
                scale={scale} 
                id={id}
                style={{
                    padding: "35px 25px 15px"
                }}
            >
                {children}
            </PDFContainer>
        )
    }

    switch (id) {
        case "synthesis_p1":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id} 
                    style={{ 
                        background: "#f8f8f8"
                    }}
                >
                    {children}
                    <Footer />
                </PDFContainer>
            )

        case "synthesis_p2":
        case "synthesis_p3":
        case "synthesis_p4":
        case "synthesis_p5":
        case "synthesis_p6":
        case "synthesis_p7":
        case "synthesis_p8":
        case "synthesis_p9":
        case "synthesis_p10":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "160px 70px 0"
                    }}
                >
                    <Header />
                    {children}
                    <Footer />
                </PDFContainer>
            )

        case "ad_portrait":
            return (
                <PDFContainer 
                    scale={scale} 
                    orientation="portrait" 
                    id={id}
                    style={{
                        padding: "200px 35px 0"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "ad_landscape":
            return (
                <PDFContainer 
                    scale={scale} 
                    orientation="landscape" 
                    id={id}
                    style={{
                        padding: "198px 28px 0"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "mandat_p1":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "10px 25px 15px"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "mandat_p2":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "35px 25px 15px"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "mandat_p3":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "45px 25px 15px"
                    }}
                >
                    {children}
                </PDFContainer>
            )
        
        case "mandat_p4":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "35px 25px 15px"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "demande_visite":
        case "compte_rendu":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "55px 45px 15px"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "buy_p1":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "20px 45px 0"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        case "tracfin_p1":
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                    style={{
                        padding: "20px 45px 0"
                    }}
                >
                    {children}
                </PDFContainer>
            )

        default:
            return (
                <PDFContainer 
                    scale={scale} 
                    id={id}
                >
                    {children}
                </PDFContainer>
            )
    }
}

export default Wrapper