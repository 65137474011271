import { Fragment } from "react"
import SectionTitle from "./SectionTitle"
import InputSelect from "../../components/InputSelect"
import { useDispatch, useSelector } from "react-redux"
import InputNumber from "../../components/InputNumber"
import Margin from "../../components/Margin"
import { mandatActions, selectAvenantEditor } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputCheckbox from "../../components/InputCheckbox"
import InputTextArea from "../../components/InputTextArea"
import GreyBox from "../../components/GreyBox"
import { numberWithSpaces } from "../../../../common/utils"

const PrixNetVendeur = ({ id }) => {
    const editor = useSelector(state => selectAvenantEditor(state, id))

    // const value = useMemo(() => {
    //     if (editor.new_fee_type === "vendeur") {
    //         return Math.round(editor.new_displayed_value * (1 - (editor.new_fee_rates / 100))) // NET = PREZ * (1 - TAUX)
    //     } else if (editor.new_fee_type === "acquereur") {
    //         return Math.round(editor.new_displayed_value / (1 + (editor.new_fee_rates / 100))) // NET = PREZ / (1  +TAUX)
    //     } else {
    //         return 0
    //     }
    // }, [editor.new_fee_type, editor.new_displayed_value, editor.new_fee_rates])

    return (
        <Fragment>
            <p style={{ fontSize: 13, lineHeight: "15px", marginRight: 3 }}>
                Nouveau prix net vendeur :
            </p>
            <GreyBox style={{
                width: 115,
                display: "flex",
                alignItems: "center",
                padding: "0 4px"
            }}>
                {numberWithSpaces(editor.new_net_value)}
            </GreyBox>
            <p style={{ fontSize: 13, lineHeight: "15px", marginLeft: 3 }}>€</p>
        </Fragment>
    )
}

const AvenantObject = ({ id, readOnly }) => {
    const dispatch = useDispatch()
    const editor = useSelector(state => selectAvenantEditor(state, id))
    const { clearTimeout, saveAvenant } = useSaveMandat()

    const handleChange = (e) => {
        const { name, value, type } = e.target
        clearTimeout()
        dispatch(mandatActions.setAvenant({ id, name, value }))
        saveAvenant(id, type === "select-one" ? 0 : 1000)
    }

    const handleChangeObject = (e) => {
        const { name, checked } = e.target
        let value

        clearTimeout()

        if (checked) {
            value = [...editor.amendment_object, name]
        } else {
            value = editor.amendment_object.filter(d => d !== name)
        }

        dispatch(mandatActions.setAvenant({ id, name: "amendment_object", value }))

        saveAvenant(id, 0)
    }

    return (
        <Fragment>
            <SectionTitle style={{ width: "100%" }}>
                OBJET(S) DE L'AVENANT
            </SectionTitle>

            <Margin m={7} />

            <div style={{ height: 340 }}>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <InputCheckbox 
                        disabled={readOnly}
                        name="prix"
                        checked={editor.amendment_object?.includes("prix") || false} 
                        onChange={handleChangeObject} 
                    />
                    <p style={{ fontSize: 14, lineHeight: "16px", fontWeight: 600, marginLeft: 5 }}>
                        <u>CHANGEMENT DE PRIX</u>
                    </p>
                </div>

                <Margin m={3} />

                {editor.amendment_object?.includes("prix") && (
                    <Fragment>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ fontSize: 13, lineHeight: "15px", marginRight: 3 }}>
                                Nouveau prix de présentation :
                            </p>
                            <InputNumber
                                disabled={readOnly}
                                value={editor.new_displayed_value || ""} 
                                name="new_displayed_value"
                                onChange={handleChange}
                                style={{ width: 115 }}
                            />
                            <p style={{ fontSize: 13, lineHeight: "15px", marginLeft: 3, marginRight: 30 }}>€</p>
                            <PrixNetVendeur id={id} />
                        </div>
                    </Fragment>
                )}

                <Margin m={7} />

                <div style={{ display: "flex", alignItems: "center" }}>
                    <InputCheckbox 
                        disabled={readOnly}
                        name="honoraires"
                        checked={editor.amendment_object?.includes("honoraires") || false} 
                        onChange={handleChangeObject} 
                    />
                    <p style={{ fontSize: 14, lineHeight: "16px", fontWeight: 600, marginLeft: 5 }}>
                        <u>CHANGEMENT DE RÉMUNÉRATION DU MANDATAIRE TTC</u>
                    </p>
                </div>

                <Margin m={3} />

                {editor.amendment_object?.includes("honoraires") && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ fontSize: 13, lineHeight: "15px", marginRight: 3 }}>Nouveau taux :</p>
                        <InputNumber 
                            disabled={readOnly}
                            value={editor.new_fee_rates || ""} 
                            name="new_fee_rates"
                            onChange={handleChange}
                            style={{ width: 70 }}
                        />
                        <p style={{ fontSize: 13, lineHeight: "15px", marginLeft: 3, marginRight: 30 }}>%</p>
                        <p style={{ fontSize: 13, lineHeight: "15px", marginRight: 3 }}>À la charge :</p>
                        <InputSelect
                            disabled={readOnly}
                            value={editor.new_fee_type || ""} 
                            name="new_fee_type"
                            onChange={handleChange}
                            style={{ width: 150, marginRight: 30 }}
                        >
                            <option value="acquereur">de l'acquéreur</option>
                            <option value="vendeur">du vendeur</option>
                        </InputSelect>
                        <PrixNetVendeur id={id} />
                    </div>
                )}

                <Margin m={7} />

                <div style={{ display: "flex", alignItems: "center" }}>
                    <InputCheckbox 
                        name="duree"
                        disabled={readOnly}
                        checked={editor.amendment_object?.includes("duree") || false} 
                        onChange={handleChangeObject} 
                    />
                    <p style={{ fontSize: 14, lineHeight: "16px", fontWeight: 600, marginLeft: 5 }}>
                        <u>CHANGEMENT DE LA DURÉE DU MANDAT</u>
                    </p>
                </div>

                <Margin m={3} />

                {editor.amendment_object?.includes("duree") && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ fontSize: 13, lineHeight: "15px", marginRight: 3 }}>Le présent mandat aura une durée de</p>
                        <InputSelect
                            disabled={readOnly}
                            value={editor.additional_duration || ""}
                            name="additional_duration"
                            onChange={handleChange}
                            style={{ width: 35 }}
                        >
                            <option value="" />
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>24</option>
                        </InputSelect>
                        <p style={{ fontSize: 13, lineHeight: "15px", marginLeft: 3 }}>
                            mois supplémentaire{Number(editor.additional_duration) > 1 ? "s" : ""}
                        </p>
                    </div>
                )}

                <Margin m={7} />

                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <InputCheckbox 
                        disabled={readOnly}
                        name="lots"
                        checked={editor.amendment_object?.includes("lots") || false} 
                        onChange={handleChangeObject} 
                    />
                    <p style={{ fontSize: 14, lineHeight: "16px", fontWeight: 600, marginLeft: 5 }}>
                        <u>CHANGEMENT DES LOTS CÉDÉS</u>
                    </p>
                </div>

                <Margin m={3} />

                {editor.amendment_object?.includes("lots") && (
                    <InputTextArea
                        disabled={readOnly}
                        value={editor.new_sold_lots || ""}
                        name="new_sold_lots"
                        onChange={handleChange}
                        style={{ width: "100%", height: 106 }}
                    />
                )}
            </div>
        </Fragment>
    )
}

export default AvenantObject