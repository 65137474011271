import { Dialog, DialogActions, DialogContent, DialogTitle, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { deleteUser } from "../actions"

const initialState = {
    open: false
}

const DeleteUser = ({ user, team }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const loading = user.deleteUserLoading || false

    useEffect(() => {
        return () => {
            setState(initialState)
        }
    }, [])

    const handleOpen = () => {
        setState(s => ({ ...s, open: true }))
    }

    const handleClose = () => {
        setState(s => ({ ...s, open: false }))
    }

    const handleDeleteUser = () => {
        dispatch(deleteUser(user.id, team.team_id))
    }

    return (
        <Fragment>
            <ListItemButton 
                onClick={handleOpen}
                sx={{
                    borderBottom: 1,
                    borderColor: scssVar.greyLight,
                }}
            >
                <ListItemText 
                    primary="Supprimer" 
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: scssVar.danger
                        },
                    }}
                />
                <i 
                    className="iconoir-cancel" 
                    style={{ 
                        color: scssVar.danger,
                        fontSize: 24, 
                        marginRight: 7 
                    }} 
                />
            </ListItemButton>
            <Dialog 
                open={state.open} 
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Supprimer {user.name} ?
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                    }}>
                        {user.name} sera supprimé(e) de l'agence : {team.team_name}.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        loading={loading}
                        disabled={loading}
                        onClick={handleDeleteUser}
                        sx={{
                            width: 150
                        }}
                    >
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteUser