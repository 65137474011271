import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCoverBlob, selectCoverBlob } from "../photosSlice"

const useCoverBlob = () => {
    const dispatch = useDispatch()
    const blob = useSelector(selectCoverBlob)

    useEffect(() => {
        dispatch(getCoverBlob())
    }, [dispatch])

    return { 
        src: blob.src, 
        loading: blob.loading, 
        id: blob.id 
    }
}

export default useCoverBlob