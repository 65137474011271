import { Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../app/scssVar"
import TooltipStyled from "../../../common/components/TooltipStyled"
import ShapleyChartDesktop from "./components/ShapleyChartDesktop"
import ShapleyChartMobile from "./components/ShapleyChartMobile"

export const shapleyTitle = "Principaux critères d'évaluation"

const Title = () => (
    <Box sx={{
        pt: "6px",
        pr: "12px",
        pl: "12px",
        mb: 2,
    }}>
        <Typography sx={{ 
            fontFamily: scssVar.fontFamily, 
            color: "black", 
            fontWeight: 700, 
            fontSize: { xs: 17, sm: 20 },
            display: "flex", 
            alignItems: "center",
        }}>
            {shapleyTitle}
            <TooltipStyled
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                    <Fragment>
                        <Typography sx={{ 
                            fontFamily: scssVar.fontFamily, 
                            display: "none",
                            "@media (max-width: 700px)": {
                                display: "block"
                            }
                        }}>
                            Cliquez sur les barres pour afficher le détail
                        </Typography>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily, 
                            "@media (max-width: 700px)": {
                                display: "none"
                            }
                        }}>
                            Passez la souris sur les barres pour afficher le détail
                        </Typography>
                    </Fragment>
                }
            >
                <i 
                    className="iconoir-info-empty"
                    style={{ 
                        fontSize: 21,
                        marginLeft: 6,
                        color: "black",
                    }}
                ></i>
            </TooltipStyled>
        </Typography>
    </Box>
)

export const shapleyDescription = `
    Synthèse des caractéristiques qui entraînent une plus-value/moins-value du prix du bien estimé. Ces caractéristiques combinent les 
    informations saisies avec les paramètres de notre modèle de Machine Learning pour fournir une analyse des facteurs d'influence 
    prépondérants dans l'estimation finale.
`

const Description = () => (
    <Typography sx={{ 
        fontFamily: scssVar.fontFamily, 
        color: "grey",
        fontSize: 13,
        mb: 3,
        pl: "12px",
    }}>
        {shapleyDescription}
    </Typography>
)

const ShapleyDesktop = () => (
    <Box sx={{ 
        width: "fit-content",
        margin: "0 auto", 
        "@media (max-width: 700px)": {
            display: "none"
        }
    }}>
        <ShapleyChartDesktop />
    </Box>
)

const ShapleyMobile = () => (
    <Box sx={{ 
        pl: "12px", 
        pr: "12px", 
        pb: "12px", 
        display: "none",
        "@media (max-width: 700px)": {
            display: "block"
        }
    }}>
        <ShapleyChartMobile />
    </Box>
)

const Shapley = () => (
    <Box sx={{
        bgcolor: scssVar.greyLighter,
        borderRadius: "16px",
        p: "4px 4px 0"
    }}>
        <Title />
        <Description />
        <ShapleyDesktop />
        <ShapleyMobile />
    </Box>
)

export default Shapley