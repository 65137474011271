import { Box, Grid, Typography } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const Plan = ({ infos, onSelect, selected }) => {
    return (
        <Fragment>
            <Box sx={{
                bgcolor: scssVar.success,
                p: 2,
                mb: 3
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "white",
                    fontWeight: 700,
                    fontSize: 21
                }}>
                    {infos.message}
                </Typography>
            </Box>
            <Grid container spacing={0} sx={{ 
                mb: 4, 
                bgcolor: scssVar.greyLighter, 
                border: "1px solid " + scssVar.grey
            }}>
                {infos.tarifs.map((tarif, index) => 
                    <Grid key={index} item xs={12} sm={4} sx={{
                        borderRight: {
                            xs: 0,
                            sm: index === infos.tarifs.length - 1 ? 0 : ("1px solid " + scssVar.grey)
                        },
                        borderBottom: {
                            xs: index === infos.tarifs.length - 1 ? 0 : ("1px solid " + scssVar.grey),
                            sm: 0,
                        },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <Box sx={{
                            height: 70,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: scssVar.greyLight,
                            borderBottom: "1px solid " + scssVar.grey,
                            p: "5px"
                        }}>
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                textAlign: "center",
                                color: scssVar.primary,
                                fontWeight: 600,
                                lineHeight: "20px"
                            }}>
                                {tarif.engagement}
                            </Typography>
                        </Box>
                        <Box sx={{
                            height: 80,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: "5px"
                        }}>
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                textAlign: "center",
                                color: "black",
                                fontWeight: 600,
                                fontSize: 13.5
                            }}>
                                {tarif.prix}
                            </Typography>
                        </Box>
                        <Box sx={{
                            height: 50,
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <Button 
                                type="button"
                                variant={selected === tarif.price_id ? "success" : "primary"}
                                onClick={() => onSelect(tarif.price_id)}
                                sx={{
                                    fontSize: 14.5,
                                    fontWeight: 600,
                                    height: 35,
                                    width: 120
                                }}
                            >
                                {selected === tarif.price_id ? "Sélectionnée" : "S'abonner"}
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    )
}

export default Plan