import { useSelector } from "react-redux"

const AdSectionTitle = ({ children, style }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <p style={Object.assign({}, {
            color: userColor,
            borderBottom: "3px solid " + userColor,
            width: "fit-content",
            padding: "0 15px 4px 0",
            fontSize: 18,
            fontWeight: 700,
            whiteSpace: "nowrap"
        }, style)}>
            {children}
        </p>
    )
}

export default AdSectionTitle