import { useSelector } from "react-redux"
import { capitalizeFirstLetter, m2, numberWithSpaces } from "../../../../common/utils"
import DpeGseGradient from "../../../../common/components/DpeGseGradient"
import useCharacteristicItems from "../../../estimate/hooks/useCharacteristicItems"
import useConstructionItems from "../../../estimate/hooks/useConstructionItems"
import useEnvEtServicesItems from "../../../estimate/hooks/useEnvEtServicesItems"
import useInterieurEtDecoItems from "../../../estimate/hooks/useInterieurEtDecoItems"
import useVieDeQuartierItems from "../../../estimate/hooks/useVieDeQuartierItems"
import List from "../components/List"
import ListItem from "../components/ListItem"
import Margin from "../../components/Margin"
import SectionTitle from "../components/SectionTitle"
import Wrapper from "../../components/Wrapper"
import { selectEstimation } from "../../../estimate/selectors"

const Page04 = ({ scale }) => {
    const estimation = useSelector(selectEstimation)
    const constructionItems = useConstructionItems()
    const characteristicItems = useCharacteristicItems(estimation)
    const interieurEtDecoItems = useInterieurEtDecoItems()
    const vieDeQuartierItems = useVieDeQuartierItems()
    const envEtServicesItems = useEnvEtServicesItems()
    const dpe = estimation.dpe
    const gse = estimation.gse

    return (
        <Wrapper id="synthesis_p4" scale={scale}>

            <Margin m={30} />

            <SectionTitle color="rgb(173, 52, 51)">
                Etat du bien
            </SectionTitle>

            <Margin m={25} />

            <List>
                {constructionItems.map((d, i) =>
                    <ListItem key={i} index={i}>
                        {d.label} <b>{d.value !== null && d.value !== "" ? capitalizeFirstLetter(d.value) : d.nd}</b>
                    </ListItem>
                )}
                <ListItem index={2}>
                    Emission de Gaz à Effet de Serre (GES) {(gse === "NC" || gse === null) && <b>NC</b>}
                    <DpeGseGradient palette="gse" value={gse} />
                </ListItem>
                <ListItem index={3}>
                    Diagnostic de Performance Energétique (DPE) {(dpe === "nc" || dpe === null) && <b>NC</b>}
                    <DpeGseGradient palette="dpe" value={dpe} />
                </ListItem>
            </List>

            <Margin m={25} />

            <SectionTitle color="rgb(173, 52, 51)">
                Caractéristiques
            </SectionTitle>

            <Margin m={25} />

            {characteristicItems
                .filter(d => d.checked && d.displayed)
                .length ? (
                    <List>
                        {characteristicItems
                            .filter(d => d.checked && d.displayed)
                            .map((d, i) =>
                                <ListItem key={i} index={i}>
                                    <span style={{ 
                                        whiteSpace: "nowrap"
                                    }}>
                                        {d.text} 
                                    </span>
                                    {d.value !== null && d.value !== "" && ( 
                                        <b style={{ 
                                            marginLeft: 5 
                                        }}>
                                            {d.unit === m2 ? (
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {numberWithSpaces(d.value)}{d.unit}
                                                </span>
                                            ): (
                                                <span style={{
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {d.value}
                                                </span>
                                            )}
                                        </b> 
                                    )}
                                </ListItem>
                            )
                        }
                    </List>
                ):(
                    <p style={{
                        textAlign: "center",
                        fontWeight: 700
                    }}>
                        Aucune
                    </p>
                )
            }

            <Margin m={25} />

            <SectionTitle>
                Intérieur et décoration
            </SectionTitle>

            <Margin m={25} />

            <List>
                {interieurEtDecoItems.map((d, i) =>
                    <ListItem key={i} index={i}>
                        {d.label} <b>{d.value !== null && d.value !== "" ? d.value : d.nd}</b>
                    </ListItem>
                )}
            </List>

            <Margin m={25} />

            <SectionTitle>
                Environnements et services
            </SectionTitle>

            <Margin m={25} />

            <List>
                <ListItem 
                    index={0} 
                >
                    Vie de quartier {vieDeQuartierItems
                        .filter(d => d.checked && d.displayed)
                        .map((d, i) =>
                            <b key={i}>
                                {d.text}{i < vieDeQuartierItems.filter(d => d.checked && d.displayed).length - 1 ? ", " : ""}
                            </b>
                        )}
                </ListItem>
                 {envEtServicesItems
                    .filter(d => d.displayed)
                    .map((d, i) =>
                        <ListItem key={i} index={i+1}>
                            {d.label} <b>{d.value !== null && d.value !== "" ? d.value : d.nd}</b>
                        </ListItem>
                    )
                }
            </List>

        </Wrapper>
    )
}

export default Page04