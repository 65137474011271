import { Grid } from "@mui/material"
import UsageCost from "../../../steps/components/UsageCost"
import PrixPresentation from "./components/PrixPresentation"
import BudgetTotal from "./components/BugetTotal"
import Shapley from "../../../shapley/Shapley"
import CardLead from "./components/CardLead"
import { scssVar } from "../../../../../app/scssVar"
import { useSelector } from "react-redux"
import { selectIsLeadEstimation } from "../../../selectors"
import EstimationValue from "./components/EstimationValue"
import PrixNetVendeur from "./components/PrixNetVendeur"

const TabSynthese = () => {
    const isLeadEstimation = useSelector(selectIsLeadEstimation)

    return (
        <Grid container spacing={1.4}>
            {isLeadEstimation && (
                <Grid item xs={12}>
                    <CardLead />
                </Grid>
            )}
            <Grid item xs={12}>
                <EstimationValue />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <PrixNetVendeur />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <PrixPresentation />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <BudgetTotal />
            </Grid>
            <Grid item xs={12}>
                <Shapley />
            </Grid>
            <Grid item xs={12} sm={10} md={8}>
                <UsageCost yearly sx={{ wrapper: { bgcolor: scssVar.greyLighter } }} />
            </Grid>
        </Grid>
    )
}

export default TabSynthese