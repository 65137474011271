import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../common/setupApi";
import { addAlert } from "../alerts/alertsSlice";

export const registerUser = (teamId, token) => async (dispatch, getState) => {
    const state = getState()
    const lastName = state.registerUser.form.last_name
    const firstName = state.registerUser.form.first_name
    const email = state.registerUser.form.email
    const pwd1 = state.registerUser.form.pwd1
    const pwd2 = state.registerUser.form.pwd2

    if (teamId === null || token === null) {
        dispatch(addAlert("Vous n'avez pas l'autorisation.", "error"))

        return
    }

    if (pwd1 !== pwd2) {
        const errMsg = "Les mots de passe ne correspondent pas."
        dispatch(registerUserActions.setErrorFields({ name: "pwdMatch", error: [errMsg] }))
        dispatch(addAlert(errMsg, "error"))

        return
    }

    const payload = {
        "email": email.toLowerCase(),
        "first_name": firstName,
        "last_name": lastName,
        "pwd1": pwd1,
        "pwd2": pwd2
    }

    dispatch(registerUserActions.registerLoading())

    return api
        .post(`v1/registration/register/${teamId}/${token}/`, payload)
        .then(() => {
            dispatch(registerUserActions.registerSucceded())
            // Pas de login car il faut que l'admin active son compte
            // Le reset est géré par le composant à l'unmount
        }) 
        .catch(err => {
            if (err.response.data) {
                console.log(err.response.data)

                // console.log(typeof err.response.data)
                // hack pour err 500
                // if (typeof err.response.data === "string") {
                //     dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
                // }

                for (const property in err.response.data) {
                    const error = err.response.data[property]

                    dispatch(registerUserActions.setErrorFields({ name: property, error }))

                    error.forEach(d => dispatch(addAlert(d, "error")))
                }
            } else {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                const errMsg = err.message || "Une erreur est survenue, veuillez réessayer."
                console.log(errMsg)

                dispatch(registerUserActions.registerFailed({ error: errMsg }))

                dispatch(addAlert(errMsg, "error"))
            }
        })
}

const initialState = {
    form: {
        first_name: "",
        last_name: "",
        email: "",
        pwd1: "",
        pwd2: "",
        cgu: false,
    },
    errFields: {
        first_name: [],
        last_name: [],
        email: [],
        pwd1: [],
        pwd2: [],
        pwd_match: [],
        // Django ne détecte pas le case où 2 mdp ne correspondent pas 
        pwdMatch: []
    },
    registering: {
        loading: false,
        error: null,
        registered: false
    }
}

const registerUserSlice = createSlice({
    name: "registerUser",
    initialState,
    reducers: {
        setField: (state, action) => {
            state.form[action.payload.name] = action.payload.value
        },
        registerLoading: (state) => {
            state.registering.loading = true
            state.registering.error = null
            state.errFields = initialState.errFields
        },
        registerSucceded: (state) => {
            state.registering.loading = false
            state.registering.registered = true
        },
        registerFailed: (state, action) => {
            state.registering.loading = false
            state.registering.error = action.payload.error
        },
        setErrorFields: (state, action) => {
            state.registering.loading = false
            state.errFields[action.payload.name] = action.payload.error
        },
        reset: () => {
            return initialState
        }
    }
})

export const registerUserActions = registerUserSlice.actions

export const selectRegisterUserForm = (state) => state.registerUser.form

export const selectRegisterUserErrFields = (state) => state.registerUser.errFields

export default registerUserSlice.reducer