import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import useGalleryBlobs from "../../../photoUploader/hooks/useGalleryBlobs"
import useOrgBlob from "../../../photoUploader/hooks/useOrgBlob"

const ButtonDownloadSynthesis = ({ onClick, loading }) => {
    const orgBlob = useOrgBlob()
    const galleryBlobs = useGalleryBlobs()
    const loadingGalleryBlobs = galleryBlobs.filter(d => d.loading === true).length > 0

    return (
        <Button
            variant="success"
            onClick={onClick}
            loading={loading}
            disabled={
                loading
                || orgBlob.loading 
                || loadingGalleryBlobs
            }
            sx={{
                position: "fixed",
                bottom: `calc(20px + ${scssVar.sab})`,
                left: 0,
                right: 0,
                ml: "auto",
                mr: "auto",
                width: 200,
                zIndex: 1300,
            }}
        >
            Télécharger
            <i 
                className="iconoir-download"
                style={{
                    fontSize: 24,
                    marginLeft: 5,
                }}
            ></i>
        </Button>
    )
}

export default ButtonDownloadSynthesis