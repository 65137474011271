import { useDispatch, useSelector } from "react-redux"
import CostInfo from "./CostInfo"
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { periodicityChoices } from "./Step11Form"
import FieldControl from "../../../../common/components/FieldControl"
import { useEffect, useState } from "react"
import Button from "../../../../common/components/Button"
import { scssVar } from "../../../../app/scssVar"

const initialState = {
    showInfos: false
}

const DialogEnergyCostsEditor = ({ 
    open, 
    onClose, 
    onChange 
}) => {
    const dispatch = useDispatch()
    const periodicity = useSelector(state => state.estimate.periodicity)
    const working = useSelector(state => state.estimate.working)
    const estimation = useSelector(state => state.estimate.estimation)
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (!open) {
            setState(initialState)
        }
    }, [open])

    const handleChangePeriodicity = (name, newValue) => {
        dispatch({ type: "estimate/set_periodicity", payload: newValue })
    }

    const toggleInfos = () => {
        setState({ ...state, showInfos: !state.showInfos })
    }

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            fullWidth 
            maxWidth="md"
        >
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
                display: "flex",
                alignItems: "center",
            }}>
                Types des énergies
                <IconButton onClick={toggleInfos}>
                    <i className="iconoir-info-empty" style={{ fontSize: 20, color: scssVar.black }}></i>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    fontSize: 14,
                    fontFamily: scssVar.fontFamily,
                    mb: 2
                }}>
                    Ajoutez le détail de chaque type d’énergie afin d’effectuer le calcul global
                </DialogContentText>
                <CostInfo show={state.showInfos} onClose={toggleInfos} />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Périodicité"
                            type="checkbox"
                            disallowDeselect
                            choices={periodicityChoices}
                            selectedValue={periodicity}
                            onChange={handleChangePeriodicity}
                            xs={6}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display:"flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                            minHeight: "75px"
                        }}>
                            <label className="label has-text-weight-normal" style={{
                                fontSize: 14
                            }}>
                                Total énergies
                            </label>
                            <Box sx={{
                                width: "100%",
                                height: "100%",
                                bgcolor: scssVar.greyLighter,
                                borderRadius: "8px",
                                paddingLeft: "25px",
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    color: "black",
                                    fontWeight: 600
                                }}>
                                    {periodicity === "month" && (
                                        estimation.total_cout_energetique_mois
                                    )}
                                    {periodicity === "year" && (
                                        estimation.total_cout_energetique_an
                                    )}
                                    €
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Électricité" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_elec_mois : estimation.cout_energie_elec_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_elec_mois" : "cout_energie_elec_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_elec_mois || "") : (estimation.cout_energie_elec_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Gaz" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_gaz_mois : estimation.cout_energie_gaz_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_gaz_mois" : "cout_energie_gaz_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_gaz_mois || "") : (estimation.cout_energie_gaz_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Fioul" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_fioul_mois : estimation.cout_energie_fioul_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_fioul_mois" : "cout_energie_fioul_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_fioul_mois || "") : (estimation.cout_energie_fioul_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Bois" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_bois_mois : estimation.cout_energie_bois_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_bois_mois" : "cout_energie_bois_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_bois_mois || "") : (estimation.cout_energie_bois_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Pelet" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_pelet_mois : estimation.cout_energie_pelet_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_pelet_mois" : "cout_energie_pelet_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_pelet_mois || "") : (estimation.cout_energie_pelet_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Photovoltaïque" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_photovoltaique_mois : estimation.cout_energie_photovoltaique_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_photovoltaique_mois" : "cout_energie_photovoltaique_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_photovoltaique_mois || "") : (estimation.cout_energie_photovoltaique_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Aérothermie" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_aerothermie_mois : estimation.cout_energie_aerothermie_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_aerothermie_mois" : "cout_energie_aerothermie_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_aerothermie_mois || "") : (estimation.cout_energie_aerothermie_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Géothermie" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_geothermie_mois : estimation.cout_energie_geothermie_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_geothermie_mois" : "cout_energie_geothermie_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_geothermie_mois || "") : (estimation.cout_energie_geothermie_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldControl
                            label="Autres" 
                            isSuccess={periodicity === "month" ? estimation.cout_energie_autre_mois : estimation.cout_energie_autre_an}
                            onChange={onChange}
                            name={periodicity === "month" ? "cout_energie_autre_mois" : "cout_energie_autre_an"}
                            value={periodicity === "month" ? (estimation.cout_energie_autre_mois || "") : (estimation.cout_energie_autre_an || "") }
                            type="number"
                            placeholder="Montant"
                            icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            disabled={working}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="light"
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogEnergyCostsEditor