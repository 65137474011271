import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import FieldControl from "../../../../common/components/FieldControl";
import { api } from "../../../../common/setupApi";
import { addAlert } from "../../../alerts/alertsSlice";
import { updateHousing } from "../../actions";
import Button from "../../../../common/components/Button";
import { scssVar } from "../../../../app/scssVar";

const initialState = {
    open: false,
    newName: ""
}

const HousingName = () => {
    const dispatch = useDispatch()
    const housing = useSelector(state => state.estimate.housing)
    const working = useSelector(state => state.estimate.working)
    const [state, setState] = useState(initialState)
    const name = housing.name
    const emptyName = !state.newName.trim().length
    const notModified = state.newName === name

    useEffect(() => {
        if (state.open) {
            setState(s => ({ 
                ...s, 
                newName: name || ""
            }))
        }
    }, [state.open, name])

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleChangeEvalInfos = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState({
            ...state, 
            [name]: value
        })
    }

    const handleSubmitEvalInfos = async () => {
        dispatch({ 
            type: "estimate/set_working", 
            payload: true 
        })

        const payload = {
            ...housing,
            name: state.newName
        }

        return api
            .post("v1/housing/update", payload)
            .then(res => {
                dispatch(updateHousing(res.data))

                dispatch({ 
                    type: "estimate/set_working", 
                    payload: false 
                })

                setState({ ...state, open: false })

                dispatch(addAlert("Les infos ont bien été modifiées !", "success"))
            })
            .catch(err => {
                console.log(err.response)

                dispatch({ 
                    type: "estimate/set_working", 
                    payload: false 
                })

                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
            })
    }

    return (
        <Fragment>
            <Typography sx={{ 
                fontSize: 36,
                fontWeight: 700,
                color: "black",
                lineHeight: "48px",
                fontFamily: scssVar.fontFamily,
                overflow: "hidden",
                textOverflow: "ellipsis",
                "@media (max-width: 800px)": {
                    fontSize: 33,
                    lineHeight: "37px"
                },
                "@media (max-width: 600px)": {
                    fontSize: 30,
                    lineHeight: "33px"
                },
            }}>
                {name} 
                <IconButton 
                    onClick={handleOpen}
                    disabled={working}
                >
                    <i
                        className="iconoir-edit-pencil edit-icon"
                        style={{ 
                            fontSize: 24,
                            color: "black"
                        }}
                    ></i>
                </IconButton>
            </Typography>
            <Dialog 
                open={state.open} 
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    Information concernant l’évaluation
                </DialogTitle>
                <DialogContent>
                    <FieldControl
                        label="Nom" 
                        isSuccess={state.newName} 
                        value={state.newName || ""}
                        name="newName"
                        placeholder="Nom"
                        onChange={handleChangeEvalInfos}
                        type="text"
                        maxLength={50}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmitEvalInfos}
                        loading={working}
                        disabled={working || emptyName || notModified}
                        sx={{
                            width: 150
                        }}
                    >
                        Enregistrer
                        <i
                            className="iconoir-save-floppy-disk"
                            style={{
                                fontSize: 24,
                                marginLeft: 5,
                            }}
                        ></i>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default HousingName