import { Fragment } from "react"
import { useSelector } from "react-redux"
import { numberWithSpaces } from "../../../../common/utils"
import { selectCosts, selectEstimation, selectValues } from "../../../estimate/selectors"
import GreyBox from "../../components/GreyBox"

const Price = () => {
    const fraisAgence = useSelector(selectEstimation).frais_agence_absolu
    const { prixPresentation } = useSelector(selectCosts)
    const { prixNetVendeur } = useSelector(selectValues)
    const acquereurVendeur = useSelector(selectEstimation).a_charge_vendeur_ou_acquereur
    
    const isAcquereur = acquereurVendeur === "acquereur"

    return (
        <Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px",
                    marginRight: 3
                }}>
                    Somme : 
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 120
                }}>
                    {numberWithSpaces(prixPresentation)}
                </GreyBox>
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px",
                    marginLeft: 3,
                    marginRight: 30
                }}>
                    €
                </p>
                {isAcquereur && (
                    <Fragment>
                        <p style={{
                            fontSize: 13,
                            lineHeight: "13px",
                            fontWeight: 600,
                            marginRight: 3
                        }}>
                            Honoraires à la charge des acquéreurs : 
                        </p>
                        <GreyBox style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 90
                        }}>
                            {numberWithSpaces(fraisAgence)}
                        </GreyBox>
                        <p style={{
                            fontSize: 13,
                            lineHeight: "13px",
                            marginLeft: 3,
                            marginRight: 30
                        }}>
                            €
                        </p>
                        <p style={{
                            fontSize: 13,
                            lineHeight: "13px",
                            fontWeight: 600,
                            marginRight: 3
                        }}>
                            Prix net vendeur :
                        </p>
                        <GreyBox style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 120
                        }}>
                            {numberWithSpaces(prixNetVendeur)}
                        </GreyBox>
                        <p style={{
                            fontSize: 13,
                            lineHeight: "13px",
                            marginLeft: 3,
                        }}>
                            €
                        </p>
                    </Fragment>
                )}
            </div>
            {/* {isAcquereur && (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        fontWeight: 600,
                        marginRight: 3
                    }}>
                        Les honoraires sont à la charge des acquéreurs, Prix net vendeur : 
                    </p>
                    <GreyBox style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 120
                    }}>
                        {numberWithSpaces(prixNetVendeur)}
                    </GreyBox>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        marginLeft: 3,
                        marginRight: 40
                    }}>
                        €
                    </p>
                    <GreyBox style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 90
                    }}>
                        {numberWithSpaces(fraisAgence)}
                    </GreyBox>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        marginLeft: 3
                    }}>
                        €
                    </p>
                </div>
            )} */}
        </Fragment>
    )
}

export default Price