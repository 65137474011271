import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../common/setupApi";
import { selectEstimation } from "../../../estimate/selectors";
import { addAlert } from "../../../alerts/alertsSlice";

export const saveTracfin = () => async (dispatch, getState) => {
    const state = getState()
    const estimationId = selectEstimation(state).id
    const editor = selectTracfinEditor(state)
    const { estimation_id, ...payload } = editor

    dispatch(tracfinActions.saveLoading())

    return api
        .patch(`v1/housing/estimation/tracfin/${estimationId}/`, payload)
        .then(res => dispatch(tracfinActions.saveSucceeded({ data: res.data })))
        .catch(err => {
            console.log(err.response)
            dispatch(tracfinActions.saveFailed({ error: err.response?.data }))

            if (err.response?.data && err.response?.status) {
                if (err.response.status === 400) {
                    for (const property in err.response.data) {
                        const error = err.response.data[property] || []
                        error.forEach(d => dispatch(addAlert(d, "error")))
                    }
                } else {
                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                }
            } else {
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
            }
        })
}

export const getTracfin = (estimationId) => async (dispatch, getState) => {
    const state = getState()
    const items = selectTracfinDocs(state).data
    const alreadySelected = items.find(d => d.estimation_id === estimationId)

    if (alreadySelected) {
        return dispatch(tracfinActions.getSucceeded(alreadySelected))
    }

    dispatch(tracfinActions.getLoading())

    return api
        .get(`v1/housing/estimation/tracfin/${estimationId}/`)
        .then(res => dispatch(tracfinActions.getSucceeded(res.data)))
        .catch(err => dispatch(tracfinActions.getFailed(err.response?.data)))
}

const initialState = {
    editor: {},
    save: {
        saving: false,
        error: null,
        last: null
    },
    docs: {
        status: "idle",
        error: null,
        data: []
    }
}

const tracfinSlice = createSlice({
    name: "tracfin",
    initialState,
    reducers: {
        setField: (state, action) => {
            state.editor[action.payload.name] = action.payload.value
        },
        saveLoading: (state) => {
            state.save.saving = true
            state.save.error = null
        },
        saveSucceeded: (state, action) => {
            state.save.last = String(new Date())
            state.save.saving = false
            state.docs.data = 
                state.docs.data.map(d => d.estimation_id === action.payload.data.estimation_id ? action.payload.data : d)
        },
        saveFailed: (state, action) => {
            state.save.saving = false
            state.save.error = action.payload.error
        },
        getLoading: (state) => {
            state.docs.status = "pending"
            state.docs.error = null
        },
        getSucceeded: (state, action) => {
            state.docs.status = "fulfilled"
            state.docs.data.push(action.payload)
            state.editor = action.payload
        },
        getFailed: (state, action) => {
            state.docs.status = "rejected"
            state.docs.error = action.payload
        },
    },
    extraReducers: (builder) => 
        builder
            .addCase("auth/logout", () => initialState)
})

export const tracfinActions = tracfinSlice.actions

export const selectTracfinEditor = (state) => state.tracfin.editor

export const selectTracfinSaving = (state) => state.tracfin.save.saving

export const selectTracfinDocs = (state) => {
    const data = state.tracfin.docs.data
    const status = state.tracfin.docs.status
    const error = state.tracfin.docs.error
    const isLoading = status === "pending"
    return { data, status, error, isLoading }
}

export default tracfinSlice.reducer