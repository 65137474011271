import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Button from "../../../../common/components/Button"
import { api } from "../../../../common/setupApi"
import { addAlert } from "../../../alerts/alertsSlice"
import { updateHousing } from "../../actions"
import useStep from "../../hooks/useStep"

const ContinueEvalButton = ({ sellerFirstName, sellerLastName }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getStep = useStep()
    const currentStep = useSelector(state => state.estimate.estimation.current_step)
    const housing = useSelector(state => state.estimate.housing)
    const currentSellerFirstName = housing.prenom_vendeur
    const currentSellerLastName = housing.nom_vendeur
    const emptySeller = !sellerLastName.trim().length || !sellerFirstName.trim().length

    const handleClick = useCallback(() => {
        if (sellerFirstName !== currentSellerFirstName || sellerLastName !== currentSellerLastName) {
            const payload = {
                ...housing,
                nom_vendeur: sellerLastName,
                prenom_vendeur: sellerFirstName
            }

            dispatch({ 
                type: "estimate/set_working", 
                payload: true 
            })

            return api
                .post("v1/housing/update", payload)
                .then(res => {
                    dispatch(updateHousing(res.data))

                    dispatch({ 
                        type: "estimate/set_working", 
                        payload: false 
                    })

                    navigate(`/estimer/${getStep(currentStep).path}`)
                })
                .catch(err => {
                    console.log(err.response)

                    dispatch({ 
                        type: "estimate/set_working", 
                        payload: false 
                    })

                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                })
        } else {
            navigate(`/estimer/${getStep(currentStep).path}`)
        }
    }, [
        sellerFirstName, 
        sellerLastName,
        currentSellerFirstName,
        currentSellerLastName,
        currentStep, 
        getStep, 
        navigate, 
        housing, 
        dispatch
    ])

    return (
        <Button
            onClick={handleClick}
            disabled={emptySeller}
            sx={{ 
                height: 56, 
                width: 212
            }}
        >
            Continuer
            <i 
                className="iconoir-arrow-right"
                style={{
                    fontSize: 24,
                    marginLeft: 5
                }}
            ></i>
        </Button>
    )
}

export default ContinueEvalButton