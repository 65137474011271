import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"

const SectionTitle = ({ children }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <div style={{
            color: userColor,
            width: "100%",
            height: 33,
            borderBottom: "1px solid " + scssVar.grey
        }}>
            <div style={{
                borderBottom: "4px solid " + userColor,
                width: "fit-content",
                paddingBottom: 7
            }}>
                <p style={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: 21,
                    fontWeight: 700,
                }}>
                    {children}
                </p>
            </div>
        </div>
    )
}

export default SectionTitle