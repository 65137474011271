import { scssVar } from "../../app/scssVar"

const TopBarDrawer = () => {
    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1301,
            backgroundColor: "white"
        }}>
            <div style={{
                width: "100%",
                height: 56,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div style={{
                    width: 48,
                    height: 6,
                    backgroundColor: scssVar.grey,
                    borderRadius: "6px"
                }} />
            </div>
        </div>
    )
}

export default TopBarDrawer