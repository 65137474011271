import { Box } from "@mui/material"
import { scssVar } from "../../app/scssVar"

const aspectRatio = {
    "16:9": "56.25%",
    "3:2": "66.66%",
    "8:5": "62.5%",
    "1:1": "100%"
}

const Iframe = ({ 
    ar, 
    pt, 
    title, 
    src,
    googleDocs,
    sx
}) => {
    return (
        <Box sx={Object.assign({}, {
            position: "relative",
            overflow: "hidden",
            width: "100%",
            paddingTop: pt || aspectRatio[ar],
            backgroundColor: scssVar.greyLight,
        }, sx?.wrapper)}>
            <iframe
                src={googleDocs ? `https://docs.google.com/gview?url=${src}&embedded=true`: src}
                title={title}
                style={Object.assign({}, {
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0
                }, sx?.iframe)}
            />
        </Box>
    )
}

export default Iframe