import { useDispatch } from "react-redux"
import Button from "../../../../common/components/Button"

const ResetEvalButton = ({ onReset }) => {
    const dispatch = useDispatch()

    const handleReset = () => {
        dispatch({ type: "estimate/reset" })
        onReset()
    }

    return (
        <Button
            type="button"
            variant="primary-light"
            sx={{ 
                height: 56,
                width: 212
            }}
            onClick={handleReset}
        >
            Nouvelle évaluation
        </Button>
    )
}

export default ResetEvalButton