import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMainBlob, selectMainBlob } from "../photosSlice"

const useMainBlob = () => {
    const dispatch = useDispatch()
    const { blob, loading } = useSelector(selectMainBlob)

    useEffect(() => {
        dispatch(getMainBlob())
    }, [dispatch])

    return { blob, loading }
}

export default useMainBlob