import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputSelect from "../../components/InputSelect"
import GreyBox from "../../components/GreyBox"

const choices = [
    {label: "Maison", value: "maison"}, 
    {label: "Appartement", value: "appartement"}, 
    {label: "Chambre de service", value: "chambre_service"}, 
    {label: "Hôtel particulier", value: "hotel_particulier"}, 
    {label: "Mas", value: "mas"}, 
    {label: "Château", value: "chateau"}, 
    {label: "Longère", value: "longere"}, 
    {label: "Ferme", value: "ferme"}, 
    {label: "Chalet", value: "chalet"}, 
    {label: "Moulin", value: "moulin"}, 
    {label: "Grange", value: "grange"}, 
    {label: "Parking", value: "parking"}, 
    {label: "Terrain", value: "terrain"}
]

const Nature = ({ readOnly }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const value = useSelector(selectMandatEditor2).type_bien_detail

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(0)
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            width: 245,
        }}>
            <p style={{
                fontSize: 14,
                lineHeight: "14px",
                marginRight: 3
            }}>
                Nature :
            </p>
            {readOnly ? (
                <GreyBox style={{
                    width: 180,
                    padding: "0 4px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    {choices.find(d => d.value === value)?.label || ""}
                </GreyBox>
            ):(
                <InputSelect
                    name="type_bien_detail"
                    value={value || ""}
                    onChange={handleChange}
                >
                    <option value="" />
                    {choices.map((c, i) =>
                        <option key={i} value={c.value}>
                            {c.label}
                        </option>
                    )}
                </InputSelect>
            )}
        </div>
    )
}

export default Nature