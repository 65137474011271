export const getGenderTextFormat = (genderCode) => {
    switch (genderCode) {
        case "m":
            return "Monsieur"
        case "mme":
            return "Madame"
        case "ind":
            return "-"
        default:
            return ""
    }
}