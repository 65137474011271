import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import FieldControl from "../../../../../common/components/FieldControl"
import { api } from "../../../../../common/setupApi"
import { addAlert } from "../../../../alerts/alertsSlice"
import { updateEstimationsList } from "../../../actions"
import Button from "../../../../../common/components/Button"

const DialogEditHistory = ({ open, onClose }) => {
    const dispatch = useDispatch()
    const housing = useSelector(state =>state.estimate.housing)
    const estimation = useSelector(state => state.estimate.estimation)
    const name = useSelector(state => state.estimate.estimation.name)
    const annotations = useSelector(state => state.estimate.estimation.annotations)
    const client_potentiel = useSelector(state => state.estimate.estimation.client_potentiel)
    const [state, setState] = useState({
        name: "",
        annotations: "",
        client_potentiel: "",
        loading: false,
        error: null
    })

    useEffect(() => {
        if (open) {
            setState(s => ({ ...s, name, annotations, client_potentiel }))
        }
    }, [open, name, annotations, client_potentiel])

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState({
            ...state, 
            [name]: value
        })
    }

    const handleSubmit = async () => {
        dispatch({ type: "estimate/set_working", payload: true })

        setState({ ...state, loading: true, error: null })

        const estimationCopy = {
            ...estimation,
            name: state.name,
            annotations: state.annotations,
            client_potentiel: state.client_potentiel,
        }

        return api
            .post("v1/housing/estimation/compute", { housing, estimation: estimationCopy })
            .then(res => {
                dispatch({ type: "estimate/set_estimation", payload: res.data })
                dispatch({ type: "estimate/edit_estimation", payload: res.data })
                dispatch(updateEstimationsList("Synthesis"))
                dispatch(addAlert("Cette évaluation a bien été modifiée !", "success"))
                dispatch({ type: "estimate/set_working", payload: false })
                setState({ ...state, loading: false })
                onClose()
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
                setState({ ...state, error: err.response, loading: false })
            })
    }

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            fullWidth
        >
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
                display: "flex",
                alignItems: "center",
            }}>
                Informations concernant l’estimation
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FieldControl
                            label="Nom" 
                            isSuccess={state.name} 
                            onChange={handleChange}
                            value={state.name || ""}
                            type="text" 
                            placeholder="Nom"
                            name="name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldControl
                            label="Annotations" 
                            isSuccess={state.annotations} 
                            onChange={handleChange}
                            value={state.annotations || ""}
                            type="textarea" 
                            placeholder="Annoter votre estimation"
                            name="annotations"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldControl
                            label="Client potentiel" 
                            isSuccess={state.client_potentiel} 
                            onChange={handleChange}
                            value={state.client_potentiel || ""}
                            type="text" 
                            placeholder="Renseigner ici le client potentiellement intéressé par cette estimation"
                            name="client_potentiel"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="light"
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={state.loading || (state.name !== null && !state.name.trim().length)}
                    loading={state.loading}
                    sx={{ width: 150 }}
                >
                    Enregistrer
                    <i
                        className="iconoir-save-floppy-disk"
                        style={{
                            fontSize: 24,
                            marginLeft: 5,
                        }}
                    ></i>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogEditHistory