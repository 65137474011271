import { Box, ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import { scssVar } from "../../../app/scssVar"

const MenuItemNav = ({ 
    onClick, 
    sx, 
    icon, 
    iconColor, 
    label, 
    isAlert 
}) => {
    return (
        <MenuItem
            onClick={onClick} 
            sx={Object.assign({}, { 
                width: 215, 
                padding: "15px", 
                color: scssVar.primary,
            }, sx)}
        >
            <ListItemIcon style={{ minWidth: "32px", marginLeft: "3px" }}>
                <i 
                    className={icon}
                    style={{
                        fontSize: 24,
                        color: iconColor || scssVar.primary
                    }}
                ></i>
            </ListItemIcon>
            <ListItemText primary={label} sx={{
                "& .MuiTypography-root": {
                    fontFamily: scssVar.fontFamily, 
                }
            }} />
            {isAlert && (
                <Box sx={{
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "24px",
                    bgcolor: scssVar.danger,
                    color: "white",
                    fontWeight: 600,
                    fontSize: "14px",
                    mr: 1
                }}>
                    1
                </Box>
            )}
        </MenuItem>
    )
}

export default MenuItemNav