import { Box, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { scssVar } from "../../app/scssVar"
import Button from "../../common/components/Button"
import Logo from "../../common/components/Logo"
import { api } from "../../common/setupApi"
import { appName } from "../../common/utils"
import { addAlert } from "../alerts/alertsSlice"

const Opt = () => {
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get("t")
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)

        return api
            .get(`v1/profile/emails-opt-out/${token}/`)
            .then(() => {
                dispatch(addAlert(`Vous ne recevrez plus d'emails d'${appName}`, "success"))
            })
            .catch(() => {
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%"
        }}>
            <form 
                method="POST" 
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: 30
                }}
            >
                <Logo variant="black" sx={{ wrapper: { width: 115 } }} />
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: 25,
                    fontWeight: 700,
                    color: "black",
                    mt: 3,
                    mb: 3,
                    textAlign: "center"
                }}>
                    Ne plus recevoir d'emails d'{appName} ?
                </Typography>
                <Button
                    variant="danger"
                    loading={loading}
                    disabled={loading}
                >
                    Ne plus recevoir d'emails
                </Button>
            </form>
            <Link to="/" replace style={{
                textDecoration: "underline"
            }}>
                Retour vers {appName}
            </Link>
        </Box>
    )
}

export default Opt