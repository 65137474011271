import Footer from "../../../components/Footer"
import Margin from "../../../components/Margin"
import OrgLogo from "../../../components/OrgLogo"
import Wrapper from "../../../components/Wrapper"
import Comments from "../components/Comments"
import Eval from "../components/Eval"
import Identities from "../components/Identities"
import Quote from "../components/Quote"
import Signature from "../components/Signature"

const Page01 = () => {
    return (
        <Wrapper id="tracfin_p1" scale={1}>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <p style={{ fontWeight: 800, fontSize: 20 }}>
                    EVALUATION  DES  RISQUES - TRACFIN 
                </p>
                <OrgLogo wrapperStyle={{ width: 200 }} />
            </div>
            

            <Margin m={15} />

            <Identities />

            <Margin m={40} />

            <Eval />

            <Margin m={15} />

            <div style={{ display: "flex" }}>
                <div style={{ width: 450, marginRight: 75 }}>
                    <Quote />
                    <Margin m={10} />
                    <Comments />
                </div>
                <Signature />
            </div>

            <Footer />
        </Wrapper>
    )
}

export default Page01