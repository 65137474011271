import { Typography } from "@mui/material"
import { scssVar } from "../../app/scssVar"

const RenderTextWithLineBreak = ({ 
    text,
    color,
    fontSize,
    fontWeight,
    sx
}) => {
    return text
        // enlève les line-breaks au début et fin du texte
        .trim()
        // transforme en tableau quand line-break
        .split("\n")
        // map ce tableau, quand line-break, render un <br/>
        .map((d, i) => 
            d ? (
                <Typography 
                    key={i} 
                    sx={Object.assign({}, {
                        fontFamily: scssVar.fontFamily,
                        color: color || "black",
                        fontSize: fontSize || "16px",
                        fontWeight: fontWeight || 400,
                    }, sx)}
                >
                    {d}
                </Typography>
            ):(
                <br key={i} />
            )
        )
}

export default RenderTextWithLineBreak