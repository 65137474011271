import Margin from "../../components/Margin"
import SectionTitle from "../components/SectionTitle"
import Wrapper from "../../components/Wrapper"

const Page10 = ({ scale }) => {
    return (
        <Wrapper id="synthesis_p10" scale={scale}>

            <Margin m={65} />

            <SectionTitle>
                Méthode d’estimation
            </SectionTitle>

            <Margin m={20} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                Estimia, reprend les données sur plusieurs années de la base DVF (Etalab, valeurs foncières communiquées par l’Etat), celles des notaires et 
                des ventes renseignées par les professionnels de l’immobilier. Ces data sont mises à jour. Pour coller à l’actualité du marché immobilier, sont 
                exclus des bases, les biens exceptionnels ou trop atypiques, les valeurs viagères.
            </p>

            <Margin m={10} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                Digipi, l’éditeur d’estimia, a investi plus de 2 années de R&D (Recherche et développement), pour mettre en place ce moteur d’estimation fondé sur une 
                approche Maching Learning.
            </p>

            <Margin m={10} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                L’apprentissage, à partir des valeurs existantes, permet l’amélioration continue des base de données. Objectif : proposer un écart-type réduit du prix de 
                présentation du bien.
            </p>
        </Wrapper>
    )
}

export default Page10