import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import DesktopNav from "../../../common/components/nav/DesktopNav"
import MobileNav from "../../../common/components/nav/MobileNav"
import PageWrapper from "../../../common/components/PageWrapper"
import History from "./history/History"
import IDcard from "./idCard/IDcard"
import Options from "./options/Options"
import TabsNav from "./tabs/TabsNav"
import { selectHousing } from "../selectors"

const Synthesis = () => {
    const noHousingId = useSelector(selectHousing).id === null

    if (noHousingId) {
        return <Navigate to="/" />
    }

    return (
        <PageWrapper>
            <MobileNav />
            <DesktopNav />
            <Box sx={{ m: { xs: 2, sm: 3 } }} />
            <Box sx={{
                maxWidth: "1063px",
                width: "100%",
                margin: "0 auto"
            }}>
                <IDcard />
                <Options />
                <TabsNav />
                <Box sx={{ m: 3.3 }} />
                <History />
            </Box>
        </PageWrapper>
    )
}

export default Synthesis