import { Avatar, Box, Collapse, Grid, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { scssVar } from "../../../app/scssVar"
import TeamName from "./TeamName"
import RegisterLink from "./RegisterLink"
import UserTeam from "./UserTeam"
import SendEmail from "./SendEmail"
import DeleteTeam from "./DeleteTeam"

const initialState = {
    collapsed: false
}

const Team = ({ team }) => {
    const [state, setState] = useState(initialState)

    const handleCollapse = () => {
        setState({ ...state, collapsed: !state.collapsed })
    }

    return (
        <Fragment>
            <ListItemButton 
                onClick={handleCollapse}
                sx={{
                    borderBottom: 1,
                    borderColor: scssVar.greyLight,
                    pl: { xs: 0, sm: 2 },
                    pr: { xs: 0, sm: 2 },
                }}
            >
                <ListItemAvatar>
                    <Avatar sx={{
                        bgcolor: scssVar.primaryLighter,
                        color: "black"
                    }}>
                        <i className="iconoir-building" style={{ fontSize: 24 }} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary={team.team_name}
                    secondary={`${team.users.length} utilisateur${team.users.length > 1 ? "s" : ""}`} 
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black",
                            fontWeight: 700
                        },
                    }}
                />
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark,
                        transform: `rotate(${state.collapsed ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </ListItemButton>
            <Collapse in={state.collapsed} timeout="auto" unmountOnExit>
                <Grid 
                    container 
                    spacing={0} 
                    sx={{ 
                        mt: 2, 
                        pl: { xs: 0, sm: 3 }, 
                        pr: { xs: 0, sm: 3 },  
                    }}
                >
                    <Grid item xs={12}>
                        <TeamName team={team} />
                        <Box sx={{ m: 1 }} />
                        <hr/>
                    </Grid>
                    <Grid item xs={12}>
                        <SendEmail team={team} />
                        <Box sx={{ m: 1 }} />
                        <hr/>
                    </Grid>
                    <Grid item xs={12}>
                        <RegisterLink team={team} />
                        <Box sx={{ m: 1 }} />
                        <hr/>
                    </Grid>
                    <Grid item xs={12}>
                        <DeleteTeam team={team} />
                        <Box sx={{ m: 1 }} />
                        <hr/>
                    </Grid>
                </Grid>
                <Typography sx={{
                    color: "black",
                    fontWeight: 700,
                    fontFamily: scssVar.fontFamily,
                    mt: 1,
                    pl: { xs: 0, sm: 3 }
                }}>
                    Utilisateurs
                </Typography>
                {team.users.length ? (
                    <List sx={{ p: 0 }}>
                        {team.users.map(u => 
                            <UserTeam 
                                key={u.id} 
                                user={u} 
                                team={team}
                                usersCount={team.users.length}
                            />
                        )}
                    </List>
                ):(
                    <Box sx={{
                        borderBottom: 1,
                        borderColor: scssVar.greyLight
                    }}>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDarker,
                            pt: 1,
                            pb: 2,
                            pl: { xs: 0, sm: 6 },
                            pr: { xs: 0, sm: 4 },
                            fontSize: "14px"
                        }}>
                            Il n'y a aucun utilisateur dans cette agence. 
                            Vous pouvez envoyer des invitations par email 
                            ou générer un lien d'inscription.
                        </Typography>
                    </Box>
                )}
            </Collapse>
        </Fragment>
    )
}

export default Team