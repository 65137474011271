import { useSelector } from "react-redux"

const useFinishedEstimationsOfHousing = () => {
    const items = useSelector(state => state.estimate.estimations.items)

    return items
        .filter(
            d => 
                d.current_step === "Synthesis"
                    || d.current_step === "ResultFinal"
                    || d.current_step === "Result"
        )
}

export default useFinishedEstimationsOfHousing