import { scssVar } from "../../app/scssVar"

const Chip = ({
    style,
    label
}) => {
    return (
        <span style={Object.assign({}, {
            backgroundColor: scssVar.primaryLighter,
            color: scssVar.primary,
            fontSize: 14,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100px",
            padding: "0px 7px",
            width: "fit-content",
            whiteSpace: "nowrap"
        }, style)}>
            {label}
        </span>
    )
}

export default Chip