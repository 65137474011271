import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import Margin from "../../components/Margin"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import InputText from "../../components/InputText"
import InputSelect from "../../components/InputSelect"
import useSaveMandat from "../hooks/useSaveMandat"

const choices = [
    {label: "Se Loger", value: "seloger" },
    {label: "Le Bon Coin", value: "leboncoin" },
    {label: "Belles demeures", value: "bellesdemeures" },
    {label: "Le Figaro", value: "lefigaro" },
    {label: "Gens De Confiance", value: "gensdeconfiance" },
    {label: "Ouest France", value: "ouestfrance" },
    {label: "La Provence", value: "laprovence" },
    {label: "La Voix Du Nord", value: "lavoixdunord" },
    {label: "Paru Vendu", value: "paruvendu" },
    {label: "À Vendre À Louer", value: "avendrealouer" },
    {label: "Demeures Et Propriétés De France", value: "demeuresetpropriete" },
]

const AdsSites = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const values = useSelector(selectMandatEditor2).sites_annonces || []

    const handleChange = (e) => {
        const { value, name, type } = e.target
        clearTimeout()
        dispatch(mandatActions.setAdSite({ name: Number(name), value }))
        saveMandat(type === "select-one" ? 0 : 1000)
    }

    return (
        <Fragment>
            <div style={{ 
                display: "flex",
                alignItems: "center"
            }}>
                <p style={{
                    fontSize: 12,
                    lineHeight: "12px",
                    width: 183
                }}>
                    Les sites d'annonces comme :
                </p>
                <div style={{ 
                    width: "calc(100% - 183px)", 
                    display: "flex", 
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    {values.map((d, i) => i <= 2 && (
                        <InputSelect 
                            key={i}
                            name={d.id}
                            value={d.value} 
                            onChange={handleChange}
                            style={{
                                width: "32.75%",
                            }}
                        >
                            <option value="" />
                            {choices.map((c, j) => 
                                <option 
                                    key={j} 
                                    value={c.value} 
                                    disabled={values.find(d => d.value === c.value)}
                                >
                                    {c.label}
                                </option>
                            )}
                        </InputSelect>
                    ))}
                </div>
            </div>
            <Margin m={5} />
            <div style={{ 
                display: "flex",
                alignItems: "center"
            }}>
                <p style={{
                    fontSize: 12,
                    lineHeight: "12px",
                    width: 183
                }}>
                    Autres sites d'annonces :
                </p>
                <div style={{ 
                    width: "calc(100% - 183px)", 
                    display: "flex", 
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    {values.map((d, i) => i > 2 && (
                        <InputText 
                            key={i}
                            name={d.id}
                            value={d.value} 
                            onChange={handleChange}
                            style={{
                                width: "49.5%",
                            }}
                        />
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

export default AdsSites