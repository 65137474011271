import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    demande_visite: {
        visiteur1: { civilite: "", nom_naissance: "", prenom: "", cardId: null },
        visiteur2: { civilite: "", nom_naissance: "", prenom: "", cardId: null },
        adresse_domicile: "",
        cp_domicile: "",
        ville_domicile: "",
        tel: "",
        email: "",
        statut: "",
        acquisition_type: "",
        acquisition_apport: "",
        acquisition_vente: "",
        acquisition_origine: ""
    },
    compte_rendu: {
        visiteur1: { civilite: "", nom_naissance: "", prenom: "" },
        visiteur2: { civilite: "", nom_naissance: "", prenom: "" },
        adresse_domicile: "",
        cp_domicile: "",
        ville_domicile: "",
        tel: "",
        email: "",
        date_visite: "",
        note_localisation: "",
        note_exterieur: "",
        note_etat_general: "",
        note_surface_pieces: "",
        note_interet: "",
        note_prix: "",
        commentaires: "",
        estimation_prix: ""
    }
}

const visitsSlice = createSlice({
    name: "visits",
    initialState,
    reducers: {
        setVisitor: (state, action) => {
            state[action.payload.pageId][action.payload.visitor][action.payload.name] = action.payload.value
        },
        setField: (state, action) => {
            state[action.payload.pageId][action.payload.name] = action.payload.value
        },
        setDemandeField: (state, action) => {
            state.demande_visite[action.payload.name] = action.payload.value
        },
        setCompteRenduField: (state, action) => {
            state.compte_rendu[action.payload.name] = action.payload.value
        },
        reset: (state, action) => {
            state[action.payload] = initialState[action.payload]
        }
    },
    extraReducers: (builder) => builder.addCase("auth/logout", () => initialState)
})

export const visitsActions = visitsSlice.actions

export const selectVisitsState = (state, pageId) => state.visits[pageId] || {}

export const selectDemandeVisiteState = (state) => state.visits.demande_visite

export const selectCompteRenduVisiteState = (state) => state.visits.compte_rendu

export default visitsSlice.reducer