import { ArrowForwardIosRounded } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../app/scssVar"
import Button from "./Button"
import Logo from "./Logo"

const NoMatch = () => {
    const navigate = useNavigate()

    return (
        <Box sx={{
            color: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        }}>
            <Logo sx={{ wrapper: { width: 150 } }} />
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                fontWeight: 800,
                fontSize: 23,
                mt: 2,
                mb: 1
            }}>
                404 Not Found !
            </Typography>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                mb: 2
            }}>
                Cette page n'existe pas
            </Typography>
            <Button onClick={() => navigate("/")}>
                Accueil
                {<ArrowForwardIosRounded sx={{ ml: 1, fontSize: 17 }} />}
            </Button>
        </Box>
    )
}

export default NoMatch