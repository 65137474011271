import { useSelector } from "react-redux"
import { selectApiConfig, selectApiDomains } from "../../apiOfferSlice"
import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const TabPanelSettingsAlertMessage = () => {
    const domains = useSelector(selectApiDomains)
    const { error, isLoading } = useSelector(selectApiConfig)

    if (!domains.length && !isLoading && !error) {
        return (
            <Box sx={{ p: 1, bgcolor: scssVar.danger, borderRadius: "8px", color: "white", mb: 3, fontWeight: 500 }}>
                Pour intégrer l'API à votre site web, commencez par renseigner son nom de domaine dans l'onglet "Configuration".
            </Box>
        )
    }

    return null
}

export default TabPanelSettingsAlertMessage