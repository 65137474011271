import { useSelector } from "react-redux"

const useConstructionItems = () => {
    const etatDuBien = useSelector(state => state.estimate.estimation.etat_du_bien)
    const anneeConstruction = useSelector(state => state.estimate.estimation.annee_construction)

    return [
        { label: "État du bien", value: etatDuBien, nd: "Non défini" },
        { label: "Année de construction", value: anneeConstruction, nd: "Non définie" },
    ]
}

export default useConstructionItems