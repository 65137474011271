import { Box, IconButton, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../../../app/scssVar"
import { numberWithSpaces } from "../../../../../../common/utils"
import { selectCosts, selectEstimation } from "../../../../selectors"
import Text from "../../../../../../common/components/Text"
import { Fragment, useState } from "react"
import DrawerStepEditor from "../../../drawerStepEditor/DrawerStepEditor"

const PrixPresentation = () => {
    const estimation = useSelector(selectEstimation)
    const { prixPresentation } = useSelector(selectCosts)
    const [open, setOpen] = useState(false)

    const vendeurAcquereur = estimation.a_charge_vendeur_ou_acquereur
    const vendeurAcquereurText = 
        vendeurAcquereur === "acquereur" ? "acquéreur" :
        vendeurAcquereur === "vendeur" ? "vendeur" : ""

    return (
        <Fragment>
            <Box sx={{
                bgcolor: scssVar.greyLighter,
                borderRadius: "16px",
                p: "25px",
                height: "100%",
                position: "relative",
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDarker,
                    fontSize: "14px",
                    mr: "40px",
                }}>
                    Prix de présentation suggéré
                </Typography>
                <IconButton
                    onClick={() => setOpen(true)}
                    sx={{
                        fontSize: 24,
                        color: "black",
                        bgcolor: scssVar.greyLight,
                        position: "absolute",
                        top: "15px",
                        right: "15px"
                    }}
                >
                    <i className="iconoir-edit-pencil edit-icon"></i>
                </IconButton>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    fontSize: "28px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                }}>
                    {numberWithSpaces(prixPresentation)} €
                </Typography>

                <div style={{ margin: 10 }} />

                <Text sx={{ fontSize: 14, color: "rgb(110, 134, 140)", mb: 1 }}>
                    Frais d'agence
                    {estimation.frais_agence_absolu !== null && estimation.frais_agence_absolu !== "" ? (
                        <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                            {numberWithSpaces(estimation.frais_agence_absolu)} €
                        </b>
                    ):(
                        <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                            Non définis
                        </b>
                    )}
                </Text>
                <Text sx={{ fontSize: 14, color: "rgb(110, 134, 140)" }}>
                    Taux <b>{estimation.frais_agence_pourcent}%</b> charge <b>{vendeurAcquereurText}</b>
                </Text>
            </Box>
            <DrawerStepEditor open={open} step="totalCost" onClose={() => setOpen(false)} />
        </Fragment>
    )
}

export default PrixPresentation