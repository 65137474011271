import { useSelector } from "react-redux"
import { selectApiConfig, selectApiDomains } from "../../apiOfferSlice"
import { ErrorRounded } from "@mui/icons-material"
import { scssVar } from "../../../../app/scssVar"

const TabSettingsLabel = ({ label }) => {
    const domains = useSelector(selectApiDomains)
    const { error, isLoading } = useSelector(selectApiConfig)

    if (!domains.length && !isLoading && !error) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {label}
                <ErrorRounded sx={{ color: scssVar.danger, ml: .5 }} />
            </span>
        )
    }

    return label
}

export default TabSettingsLabel
