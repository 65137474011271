import { useSelector } from "react-redux"
import { getDateToString } from "../../../../common/utils"
import { selectAddress } from "../../../estimate/selectors"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"
import Wrapper from "../../components/Wrapper"
import useCoverBlob from "../../../photoUploader/hooks/useCoverBlob"

const Page01 = ({ scale }) => {
    const createdOn = useSelector(state => state.estimate.housing.created_on)
    const { rue, codePostal, commune } = useSelector(selectAddress)
    const sellerLastName = useSelector(state => state.estimate.housing.nom_vendeur)
    const sellerFirstName = useSelector(state => state.estimate.housing.prenom_vendeur)
    const userColor = useSelector(state => state.account.org.color)
    const pic = useCoverBlob()

    return (
        <Wrapper id="synthesis_p1" scale={scale}>
            <OrgLogo wrapperStyle={{
                width: 275,
                height: 167,
                borderRadius: 20,
                border: "1px solid " + userColor,
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                top: 120,
                zIndex: 2
            }} />
            <div style={{
                height: 222,
                backgroundColor: userColor
            }} />
            <div style={{
                height: 520,
                position: "relative"
            }}>
                {pic.loading ? (
                    <p style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: "auto",
                        width: "fit-content",
                        height: 28
                    }}>
                        Chargement de la photo...
                    </p>
                ): pic.src ? (
                    <img 
                        src={pic.src} 
                        style={{ width: "100%", height: "100%" }}
                        alt="1" 
                    />
                ):(
                    <div style={{
                        width: "100%",
                        height: "100%",
                        background: "#efecec"
                    }} />
                )}
            </div>

            <Margin m={67} />

            <div style={{
                padding: "0 10px"
            }}>
                <p style={{
                    textAlign: "center",
                    fontSize: 57,
                    fontWeight: 700,
                    color: userColor
                }}>
                    RAPPORT D'ÉVALUATION
                </p>
                <Margin m={40} />
                <p style={{
                    fontWeight: 600,
                    fontSize: 28,
                    textAlign: "center",
                    color: "black"
                }}>
                    du {getDateToString(createdOn)}
                </p>
                <Margin m={30} />
                <p style={{
                    fontWeight: 600,
                    fontSize: 28,
                    textAlign: "center",
                    color: "black"
                }}>
                    {rue}
                </p>
                <Margin m={17} />
                <p style={{
                    fontWeight: 600,
                    fontSize: 28,
                    textAlign: "center",
                    color: "black"
                }}>
                    {codePostal} {commune}
                </p>
                <Margin m={15} />
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={{
                        height: 115,
                        border: "1px solid grey",
                        width: .5
                    }}></div>
                </div>
                <Margin m={15} />
                <p style={{
                    fontWeight: 700,
                    fontSize: 24,
                    textAlign: "center",
                    color: "black",
                }}>
                    A la demande de {sellerFirstName} {sellerLastName}
                </p>
            </div>
        </Wrapper>
    )
}

export default Page01