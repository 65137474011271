import { appName, cguUrl, cgvUrl, mlUrl, ppdpUrl } from "../../../common/utils"

const usePdfDoc = () => {

    const getPdfDoc = (docId) => {
        switch (docId) {
            default: case "cgu":
                return {
                    src: cguUrl,
                    title: `Conditions générales d'utilisation d'${appName}.`
                }
            case "cgv":
                return {
                    src: cgvUrl,
                    title: `Conditions générales de vente d'${appName}.`
                }
            case "ppdp":
                return {
                    src: ppdpUrl,
                    title: `Politique de protection de vos données personnelles d'${appName}.`
                }
            case "ml":
                return {
                    src: mlUrl,
                    title: `Mentions légales d'${appName}.`
                }
        }
    }

    return getPdfDoc
}

export default usePdfDoc