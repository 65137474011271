import { useEffect, useState } from "react"
import PhotoUploader from "../../../photoUploader/PhotoUploader"
import useMandatBlobs from "../../../photoUploader/hooks/useMandatBlobs"
import Margin from "../../components/Margin"
import Wrapper from "../../components/Wrapper"
import { v4 as uuid } from 'uuid';
import { getCompressedImg, getCroppedImg } from "../../../photoUploader/settings"
import { selectEstimation, selectHousing } from "../../../estimate/selectors"
import { getMandatBlobs, photosActions } from "../../../photoUploader/photosSlice"
import { addAlert } from "../../../alerts/alertsSlice"
import { defaultErrorMessage } from "../../../../common/utils"
import { useDispatch, useSelector } from "react-redux"
import { api } from "../../../../common/setupApi"
import { mandatActions } from "../mandatSlice"
import { scssVar } from "../../../../app/scssVar"
import { CircularProgress } from "@mui/material"
import PhotosButton from "../../../photoUploader/components/PhotosButton"

const Photo = ({ photo }) => {
    const dispatch = useDispatch()
    const housingId = useSelector(selectHousing).id
    const estimationId = useSelector(selectEstimation).id
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        return () => {
            setLoading(false)
        }
    }, [])

    const handleDelete = async () => {
        setLoading(true)
        return api
            .delete(`v1/housing/delete-photos/${housingId}/${photo.id}/`)
            .then(res => {
                dispatch(photosActions.deleteMandatBlob({ housingId, id: photo.id }))
                dispatch(mandatActions.setPhotos({ data: res.data, estimationId }))
                dispatch(addAlert("La photo a bien été supprimée", "success"))
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert(defaultErrorMessage, "error"))
                setLoading(false)
            })
    }

    return (
        <PhotoUploader
            noUpdate
            onDelete={handleDelete}
            loadingBlob={photo.loading}
            deleteLoading={loading}
            blob={photo.src}
            ButtonEmpty={({ loadingBlob }) => {
                if (loadingBlob) {
                    return (
                        <div style={{ width: 250, height: 250, background: "#efecec", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress size={25} sx={{ color: scssVar.primary }} />
                        </div>
                    )
                }
            }}
            ButtonBlob={({ handleOpenMenu, newPhotoLoading, loadingBlob, blob, deleteLoading }) => {
                return (
                    <div style={{ position: "relative" }}>
                        {deleteLoading && (
                            <CircularProgress
                                size={25} 
                                sx={{ 
                                    color: scssVar.primary,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    margin: "auto"
                                }}
                            />
                        )}
                        <img
                            src={photo.src}
                            alt={photo.id}
                            onClick={(e) => {
                                if (deleteLoading) return
                                handleOpenMenu(e)
                            }}
                            style={{
                                cursor: deleteLoading ? "not-allowed" : "pointer",
                                opacity: deleteLoading ? .5 : 1,
                                width: 250,
                                height: 250,
                            }}
                        />
                    </div>
                )
            }}
        />
    )
}

const Page04 = ({ scale, isExporting }) => {
    const dispatch = useDispatch()
    const blobs = useMandatBlobs()
    const housingId = useSelector(selectHousing).id
    const estimationId = useSelector(selectEstimation).id
    const [loading, setLoading] = useState(false)

    const handleChange = async (cropArea, rotation, aspect, file) => {
        setLoading(true)

        return getCroppedImg(URL.createObjectURL(file), cropArea, rotation)
            .then(async (croppedImg) => {
                console.log(`originalFile size ${croppedImg.size / 1024 / 1024} MB`)

                return getCompressedImg(croppedImg)
                    .then(async (compressedImg) => {
                        console.log(`compressedFile size ${compressedImg.size / 1024 / 1024} MB`)

                        const fileId = uuid()
                        const formData = new FormData()
                        formData.append("housing_id", housingId)
                        formData.append("position", "mandat")
                        formData.append("photos", compressedImg, fileId)

                        return api
                            .post("v1/housing/photos/", formData, { headers: { "content-type": "multipart/form-data" } })
                            .then(async (res) => {
                                dispatch(mandatActions.setPhotos({ data: res.data, estimationId }))
                                dispatch(getMandatBlobs())
                                dispatch(addAlert("La photo a bien été ajoutée", "success"))
                                setLoading(false)
                            })
                            .catch(err => {
                                console.log(err.response)
                                dispatch(addAlert(defaultErrorMessage, "error"))
                                setLoading(false)
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(addAlert("Une erreur est survenue lors de la compression de l'image, veuillez réessayer", "error"))
                        setLoading(false)
                    })
            })
            .catch(err => {
                console.log(err)
                dispatch(addAlert("Une erreur est survenue lors du redimensionnement de l'image, veuillez réessayer", "error"))
                setLoading(false)
            })
    }

    return (
        <Wrapper id="mandat_p4" scale={scale}>
            <p style={{
                fontWeight: 600,
                textAlign: "center",
                fontSize: 16,
                lineHeight: "20px"
            }}>
                Photos contractuelles
                <br/>Équipements qui seront laissés à l'issue de la réitération de l'acte authentique. 
            </p>

            <Margin m={50} />

            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                margin: "0 auto",
                width: "fit-content",
                gridGap: "10px"
            }}>
                {blobs.map((d, i) => <Photo photo={d} key={i} />)}
                {!isExporting && blobs.length < 12 && (
                    <PhotoUploader 
                        withCropper 
                        onSubmit={handleChange}
                        newPhotoLoading={loading}
                        aspect={1}
                        ButtonEmpty={({ newPhotoLoading }) => {
                            return (
                                <PhotosButton
                                    component="span"
                                    sx={{
                                        width: 250, 
                                        height: 250,
                                        background: "#efecec",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                   <p style={{ fontSize: 13, fontWeight: 600 }}>
                                        {newPhotoLoading ? <CircularProgress size={25} sx={{ color: scssVar.primary }} /> : `Cliquez pour importer (${blobs.length}/12)`}
                                    </p>
                                </PhotosButton>
                            )
                        }} 
                    />
                )}
            </div>
        </Wrapper>
    )
}

export default Page04