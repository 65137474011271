import { Box, CircularProgress } from "@mui/material"
import { Fragment, useMemo } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import { numberWithSpaces } from "../../../../common/utils"
import useBudgetUsage from "../../hooks/useBudgetUsage"

const UsageCost = ({ yearly, sx }) => {
    const periodicityChosenByUser = useSelector(state => state.estimate.periodicity)
    const working = useSelector(state => state.estimate.working)
    const step = useSelector(state => state.estimate.estimation.current_step)
    const saving = useSelector(state => state.estimate.saving)
    const { valueStep11, valueStep12 } = useBudgetUsage(yearly)

    const periodicity = useMemo(() => {
        if (yearly) {
            return "year"
        }
        return periodicityChosenByUser
    }, [periodicityChosenByUser, yearly])

    const value = useMemo(() => {
        if (step === "Step11") {
            return valueStep11
        }
        return valueStep11 + valueStep12
    }, [step, valueStep11, valueStep12])

    const percentValueStep11 = useMemo(() => {
        return valueStep11 / value * 100
    }, [value, valueStep11])

    const percentValueStep12 = useMemo(() => {
        return valueStep12 / value * 100
    }, [value, valueStep12])

    // Step11
    // console.log(estimation.total_charge_an)
    // console.log(estimation.total_charge_mois)

    // Step12
    // console.log(estimation.total_cout_energetique_an)
    // console.log(estimation.total_cout_energetique_mois)
    // console.log(estimation.cout_eau_an)
    // console.log(estimation.cout_eau_mois)

    return (
        <Box sx={Object.assign({}, {
            width: "100%",
            borderRadius: "16px",
            bgcolor: "white",
            p: "24px",
        }, sx?.wrapper)}>
            {working && !saving ? (
                <Box sx={{ height: "83px" }}>
                    <CircularProgress size={30} sx={{ color: scssVar.primary }} />
                </Box>
            ):(
                <Fragment>
                    <p style={{ color: "grey", margin: 0, fontSize: 14 }}>
                        Budget d’usage estimé
                    </p>
                    <p style={{
                        color: "black",
                        fontSize: 28,
                        fontWeight: 700,
                        margin: "7px 0 12px",
                        whiteSpace: "nowrap"
                    }}>
                        {numberWithSpaces(value)} €/{periodicity === "month" ? "mois" : periodicity === "year" ? "an" : null}
                    </p>
                    <div style={{ width: "100%", height: 16, display: "flex" }}>
                        <div style={{
                            width: `${percentValueStep11}%`,
                            height: "100%",
                            borderTopLeftRadius: "16px",
                            borderBottomLeftRadius: "16px",
                            backgroundColor: "#F1A041",
                            marginRight: 10
                        }}></div>
                        <div style={{
                            width: `${percentValueStep12}%`,
                            height: "100%",
                            borderTopRightRadius: "16px",
                            borderBottomRightRadius: "16px",
                            backgroundColor: step === "Step11" ? "#FDF3E7" : "#F1A041"
                        }}></div>
                    </div>
                </Fragment>
            )}
        </Box>
    )
}

export default UsageCost