import { Box, Grid, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import FieldControl from "../../../common/components/FieldControl"
import { updateOrgInfos } from "../actions"
import AccountSection from "../components/AccountSection"
import ColorPicker from "../components/ColorPicker"
import OrganizationLogo from "../components/OrganizationLogo"

const ActionsButtons = ({
    onCancel,
    notModified,
    updateLoading,
    emptyField,
    descMinSizeError
}) => {
    return (
        <Box sx={{ 
            mt: "35px",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }
        }}>
            <Button
                variant="light"
                onClick={onCancel}
                type="button"
                disabled={notModified}
                sx={{
                    pl: 7,
                    pr: 7
                }}
            >
                Annuler
            </Button>
            <Box sx={{ m: 1 }} />
            <Button
                loading={updateLoading}
                disabled={notModified || emptyField || descMinSizeError || updateLoading}
                sx={{
                    pl: 7,
                    pr: 7
                }}
            >
                Enregistrer
            </Button>
        </Box>
    )
}

const initialState = {
    org_name: "",
    t_card: "",
    org_email: "",
    org_address: "",
    org_phone: "",
    org_website: "",
    org_description: "",
    org_color: null,
    logo: null,
    org_statut: null,
    org_capital: null,
    org_rcs: null,
    org_siret: null,
    assurance_nom: null,
    assurance_adresse: null,
    garantie_maniement_fonds: null, // Bool
    garantie_organisme: null,
    garantie_montant_caution: null,
    frais_agence_absolu_minimum: null,
    org_cci: null,
    org_directeur_nom: null,
    org_directeur_prenom: null,
    org_directeur_email: null,
    org_mediateur_site: null, // Email
    org_mediateur_nom: null,
    org_rgpd_email: null,
}

const Organization = () => {
    const dispatch = useDispatch()

    const org = useSelector(state => state.account.org)
    const getLoading = org.loading
    const getError = org.error

    const updateLoading = useSelector(state => state.account.org.updateLoading)

    const [state, setState] = useState(initialState)

    const notModified = 
        org.name === state.org_name
        && org.tCard === state.t_card
        && org.email === state.org_email
        && org.address === state.org_address
        && org.phone === state.org_phone
        && org.website === state.org_website
        && org.desc === state.org_description
        && org.color === state.org_color
        && org.org_statut === state.org_statut
        && org.org_capital === state.org_capital
        && org.org_rcs === state.org_rcs
        && org.org_siret === state.org_siret
        && org.assurance_nom === state.assurance_nom
        && org.assurance_adresse === state.assurance_adresse
        && org.garantie_maniement_fonds === state.garantie_maniement_fonds
        && org.garantie_organisme === state.garantie_organisme
        && org.garantie_montant_caution === state.garantie_montant_caution
        && org.frais_agence_absolu_minimum === state.frais_agence_absolu_minimum
        && org.org_cci === state.org_cci
        && org.org_directeur_nom === state.org_directeur_nom
        && org.org_directeur_prenom === state.org_directeur_prenom
        && org.org_directeur_email === state.org_directeur_email
        && org.org_mediateur_nom === state.org_mediateur_nom
        && org.org_mediateur_site === state.org_mediateur_site
        && org.org_rgpd_email === state.org_rgpd_email

    const emptyField = !state.org_name.trim().length

    const descMinSizeError = state.org_description && state.org_description.length < 130

    useEffect(() => {
        setState(s => ({
            ...s,
            org_name: org.name || "",
            t_card: org.tCard || "",
            org_email: org.email || "",
            org_address: org.address || "",
            org_phone: org.phone || "",
            org_website: org.website || "",
            org_description: org.desc || "",
            org_color: org.color || "",
            logo: null || "",
            org_statut: org.org_statut || "",
            org_capital: org.org_capital || "",
            org_rcs: org.org_rcs || "",
            org_siret: org.org_siret || "",
            assurance_nom: org.assurance_nom || "",
            assurance_adresse: org.assurance_adresse || "",
            garantie_maniement_fonds: org.garantie_maniement_fonds || "", // Bool
            garantie_organisme: org.garantie_organisme || "",
            garantie_montant_caution: org.garantie_montant_caution || "",
            frais_agence_absolu_minimum: org.frais_agence_absolu_minimum || "",
            org_cci: org.org_cci || "",
            org_directeur_nom: org.org_directeur_nom || "",
            org_directeur_prenom: org.org_directeur_prenom || "",
            org_directeur_email: org.org_directeur_email || "",
            org_mediateur_site: org.org_mediateur_site || "", // Email
            org_mediateur_nom: org.org_mediateur_nom || "",
            org_rgpd_email: org.org_rgpd_email || "",
        }))
    }, [
        org.name, 
        org.tCard, 
        org.email, 
        org.address, 
        org.phone, 
        org.website, 
        org.desc, 
        org.color,
        org.assurance_adresse, 
        org.assurance_nom, 
        org.frais_agence_absolu_minimum, 
        org.garantie_maniement_fonds, 
        org.garantie_montant_caution, 
        org.garantie_organisme, 
        org.org_capital, 
        org.org_cci, 
        org.org_directeur_email, 
        org.org_directeur_nom, 
        org.org_directeur_prenom, 
        org.org_mediateur_nom, 
        org.org_mediateur_site, 
        org.org_rcs, 
        org.org_rgpd_email, 
        org.org_siret, 
        org.org_statut
    ])

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState({
            ...state,
            [name]: value
        })
    }

    const handleChangeColor = (newValue) => {
        setState({ ...state, org_color: newValue })
    }

    const handleCancel = () => {
        setState(s => ({
            ...s,
            org_name: org.name || "",
            t_card: org.tCard || "",
            org_email: org.email || "",
            org_address: org.address || "",
            org_phone: org.phone || "",
            org_website: org.website || "",
            org_description: org.desc || "",
            org_color: org.color || "",
            logo: null || "",
            org_statut: org.org_statut || "",
            org_capital: org.org_capital || "",
            org_rcs: org.org_rcs || "",
            org_siret: org.org_siret || "",
            assurance_nom: org.assurance_nom || "",
            assurance_adresse: org.assurance_adresse || "",
            garantie_maniement_fonds: org.garantie_maniement_fonds || "", // Bool
            garantie_organisme: org.garantie_organisme || "",
            garantie_montant_caution: org.garantie_montant_caution || "",
            frais_agence_absolu_minimum: org.frais_agence_absolu_minimum || "",
            org_cci: org.org_cci || "",
            org_directeur_nom: org.org_directeur_nom || "",
            org_directeur_prenom: org.org_directeur_prenom || "",
            org_directeur_email: org.org_directeur_email || "",
            org_mediateur_nom: org.org_mediateur_nom || "",
            org_mediateur_site: org.org_mediateur_site || "", // Email
            org_rgpd_email: org.org_rgpd_email || "",
        }))
    }

    const handleSave = (e) => {
        e.preventDefault()

        dispatch(updateOrgInfos(
            state.org_name,
            state.t_card,
            state.org_email,
            state.org_address,
            state.org_phone,
            state.org_website,
            state.org_description,
            state.org_color,
            state.org_statut,
            state.org_capital,
            state.org_rcs,
            state.org_siret,
            state.assurance_nom,
            state.assurance_adresse,
            state.garantie_maniement_fonds,
            state.garantie_organisme,
            state.garantie_montant_caution,
            state.frais_agence_absolu_minimum,
            state.org_cci,
            state.org_directeur_nom,
            state.org_directeur_prenom,
            state.org_directeur_email,
            state.org_mediateur_nom,
            state.org_mediateur_site,
            state.org_rgpd_email
        ))
    }

    return (
        <AccountSection
            title="Organisation"
            loading={getLoading}
            error={getError?.data ? "Une erreur est survenue lors de la récupération de votre profil, veuillez réessayer." : null}
        >
            <Box
                sx={{ 
                    flexGrow: 1, 
                    mb: "30px" 
                }}
                component="form"
                onSubmit={handleSave}
            >
                <Box sx={{
                    display: "flex",
                    mb: 2,
                    "@media (max-width: 760px)": {
                        flexDirection: "column"
                    }
                }}>
                    <Box sx={{
                        width: 190,
                        "@media (max-width: 760px)": {
                            mb: 2
                        }
                    }}>
                        <OrganizationLogo />
                    </Box>
                    <Box sx={{
                        width: "calc(100% - 190px)",
                        "@media (max-width: 760px)": {
                            width: "100%"
                        }
                    }}>
                        <ColorPicker
                            value={state.org_color}
                            onChange={handleChangeColor}
                        />
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Nom de l'organisation"
                            placeholder="Nom de l'organisation"
                            isSuccess={state.org_name}
                            onChange={handleChange}
                            value={state.org_name || ""}
                            name="org_name"
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Numéro carte T"
                            placeholder="Numéro carte T"
                            isSuccess={state.t_card}
                            onChange={handleChange}
                            value={state.t_card || ""}
                            type="text"
                            name="t_card"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Email"
                            placeholder="Email"
                            isSuccess={state.org_email}
                            onChange={handleChange}
                            value={state.org_email || ""}
                            name="org_email"
                            type="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Adresse de l'organisation"
                            placeholder="Adresse de l'organisation"
                            isSuccess={state.org_address}
                            onChange={handleChange}
                            value={state.org_address || ""}
                            type="text"
                            name="org_address"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Numéro de téléphone"
                            placeholder="Numéro de téléphone"
                            isSuccess={state.org_phone}
                            onChange={handleChange}
                            value={state.org_phone || ""}
                            name="org_phone"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Site web"
                            placeholder="Site web"
                            isSuccess={state.org_website}
                            onChange={handleChange}
                            value={state.org_website || ""}
                            name="org_website"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldControl
                            label="Description de l'organisation (130 signes minimum)" 
                            isSuccess={state.org_description} 
                            onChange={handleChange}
                            value={state.org_description || ""}
                            type="textarea" 
                            placeholder="Description de l'organisation"
                            name="org_description"
                            minLength={130}
                            maxLength={600}
                        />
                    </Grid>
                </Grid>
            
                <Box sx={{ m: 4.5 }} />
                <hr />
                <Box sx={{ m: 3 }} />

                <Typography sx={{
                    color: "black",
                    fontWeight: 600,
                    fontSize: 19,
                    fontFamily: scssVar.fontFamily,
                    mb: 1.5
                }}>
                    Informations indispensables au mandat
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Statut de l'organisation"
                            isSuccess={state.org_statut}
                            onChange={handleChange}
                            value={state.org_statut || ""}
                            name="org_statut"
                            type="select"
                            options={
                                <Fragment>
                                    <option value="">Statut</option>
                                    <option>SAS</option> 
                                    <option>SARL</option> 
                                    <option>EI</option> 
                                    <option>SASU</option> 
                                    <option>EURL</option>
                                    <option>SA</option>
                                </Fragment>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Capital" 
                            isSuccess={state.org_capital} 
                            onChange={handleChange}
                            value={state.org_capital || ""}
                            type="number" 
                            placeholder="Capital" 
                            name="org_capital"
                            icon={{ icon: "fas fa-euro-sign", position: "right" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Ville du RCS" 
                            isSuccess={state.org_rcs} 
                            onChange={handleChange}
                            value={state.org_rcs || ""}
                            type="text" 
                            placeholder="Ville du RCS" 
                            name="org_rcs"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="SIRET" 
                            isSuccess={state.org_siret} 
                            onChange={handleChange}
                            value={state.org_siret || ""}
                            type="text" 
                            placeholder="SIRET" 
                            name="org_siret"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Nom de l'assurance" 
                            isSuccess={state.assurance_nom} 
                            onChange={handleChange}
                            value={state.assurance_nom || ""}
                            type="text" 
                            placeholder="Nom de l'assurance" 
                            name="assurance_nom"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Adresse de l'assurance" 
                            isSuccess={state.assurance_adresse} 
                            onChange={handleChange}
                            value={state.assurance_adresse || ""}
                            type="text" 
                            placeholder="Adresse de l'assurance" 
                            name="assurance_adresse"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Frais d'agence minimum TTC" 
                            isSuccess={state.frais_agence_absolu_minimum} 
                            onChange={handleChange}
                            value={state.frais_agence_absolu_minimum || ""}
                            type="number" 
                            placeholder="Frais d'agence minimum TTC"  
                            name="frais_agence_absolu_minimum"
                            icon={{ icon: "fas fa-euro-sign", position: "right" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Ville de la CCI" 
                            isSuccess={state.org_cci} 
                            onChange={handleChange}
                            value={state.org_cci || ""}
                            type="text" 
                            placeholder="Ville de la CCI"  
                            name="org_cci"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="text" 
                            label="Nom du directeur" 
                            placeholder="Nom du directeur" 
                            isSuccess={state.org_directeur_nom} 
                            value={state.org_directeur_nom || ""}
                            name="org_directeur_nom"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="text" 
                            label="Prénom du directeur" 
                            placeholder="Prénom du directeur" 
                            isSuccess={state.org_directeur_prenom} 
                            value={state.org_directeur_prenom || ""}
                            name="org_directeur_prenom"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="email" 
                            label="Email du directeur" 
                            placeholder="Email du directeur" 
                            isSuccess={state.org_directeur_email} 
                            value={state.org_directeur_email || ""}
                            name="org_directeur_email"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="email" 
                            label="Email contact RGPD" 
                            placeholder="Email contact RGPD" 
                            isSuccess={state.org_rgpd_email} 
                            value={state.org_rgpd_email || ""}
                            name="org_rgpd_email"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="text" 
                            label="Nom du médiateur" 
                            placeholder="Nom du médiateur" 
                            isSuccess={state.org_mediateur_nom} 
                            value={state.org_mediateur_nom || ""}
                            name="org_mediateur_nom"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="email" 
                            label="Email du médiateur" 
                            placeholder="Email du médiateur" 
                            isSuccess={state.org_mediateur_site} 
                            value={state.org_mediateur_site || ""}
                            name="org_mediateur_site"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            type="checkbox" 
                            label="Garantie maniement de fonds" 
                            isSuccess={state.garantie_maniement_fonds !== null && state.garantie_maniement_fonds !== ""} 
                            name="garantie_maniement_fonds"
                            onChange={(name, value) => {
                                setState({
                                    ...state,
                                    [name]: value,
                                    garantie_organisme: "",
                                    garantie_montant_caution: ""
                                })
                            }}
                            xs={6}
                            selectedValue={state.garantie_maniement_fonds}
                            choices={[
                                {value: true, label: "Oui"},
                                {value: false, label: "Non"},
                            ]}
                        />
                    </Grid>
                    {state.garantie_maniement_fonds && (
                        <Fragment>
                            <Grid item xs={12} sm={6}>
                                <FieldControl
                                    type="text" 
                                    label="Nom de l'organisme de garantie" 
                                    placeholder="Nom de l'organisme de garantie"
                                    isSuccess={state.garantie_organisme} 
                                    value={state.garantie_organisme || ""}
                                    name="garantie_organisme"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FieldControl
                                    type="number" 
                                    label="Montant de la caution de garantie" 
                                    placeholder="Montant de la caution de garantie" 
                                    isSuccess={state.garantie_montant_caution} 
                                    value={state.garantie_montant_caution || ""}
                                    name="garantie_montant_caution"
                                    onChange={handleChange}
                                    icon={{ icon: "fas fa-euro-sign", position: "right" }}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
                
                <ActionsButtons 
                    onCancel={handleCancel}
                    notModified={notModified}
                    updateLoading={updateLoading}
                    emptyField={emptyField}
                    descMinSizeError={descMinSizeError}
                />
            </Box>
        </AccountSection>
    )
}

export default Organization