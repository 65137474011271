import { createSlice } from "@reduxjs/toolkit"
import { api } from "../../common/setupApi"
import { addAlert } from "../alerts/alertsSlice"

export const sendUserOpinion = () => async (dispatch, getState) => {
    const state = getState()
    const subject = state.userOpinion.subject
    const detail = state.userOpinion.detail
    const estimation = state.estimate.estimation
    const housing = state.estimate.housing
    const user = state.auth.user

    const context = {
        housing: {
            name: housing.name,
            id: housing.id
        },
        estimation: {
            current_step: estimation.current_step,
            id: estimation.id
        },
        loggedin_user: {
            first_name: user.firstName,
            last_name: user.lastName,
            id: user.id,
            email: user.email,
            is_tester: user.isTester
        },
        path: window.location.pathname
    } 

    dispatch(opinionAddLoading())

    const payload = {
        subject,
        detail,
        context: JSON.stringify(context, null, 4)
    }

    return api
        .post("v1/core/tester-info", payload)
        .then(() => {
            dispatch(addAlert("Votre message a été transmis", "success"))
            dispatch(opinionAddSucceeded())
            dispatch(opinionFormReseted())
        })
        .catch(err => {
            dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            dispatch(opinionAddFailed(err.response))
        })
}

const initialState = {
    open: false,
    subject: "",
    detail: "",
    loading: false,
    error: null
}

const userOpinionSlice = createSlice({
    name: "userOpinion",
    initialState,
    reducers: {
        opinionOpened: (state) => {
            state.open = true
        },
        opinionClosed: (state) => {
            state.open = false
        },
        opinionFieldChanged: (state, action) => {
            state[action.payload.name] = action.payload.value
        },
        opinionAddLoading: (state) => {
            state.loading = true
            state.error = null
        },
        opinionAddSucceeded: (state) => {
            state.loading = false
        },
        opinionAddFailed: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        opinionFormReseted: () => {
            return initialState
        }
    },
    extraReducers: (builder) => builder.addCase("auth/logout", () => initialState)
})

const { opinionOpened, opinionClosed, opinionFieldChanged, opinionAddLoading, opinionAddSucceeded, opinionAddFailed, opinionFormReseted } = userOpinionSlice.actions

export { opinionOpened, opinionClosed, opinionFieldChanged }

export default userOpinionSlice.reducer