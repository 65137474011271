import { Box, Divider } from "@mui/material"
import { Fragment } from "react"
import LegalDocs from "./components/LegalDocs"
import Domains from "./components/Domains"
import Departements from "./components/Departements"
import { useSelector } from "react-redux"
import { selectApiConfig } from "../apiOfferSlice"
import { scssVar } from "../../../app/scssVar"
import LoaderGif from "../../../common/components/LoaderGif"

const TabSettings = () => {
    const { isLoading, error } = useSelector(selectApiConfig)

    if (isLoading) {
        return <LoaderGif />
    }

    if (error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer</p>
    }

    return (
        <Fragment>
            <Domains />
            <Box sx={{ m: 3 }} />
            <Divider />
            <Box sx={{ m: 3 }} />
            <LegalDocs />
            <Box sx={{ m: 3 }} />
            <Divider />
            <Box sx={{ m: 3 }} />
            <Departements />
        </Fragment>
    )
}

export default TabSettings