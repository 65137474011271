import logoBlack from "../../common/assets/logo_black.png"
import logoViolet from "../../common/assets/logo_violet_2.png"
import logoWhite from "../../common/assets/logo_white_3.png"
import logoDigipi from "../../common/assets/logo_digipi.png"
import { Box } from "@mui/material"
import { appCompany, appName } from "../utils"

const Wrapper = ({ children, sx }) => (
    <Box sx={Object.assign({}, {
        width: 90,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }, sx)}>
        {children}
    </Box>
)

const Logo = ({ sx, variant }) => {

    if (variant === "digipi") {
        return (
            <Wrapper sx={sx?.wrapper}>
                <img
                    src={logoDigipi}
                    alt={`logo ${appCompany}`}
                    style={Object.assign({}, {
                        width: "100%"
                    }, sx?.image)}
                />
            </Wrapper>
        )
    }

    if (variant === "white") {
        return (
            <Wrapper sx={sx?.wrapper}>
                <img
                    src={logoWhite}
                    alt="logo blanc"
                    style={Object.assign({}, {
                        width: "100%"
                    }, sx?.image)}
                />
            </Wrapper>
        )
    }

    if (variant === "black") {
        return (
            <Wrapper sx={sx?.wrapper}>
                <img
                    src={logoBlack}
                    alt={`logo ${appName} noir`}
                    style={Object.assign({}, {
                        width: "100%"
                    }, sx?.image)}
                />
            </Wrapper>
        )
    }

    return (
        <Wrapper sx={sx?.wrapper}>
            <img
                src={logoViolet}
                alt={`logo ${appName} violet`}
                style={Object.assign({}, {
                    width: "100%"
                }, sx?.image)}
            />
        </Wrapper>
    )
}

export default Logo