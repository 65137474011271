import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { initDefaultValuesAndSaveProgress, saveProgress } from "../actions"
import StepLayout from "./components/StepLayout"
import { selectCanComputeStep01, selectValues } from "../selectors"
import Step01Form from "./components/Step01Form"
import Step04Form from "./components/Step04Form"
import Step05Form from "./components/Step05Form"
import Step07Form from "./components/Step07Form"
import Step08Form from "./components/Step08Form"
import Step11Form from "./components/Step11Form"
import Step12Form from "./components/Step12Form"

const StepProvider = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const value = useSelector(selectValues).estimationValue
    const canComputeStep01 = useSelector(selectCanComputeStep01)
    const loadingDefaultValues = useSelector(state => state.estimate.loadingDefaultValues)

    const { pathname } = location
    const step = pathname.split("/")[2]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    const handleGoNext = useCallback(() => {
        switch (step) {
            default:
                return null

            case "step-1":
                navigate("/estimer/step-4")
                dispatch(saveProgress("Step4"))
                return

            case "step-4":
                navigate("/estimer/step-5")
                dispatch(initDefaultValuesAndSaveProgress("Step5"))
                return

            case "step-5":
                navigate("/estimer/step-7")
                dispatch(saveProgress("Step7"))
                return 
                
            case "step-7":
                navigate("/estimer/step-8")
                dispatch(saveProgress("Step8"))
                return 
                
            case "step-8":
                navigate("/estimer/resultat")
                dispatch(saveProgress("Result"))
                return
                
            case "step-11":
                navigate("/estimer/step-12")
                dispatch(saveProgress("Step12"))
                return 
                
            case "step-12":
                navigate("/estimer/resultat-final")
                dispatch(saveProgress("ResultFinal"))
                return
        }
    }, [step, dispatch, navigate])

    const handleGoBack = useCallback(() => {
        switch (step) {
            default:
                return null

            case "step-1":
                navigate("/estimer")
                return

            case "step-4":
                navigate("/estimer/step-1")
                dispatch(saveProgress("Step1"))
                return

            case "step-5":
                navigate("/estimer/step-4")
                dispatch(saveProgress("Step4"))
                return 
                
            case "step-7":
                navigate("/estimer/step-5")
                dispatch(saveProgress("Step5"))
                return 
                
            case "step-8":
                navigate("/estimer/step-7")
                dispatch(saveProgress("Step7"))
                return
                
            case "step-11":
                navigate("/estimer/resultat")
                dispatch(saveProgress("Result"))
                return 
                
            case "step-12":
                navigate("/estimer/step-11")
                dispatch(saveProgress("Step11"))
                return
        }
    }, [step, dispatch, navigate])

    const canCompute = useMemo(() => {
        switch (step) {
            default:
                return true
            case "step-1":
                return canComputeStep01
        }
    }, [step, canComputeStep01])

    const backDisabled = useMemo(() => {
        switch (step) {
            default:
                return false
            case "step-5":
                return loadingDefaultValues
        }
    }, [step, loadingDefaultValues])

    const nextDisabled = useMemo(() => {
        switch (step) {
            default:
                return false
            case "step-1":
                return !value || !canCompute
            case "step-5":
                return loadingDefaultValues
        }
    }, [step, canCompute, value, loadingDefaultValues])


    const showUseCost = useMemo(() => {
        switch (step) {
            default:
                return false
            case "step-11":
                return true
            case "step-12":
                return true
        }
    }, [step])

    const stepComponent = useMemo(() => {
        switch (step) {
            default:
                return null
            case "step-1":
                return <Step01Form stepName="Step1" />
            case "step-4":
                return <Step04Form stepName="Step4" />
            case "step-5":
                return <Step05Form stepName="Step5" />
            case "step-7":
                return <Step07Form stepName="Step7" />
            case "step-8":
                return <Step08Form stepName="Step8" />
            case "step-11":
                return <Step11Form stepName="Step11" />
            case "step-12":
                return <Step12Form stepName="Step12" />
        }
    }, [step])

    return (
        <StepLayout
            onGoNext={handleGoNext}
            onGoBack={handleGoBack}
            canCompute={canCompute}
            backDisabled={backDisabled}
            nextDisabled={nextDisabled}
            showUseCost={showUseCost}
        >
            {stepComponent}
        </StepLayout>
    )
}

export default StepProvider