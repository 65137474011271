import useCoverBlob from "../../../photoUploader/hooks/useCoverBlob"
import useGalleryBlobs from "../../../photoUploader/hooks/useGalleryBlobs"
import { Grid } from "@mui/material"
import GalleryLayout from "../../../photoUploader/components/GalleryLayout"
import Margin from "../../components/Margin"

const Photos = ({ docType }) => {
    const coverBlob = useCoverBlob()
    const galleryBlobs = useGalleryBlobs()

    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            {coverBlob.src && <img src={coverBlob.src} alt="1" width="100%" />}
            <Margin m={2} />
            <Grid container spacing={.5}>
                <GalleryLayout pics={galleryBlobs} readOnly docType={docType} />
            </Grid>
        </div>
    )
}

export default Photos