import { api } from "../../common/setupApi"
import { addAlert } from "../alerts/alertsSlice"
import { apiOfferActions } from "../apiOffer/apiOfferSlice"
import { filterEstimations } from "../estimations/actions"
import { selectCanComputeStep01, selectIsLeadEstimation } from "./selectors"

export function updateEstimation(currentStep) {
    return async (dispatch, getState) => {
        const state = getState()
        const housing = state.estimate.housing
        const estimation = state.estimate.estimation
        const canCompute = selectCanComputeStep01(state)
        const isLeadEstimation = selectIsLeadEstimation(state)

        if (!canCompute) {
            dispatch(updateEstimationsList(currentStep))
            dispatch(updateUnfinishedsList(currentStep))

            return
        }

        const estimationWithUpdatedStep = { ...estimation, current_step: currentStep }

        // console.log(estimationWithUpdatedStep.taxe_fonciere_an)
        // console.log(estimationWithUpdatedStep.taxe_fonciere_an_saisie)

        // console.log("Avec terrasse", estimationWithUpdatedStep.avec_terrasse)
        // console.log("Surface terrasse", estimationWithUpdatedStep.surface_terrasse)
    
        // console.log("Avec jardin", estimationWithUpdatedStep.avec_jardin)
        // console.log("Surface jardin", estimationWithUpdatedStep.surface_jardin)

        dispatch({ type: "estimate/set_working", payload: true })

        return api
            .post("v1/housing/estimation/compute", { housing, estimation: estimationWithUpdatedStep })
            .then(res => {
                dispatch({ type: "estimate/set_housing", payload: res.data.housing })
                dispatch({ type: "estimate/set_estimation", payload: res.data })
                dispatch({ type: "estimate/edit_estimation", payload: res.data })

                dispatch(updateEstimationsList(currentStep))
                dispatch(updateUnfinishedsList(currentStep))

                if (isLeadEstimation) {
                    dispatch(apiOfferActions.setLeadAfterUpdateInSynthesis({
                        pk: res.data.id,
                        value_displayed: res.data.value_displayed || res.data.value,
                        surface_reelle_bati: res.data.housing.surface_reelle_bati,
                        nombre_pieces_principales: res.data.housing.nombre_pieces_principales
                    }))
                    // dispatch(apiOfferActions.setLeads({ pk: [res.data.id], name: "value_displayed", value: res.data.value_displayed || res.data.value }))
                    // dispatch(apiOfferActions.setLeads({ pk: [res.data.id], name: "surface_reelle_bati", value: res.data.housing.surface_reelle_bati }))
                    // dispatch(apiOfferActions.setLeads({ pk: [res.data.id], name: "nombre_pieces_principales", value: res.data.housing.nombre_pieces_principales }))
                }
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
            })
            .finally(() => {
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }
}

export function initDefaultValuesAndSaveProgress(currentStep) {
    return async (dispatch, getState) => {
        const state = getState()
        const id = state.estimate.estimation.id

        dispatch({ type: "estimate/set_working", payload: true })

        dispatch({ type: "estimate/init_default_values_loading" })

        return api
            .get("v1/housing/estimation/init", { params: { pk: id } })
            .then(res => {
                dispatch({ 
                    type: "estimate/set_estimation", 
                    payload: res.data 
                })

                dispatch({ type: "estimate/init_default_values_success" })

                dispatch(saveProgress(currentStep))
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/init_default_values_failure", payload: err.response })
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }
}

export function getUnfinished() {
    return async (dispatch, getState) => {
        const state = getState()
        const fetched = state.estimations.unfinished.fetched

        if (fetched) {
            return
        }

        dispatch({ type: "estimations/get_unfinished_loading" })

        return api
            .get("v1/housing/estimation/unfinished")
            .then(res => {
                const dataReady = res.data.filter(
                    d => d.current_step !== "Synthesis"
                    && d.current_step !== "ResultFinal"
                )
                
                dispatch({ 
                    type: "estimations/get_unfinished_success", 
                    payload: dataReady
                })
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "estimations/get_unfinished_failure",
                    payload: err.response
                })
            })
    }
}

export function getEstimationsOfHousing() {
    return async (dispatch, getState) => {
        const state = getState()
        const housingId = state.estimate.housing.id

        dispatch({ type: "estimate/get_estimations_of_housing_loading" })

        return api
            .get("v1/housing/estimation", { params: { housing_id: housingId } })
            .then(res => {
                dispatch({ 
                    type: "estimate/get_estimations_of_housing_success",
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({ 
                    type: "estimate/get_estimations_of_housing_failure",
                    payload: err.response
                })
            })
    }
}

export function updateHousing(housing) {
    return async (dispatch, getState) => {
        const state = getState()
        const estimation = state.estimate.estimation

        dispatch({ 
            type: "estimate/set_housing", 
            payload: housing
        })

        dispatch({
            type: "estimate/set_estimation",
            payload: {
                ...estimation,
                housing: housing
            }
        })

        dispatch({
            type: "estimate/set_housing_in_estimations_of_housing",
            payload: housing
        })

        dispatch(updateHousingInEstimationsList(housing))

        dispatch(updateHousingInUnfinishedsList(housing))
    }
}

function updateHousingInEstimationsList(housing) {
    return async (dispatch, getState) => {
        const state = getState()
        const fetched = state.estimations.estimations.fetched

        if (!fetched) {
            return
        }

        dispatch({
            type: "estimations/set_estimations_of_housing",
            payload: housing
        })
    }
}

function updateHousingInUnfinishedsList(housing) {
    return async (dispatch, getState) => {
        const state = getState()
        const fetched = state.estimations.unfinished.fetched

        if (!fetched) {
            return
        }

        dispatch({
            type: "estimations/set_unfinished_of_housing",
            payload: housing
        })
    }
}

export function updateEstimationsList(currentStep) {
    return async (dispatch, getState) => {
        const state = getState()
        const estimation = state.estimate.estimation
        const canCompute = selectCanComputeStep01(state)
        const hasToBeInEvaluationsList = canCompute && estimation.value > 0.01
        const id = state.estimate.estimation.id
        const ids = state.estimations.estimations.items.map(d => d.id)
        const exists = ids.includes(id)
        const fetched = state.estimations.estimations.fetched

        if (!fetched) {
            return
        }

        const _estimation = {
            ...estimation,
            current_step: currentStep
        }

        if (hasToBeInEvaluationsList) {
            if (exists) {
                dispatch({
                    type: "estimations/set_estimation",
                    payload: _estimation
                })
            } else {
                dispatch({
                    type: "estimations/add_estimation",
                    payload: _estimation
                })

                dispatch(filterEstimations())
            }
        } else {
            if (exists) {
                dispatch({
                    type: "estimations/delete_estimation",
                    payload: id
                })
            }
        }
    }
}

export function updateUnfinishedsList(currentStep) {
    return async (dispatch, getState) => {
        const state = getState()
        const estimation = state.estimate.estimation
        const id = state.estimate.estimation.id
        const hasToBeInUnfinishedsList = currentStep !== "Synthesis" && currentStep !== "ResultFinal"
        const fetched = state.estimations.unfinished.fetched
        const ids = state.estimations.unfinished.items.map(d => d.id)
        const exists = ids.includes(id)

        if (!fetched) {
            return
        }

        const _estimation = {
            ...estimation,
            current_step: currentStep
        }

        if (hasToBeInUnfinishedsList) {
            if (exists) {
                dispatch({
                    type: "estimations/set_unfinished",
                    payload: _estimation
                })
            } else {
                dispatch({
                    type: "estimations/add_unfinished",
                    payload: _estimation
                })
            }
        } else {
            if (exists) {
                dispatch({
                    type: "estimations/delete_unfinished",
                    payload: id
                })
            }
        }
    }
}

export function saveProgress(currentStep) {
    return async (dispatch, getState) => {
        const state = getState()
        const estimation = state.estimate.estimation
        const id = state.estimate.estimation.id

        const _estimation = {
            ...estimation,
            current_step: currentStep
        }

        dispatch({ type: "estimate/save_loading" })
        dispatch({ type: "estimate/set_working", payload: true })

        return api
            .get("v1/housing/estimation/set-step", { params: { pk: id, step: currentStep } })
            .then(() => {
                dispatch({ 
                    type: "estimate/set_step", 
                    payload: currentStep 
                })

                dispatch(updateEstimationsList(currentStep))
                dispatch(updateUnfinishedsList(currentStep))

                dispatch({ 
                    type: "estimate/edit_estimation", 
                    payload: _estimation
                })

                dispatch({ type: "estimate/set_working", payload: false })
                dispatch({ type: "estimate/save_success" })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue lors de la sauvegarde de l'évaluation. Veuillez réessayer.", "error"))
                dispatch({ type: "estimate/save_failure", payload: err.response })
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }
}

// fonctions de JB
// function setSaisie(field_name, estimation) {
//     let field_name_saisie = `${field_name}_saisie`

//     for (const [key, value] of Object.entries(estimation)) {
//         if (key === field_name_saisie) {
//             estimation[key] = true

//             // unset the complementary key: mois -> an / an -> mois
//             // if one set the field "mois" then we must compute the field "an" and vice versa
//             if (key.includes("_mois") || key.includes("_an")) {
//                 let complementary_key = ""

//                 if (key.includes("_mois")){
//                     complementary_key = key.replace("_mois", "_an")
//                 } else {
//                     complementary_key = key.replace("_an", "_mois")
//                 }

//                 if (estimation.hasOwnProperty(complementary_key)) {
//                     // set to null the "complementary": the API must compute it.
//                     estimation[complementary_key] = null
//                 }
//             }
//         }
//     }
// }

// function setPercentAbsolute(field_name, pourcent_or_absolu, estimation) {
//     let field_name_saisie
//     const isPercent = field_name.includes("_pourcent")

//     if (isPercent) {
//         field_name_saisie = field_name.replace("_pourcent", "_saisie_pourcent")
//     } else {
//         field_name_saisie = field_name.replace("_absolu", "_saisie_pourcent")
//     }

//     for (const [key, value] of Object.entries(estimation)) {
//         if (key === field_name_saisie){
//             if (pourcent_or_absolu == "pourcent") {
//                 // set saisie to "pourcent"
//                 estimation[key] = true
//             } else {
//                 // set saisie to "absolu"
//                 estimation[key] = false
//             }
//         }
//     }
// }