import { useDispatch, useSelector } from "react-redux"
import InputTextArea from "../../../components/InputTextArea"
import useSaveTracfin from "../hooks/useSaveTracfin"
import { selectTracfinEditor, tracfinActions } from "../tracfinSlice"
import Margin from "../../../components/Margin"

const Comments = () => {
    const dispatch = useDispatch()
    const { clearTimeout, saveTracfin } = useSaveTracfin()
    const editor = useSelector(selectTracfinEditor)

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(tracfinActions.setField({ name, value }))
        saveTracfin(1000)
    }

    return (
        <div>
            <p style={{ fontSize: 13, lineHeight: "15px" }}>Commentaires :</p>
            <Margin m={2} />
            <InputTextArea 
                value={editor.tf_commentaire_cotation || ""}
                name="tf_commentaire_cotation"
                onChange={handleChange}
                style={{ width: "100%", height: 71 }} 
            />
        </div>
    )
}

export default Comments