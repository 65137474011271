import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ButtonUserProfile from '../../../common/components/nav/ButtonUserProfile';
import useRoutes from '../../../common/hooks/useRoutes';
import { Fragment, useState } from 'react';
import MenuNav from '../../../common/components/nav/MenuNav';
import LinkLogo from '../../../common/components/nav/LinkLogo';
import LinkTopBar from '../../../common/components/nav/LinkTopBar';

const EstimationsTopBar = () => {
    const routes = useRoutes()
    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    return (
        <Fragment>
            <AppBar position="static" sx={{
                boxShadow: "none",
                WebkitBoxShadow: "none",
                background: "none",
                color: "black",
                pt: { xs: 0, sm: 2.65 },
            }}>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: { xs: 1, sm: 0 } }}>
                        <LinkLogo />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
                        {routes.map((d, i) => <LinkTopBar key={i} link={d} />)}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Box sx={{ display: { xs: "none", lg: "block" } }}>
                            <ButtonUserProfile open={Boolean(anchorElUser)} onClick={handleOpenUserMenu} />
                        </Box>
                        <Box sx={{ display: { md: "block", lg: "none" }}}>
                            <ButtonUserProfile small onClick={handleOpenUserMenu} />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <MenuNav anchorEl={anchorElUser} onClose={() => setAnchorElUser(null)} />
        </Fragment>
    )
}

export default EstimationsTopBar