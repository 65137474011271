import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getDateToString } from "../../../../common/utils"
import HousingName from "../../result/components/HousingName"
import { selectAddress } from "../../selectors"
import { scssVar } from "../../../../app/scssVar"
import MainPhoto from "./components/MainPhoto"
import Seller from "./components/Seller"

const IDcard = () => {
    const { rue, codePostal, commune } = useSelector(selectAddress)
    const created = useSelector(state => state.estimate.housing.created_on)

    return (
        <Box sx={{ 
            mb: 3, 
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" }
        }}>
            <Box sx={{ 
                width: 184, 
                mb: { xs: 4, sm: 0 },
            }}>
                <MainPhoto />
            </Box>
            <Box sx={{
                width: {
                    xs: "100%",
                    sm: "calc(100% - 220px)"
                },
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDark,
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: "7px"
                }}>
                    Le {getDateToString(created)}
                </Typography>
                <HousingName />
                <Typography sx={{
                    mt: 1,
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDarker,
                    fontSize: "16px",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }}>
                    {rue}
                    <br />
                    {codePostal} {commune}
                </Typography>
                <Box sx={{ m: 2 }} />
                <Seller />
            </Box>
        </Box>
    )
}

export default IDcard