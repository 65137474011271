import { Fragment, useEffect, useMemo, useState } from "react"
import Step01 from "./steps/Step01"
import Step02 from "./steps/Step02"
import Step03 from "./steps/Step03"
import BottomNav from "./components/BottomNav"
import TopBar from "./components/TopBar"
import Step04 from "./steps/Step04"
import Step00 from "./steps/Step00"
import WrapperStep from "./components/WrapperStep"
import Step05 from "./steps/Step05"
import LoadingScreen from "../../../common/components/LoadingScreen"
import { api, baseURL } from "../../../common/setupApi"
import Step06 from "./steps/Step06"
import { addAlert } from "../../alerts/alertsSlice"
import { useDispatch } from "react-redux"
import axios from "axios"
import { v4 as uuid } from 'uuid';
import { getBlob } from "../../photoUploader/settings"
import { scssVar } from "../../../app/scssVar"

const initialAddress = {
    isOk: false,
    adresse: "",
    code_postal: "",
    code_commune: "",
    nom_commune: "",
    code_departement: "",
    longitude: "",
    latitude: ""
}

const initialHousingType = {
    is_flat: false,
    is_house: false,
    type_vente: null
}

const initialHousing = {
    surface_reelle_bati: "",
    surface_terrain_totale: "",
    nombre_pieces_principales: "",
}

const initialEstimation = {
    nb_chambres: "",
    etage_bien: "",
    etages_batiment: "",
    etat_du_bien: "",
    luminosite: null,
    attrait: null,
    avec_balcon: false,
    avec_terrasse: false,
    avec_jardin: false,
    avec_rooftop: false,
    avec_veranda: false,
    avec_stationnement: false,
    avec_garage: false,
    avec_grenier: false,
    avec_cave: false,
    avec_cabanon: false,
    avec_piscine: false,
    avec_spa: false,
    avec_teletravail: false,
    avec_home_cinema: false,
    avec_salle_activite: false,
    avec_ascenseur: false,
    avec_alarme: false,
    avec_climatisation: false,
    avec_poele_bois: false,
    avec_mitoyennete: false,
    avec_vue_exceptionnelle: false,
    avec_residence_securisee: false,
    avec_droit_passage: false
}

const initialUserInfos = {
    loading: false,
    errors: {},
    data: {
        lead_session_id: null,
        lead_first_name: "",
        lead_last_name: "",
        lead_email: "",
        lead_phone: "",
        project: "",
        contact: "",
    }
}

const initialGeo = {
    loading: false,
    data: {}
}

const initialConfig = {
    loading: true,
    error: null,
    data: {}
}

const initialResults = {
    loading: false,
    value_displayed: 0
}

const getStepName = (step) => {
    switch (step) {
        case 1:
            return "surface"
        case 2:
            return "etat"
        case 3:
            return "caract"
        case 4:
            return "contact"
        default:
            return null
    }
}

const ApiIframe = () => {
    const dispatch = useDispatch()
    const [step, setStep] = useState(0)
    const [isNotFirstEstimation, setIsNotFirstEstimation] = useState(false)
    const [config, setConfig] = useState(initialConfig)
    const [address, setAddress] = useState(initialAddress)
    const [geo, setGeo] = useState(initialGeo)
    const [housingType, setHousingType] = useState(initialHousingType)
    const [housing, setHousing] = useState(initialHousing)
    const [estimation, setEstimation] = useState(initialEstimation)
    const [userInfos, setUserInfos] = useState(initialUserInfos)
    const [results, setResults] = useState(initialResults)
    const [paramForResendCode, setParamForResendCode] = useState(null)

    const street = address.adresse
        .replace(address.code_postal, "")
        .replace(address.nom_commune, "")
        .trim()

    const parentUrl = 
        window.location !== window.parent.location 
            ? document.referrer 
            : document.location.href

    const readOnly = parentUrl.includes(document.location.origin)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    useEffect(() => {
        const getConfig = () => {
            axios
                .post(`${baseURL}/v1/api/iframe-config/`, { domain: parentUrl })
                .then(res => {
                    const { org_name, org_address, org_email, org_phone, org_logo, org_color, api_legal_docs, api_departments } = res.data

                    const data = {
                        org_name, 
                        org_address, 
                        org_email, 
                        org_phone, 
                        org_color: { 
                            main: org_color || scssVar.primary, 
                            light: org_color ? `rgba${org_color.substring(3, org_color.length - 1)}, 0.1)` : scssVar.primaryLight 
                        },
                        org_logo: null,
                        api_departments,
                        api_legal_docs: api_legal_docs.map(d => ({ ...d, checked: false }))
                    }

                    getBlob(org_logo)
                        .then(blob => {
                            setConfig({ 
                                loading: false, 
                                error: null, 
                                data: { ...data, org_logo: blob ? URL.createObjectURL(blob) : null } 
                            })
                        })
                        .catch(err => {
                            console.log(err.response)
                            setConfig({ loading: false, error: null, data })
                        })
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response) {
                        const unauthorized = err.response.status === 401
                        if (unauthorized) {
                            setConfig({ loading: false, error: "Unauthorized", data: {} })
                        } else {
                            setConfig({ loading: false, error: err.response.data, data: {} })
                            dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                        }
                    } else {
                        setConfig({ loading: false, error: "Unknown", data: {} })
                        dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                    }
                })
        }
        const getReadOnlyConfig = () => {
            api
                .get("v1/profile/org-infos/")
                .then(orgRes => {
                    const { org_name, org_address, org_email, org_phone, org_logo, org_color } = orgRes.data

                    api
                        .get("v1/api/infos/")
                        .then(async (apiInfos) => {
                            const { api_legal_docs, api_departments } = apiInfos.data

                            const data = {
                                org_name, 
                                org_address, 
                                org_email, 
                                org_phone, 
                                org_color: { 
                                    main: org_color || scssVar.primary, 
                                    light: org_color ? `rgba${org_color.substring(3, org_color.length - 1)}, 0.1)` : scssVar.primaryLight 
                                },
                                org_logo: null,
                                api_departments,
                                api_legal_docs: api_legal_docs.map(d => ({ ...d, checked: false }))
                            }

                            getBlob(org_logo)
                                .then(blob => {
                                    setConfig({ 
                                        loading: false, 
                                        error: null, 
                                        data: { ...data, org_logo: blob ? URL.createObjectURL(blob) : null } 
                                    })
                                })
                                .catch(err => {
                                    console.log(err.response)
                                    setConfig({ loading: false, error: null, data })
                                })
                        })
                        .catch(err => {
                            console.log(err.response)
                            setConfig({ loading: false, error: err.response?.data || "unknown", data: {} })
                            dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                        })
                })
                .catch(err => {
                    console.log(err.response)
                    setConfig({ loading: false, error: err.response?.data || "unknown", data: {} })
                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                })
        }
        if (readOnly) {
            getReadOnlyConfig()
        } else {
            getConfig()
        }
    }, [parentUrl, readOnly, dispatch])

    const saveProgress = (step) => {
        if (readOnly) {
            return
        }

        return axios
            .post(`${baseURL}/v1/api/lead-estimation/`, {
                lead_session_id: userInfos.data.lead_session_id,
                step: getStepName(step),
                estimation_datas: {
                    ...geo.data, 
                    ...housingType, 
                    ...housing, 
                    ...estimation,
                    ...userInfos.data,
                    domain: parentUrl,
                    adresse: address.adresse,
                    code_postal: address.code_postal,
                    code_commune: address.code_commune,
                    nom_commune: address.nom_commune,
                    code_departement: address.code_departement,
                    longitude: address.longitude,
                    latitude: address.latitude,
                }
            })
            .then(() => {})
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue lors de la sauvegarde de l'évaluation. Veuillez réessayer.", "error"))
            })
    }

    const sendCode = async () => {
        return axios
            .post(`${baseURL}/v1/api/send-code/`, { 
                ...geo.data, 
                ...housingType, 
                ...housing, 
                ...estimation,
                ...userInfos.data,
                domain: parentUrl,
                adresse: address.adresse,
                code_postal: address.code_postal,
                code_commune: address.code_commune,
                nom_commune: address.nom_commune,
                code_departement: address.code_departement,
                longitude: address.longitude,
                latitude: address.latitude,
            })
    }

    const topBar = useMemo(() => {
        return (
            <TopBar
                orgLogo={config.data.org_logo}
                orgName={config.data.org_name}
                street={street}
                code={address.code_postal}
                city={address.nom_commune}
                housingType={housingType.type_vente}
            />
        )
    }, [config.data.org_logo, config.data.org_name, street, address, housingType.type_vente])

    if (config.loading) {
        return <LoadingScreen />
    }

    if (config.error) {
        return (
            <Fragment>
                {config.error === "Unauthorized" ? <p>Unauthorized</p> : <p>Error</p>}
            </Fragment>
        )
    }

    if (step === 1) {
        return (
            <WrapperStep 
                form 
                onGoNext={() => {
                    saveProgress(2)
                    setStep(2)
                }}
            >
                {topBar}
                <Step01 
                    orgColor={config.data.org_color}
                    housing={housing}
                    estimation={estimation}
                    onChangeHousingValue={e => {
                        const { name, value } = e.target
                        setHousing({ ...housing, [name]: value })
                    }}
                    onChangeEstimationValue={e => {
                        const { name, value } = e.target
                        setEstimation({ ...estimation, [name]: value })
                    }}
                    isFlat={housingType.is_flat}
                />
                <BottomNav 
                    form
                    noDisplayBack
                    orgColor={config.data.org_color}
                    disabledNext={
                        housing.surface_reelle_bati === ""
                        || housing.nombre_pieces_principales === ""
                    }
                />
            </WrapperStep>
        )
    }

    if (step === 2) {
        return (
            <WrapperStep 
                form 
                onGoNext={() => {
                    setStep(3)
                    saveProgress(3)
                }}
            >
                {topBar}
                <Step02 
                    orgColor={config.data.org_color}
                    estimation={estimation}
                    onChange={e => {
                        const { name, value } = e.target
                        setEstimation({ ...estimation, [name]: value })
                    }}
                    onCheck={(name, value) => setEstimation({ ...estimation, [name]: value })}
                />
                <BottomNav 
                    form 
                    orgColor={config.data.org_color}
                    onGoBack={() => setStep(1)} 
                />
            </WrapperStep>
        )
    }

    if (step === 3) {
        if (results.loading) {
            return <LoadingScreen />
        }

        return (
            <WrapperStep 
                form 
                onGoNext={() => {
                    if (isNotFirstEstimation) {
                        setResults({ ...results, loading: true })

                        if (readOnly) {
                            return setTimeout(() => {
                                setResults({ loading: false, value_displayed: 999999 })
                                setStep(6)
                            }, [4000])
                        }

                        sendCode()
                            .then(res => {
                                setResults({ loading: false, value_displayed: res.data.value_displayed })
                                setStep(6)
                            })
                            .catch(err => {
                                console.log(err.response)
                                setResults({ ...results, loading: false })
                                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                            })
                    } else {
                        setStep(4)
                        saveProgress(4)
                    }
                }}
            >
                {topBar}
                <Step03 
                    orgColor={config.data.org_color}
                    estimation={estimation} 
                    onCheck={(name, value) => setEstimation({ ...estimation, [name]: value })} 
                />
                <BottomNav 
                    form 
                    orgColor={config.data.org_color}
                    onGoBack={() => setStep(2)}
                />
            </WrapperStep>
        )
    }

    if (step === 4) {
        if (userInfos.loading) {
            return <LoadingScreen />
        }
        
        return (
            <WrapperStep 
                form 
                onGoNext={async () => {
                    setUserInfos({ ...userInfos, loading: true })

                    if (readOnly) {
                        return setTimeout(() => {
                            setStep(5)
                            setUserInfos({ ...userInfos, loading: false })
                            setParamForResendCode("estimation_id")
                            dispatch(addAlert("Renseignez le code 123456", "success"))
                        }, [4000])
                    }

                    return sendCode()
                        .then(res => {
                            setParamForResendCode(res.data.estimation_id)
                            setStep(5)
                            setUserInfos({ ...userInfos, loading: false, errors: {} })
                        })
                        .catch(err => {
                            console.log(err.response)
                            if (err.response?.data && err.response?.status) {
                                if (err.response.status === 400) {
                                    setUserInfos({ ...userInfos, errors: err.response.data })
                                    for (const property in err.response.data) {
                                        const error = err.response.data[property] || []
                                        error.forEach(d => dispatch(addAlert(d, "error")))
                                    }
                                } else {
                                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                                    setUserInfos({ ...userInfos, loading: false })
                                }
                            } else {
                                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                                setUserInfos({ ...userInfos, loading: false })
                            }
                        })
                }}
            >
                <TopBar
                    orgLogo={config.data.org_logo}
                    orgName={config.data.org_name}
                    street={street}
                    code={address.code_postal}
                    city={address.nom_commune}
                    housingType={housingType.type_vente}
                />
                <Step04 
                    orgColor={config.data.org_color}
                    userInfos={userInfos.data}
                    onChange={e => setUserInfos({ ...userInfos, data: { ...userInfos.data, [e.target.name]: e.target.value } })}
                    legalDocs={config.data.api_legal_docs || []}
                    onCheckDoc={newDocs => setConfig({ ...config, data: { ...config.data, api_legal_docs: newDocs }})}
                    errorContactField={userInfos.errors}
                />
                <BottomNav 
                    form
                    noDisplayBack
                    orgColor={config.data.org_color}
                    disabledNext={
                        !userInfos.data.lead_email.trim().length
                        || !userInfos.data.lead_first_name.trim().length
                        || !userInfos.data.lead_last_name.trim().length
                        || (config.data.api_legal_docs && config.data.api_legal_docs.filter(d => !d.checked).length > 0)
                    }
                />
            </WrapperStep>
        )
    }

    if (step === 5) {
        if (results.loading) {
            return <LoadingScreen />
        }

        return (
            <WrapperStep>
                <TopBar
                    orgLogo={config.data.org_logo}
                    orgName={config.data.org_name}
                    street={street}
                    code={address.code_postal}
                    city={address.nom_commune}
                    housingType={housingType.type_vente}
                />
                <Step05 
                    orgColor={config.data.org_color}
                    email={userInfos.data.lead_email}
                    readOnly={readOnly}
                    estimationId={paramForResendCode}
                    onGoNext={async (code) => {
                        setResults({ ...results, loading: true })

                        if (readOnly) {
                            return setTimeout(() => {
                                if (code === "123456") {
                                    setResults({ loading: false, value_displayed: 999999 })
                                    setIsNotFirstEstimation(true)
                                    setStep(6)
                                } else {
                                    setResults({ ...results, loading: false })
                                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                                }
                            }, [4000])
                        }
        
                        return axios
                            .post(`${baseURL}/v1/api/validate-code/`, { domain: parentUrl, lead_email: userInfos.data.lead_email, validation_code: code })
                            .then(res => {
                                setResults({ loading: false, value_displayed: res.data.value_displayed })
                                setIsNotFirstEstimation(true)
                                setStep(6)
                            })
                            .catch(err => {
                                console.log(err)
                                setResults({ ...results, loading: false })
                                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                            })
                    }}
                />
            </WrapperStep>
        )
    }

    if (step === 6) {
        return (
            <Step06
                street={street}
                code={address.code_postal}
                city={address.nom_commune}
                value={results.value_displayed}
                sellType={housingType.type_vente}
                surface={housing.surface_reelle_bati}
                rooms={housing.nombre_pieces_principales}
                bedrooms={estimation.nb_chambres}
                orgLogo={config.data.org_logo}
                orgName={config.data.org_name}
                orgAddress={config.data.org_address}
                orgEmail={config.data.org_email}
                orgPhone={config.data.org_phone}
                orgColor={config.data.org_color}
                onReset={() => {
                    setStep(0)
                    setAddress(initialAddress)
                    setHousingType(initialHousingType)
                    setHousing(initialHousing)
                    setEstimation(initialEstimation)
                    setGeo(initialGeo)
                    setUserInfos({ ...userInfos, data: { ...userInfos.data, lead_session_id: null } })
                    setResults(initialResults)
                }}
            />
        )
    }

    return (
        <Step00 
            orgLogo={config.data.org_logo}
            orgName={config.data.org_name}
            orgColor={config.data.org_color}
            departements={config.data.api_departments || []}
            address={address}
            onChangeAddress={(value) => setAddress({ ...address, adresse: value, isOk: false })}
            onSelectAddress={(payload) => setAddress({ ...payload, isOk: true })}
            onResetAddress={() => setAddress(initialAddress)}
            isHouse={housingType.is_house}
            isFlat={housingType.is_flat}
            onClickHouse={() => {
                setHousingType({
                    is_house: !housingType.is_house,
                    is_flat: false,
                    type_vente: !housingType.is_house === true ? "Maison" : null
                })
            }}
            onClickFlat={() => {
                setHousingType({
                    is_flat: !housingType.is_flat,
                    is_house: false,
                    type_vente: !housingType.is_flat === true ? "Appartement" : null
                })
            }}
            onGoNext={() => {
                const leadSessionId = uuid()

                setStep(1)
                setUserInfos({ ...userInfos, data: { ...userInfos.data, lead_session_id: leadSessionId } })
                setGeo({ ...geo, loading: true })

                if (readOnly) {
                    return setTimeout(() => {
                        setGeo({ 
                            loading: false, 
                            data: { 
                                longitude: address.longitude, 
                                latitude: address.latitude 
                            } 
                        })
                    }, [4000])
                }

                const payload = {
                    lon: address.longitude, 
                    lat: address.latitude, 
                    domain: parentUrl,
                    type_vente: housingType.type_vente,
                    address: address.adresse,
                    postcode: address.code_postal,
                    citycode: address.code_commune,
                    city: address.nom_commune,
                    lead_session_id: leadSessionId
                }

                axios
                    .post(`${baseURL}/v1/housing/geo-features`, payload)
                    .then(res => {
                        setGeo({ 
                            loading: false, 
                            data: { 
                                ...res.data, 
                                longitude: address.longitude, 
                                latitude: address.latitude 
                            } 
                        })
                    })
                    .catch(err => {
                        console.log(err.response)
                        setGeo(initialGeo)
                        setStep(0)
                        dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                    })
            }}
        />
    )
}

export default ApiIframe