import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { saveTracfin } from "../tracfinSlice"

const useSaveTracfin = () => {
    const dispatch = useDispatch()
    const timeout = useRef(null)

    useEffect(() => {
        return () => {
            clearTimeout(timeout.current)
        }
    }, [])

    const handleSaveTracfin = (delay) => {
        timeout.current = setTimeout(() => {
            dispatch(saveTracfin())
        }, [delay])
    }

    return {
        clearTimeout: () => clearTimeout(timeout.current),
        saveTracfin: handleSaveTracfin
    }
}

export default useSaveTracfin