import { Box } from "@mui/material"
import { scssVar } from "../../../app/scssVar"

const BottomBar = ({ children, sx }) => {
    return (
        <Box sx={Object.assign({}, {
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 1301,
            backgroundColor: "white"
        }, sx?.wrapper)}>
            <Box sx={{
                height: `calc(65px + ${scssVar.sab})`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "1341px",
                margin: "0 auto",
                p: {
                    xs: "0 20px",
                    sm: "0 35px",
                    lg: "0 60px"
                }
            }}>
                {children}
            </Box>
        </Box>
    )
}

export default BottomBar