import { Box, Checkbox, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import Chip from "../../../common/components/Chip"
import { getDateToString, m2, numberWithSpaces } from "../../../common/utils"
import { getEstimationsOfHousing } from "../../estimate/actions"
import useStep from "../../estimate/hooks/useStep"

const EstimationsItem = ({ estimation }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const checkedEstimations = useSelector(state => state.estimations.estimations.selected)
    const loadingDelete = useSelector(state => state.estimations.estimations.loadingDelete)
    const isDeleting = loadingDelete && checkedEstimations.includes(estimation.id)
    const checked = checkedEstimations.includes(estimation.id)
    const [hovered, setHovered] = useState(false)
    const getStep = useStep()
    const address = estimation.housing.adresse
    const codePostal = estimation.housing.code_postal
    const commune = estimation.housing.nom_commune
    const rue = address
        .replace(codePostal, "")
        .replace(commune, "")
        .trim()
    const value = estimation.value_custom || estimation.value_displayed || estimation.value
    const working = useSelector(state => state.estimate.working)

    const handleMouseEnter = () => {
        if ("ontouchstart" in document) return
        setHovered(true)
    }

    const handleMouseLeave = () => {
        if ("ontouchstart" in document) return
        setHovered(false)
    }

    const handleCheck = e => {
        const checked = e.target.checked

        dispatch({
            type: "estimations/select",
            payload: { 
                id: estimation.id,
                checked
            }
        })
    }

    const handleClick = () => {
        if (isDeleting || working) {
            return
        }
        
        dispatch({ type: "estimate/set_estimation", payload: estimation })
        dispatch({ type: "estimate/set_housing", payload: estimation.housing })
        dispatch({ type: "estimate/set_is_lead_estimation", payload: false })

        // <History /> envoie la requête 
        // donc ce code empêche d'envoyer 2 fois la requête
        // on a besoin de ces datas si l'estimation n'est pas finie
        if (estimation.current_step !== "Synthesis") {
            dispatch(getEstimationsOfHousing())
        }
        
        navigate(`/estimer/${getStep(estimation.current_step).path}`)
    }

    return (
        <Box 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                borderRadius: "8px",
                bgcolor: scssVar.greyLighter,
                p: "0 10px",
                border: 1,
                borderColor: hovered || checked ? scssVar.grey : scssVar.greyLighter,
                height: "186px",
            }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Checkbox 
                        inputProps={{ 'aria-label': 'select estimation' }}
                        checked={checked}
                        onChange={handleCheck}
                        disabled={isDeleting || working}
                        sx={{
                            marginLeft: "-12px",
                            color: scssVar.grey,
                            '&.Mui-checked': {
                                color: scssVar.primary
                            },
                        }}
                    />
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.greyDark,
                        fontSize: "14px"
                    }}>
                        {getDateToString(estimation.created_on)}
                    </Typography>
                </Box>
                <Box sx={{
                    width: "46px",
                    height: "28px",
                    backgroundColor: scssVar.primaryLighter,
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: scssVar.primary,
                    fontSize: "14px",
                }}>
                    {getStep(estimation.current_step).progress}%
                </Box>
            </Box>

            <Box 
                onClick={handleClick}
                sx={{
                    cursor: isDeleting || working ? "not-allowed" : "pointer",
                    mt: "-5px"
                }}
            >
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    fontWeight: 600,
                    mb: "1px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                    {estimation.housing.name}
                </Typography>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDark,
                    fontSize: "14px",
                    mb: "7px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                    {rue}
                    <br />
                    {codePostal} {commune}
                </Typography>
                {hovered ? (
                    <Fragment>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontSize: "14px",
                            mb: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>
                            Type de bien : <b>{estimation.housing.type_vente}</b>
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>
                            <Chip 
                                label={`
                                    ${estimation.housing.nombre_pieces_principales || "0"} pièce${estimation.housing.nombre_pieces_principales > 1 ? "s" : ""}
                                `}
                                style={{
                                    color: scssVar.greyDark,
                                    backgroundColor: scssVar.greyLight,
                                    border: `1px solid ${scssVar.grey}`,
                                    marginRight: 4
                                }}
                            />
                            <Chip 
                                label={`
                                    ${estimation.housing.surface_reelle_bati || "0"} ${m2}
                                `}
                                style={{
                                    color: scssVar.greyDark,
                                    backgroundColor: scssVar.greyLight,
                                    border: `1px solid ${scssVar.grey}`,
                                    marginRight: 4
                                }}
                            />
                            <Chip 
                                label={`
                                    ${estimation.nb_chambres ? (
                                        estimation.nb_chambres === "4 et plus" ? 
                                            "4+ chambres" 
                                            : `${estimation.nb_chambres} chambre${estimation.nb_chambres > 1 ? "s" : ""}`
                                    ): "0 chambre"}
                                `}
                                style={{
                                    color: scssVar.greyDark,
                                    backgroundColor: scssVar.greyLight,
                                    border: `1px solid ${scssVar.grey}`,
                                }}
                            />
                        </Box>
                    </Fragment>
                ):(
                    <Fragment>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontSize: "14px",
                            mb: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>
                            Client <b>{estimation.client_potentiel || "aucun"}</b>
                        </Typography>
                        {estimation.housing.prix_vente ? (
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    color: scssVar.success,
                                    fontSize: "14px",
                                    mr: "5px",
                                    whiteSpace: "nowrap"
                                }}>
                                    Vendu à
                                </Typography>
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    color: scssVar.success,
                                    fontWeight: 600,
                                    mr: "10px",
                                    whiteSpace: "nowrap"
                                }}>
                                    {numberWithSpaces(estimation.housing.prix_vente)}€
                                </Typography>
                                {/* {estimation.housing.prix_vente !== value && ( */}
                                    <Typography sx={{
                                        textDecoration: "line-through",
                                        fontFamily: scssVar.fontFamily,
                                        color: "#b3a2ce",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap"
                                    }}>
                                        {numberWithSpaces(value)}€
                                    </Typography>
                                {/* )} */}
                            </Box>
                        ):(
                            <Box sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <Chip
                                    label="Prix évalué"
                                    style={{
                                        marginRight: 10,
                                        padding: "3px 8px"
                                    }}
                                />
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    color: scssVar.primary,
                                    fontWeight: 600
                                }}>
                                    {numberWithSpaces(value)}€
                                </Typography>
                            </Box>
                        )}
                    </Fragment>
                )}
            </Box>
        </Box>
    )
}

export default EstimationsItem