import { useSelector } from "react-redux"
import { selectIsAuth } from "../selectors"

const useAuth = () => {
    const accessToken = useSelector(state => state.auth.accessToken)
    const refreshToken = useSelector(state => state.auth.refreshToken)
    const loading = useSelector(state => state.auth.loading)
    const user = useSelector(state => state.auth.user)
    const isAuth = useSelector(selectIsAuth)

    return {
        isAuth,
        accessToken,
        refreshToken,
        loading,
        user
    }
}

export default useAuth