import useOrgBlob from "../../photoUploader/hooks/useOrgBlob"

const OrgLogo = ({ 
    wrapperStyle, 
    imgStyle, 
    textStyle 
}) => {
    const orgBlob = useOrgBlob()
    const blob = orgBlob.blob
    const loading = orgBlob.loading

    return (
        <div style={Object.assign({}, {
            width: 250,
            height: 110,
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }, wrapperStyle)}>
            {loading ? (
                <p style={Object.assign({}, { 
                    textAlign: "center", 
                    color: "black", 
                    textTransform: "uppercase",
                    fontWeight: 500
                }, textStyle)}>
                    Chargement du logo...
                </p>
            ): blob ? (
                <img 
                    src={blob} 
                    alt="logo org"
                    style={Object.assign({}, {
                        width: "auto",
                        maxWidth: "90%",
                        maxHeight: "90%",
                    }, imgStyle)}
                /> 
            ):(
                <p style={Object.assign({}, { 
                    textAlign: "center", 
                    color: "black", 
                    textTransform: "uppercase",
                    fontWeight: 500
                }, textStyle)}>
                    Insérez votre logo
                </p>
            )}
        </div>
    )
}

export default OrgLogo