import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"

const EstimationsTitle = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const working = useSelector(state => state.estimate.working)

    const handleNewEstimation = () => {
        dispatch({ type: "estimate/reset" })
        navigate("/estimer")
    }

    return (
        <Box sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "space-between",
            alignItems: "flex-end",
            mb: 4,
            "@media (max-width: 1100px)": {
                flexDirection: "column",
                alignItems: "flex-start",
            },
        }}>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: "black",
                fontSize: 36,
                fontWeight: 700
            }}>
                Mes évaluations
            </Typography>
            <Button onClick={handleNewEstimation} disabled={working} sx={{ pr: "35px" }}>
                <i className="iconoir-plus" style={{ fontSize: 24, marginRight: 5 }}></i>
                Nouvelle évaluation
            </Button>
        </Box>
    )
}

export default EstimationsTitle