import { Box, Grid, IconButton, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import FieldControl from "../../../../../common/components/FieldControl"
import { numberWithSpaces } from "../../../../../common/utils"
import useBudgetUsage from "../../../hooks/useBudgetUsage"
import DrawerStepEditor from "../../drawerStepEditor/DrawerStepEditor"

const Li = ({ children }) => {
    return (
        <li style={{
            marginBottom: 7,
            backgroundColor: scssVar.greyLighter,
            padding: "20px 10px",
            borderRadius: "8px"
        }}>
            {children}
        </li>
    )
}

const Charges = () => {
    const [state, setState] = useState({ open: false, step: null })
    const { charges } = useBudgetUsage()

    const handleOpen = () => {
        setState({ ...state, open: true, step: "Step11" })
    }

    const handleClose = () => {
        setState({ open: false, step: null })
    }

    return (
        <Fragment>
            <Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                    }}>
                        Charges
                    </Typography>
                    <IconButton 
                        onClick={handleOpen}
                        sx={{
                            fontSize: 24,
                            color: "black",
                            bgcolor: scssVar.greyLight,
                            ml: 1
                        }}
                    >
                        <i className="iconoir-edit-pencil edit-icon"></i>
                    </IconButton>
                </Box>
                <Box sx={{ m: 1 }} />
                <ul style={{
                    color: "black",
                    fontSize: 14,
                }}>
                    {charges.map((d, i) =>
                        <Li key={i}>
                            {d.label} 
                            {d.value !== null && d.value !== "" ? ( 
                                <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                                    {numberWithSpaces(d.value)} {d.unit}
                                </b> 
                            ):(
                                <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                                    {d.nd}
                                </b>
                            )}
                        </Li>
                    )}
                </ul>
            </Box>
            <DrawerStepEditor
                step={state.step}
                open={state.open}
                onClose={handleClose}
            />
        </Fragment>
    )
}

const Energie = () => {
    const [state, setState] = useState({ open: false, step: null })
    const { coutEau, energie } = useBudgetUsage()

    const handleOpen = () => {
        setState({ ...state, open: true, step: "Step12" })
    }

    const handleClose = () => {
        setState({ open: false, step: null })
    }

    return (
        <Fragment>
            <Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                    }}>
                        Énergie
                    </Typography>
                    <IconButton 
                        onClick={handleOpen}
                        sx={{
                            fontSize: 24,
                            color: "black",
                            bgcolor: scssVar.greyLight,
                            ml: 1
                        }}
                    >
                        <i className="iconoir-edit-pencil edit-icon"></i>
                    </IconButton>
                </Box>
                <Box sx={{ m: 1 }} />
                <ul style={{
                    color: "black",
                    fontSize: 14,
                }}>
                    <Li>
                        {coutEau.label} 
                        {coutEau.value !== null && coutEau.value !== "" ? (
                            <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                                {numberWithSpaces(coutEau.value)} {coutEau.unit}
                            </b>
                        ):(
                            <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                                {coutEau.nd}
                            </b>
                        )}
                    </Li>
                    <Li>
                        {energie.label}
                        {energie.value !== null && energie.value !== "" ? (
                            <b style={{ marginLeft: 5 }}>
                                <span style={{ whiteSpace: "nowrap" }}>
                                    {numberWithSpaces(energie.value)} {energie.unit}
                                </span>
                            </b>
                        ):(
                            <b style={{ marginLeft: 5 }}>
                                {energie.nd}
                            </b>
                        )}
                        <ul style={{
                            marginTop: 20,
                            color: "#6e868c",
                            fontSize: 14,
                            paddingLeft: 15
                        }}>
                            {energie.detail.map((d, i) => 
                                <li key={i} style={{ marginBottom: 15 }}>
                                    {d.label} 
                                    {d.value !== null && d.value !== "" ? (
                                        <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                                            {numberWithSpaces(d.value)} {d.unit}
                                        </b> 
                                    ):(
                                        <b style={{ marginLeft: 5, whiteSpace: "nowrap" }}>
                                            {d.nd}
                                        </b>
                                    )}
                                </li>
                            )}
                        </ul>
                    </Li>
                </ul>
            </Box>
            <DrawerStepEditor
                step={state.step}
                open={state.open}
                onClose={handleClose}
            />
        </Fragment>
    )
}

const TabBudgetUsage = () => {
    const dispatch = useDispatch()
    const periodicity = useSelector(state => state.estimate.periodicity)
    const { totalBudgetUsage } = useBudgetUsage()

    const handleChangePeriodicity = (name, newValue) => {
        dispatch({ type: "estimate/set_periodicity", payload: newValue })
    }

    return (
        <Fragment>
            <Box sx={{
                width: "100%",
                maxWidth: 510
            }}>
                <FieldControl
                    label="Périodicité"
                    type="checkbox"
                    disallowDeselect
                    choices={[
                        { label: "Mensuel", value: "month" },
                        { label: "Annuel", value: "year" },
                    ]}
                    selectedValue={periodicity}
                    onChange={handleChangePeriodicity}
                    xs={6}
                />
                <Box sx={{ m: 2 }} />
                <Box sx={{ 
                    height: 90, 
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    pl: 2,
                    bgcolor: scssVar.greyLighter
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        fontSize: 28,
                        fontWeight: 700
                    }}>
                        {numberWithSpaces(totalBudgetUsage)} € / {periodicity === "month" ? "mois" : periodicity === "year" ? "an" : null}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ m: 4 }} />
            <Grid container spacing={2.5}>
                <Grid item xs={12} md={6}>
                    <Charges />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Energie  />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default TabBudgetUsage