import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuid } from 'uuid';

export const addAlert = (text, level) => (dispatch) => {
    const id = uuid()

    const getText = () => {
        if (level === "error") {
            return text || "Une erreur est survenue, veuillez réessayer."
        }
        
        return text || "OK !"
    }

    dispatch(add({ id, text: getText(), level }))

    return setTimeout(() => { 
        dispatch(clearAlert(id))
    }, 7500)
}

export const clearAlert = (id) => (dispatch) => dispatch(clear(id))

export const alertsSlice = createSlice({
    name: "alerts",
    initialState: [],
    reducers: {
        add: (state, action) => {
            // "Mutate" the existing state, no return value needed
            state.push(action.payload)
        },
        clear: (state, action) => {
            // Construct a new result array immutably and return it
            return state.filter(d => d.id !== action.payload)
        }
    }
    // rien en cas de logout
})

const { add, clear } = alertsSlice.actions

export default alertsSlice.reducer
