import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { selectEstimation } from "../../estimate/selectors"
import BetaVersionAlertText from "../components/BetaVersionAlertText"
import ButtonReset from "../components/ButtonReset"
import DownloadIconButton from "../components/DownloadIconButton"
import LoadingMask from "../components/LoadingMask"
import MissingFieldAlertText from "../../../common/components/MissingFieldAlertText"
import { getMandat, selectMandat, selectMandatEditor2 } from "../mandat/mandatSlice"
import Page01 from "./pages/Pages01"
import Page02 from "./pages/Pages02"
import { visitsActions } from "./visitsSlice"
import { Tab, TabPanel, Tabs, a11yProps } from "../../../common/components/Tabs"

const VisitsPdf = () => {
    const dispatch = useDispatch()
    const estimationId = useSelector(selectEstimation).id
    const numberMandat = useSelector(selectMandatEditor2).mandat_id
    const { isLoading, error } = useSelector(selectMandat)
    const [resetMode, setResetMode] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openMissingValues, setOpenMissingValues] = useState(false)
    const [index, setIndex] = useState(0)

    const emptyNumberMandat = numberMandat === "" || numberMandat === null || numberMandat === undefined
    const scale = loading 
        ? 1.0 
        : 1.0
        // : 0.665 // --> Lecteur PDF Google : 77%

    // pour avoir les infos liés au mandat de l'estimation
    useEffect(() => {
        dispatch(getMandat(estimationId))
    }, [dispatch, estimationId])

    const handleDownload = (pageId, title) => {
        if (emptyNumberMandat) {
            setOpenMissingValues(true)
            return
        }
        
        const pdf = new jsPDF("p", "mm", "a4", true)

        const generatePage = async (html, addPage) => {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true // Pour la map leaflet
            })
                .then(canvas => {
                    const img = canvas.toDataURL("img/png", 1.0)
                    const imgWidth = 210
                    const imgHeight = canvas.height * imgWidth / canvas.width + 3
        
                    pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST")
        
                    if (addPage) {
                        pdf.addPage()
                    }
                })
        }

        setLoading(true)

        setTimeout(async () => {
            const page = document.getElementById(pageId)
    
            await generatePage(page, false)

            setLoading(false)
    
            return pdf.save(title)
        }, [1500])
    }

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: scssVar.primary }}>Chargement...</p>
                <CircularProgress size={20} sx={{ color: scssVar.primary, ml: 1 }} />
            </Box>
        )
    }

    if (error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer.</p>
    }

    return (
        <Fragment>
            <LoadingMask loading={loading} />

            <BetaVersionAlertText />

            <Box sx={{ m: 4 }} />

            <MissingFieldAlertText visible={emptyNumberMandat} sx={{ mb: 4 }}>
                Veuillez renseigner le <u>numéro de mandat</u> dans l'onglet "Mandat"
            </MissingFieldAlertText>

            <Box sx={{ bgcolor: scssVar.greyLighter, borderRadius: "8px", pb: 6 }}>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <Tabs
                        value={index} 
                        onChange={(e, newValue) => setIndex(newValue)} 
                        aria-label="visits tabs"
                        variant="scrollable"
                    >
                        <Tab label="Demande de visite" {...a11yProps(0)} />
                        <Tab label="Compte rendu de visite" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={index} index={0}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <DownloadIconButton disabled={loading} onClick={() => handleDownload("demande_visite", "demande-de-visite")} />
                        <p>Demande de visite</p>
                    </Box>
                    <Box sx={{ m: 1 }} />
                    <Box sx={{ width: "fit-content",  margin: "0 auto" }}>
                        <ButtonReset onClick={() => setResetMode("demande_visite")} />
                        <Page01 scale={scale} isExporting={loading} />
                    </Box>
                </TabPanel>

                <TabPanel value={index} index={1}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <DownloadIconButton disabled={loading} onClick={() => handleDownload("compte_rendu", "compte-rendu-de-visite")} />
                        <p>Compte rendu de visite</p>
                    </Box>
                    <Box sx={{ m: 1 }} />
                    <Box sx={{ width: "fit-content", margin: "0 auto" }}>
                        <ButtonReset onClick={() => setResetMode("compte_rendu")} />
                        <Page02 scale={scale} />
                    </Box>
                </TabPanel>
            </Box>

            <Dialog open={resetMode !== null} onClose={() => setResetMode(null)} fullWidth>
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Effacer la saisie en cours ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="light"
                        sx={{ width: 150 }}
                        onClick={() => setResetMode(null)} 
                    >
                        Fermer
                    </Button>
                    <Button
                        variant="danger"
                        sx={{ width: 150 }}
                        onClick={() => {
                            dispatch(visitsActions.reset(resetMode))
                            setResetMode(null)
                        }}
                    >
                        Effacer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openMissingValues} onClose={() => setOpenMissingValues(false)} fullWidth>
                <DialogContent sx={{ pb: 0 }}>
                    <MissingFieldAlertText visible>
                        Veuillez renseigner le <u>numéro de mandat</u> dans l'onglet "Mandat"
                    </MissingFieldAlertText>
                </DialogContent>
                <DialogActions>
                    <Button variant="light" onClick={() => setOpenMissingValues(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default VisitsPdf