import { useSelector } from "react-redux"
import RenderTextWithLineBreak from "../../../../common/components/RenderTextWithLineBreak"
import { selectOrg } from "../../../account/selectors"
import { selectEstimation } from "../../../estimate/selectors"
import Margin from "../../components/Margin"

const Description = () => {
    const userColor = useSelector(selectOrg).color
    const description = useSelector(selectEstimation).description_estimation
    const backgroundColor = "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"

    return (
        <div>
            <p style={{ fontWeight: 600, fontSize: 16 }}>
                <u>Description du bien :</u>
            </p>
            <Margin m={5} />
            <div style={{
                background: backgroundColor,
                height: 72,
                maxHeight: 72,
                overflow: "hidden",
                flexGrow: 1,
                color: "black",
                padding: "3px"
            }}>
                <RenderTextWithLineBreak
                    text={description || ""} 
                    sx={{
                        fontSize: "12px",
                        color: "inherit",
                        lineHeight: "14px"
                    }}
                />
            </div>
        </div>
    )
}

export default Description