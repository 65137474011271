import Margin from "../../components/Margin"
import Wrapper from "../../components/Wrapper"
import SectionTitle from "../components/SectionTitle"
import Title from "../components/Title"
import LeafletMap from "../../../map/LeafletMap"
import { selectAddress, selectHousing } from "../../../estimate/selectors"
import { useSelector } from "react-redux"
import RenderTextWithLineBreak from "../../../../common/components/RenderTextWithLineBreak"

const Page02 = ({ scale }) => {
    const { rue, codePostal, commune } = useSelector(selectAddress)
    const description = useSelector(state => state.estimate.estimation.description_estimation)
    const isHouse = useSelector(selectHousing).is_house
    const isFlat = useSelector(selectHousing).is_flat
    const flatOrHouseText = isHouse ? "de maisons" : isFlat ? "d'appartements" : "de biens"

    return (
        <Wrapper id="synthesis_p2" scale={scale}>

            <Margin m={35} />

            <Title>
                SYNTHÈSE DE L’ÉVALUATION
            </Title>

            <Margin m={25} />

            <SectionTitle>
                Situation du bien
            </SectionTitle>

            <Margin m={25} />

            <p style={{
                color: "black",
                fontSize: 16,
                fontWeight: 700
            }}>
                {rue}, {codePostal} {commune}
            </p>

            <Margin m={10} />

            <p style={{
                color: "black",
                fontSize: 15,
            }}>
                Ventes {flatOrHouseText} similaires à proximité
            </p>

            <Margin m={5} />

            <div style={{ width: "100%", height: 325 }}>
                <LeafletMap withFeatures height="100%" />
            </div>

            <Margin m={35} />

            <SectionTitle>
                Description du bien
            </SectionTitle>

            <Margin m={25} />

            <div style={{ overflow: "hidden", maxHeight: 403 }}>
                <RenderTextWithLineBreak
                    text={description || ""} 
                    sx={{
                        fontSize: 15,
                        lineHeight: "19px"
                    }}
                />
            </div>
        </Wrapper>
    )
}

export default Page02