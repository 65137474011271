import { Backdrop, Menu } from "@mui/material"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import { selectIsUpdateAvailable } from "../../../features/appUpdate/appUpdateSlice"
import { logoutUser } from "../../../features/auth/actions"
import { opinionOpened } from "../../../features/userOpinion/userOpinionSlice"
import MenuItemNav from "./MenuItemNav"

const MenuNav = ({ anchorEl, onClose, children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const isUpdateAvailable = useSelector(selectIsUpdateAvailable)

    return (
        <Fragment>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
                sx={{
                    "& .MuiList-root": {
                        padding: 0,
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "12px"
                    }
                }}
            >
                <li></li> {/* remove auto focus on phone */}
                {/* <MenuItemUserOpinion callback={onClose} /> */}
                <MenuItemNav
                    icon="iconoir-message-text"
                    iconColor="white"
                    label="Votre avis"
                    onClick={() => {
                        onClose()
                        dispatch(opinionOpened())
                    }}
                    sx={{
                        borderBottom: 1, 
                        borderColor: scssVar.grey,
                        color: "white",
                        background: scssVar.primary,
                        display: { xs: "flex", sm: "none" },
                        "&:hover": {
                            background: scssVar.primary,
                        }
                    }}
                />
                {children}
                <MenuItemNav
                    icon="iconoir-profile-circled"
                    label="Mon compte"
                    isAlert={isUpdateAvailable}
                    onClick={() => {
                        onClose()
                        if (pathname.includes("mon-compte")) return
                        navigate("/mon-compte")
                    }}
                    sx={{
                        borderBottom: 1, 
                        borderColor: scssVar.grey,
                    }}
                />
                <MenuItemNav
                    icon="iconoir-log-out"
                    iconColor={scssVar.danger}
                    label="Se déconnecter"
                    onClick={() => {
                        onClose()
                        dispatch(logoutUser())
                    }}
                    sx={{
                        color: scssVar.danger
                    }}
                />
            </Menu>
            <Backdrop
                open={Boolean(anchorEl)} 
                sx={{ 
                    background: "rgba(20, 20, 20, 0.6)",
                    WebkitBackdropFilter: "blur(4px)",
                    backdropFilter: "blur(4px)",
                    zIndex: 1300
                }} 
            />
        </Fragment>
    )
}

export default MenuNav