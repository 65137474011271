import { useSelector } from "react-redux"
import { m2, numberWithSpaces } from "../../../../common/utils"
import { selectOrg } from "../../../account/selectors"
import { selectCosts, selectHousing } from "../../../estimate/selectors"
import Footer from "../../components/Footer"
import GreyBox from "../../components/GreyBox"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"
import Wrapper from "../../components/Wrapper"
import Description from "../components/Description"
import Opinion from "../components/Opinion"
import Visitors from "../components/Visitors"

const pageId = "compte_rendu"

const Page02 = ({ scale }) => {
    const { prixPresentation } = useSelector(selectCosts)
    const orgName = useSelector(selectOrg).name
    const surfaceReelleBati = useSelector(selectHousing).surface_reelle_bati

    return (
        <Wrapper id={pageId} scale={scale}>
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <OrgLogo wrapperStyle={{ width: "100%" }} />
                </div>
                <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ fontWeight: 600, fontSize: 35 }}>Compte rendu de visite</p>
                </div>
            </div>

            <Margin m={20} />
            
            <p style={{ fontSize: 16, lineHeight: "18px" }}>
                Dans le cadre de sa mission d’intermédiaire (prévue par la loi Hoguet et plus particulièrement par le
                Code Civil article 1993), {orgName} transactions rend compte systématiquement de
                ses visites à ses mandants. 
            </p>

            <Margin m={20} />

            <p style={{ fontSize: 16, lineHeight: "18px" }}>
                A ce titre et pour respecter nos engagements, nous vous remercions de bien vouloir compléter ce
                document en nous indiquant quel est votre avis et quels sont vos commentaires à propos du
                bien que vous venez de visiter.
            </p>

            <Margin m={5} />

            <Visitors pageId={pageId} />

            <Margin m={10} />

            <Description />

            <Margin m={15} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3, fontSize: 16 }}>Surface :</p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 4px",
                    fontSize: 16
                }}>
                    {surfaceReelleBati}
                </GreyBox>
                <p style={{ marginLeft: 3, marginRight: 30, fontSize: 16 }}>{m2}</p>
                <p style={{ marginRight: 3, fontSize: 16 }}>Prix affiché :</p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 4px",
                    fontSize: 16
                }}>
                    {numberWithSpaces(prixPresentation)}
                </GreyBox>
                <p style={{ marginLeft: 3, fontSize: 16}}>€</p>
            </div>

            <Margin m={15} />

            <Opinion />

            <Footer />
        </Wrapper>
    )
}

export default Page02