import MobileNav from "../../../common/components/nav/MobileNav"
import { ReactComponent as SmileyNeutre } from "../../../common/assets/smiley_neutre.svg"
import scssVar from "../../../app/App.scss"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import DesktopNav from "../../../common/components/nav/DesktopNav"
import { saveProgress } from "../actions"
import { Box, Typography } from "@mui/material"
import ResultTitle from "./components/ResultTitle"
import ResultSubtitle from "./components/ResultSubtitle"
import ResultCard from "./components/ResultCard"
import Result3Buttons from "./components/Result3Buttons"
import PageWrapper from "../../../common/components/PageWrapper"
import { appName } from "../../../common/utils"
import Button from "../../../common/components/Button"

const Result = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const noHousingId = useSelector(state => state.estimate.housing.id) === null
    const working = useSelector(state => state.estimate.working)
    //const stepName = "Result"

    const handleNewEstimation = () => {
        navigate("/estimer")
        dispatch({ type: "estimate/reset" })
    }

    const handleGoPrev = () => {
        navigate("/estimer/step-8")
        dispatch(saveProgress("Step8"))
    }

    const handleGoSynthesis = () => {
        navigate("/estimer/synthese")
        dispatch(saveProgress("Synthesis"))
    }

    const handleContinue = () => {
        navigate("/estimer/step-11")
        dispatch(saveProgress("Step11"))
    }

    if (noHousingId) {
        return <Navigate to="/" />
    }

    return (
        <PageWrapper variant="result">
            <MobileNav />
            <Box sx={{ m: { xs: 1, sm: 0 } }} />
            <DesktopNav />
            <Box sx={{ m: { xs: 4, sm: 4 } }} />
            <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}>
                <ResultTitle title="Votre évaluation" />
                <ResultSubtitle
                    subtitle={`${appName} vous propose une valeur intermédiaire.`}
                    smiley={<SmileyNeutre />}
                    text="L’évaluation est complète à"
                    percent="75%"
                    color={scssVar.warning}
                />
                <Result3Buttons
                    onClickNewEstimation={handleNewEstimation}
                    onClickGoSynthesis={handleGoSynthesis}
                    onClickPrev={handleGoPrev}
                />
                <ResultCard />
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    fontWeight: 700,
                    fontSize: 24,
                    color: "black",
                    textAlign: "center",
                    lineHeight: "33.6px"
                }}>
                    Souhaitez-vous encore améliorer la précision ?
                </Typography>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    margin: "15px 0 20px",
                    fontSize: 14,
                    color: "black",
                    textAlign: "center",
                }}>
                    Appréciez l’impact du <b>budget d’usage</b> du bien…
                </Typography>
                <Button
                    variant="success"
                    onClick={handleContinue}
                    disabled={working}
                >
                    Continuer
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 24,
                            marginLeft: 5
                        }}
                    ></i>
                </Button>
            </Box>
        </PageWrapper>
    )
}

export default Result