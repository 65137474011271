import { Box, Grid, IconButton, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import { capitalizeFirstLetter, m2, numberWithSpaces } from "../../../../../common/utils"
import DpeGseGradient from "../../../../../common/components/DpeGseGradient"
import useCharacteristicItems from "../../../hooks/useCharacteristicItems"
import useConstructionItems from "../../../hooks/useConstructionItems"
import useEnvEtServicesItems from "../../../hooks/useEnvEtServicesItems"
import useInterieurEtDecoItems from "../../../hooks/useInterieurEtDecoItems"
import useSurfacesEtPiecesItems from "../../../hooks/useSurfacesEtPiecesItems"
import useVieDeQuartierItems from "../../../hooks/useVieDeQuartierItems"
import { selectEstimation } from "../../../selectors"
import Description from "./components/Description"
import DrawerStepEditor from "../../drawerStepEditor/DrawerStepEditor"
// import DrawerTantiemesLot from "./DrawerTantiemesLot"

const TabFormBox = ({ title, onClick, children, step }) => {

    const handleClick = () => {
        onClick(step)
    }
    
    return (
        <Box sx={{
            bgcolor: scssVar.greyLighter,
            borderRadius: "8px",
            padding: "24px",
            position: "relative",
            height: "100%"
        }}>
            <Typography sx={{
                fontSize: "20px",
                fontWeight: 700,
                fontFamily: scssVar.fontFamily,
                color: "black",
                mb: 3,
                width: "84%"
            }}>
                {title}
            </Typography>
            {onClick && (
                <IconButton 
                    onClick={handleClick}
                    sx={{
                        fontSize: 24,
                        color: "black",
                        position: "absolute",
                        right: 15,
                        top: 15,
                        bgcolor: scssVar.greyLight
                    }}
                >
                    <i className="iconoir-edit-pencil edit-icon"></i>
                </IconButton>
            )}
            {children}
        </Box>
    )
}

const initialState = {
    open: false,
    step: null
}

const TabFicheBien = () => {
    const estimation = useSelector(selectEstimation)
    const surfacesEtPiecesItems = useSurfacesEtPiecesItems()
    const constructionItems = useConstructionItems()
    const characteristicItems = useCharacteristicItems(estimation)
    const interieurEtDecoItems = useInterieurEtDecoItems()
    const vieDeQuartierItems = useVieDeQuartierItems()
    const envEtServicesItems = useEnvEtServicesItems()
    const [state, setState] = useState(initialState)

    const handleOpen = (step) => {
        setState({ ...state, open: true, step })
    }

    const handleClose = () => {
        setState(initialState)
    } 

    return (
        <Fragment>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flexDirection: { xs: "column", md: "row" }
            }}>
                <Description />
                {/* <DrawerTantiemesLot /> */}
            </Box>
            <Grid container spacing={1.1}>
                <Grid item xs={12} md={6}>
                    <TabFormBox title="Surface et pièces" onClick={handleOpen} step="Step1">
                        <ul style={{
                            color: "black",
                            fontSize: 14
                        }}>
                            {surfacesEtPiecesItems
                                .filter(d => d.displayed)
                                .map((d, i) =>
                                    <li key={i} style={{ marginBottom: 10 }}>
                                        {d.label}
                                        {d.value !== null && d.value !== "" && d.value !== undefined ? ( 
                                            <b style={{ marginLeft: 5 }}>
                                                {d.unit === m2 ? (
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {numberWithSpaces(d.value)}{d.unit}
                                                    </span>
                                                ): d.value}
                                            </b> 
                                        ):(
                                            <b style={{ marginLeft: 5 }}>
                                                <span>{d.nd}</span>
                                            </b>
                                        )}
                                    </li>
                                )
                            }
                        </ul>
                    </TabFormBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TabFormBox title="État du bien" onClick={handleOpen} step="Step4">
                        <ul style={{
                            color: "black",
                            fontSize: 14
                        }}>
                            {constructionItems.map((d, i) =>
                                <li key={i} style={{ marginBottom: 10 }}>
                                    {d.label} <b>{d.value !== null && d.value !== "" ? capitalizeFirstLetter(d.value) : d.nd}</b>
                                </li>
                            )}
                            <li style={{ marginBottom: 10 }}>
                                Emission de Gaz à Effet de Serre (GSE) {(estimation.gse === "NC" || estimation.gse === null) && <b>NC</b>}
                                <DpeGseGradient palette="gse" value={estimation.gse} />
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                Diagnostic de Performance Energétique (DPE) {(estimation.dpe === "nc" || estimation.dpe === null) && <b>NC</b>}
                                <DpeGseGradient palette="dpe" value={estimation.dpe} />
                            </li>
                        </ul>
                    </TabFormBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TabFormBox title="Caractéristiques" onClick={handleOpen} step="Step5">
                        {characteristicItems
                            .filter(d => d.checked && d.displayed)
                            .length ? (
                                <ul style={{
                                    color: "black",
                                    fontSize: 14
                                }}>
                                    {characteristicItems
                                        .filter(d => d.checked && d.displayed)
                                        .map((d, i) =>
                                            <li key={i} style={{ marginBottom: 15, display: "flex", alignItems: "center" }}>
                                                <i
                                                    className={d.icon}
                                                    style={{ 
                                                        fontSize: 22,
                                                        color: "black",
                                                        marginRight: 8
                                                    }}
                                                ></i>
                                                {d.text} 
                                                {d.value !== null && d.value !== "" && ( 
                                                    <b style={{ marginLeft: 5 }}>
                                                        {d.unit === m2 ? (
                                                            <span style={{ whiteSpace :"nowrap" }}>
                                                                {numberWithSpaces(d.value)}{d.unit}
                                                            </span>
                                                        ): d.value}
                                                    </b> 
                                                )}
                                            </li>
                                        )
                                    }
                                </ul>
                            ):(
                                <Typography sx={{
                                    fontFamily: scssVar.fontFamily,
                                    fontSize: "14px",
                                    color: "black",
                                    textAlign: "center",
                                    fontWeight: 700
                                }}>
                                    Aucune
                                </Typography>
                            )
                        }
                    </TabFormBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TabFormBox title="Intérieur et décoration" onClick={handleOpen} step="Step7">
                        <ul style={{
                            color: "black",
                            fontSize: 14
                        }}>
                            {interieurEtDecoItems.map((d, i) =>
                                <li key={i} style={{ marginBottom: 10 }}>
                                    {d.label} <b>{d.value !== null && d.value !== "" ? d.value : d.nd}</b>
                                </li>
                            )}
                        </ul>
                    </TabFormBox>
                </Grid>
                <Grid item xs={12}>
                    <TabFormBox title="Environnements et services" onClick={handleOpen} step="Step8">
                        <ul style={{
                            color: "black",
                            fontSize: 14
                        }}>
                            {vieDeQuartierItems
                                .filter(d => d.checked && d.displayed)
                                .map((d, i) =>
                                    <li key={i} style={{ marginBottom: 15, display: "flex", alignItems: "center" }}>
                                        <i
                                            className={d.icon}
                                            style={{ 
                                                fontSize: 22,
                                                color: "black",
                                                marginRight: 8
                                            }}
                                        ></i>
                                        {d.text} 
                                    </li>
                                )
                            }
                        </ul>
                        <ul style={{
                            color: "black",
                            fontSize: 14
                        }}>
                            {envEtServicesItems
                                .filter(d => d.displayed)
                                .map((d, i) =>
                                    <li key={i} style={{ marginBottom: 10 }}>
                                        {d.label} <b>{d.value !== null && d.value !== "" ? d.value : d.nd}</b>
                                    </li>
                                )
                            }
                        </ul>
                    </TabFormBox>
                </Grid>
            </Grid>
            <DrawerStepEditor
                step={state.step}
                open={state.open}
                onClose={handleClose}
            />
        </Fragment>
    )
}

export default TabFicheBien