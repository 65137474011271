import { useMemo } from "react"
import { useSelector } from "react-redux"

const greyBackgroundIndexes = [0, 1, 4, 5, 8, 9, 12, 13, 16, 17, 20, 21]

const ListItem = ({ index, children }) => {
    const userColor = useSelector(state => state.account.org.color)

    const background = useMemo(() => {
        if (greyBackgroundIndexes.includes(index)) {
            return "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"
        }

        return "none"
    }, [index, userColor])

    return (
        <div 
            style={{ 
                marginBottom: 3,
                background,
                paddingLeft: 2,
            }}
        >
            {children}
        </div>
    )
}

export default ListItem