import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import FieldControl from "../../../../../common/components/FieldControl"
import { api } from "../../../../../common/setupApi"
import { numberWithSpaces } from "../../../../../common/utils"
import { addAlert } from "../../../../alerts/alertsSlice"
import { updateHousing } from "../../../actions"
import { selectValues } from "../../../selectors"
import Button from "../../../../../common/components/Button"

const ECART_TROP_IMPORTANT = .3

const Finalize = () => {
    const dispatch = useDispatch()
    const working = useSelector(state => state.estimate.working)
    const name = useSelector(state => state.estimate.housing.name)
    const address = useSelector(state => state.estimate.housing.adresse)
    const dateMiseEnVente = useSelector(state => state.estimate.housing.date_mise_en_vente)
    const dateVente = useSelector(state => state.estimate.housing.date_vente)
    const prixVente = useSelector(state => state.estimate.housing.prix_vente) 
    const housing = useSelector(state => state.estimate.housing) 
    const { estimationValue, customValue } = useSelector(selectValues)

    const prixDepart = customValue || estimationValue
    
    const [state, setState] = useState({
        open: false,
        dateMiseEnVente: "",
        dateVente: "",
        prixVente: "",
        loading: false
    })

    useEffect(() => {
        if (state.open) {
            setState(s => ({ 
                ...s, 
                dateMiseEnVente, 
                dateVente, 
                prixVente: prixVente || prixDepart
            }))
        }
    }, [state.open, dateMiseEnVente, dateVente, prixVente, prixDepart])

    const dateOk = useMemo(() => {
        if (state.dateVente && state.dateMiseEnVente) {
            return new Date(state.dateVente) >= new Date(state.dateMiseEnVente)
        } else {
            return false
        }
    }, [state.dateVente, state.dateMiseEnVente])

    const ecartTropImportant = useMemo(() => {
        return Math.abs((prixDepart / state.prixVente) - 1.0) > ECART_TROP_IMPORTANT
    }, [prixDepart, state.prixVente])

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState({
            ...state, 
            [name]: value
        })
    }

    const handleSubmit = async () => {
        dispatch({ type: "estimate/set_working", payload: true })

        setState({ ...state, loading: true })

        const housingCopy = {
            ...housing,
            date_mise_en_vente: state.dateMiseEnVente,
            date_vente: state.dateVente,
            prix_vente: state.prixVente
        }

        return api
            .post("v1/housing/update", housingCopy)
            .then(res => {
                dispatch(updateHousing(res.data))
                dispatch(addAlert("Cette évaluation a bien été modifiée !", "success"))
                dispatch({ type: "estimate/set_working", payload: false })
                setState({ ...state, loading: false, open: false })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
                setState({ ...state, loading: false })
            })
    }

    return (
        <Fragment>
            <Box sx={{ m: 1 }}>
                <Button
                    onClick={handleOpen}
                    disabled={working}
                    sx={{
                        "& i": {
                            fontSize: 24,
                            mr: "10px"
                        }
                    }}
                >
                    <i className="iconoir-euro-square"></i>
                    Finaliser une vente
                </Button>
            </Box>
            <Dialog 
                open={state.open} 
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                    display: "flex",
                    alignItems: "center",
                }}>
                    Vous avez vendu ce bien ?
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        bgcolor: scssVar.greyLighter,
                        borderRadius: "16px",
                        padding: "20px",
                        mb: 3
                    }}>
                        <span style={{
                            backgroundColor: scssVar.primaryLighter,
                            color: scssVar.primary,
                            fontSize: 14,
                            width: 93,
                            height: 28,
                            padding: "4px 8px",
                            borderRadius: "100px",
                            marginBottom: 20
                        }}>
                            Prix évalué
                        </span>
                    <Typography sx={{
                        color: "black",
                        fontFamily: scssVar.fontFamily,
                        fontWeight: 600,
                        mt: 1
                    }}>
                        {name}
                    </Typography>
                    <Typography sx={{
                        color: scssVar.greyDark,
                        fontFamily: scssVar.fontFamily,
                        fontSize: 14,
                        mt: "5px"
                    }}>
                        {address}
                    </Typography>
                    <Typography sx={{
                        color: scssVar.primary,
                        fontFamily: scssVar.fontFamily,
                        fontWeight: 600,
                        mt: "5px"
                    }}>
                        {numberWithSpaces(prixDepart)}€
                    </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FieldControl
                                label="Date de mise en vente"
                                isSuccess={state.dateMiseEnVente} 
                                onChange={handleChange}
                                value={state.dateMiseEnVente || ""}
                                type="date" 
                                placeholder="Date de mise en vente"
                                name="dateMiseEnVente"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldControl
                                label="Date de vente"
                                isSuccess={state.dateVente} 
                                onChange={handleChange}
                                value={state.dateVente || ""}
                                type="date" 
                                placeholder="Date de vente"
                                name="dateVente"
                            />
                        </Grid>
                            
                        <Grid item xs={10}>
                            <FieldControl
                                label="Prix de vente"
                                isSuccess={state.prixVente} 
                                onChange={handleChange}
                                value={state.prixVente || ""}
                                type="number" 
                                placeholder="Prix de vente"
                                name="prixVente"
                                icon={{ position: "right", icon: "fas fa-euro-sign" }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {ecartTropImportant && (
                                <Box sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    width: "100%"
                                }}>
                                    <Box sx={{
                                        height: 40,
                                        bgcolor: scssVar.dangerLight,
                                        width: "100%",
                                        borderRadius: "4px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <i 
                                            className="iconoir-emoji-sad"
                                            style={{
                                                fontSize: 20,
                                                color: scssVar.danger
                                            }}
                                        ></i>
                                        {/* {Number(value)/Number(state.prixVente)} */}
                                        {/* {Number(state.prixVente) - Number(value)} */}
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Box sx={{ height: "auto", minHeight: 25, marginTop: 1 }}>
                        {ecartTropImportant && (
                            <Typography sx={{
                                color: scssVar.danger,
                                fontSize: 14,
                                fontFamily: scssVar.fontFamily
                            }}>
                                {/* <i 
                                    className="iconoir-emoji-sad"
                                    style={{
                                        fontSize: 20,
                                        color: scssVar.danger
                                    }}
                                ></i> */}
                                L’écart entre le prix estimé et le prix de vente est très important.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ height: "auto", minHeight: 25, marginTop: 1 }}>
                        {state.dateVente !== null && state.dateMiseEnVente !== null && !dateOk && (
                            <Typography sx={{
                                color: scssVar.danger,
                                fontSize: 14,
                                fontFamily: scssVar.fontFamily
                            }}>
                                {/* <i 
                                    className="iconoir-emoji-sad"
                                    style={{
                                        fontSize: 20,
                                        color: scssVar.danger
                                    }}
                                ></i> */}
                                La date de vente doit être postérieure à la date de mise en vente.
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        //disabled={state.loading || (state.name !== null && !state.name.trim().length)}
                        loading={state.loading}
                        disabled={
                            (state.prixVente === "" && state.prixVente !== null && !state.prixVente.trim().length)
                            || !dateOk
                            || state.loading
                        }
                    >
                        C'est vendu !
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Finalize