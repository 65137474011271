import InputSelect from "../../../components/InputSelect"
import InputText from "../../../components/InputText"
import Margin from "../../../components/Margin"
import SectionTitle from "./SectionTitle"
import { useDispatch, useSelector } from "react-redux"
import { buyOfferActions, selectBuyOfferEditor } from "../buyOfferSlice"

const Conditions = () => {
    const dispatch = useDispatch()
    const buyState = useSelector(selectBuyOfferEditor)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(buyOfferActions.setField({ name, value }))
    }

    return (
        <div>
            <SectionTitle>
                CONDITIONS SUSPENSIVES ou PARTICULIÈRES
            </SectionTitle>

            <Margin m={2} />

            <InputSelect
                value={buyState.condition}
                name="condition"
                onChange={handleChange}
                style={{ width: "100%" }}
            >
                <option value="avec_suspensive">Avec condition suspensive de prêt</option>
                <option value="sans_suspensive">Sans condition suspensive de prêt</option>
                <option value="achat_comptant">Achat comptant</option>
            </InputSelect>

            <div style={{ display: "flex", alignItems: "center"}}>
                <p style={{ marginRight: 3, fontSize: 13 }}>Autres :</p>
                <InputText 
                    value={buyState.autre_condition}
                    name="autre_condition"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
            </div>
        </div>
    )
}

export default Conditions