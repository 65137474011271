import { Box, CircularProgress, Typography } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../app/scssVar"
import PhotoUploader from "../../photoUploader/PhotoUploader"
import PhotosButton from "../../photoUploader/components/PhotosButton"
import { useDispatch, useSelector } from "react-redux"
import useOrgBlob from "../../photoUploader/hooks/useOrgBlob"
import { getCompressedImg, getCroppedImg } from "../../photoUploader/settings"
import { addAlert } from "../../alerts/alertsSlice"
import { api } from "../../../common/setupApi"
import { deleteOrgBlob, setOrgBlob } from "../../photoUploader/photosSlice"

const OrganizationLogo = () => {
    const dispatch = useDispatch()
    const updateLoading = useSelector(state => state.account.org.updateLogoLoading)
    const { blob, loading } = useOrgBlob()

    const handleSubmit = async (cropArea, rotation, aspect, file) => {
        dispatch({ type: "account/set_org_logo_loading" })

        return getCroppedImg(URL.createObjectURL(file), cropArea, rotation)
            .then(async (croppedImg) => {
                console.log(`originalFile size ${croppedImg.size / 1024 / 1024} MB`);
        
                return getCompressedImg(croppedImg)
                    .then(async (compressedImg) => {
                        console.log(`compressedFile size ${compressedImg.size / 1024 / 1024} MB`)
        
                        const formData = new FormData()
                        formData.append("logo", compressedImg)
        
                        return api
                            .post("v1/profile/org-logo/", formData, { headers: { "content-type": "multipart/form-data" } })
                            .then(res => {
                                dispatch({ type: "account/set_org_logo_success", payload: res.data })
                                dispatch(setOrgBlob(res.data))
                                dispatch(addAlert("Le logo a bien été modifié", "success"))
                            })
                            .catch(err => {
                                console.log(err.response)
                                dispatch({ type: "account/set_org_logo_failure", payload: err.response?.data })
                                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(addAlert("Une erreur est survenue lors de la compression de l'image, veuillez réessayer", "error"))
                        dispatch({ type: "account/set_org_logo_failure", payload: "compression error" })
                    })
            })
            .catch(err => {
                console.log(err)
                dispatch(addAlert("Une erreur est survenue lors du redimensionnement de l'image, veuillez réessayer", "error"))
                dispatch({ type: "account/set_org_logo_failure", payload: "crop error" })
            })
    }

    const handleDelete = async () => {
        dispatch({ type: "account/delete_org_logo_loading" })

        return api
            .delete("v1/profile/delete-logo")
            .then(() => {
                dispatch({ type: "account/delete_org_logo_success" })
                dispatch(deleteOrgBlob())
                dispatch(addAlert("Le logo a bien été supprimé", "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ type: "account/delete_org_logo_failure", payload: err.response })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }

    return (
        <Fragment>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                fontSize: 14,
                color: "black",
                mb: 1
            }}>
                Logo de l'organisation
            </Typography>
            <PhotoUploader 
                blob={blob}
                onSubmit={handleSubmit}
                onUpdate={handleSubmit}
                onDelete={handleDelete}
                newPhotoLoading={updateLoading}
                loadingBlob={loading}
                withCropper
                ButtonEmpty={({ newPhotoLoading, loadingBlob }) => {
                    return (
                        <PhotosButton 
                            component="span"
                            sx={{
                                width: 115,
                                height: 67,
                                bgcolor: scssVar.greyLighter,
                                border: 1,
                                borderColor: scssVar.grey,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {(newPhotoLoading || loadingBlob) ? (
                                <CircularProgress size={20} sx={{ color: scssVar.primary }} />
                            ):(
                                <i 
                                    className="iconoir-plus"
                                    style={{
                                        fontSize: 50,
                                        color: scssVar.grey
                                    }}
                                ></i>
                            )}
                        </PhotosButton>
                    )
                }}
                ButtonBlob={({ handleOpenMenu, newPhotoLoading, loadingBlob }) => {
                    if (newPhotoLoading || loadingBlob) {
                        return (
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                maxWidth: 115
                            }}>
                                <CircularProgress size={20} sx={{ color: scssVar.primary }} />
                            </Box>
                        )
                    }

                    return (
                        <img
                            onClick={handleOpenMenu}
                            src={blob}
                            alt="logo agence"
                            style={{
                                cursor: "pointer",
                                width: "100%",
                                maxWidth: 115
                            }}
                        />
                    )
                }}
            />
        </Fragment>
    )
}

export default OrganizationLogo