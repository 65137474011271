import { Typography } from "@mui/material";
import { scssVar } from "../../../app/scssVar";

const Title = ({ children }) => (
    <Typography sx={{
        fontWeight: 700,
        fontFamily: scssVar.fontFamily,
        color: "black",
        mb: 1
    }}>
        {children}
    </Typography>
)

export default Title