import { Fragment } from "react"
import { useSelector } from "react-redux"
import useAuth from "../../../auth/hooks/useAuth"
import { numberWithSpaces, phoneWithSpaces } from "../../../../common/utils"
import { selectEstimation, selectHousing } from "../../../estimate/selectors"
import useBudgetUsage from "../../../estimate/hooks/useBudgetUsage"
import AdDescriptif from "../components/AdDescriptif"
import AdDescription from "../components/AdDescription"
import AdHeader from "../components/AdHeader"
import AdReleasedBy from "../components/AdReleasedBy"
import AdScalePerformance from "../components/AdScalePerformance"
import AdSectionTitle from "../components/AdSectionTitle"
import Margin from "../../components/Margin"
import Wrapper from "../../components/Wrapper"
import ERP from "../components/ERP"
import { selectOrg } from "../../../account/selectors"
import Photos from "../components/Photos"

const Agent = () => {
    const userColor = useSelector(state => state.account.org.color)
    const { user } = useAuth()
    const { firstName, lastName, email, phone } = user

    return (
        <div style={{
            border: "1px solid " + userColor,
            color: userColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            padding: 18,
            position: "absolute",
            bottom: 48,
            left: 29
        }}>
            <p style={{
                fontSize: 25,
                fontWeight: 700,
                whiteSpace: "nowrap"
            }}>
                {firstName} {lastName}
            </p>
            {phone && (
                <Fragment>
                    <Margin m={8} />
                    <p style={{
                        fontSize: 19,
                        fontWeight: 700,
                        whiteSpace: "nowrap"
                    }}>
                        {phoneWithSpaces(phone)}
                    </p>
                </Fragment>
            )}
            <Margin m={5} />
            <p style={{
                fontSize: 16,
                fontWeight: 500,
                zIndex: 2
            }}>
                {email}
            </p>
        </div>
    )
}

const AdLandscape = ({ scale }) => {
    const userColor = useSelector(selectOrg).color
    const typeVente = useSelector(selectHousing).type_vente
    const adReference = useSelector(selectHousing).ad_reference_id
    const chargesCoPro = useSelector(selectEstimation).charge_copro_an
    const coutGlobal = useSelector(selectEstimation).ad_total_value
    const { totalBudgetUsage } = useBudgetUsage(true)

    const typeVenteFormat = typeVente.toLowerCase() === "maison" ? "la maison" : "l'appartement"

    return (
        <Wrapper id="ad_landscape" scale={scale}>
            <AdHeader variant="landscape" />

            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                <div style={{ width: "32%" }}>
                    <Photos docType="ad_landscape" />
                    <Margin m={10} />
                    <p style={{ fontSize: 18, fontWeight: 700, color: userColor }}>Réf. : #{adReference}</p>
                </div>

                <div style={{ width: "32%" }}>
                    <AdSectionTitle style={{ fontSize: 22 }}>A propos de {typeVenteFormat}</AdSectionTitle>
                    <Margin m={27} />
                    <AdDescription 
                        wrapperStyle={{
                            maxHeight: 345,
                        }}
                    />
                    <Margin m={20} />
                    <AdSectionTitle style={{ fontSize: 22 }}>Diagnostics</AdSectionTitle>
                    <Margin m={20} />
                    <AdScalePerformance />
                </div>

                <div style={{ width: "33%" }}>
                    <AdSectionTitle style={{ fontSize: 22}}>Descriptif</AdSectionTitle>
                    <Margin m={20} />
                    <AdDescriptif style={{ fontSize: 17, lineHeight: "28px" }} />
                    <Margin m={45} />
                    <AdSectionTitle style={{ fontSize: 22 }}>Budget total d’achat (indicatif)</AdSectionTitle>
                    <Margin m={7} />
                    <p style={{ fontSize: 12.5, lineHeight: "19px"}}>(avec frais de notaire, à faire préciser, hors crédit)</p>
                    <Margin m={25} />
                    <p style={{ color: userColor, fontSize: 40, fontWeight: 700 }}>{numberWithSpaces(coutGlobal)} €</p>
                    <Margin m={50} />
                    <AdSectionTitle style={{ fontSize: 22 }}>Budget d’usage annuel (indicatif)</AdSectionTitle>
                    <Margin m={7} />
                    <p style={{ fontSize: 12.5, lineHeight: "19px" }}>
                        Dont taxe foncière, charges totales, frais divers
                        <br />Selon les résidants actuels
                    </p>
                    <Margin m={25} />
                    <p style={{ color: userColor, fontSize: 40, fontWeight: 700 }}>{numberWithSpaces(totalBudgetUsage)} €</p>
                    <Margin m={5} />
                    <p style={{ fontSize: 12.5 }}>Dont charges de copropriété : {numberWithSpaces(chargesCoPro)} €</p>
                </div>
            </div>

            <Agent />
            <AdReleasedBy bottom={28} />
            <ERP bottom={28} left={29} />
        </Wrapper>
    )
}

export default AdLandscape