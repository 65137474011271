import { useSelector } from "react-redux"
import { selectOrg } from "../../../account/selectors"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"
import Wrapper from "../../components/Wrapper"
import { selectEstimation } from "../../../estimate/selectors"
import { selectMandatEditor2 } from "../mandatSlice"
// import { getGenderTextFormat } from "../../settings"
import ActionsSpe from "../components/ActionsSpe"
import AdsSites from "../components/AdsSites"
import Empty from "../../components/Empty"
import Footer from "../../components/Footer"
import GreyBox from "../../components/GreyBox"
import { Mandants } from "../components/MandantsList"
import SectionTitle from "../components/SectionTitle"

const Page03 = ({ scale }) => {
    const orgName = useSelector(selectOrg).name
    const orgEmail = useSelector(selectOrg).email
    const managerFirstName = useSelector(selectOrg).org_directeur_prenom
    const managerLastName = useSelector(selectOrg).org_directeur_nom
    const mediateurName = useSelector(selectOrg).org_mediateur_nom
    const mediateurEmail = useSelector(selectOrg).org_mediateur_site
    const fraisAgencePer = useSelector(state => state.estimate.estimation.frais_agence_pourcent)
    const honorairesMin = useSelector(selectOrg).frais_agence_absolu_minimum
    const exclusivity = useSelector(selectMandatEditor2).mandat_type_exclusivite
    const acquereurVendeur = useSelector(selectEstimation).a_charge_vendeur_ou_acquereur
    const acquereurVendeurText = 
        acquereurVendeur === "acquereur"
            ? "acquéreur"
            : "vendeur"

    return (
        <Wrapper id="mandat_p3" scale={scale}>
            <OrgLogo wrapperStyle={{ margin: "0 auto" }} />

            <Margin m={50} />

            <p style={{
                fontWeight: 600
            }}>
                INFORMATIONS PRECONTRACTUELLES
            </p>

            <Margin m={20} />

            <p style={{
                fontWeight: 600,
                fontSize: 11.5,
                lineHeight: "14px"
            }}>
                Conformément aux dispositions de la Loi Hamon, tout intermédiaire doit avant la prise d'un mandat avoir fait signer un document d'information
                précontractuelle. L’article L 121-17 du Code de la consommation. Ce document doit permettre à tout mandant de connaitre les informations sociales
                du prestataire (cf. : En tête), les honoraires applicables et les actions que seront menées dans le cadre du mandat. 
            </p>

            <Margin m={40} />

            <div style={{
                border: "1px solid black",
                padding: "10px"
            }}>
                <Mandants readOnly />
            </div>

            <Margin m={10} />

            <SectionTitle>
                1. ACTIONS MENEES ET DROITS DU MANDANT
            </SectionTitle>

            <Margin m={10} />

            <p style={{
                fontSize: 13,
                lineHeight: "13px"
            }}>
                Nos actions et supports les plus classiques pour promouvoir votre vente, sont :
            </p>

            <Margin m={10} />

            <AdsSites />

            <Margin m={5} />

            <div style={{ 
                display: "flex",
                alignItems: "center"
            }}>
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px",
                    marginRight: 30
                }}>
                    Des actions spécifiques :
                </p>
                <ActionsSpe />
            </div>

            <Margin m={10} />

            <ul style={{
                listStyleType: "disc",
                fontSize: 13,
                lineHeight: "16px",
                paddingLeft: 18
            }}>
                <li>
                    Toute réclamation devra se faire sur l’adresse : {orgEmail || <Empty>email organisation</Empty>}
                </li>
                <li>
                    La garantie commerciale et le service après-vente sont mis à votre disposition sur l’adresse mail : {orgEmail || <Empty>email organisation</Empty>}
                </li>
                <li>
                    Dans le cas d’un conflit, nous vous invitons à prendre contact avec notre médiateur, {mediateurName || <Empty>nom médiateur</Empty>}, {mediateurEmail || <Empty>email médiateur</Empty>}
                </li>
            </ul>

            <Margin m={15} />

            <p style={{
                fontSize: 13,
                lineHeight: "16px",
            }}>
                Le client a été informé qu'il dispose d'un délai de 14 jours pour résilier le mandat à compter de sa prise d'effet et qu’il peut résilier tout mandat exclusif
                au-delà du 3ème mois en respectant un préavis de 15 jours. De plus, en conformité avec l’article L136.1 du code du commerce, si le mandat se poursuit
                par tacite reconduction, le mandant a pris connaissance qu’il peut alors résilier le mandat à tout moment au-delà de cette reconduction. 
            </p>

            <Margin m={15} />

            <p style={{
                fontWeight: 600,
                fontSize: 13,
                lineHeight: "13px"
            }}>
                RGPD : Traitement des données à caractère personnel
            </p>

            <p style={{
                fontSize: 13,
                lineHeight: "16px",
            }}>
                Conformément à la loi n°78-17 du 6 janvier 1978 dans sa version en vigueur et au Règlement européen (UE) 2016/679, le Mandant est informé que le Mandataire
                procède au traitement des données à caractère personnel contenues dans le présent contrat. Le délégué à la protection des données
                (DPO) désigné au sein du cabinet/de l’agence étant {managerFirstName || <Empty>prénom directeur</Empty>} {managerLastName || <Empty>nom directeur</Empty>} Gérant de {orgName}.
            </p>

            <Margin m={15} />

            <SectionTitle>
                2. REMUNERATION DU MANDATAIRE
            </SectionTitle>

            <Margin m={5} />

            <p style={{
                fontSize: 13,
                lineHeight: "16px",
            }}>
                La rémunération du mandataire se calcule sur la base du prix net vendeur pour les honoraires à la charge de l'acquéreur et
                sur le prix final de vente s'ils sont à la charge du vendeur :
            </p>

            <Margin m={10} />

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <GreyBox style={{
                    marginRight: 3,
                    padding: "0 4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {exclusivity === "exclu" && "Mandat EXCLUSIF"}
                    {exclusivity === "semi" && "Mandat SEMI EXCLUSIF"}
                    {exclusivity === "simple" && "Mandat SIMPLE"}
                </GreyBox>
                <p style={{
                    fontSize: 13,
                    marginRight: 3
                }}>
                    à charge
                </p>
                <GreyBox style={{
                    padding: "0 4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {acquereurVendeurText}
                </GreyBox>
                <p style={{
                    marginRight: 3,
                    fontSize: 13
                }}>
                    ,
                </p>
                <p style={{
                    fontSize: 13,
                    marginRight: 3
                }}>
                    honoraires :
                </p>
                <GreyBox style={{
                    padding: "0 4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {fraisAgencePer} %
                </GreyBox>
            </div>

            <Margin m={15} />

            <p style={{
                fontSize: 13,
                lineHeight: "13px",
            }}>
                Pour toute vente, un minimum d'honoraire est fixé à {honorairesMin || <Empty>honoraires mininum</Empty>} € TTC quel que soit le prix final de vente.
            </p>

            {/* <Margin m={50} /> */}

            {/* <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 30
                }}>
                    <p style={{ fontWeight: 600, marginRight: 3 }}>
                        À :
                    </p>
                    <GreyBox w={190} h={25} />
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <p style={{ fontWeight: 600, marginRight: 3 }}>
                        DATE DE SIGNATURE :
                    </p>
                    
                    <GreyBox w={190} h={25} />
                </div>
            </div> 

            <Margin m={15} />

            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <p style={{ fontSize: 14, lineHeight: "14px" }}>Le client prospect</p>
                <p style={{ fontSize: 14, lineHeight: "14px" }}>Votre conseiller.e - E.I.</p>
            </div>

            <Margin m={5} />

            <div style={{ width: "100%", borderBottom: "1px solid black" }} />

            <Margin m={5} />

            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <div style={{ width: "49%" }}>
                    <p style={{
                        fontSize: 14,
                        lineHeight: "14px"
                    }}>
                        Signature avec la mention <span style={{ fontWeight: 600 }}><i>"Informations validées"</i></span>
                    </p>
                    <Margin m={5} />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "49.5%" }}>
                            <GreyBox style={{ width: "50%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {getGenderTextFormat(mandants[0].gender)}
                            </GreyBox>
                            <Margin m={3} />
                            <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {mandants[0].lastName || "Nom"}
                            </GreyBox>
                            <Margin m={3} />
                            <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {mandants[0].firstName || "Prénom"}
                            </GreyBox>
                        </div>
                        <div style={{ width: "49.5%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <GreyBox style={{ width: "50%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                    {getGenderTextFormat(mandants[1].gender)}
                                </GreyBox>
                            </div>
                            <Margin m={3} />
                            <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {mandants[1].lastName || "Nom"}
                            </GreyBox>
                            <Margin m={3} />
                            <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {mandants[1].firstName || "Prénom"}
                            </GreyBox>
                        </div>
                    </div>
                </div>
                <div style={{ width: "49%" }}>
                    <p style={{
                        fontSize: 14,
                        lineHeight: "14px",
                        textAlign: "end"
                    }}>
                        Signature avec la mention <span style={{ fontWeight: 600 }}><i>"Informations validées"</i></span>
                    </p>
                    <Margin m={5} />
                    <GreyBox style={{
                        padding: "0 4px",
                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "auto"
                    }}>
                        {mandataire.lastName || "Nom et prénom"}
                    </GreyBox>
                </div>
            </div>

            */}

            <Footer />
        </Wrapper>
    )
}

export default Page03