import { Box, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material"
import { useCallback, useState } from "react"
import Cropper from "react-easy-crop"
import Button from "../../../common/components/Button"
import { Rotate90DegreesCwRounded } from "@mui/icons-material"
import { scssVar } from "../../../app/scssVar"

const aspects = [
    {label: "3:2", value: 3 / 2},
    {label: "3:4", value: 3 / 4},
    {label: "1:1", value: 1},
]

const initialAspect = { value: 3 / 2, label: "3:2" }

const PhotoCropper = ({ 
    image,
    open, 
    onClose,
    onSubmit,
    aspect
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [aspect_, setAspect_] = useState(initialAspect)

    const handleRotate = () => {
        const newRotation = rotation + 90
        if (newRotation === 360) {
            setRotation(0)
        } else {
            setRotation(newRotation)
        }
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        //console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const handleResetState = () => {
        setCrop({ x: 0, y: 0 })
        setCroppedAreaPixels({ x: 0, y: 0 })
        setZoom(1)
        setRotation(0)
        setAspect_(initialAspect)
    }

    const handleClose = () => {
        onClose(handleResetState)
    }

    const handleSubmit = () => {
        onSubmit(croppedAreaPixels, rotation, aspect_.label, handleResetState)
    }

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogContent sx={{ position: "relative" }}>
                {!aspect && (
                    <div style={{ 
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        background: "white",
                        zIndex: 1
                    }}>
                        <div style={{
                            width: "100%",
                            paddingRight: 8,
                            paddingLeft: 8,
                            margin: "0 auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            height: 56,
                        }}>
                            <Button 
                                variant={aspect_.label === "3:2" ? "primary" : "primary-light"}
                                onClick={() => setAspect_(aspects.find(d => d.label === "3:2"))}
                                sx={{ fontSize: 13, height: 25, pl: 1, pr: 1, mr: 1 }}
                            >
                                Paysage
                            </Button>
                            <Button 
                                variant={aspect_.label === "3:4" ? "primary" : "primary-light"}
                                onClick={() => setAspect_(aspects.find(d => d.label === "3:4"))}
                                sx={{ fontSize: 13, height: 25, pl: 1, pr: 1, mr: 1 }}
                            >
                                Portrait
                            </Button>
                            <Button 
                                variant={aspect_.label === "1:1" ? "primary" : "primary-light"}
                                onClick={() => setAspect_(aspects.find(d => d.label === "1:1"))}
                                sx={{ fontSize: 13, height: 25, pl: 1, pr: 1 }}
                            >
                                Carré
                            </Button>
                        </div>
                    </div>
                )}
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={aspect || aspect_.value}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // onRotationChange={setRotation} // au doigt c'est pas ouf
                    showGrid={false}
                    style={{
                        containerStyle: {
                            top: !aspect ? "56px" : 0
                        }
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton 
                        onClick={handleRotate}
                        sx={{
                            bgcolor: scssVar.greyLighter,
                            color: scssVar.primary,
                            "&:hover": {
                                bgcolor: scssVar.greyLight,
                                color: scssVar.primary,
                            }
                        }}
                    >
                        <Rotate90DegreesCwRounded />
                    </IconButton>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button variant="light" onClick={handleClose} sx={{ mr: "15px" }}>Annuler</Button>
                    <Button onClick={handleSubmit}>Envoyer</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default PhotoCropper