import { useSelector } from "react-redux"
import Margin from "../../components/Margin"
import Title from "../components/Title"
import Wrapper from "../../components/Wrapper"

const Box = ({ title, children }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <div style={{
            padding: "20px 30px",
            background: "#f8f8f8",
        }}>
            <p style={{
                color: userColor,
                fontWeight: 700,
                fontSize: 18
            }}>
                {title}
            </p>
            <Margin m={7} />
            <div style={{ fontSize: 14.67, lineHeight: "19px" }}>
                {children}
            </div>
        </div>
    )
}

const Page07 = ({ scale }) => {
    return (
        <Wrapper id="synthesis_p7" scale={scale}>

            <Margin m={55} />

            <Title>
                STRATÉGIE DE PRIX : NOS CONSEILS
            </Title>

            <Margin m={50} />

            <p style={{ fontSize: 15, lineHeight: "18px" }}>
                En constatant qu'il existe des biens présentés à des prix plus élevés que celui que nous vous proposons d'établir ensemble, 
                il est important de comprendre l'incidence d'une mise en vente à un prix performant ! Nous avons pour principe de nous 
                positionner de manière à être attractif et surtout toujours le plus intéressant vis-à-vis des biens qui sont en concurrence avec le vôtre.
                <br/>
                C'est pourquoi nous tenons à vous communiquer notre expérience sur les 3 grandes durées de vente possibles :
            </p>

            <Margin m={50} />

            <Box title="1. La vente dans le premier mois de mise en commercialisation.">
                <p>
                    Le prix est bien positionné, nous avons des clients actifs qui ont déjà réalisé de nombreuses visites 
                    et qui sont matures pour s’engager vite ! À la présentation de votre bien ils sont capables de se décider rapidement.
                </p>

                <Margin m={7} />

                <p>
                    N’oubliez pas que la 1re proposition est souvent la bonne... C’est généralement celle que l’on regrette ultérieurement. 
                </p>
            </Box>

            <Margin m={8} />

            <Box title="2. La vente dans les 3 mois.">
                <p>
                    Le bien est proche du prix du marché, les clients vont s’y intéresser, le visiter, le revisiter, le comparer encore à d’autres avant de se 
                    décider et s’il y a une négociation, la vente se réalisera dans un délai variant entre 1 et 3 mois.
                </p>
            </Box>

            <Margin m={8} />

            <Box title="3. La vente au-delà de 3 mois et? ...">
                <p>
                    Nous avons débuté un peu fort, nous sommes en décalage avec la réalité du marché immobilier et les acquéreurs sont plutôt distants ! 
                    Le bien sert de lièvre ou de confortation de vente pour les biens concurrents et assez similaires. Dans le temps il connaît des baisses 
                    légères à répétition et il se met hors marché. Par conséquent vous prenez le risque de le vendre sous le marché à terme !
                </p>
            </Box>

            <Margin m={35} />

            <p style={{ lineHeight: "18px"}}>
                Pour corroborer ce tableau, voici quelques réflexions qui vous permettront de choisir une stratégie de
                commercialisation.
            </p>

            <Margin m={8} />

            <p style={{
                fontSize: 16,
                textDecoration: "underline",
                fontWeight: 500
            }}>
                ATTENTION A LA POLITIQUE DU PRIX FORT « COUP DE CŒUR »
            </p>

            <Margin m={6} />

            <p style={{
                fontSize: 11.15,
                lineHeight: "14px"
            }}>
                <i>
                    En préambule : Voici un article paru dans Le Figaro : « L’immobilier français serait le plus surévalué au monde » « Les tarifs français sont surcotés de 
                    50% pour les loyers et de 35% pour les prix.
                    <br/>
                    Il y a quelques mois encore, la France était le deuxième pays européen le plus surcoté (derrière la Belgique), elle est aujourd’hui le numéro un en 
                    Europe pour la surévaluation des prix par rapport aux loyers pratiqués (seuls le Canada, Hongkong et Singapour sont devant) et le numéro un mondial pour la 
                    surévaluation des prix par rapport au revenu des ménages (devant le Canada, les Pays-Bas et l’Australie). »
                </i>
            </p>
        </Wrapper>
    )
}

export default Page07