import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMandatBlobs, selectMandatBlobs } from "../photosSlice"

const useMandatBlobs = () => {
    const dispatch = useDispatch()
    const blobs = useSelector(selectMandatBlobs)

    useEffect(() => {
        dispatch(getMandatBlobs())
    }, [dispatch])

    return blobs
}

export default useMandatBlobs