import { Fragment } from "react"
import InputSelect from "../../../components/InputSelect"
import InputCheckbox from "../../../components/InputCheckbox"
import { scssVar } from "../../../../../app/scssVar"
import { selectTracfinEditor, tracfinActions } from "../tracfinSlice"
import { useDispatch, useSelector } from "react-redux"
import useSaveTracfin from "../hooks/useSaveTracfin"

const questions = [
    { id: "tf_prix_trop_eleve", label: "Le prix de l'acquisition vous paraît-il trop élevé par rapport aux ressources déclarées par votre client ?" },
    { id: "tf_acquereur_beneficiaire", label: "L’acquéreur est-il le bénéficiaire final de l'opération ?" },
    { id: "tf_rencontre_beneficiaire", label: "Avez-vous personnellement rencontré le bénéficiaire final de l'opération ? (l’acquéreur)" },
    { id: "tf_acquisition_sans_visite", label: "Vous a-t-il indiqué être prêt à acheter le bien sans l'avoir visité ?" },
    { id: "tf_clause_substitution", label: "L’acquéreur a-t-il exigé, pour des raisons curieuses, l'insertion d'une clause de substitution spécifique ?" },
    { id: "tf_pays_risque", label: "L’acquéreur réside-t-il dans un pays dit « à risque » ?" },
    { id: "tf_reticence_justificatifs", label: "L’acquéreur a-t-il été réticent à vous communiquer les justificatifs demandés ?" },
    { id: "tf_justificatifs_douteux", label: "Les justificatifs communiqués vous semblent-ils douteux ou atypiques ?" }, 
    { id: "tf_acquereur_chgmt_nom", label: "L’acquéreur a-t-il changé de nom au cours de l'opération ?" },
    { id: "tf_acquereur_politique", label: "L’acquéreur est-il un homme politique, un diplomate, un juge d'une Cour suprême ou un conjoint d’une personne politiquement exposée ?" },
    { id: "tf_prix_marche", label: "Le prix de la transaction est-il conforme aux tendances du marché ?" },
    { id: "tf_operation_logique", label: "L'opération envisagée vous semble-t-elle logique ?" },
    { id: "tf_financement_complexe", label: "Les modalités de financement de l'opération vous semblent-elles anormalement compliquées ?" },
    { id: "tf_financement_jeu", label: "L’acquéreur vous a-t-il expliqué qu'il finance l'opération grâce à des gains provenant du jeu ?" },
    { id: "tf_adresse_suspecte", label: "L'adresse communiquée par le client vous parait-elle suspecte ?" },
    { id: "tf_origine_fonds", label: "L’acquéreur vous a-t-il indiqué l’origine de ses fonds" },
    { id: "tf_pieces_identite", label: "Les vendeurs et les acquéreurs vous ont-ils communiqué leurs pièces d’identité (CNI)" },
]

const Q = ({ id, q, background }) => {
    const dispatch = useDispatch()
    const { clearTimeout, saveTracfin } = useSaveTracfin()
    const editor = useSelector(selectTracfinEditor)

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(tracfinActions.setField({ name, value }))
        saveTracfin(0)
    }

    return (
        <Fragment>
            <div style={{ background, borderRight: "1px solid", borderBottom: "1px solid", borderLeft: "1px solid", padding: "7px 9px" }}>
                <p style={{ fontSize: 13, lineHeight: "15px" }}>{q}</p>
            </div>
            <div style={{ background, borderBottom: "1px solid", borderRight: "1px solid", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <InputSelect 
                    value={editor[id] || ""}
                    name={id}
                    onChange={handleChange}
                    style={{ border: "1px solid" }}
                >
                    <option value="" />
                    <option value="oui">OUI</option>
                    <option value="non">NON</option>
                    <option value="sans_objet">SANS OBJET</option>
                </InputSelect>
            </div>
        </Fragment>
    )
}

const Eval = () => {
    const dispatch = useDispatch()
    const { clearTimeout, saveTracfin } = useSaveTracfin()
    const editor = useSelector(selectTracfinEditor)

    const handleChange = (e) => {
        const { name, checked } = e.target
        clearTimeout()
        dispatch(tracfinActions.setField({ name, value: checked }))
        saveTracfin(0)
    }

    return (
        <div>
            <div style={{
                borderTop: "1px solid",
                display: "grid",
                gridTemplateColumns: "75% 25%",
                gridGap: "0px",
            }}>
                {questions.map((q, i) => 
                    <Q 
                        key={i} 
                        id={q.id}
                        q={q.label} 
                        background={i % 2 ? "none" : scssVar.greyLighter} 
                    />
                )}
            </div>
            <div style={{ 
                borderLeft: "1px solid", 
                borderBottom: "1px solid", 
                borderRight: "1px solid", 
                display: "flex", 
                alignItems: "center", 
                padding: "7px 9px",
                fontSize: 14, 
                lineHeight: "17px"
            }}>
                <p style={{ marginRight: 20, fontSize: 13, lineHeight: "15px" }}>Informations scannées :</p>
                <InputCheckbox 
                    checked={editor.tf_info_google || false} 
                    name="tf_info_google"
                    onChange={handleChange}
                />
                <p style={{ marginLeft: 3, marginRight: 20, fontSize: 13, lineHeight: "15px" }}>Infos Google</p>
                <InputCheckbox 
                    checked={editor.tf_gel_avoirs || false} 
                    name="tf_gel_avoirs"
                    onChange={handleChange}
                />
                <p style={{ marginLeft: 3, marginRight: 20, fontSize: 13, lineHeight: "15px" }}>Gel des avoirs</p>
                <InputCheckbox 
                    checked={editor.tf_reel_beneficiaire || false} 
                    name="tf_reel_beneficiaire"
                    onChange={handleChange}
                />
                <p style={{ marginLeft: 3, fontSize: 13, lineHeight: "15px" }}>Réel bénéficiaire</p>
            </div>
        </div>
    )
}

export default Eval