import { Box } from "@mui/material"
import firstScreenImage from "../../common/assets/first_screen.png"
import Logo from "../../common/components/Logo"

const FirstScreen = ({ onClick }) => {
    return (
        <Box onClick={onClick} sx={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100vh",
            width: "100%",
            backgroundColor: "white",
            cursor: "pointer",
            position: "fixed",
        }}>
            <Logo sx={{
                wrapper: {
                    width: "50%",
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center",
                },
                image: {
                    width: "80%",
                    maxWidth: "250px",
                }
            }} />
            <Box sx={{
                width: "50%",
                height: "100%", 
                backgroundImage: `url(${firstScreenImage})`,
                backgroundSize: "cover",
            }} />
        </Box>
    )
}

export default FirstScreen