import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { SketchPicker } from "react-color"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { selectOrg } from "../selectors"

const defaultColors = [
    "rgb(69, 39, 116)",
    "rgb(187, 42, 210)",
    "rgb(243, 0, 77)",
    "rgb(255, 107, 0)",
    "rgb(140, 202, 172)",
    "rgb(0, 117, 255)",
    "rgb(123, 123, 123)",
]

const NotCheckedIcon = () => (
    <Box sx={{
        width: 16,
        height: 16,
        borderRadius: "16px",
        border: 1,
        borderColor: scssVar.grey,
        bgcolor: scssVar.greyLighter,
    }} />
)

const CheckedIcon = () => (
    <Box sx={{
        width: 16,
        height: 16,
        borderRadius: "16px",
        border: 2,
        p: "2px",
        borderColor: scssVar.primary,
    }}>
        <Box sx={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            bgcolor: scssVar.primary
        }} />
    </Box>
)

const ColorPicker = ({ value, onChange }) => {
    const currentColor = useSelector(selectOrg).color
    const [colors, setColors] = useState(defaultColors)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!defaultColors.includes(currentColor)) {
            setColors(c => {
                if (c.length === 7) {
                    return [...c, currentColor]
                } else {
                    const copy = [...c]
                    copy[7] = currentColor
                    return copy
                }
            })
        }
    }, [currentColor])

    const handleChange = (newValue) => (event) => {
        onChange(newValue)
    }

    return (
        <Fragment>
            <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                flexWrap: "wrap",
                mb: 2.3, 
                "@media (max-width: 760px)": {
                    mb: 1.5
                }
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: 14,
                    color: "black",
                    flexGrow: 1,
                    mr: "5px"
                }}>
                    Personnalisez la couleur de vos documents
                </Typography>
                <Button
                    variant="text" 
                    type="button"
                    onClick={() => setOpen(true)}
                    sx={{ 
                        fontSize: 14,
                        textDecoration: "underline",
                    }}
                >
                    Plus de couleurs
                    <i
                        className="iconoir-palette"
                        style={{ fontSize: 22, marginLeft: 3 }}
                    ></i>
                </Button>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogContent sx={{ p: 0 }}>
                        <SketchPicker
                            color={value}
                            onChangeComplete={(color) => {
                                const rgbString = "rgb(" + color.rgb.r + ", " + color.rgb.g + ", " + color.rgb.b + ")"
                                onChange(rgbString)

                                if (!colors.includes(rgbString)) {
                                    setColors(c => {
                                        if (c.length === 7) {
                                            return [...c, rgbString]
                                        } else {
                                            const copy = [...c]
                                            copy[7] = rgbString
                                            return copy
                                        }
                                    })
                                }
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
            <Grid container spacing={2}>
                {colors.map((c, i) =>
                    <Grid
                        item
                        xs={6}
                        sm={12 / colors.length}
                        key={i}
                        onClick={handleChange(c)}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    >
                        <Box sx={{ 
                            width: "100%", 
                            height: 27, 
                            bgcolor: c,
                            mb: 1
                        }} />
                        {value === c ? (
                            <CheckedIcon />
                        ):(
                            <NotCheckedIcon />
                        )}
                    </Grid>
                )}
                {/* {!colors.includes(currentColor) && (
                    <Grid
                        item
                        xs={6}
                        sm={12 / colors.length}
                        key={i}
                        onClick={handleChange(c)}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    >
                        <Box sx={{ 
                            width: "100%", 
                            height: 27, 
                            bgcolor: c,
                            mb: 1
                        }} />
                        {value === c ? (
                            <CheckedIcon />
                        ):(
                            <NotCheckedIcon />
                        )}
                    </Grid>
                )} */}
            </Grid>
        </Fragment>
    )
}

export default ColorPicker