import { styled } from '@mui/material/styles';
import { Tooltip as MuiTooltip, tooltipClasses } from "@mui/material";
import { scssVar } from '../../app/scssVar';

const HtmlTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: scssVar.primary,
        color: "white",
        border: "1px solid white",
        padding: "2.5px 5px",
        maxWidth: "300px",
        fontSize: "13px",
        lineHeight: "16px",
        fontWeight: 400,
        fontFamily: scssVar.fontFamily,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: scssVar.primary,
    }
}));

const TooltipStyled = ({ 
    title, 
    arrow, 
    placement,
    enterTouchDelay,
    leaveTouchDelay,
    disableTouchListener,
    children,
    sx
}) => {
    return (
        <HtmlTooltip 
            arrow={arrow}
            enterTouchDelay={enterTouchDelay}
            leaveTouchDelay={leaveTouchDelay}
            disableTouchListener={disableTouchListener}
            placement={placement}
            title={title}
            sx={sx}
        >
            {children}
        </HtmlTooltip>
    )
}

export default TooltipStyled