import Margin from "../../components/Margin"
import Wrapper from "../../components/Wrapper"
import ConditionsParticulieres from "../components/ConditionsParticulieres"
import Diag from "../components/Diag"
import Duration from "../components/Duration"
import Footer from "../../components/Footer"
import HousingInfos from "../components/HousingInfos"
import MandantsList from "../components/MandantsList"
import Panneau from "../components/Panneau"
import Price from "../components/Price"
import Remuneration from "../components/Remuneration"
import SectionTitle from "../components/SectionTitle"
import Signature from "../components/Signature"
import HeaderPage01 from "../components/HeaderPage01"

const Page01 = ({ scale }) => {
    return (
        <Wrapper id="mandat_p1" scale={scale}>
            <HeaderPage01 />

            <Margin m={7} />

            <MandantsList />

            <Margin m={3} />

            <SectionTitle>
                1. DÉSIGNATION DU BIEN, OBJET DES PRESENTES
            </SectionTitle>

            <Margin m={3} />

            <HousingInfos />

            <Margin m={3} />

            <SectionTitle>
                2. PRIX DE VENTE 
                <span style={{ fontWeight: 400, fontSize: 11 }}>
                    {" "}- Les honoraires se fondent sur le prix net vendeur si les honoraires sont à la charge de l'acquéreur et le prix final de vente autrement
                </span>
            </SectionTitle>

            <Margin m={3} />

            <Price />

            <Margin m={3} />

            <SectionTitle>
                3. RÉMUNÉRATION DU MANDATAIRE TTC
                <span style={{ fontWeight: 400, fontSize: 10 }}>
                    {" "}(se fonde sur le prix
                </span>
                <span style={{ fontWeight: 600, fontSize: 10 }}>
                    {" "}net vendeur sur les honoraires sont à la charge de l'acquéreur et sur le prix de vente sinon)
                </span>
            </SectionTitle>

            <Margin m={3} />

            <Remuneration />

            <Margin m={3} />

            <SectionTitle>
                4. DURÉE DU MANDAT
            </SectionTitle>

            <Margin m={3} />

            <Duration />

            <Margin m={3} />

            <SectionTitle>
                5. OBLIGATIONS DU MANDANT
            </SectionTitle>

            <Margin m={3} />

            <Diag />

            <Margin m={3} />

            <SectionTitle>
                6. CLAUSES PARTICULIÈRES
            </SectionTitle>

            <Margin m={3} />

            <Panneau />

            <Margin m={3} />
            
            <ConditionsParticulieres />

            <Margin m={3} />

            <Signature />

            <Footer />
        </Wrapper>
    )
}

export default Page01