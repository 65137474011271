import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"

const Title = ({ children }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <div style={{
            backgroundColor: userColor,
            padding: "8px 26px 8px",
            width: "fit-content"
        }}>
            <p style={{
                fontFamily: scssVar.fontFamily,
                color: "white",
                fontSize: 29,
                fontWeight: 700,
                textAlign: "center",
                textTransform: "uppercase"
            }}>
                {children}
            </p>
        </div>
    )
}

export default Title