import InputDate from "../../../components/InputDate"
import InputSelect from "../../../components/InputSelect"
import InputText from "../../../components/InputText"
import Margin from "../../../components/Margin"
import SectionTitle from "./SectionTitle"
import { useDispatch, useSelector } from "react-redux"
import { buyOfferActions, selectBuyOfferEditor } from "../buyOfferSlice"

const Buyer = ({ id }) => {
    const dispatch = useDispatch()
    const buyer = useSelector(selectBuyOfferEditor)[id]

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(buyOfferActions.setBuyer({ name, value, id }))
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InputSelect
                    value={buyer.civilite}
                    name="civilite"
                    onChange={handleChange}
                    style={{ width: 90 }}
                >
                    <option value="">Titre</option>
                    <option value="mme">Madame</option>
                    <option value="m">Monsieur</option>
                    <option value="ind">Indifférent</option>
                </InputSelect>
                <InputText 
                    value={buyer.nom_naissance}
                    name="nom_naissance"
                    onChange={handleChange}
                    placeholder="Nom" 
                    style={{
                        width: "calc(50% - 50px)",
                    }}
                />
                <InputText 
                    value={buyer.prenom}
                    name="prenom"
                    onChange={handleChange}
                    placeholder="Prénom" 
                    style={{
                        width: "calc(50% - 50px)",
                    }}
                />
            </div>
            <Margin m={2} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Date de naissance :</p>
                <InputDate 
                    value={buyer.date_naissance}
                    name="date_naissance"
                    onChange={handleChange}
                    style={{ width: 150 }}
                />
                <p style={{ marginRight: 3, marginLeft: 3 }}>À :</p>
                <InputText 
                    value={buyer.lieu_naissance}
                    name="lieu_naissance"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
                <p style={{ marginRight: 3, marginLeft: 3 }}>Nationalité :</p>
                <InputText 
                    value={buyer.nationalite}
                    name="nationalite"
                    onChange={handleChange}
                    style={{ width: 50 }}
                />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Profession :</p>
                <InputText 
                    value={buyer.profession}
                    name="profession"
                    onChange={handleChange}
                    style={{
                        flexGrow: 1
                    }}
                />
            </div>
        </div>
    )
}

const Buyers = () => {
    const dispatch = useDispatch()
    const buyState = useSelector(selectBuyOfferEditor)
    
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(buyOfferActions.setField({ name, value }))
    }

    return (
        <div>
            <SectionTitle>
                SIGNE(S) : LE(S) PROPOSANT(S) 
            </SectionTitle>

            <Margin m={10} />

            <Buyer id="acheteur1" />

            <Margin m={2} />

            <Buyer id="acheteur2" />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Domicilié(s) :</p>
                <InputText 
                    value={buyState.adresse_domicile}
                    name="adresse_domicile"
                    onChange={handleChange}
                    placeholder="Rue" 
                    style={{ flexGrow: 1, marginRight: 5 }}
                />
                <InputText 
                    value={buyState.cp_domicile}
                    name="cp_domicile"
                    onChange={handleChange}
                    placeholder="CP" 
                    style={{ width: 75, marginRight: 5 }}
                />
                <InputText 
                    value={buyState.ville_domicile}
                    name="ville_domicile"
                    onChange={handleChange}
                    placeholder="Ville" 
                    style={{ flexGrow: 1 }}
                />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3 }}>Ou Société :</p>
                <InputText 
                    value={buyState.societe}
                    name="societe"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
            </div>
        </div>
    )
}

export default Buyers