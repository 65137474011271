import { Box } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import Logo from "../../../common/components/Logo"
import PageWrapper from "../../../common/components/PageWrapper"

const BackButton = () => {
    const navigate = useNavigate()
    return (
        <Button
            variant="primary-light"
            onClick={() => navigate("/login")}
            sx={{
                position: "absolute",
                top: 35,
                right: 0,
                bgcolor: "#F9F9FB",
                color: scssVar.primary,
                boxShadow: "0px 2.35px 9.4px rgba(0, 39, 51, 0.2)",
                WebkitBoxShadow: "0px 2.35px 9.4px rgba(0, 39, 51, 0.2)"
            }}
        >
            Se connecter
        </Button>
    )
}

const WrapperRegisterForm = ({ children }) => {
    return (
        <PageWrapper>
            <Box sx={{ position: "relative", pt: 5 }}>
                <Link to="/login" style={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                }}>
                    <Logo sx={{ wrapper: { mb: 4 } }} />
                </Link>
                <BackButton />
                {children}
            </Box>
        </PageWrapper>
    )
}

export default WrapperRegisterForm