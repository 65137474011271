import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    from: window.location.pathname,
    to: window.location.pathname
}

export const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        setHistory: (state, action) => {
            state.from = state.to
            state.to = action.payload
        }
    },
    // rien en cas de logout
})

export const { setHistory } = historySlice.actions

export default historySlice.reducer