import { Box, Grid, IconButton, Typography } from "@mui/material"
import { Fragment, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import FieldControl from "../../../../common/components/FieldControl"
import { numberWithSpaces } from "../../../../common/utils"
import { updateEstimation } from "../../actions"
import { selectCosts, selectValues } from "../../selectors"
import CostInfo from "./CostInfo"
import StepTitle from "./StepTitle"

const initialState = {
    showInfos: false
}

const StepTotalCostForm = ({ stepName }) => {
    const dispatch = useDispatch()
    const working = useSelector(state => state.estimate.working)
    const estimation = useSelector(state => state.estimate.estimation)
    const relativeOrAbsolute = useSelector(state => state.estimate.relativeOrAbsolute)
    const [state, setState] = useState(initialState)
    const { prixNetVendeur } = useSelector(selectValues)
    const { prixPresentation, coutGlobal } = useSelector(selectCosts)
    const timeout = useRef(null)

    const isRel = relativeOrAbsolute === "relative"

    const onUpdateEstimation = (delay) => {
        timeout.current = setTimeout(() => {
            dispatch(updateEstimation(stepName))
        }, [delay])
    }

    const toggleInfos = () => {
        setState({ ...state, showInfos: !state.showInfos })
    }

    const handleChangeRelativeOrAbsolute = (name, value) => {
        dispatch({ type: "estimate/set_relative_or_absolute", payload: value })
    }

    const handleChangeAcquereurVendeur = (name, value) => {
        clearTimeout(timeout.current)

        dispatch({
            type: "estimate/set_estimation_costs_acquereur_vendeur",
            payload: { value }
        })

        onUpdateEstimation(0)
    }

    const handleChange = (e) => {
        const target = e.target
        const name = target.name
        let value = target.value

        if (isRel) {
            if (value > 100) {
                value = 100
            } else if (value === null || value === "" || value < 0) {
                value = 0
            } else {
                value = target.value
            }
        } else {
            value = target.value
        }

        clearTimeout(timeout.current)

        dispatch({
            type: "estimate/set_estimation_costs_percent_or_absolute",
            payload: { name, value }
        })

        onUpdateEstimation(1000)
    }

    return (
        <Fragment>
            <StepTitle 
                title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                        Budget total
                        <IconButton onClick={toggleInfos}>
                            <i className="iconoir-info-empty" style={{ fontSize: 20, color: scssVar.black }}></i>
                        </IconButton>
                    </span>
                }
                subTitle="Ajustez les frais de transaction"
                noProgressBarDisplay
            />

            <div style={{ margin: 20 }} />

            <CostInfo show={state.showInfos} onClose={toggleInfos} />

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                        <FieldControl
                            label=""
                            type="checkbox"
                            disallowDeselect
                            choices={[
                                { label: "%", value: "relative" },
                                { label: "€", value: "absolute" },
                            ]}
                            selectedValue={relativeOrAbsolute}
                            onChange={handleChangeRelativeOrAbsolute}
                            xs={6}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Vente à charge"
                        type="checkbox"
                        disallowDeselect
                        choices={[
                            { label: "Vendeur", value: "vendeur" },
                            { label: "Acquéreur", value: "acquereur" },
                        ]}
                        selectedValue={estimation.a_charge_vendeur_ou_acquereur}
                        name="a_charge_vendeur_ou_acquereur"
                        onChange={handleChangeAcquereurVendeur}
                        disabled={working}
                        xs={6}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FieldControl
                        label="Frais d'agence"
                        isSuccess={isRel ? estimation.frais_agence_pourcent : estimation.frais_agence_absolu} 
                        onChange={handleChange}
                        value={isRel ? (estimation.frais_agence_pourcent || "") : (estimation.frais_agence_absolu || "")}
                        type="number" 
                        placeholder="Montant"
                        name={isRel ? "frais_agence_pourcent" : "frais_agence_absolu"}
                        icon={{ position: "right", icon: isRel ? "fas fa-percent" : "fas fa-euro-sign" }}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FieldControl
                        label="Frais de notaire"
                        isSuccess={isRel ? estimation.frais_notaire_pourcent : estimation.frais_notaire_absolu} 
                        onChange={handleChange}
                        value={isRel ? (estimation.frais_notaire_pourcent || "") : (estimation.frais_notaire_absolu || "")}
                        type="number" 
                        placeholder="Montant"
                        name={isRel ? "frais_notaire_pourcent" : "frais_notaire_absolu"}
                        icon={{ position: "right", icon: isRel ? "fas fa-percent" : "fas fa-euro-sign" }}
                        disabled={working}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{
                        bgcolor: "#F9F9FB",
                        borderRadius: "16px",
                        height: "110px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: { xs: "center", md: "flex-start" },
                        p: {
                            xs: "0",
                            md: "15px 24px 0"
                        },
                    }}>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDarker,
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            Prix Net vendeur
                        </Typography>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontSize: "28px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            {numberWithSpaces(prixNetVendeur)}€
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{
                        bgcolor: "#F9F9FB",
                        borderRadius: "16px",
                        height: "110px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: { xs: "center", md: "flex-start" },
                        p: {
                            xs: "0",
                            md: "15px 24px 0"
                        },
                    }}>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDarker,
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            Prix de présentation suggéré
                        </Typography>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontSize: "28px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            {numberWithSpaces(prixPresentation)}€
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Box sx={{
                        bgcolor: "#F9F9FB",
                        borderRadius: "16px",
                        height: "110px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: { xs: "center", md: "flex-start" },
                        p: {
                            xs: "0",
                            md: "15px 24px 0"
                        },
                    }}>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDarker,
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            Budget total acheteur
                        </Typography>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontSize: "28px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            {numberWithSpaces(coutGlobal)}€
                        </Typography>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            mt: "-3px",
                            textAlign: { xs: "center", md: "start" }
                        }}>
                            Avec frais de notaire
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default StepTotalCostForm