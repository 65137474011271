const ResultSubtitle = ({ subtitle, smiley, text, percent, color }) => (
    <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        marginBottom: 25,
        marginTop: 15
    }}>
        <p 
            style={{ 
                textAlign: "center",
                color: "black",
                fontSize: 14,
            }}
        >
            {subtitle}
        </p>
        <p 
            style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 5,
                color,
                fontSize: 14,
            }}
        >
            {smiley}
            <span style={{ marginLeft: 5 }}>
                {text} <b>{percent}</b>
            </span>
        </p>
    </div>
)

export default ResultSubtitle