import { Box, Typography } from "@mui/material";
import { scssVar } from "../../../app/scssVar";
import Button from "../../../common/components/Button";
import { maxScale, minScale } from "../settings";

const Zoom = ({
    onZoomOut,
    onZoomIn,
    scale,
    loadingDoc,
    loadingPage,
    sx
}) => {
    return (
        <Box sx={Object.assign({}, {
            position: "absolute",
            right: 0,
            left: 0,
            bottom: `calc(70px + ${scssVar.sab})`,
            zIndex: 1301,
            width: "fit-content",
            margin: "0 auto"
        }, sx?.wrapper)}>
            <Box sx={{
                p: 1,
                borderRadius: "35px",
                display: "flex",
                alignItems: "center",
                bgcolor: scssVar.greyLight
            }}>
                <Button
                    variant="primary"
                    onClick={onZoomOut}
                    disabled={loadingDoc || loadingPage || scale < minScale}
                    sx={{
                        width: 45,
                        height: 45,
                        borderRadius: "45px",
                        p: 0,
                    }}
                >
                    <i
                        className="iconoir-minus"
                        style={{
                            fontSize: 24
                        }}
                    ></i>
                </Button>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDarker,
                    width: "50px",
                    textAlign: "center"
                }}>
                    {(scale * 100).toFixed(0)}%
                </Typography>
                <Button
                    variant="primary"
                    onClick={onZoomIn}
                    disabled={loadingDoc || loadingPage || scale > maxScale}
                    sx={{
                        width: 45,
                        height: 45,
                        borderRadius: "45px",
                        p: 0
                    }}
                >
                    <i
                        className="iconoir-plus"
                        style={{
                            fontSize: 24
                        }}
                    ></i>
                </Button>
            </Box>
        </Box>
    )
}

export default Zoom;