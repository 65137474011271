import { Fragment } from "react"
import { useSelector } from "react-redux"
import GreyBox from "../../components/GreyBox"

const RSAC = () => {
    const value = useSelector(state => state.auth.user.rsac)
    return (
        <Fragment>
            <p style={{
                fontWeight: 600,
                marginRight: 3,
                fontSize: 14,
                whiteSpace: "nowrap"
            }}>
                E.I. RSAC N°
            </p>
            <GreyBox style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 160,
                whiteSpace: "nowrap"
            }}>
                {value || "ND"}
            </GreyBox>
        </Fragment>
    )
}

export default RSAC