import { useSelector } from "react-redux"
import { selectMandatSaving } from "../mandat/mandatSlice"
import { selectTracfinSaving } from "../buy/tracfin/tracfinSlice"
import { selectOrg } from "../../account/selectors"

const InputCheckbox = ({
    name,
    checked,
    onChange,
    required,
    disabled
}) => {
    const savingMandat = useSelector(selectMandatSaving)
    const savingTracin = useSelector(selectTracfinSaving)
    const userColor = useSelector(selectOrg).color

    if (disabled) {
        return (
            <div style={{
                width: 17,   
                minWidth: 17,
                maxWidth: 17,             
                height: 17,
                minHeight: 17,
                maxHeight: 17,
                border: "1px solid",
                borderRadius: 3,
                background: checked ? userColor : "white"
            }} />
        )
    }

    return (
        <input
            required={required}
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={savingMandat || savingTracin}
            style={{
                width: 17,   
                minWidth: 17,
                maxWidth: 17,             
                height: 17,
                minHeight: 17,
                maxHeight: 17,
            }}
        />
    )
}

export default InputCheckbox