import { useSelector } from "react-redux"
import { selectApiLeads, selectApiLeadsMissingAssignments } from "../../apiOfferSlice"
import { InfoRounded } from "@mui/icons-material"
import { scssVar } from "../../../../app/scssVar"
import { selectTeams } from "../../../account/selectors"

const TabLeadsLabel = ({ label }) => {
    const missingAssignments = useSelector(selectApiLeadsMissingAssignments)
    const leads = useSelector(selectApiLeads)
    const teams = useSelector(selectTeams)

    if (
        missingAssignments > 0 
        && !leads.isLoading 
        && !leads.error 
        && !teams.isLoading 
        && !teams.error
    ) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {label}
                <InfoRounded sx={{ color: scssVar.danger, ml: .5 }} />
            </span>
        )
    }

    return label
}

export default TabLeadsLabel