import { Box, CircularProgress } from "@mui/material"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import { selectEstimation } from "../../estimate/selectors"
import { getMandat, selectMandat, selectMandatEditor } from "./mandatSlice"
import Page01 from "./pages/Page01"
import Page02 from "./pages/Page02"
import Page03 from "./pages/Page03"
import Page04 from "./pages/Page04"
import LoadingMask from "../components/LoadingMask"
import Avenant from "./pages/Avenant"
import TopBar from "./components/TopBar"

const MandatPdf = () => {
    const dispatch = useDispatch()
    const estimationId = useSelector(selectEstimation).id
    const { status } = useSelector(selectMandat)
    const mandat = useSelector(selectMandatEditor)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getMandat(estimationId))
    }, [dispatch, estimationId])

    const scale = loading 
        ? 1.0 
        : 1.0
        // : 0.665 // --> Lecteur PDF Google : 77%

    const handleDownloadMandat = () => {
        const pdf = new jsPDF("p", "mm", "a4", true)

        const generatePage = async (html, addPage) => {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true // Pour la map leaflet
            })
                .then(canvas => {
                    const img = canvas.toDataURL("img/png", 1.0)
                    const imgWidth = 210
                    const imgHeight = canvas.height * imgWidth / canvas.width + 3
        
                    pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST")
        
                    if (addPage) {
                        pdf.addPage()
                    }
                })
        }

        setLoading(true)

        setTimeout(async () => {
            const page1 = document.getElementById("mandat_p1")
            const page2 = document.getElementById("mandat_p2")
            const page3 = document.getElementById("mandat_p3")
            const page4 = document.getElementById("mandat_p4")

            await generatePage(page1, true)
            await generatePage(page2, true)
            await generatePage(page3, true)
            await generatePage(page4, mandat.amendments.length > 0)

            for (let i = 0; i < mandat.amendments.length; i++) {
                const pageAvenant = document.getElementById(`avenant_mandat_${i}`)
                await generatePage(pageAvenant, i < mandat.amendments.length - 1)
            }

            setLoading(false)
    
            return pdf.save("mandat")
        }, [1500])
    }

    if (status === "idle") {
        return null
    }

    if (status === "pending") {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: scssVar.primary }}>Chargement...</p>
                <CircularProgress size={20} sx={{ color: scssVar.primary, ml: 1 }} />
            </div>
        )
    }

    if (status === "rejected") {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer.</p>
    }

    return (
        <Fragment>
            <LoadingMask loading={loading} />

            <Box sx={{ bgcolor: scssVar.greyLighter, borderRadius: "8px", pb: 6 }}>
                <TopBar loading={loading} onDownload={handleDownloadMandat} />

                <Box sx={{ m: 8 }} />

                <Box sx={{ width: "fit-content",  margin: "0 auto", position: "relative" }}>
                    <Page01 scale={scale} />
                    <Box sx={{ m: 2 }} />
                    <Page02 scale={scale} />
                    <Box sx={{ m: 2 }} />
                    <Page03 scale={scale} />
                    <Box sx={{ m: 2 }} />
                    <Page04 scale={scale} isExporting={loading} />

                    {mandat.amendments?.length > 0 && (
                        <Fragment>
                            <Box sx={{ m: 2 }} />
                            {mandat.amendments.map((d, i) => 
                                <Fragment key={i}>
                                    <p id={`avenant-anchor-${i}`}>Avenant {i + 1}</p>
                                    <Avenant 
                                        id={d.id} 
                                        scale={scale} 
                                        docId={`avenant_mandat_${i}`} 
                                        disabled={(i + 1) < mandat.amendments.length} 
                                    />
                                    <Box sx={{ m: 2 }} />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Box>
            </Box>
        </Fragment>
    )
}

export default MandatPdf