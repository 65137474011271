import { useDispatch, useSelector } from "react-redux"
import useAuth from "../../../auth/hooks/useAuth"
import Margin from "../../components/Margin"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import { getGenderTextFormat } from "../settings"
import GreyBox from "../../components/GreyBox"
import SectionTitle from "./SectionTitle"
import InputText from "../../components/InputText"
import InputDate from "../../components/InputDate"
import { Fragment } from "react"
import useSaveMandat from "../hooks/useSaveMandat"

export const SignatureMandant = () => {
    const exclusivity = useSelector(selectMandatEditor2).mandat_type_exclusivite
    const mandants = useSelector(selectMandatEditor2).mandants
    const firstMandant = mandants ? mandants[0] : {}
    const secondMandant = mandants ? mandants[1] : {}

    return (
        <Fragment>
            <SectionTitle>
                LE MANDANT
            </SectionTitle>

            <div style={{ width: "100%", borderBottom: "1px solid black" }} />

            <div style={{
                borderRight: "1px solid black",
                paddingRight: 10,
                paddingTop: 3,
                paddingBottom: 65
            }}>
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px",
                }}>
                    Le mandant fera précéder sa signature de la mention : 
                </p>
                <Margin m={6} />
                <p style={{
                    fontSize: 12.25,
                    lineHeight: "12.25px",
                }}>
                    "lu et approuvé, mandat confié et reçu ce jour <i>(avec ou sans exclusivité à préciser)</i>"
                </p>
                <Margin m={6} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "49.5%" }}>
                        <GreyBox style={{ width: "50%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                            {getGenderTextFormat(firstMandant?.civilite)}
                        </GreyBox>
                        <Margin m={3} />
                        <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                            {firstMandant?.nom_naissance}
                        </GreyBox>
                        <Margin m={3} />
                        <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                            {firstMandant?.prenom}
                        </GreyBox>
                    </div>
                    <div style={{ width: "49.5%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <GreyBox style={{ width: "49%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {getGenderTextFormat(secondMandant?.civilite)}
                            </GreyBox>
                            <GreyBox style={{ width: "49%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                                {exclusivity === "simple" && "Sans exclusivité"}
                                {exclusivity === "exclu" && "Avec exclusivité"}
                                {exclusivity === "semi" && "Semi exclusivité"}
                            </GreyBox>
                        </div>
                        <Margin m={3} />
                        <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                            {secondMandant?.nom_naissance}
                        </GreyBox>
                        <Margin m={3} />
                        <GreyBox style={{ width: "100%", display: "flex", alignItems: "center", padding: "0 3px" }}>
                            {secondMandant?.prenom}
                        </GreyBox>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export const SignatureMandataire = () => {
    const { firstName, lastName } = useAuth().user

    return (
        <Fragment>
            <SectionTitle style={{ marginLeft: 10 }}>
                LE MANDATAIRE
            </SectionTitle>

            <div style={{ width: "100%", borderBottom: "1px solid black" }} />

            <Margin m={3} />

            <div style={{
                paddingLeft: 10
            }}>
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px"
                }}>
                    Le mandataire fera précéder sa signature de la mention :
                </p>
                <Margin m={3} />
                <p style={{
                    fontSize: 13,
                    lineHeight: "13px"
                }}>
                    "lu et approuvé, bon pour mandat accepté" 
                </p>
                <Margin m={21} />
                <p style={{
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px"
                }}>
                    <i>Pour l'agence représentée par son mandataire</i>
                </p>
                <Margin m={6} />
                <GreyBox style={{ 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center", 
                    padding: "0 3px" 
                }}>
                    {lastName} {firstName}
                </GreyBox>
            </div>
        </Fragment>
    )
}

const Signature = () => {
    const dispatch = useDispatch()
    const signatureLocation = useSelector(selectMandatEditor2).signature_location
    const signatureDate = useSelector(selectMandatEditor2).signature_date
    const { clearTimeout, saveMandat } = useSaveMandat()
  
    const handleChange = (e) => {
        const { value, name } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(1000)
    }

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "60%" }}>
                <div style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        fontWeight: 600,
                        marginRight: 3,
                        whiteSpace: "nowrap"
                    }}>
                        MANDAT ÉTABLI EN DEUX EXEMPLAIRES, À :
                    </p>
                    <InputText
                        name="signature_location"
                        value={signatureLocation || ""}
                        onChange={handleChange}
                        style={{
                            flexGrow: 1
                        }}
                    />
                </div>
                <SignatureMandant />
            </div>
            <div style={{ width: "40%" }}>
                <div style={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        fontWeight: 600,
                        marginRight: 3,
                        whiteSpace: "nowrap"
                    }}>
                        DATE DE SIGNATURE DU MANDAT :
                    </p>
                    <InputDate
                        value={signatureDate || ""}
                        onChange={handleChange}
                        name="signature_date"
                    />
                </div>
                <SignatureMandataire />
            </div>
        </div>
    )
}

export default Signature