import { Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import { ReactComponent as EuroWhiteIcon } from "../../../common/assets/euro.svg" 
import { ReactComponent as HomeWhiteIcon } from "../../../common/assets/home_white.svg" 
import Button from "../../../common/components/Button"

const Bar = ({ variant }) => {
    if (variant === "vertical") {
        return (
            <div style={{
                width: 6,
                height: "100%",
                position: "absolute",
                top: 0,
                left: 22,
                background: `linear-gradient(
                    to bottom,
                    rgba(68, 42, 113, 1),
                    rgba(68, 43, 113, 1),
                    rgba(73, 83, 109, 1),
                    rgba(77, 117, 105, 1),
                    rgba(80, 143, 102, 1),
                    rgba(83, 161, 100, 1),
                    rgba(84, 172, 99, 1),
                    rgba(84, 176, 99, 1)
                    `
            }}></div>
        )
    }

    return (
        <div style={{
            width: "100%",
            height: 6,
            position: "absolute",
            top: 23,
            background: `linear-gradient(
                to right,
                rgba(68, 42, 113, 1),
                rgba(68, 43, 113, 1),
                rgba(73, 83, 109, 1),
                rgba(77, 117, 105, 1),
                rgba(80, 143, 102, 1),
                rgba(83, 161, 100, 1),
                rgba(84, 172, 99, 1),
                rgba(84, 176, 99, 1)
                `
        }}></div>
    )
}

const Icon = ({ sx, variant }) => {
    return (
        <Box sx={Object.assign({}, {
            width: "37px",
            height: "37px",
            minWidth: "37px",
            minHeight: "37px",
            bgcolor: variant === "euro" ? scssVar.green : scssVar.primary,
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }, sx)}>
            {variant === "euro" ? (
                <EuroWhiteIcon />
            ):(
                <HomeWhiteIcon />
            )}
        </Box>
    )
}

const Number = ({ variant }) => (
    <Box sx={{ 
        width: "51px",
        height: "51px",
        minWidth: "51px",
        minHeight: "51px",
        border: "2px solid white",
        backgroundColor: variant === 2 ? "#B3E5BA" : scssVar.green,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }}>
        <span style={{
            fontSize: 30,
            fontWeight: 700,
            color: "white"
        }}>
            {variant === 2 ? "2" : "1"}
        </span>
    </Box>
)

const Block = ({ variant }) => {
    const navigate = useNavigate()

    const Wrapper = ({ children }) => (
        <div style={{
            backgroundColor: "#F9F9FB",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: 307,
            height: 207,
        }}>
            {children}
        </div>
    )

    const Btn = ({
        onClick,
        disabled,
        children
    }) => {
        const props = {
            variant: "success",
            noShadow: true,
            disabled: disabled,
            onClick: onClick,
            sx: {
                fontWeight: 500,
                width: 208,
                height: 45,
                opacity: 1
            }
        }

        if (variant === 2) {
            const secondBtnProps = {
                ...props,
                sx: Object.assign({}, props.sx, {
                    bgcolor: "#B3E5BA",
                })
            }

            return (
                <Button {...secondBtnProps}>
                    {children}
                </Button>
            )
        }

        return (
            <Button {...props}>
                {children}
            </Button>
        )
    }

    const Text = ({ children }) => (
        <p style={{
            marginTop: 20,
            color: "black",
            textAlign: "center",
            fontSize: 14,
            opacity: variant === 2 ? .6 : 1
        }}>
            {children}
        </p>
    )

    if (variant === 2) {
        return (
            <Wrapper>
                <Btn disabled>
                    Affiner l’estimation
                </Btn>
                <Text>
                    en tenant compte <b>DU BUDGET D’USAGE</b>
                    <br/>
                    du bien : consommations d’énergie
                    <br/>et d’eau, taxes foncières,
                    <br/>charges de copropriété…
                </Text>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Btn onClick={() => navigate("estimer")}>
                Estimer un bien
            </Btn>
            <Text>
                en prenant en compte toutes
                <br/><b>SES CARACTÉRISTIQUES,</b>
                <br/>ses équipements et son
                <br/>environnement.
            </Text>
        </Wrapper>
    )
}

const Intro = () => {
    return (
        <Fragment>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Box sx={{ width: "100%" }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        mb: "40px", 
                        fontWeight: 700, 
                        fontSize: "43px",
                        lineHeight: "60px",
                        textAlign: "center"
                    }}>
                        <span style={{ color: scssVar.primary }}>Réalisez une</span>
                        <span style={{ color: scssVar.green }}> évaluation précise</span>
                        <br />
                        <span style={{ color: scssVar.primary }}>pour un mandat au juste prix</span>
                    </Typography>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                    }}>
                        <Bar />
                        <Icon sx={{
                            position: "absolute",
                            top: 7,
                            left: 0,
                        }} />
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            mr: "60px",
                            zIndex: 1
                        }}>
                            <Number />
                            <Block />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            zIndex: 1
                        }}>
                            <Number variant={2} />
                            <Block variant={2} />
                        </Box>
                        <Icon 
                            variant="euro" 
                            sx={{
                                position: "absolute",
                                top: 7,
                                right: 0,
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Box sx={{ 
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    "@media (max-width: 700px)": {
                        flexDirection: "column"
                    }
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        fontWeight: 700, 
                        fontSize: "30px",
                        textAlign: "center",
                        "@media (max-width: 700px)": {
                            mb: 2
                        }
                    }}>
                        <span style={{ color: scssVar.primary }}>Réalisez une</span>
                        <br />
                        <span style={{ color: scssVar.green }}> évaluation précise</span>
                        <br />
                        <span style={{ color: scssVar.primary }}>pour un mandat au juste prix</span>
                    </Typography>
                    <Box sx={{ 
                        position: "relative", 
                        height: "575px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "fit-content",
                        maxWidth: "100%",
                        margin: "0 auto",
                    }}>
                        <Bar variant="vertical" />
                        <Icon sx={{
                            position: "absolute",
                            top: 0,
                            left: 7
                        }} />
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                            flexDirection: "column"
                        }}>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 2,
                                zIndex: 1
                            }}>
                                <Number />
                                <Block />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                zIndex: 1
                            }}>
                                <Number variant={2} />
                                <Block variant={2} />
                            </Box>
                        </Box>
                        <Icon 
                            variant="euro"
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                left: 7
                            }} 
                        />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}

export default Intro