import { Box } from "@mui/material"
import BigCheckbox from "../../../../common/components/BigCheckbox"

const HousingType = ({
    onClickHouse,
    isHouse,
    isEstimationInProgress,
    working,
    onClickFlat,
    isFlat
}) => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
        }}>
            <Box sx={{ width: "48%" }}>
                <BigCheckbox
                    onClick={onClickHouse}
                    checked={isHouse}
                    text="Maison"
                    icon="iconoir-home-alt-slim"
                    disabled={isEstimationInProgress || working}
                />
            </Box>
            <Box sx={{ width: "48%" }}>
                <BigCheckbox
                    onClick={onClickFlat}
                    checked={isFlat}
                    text="Appartement"
                    icon="iconoir-building"
                    disabled={isEstimationInProgress || working}
                />
            </Box>
        </Box>
    )
}

export default HousingType