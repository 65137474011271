import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateEstimation } from "../../actions"
import bohemien from "../../../../common/assets/decoration/Bohémien.png"
import scandinave from "../../../../common/assets/decoration/Scandinave.png"
import moderne from "../../../../common/assets/decoration/Moderne.png" 
import rustique from "../../../../common/assets/decoration/Rustique.png"
import industriel from "../../../../common/assets/decoration/Industriel.png"
import art_deco from "../../../../common/assets/decoration/Art_déco.png"
import vintage from "../../../../common/assets/decoration/Vintage.png" 
import minimaliste from "../../../../common/assets/decoration/Minimaliste.png"
import baroque from "../../../../common/assets/decoration/Baroque.png" 
import traditionnel from "../../../../common/assets/decoration/Traditionnel.png"
import { Grid } from "@mui/material"
import StepTitle from "./StepTitle"
import FieldControl from "../../../../common/components/FieldControl"

export const choices_luminosite = [
    { label: "Sombre", value: "Sombre" },
    { label: "Neutre", value: "Neutre" },
    { label: "Lumineux", value: "Lumineux" },
    { label: "Très lumineux", value: "Très lumineux" },
]

const choices_hauteur_sous_plafond = [
    {
        label: "Normale de 2,20 à 2,50 m",
        value: "Normale de 2,20 à 2,50 m",
    },
    {
        label: "Confortable de 2,5 à 2,8 m",
        value: "Confortable de 2,5 à 2,8 m",
    },
    { 
        label: "Prestigieuse + de 2,8 m", 
        value: "Prestigieuse + de 2,8 m" 
    },
]
  
const choices_decoration = [
    {
        label: "Bohémien",
        value: "Bohémien",
        img: bohemien
    },
    {
        label: "Scandinave",
        value: "Scandinave",
        img: scandinave
    },
    { 
        label: "Moderne", 
        value: "Moderne", 
        img: moderne
    },
    {
        label: "Rustique",
        value: "Rustique",
        img: rustique
    },
    {
        label: "Industriel",
        value: "Industriel",
        img: industriel
    },
    {
        label: "Art déco",
        value: "Art déco",
        img: art_deco
    },
    { 
        label: "Vintage", 
        value: "Vintage", 
        img: vintage
    },
    {
        label: "Minimaliste",
        value: "Minimaliste",
        img: minimaliste
    },
    { 
        label: "Baroque", 
        value: "Baroque", 
        img: baroque
    },
    {
        label: "Traditionnel",
        value: "Traditionnel",
        img: traditionnel
    },
]

// const choices_etat_interieur = [
//     { label: "À rafraichir", value: "À rafraichir" },
//     { label: "État d’usage", value: "État d’usage" },
//     { label: "Excellent état", value: "Excellent état" }
// ]

const Step07Form = ({ stepName, noProgressBarDisplay }) => {
    const dispatch = useDispatch()
    const estimation = useSelector(state => state.estimate.estimation)
    const working = useSelector(state => state.estimate.working)

    const handleChangeButtonCheckbox = (name, value) => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        dispatch(updateEstimation(stepName))
    }

    return (
        <Fragment>
            <StepTitle title="Intérieur et décoration" step={stepName} noProgressBarDisplay={noProgressBarDisplay} />
            <div style={{ marginBottom: 50 }} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FieldControl
                        label="Luminosité"
                        isSuccess={estimation.luminosite !== null}
                        type="checkbox"
                        choices={choices_luminosite}
                        selectedValue={estimation.luminosite}
                        name="luminosite"
                        onChange={handleChangeButtonCheckbox}
                        xs={12}
                        md={(12/4)*2} 
                        lg={12/4}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Hauteur sous plafond"
                        isSuccess={estimation.hauteur_sous_plafond !== null}
                        type="checkbox"
                        choices={choices_hauteur_sous_plafond}
                        selectedValue={estimation.hauteur_sous_plafond}
                        name="hauteur_sous_plafond"
                        onChange={handleChangeButtonCheckbox}
                        xs={12}
                        lg={12/3}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="De quel style de décoration êtes vous le plus proche ?"
                        isSuccess={estimation.decoration !== null}
                        type="checkbox-image"
                        choices={choices_decoration}
                        selectedValue={estimation.decoration}
                        name="decoration"
                        onChange={handleChangeButtonCheckbox}
                        xs={12}
                        sm={12}
                        md={4}
                        lg={3}
                        disabled={working}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <FieldControl
                        label="État intérieur"
                        isSuccess={estimation.etat_interieur !== null}
                        type="checkbox"
                        choices={choices_etat_interieur}
                        selectedValue={estimation.etat_interieur}
                        name="etat_interieur"
                        onChange={handleChangeButtonCheckbox}
                        xs={12}
                        md={4}
                        disabled={working}
                    />
                </Grid> */}
            </Grid>
        </Fragment>
    )
}

export default Step07Form