import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import { styled } from '@mui/system';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

const LinkButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 14px;
    background: none;
    padding: 7px 20px;
    border-radius: 8px;
    transition: all 150ms ease;
    border: none;
    white-space: nowrap;

    &:hover {
        color: ${scssVar.primary};
    }

    &.${buttonUnstyledClasses.active} {}

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const LinkTopBar = ({ link, onClick, sx }) => {
    const navigate = useNavigate()

    const handleClick = () => {
        if (onClick) onClick()
        else {
            if (link.isActive) return
            navigate(link.to)
        }
    }

    return (
        <LinkButton
            onClick={handleClick}
            sx={Object.assign({}, {
                cursor: link.isActive ? "initial" : "pointer",
                bgcolor: link.isActive ? scssVar.primaryLight : "white",
                color: link.isActive ? scssVar.primary : scssVar.greyDark,
                mr: { xs: 0, lg: "10px" },
            }, sx)}
        >
            {link.label}
        </LinkButton>
    )
}

export default LinkTopBar