import { Box, Typography } from "@mui/material"
import StepsProgress from "./StepsProgress"
import { Fragment } from "react"
import { scssVar } from "../../../../app/scssVar"

const StepTitle = ({ 
    title, 
    subTitle, 
    step, 
    isCostDisplay,
    noProgressBarDisplay
}) => {
    return (
        <Box>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                fontSize: { xs: "30px", md: "36px"},
                fontWeight: 700,
                lineHeight: { xs: "40px", md: "48px"},
                color: "black"
            }}>
                {title}
            </Typography>
            {subTitle && (
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: "16px",
                    fontWeight: 400,
                    //lineHeight: "48px",
                    color: scssVar.greyDark
                }}>
                    {subTitle}
                </Typography>
            )}
            {!noProgressBarDisplay && (
                <Fragment>
                    <div style={{ marginTop: 15 }} />
                    <StepsProgress step={step} isCostDisplay={isCostDisplay} />
                </Fragment>
            )}
        </Box>
    )
}

export default StepTitle