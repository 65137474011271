import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import FieldControl from "../../../common/components/FieldControl"
import { addTeam } from "../actions"

const initialState = {
    open: false,
    name: ""
}

const AddTeam = () => {
    const dispatch = useDispatch()
    const maxTeams = useSelector(state => state.account.teams.items.max_teams)
    const teamsCount = useSelector(state => state.account.teams.items.data)?.length
    const loading = useSelector(state => state.account.teams.addLoading)
    const additionalPrice = useSelector(state => state.account.teams.items.new_team_price)
    const proraPrice = useSelector(state => state.account.teams.items.new_team_prorated_price)
    const [state, setState] = useState(initialState)

    const empty = !state.name.trim().length

    const handleOpen = () => {
        setState({ ...state, open: true, name: "" })
    }

    const handleClose = () => {
        setState(initialState)
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = () => {
        dispatch(addTeam(state.name))
        .then(() => {
            setState(initialState)
        })
    }

    if (teamsCount === maxTeams) {
        return null
    }

    return (
        <Fragment>
            <Box sx={{
                mt: 3,
                display: "flex",
                justifyContent: "center"
            }}>
                <Button onClick={handleOpen}>
                    <i
                        className="iconoir-plus"
                        style={{
                            fontSize: 24,
                            marginRight: 5
                        }}
                    ></i>
                    Ajouter une agence
                </Button>
            </Box>
            <Dialog 
                open={state.open} 
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Nouvelle agence
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{
                        fontFamily: scssVar.fontFamily,
                        mb: 3
                    }}>
                        L'ajout de cet agence engendrera un coût supplémentaire de {String(additionalPrice.toFixed(2))}€ par mois.
                        Le montant proratisé de {String(proraPrice.toFixed(2))}€, pour le mois en cours, sera reporté sur votre prochaine facture.
                    </DialogContentText>
                    <FieldControl
                        label="Nom de l'agence"
                        placeholder="Nom de l'agence"
                        isSuccess={state.name}
                        value={state.name}
                        name="name"
                        onChange={handleChange}
                        type="text"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={empty || loading}
                        sx={{
                            width: 150
                        }}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AddTeam