import { Fragment, useEffect, useState } from "react"
import Page01 from "./pages/Page01"
import { useDispatch, useSelector } from "react-redux"
import { selectMandatEditor2 } from "../../mandat/mandatSlice"
import { selectEstimation } from "../../../estimate/selectors"
import { Box, CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import MissingFieldAlertText from "../../../../common/components/MissingFieldAlertText"
import LoadingMask from "../../components/LoadingMask"
import DownloadIconButton from "../../components/DownloadIconButton"
import Button from "../../../../common/components/Button"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { getTracfin, selectTracfinDocs, selectTracfinSaving } from "./tracfinSlice"

const TracfinPdf = () => {
    const dispatch = useDispatch()
    const estimationId = useSelector(selectEstimation).id
    const { isLoading, error } = useSelector(selectTracfinDocs)
    const numberMandat = useSelector(selectMandatEditor2).mandat_id
    const saving = useSelector(selectTracfinSaving)
    const [loading, setLoading] = useState(false)
    const [openMissingValues, setOpenMissingValues] = useState(false)

    const emptyNumberMandat = numberMandat === "" || numberMandat === null || numberMandat === undefined

    useEffect(() => {
        dispatch(getTracfin(estimationId))
    }, [dispatch, estimationId])

    const handleDownload = () => {
        if (emptyNumberMandat) {
            setOpenMissingValues(true)
            return
        }

        const pdf = new jsPDF("p", "mm", "a4", true)

        const generatePage = async (html, addPage) => {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 

            // https://github.com/niklasvh/html2canvas/issues/2775#issuecomment-1316356991
            // const style = document.createElement('style');
            // document.head.appendChild(style);
            // // style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');
            // style.sheet?.insertRule('body > img { display: inline-block; }');

            // const a = document.getElementById("html2canvas-container")
            // if (a) console.log(a)

            // document.body.style.lineHeight = '0.5';
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true, // Pour la map leaflet
            })
                .then(canvas => {
                    // console.log(style.sheet)
                    // style.remove()

                    const img = canvas.toDataURL("img/png", 1.0)
                    const imgWidth = 210
                    const imgHeight = canvas.height * imgWidth / canvas.width + 3
        
                    pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST")
        
                    if (addPage) {
                        pdf.addPage()
                    }
                })
        }

        setLoading(true)

        setTimeout(async () => {
            const page = document.getElementById("tracfin_p1")
    
            await generatePage(page, false)

            setLoading(false)
    
            return pdf.save("eval_risques_tracfin")
        }, [1500])
    }

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: scssVar.primary }}>Chargement...</p>
                <CircularProgress size={20} sx={{ color: scssVar.primary, ml: 1 }} />
            </Box>
        )
    }

    if (error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer.</p>
    }

    return (
        <Fragment>
            <LoadingMask loading={loading} />

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <DownloadIconButton disabled={loading} onClick={handleDownload} />
                <p>Évaluation des risques - Tracfin</p>
            </Box>
                
            <Box sx={{ width: "fit-content", margin: "0 auto" }}>
                <Box sx={{ height: 30 }}>
                    {saving && (
                        <p style={{ color: scssVar.greyDark }}>
                            <i>Enregistrement des changements...</i>
                        </p>
                    )}
                </Box>

                <Page01 scale={1} />
            </Box>

            <Dialog open={openMissingValues} onClose={() => setOpenMissingValues(false)} fullWidth>
                <DialogContent sx={{ pb: 0 }}>
                    <MissingFieldAlertText visible={emptyNumberMandat}>
                        Veuillez renseigner le <u>numéro de mandat</u> dans l'onglet "Mandat"
                    </MissingFieldAlertText>
                </DialogContent>
                <DialogActions>
                    <Button variant="light" onClick={() => setOpenMissingValues(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default TracfinPdf