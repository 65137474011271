import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import Margin from "../../components/Margin"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import InputCheckbox from "../../components/InputCheckbox"
import GreyBox from "../../components/GreyBox"
import InputDate from "../../components/InputDate"
import InputText from "../../components/InputText"
import InputSelect from "../../components/InputSelect"
import { getGenderTextFormat } from "../settings"
import { getDateToString } from "../../../../common/utils"
import useJssInput from "../../hooks/useJssInput"
import useSaveMandat from "../hooks/useSaveMandat"

const Mandant = ({ mandant, readOnly, and }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const jss = useJssInput()
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setMandantInfos({ id: mandant.id_ordre, name, value }))
        saveMandat(1000)
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between"
        }}>
            <div style={{ width: "49.5%" }}>
                <div style={{ 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center" 
                }}>
                    {and && (
                        <p style={{ 
                            fontWeight: 600,
                            marginRight: 3,
                            fontSize: 13,
                            lineHeight: "13px"
                        }}>
                            et :
                        </p>
                    )}
                    {readOnly ? (
                        <GreyBox style={{
                            display: "flex",
                            alignItems: "center",
                            width: 75,
                            marginRight: 3,
                            padding: "0 2px",
                        }}>
                            {getGenderTextFormat(mandant?.civilite)}
                        </GreyBox>
                    ):(
                        <InputSelect
                            required={!and}
                            value={mandant?.civilite || ""}
                            onChange={handleChange}
                            name="civilite"
                            style={{
                                width: 75,
                                marginRight: 3
                            }}
                        >
                            <option value="">Titre</option>
                            <option value="mme">Madame</option>
                            <option value="m">Monsieur</option>
                            <option value="ind">Indifférent</option>
                        </InputSelect>
                    )}
                    <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                        {readOnly ? (
                            <GreyBox style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                padding: "0 2px",
                            }}>
                                {mandant?.nom_naissance}
                            </GreyBox>
                        ):(
                            <InputText
                                required={!and}
                                value={mandant?.nom_naissance || ""}
                                onChange={handleChange}
                                name="nom_naissance"
                                placeholder="Nom"
                                style={{
                                    width: "100%"
                                }}
                            />
                        )}
                    </div>
                </div>
                <Margin m={2} />
                <div style={{ 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center"
                }}>
                    <p style={{ 
                        fontWeight: 600,
                        marginRight: 3,
                        fontSize: 13,
                        lineHeight: "13px",
                    }}>
                        Né(e) le :
                    </p>
                    {readOnly ? (
                        <GreyBox style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0 2px",
                            width: jss.width
                        }}>
                            {mandant?.date_naissance ? getDateToString(mandant.date_naissance, "numeric") : ""}
                        </GreyBox>
                    ):(
                        <InputDate
                            required={!and}
                            value={mandant?.date_naissance || ""}
                            onChange={handleChange}
                            name="date_naissance"
                        />
                    )}
                </div>
            </div>
            <div style={{ width: "49.5%" }}>
                <div style={{ 
                    display: "flex", 
                    width: "100%", 
                    alignItems: "center" 
                }}>
                    <p style={{ 
                        fontWeight: 600,
                        marginRight: 3,
                        fontSize: 13,
                        lineHeight: "13px",
                    }}>
                        Prénom :
                    </p>
                    <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                        {readOnly ? (
                            <GreyBox style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0 2px",
                                width: "100%"
                            }}>
                                {mandant?.prenom}
                            </GreyBox>
                        ):(
                            <InputText
                                required={!and}
                                value={mandant?.prenom || ""}
                                onChange={handleChange}
                                name="prenom"
                                style={{
                                    width: "100%"
                                }}
                            />
                        )}
                    </div>
                </div>
                <Margin m={2} />
                <div style={{ 
                    display: "flex", 
                    width: "100%", 
                    alignItems: "center" 
                }}>
                    <p style={{ 
                        fontWeight: 600,
                        marginRight: 3,
                        fontSize: 13,
                        lineHeight: "13px",
                    }}>
                        à :
                    </p>
                    <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                        {readOnly ? (
                            <GreyBox style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0 2px",
                                width: "100%",
                                marginRight: 3,
                            }}>
                                {mandant?.lieu_naissance}
                            </GreyBox>
                        ):(
                            <InputText
                                required={!and}
                                value={mandant?.lieu_naissance || ""}
                                onChange={handleChange}
                                name="lieu_naissance"
                                style={{
                                    marginRight: 3,
                                    width: "100%"
                                }}
                            />
                        )}
                    </div>
                    <p style={{ 
                        fontWeight: 600,
                        marginLeft: 3,
                        marginRight: 3,
                        fontSize: 13,
                        lineHeight: "13px",
                    }}>
                        Nationalité :
                    </p>
                    {readOnly ? (
                        <GreyBox style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0 2px",
                            width: 50,
                        }}>
                            {mandant?.nationalite}
                        </GreyBox>
                    ):(
                        <InputText
                            required={!and}
                            value={mandant?.nationalite || ""}
                            onChange={handleChange}
                            name="nationalite"
                            placeholder="FR"
                            style={{
                                width: 50
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export const Mandants = ({ readOnly }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const jss = useJssInput()
    const dispatch = useDispatch()
    const type = useSelector(selectMandatEditor2).statut_mandant
    const mandants = useSelector(selectMandatEditor2).mandants
    const firstMandant = mandants ? mandants[0] : {}
    const secondMandant = mandants ? mandants[1] : {}

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setMandantInfos({ id: firstMandant.id_ordre, name, value }))
        saveMandat(1000)
    }

    return (
        <Fragment>
            <p style={{ fontSize: 13, lineHeight: "13px" }}>
                <u style={{ fontWeight: 600 }}>LE MANDANT</u>
                {" "}: Les soussignés <span style={{ fontWeight: 600 }}>VENDEUR(S)</span>
                <span style={{ fontWeight: 600 }}>
                    {type === "sci" && (
                        " - Pour les SCI : préciser le nom du Gérant puis collecter les Statuts, un PV et le Bilan."
                    )}
                    {type === "indivision" && (
                        " - En cas d'indivision, fournir une annexe indivisaires."
                    )}
                </span>
            </p>

            <Margin m={7} />

            <Mandant mandant={firstMandant} readOnly={readOnly} />

            <Margin m={2} />

            <Mandant mandant={secondMandant} readOnly={readOnly} and />

            <Margin m={2} />

            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <p style={{
                    fontWeight: 600,
                    marginRight: 3,
                    fontSize: 13,
                    lineHeight: "13px",
                }}>
                    Demeurant : 
                </p>
                <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                    {readOnly ? (
                        <GreyBox style={{
                            padding: "0 2px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%"
                        }}>
                            {firstMandant?.adresse_domicile}
                        </GreyBox>
                    ):(
                        <InputText
                            required
                            value={firstMandant?.adresse_domicile || ""}
                            placeholder="Rue"
                            onChange={handleChange}
                            name="adresse_domicile"
                            style={{
                                width: "100%",
                            }}
                        />
                    )}
                    
                </div>
                <p style={{
                    fontWeight: 600,
                    marginLeft: 3,
                    marginRight: 3,
                    fontSize: 13,
                    lineHeight: "13px",
                }}>
                    Code postal :
                </p>
                {readOnly ? (
                    <GreyBox style={{
                        padding: "0 2px",
                        display: "flex",
                        alignItems: "center",
                        width: 75,
                    }}>
                        {firstMandant?.cp_domicile}
                    </GreyBox>
                ):(
                    <InputText
                        required
                        value={firstMandant?.cp_domicile || ""}
                        onChange={handleChange}
                        name="cp_domicile"
                        style={{
                            width: 75
                        }}
                    />
                )}
                <p style={{
                    fontWeight: 600,
                    marginLeft: 3,
                    marginRight: 3,
                    fontSize: 13,
                    lineHeight: "13px",
                }}>
                    Ville : 
                </p>
                {readOnly ? (
                    <GreyBox style={{
                        padding: "0 2px",
                        display: "flex",
                        alignItems: "center",
                        width: jss.width
                    }}>
                        {firstMandant?.ville_domicile}
                    </GreyBox>
                ):(
                    <InputText
                        required
                        value={firstMandant?.ville_domicile || ""}
                        onChange={handleChange}
                        name="ville_domicile"
                    />
                )}
            </div>
        </Fragment>
    )
}

export const choicesType = [
    { label: "Célibataire", value: "celib" },
    { label: "Marié(s)", value: "marié" },
    { label: "Concubin(s)", value: "concubin" },
    { label: "Pacsé(s)", value: "pacse" },
    { label: "Divorcé(s)", value: "divorce" },
    { label: "Veuve/veuf", value: "veuve" },
    { label: "Indivision", value: "indivision" },
    { label: "SCI", value: "sci" },
]

const MandantsList = ({ readOnly }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const type = useSelector(selectMandatEditor2).statut_mandant
    const origin = useSelector(selectMandatEditor2).origine_bien
    const isDonorAlive = useSelector(selectMandatEditor2).origine_bien_donation_detail
    // const errorType = useSelector(selectMandatEditor).errorFields.mandantType
    // const errorOrigin = useSelector(selectMandatEditor).errorFields.origin
    // const errorDonorAlive = useSelector(selectMandatEditor).errorFields.donorAlive

    return (
        <div style={{
            border: "1px solid black",
            padding: "5px 10px"
        }}>
            <Mandants readOnly={readOnly} />

            <Margin m={2} />

            <div style={{ 
                display: "flex", 
                justifyContent: "space-between",
                width: "100%",
                //border: errorType ? ("1px solid " + scssVar.danger) : "none"
            }}>
                {choicesType.map((c, i) => 
                    <div key={i} style={{ 
                        display: "flex", 
                        alignItems: "center" 
                    }}>
                        <InputCheckbox
                            checked={c.value === type}
                            name="statut_mandant"
                            disabled={readOnly}
                            onChange={e => {
                                const { name, checked } = e.target
                                clearTimeout()
                                dispatch(mandatActions.setField({ name, value: checked ? c.value : "" }))
                                saveMandat(0)
                            }} 
                        />
                        <p style={{ fontSize: 13, marginLeft: 3, lineHeight: "13px" }}>
                            {c.label}
                        </p>
                    </div>
                )}
            </div>

            <Margin m={2} />

            <div style={{
                display: "flex",
                alignItems: "center",
                //border: errorOrigin ? ("1px solid " + scssVar.danger) : "none"
            }}>
                <p style={{ fontSize: 13, lineHeight: "13px", marginRight: 15 }}>
                    Le bien est issu d’une
                </p>
                <InputCheckbox 
                    checked={origin === "acquisition"}
                    disabled={readOnly}
                    onChange={() => {
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "origine_bien", value: origin === "acquisition" ? "" : "acquisition" }))
                        dispatch(mandatActions.setField({ name: "origine_bien_donation_detail", value: "" }))
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px",
                    marginLeft: 3,
                    marginRight: 15,
                }}>
                    Acquisition
                </p>
                <InputCheckbox 
                    checked={origin === "donation"}
                    disabled={readOnly}
                    onChange={() => {
                        const checked = origin === "donation"
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "origine_bien", value: checked ? "" : "donation" }))
                        if (checked) {
                            dispatch(mandatActions.setField({ name: "origine_bien_donation_detail", value: "" }))
                        }
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px",
                    marginLeft: 3,
                    marginRight: 3
                }}>
                    Donation
                </p>
                <p style={{
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px",
                    marginRight: 3,
                    //color: errorDonorAlive ? scssVar.danger : "black"
                }}>
                    (Si oui, le donateur est-il vivant ? 
                </p>
                <InputCheckbox 
                    checked={isDonorAlive === true}
                    disabled={readOnly}
                    onChange={() => {
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "origine_bien_donation_detail", value: isDonorAlive ? "" : true }))
                        dispatch(mandatActions.setField({ name: "origine_bien", value: "donation" }))
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    marginLeft: 3,
                    marginRight: 3,
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px",
                    //color: errorDonorAlive ? scssVar.danger : "black"
                }}>
                    Oui
                </p>
                <InputCheckbox 
                    checked={isDonorAlive === false}
                    disabled={readOnly}
                    onChange={() => {
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "origine_bien_donation_detail", value: isDonorAlive === false ? "" : false }))
                        dispatch(mandatActions.setField({ name: "origine_bien", value: "donation" }))
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    marginLeft: 3,
                    marginRight: 15,
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px",
                    //color: errorDonorAlive ? scssVar.danger : "black"
                }}>
                    Non)
                </p>
                <InputCheckbox 
                    checked={origin === "succession"}
                    disabled={readOnly}
                    onChange={() => {
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "origine_bien", value: origin === "succession" ? "" : "succession" }))
                        dispatch(mandatActions.setField({ name: "origine_bien_donation_detail", value: "" }))
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    marginLeft: 3,
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: "13px"
                }}>
                    Succession
                </p>
            </div>
        </div>
    )
}

export default MandantsList