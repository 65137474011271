import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const DoughnutLegend = ({ title, data }) => {
    return (
        <Box>
            <p style={{ color: scssVar.greyDark, fontSize: 14, fontWeight: 600, marginBottom: "10px" }}>
                {title}
            </p>
            {data.map((d, i) => 
                <Box key={i} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box sx={{ width: 20, height: 13, bgcolor: d.color, mr: 1, borderRadius: "2px" }} />
                    <p style={{ fontSize: 14, color: scssVar.greyDark, whiteSpace: "nowrap" }}>
                        {d.name} ({d.percent || "0"}%)
                    </p>
                </Box>
            )}
        </Box>
    )
}

export default DoughnutLegend