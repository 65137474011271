import { CircularProgress, ListItemButton, ListItemText } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import { getCustomerPortal } from "../actions"

const CustomerPortal = () => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.account.customerPortal.loading)

    const handleGetCustomerPortal = () => {
        dispatch(getCustomerPortal())
    }

    return (
        <ListItemButton
            onClick={handleGetCustomerPortal}
            disabled={loading}
            sx={{
                borderBottom: 1,
                borderColor: scssVar.greyLight,
            }}
        >
            <ListItemText
                primary="Portail client"
                secondary="Mode de paiement, factures & reçus"
                sx={{
                    "& .MuiTypography-root": {
                        fontFamily: scssVar.fontFamily
                    },
                    "& .MuiListItemText-primary": {
                        color: "black"
                    },
                    "& .MuiListItemText-secondary": {
                        color: scssVar.greyDark
                    }
                }}
            />
            {loading ? (
                <CircularProgress
                    size={24}
                    sx={{
                        color: scssVar.primary
                    }}
                />
            ):(
                <i 
                    className="iconoir-arrow-right"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark
                    }}
                ></i>
            )}
        </ListItemButton>
    )
}

export default CustomerPortal