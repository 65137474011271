import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../../../app/scssVar"
import TooltipStyled from "../../../../../common/components/TooltipStyled"
import { api } from "../../../../../common/setupApi"
import { addAlert } from "../../../../alerts/alertsSlice"
import useFinishedEstimationsOfHousing from "../../../hooks/useFinishedEstimationsOfHousing"
import Button from "../../../../../common/components/Button"

const Delete = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const estimationId = useSelector(state => state.estimate.estimation.id)
    const loadingEstimations = useSelector(state => state.estimate.estimations.loading)
    const working = useSelector(state => state.estimate.working)
    const items = useFinishedEstimationsOfHousing()
    const [state, setState] = useState({
        open: false,
        loading: false
    })

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleDelete = async () => {
        dispatch({ type: "estimate/set_working", payload: true })

        setState({ ...state, loading: true })

        return api
            .post("v1/housing/estimation/delete", { id: estimationId })
            .then(res => {
                console.log(res.data)

                dispatch({ 
                    type: "estimations/delete_estimation",
                    payload: estimationId
                })

                dispatch(addAlert("Cette évaluation a bien été supprimée !", "success"))

                setState({ ...state, loading: false, open: false })

                // S'il supprime la dernière éval
                // faut rediriger vers l'accueil
                if (items.length > 1) {
                    dispatch({
                        type: "estimate/delete_estimation",
                        payload: estimationId
                    })

                    if (items[0].id === estimationId) {
                        dispatch({ 
                            type: "estimate/set_estimation", 
                            payload: items[1] 
                        })
                    } else {
                        dispatch({ 
                            type: "estimate/set_estimation", 
                            payload: items[0] 
                        })
                    }
                } else {
                    dispatch({ type: "estimate/reset" })
                    navigate("/mes-evaluations")
                }

                dispatch({ type: "estimations/deselect_all" })
                dispatch({ type: "estimate/set_working", payload: false })
            })
            .catch(err => {
                console.log(err.response)
                setState({ ...state, loading: false })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
            })
    }

    return (
        <Fragment>
            <TooltipStyled arrow title="Supprimer">
                <span style={{ marginRight: 16 }}>
                    <IconButton
                        onClick={handleOpen}
                        disabled={working || loadingEstimations}
                        sx={{
                            bgcolor: scssVar.greyLighter,
                            color: scssVar.primary,
                            "&:hover": {
                                bgcolor: scssVar.greyLight,
                            }
                        }}
                    >
                        <i className="iconoir-trash"></i>
                    </IconButton>
                </span>
            </TooltipStyled>
            <Dialog 
                open={state.open} 
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                    display: "flex",
                    alignItems: "center",
                }}>
                    Voulez-vous supprimer cette évaluation ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleDelete}
                        disabled={state.loading}
                        loading={state.loading}
                        sx={{ width: 150 }}
                    >
                        Supprimer
                        <i
                            className="iconoir-trash"
                            style={{
                                fontSize: 24,
                                marginLeft: 5,
                            }}
                        ></i>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Delete