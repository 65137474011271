import { Avatar, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { changeTeamUser } from "../actions"
import Surfacturation from "./Surfacturation"

const initialState = {
    openChangeTeam: false,
    openSurfacture: false,
    newUserTeam: {}
}

const ChangeUserTeam = ({ user, team }) => {
    const dispatch = useDispatch()
    const teams = useSelector(state => state.account.teams.items)
    const maxUsers = useSelector(state => state.account.teams.items.max_users_per_team)
    const [state, setState] = useState(initialState)
    const loading = user.changeTeamLoading || false

    useEffect(() => {
        return () => {
            setState(initialState)
        }
    }, [])

    const handleOpenChangeTeam = () => {
        setState({
            ...state,
            openChangeTeam: true,
            newUserTeam: team
        })
    }

    const handleCloseChangeTeam = () => {
        setState({
            ...state,
            openChangeTeam: false,
            newUserTeam: {}
        })
    }

    const handleSelectNewUserTeam = (newTeam) => {
        setState({
            ...state,
            newUserTeam: newTeam
        })
    }

    const tryChangeUserTeam = () => {
        if (state.newUserTeam.users.length >= maxUsers) {
            setState({
                ...state,
                openSurfacture: true
            })

            return
        }

        dispatch(changeTeamUser(
            user, 
            state.newUserTeam.team_id, 
            team.team_id
        ))
    }

    const handleCloseSurfacturation = () => {
        setState({
            ...state,
            openSurfacture: false
        })
    }

    const handleChangeUserTeam = () => {
        dispatch(changeTeamUser(
            user, 
            state.newUserTeam.team_id, 
            team.team_id
        ))
    }

    return (
        <Fragment>
            <ListItemButton 
                onClick={handleOpenChangeTeam}
                sx={{
                    borderBottom: 1,
                    borderColor: scssVar.greyLight,
                }}
            >
                <ListItemText 
                    primary="Changer d'agence"
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black"
                        },
                    }}
                />
                <i 
                    className="iconoir-redo" 
                    style={{ 
                        color: "black", 
                        fontSize: 24, 
                        marginRight: 7 
                    }} 
                />
            </ListItemButton>
            <Dialog 
                open={state.openChangeTeam} 
                onClose={handleCloseChangeTeam} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    {user.name} : changer d'agence
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{
                        fontSize: 14,
                        fontFamily: scssVar.fontFamily,
                    }}>
                        Sélectionnez une nouvelle agence
                    </DialogContentText>
                    <List sx={{ p: 0 }}>
                        {teams?.data?.map((d, i) => 
                            <ListItem
                                key={i}
                                sx={{
                                    borderBottom: 1,
                                    borderColor: scssVar.greyLight,
                                }}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={() => handleSelectNewUserTeam(d)}
                                        checked={state.newUserTeam.team_id === d.team_id}
                                        inputProps={{ 'aria-labelledby': "checkbox-new-team-selected" }}
                                        sx={{
                                            color: scssVar.grey,
                                            '&.Mui-checked': {
                                                color: scssVar.primary
                                            },
                                        }}
                                    />
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{
                                        bgcolor: scssVar.primaryLighter,
                                        color: "black"
                                    }}>
                                        <i className="iconoir-building" style={{ fontSize: 24 }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={d.team_name} 
                                    secondary={`${d.users.length} utilisateur${d.users.length > 1 ? "s" : ""}`} 
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontFamily: scssVar.fontFamily
                                        },
                                        "& .MuiListItemText-primary": {
                                            color: "black",
                                            fontWeight: 700
                                        },
                                    }}
                                />
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleCloseChangeTeam}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        loading={loading}
                        disabled={loading || state.newUserTeam.team_id === team.team_id}
                        onClick={tryChangeUserTeam}
                        sx={{
                            width: 150
                        }}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            <Surfacturation
                open={state.openSurfacture}
                onClose={handleCloseSurfacturation}
                onSubmit={handleChangeUserTeam}
                loading={loading}
            />
        </Fragment>
    )
}

export default ChangeUserTeam