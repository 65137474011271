import { Fragment } from "react"
import GreyBox from "../../../components/GreyBox"
import InputNumber from "../../../components/InputNumber"
import InputSelect from "../../../components/InputSelect"
import InputText from "../../../components/InputText"
import InputTextArea from "../../../components/InputTextArea"
import Margin from "../../../components/Margin"
import { useDispatch, useSelector } from "react-redux"
import { selectFirstMandant, selectMandatEditor2 } from "../../../mandat/mandatSlice"
import Empty from "../../../components/Empty"
import useAuth from "../../../../auth/hooks/useAuth"
import { selectTracfinEditor, tracfinActions } from "../tracfinSlice"
import useSaveTracfin from "../hooks/useSaveTracfin"

const Seller = () => {
    const dispatch = useDispatch()
    const { clearTimeout, saveTracfin } = useSaveTracfin()
    const { user } = useAuth()
    const editor = useSelector(selectTracfinEditor)
    const seller = useSelector(selectFirstMandant)
    const numberMandat = useSelector(selectMandatEditor2).mandat_id

    const handleChange = (e) => {
        const { name, value, type } = e.target
        clearTimeout()
        dispatch(tracfinActions.setField({ name, value }))
        saveTracfin(type === "select-one" ? 0 : 1000)
    }

    return (
        <Fragment>
            <div style={{ display: "flex", alignItems: "center", fontSize: 13 }}>
                <p style={{ whiteSpace: "nowrap", fontWeight: 600, marginRight: 3, lineHeight: "15px" }}>VENDEUR :</p>
                <GreyBox style={{ width: 350, padding: "0 4px", display: "flex", alignItems: "center" }}>
                    {seller}
                </GreyBox>
                <p style={{ whiteSpace: "nowrap", marginLeft: 3, marginRight: 3, lineHeight: "15px" }}>Profession :</p>
                <InputText 
                    value={editor.tf_profession_vendeur || ""}
                    name="tf_profession_vendeur"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }}
                />
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", alignItems: "center", fontSize: 13 }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, lineHeight: "15px" }}>Nature de la transaction :</p>
                <InputSelect 
                    value={editor.tf_nature_transaction || ""}
                    name="tf_nature_transaction"
                    onChange={handleChange}
                    style={{ width: 100 }}
                >
                    <option value="" />
                    <option>Vente</option>
                    <option>Location</option>
                </InputSelect>
                <p style={{ whiteSpace: "nowrap", marginLeft: 3, marginRight: 3, lineHeight: "15px" }}>Numéro de mandat :</p>
                {numberMandat !== null ? (
                    <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center", flexGrow: 1 }}>
                        {numberMandat}
                    </GreyBox>
                ):(
                    <Empty>N° mandat</Empty>
                )}
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", alignItems: "center", fontSize: 13 }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, lineHeight: "15px" }}>Négociateur :</p>
                <GreyBox style={{ padding: "0 4px", display: "flex", alignItems: "center", flexGrow: 1 }}>
                    {user.lastName} {user.firstName}
                </GreyBox>
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", fontSize: 13 }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, lineHeight: "15px" }}>Motivation de vente :</p>
                <InputTextArea 
                    value={editor.tf_motivation_vente || ""}
                    name="tf_motivation_vente"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }} 
                />
            </div>
        </Fragment>
    )
}

const Buyer = () => {
    const dispatch = useDispatch()
    const { clearTimeout, saveTracfin } = useSaveTracfin()
    const editor = useSelector(selectTracfinEditor)

    const handleChange = (e) => {
        const { name, value, type } = e.target
        clearTimeout()
        dispatch(tracfinActions.setField({ name, value }))
        saveTracfin(type === "select-one" ? 0 : 1000)
    }

    return (
        <Fragment>
            <p style={{ fontWeight: 600, fontSize: 13, lineHeight: "15px" }}>Client(s) ACQUÉREUR(S) :</p>

            <Margin m={2} />

            <div style={{ display: "flex" }}>
                <InputSelect 
                    value={editor.tf_civilite_acquereur_1 || ""}
                    name="tf_civilite_acquereur_1"
                    onChange={handleChange}
                    style={{ width: 75, marginRight: 3 }}
                >
                    <option value="">Titre</option>
                    <option value="mme">Madame</option>
                    <option value="m">Monsieur</option>
                    <option value="ind">Indifférent</option>
                </InputSelect>
                <InputText 
                    value={editor.tf_nom_acquereur_1 || ""}
                    name="tf_nom_acquereur_1"
                    onChange={handleChange}
                    placeholder="Nom" 
                    style={{ width: 375, marginRight: 3 }} 
                />
                <InputText 
                    value={editor.tf_prenom_acquereur_1 || ""}
                    name="tf_prenom_acquereur_1"
                    onChange={handleChange}
                    placeholder="Prénom" 
                    style={{ flexGrow: 1 }} 
                />
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, fontSize: 13, lineHeight: "15px" }}>Profession :</p>
                <InputText 
                    value={editor.tf_profession_acquereur_1 || ""}
                    name="tf_profession_acquereur_1"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }} 
                />
            </div>

            <Margin m={2} />

            <div style={{ display: "flex" }}>
                <InputSelect 
                    value={editor.tf_civilite_acquereur_2 || ""}
                    name="tf_civilite_acquereur_2"
                    onChange={handleChange}
                    style={{ width: 75, marginRight: 3 }}
                >
                    <option value="">Titre</option>
                    <option value="mme">Madame</option>
                    <option value="m">Monsieur</option>
                    <option value="ind">Indifférent</option>
                </InputSelect>
                <InputText 
                    value={editor.tf_nom_acquereur_2 || ""}
                    name="tf_nom_acquereur_2"
                    onChange={handleChange}
                    placeholder="Nom" 
                    style={{ width: 375, marginRight: 3 }} 
                />
                <InputText 
                    value={editor.tf_prenom_acquereur_2 || ""}
                    name="tf_prenom_acquereur_2"
                    onChange={handleChange}
                    placeholder="Prénom" 
                    style={{ flexGrow: 1 }} 
                />
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, fontSize: 13, lineHeight: "15px" }}>Profession :</p>
                <InputText 
                    value={editor.tf_profession_acquereur_2 || ""}
                    name="tf_profession_acquereur_2"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }} 
                />
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, fontSize: 13, lineHeight: "15px" }}>Prix d'achat :</p>
                <InputNumber 
                    value={editor.tf_prix_achat || ""}
                    name="tf_prix_achat"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }} 
                />
                <p style={{ marginLeft: 3, marginRight: 20, fontSize: 13, lineHeight: "15px" }}>€</p>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, fontSize: 13, lineHeight: "15px" }}>Apport :</p>
                <InputNumber 
                    value={editor.tf_apport || ""}
                    name="tf_apport"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }} 
                />
                <p style={{ marginLeft: 3, fontSize: 13, lineHeight: "15px" }}>€</p>
            </div>

            <Margin m={2} />

            <div style={{ display: "flex", marginTop: 2 }}>
                <p style={{ whiteSpace: "nowrap", marginRight: 3, fontSize: 13, lineHeight: "15px" }}>Raison de l'acquisition :</p>
                <InputTextArea 
                    value={editor.tf_raison_acquisition || ""}
                    name="tf_raison_acquisition"
                    onChange={handleChange}
                    style={{ flexGrow: 1 }} 
                />
            </div>
        </Fragment>
    )
}

const Identities = () => {
    return (
        <div style={{ border: "1px solid", padding: "5px" }}>
            <Seller />
            <Margin m={4} />
            <Buyer />
        </div>
    )
}

export default Identities