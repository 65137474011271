import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const BottomNav = ({ 
    onGoBack, 
    onGoNext,
    disabledBack,
    disabledNext,
    noDisplayBack,
    form,
    orgColor
}) => {
    return (
        <Box sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            bgcolor: "white",
            boxShadow: scssVar.shadow2,
            width: "100%",
            zIndex: 1301
        }}>
            <Box sx={{ 
                width: "90%",
                // maxWidth: 700,
                margin: "0 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: noDisplayBack ? "flex-end" : "space-between",
                height: 60
            }}>
                {!noDisplayBack && (
                    <Button
                        type="button"
                        variant="light"
                        disabled={disabledBack}
                        onClick={onGoBack}
                        sx={{
                            width: 130,
                            bgcolor: orgColor.light,
                            color: orgColor.main,
                            "&:hover": {
                                bgcolor: orgColor.light,
                                filter: "brightness(80%)"
                            },
                            "&.Mui-active": {
                                bgcolor: orgColor.light,
                                filter: "brightness(70%)"
                            },
                        }}
                    >
                        <i 
                            className="iconoir-arrow-left"
                            style={{
                                fontSize: 20,
                                marginRight: 1,
                            }}
                        ></i>
                        Précédent
                    </Button>
                )}
                <Button
                    type={form ? "submit" : "button"}
                    disabled={disabledNext}
                    onClick={onGoNext}
                    orgColor={orgColor}
                    sx={{
                        width: 130,
                        bgcolor: orgColor.main,
                        "&:hover": {
                            bgcolor: orgColor.main,
                            filter: "brightness(93%)"
                        },
                        "&.Mui-active": {
                            bgcolor: orgColor.main,
                            filter: "brightness(87%)"
                        },
                    }}
                >
                    Suivant
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 20,
                            marginLeft: 1
                        }}
                    ></i>
                </Button>
            </Box>
        </Box>
    )
}

export default BottomNav