import { Typography } from "@mui/material"
import { scssVar } from "../../../app/scssVar"

const ErrorHandler = ({ errors }) => {
    return errors.map((d, i) => 
        <Typography 
            key={i} 
            sx={{ 
                color: scssVar.danger,
                fontFamily: scssVar.fontFamily,
                fontSize: "14px", 
            }}
        >
            {d}
        </Typography>
    )
}

export default ErrorHandler