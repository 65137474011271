import { ListItem, ListItemText, Switch } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import { activateUser } from "../actions"
import Surfacturation from "./Surfacturation"

const initialState = {
    open: false
}

const ActivateUser = ({ user, usersCount, team }) => {
    const dispatch = useDispatch()
    const maxUsers = useSelector(state => state.account.teams.items.max_users_per_team)
    const [state, setState] = useState(initialState)
    const loading = user.activateUserLoading || false

    useEffect(() => {
        return () => {
            setState(initialState)
        }
    }, [])

    const tryActivateUser = () => {
        if (loading) {
            return
        }
        
        if (usersCount >= maxUsers) {
            setState({ ...state, open: true })

            return
        }

        dispatch(activateUser(user.id, team.team_id))
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleActivateUser = () => {
        dispatch(activateUser(user.id, team.team_id))
    }

    return (
        <Fragment>
            <ListItem sx={{
                borderBottom: 1,
                borderColor: scssVar.greyLight,
            }}>
                <ListItemText 
                    primary={loading ? "Activation en cours..." : "Activer"}
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: loading ? scssVar.success : "black"
                        },
                    }}
                />
                <Switch
                    inputProps={{ 'aria-label': 'Activate user' }}
                    sx={{
                        '& .MuiSwitch-switchBase': {
                            "&.Mui-checked": {
                                color: scssVar.success,
                                '& + .MuiSwitch-track': {
                                    backgroundColor: scssVar.success,
                                }
                            }
                        }
                    }}
                    checked={loading}
                    onChange={tryActivateUser}
                />
            </ListItem>
            <Surfacturation
                open={state.open}
                onClose={handleClose}
                onSubmit={handleActivateUser}
                loading={loading}
            />
        </Fragment>
    )
}

export default ActivateUser