import { Grid } from "@mui/material"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import FieldControl from "../../../../common/components/FieldControl"
import { updateEstimation } from "../../actions"
import StepTitle from "./StepTitle"
import useVieDeQuartierItems from "../../hooks/useVieDeQuartierItems"

const choices_orientation = [
    { label: "N", value: "N" },
    { label: "NE", value: "NE" },
    { label: "NO", value: "NO" },
    { label: "E", value: "E" },
    { label: "O", value: "O" },
    { label: "S", value: "S" },
    { label: "SE", value: "SE" },
    { label: "SO", value: "SO" },
]

const choices_environnement_sonore = [
    { label: "Très bruyant", value: "Très bruyant" },
    { label: "Bruyant", value: "Bruyant" },
    { label: "Calme", value: "Calme" },
    { label: "Très calme", value: "Très calme" },
]

const choices_qualite_numerique = [
    { label: "Basse", value: "Basse" },
    { label: "Moyenne", value: "Moyenne" },
    { label: "Haute", value: "Haute" },
]

export const choices_attrait = [
    { label: "Non recherché", value: "Non recherché" },
    { label: "Moyennement recherché", value: "Moyennement recherché" },
    { label: "Recherché", value: "Recherché" },
    { label: "Très recherché", value: "Très recherché" },
]

const Step08Form = ({ stepName, noProgressBarDisplay }) => {
    const dispatch = useDispatch()
    const estimation = useSelector(state => state.estimate.estimation)
    const working = useSelector(state => state.estimate.working)

    const handleChange = (name, value) => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        dispatch(updateEstimation(stepName))
    }

    const handleChangeButtonCheckbox = (name, value) => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })

        dispatch(updateEstimation(stepName))
    }

    const vieDeQuartierItems = useVieDeQuartierItems()

    const installationNumeriqueItems = [
        {
            name: "installation_numerique",
            checked: estimation.installation_numerique === "Sans internet",
            value: "Sans internet",
            text: "Sans internet",
            icon: "iconoir-wifi-off",
            displayed: true
        },
        {
            name: "installation_numerique",
            checked: estimation.installation_numerique === "4G",
            value: "4G",
            text: "4G",
            icon: "iconoir-antenna",
            displayed: true
        },
        {
            name: "installation_numerique",
            checked: estimation.installation_numerique === "ADSL",
            value: "ADSL",
            text: "ADSL",
            icon: "iconoir-wifi",
            displayed: true
        },
        {
            name: "installation_numerique",
            checked: estimation.installation_numerique === "Fibre",
            value: "Fibre",
            text: "Fibre",
            icon: "iconoir-cable-rounded",
            displayed: true
        }
    ]

    return (
        <Fragment>
            <StepTitle title="Environnement et services" step={stepName} noProgressBarDisplay={noProgressBarDisplay} />
            <div style={{ marginBottom: 50 }} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FieldControl
                        label="Vie de quartier"
                        //isSuccess={estimation.installation_numerique !== null}
                        type="checkbox-big"
                        choices={vieDeQuartierItems}
                        onChange={(name, checked) => handleChange(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Orientation (pièce principale)"
                        isSuccess={estimation.orientation !== null}
                        type="checkbox"
                        choices={choices_orientation}
                        selectedValue={estimation.orientation}
                        name="orientation"
                        onChange={handleChangeButtonCheckbox}
                        xs={(12/8)*2} 
                        md={12/8}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Environnement sonore"
                        isSuccess={estimation.environnement_sonore !== null}
                        type="checkbox"
                        choices={choices_environnement_sonore}
                        selectedValue={estimation.environnement_sonore}
                        name="environnement_sonore"
                        onChange={handleChangeButtonCheckbox}
                        xs={6} 
                        md={3}
                        //md={12/8}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Installation numérique"
                        isSuccess={estimation.installation_numerique !== null}
                        type="checkbox-big"
                        choices={installationNumeriqueItems}
                        onChange={(name, checked, value) => handleChange(name, checked ? value : null)}
                        xs={6}
                        md={3} 
                        disabled={working}
                    />
                </Grid>
                {estimation.installation_numerique !== "Sans internet" && (
                    <Grid item xs={12}>
                        <FieldControl
                            label="Qualité numérique"
                            isSuccess={estimation.qualite_numerique !== null}
                            type="checkbox"
                            choices={choices_qualite_numerique}
                            selectedValue={estimation.qualite_numerique}
                            name="qualite_numerique"
                            onChange={handleChangeButtonCheckbox}
                            xs={4}
                            disabled={working}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FieldControl
                        label="Attrait général : bien et quartier"
                        isSuccess={estimation.attrait !== null}
                        type="checkbox"
                        choices={choices_attrait}
                        selectedValue={estimation.attrait}
                        name="attrait"
                        onChange={handleChangeButtonCheckbox}
                        xs={12}
                        md={6}
                        lg={3}
                        disabled={working}
                    />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Step08Form