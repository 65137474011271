const List = ({ children }) => {
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "5px 40px",
            gridAutoRows: "minmax(min-content, max-content)",
            alignItems: "start"
        }}>
            {children}
        </div>
    )
}

export default List