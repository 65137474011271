import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import Button from "../../../../../common/components/Button"
import FieldControl from "../../../../../common/components/FieldControl"
import { api } from "../../../../../common/setupApi"
import { addAlert } from "../../../../alerts/alertsSlice"
import { updateHousing } from "../../../actions"

const initialState = {
    firstName: "",
    lastName: "",
    open: false
}

const Seller = () => {
    const dispatch = useDispatch()
    const housing = useSelector(state =>state.estimate.housing)
    const working = useSelector(state => state.estimate.working)
    const firstName = housing.prenom_vendeur
    const lastName = housing.nom_vendeur
    const [state, setState] = useState(initialState)
    const emptySeller = !state.firstName.trim().length || !state.lastName.trim().length
    const notModified = state.firstName === firstName && state.lastName === lastName

    useEffect(() => {
        if (state.open) {
            setState(s => ({ 
                ...s, 
                firstName: firstName || "",
                lastName: lastName || ""
            }))
        }
    }, [state.open, firstName, lastName])

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    } 

    const handleSubmit = async () => {
        dispatch({ 
            type: "estimate/set_working", 
            payload: true 
        })

        const payload = {
            ...housing,
            nom_vendeur: state.lastName,
            prenom_vendeur: state.firstName
        }

        return api
            .post("v1/housing/update", payload)
            .then(res => {
                dispatch(updateHousing(res.data))

                dispatch({ 
                    type: "estimate/set_working", 
                    payload: false 
                })

                setState({ ...state, open: false })

                dispatch(addAlert("Le vendeur a bien été modifié", "success"))
            })
            .catch(err => {
                console.log(err.response)

                dispatch({ 
                    type: "estimate/set_working", 
                    payload: false 
                })

                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
            })
    }

    return (
        <Fragment>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: "black",
                fontSize: 14,
                mb: "0.5em"
            }}>
                Nom du vendeur
            </Typography>
            <Box sx={{ 
                display: "flex", 
                alignItems: "center",
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1.5,
                    borderRadius: "10px",
                    border: 1,
                    borderColor: scssVar.grey,
                    minWidth: 220,
                    width: "fit-content",
                    maxWidth: "calc(100% - 45px)",
                    minHeight: "2.5em"
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {firstName} {lastName}
                    </Typography>
                </Box>
                <IconButton
                    onClick={handleOpen}
                    disabled={working}
                    sx={{
                        ml: "5px",
                    }}
                >
                    <i 
                        className="iconoir-edit-pencil edit-icon"
                        style={{
                            fontSize: 24,
                            color: "black"
                        }}
                    ></i>
                </IconButton>
            </Box>
            <Dialog
                open={state.open}
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Modifier le vendeur
                </DialogTitle>
                <DialogContent>
                    <FieldControl
                        type="text"
                        name="firstName"
                        isSuccess={state.firstName}
                        label="Prénom"
                        placeholder="Prénom"
                        value={state.firstName || ""}
                        onChange={handleChange}
                        maxLength={20}
                    />
                    <Box sx={{ m: 2 }} />
                    <FieldControl
                        type="text"
                        name="lastName"
                        isSuccess={state.lastName}
                        label="Nom"
                        placeholder="Nom"
                        value={state.lastName || ""}
                        onChange={handleChange}
                        maxLength={20}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        loading={working}
                        disabled={working || emptySeller || notModified}
                        sx={{
                            width: 150
                        }}
                    >
                        Enregistrer
                        <i
                            className="iconoir-save-floppy-disk"
                            style={{
                                fontSize: 24,
                                marginLeft: 5,
                            }}
                        ></i>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Seller