import { m2, numberWithSpaces } from "../../../../common/utils"
import useSurfacesEtPiecesItems from "../../../estimate/hooks/useSurfacesEtPiecesItems"
import List from "../components/List"
import ListItem from "../components/ListItem"
import Margin from "../../components/Margin"
import SectionTitle from "../components/SectionTitle"
import Wrapper from "../../components/Wrapper"
import { Grid } from "@mui/material"
import useGalleryBlobs from "../../../photoUploader/hooks/useGalleryBlobs"
import GalleryLayout from "../../../photoUploader/components/GalleryLayout"
import { shapleyDescription, shapleyTitle } from "../../../estimate/shapley/Shapley"
import ShapleyChartDesktop from "../../../estimate/shapley/components/ShapleyChartDesktop"
import { Fragment } from "react"

const Photos = () => {
    const pics = useGalleryBlobs()

    if (!pics.length) {
        return (
            <Fragment>
                <SectionTitle>Surface et pièces</SectionTitle>
                <Margin m={15} />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <SectionTitle>Photos, surface et pièces</SectionTitle>
            <Margin m={15} />
            <Grid container spacing={.5}>
                <GalleryLayout pics={pics} readOnly docType="synthesis" />
            </Grid>
            <Margin m={5} />
        </Fragment>
    )
}

const SurfaceEtPieces = () => {
    const surfacesEtPiecesItems = useSurfacesEtPiecesItems()

    return (
        <List>
            {surfacesEtPiecesItems
                .filter(d => d.displayed)
                .map((d, i) => 
                    <ListItem 
                        key={i} 
                        index={i} 
                    >
                        {d.label}
                        {d.value !== null && d.value !== "" && d.value !== undefined ? ( 
                            <b style={{ marginLeft: 5 }}>
                                {d.unit === m2 ? (
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        {numberWithSpaces(d.value)}{d.unit}
                                    </span>
                                ): d.value}
                            </b> 
                        ):(
                            <b style={{ marginLeft: 5 }}>
                                <span>{d.nd}</span>
                            </b>
                        )}
                    </ListItem>
                )
            }
        </List>
    )
}

const Shapley = () => {
    return (
        <Fragment>
            <SectionTitle>{shapleyTitle}</SectionTitle>
            <Margin m={10} />
            <p style={{
                fontSize: 10.23,
                lineHeight: "13.23px",
                color: "black",
            }}>
                {shapleyDescription}
            </p>
            <Margin m={5} />
            <div style={{
                background: "#f8f8f8",
                padding: "0 4px",
            }}>
                <ShapleyChartDesktop />
            </div>
        </Fragment>
    )
}
 
const Page03 = ({ scale }) => {
    return (
        <Wrapper id="synthesis_p3" scale={scale}>
            <Margin m={30} />
            <Photos />
            <SurfaceEtPieces />
            <Margin m={40} />
            <Shapley />
        </Wrapper>
    )
}

export default Page03