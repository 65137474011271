import { useSelector } from "react-redux"
import { numberWithSpaces } from "../../../../common/utils"
import { selectAddress, selectEstimation, selectHousing } from "../../../estimate/selectors"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"
import { selectOrg } from "../../../account/selectors"

const AdHeader = () => {
    const { commune } = useSelector(selectAddress)
    const userColor = useSelector(selectOrg).color
    const typeVente = useSelector(selectHousing).type_vente
    const prixPres = useSelector(selectEstimation).ad_displayed_value
    const tauxHonoraires = useSelector(selectEstimation).ad_fee_rates
    const prixNetVendeur = useSelector(selectEstimation).ad_net_value
    const aCharge = useSelector(selectEstimation).ad_fee_type
    
    const aChargeTextFormat = 
        aCharge === "acquereur"
            ? "de l'acquéreur"
            : aCharge === "vendeur"
                ? "du vendeur"
                : ""

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: 170,
            background: userColor,
            color: "white",
            padding: "30px 35px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <p style={{
                    fontWeight: 700,
                    fontSize: 25
                }}>
                    Vente {typeVente.toLowerCase()} {commune}
                </p>
                <Margin m={11} />
                <p style={{
                    fontWeight: 700,
                    fontSize: 50
                }}>
                    {numberWithSpaces(prixPres)} €
                </p>
                <Margin m={8} />
                <p style={{
                    fontWeight: 500,
                    fontSize: 10
                }}>
                    Dont {tauxHonoraires}% d’honoraires à la charge {aChargeTextFormat} - Prix net vendeur : {numberWithSpaces(prixNetVendeur)} €
                </p>
            </div>
            <OrgLogo />
        </div>
    )
}

export default AdHeader