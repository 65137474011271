import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectMandatEditorActionsSpe } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputCheckbox from "../../components/InputCheckbox"

const ActionsSpe = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const values = useSelector(selectMandatEditorActionsSpe)

    const handleChange = (e) => {
        const { name, checked } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value: checked }))
        saveMandat(0)
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <InputCheckbox 
                checked={values.proxim || ""} 
                name="action_spe_proximite" 
                onChange={handleChange} 
            />
            <p style={{
                fontSize: 13,
                lineHeight: "13px",
                marginLeft: 3,
                marginRight: 30
            }}>
                Actions de proximité
            </p>
            <InputCheckbox 
                checked={values.offMarket || ""} 
                name="action_spe_offmarket" 
                onChange={handleChange} 
            />
            <p style={{
                fontSize: 13,
                lineHeight: "13px",
                marginLeft: 3,
                marginRight: 30
            }}>
                Fichier clients (off market)
            </p>
            <InputCheckbox 
                checked={values.panneau || ""} 
                name="action_spe_panneau" 
                onChange={handleChange} 
            />
            <p style={{
                fontSize: 13,
                lineHeight: "13px",
                marginLeft: 3,
            }}>
                Panneau (si exclusivité)
            </p>
        </div>
    )
}

export default ActionsSpe