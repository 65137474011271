import { Box, Grid, Typography } from "@mui/material"
import { scssVar } from "../../../app/scssVar"
import DiscoverVideo from "../../../common/components/DiscoverVideo"
import { m2 } from "../../../common/utils"

const BoxStep = ({ children, wrapperSx }) => (
    <Box sx={Object.assign({}, {
        display: "flex",
    }, wrapperSx)}>
        <i
            className="iconoir-arrow-right"
            style={{
                fontSize: 26,
                color: scssVar.primary,
                marginRight: 5
            }}
        ></i>
        <Typography sx={{
            fontFamily: scssVar.fontFamily,
            color: scssVar.primary,
            fontSize: 17,
        }}>
            {children}
        </Typography>
    </Box>
)

const Video = () => (
    <Box sx={{
        bgcolor: scssVar.primary,
        width: { xs: "100%", sm: "65%", md: 282 },
        minWidth: 282,
        p: 2,
        mr: { xs: 0, md: 3, lg: 5 },
        mb: { xs: 4, md: 0 }
    }}>
        <DiscoverVideo />
    </Box>
)

const Title = () => (
    <Typography sx={{
        fontFamily: scssVar.fontFamily,
        color: "black",
        fontSize: 22,
        fontWeight: 700,
        mb: 3
    }}>
        Pourquoi s’abonner à Estimia  ?
    </Typography>
)

const Step1 = () => (
    <BoxStep wrapperSx={{ maxWidth: { xs: "100%", lg: 263 } }}>
        <b>Estimation au {m2}</b> avec un intervalle de confiance fiable
    </BoxStep>
)

const Step2 = () => (
    <BoxStep wrapperSx={{ maxWidth: { xs: "100%", lg: 263 } }}>
        <b>Évaluation du budget d’usage</b> du bien (notamment de son <b>bilan énergétique)</b>
    </BoxStep>
)

const Step3 = () => (
    <BoxStep wrapperSx={{ maxWidth: { xs: "100%", lg: 229 } }}>
        <b>Budget total d’achat</b> pour une information complète : vendeur et acheteurs 
    </BoxStep>
)

const Steps = () => (
    <Box>
        <Title />
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={4}><Step1 /></Grid>
            <Grid item xs={12} sm={12} lg={4}><Step2 /></Grid>
            <Grid item xs={12} sm={12} lg={4}><Step3 /></Grid>
        </Grid> 
    </Box>
)

const Discover = () => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            maxWidth: 1166
        }}>
            <Video />
            <Steps />
        </Box>
    )
}

export default Discover