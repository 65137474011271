import { Box } from "@mui/material"
import loaderGif from "../../../../common/assets/loader.gif"

const CreateHousingLoading = ({ progress }) => {
    return (
        <Box>
            <Box>
                <progress 
                    className="progress is-small is-primary" 
                    value={progress} 
                    max="100"
                ></progress>
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <img 
                    src={loaderGif}
                    alt="loading" 
                    style={{ width: 120 }} 
                />
            </Box>
        </Box>
    )
}

export default CreateHousingLoading