export function selectIsAuth(state) {
    const accessToken = state.auth.accessToken
    return accessToken !== null && accessToken !== undefined && accessToken !== ""
}

export const selectAuth = (state) => {
    const isLoading = state.auth.loading
    const isLogoutLoading = state.auth.logoutLoading
    const error = state.auth.error
    const tokens = {
        access: state.auth.accessToken,
        refresh: state.auth.refreshToken,
        // access: localStorage.getItem("accessToken"), 
        // refresh: localStorage.getItem("refreshToken") 
    }
    return { tokens, isLoading, isLogoutLoading, error }
}

export const selectUser = (state) => {
    const data = state.auth.user
    const isLoading = data.loading
    const error = data.error
    return { data, isLoading, error }
}