import { SwipeableDrawer } from "@mui/material"
import { useState } from "react"
import useStep from "../../hooks/useStep"
import StepTotalCostForm from "../../steps/components/StepTotalCostForm"
import TopBarDrawer from "../../../../common/components/TopBarDrawer"
import BottomBarDrawer from "../../../../common/components/BottomBarDrawer"

const DrawerStepEditor = ({ open, onClose, step }) => {
    const getStep = useStep()
    // step = pour définir quelle étape doit être afficher
    const { Component } = getStep(step)
    // stepName = pour les requêtes
    const stepName = "Synthesis"

    // eslint-disable-next-line
    const [height, setHeight] = useState(window.innerHeight - 20)

    return (
        <SwipeableDrawer
            open={open}
            anchor="bottom"
            onClose={onClose}
            onOpen={() => {}}
            disableSwipeToOpen
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: "white",
                    backgroundImage: "none",
                    width: "99vw",
                    maxWidth: 1000,
                    margin: "0 auto",
                    zIndex: 1301,
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                }
            }}
        >
            <TopBarDrawer />
            <BottomBarDrawer onClose={onClose} />
            <div 
                role="presentation" 
                style={{
                    height: height,
                    overflowY: "scroll",
                    width: "100%",
                }}
            >
                <div style={{
                    padding: "75px 0 110px",
                    width: "93%",
                    maxWidth: 1300,
                    margin: "0 auto"
                }}>
                    {step !== "totalCost" ? (
                        <Component stepName={stepName} noProgressBarDisplay />
                    ):(
                        <StepTotalCostForm stepName={stepName} />
                    )}
                </div>
            </div>
        </SwipeableDrawer>
    )
}

export default DrawerStepEditor