import { Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../../app/scssVar"
import Address from "../components/Address"
import Button from "../../../../common/components/Button"
import BigCheckbox from "../../../../common/components/BigCheckbox"

const Step00 = ({
    departements,
    address,
    onChangeAddress,
    onResetAddress,
    onSelectAddress,
    isHouse,
    isFlat,
    onClickHouse,
    onClickFlat,
    onGoNext,
    orgLogo, 
    orgName,
    orgColor
}) => {
    return (
        <Fragment>
            <Box sx={{ 
                overflow: "hidden",
                p: "25px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minHeight: "100%",
                bgcolor: "white",
                mxWidth: "100%",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    flexGrow: 1,
                    width: "100%",
                    maxWidth: 500,
                    margin: "0 auto",
                }}>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        fontSize: { xs: 36, sm: 48 },
                        lineHeight: { xs: "42px", sm: "60px" },
                        fontWeight: 800,
                        mb: "15px"
                    }}>
                        <span style={{ color: orgColor.main }}>Commencez</span>
                        <br />
                        votre évaluation !
                    </Typography>
                    <Address
                        filterDepartements={departements}
                        value={address.adresse}
                        onChange={onChangeAddress}
                        onSelect={onSelectAddress}
                        onReset={onResetAddress}
                        isOk={address.isOk}
                        orgColor={orgColor}
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ width: "48%" }}>
                            <BigCheckbox
                                onClick={onClickHouse}
                                checked={isHouse}
                                text="Maison"
                                icon="iconoir-home-alt-slim"
                                colorChecked={orgColor.main}
                                bgChecked={orgColor.light}
                            />
                        </Box>
                        <Box sx={{ width: "48%" }}>
                            <BigCheckbox
                                onClick={onClickFlat}
                                checked={isFlat}
                                text="Appartement"
                                icon="iconoir-building"
                                colorChecked={orgColor.main}
                                bgChecked={orgColor.light}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ m: "15px" }} />
                    <Button
                        type="button"
                        onClick={onGoNext}
                        disabled={!address.isOk || (isFlat === false && isHouse === false)}
                        sx={{
                            height: 56,
                            width: 150,
                            margin: "0 auto",
                            bgcolor: orgColor.main,
                            "&:hover": {
                                bgcolor: orgColor.main,
                                filter: "brightness(93%)"
                            },
                            "&.Mui-active": {
                                bgcolor: orgColor.main,
                                filter: "brightness(87%)"
                            },
                        }}
                    >
                        Suivant
                        <i 
                            className="iconoir-arrow-right"
                            style={{
                                fontSize: 24,
                                marginLeft: 5
                            }}
                        ></i>
                    </Button>
                </Box>
                {orgLogo && (
                    <img src={orgLogo} alt={orgName} style={{ width: 90, margin: "20px auto 0"}} />
                )}
            </Box>
        </Fragment>
    )
}

export default Step00