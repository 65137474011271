import { useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet-async';
import { useMemo } from "react";

const MetaTags = () => {
    const location = useLocation()
    const { pathname } = location

    const tags = useMemo(() => {
        switch (pathname) {
            default: 
                return {
                    title: "Estimia",
                    desc: "Edité par Digipi, l’estimateur immobilier Estimia dépasse l’estimation au m². Il intègre les données énergétiques d’un bien. Il fournit un intervalle de confiance fiable.",
                    robot: "noindex"
                }
            case "/login":
                return {
                    title: "Estimia : le nouvel estimateur immobilier intelligent",
                    desc: "Edité par Digipi, l’estimateur immobilier Estimia dépasse l’estimation au m². Il intègre les données énergétiques d’un bien. Il fournit un intervalle de confiance fiable.",
                    robot: "index,follow"
                }
            case "/decouvrir":
                return {
                    title: "Estimia : le mode d’emploi en vidéo",
                    desc: "Comment l’estimateur immobilier Estimia fonctionne-t-il sur mobile, tablette et ordinateur de bureau. Quelles sont les données utilisées et comment sont-elles traitées.",
                    robot: "index,follow"
                }
            case "/inscription":
                return {
                    title: "Estimia : nos formules d’abonnement et tarifs mensuels",
                    desc: "Agent commercial indépendant, agence immobilière, multi-agences, réseau de mandataire… trouvez le bon abonnement à l’estimateur immobilier Estimia selon votre besoin.",
                    robot: "noindex,nofollow"
                }
        }
    }, [pathname])

    const { title, desc, robot } = tags

    return (
        <Helmet>
            <title>
                {title}
            </title>
            <meta 
                name="description" 
                content={desc}
            />
            <meta
                name="robots"
                content={robot}
            />
        </Helmet>
    )
}

export default MetaTags