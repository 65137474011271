import { Fragment, useEffect, useRef, useState } from "react"
import CreateHousingLoading from "./components/CreateHousingLoading"
import HousingType from "./components/HousingType"
import ContinueEvalButton from "./components/ContinueEvalButton"
import ResetEvalButton from "./components/ResetEvalButton"
import StartEvalButton from "./components/StartEvalButton"
import Seller from "./components/Seller"
import Address from "./components/Address"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectHousing, selectIsWorking } from "../selectors"
import { saveProgress } from "../actions"
import { api } from "../../../common/setupApi"
import { addAlert } from "../../alerts/alertsSlice"
import { Box } from "@mui/material"

const initialSeller = {
    prenom_vendeur: "",
    nom_vendeur: ""
}

const initialAddress = {
    isOk: false,
    adresse: "",
    code_postal: "",
    code_commune: "",
    nom_commune: "",
    code_departement: "",
    longitude: "",
    latitude: ""
}

const initialHousingType = {
    is_flat: false,
    is_house: false,
    type_vente: null
}

const Step0 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const housing = useSelector(selectHousing)
    const working = useSelector(selectIsWorking)
    const loadingEstimationsList = useSelector(state => state.estimations.estimations.loading)
    const loadingUnfinishedsList = useSelector(state => state.estimations.unfinished.loading)
    const [progression, setProgression] = useState(10)
    const [seller, setSeller] = useState(initialSeller)
    const [address, setAddress] = useState(initialAddress)
    const [housingType, setHousingType] = useState(initialHousingType)
    const progressInterval = useRef(null)

    useEffect(() => {
        return () => {
            clearInterval(progressInterval)
        }
    }, [])

    useEffect(() => {
        setSeller(s => ({ 
            ...s, 
            nom_vendeur: housing.nom_vendeur || "",
            prenom_vendeur: housing.prenom_vendeur || "",
        }))
        setAddress({
            isOk: housing.id !== null,
            adresse: housing.adresse || "",
            code_postal: housing.code_postal || "",
            code_commune: housing.code_commune || "",
            nom_commune: housing.nom_commune || "",
            code_departement: housing.code_departement || "",
            longitude: housing.longitude || "",
            latitude: housing.latitude || ""
        })
        setHousingType({
            is_flat: housing.is_flat,
            is_house: housing.is_house,
            type_vente: housing.is_flat ? "Appartement" : housing.is_house ? "Maison" : null
        })
    }, [housing])

    const handleCreateHousing = async (e) => {
        e.preventDefault()

        let progressValue = 10
        progressInterval.current = setInterval(() => {
            progressValue = progressValue + 10
            setProgression(progressValue > 90 ? 90 : progressValue)
        }, [1000]) 

        dispatch({ type: "estimate/set_working", payload: true })

        return api
            .post("v1/housing/geo-features", { lon: address.longitude, lat: address.latitude })
            .then(async (geoRes) => {
                return api
                    .post("v1/housing/create", {
                        ...geoRes.data,
                        ...housingType,
                        ...seller,
                        adresse: address.adresse,
                        code_postal: address.code_postal,
                        code_commune: address.code_commune,
                        nom_commune: address.nom_commune,
                        code_departement: address.code_departement,
                        longitude: address.longitude,
                        latitude: address.latitude
                    })
                    .then(housingRes => {
                        dispatch({ type: "estimate/set_housing", payload: housingRes.data.housing })
                        dispatch({ type: "estimate/set_estimation", payload: housingRes.data.estimation })
                        dispatch({ type: "estimate/set_is_lead_estimation", payload: false })
                        dispatch({ type: "estimate/set_working", payload: false })
                        dispatch(saveProgress("Step1"))
                        clearInterval(progressInterval.current)
                        setProgression(100)
                        navigate("/estimer/step-1")
                    })
                    .catch(err => {
                        try {
                            const errorMessage = err.response.data.message
                            console.log(errorMessage)
                            dispatch(addAlert(
                                errorMessage || `Nous enrichissons chaque jour les adresses des biens à estimer, celle-ci n'apparaît pas encore mais le sera bientôt ! Merci de saisir une autre adresse.`, 
                                "error"
                            ))
                        } catch (unknownErrorException) {
                            console.log(unknownErrorException)
                            const errorMessage = "Une erreur est survenue, veuillez réessayer."
                            dispatch(addAlert(errorMessage, "error"))
                        }
                        dispatch({ type: "estimate/set_working", payload: false })
                        clearInterval(progressInterval.current)
                        setProgression(10)
                    })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert(
                    `Nous enrichissons chaque jour les adresses des biens à estimer, celle-ci n'apparaît pas encore mais le sera bientôt ! Merci de saisir une autre adresse.`, 
                    "error"
                ))
                dispatch({ type: "estimate/set_working", payload: false })
                clearInterval(progressInterval.current)
                setProgression(10)
            })
    }

    return (
        <Fragment>
            <Seller
                firstName={seller.prenom_vendeur}
                lastName={seller.nom_vendeur}
                onChange={e => {
                    const { name, value } = e.target
                    setSeller({ ...seller, [name]: value })
                }}
            />
            <Address 
                value={address.adresse}
                onChange={value => setAddress({ ...address, adresse: value, isOk: false })}
                onSelect={payload => setAddress({ ...payload, isOk: true })}
                onReset={() => setAddress(initialAddress)}
                isOk={address.isOk}
                isEstimationInProgress={housing.id !== null}
                working={working}
            />
            <Box sx={{ m: "20px" }} />
            {working ? (
                <CreateHousingLoading progress={progression} />
            ):(
                <Fragment>
                    <HousingType 
                        isHouse={housingType.is_house}
                        isFlat={housingType.is_flat}
                        onClickHouse={() => {
                            setHousingType({
                                is_house: !housingType.is_house,
                                is_flat: false,
                                type_vente: !housingType.is_house === true ? "Maison" : null
                            })
                        }}
                        onClickFlat={() => {
                            setHousingType({
                                is_flat: !housingType.is_flat,
                                is_house: false,
                                type_vente: !housingType.is_flat === true ? "Appartement" : null
                            })
                        }}
                        working={working}
                        isEstimationInProgress={housing.id !== null}
                    />
                    <Box sx={{ m: "50px" }} />
                    {housing.id !== null ? (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            "@media (max-width: 1100px)": {
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }
                        }}>
                            <Box sx={{
                                mr: 4,
                                "@media (max-width: 1100px)": {
                                    mb: "30px",
                                    mr: 0,
                                }
                            }}>
                                <ContinueEvalButton 
                                    sellerFirstName={seller.prenom_vendeur} 
                                    sellerLastName={seller.nom_vendeur} 
                                />
                            </Box>
                            <ResetEvalButton onReset={() => {
                                setSeller(initialSeller)
                                setHousingType(initialHousingType)
                                setAddress(initialAddress)
                            }} />
                        </Box>
                    ):(
                        <Box sx={{
                            "@media (max-width: 800px)": {
                                display: "flex",
                                justifyContent: "center"
                            }
                        }}>
                            <StartEvalButton
                                onClick={handleCreateHousing}
                                disabled={
                                    !seller.nom_vendeur.trim().length 
                                    || !seller.prenom_vendeur.trim().length
                                    || !address.isOk 
                                    || (!housingType.is_flat && !housingType.is_house)
                                    || loadingEstimationsList 
                                    || loadingUnfinishedsList
                                }
                            />
                        </Box>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default Step0