import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import FieldControl from "../../../common/components/FieldControl"
import { registerUser, registerUserActions, selectRegisterUserErrFields, selectRegisterUserForm } from "../registerUserSlice"
import CguCheckbox from "./CguCheckbox"
import ErrorHandler from "./ErrorHandler"

const FirstName = () => {
    const dispatch = useDispatch()
    const firstName = useSelector(selectRegisterUserForm).first_name
    const firstNameError = useSelector(selectRegisterUserErrFields).first_name
    const firstNameEmpty = !firstName.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerUserActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="text"
                label="Prénom"
                placeholder="Prénom"
                onChange={handleChange}
                value={firstName}
                name="first_name"
                isSuccess={!firstNameEmpty}
                isDanger={firstNameError.length}
                required
                autoComplete="given-name"
                maxLength={25}
            />
            <ErrorHandler errors={firstNameError} />
        </Box>
    )
}

const LastName = () => {
    const dispatch = useDispatch()
    const lastName = useSelector(selectRegisterUserForm).last_name
    const lastNameError = useSelector(selectRegisterUserErrFields).last_name
    const lastNameEmpty = !lastName.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerUserActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="text"
                label="Nom"
                placeholder="Nom"
                onChange={handleChange}
                value={lastName}
                name="last_name"
                isSuccess={!lastNameEmpty}
                isDanger={lastNameError.length}
                required
                autoComplete="family-name"
                maxLength={25}
            />
            <ErrorHandler errors={lastNameError} />
        </Box>
    )
}

const Email = () => {
    const dispatch = useDispatch()
    const email = useSelector(selectRegisterUserForm).email
    const emailError = useSelector(selectRegisterUserErrFields).email
    const emailEmpty = !email.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerUserActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="email"
                label="Adresse email"
                placeholder="Adresse email"
                onChange={handleChange}
                value={email}
                name="email"
                isSuccess={!emailEmpty}
                isDanger={emailError.length}
                required
                autoComplete="email"
                maxLength={50}
            />
            <ErrorHandler errors={emailError} />
        </Box>
    )
}

const Password1 = () => {
    const dispatch = useDispatch()
    const pwd1 = useSelector(selectRegisterUserForm).pwd1
    const pwd1Error = useSelector(selectRegisterUserErrFields).pwd1
    const pwdMatchError = useSelector(selectRegisterUserErrFields).pwd_match
    const pwdMatchError2 = useSelector(selectRegisterUserErrFields).pwdMatch
    const [visible, setVisible] = useState(false)
    const pwd1Empty = !pwd1.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerUserActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="password"
                label="Mot de passe"
                placeholder="Mot de passe"
                onChange={handleChange}
                value={pwd1}
                name="pwd1"
                isSuccess={!pwd1Empty}
                isDanger={pwd1Error.length || pwdMatchError.length || pwdMatchError2.length}
                required
                autoComplete="new-password"
                maxLength={25}
                minLength={8}
                withVisibilityIcons
                visible={visible}
                onToggleVisibility={() => setVisible(!visible)}
            />
            <ErrorHandler errors={pwd1Error} />
        </Box>
    )
}

const Password2 = () => {
    const dispatch = useDispatch()
    const pwd2 = useSelector(selectRegisterUserForm).pwd2
    const pwd2Error = useSelector(selectRegisterUserErrFields).pwd2
    const pwdMatchError = useSelector(selectRegisterUserErrFields).pwd_match
    const pwdMatchError2 = useSelector(selectRegisterUserErrFields).pwdMatch
    const [visible, setVisible] = useState(false)
    const pwd2Empty = !pwd2.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerUserActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="password"
                label="Confirmation mot de passe"
                placeholder="Confirmation mot de passe"
                onChange={handleChange}
                value={pwd2}
                name="pwd2"
                isSuccess={!pwd2Empty}
                isDanger={pwd2Error.length || pwdMatchError.length || pwdMatchError2.length}
                required
                autoComplete="new-password"
                maxLength={25}
                minLength={8}
                withVisibilityIcons
                visible={visible}
                onToggleVisibility={() => setVisible(!visible)}
            />
            <ErrorHandler errors={pwd2Error} />
        </Box>
    )
}

const CGU = () => {
    const dispatch = useDispatch()
    const cgu = useSelector(selectRegisterUserForm).cgu

    const handleChange = (e) => {
        const { name, checked } = e.target
        dispatch(registerUserActions.setField({ name, value: checked }))
    }

    const handleAcceptCgu = () => {
        dispatch(registerUserActions.setField({ name: "cgu", value: true }))
    }

    return (
        <CguCheckbox
            checked={cgu}
            onChange={handleChange}
            onAccept={handleAcceptCgu}
        />
    )
}

// const PwdMatchError = () => {
//     const pwdMatchError = useSelector(selectRegisterUserErrFields).pwd_match
//     const pwdMatchError2 = useSelector(selectRegisterUserErrFields).pwdMatch

//     if (pwdMatchError.length || pwdMatchError2.length) {
//         return (
//             <Grid item xs={12}>
//                 <Box>
//                     <ErrorHandler errors={pwdMatchError} />
//                     <ErrorHandler errors={pwdMatchError2} />
//                 </Box>
//             </Grid>
//         )
//     }

//     return null
// }

const SubmitButton = () => {
    const loading = useSelector(state => state.registerUser.registering.loading)

    return (
        <Box sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            mt: { xs: 2, sm: 0 }
        }}>
            <Button
                loading={loading}
                disabled={loading}
                sx={{
                    pl: 6,
                    pr: 6
                }}
            >
                S'inscrire
            </Button>
        </Box>
    )
}

const RegisteredDialog = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const registered = useSelector(state => state.registerUser.registering.registered)

    const handleGoLoginWhenRegistered = () => {
        dispatch(registerUserActions.reset())
        navigate("/login")
    }

    return (
        <Dialog 
            open={registered} 
            onClose={() => {}} 
            fullWidth
        >
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
                display: "flex",
                alignItems: "center",
            }}>
                Vous êtes inscrit !
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    fontFamily: scssVar.fontFamily
                }}>
                    Vous pourrez vous connecter une fois que l'administrateur aura activé votre compte
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleGoLoginWhenRegistered}>
                    Accueil
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const UserForm = () => {
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()

    const var1 = searchParams.get("var1")
    const var2 = searchParams.get("var2")

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(registerUser(var1, var2))
    }

    return (
        <Fragment>
            <form action="POST" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FirstName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LastName />
                    </Grid>
                    <Grid item xs={12}>
                        <Email />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Password1 />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Password2 />
                    </Grid>
                    {/* <PwdMatchError /> */}
                    <Grid item xs={12}>
                        <CGU />
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitButton />
                    </Grid>
                </Grid>
            </form>
            <RegisteredDialog />
        </Fragment>
    )
}

export default UserForm