import { useSelector } from "react-redux"
import Margin from "../../components/Margin"
import SectionTitle from "../components/SectionTitle"
import Wrapper from "../../components/Wrapper"

const Page09 = ({ scale }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <Wrapper id="synthesis_p9" scale={scale}>

            <Margin m={65} />

            <SectionTitle>
                Diagnostics et attestations
            </SectionTitle>

            <Margin m={20} />

            <p style={{
                fontWeight: 700,
                fontSize: 15
            }}>
                Ce qui est obligatoire pour débuter la vente et éviter tout risque de sanction ou de nullité de la vente :
            </p>

            <Margin m={6} />

            <ul style={{
                listStyleType: "disc",
                paddingLeft: 50
            }}>
                <li style={{ lineHeight: "20px" }}>
                    Le DPE (Diagnostic Performance Energétique), Lié à la consommation et au dégagement de CO2 
                    Attestation de surface si vous êtes en copropriété, dites loi Carrez ou loi Boutin
                </li>
            </ul>

            <Margin m={6} />

            <p style={{
                fontWeight: 700
            }}>
                Les autres diagnostics :
            </p>

            <Margin m={6} />

            <ul style={{
                listStyleType: "disc",
                paddingLeft: 50
            }}>
                <li style={{ marginBottom: 6 }}>
                    Le Plomb pour les constructions d’avant 1/01/1949 Amiante, pour les constructions d’avant 1/07/97
                </li>
                <li style={{ marginBottom: 6, lineHeight: "20px" }}>
                    Les États antiparasitaires Attestation de non connaissance de Mérule L’électricité & Gaz, pour les
                    installations de plus de 15 ans
                </li>
                <li style={{ marginBottom: 6, lineHeight: "20px" }}>
                    ERP (État des Risques et des Pollutions) PEB (Plan d’Exposition au bruit) Présence de Radon
                    Raccordement au réseau d’assainissement collectif ou privé
                </li>
                <li>
                    Rapport de performance numérique La fibre est disponible dans le logement
                </li>
            </ul>

            <Margin m={40} />

            <SectionTitle>
                Nos conseils pour les visites
            </SectionTitle>

            <Margin m={25} />

            <p style={{ lineHeight: "20px" }}>
                Pour vendre et pour les visites de votre bien, comme le présente de plus en plus d’émission de télévision,
                nous avons comme règle majeure pour mettre en évidence votre logement les principes suivants :
            </p>

            <Margin m={12} />

            <p style={{
                textAlign: "center",
                color: userColor,
                fontWeight: 700,
                lineHeight: "20px"
            }}>
                10 PRINCIPES ELEMENTAIRES
                <br/>Il est utile de dépersonnaliser au maximum
                <br/>Un acquéreur doit se projeter dans son futur logement, pas dans le vôtre...
            </p>

            <div style={{ lineHeight: "20px" }}>
                <p>
                    <b>1. Ne pas surcoter</b> le bien pour ne pas le paupériser dans le temps. La vente coup de cœur est
                    envisageable, mais attention à ne pas rester dans l’erreur, si l’empressement n’est pas au rendez-vous !
                </p>
                <p>
                    <b>2. Toujours comparer le marché</b> concurrentiel du bien (nous le faisons pour vous dans le cadre de nos
                    comptes rendus réguliers.)
                </p>
                <p>
                    <b>3. Dépersonnaliser</b> votre bien s’il est trop marqué de votre empreinte- Penser que l’acheteur doit se
                    projeter dans son futur logement et non dans votre style...
                </p>
                <p>
                    <b>4. Aérer</b> et désodoriser, si besoin.
                </p>
                <p>
                    <b>5. Alléger</b> un peu le mobilier, les affaires, pour ne pas rendre le bien impraticable ou trop chargé, voire
                    repoussoir, car peu aménageable.
                </p>
                <p>
                    <b>6. Agrémenter, ranger et scénariser,</b> en fin de journée vous pouvez mettre la table, arranger la table de
                    jardin, ranger, éviter de montrer des pièces trop encombrées, allumer les pièces un peu sombres,
                </p>
                <p>
                    <b>7. Écouter et ne pas intervenir systématiquement.</b> Nous connaissons nos acheteurs, nous répondons à
                    leurs demandes et leurs besoins. Ce qui est essentiel pour vous ou un plus ne l’est pas forcément pour
                    l’acheteur, ne critiquez pas ses commentaires ou choix.
                </p>
                <p>
                    <b>8. Réduire l’affect,</b> même si c’est votre histoire, votre passé, vos travaux, l’acquéreur n’aura peut-être
                    qu’une vision pragmatique du bien...
                </p>
                <p>
                    <b>9. Se préparer à vendre,</b> DPE obligatoire à la publication de l’annonce, attestation de propriété et
                    documents administratifs à rassembler le plus tôt possible.
                </p>
                <p>
                    <b>10. Se préparer à accepter une offre,</b> un principe dans nos expériences, « la première offre est souvent la
                    bonne », même quand elle est basse ! L’acquéreur peut connaître le marché, avoir déjà visité beaucoup
                    de biens et être prêt à se positionner dans les premiers jours de la mise en vente !
                </p>
            </div>
        </Wrapper>
    )
}

export default Page09