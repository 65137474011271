import { useSelector } from "react-redux"
import Margin from "../../components/Margin"
import Wrapper from "../../components/Wrapper"
import SectionTitle from "../components/SectionTitle"
import { selectMandatEditor2 } from "../mandatSlice"
import { selectOrg } from "../../../account/selectors"
import Footer from "../../components/Footer"
import { numberWithSpaces } from "../../../../common/utils"
import Empty from "../../components/Empty"

const Page02 = ({ scale }) => {
    const type = useSelector(selectMandatEditor2).mandat_type
    const numberMandat = useSelector(selectMandatEditor2).mandat_id || "ND"
    const orgName = useSelector(selectOrg).name
    const orgAddress = useSelector(selectOrg).address
    const honorairesMin = useSelector(selectOrg).frais_agence_absolu_minimum
    const managerFirstName = useSelector(selectOrg).org_directeur_prenom
    const managerLastName = useSelector(selectOrg).org_directeur_nom
    const managerEmail = useSelector(selectOrg).org_directeur_email
    const mediateurName = useSelector(selectOrg).org_mediateur_nom
    const mediateurEmail = useSelector(selectOrg).org_mediateur_site

    return (
        <Wrapper id="mandat_p2" scale={scale}>
            <SectionTitle style={{ width: "100%" }}>
                7. OBLIGATIONS DU MANDATAIRE ET PROMOTION DE LA COMMERCIALISATION
            </SectionTitle>

            <Margin m={3} />
            
            <p style={{
                fontSize: 12,
                lineHeight: "12px"
            }}>
                Le mandataire s’engage à mettre tous les moyens pour promouvoir le bien et faire en sorte de réaliser la vente le plus rapidement possible. 
                <span style={{ fontWeight: 600 }}>
                    {" "}Il devra rendre compte de sa mission dans les 21 jours qui suivent la prise d'effet du mandat (Art 1993 Code Civil).{" "}
                </span>
                En raison des obligations du mandataire, le mandant s'interdit en contrepartie, pendant la durée du mandat et pendant la période suivant son expiration, 
                pour une durée de 12 mois après la résiliation du présent mandat, de traiter directement avec un acquéreur ayant été présenté par le mandataire ou 
                ayant visité les locaux avec lui. PENDANT LA PÉRIODE SUIVANT L’EXPIRATION, en cas de vente réalisée par lui-même ou par un autre cabinet, le mandant 
                s'engage à en informer immédiatement le mandataire en lui notifiant par lettre recommandée les noms et adresses de l'acquéreur et du notaire rédacteur 
                de l'acte authentique.
            </p>

            <Margin m={10} />

            <SectionTitle style={{ width: "100%" }}>
                8. OBLIGATIONS DU MANDANT
            </SectionTitle>

            <Margin m={3} />

            {type === "vente" && (
                <p style={{
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "14px"
                }}>
                    MANDAT DE VENTE : Le mandant s’engage à accepter sans conditions, tout acquéreur(s) qui validerait le présent mandat
                    sans aucune négociation du prix ou demandes particulières, même avec une condition suspensive de prêt. Il s’oblige également à
                    valider une offre aux conditions du mandat, s’il est avéré que cette offre est la première qui lui soit présentée. Pour une offre aux
                    conditions du mandat (prix & honoraires), il autorise son mandataire à engager la vente, en validant l'offre ou une promesse de
                    vente. A défaut le mandant a pris connaissance qu’il aura à s’acquitter de la clause de dédommagement équivalent au montant des
                    honoraires prévus à l’article « Honoraires d’agence » du présent mandat, même si initialement, les honoraires ont été portés à la
                    charge des acquéreurs. Il en va de même si le mandant accepte une offre directement avec un prospect présenté par son
                    mandataire. Étant entendu que cette clause s’applique quel que soit l’option retenue à l’article 3.
                </p>
            )}
            {type === "entremise" && (
                <p style={{
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "14px"
                }}>
                    MANDAT D’ENTREMISE (temporaire) : le vendeur confère à son mandataire la mission de rechercher des
                    acquéreurs, afin de lui soumettre des propositions tant au prix, à la baisse ou à la hausse, selon le prix présenté. Le mandant
                    dispose de la faculté d’accepter l’offre qui lui convient (sans discrimination). Dans l’hypothèse d'une offre acceptée à un prix
                    supérieur au prix présenté, le mandant devra valider par un avenant au mandat, afin que son mandataire puisse engager la vente
                    dans le cadre de l’article 72 de la Loi Hoguet.
                </p>
            )}

            <Margin m={10} />

            <SectionTitle style={{ width: "100%", textTransform: "uppercase" }}>
                9. LES HONORAIRES MINIMUM DE {orgName} ET CLAUSE D’INDEMNITÉ
            </SectionTitle>

            <Margin m={3} />

            <p style={{
                fontSize: 12,
                lineHeight: "14px"
            }}>
                <span style={{ fontWeight: 600 }}>
                    MONTANT des honoraires MINIMUM et INCOMPRESSIBLE :{" "}
                </span> 
                Quelle que soit l’option choisie et la durée de commercialisation, le mandataire percevra en
                cas de vente par son intermédiaire des honoraires 
                <span style={{ fontWeight: 600 }}>
                    {" "}minimum de {honorairesMin ? numberWithSpaces(honorairesMin) : <Empty>honoraires minimum</Empty>} € TTC forfaitaire.
                    Dans l’hypothèse où un client acquéreur présenté par {orgName} viendrait à acquérir le bien via une autre agence ou en direct avec le
                    propriétaire, les honoraires d’agence seraient dus selon les dispositions suivantes :
                </span>
            </p>
            <ul style={{
                listStyleType: "disc",
                fontSize: 12,
                lineHeight: "14px",
                fontWeight: 600,
                paddingLeft: 40
            }}>
                <li>
                    Dans le 1er cas en partage d’honoraires entre notre confrère et nous à raison de 50 % des honoraires pour {orgName}.
                </li>
                <li>
                    Dans leur totalité si l’acquisition se faisait en direct entre le vendeur et l’acquéreur.
                </li>
            </ul>
            <p style={{
                fontSize: 12,
                lineHeight: "14px",
                fontWeight: 600
            }}>
                Dans le cas d’une exclusivité et durant sa validité, le vendeur informera tout acquéreur qui ferait une proposition sans passer par l’agence, que des
                honoraires seront dus et à la charge de l’acquéreur, à défaut le vendeur accepte de manière expresse de prendre à sa charge le règlement de cette
                clause pénale (indemnité), équivalente au montant des honoraires prévus au mandat.
            </p>

            <Margin m={10} />

            <SectionTitle style={{ width: "100%" }}>
                10. DISPOSITIONS SPÉCIFIQUES DU MANDAT EXCLUSIF
            </SectionTitle>

            <Margin m={3} />

            <p style={{
                fontSize: 12,
                lineHeight: "14px",
            }}>
                Si le mandant a confié le mandat en exclusivité, le mandat disposera d’une durée incompressible et irrévocable de 3 mois tant par sa nature que par 
                sa durée (sauf à
                avoir demandé une durée inférieure). 
                <span style={{ fontWeight: 600 }}>
                    {" "}Au-delà de cette période de 3 mois{" "}
                </span>
                ; selon 
                <span style={{ fontWeight: 600 }}>
                    {" "}l’article 78 de la Loi Hoguet, le mandant aura la faculté de résilier le mandat à tout moment en respectant un préavis de 15 jours.{" "}
                </span>
                Pendant toute la durée du présent mandat en exclusivité, le mandant s'interdit de traiter
                directement ou par l'intermédiaire d'un autre mandataire la vente des biens objet du mandat. Après expiration du mandat, et pour le cas où les
                biens seraient toujours disponibles à la vente, le mandant retrouvera la faculté de procéder lui-même à la recherche d’un acquéreur.
            </p>

            <Margin m={10} />

            <SectionTitle style={{ width: "100%" }}>
                11. EN CAS DE DÉMARCHAGE A DOMICILE
            </SectionTitle>

            <Margin m={3} />

            <div style={{ 
                fontSize: 10.5, 
                lineHeight: "13px" 
            }}>
                <p>
                    Dans le cas où les présentes sont établies au domicile du mandant, ce dernier bénéficie d’une faculté de renonciation en application des articles 
                    L 221-21 et suivants du Code de la Consommation. Cette faculté devra être exercée dans les quatorze jours à compter de la signature des présentes, 
                    au moyen du formulaire détachable joint aux présentes. Cette notification de renonciation devra être adressée au mandataire par lettre recommandée 
                    avec demande d'accusé de réception. Pour satisfaire aux prescriptions légales, ci-après reproduits les articles du Code de la Consommation :
                </p>
                <p>
                    L.221-23 : Les opérations visées à l'article ANCIENNEMENT L.121-21 doivent faire l'objet d'un contrat dont un exemplaire doit être remis au client au 
                    moment de la conclusion de ce contrat et comporter, à peine de nullité.
                </p>
                <p>
                    L.221-24 : Le contrat visé à l'article L.221-23 doit comprendre un formulaire détachable destiné à faciliter l'exercice de la faculté de renonciation dans les conditions
                    prévues à l'article L.121-25. Un décret en Conseil d'État précisera les mentions devant figurer sur ce formulaire. Ce contrat ne peut comporter aucune clause attributive
                    de compétence. Tous les exemplaires du contrat doivent être signés et datés de la main même du client.
                </p>
                <p>
                    L 221-25 : Dans les quatorze jours (Loi Hamon-Consommation), jours fériés compris, à compter de la commande ou de l'engagement d'achat, le client a la faculté d'y
                    renoncer par lettre recommandée avec accusé de réception. Si ce délai expire normalement un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu'au
                    premier jour ouvrable suivant. Toute clause du contrat par laquelle le client abandonne son droit de renoncer à sa commande ou à son engagement d'achat est nulle et
                    non avenue. Le présent article ne s'applique pas aux contrats conclus dans les conditions prévues à l'article L.221-27.
                </p>
                <p>
                    L.221-26 : Avant l'expiration du délai de réflexion prévu à l'article L.221-25, nul ne peut exiger ou obtenir du client, directement ou indirectement, à quelque titre ni sous
                    quelque forme que ce soit une contrepartie quelconque ni aucun engagement ni effectuer des prestations de services de quelque nature que ce soit.
                </p>
                <p>
                    RGPD : Traitement des données à caractère personnel
                </p>
                <p>
                    Conformément à la loi n°78-17 du 6 janvier 1978 dans sa version en vigueur et au Règlement européen (UE) 2016/679, le Mandant est informé que le Mandataire
                    procède au traitement des données à caractère personnel contenues dans le présent contrat. Le délégué à la protection des données (DPO) désigné au sein du
                    cabinet/de l’agence étant {managerFirstName || <Empty>prénom directeur</Empty>} {managerLastName || <Empty>nom directeur</Empty>} – {managerEmail || <Empty>email directeur</Empty>}
                </p>
                <p>
                    Les données obtenues sont nécessaires pour l’exécution des missions telles que figurant au présent contrat et dans le respect des obligations découlant des articles
                    1100 et suivants du code civil, de loi n° 70-9 du 2 janvier 1970 réglementant les conditions d'exercice des activités relatives à certaines opérations portant sur les
                    immeubles et les fonds de commerce, son décret d’application n°72-678 du 20 juillet 1972 et des articles L.561-1 et suivants du code monétaire et financier relatifs à la
                    lutte contre le blanchiment de capitaux et le financement du terrorisme.
                </p>
                <p>
                    Ces données pourront être transmises à {managerEmail || <Empty>email directeur</Empty>}
                </p>
                <p>
                    Elles seront conservées durant toute la durée de la relation commerciale et pendant une durée maximale de 5 ans à compter de la fin de la relation commerciale
                    conformément à l’article 2224 du code civil et à l’article L561-12 du code monétaire et financier relatif à l’obligation de conservation des informations des clients dans
                    le cadre de la lutte contre le blanchiment de capitaux et le financement du terrorisme. Les registres légaux tenus par le Cabinet/l’agence doivent être conservés pendant
                    dix ans (articles 53, 65 et 72 du décret du 20 juillet 1972), les noms et adresses des mandants y figurant seront donc conservés durant toute cette durée.
                </p>
                <p>
                    Le Mandant est informé qu’il bénéficie d’un droit d’accès et de rectification de ses données à caractère personnel traitées, qu’il peut demander leur effacement, leur
                    limitation et leur portabilité dans les conditions prévues aux articles 17, 18 et 20 du règlement européen (UE) 2016/679. Il peut également exercer son droit à opposition
                    dans les conditions prévues à l’article 21.
                </p>
                <p>
                    Toute réclamation pourra être formulée auprès de la CNIL - 8 rue de Vivienne - 75083 PARIS cedex 02 – tel : 01 53 73 22 22 – www.cnil.fr
                </p>
                <p>
                    Contentieux et médiation : Conformément à l'article L. 612-1 du code de la consommation, vous pouvez recourir gratuitement au service de médiation {mediateurName || <Empty>nom médiateur{" "}</Empty>}
                    dont nous relevons par voie électronique: {mediateurEmail || <Empty>email médiateur</Empty>}
                </p>
            </div>

            <Margin m={3} />

            <p style={{
                fontSize: 11,
                textAlign: "center"
            }}>
                <i>
                    <span style={{ fontWeight: 600 }}>
                        Coupon à découper UNIQUEMENT{" "}
                    </span> 
                    en cas d’exercice de votre faculté de renonciation dans le cadre d'un démarchage à domicile
                </i>
            </p>

            <Margin m={30} />

            <div style={{
                borderTop: "1px dashed black",
                width: "100%"
            }} />

            <Margin m={10} />

            <div style={{
                width: "90%",
                margin: "0 auto"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <div style={{ width: "45%" }}>
                        <p style={{
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: "14px"
                        }}>
                            Merci d'envoyer votre rétractation à l’adresse ci-dessous :
                        </p>
                        <div style={{
                            width: "100%",
                            border: "2px solid black",
                            padding: "5px"
                        }}>
                            <p style={{
                                fontSize: 11,
                                lineHeight: "13px"
                            }}>
                                Mandat répertorié au registre des mandats
                            </p>
                            <Margin m={5} />
                            <p style={{
                                fontSize: 11,
                                lineHeight: "13px"
                            }}>
                                sous <span style={{ fontWeight: 600 }}>le numéro :</span> {numberMandat}
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "45%" }}>
                        <div style={{
                            border: "2px solid black",
                            padding: "10px",
                            textAlign: "center"
                        }}>
                            <p style={{ fontSize: 14 }}>
                                {orgName}
                            </p>
                            <p style={{ fontSize: 14 }}>
                                {orgAddress}
                            </p>
                        </div>
                    </div>
                </div>

                <Margin m={10} />

                <p style={{ 
                    fontSize: 12, 
                    lineHeight: "14px" 
                }}>
                    Je soussigné………………………………………………………………………………………………………demeurant : exerce ma faculté de renonciation à mes engagements nés
                </p>

                <p style={{ 
                    fontSize: 12, 
                    lineHeight: "14px" 
                }}>
                    du présent mandat. Signature du(des) <span style={{ fontWeight: 600 }}><u>MANDANT(S)</u></span> :
                </p>
            </div>

            <Footer />
        </Wrapper>
    )
}

export default Page02