import { Box, Grid, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import FieldControl from "../../../../common/components/FieldControl"
import { api } from "../../../../common/setupApi"
import CguCheckbox from "../../components/CguCheckbox"
import ErrorHandler from "../../components/ErrorHandler"
import { loadStripe } from "@stripe/stripe-js"
import { addAlert } from "../../../alerts/alertsSlice"
import { useDispatch } from "react-redux"
import { appName } from "../../../../common/utils"

const FirstName = ({ onChange, firstName, firstNameError }) => {
    const firstNameEmpty = !firstName.trim().length

    return (
        <Box>
            <FieldControl
                type="text"
                label="Prénom"
                placeholder="Prénom"
                onChange={onChange}
                value={firstName}
                name="firstName"
                isSuccess={!firstNameEmpty}
                isDanger={firstNameError.length}
                required
                autoComplete="given-name"
                maxLength={25}
            />
            <ErrorHandler errors={firstNameError} />
        </Box>
    )
}

const LastName = ({ onChange, lastName, lastNameError }) => {
    const lastNameEmpty = !lastName.trim().length

    return (
        <Box>
            <FieldControl
                type="text"
                label="Nom"
                placeholder="Nom"
                onChange={onChange}
                value={lastName}
                name="lastName"
                isSuccess={!lastNameEmpty}
                isDanger={lastNameError.length}
                required
                autoComplete="family-name"
                maxLength={25}
            />
            <ErrorHandler errors={lastNameError} />
        </Box>
    )
}

const Email = ({ email, emailError, onChange }) => {
    const emailEmpty = !email.trim().length

    return (
        <Box>
            <FieldControl
                type="email"
                label="Adresse email"
                placeholder="Adresse email"
                onChange={onChange}
                value={email}
                name="email"
                isSuccess={!emailEmpty}
                isDanger={emailError.length}
                required
                autoComplete="email"
                maxLength={50}
            />
            <ErrorHandler errors={emailError} />
        </Box>
    )
}

const Password1 = ({ pwd1, pwd1Error, onChange }) => {
    const [visible, setVisible] = useState(false)
    const pwd1Empty = !pwd1.trim().length

    return (
        <Box>
            <FieldControl
                type="password"
                label="Mot de passe"
                placeholder="Mot de passe"
                onChange={onChange}
                value={pwd1}
                name="pwd1"
                isSuccess={!pwd1Empty}
                isDanger={pwd1Error.length}
                required
                autoComplete="new-password"
                maxLength={25}
                minLength={8}
                withVisibilityIcons
                visible={visible}
                onToggleVisibility={() => setVisible(!visible)}
            />
            <ErrorHandler errors={pwd1Error} />
        </Box>
    )
}

const Password2 = ({ pwd2, pwd2Error, onChange }) => {
    const [visible, setVisible] = useState(false)
    const pwd2Empty = !pwd2.trim().length

    return (
        <Box>
            <FieldControl
                type="password"
                label="Confirmation mot de passe"
                placeholder="Confirmation mot de passe"
                onChange={onChange}
                value={pwd2}
                name="pwd2"
                isSuccess={!pwd2Empty}
                isDanger={pwd2Error.length}
                required
                autoComplete="new-password"
                maxLength={25}
                minLength={8}
                withVisibilityIcons
                visible={visible}
                onToggleVisibility={() => setVisible(!visible)}
            />
            <ErrorHandler errors={pwd2Error} />
        </Box>
    )
}

const CGU = ({ cgu, onChange, onAccept }) => {
    return (
        <CguCheckbox
            checked={cgu}
            onChange={onChange}
            onAccept={onAccept}
        />
    )
}

const SubmitButton = ({ loading }) => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            mt: { xs: 2, sm: 0 }
        }}>
            <Button
                loading={loading}
                disabled={loading}
                sx={{
                    pl: 6,
                    pr: 6
                }}
            >
                Suivant
            </Button>
        </Box>
    )
}

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    pwd1: "",
    pwd2: "",
    cgu: false,
    error: {}
}

const NotSubForm = ({ setView, offreId, priceId, setErrorPriceId }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const [redirecting, setRedirecting] = useState(false)

    const {
        firstName,
        lastName,
        email,
        pwd1,
        pwd2,
        cgu,
        error
    } = state

    useEffect(() => {
        return () => {
            setRedirecting(false)
        }
    }, [])

    const handleChange = e => {
        const { name, value, type, checked } = e.target

        setState({ 
            ...state, 
            [name]: type === "checkbox" ? checked : value 
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!priceId) {
            setErrorPriceId(true)

            const el = document.getElementById("sub_choices")
            el.scrollIntoView({ behavior: "smooth" })

            return
        }

        const payload = {
            "price_id": priceId,
            "first_name": firstName,
            "last_name": lastName,
            "pwd1": pwd1,
            "pwd2": pwd2,
            "email": email.toLowerCase()
        }

        setErrorPriceId(false)
        setRedirecting(true)

        return api
            .get("v1/stripe/config/")
            .then(res => res.data.publicKey)
            .then(async (publicKey) => {
                return api
                    .post(`v1/stripe/offre-speciale/inscription/${offreId}/`, payload)
                    .then(async (res) => {
                        const stripePromise = loadStripe(publicKey)
                        const sessionId = res.data.id
                        const stripe = await stripePromise

                        return stripe.redirectToCheckout({ sessionId })
                    })
                    .catch(err => {
                        setRedirecting(false)
                        if (err.response.data) {
                            setState({ ...state, error: err.response.data })

                            if (err.response.status === 400) {
                                for (const property in err.response.data) {
                                    err.response.data[property].forEach(d => dispatch(addAlert(d, "error")))
                                }
                            } else {
                                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
                            }
                        } else {
                            dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
                        }
                    })
            })
            .catch(err => {
                setRedirecting(false)
                const errMsg = err.message || "Nos services sont en maintenance, veuillez réessayer ultérieurement."
                console.log(errMsg)
                dispatch(addAlert(errMsg, "error"))
            })
    }

    return (
        <Fragment>
            <Typography sx={{
                color: "black",
                fontFamily: scssVar.fontFamily,
                mb: 3
            }}>
                Vous avez déjà un compte {appName} ? 
                <span 
                    onClick={setView} 
                    style={{ 
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginLeft: 5,
                        color: scssVar.primary,
                    }}
                >
                    cliquez ici
                </span>
            </Typography>
            <Box sx={{ m: 2 }} />
            <form action="POST" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FirstName 
                            firstName={firstName} 
                            onChange={handleChange} 
                            firstNameError={error?.first_name || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LastName 
                            onChange={handleChange} 
                            lastName={lastName}
                            lastNameError={error?.last_name || []}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Email 
                            onChange={handleChange} 
                            email={email}
                            emailError={error?.email || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Password1 
                            onChange={handleChange} 
                            pwd1={pwd1}
                            pwd1Error={error?.pwd1 || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Password2 
                            onChange={handleChange} 
                            pwd2={pwd2}
                            pwd2Error={error?.pwd2 || []}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CGU 
                            onChange={handleChange} 
                            onAccept={() => setState({ ...state, cgu: true })}
                            cgu={cgu}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitButton loading={redirecting} />
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    )
}

export default NotSubForm