import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import home_5 from "../../common/assets/home_5.png"
import Unfinished from "../estimations/Unfinished"
import DesktopNav from "../../common/components/nav/DesktopNav"
import MobileNav from "../../common/components/nav/MobileNav"
import PageWrapper from "../../common/components/PageWrapper"
import Step0 from "./step0/Step0"
import { Box, Typography } from "@mui/material"
import { scssVar } from "../../app/scssVar"
import image3 from "../../common/assets/image3.png"

const Estimation = () => {
    const dispatch = useDispatch()
    const currentStep = useSelector(state => state.estimate.estimation.current_step)

    // enlever la saisie en cours si elle est terminée
    useEffect(() => {
        if (currentStep === "Synthesis" || currentStep === "ResultFinal") {
            dispatch({ type: "estimate/reset" })
        }
    }, [currentStep, dispatch])

    return (
        <PageWrapper>
            <MobileNav />
            <DesktopNav />
            <Box sx={{ m: { xs: 2, sm: 6 } }} />
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{
                    width: "50%",
                    "@media (max-width: 800px)": {
                        width: "100%",
                    },
                }}>
                    <Box sx={{
                        display: "none",
                        "@media (max-width: 800px)": {
                            backgroundImage: `url(${image3})`,
                            height: "200px",
                            backgroundSize: "cover",
                            backgroundPosition: "25% 50%",
                            borderRadius: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontSize: 36,
                            fontWeight: 700,
                            lineHeight: "42px",
                            pl: "15px",
                            mb: "30px"
                        }
                    }}>
                        <span style={{ color: "white" }}>
                            Commencez
                            <br/>votre
                            <br/>évaluation !
                        </span>
                    </Box>
                    <Typography sx={{
                        mt: "50px",
                        mb: "30px",
                        fontSize: "48px",
                        fontWeight: 700,
                        lineHeight: "60px",
                        fontFamily: scssVar.fontFamily,
                        "@media (max-width: 800px)": {
                            display: "none",
                            fontSize: "38px",
                            lineHeight: "50px",
                            textAlign: "center"
                        },
                        "@media (max-width: 599px)": {
                            fontSize: "32px",
                            lineHeight: "40px",
                        }
                    }}>
                        <span style={{ color: scssVar.primary }}>
                            Commencez
                        </span>
                        <br/>
                        <span style={{ color: "black", whiteSpace: "nowrap" }}>
                            votre évaluation !
                        </span>
                    </Typography>
                    <Step0 />
                </Box>
                <Box sx={{
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    "@media (max-width: 800px)": {
                        display: "none"
                    }
                }}>
                    <img 
                        src={home_5}
                        alt="home" 
                        style={{ 
                            width: "70%"
                        }} 
                    />
                </Box>
            </Box>
            <Unfinished />
        </PageWrapper>
    )
}

export default Estimation