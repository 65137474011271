import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { saveAvenant, saveMandat } from "../mandatSlice"

const useSaveMandat = () => {
    const dispatch = useDispatch()
    const timeout = useRef(null)

    useEffect(() => {
        return () => {
            clearTimeout(timeout.current)
        }
    }, [])

    const handleSaveMandat = (delay) => {
        timeout.current = setTimeout(() => {
            dispatch(saveMandat())
        }, [delay])
    }

    const handleSaveAvenant = (id, delay) => {
        timeout.current = setTimeout(() => {
            dispatch(saveAvenant(id))
        }, [delay])
    }

    return {
        clearTimeout: () => clearTimeout(timeout.current),
        saveMandat: handleSaveMandat,
        saveAvenant: handleSaveAvenant,
    }
}

export default useSaveMandat