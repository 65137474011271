import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import { selectOrg } from "../../../account/selectors"
import useAuth from "../../../auth/hooks/useAuth"
import Empty from "../../components/Empty"
import GreyBox from "../../components/GreyBox"
import InputDate from "../../components/InputDate"
import InputNumber from "../../components/InputNumber"
import InputSelect from "../../components/InputSelect"
import InputTextArea from "../../components/InputTextArea"
import { selectMandatEditor2 } from "../../mandat/mandatSlice"
import { selectCompteRenduVisiteState, visitsActions } from "../visitsSlice"

// const choices = [
//     { label: "Mauvais", score: 0 },
//     { label: "Assez moyen", score: 2.5 },
//     { label: "Moyen", score: 5 },
//     { label: "Correct", score: 6 },
//     { label: "Bon", score: 7 },
//     { label: "Très bon", score: 9 },
//     { label: "Coup de coeur", score: 10 },
// ]

const border = "1px solid " + scssVar.grey
const borderTop = border
const borderBottom = border
const borderRight = border
const borderLeft = border
const padding = "7px 12px"
const inputHeight = 25
const inputFontSize = 15

const SelectOpinion = ({ isInteret, value, onChange, name }) => {
    let options

    if (isInteret) {
        options = ["Aucun", "Faible", "A revoir", "Pourquoi pas...", "Intéressé(e)", "Assez positif", "Assez séduit", "Très fort", "Coup de coeur"]
    } else {
        options = ["Mauvais", "Assez moyen", "Moyen", "Correct", "Bon", "Très bon", "Coup de coeur"]
    }

    return (
        <InputSelect 
            value={value}
            onChange={onChange}
            name={name}
            style={{
                height: inputHeight,
                fontSize: inputFontSize,
                width: "100%"
            }}
        >
            <option value="" />
            {options.map((d, i) => <option key={i}>{d}</option>)}
        </InputSelect>
    )
}

const Opinion = () => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const userColor = useSelector(selectOrg).color
    const numberMandat = useSelector(selectMandatEditor2).mandat_id
    const state = useSelector(selectCompteRenduVisiteState)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(visitsActions.setCompteRenduField({ name, value }))
    }

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 50%)",
            borderTop
        }}>
            <div style={{ borderRight, borderLeft, borderBottom, padding, backgroundColor: userColor }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ fontSize: 16, marginRight: 3, whiteSpace: "nowrap", color: "white" }}>Mandat n°</p>
                    {numberMandat !== null ? (
                        <GreyBox style={{
                            fontSize: 16,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 4px"
                        }}>
                            {numberMandat}
                        </GreyBox>
                    ):(
                        <Empty>Numéro de mandat</Empty>
                    )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ fontSize: 16, marginRight: 3, whiteSpace: "nowrap", color: "white" }}>Votre conseiller :</p>
                    <GreyBox style={{
                        fontSize: 16,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 4px"
                    }}>
                        {user.lastName} {user.firstName}
                    </GreyBox>
                </div>
            </div>
            <div style={{ borderRight, borderBottom, padding, display: "flex", alignItems: "center", backgroundColor: userColor }}>
                <p style={{ fontSize: 16, marginRight: 3, color: "white" }}>Avis sur le bien visité le :</p>
                <InputDate
                    value={state.date_visite}
                    name="date_visite"
                    onChange={handleChange}
                    style={{
                        height: inputHeight,
                        fontSize: inputFontSize,
                        flexGrow: 1,
                        backgroundColor: "white"
                    }}
                />
            </div>

            {/* <div style={{ background: userColor, color: "white", borderRight, borderLeft, padding }}>
                <p style={{ fontSize: 16 }}>

                </p>
            </div>
            <div style={{ background: userColor, color: "white", borderRight, padding }}>

            </div> */}

            <div style={{ borderRight, borderBottom, borderLeft, padding }}>
                <p style={{ fontSize: 16 }}>Qualité du secteur (localisation) :</p>
            </div>
            <div style={{ borderBottom, borderRight, padding }}>
                <SelectOpinion value={state.note_localisation} onChange={handleChange} name="note_localisation" />
            </div>

            <div style={{ borderRight, borderBottom, borderLeft, padding }}>
                <p style={{ fontSize: 16 }}>Etat extérieur Façade - Murs - Clôtures - Toiture :</p>
            </div>
            <div style={{ borderBottom, borderRight, padding }}>
                <SelectOpinion value={state.note_exterieur} onChange={handleChange} name="note_exterieur" />
            </div>

            <div style={{ borderRight, borderBottom, borderLeft, padding }}>
                <p style={{ fontSize: 16 }}>Etat général intérieur du bien, style décoration : </p>
            </div>
            <div style={{ borderBottom, borderRight, padding }}>
                <SelectOpinion value={state.note_etat_general} onChange={handleChange} name="note_etat_general" />
            </div>

            <div style={{ borderRight, borderBottom, borderLeft, padding }}>
                <p style={{ fontSize: 16 }}>Surfaces des pièces : </p>
            </div>
            <div style={{ borderBottom, borderRight, padding }}>
                <SelectOpinion value={state.note_surface_pieces} onChange={handleChange} name="note_surface_pieces" />
            </div>

            <div style={{ borderRight, borderBottom, borderLeft, padding }}>
                <p style={{ fontSize: 16 }}>Votre intérêt pour le bien :</p>
            </div>
            <div style={{ borderBottom, borderRight, padding }}>
                <SelectOpinion isInteret value={state.note_interet} onChange={handleChange} name="note_interet" />
            </div>

            <div style={{ borderRight, borderLeft, borderBottom, padding }}>
                <p style={{ fontSize: 16 }}>Le prix du bien : </p>
            </div>
            <div style={{ borderBottom, borderRight, padding }}>
                <SelectOpinion value={state.note_prix} onChange={handleChange} name="note_prix" />
            </div>

            <div style={{ padding }}>
                <p style={{ fontSize: 16 }}>Vos commentaires sur le bien</p>
                <InputTextArea 
                    value={state.commentaires}
                    name="commentaires"
                    onChange={handleChange}
                    style={{
                        width: "100%",
                        height: 170,
                        fontSize: inputFontSize
                    }}
                />
            </div>
            <div style={{ borderBottom, borderLeft, borderRight, height: "max-content", padding, display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: 16, marginRight: 3, minWidth: "fit-content" }}>Votre estimation du prix :</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <InputNumber
                        value={state.estimation_prix}
                        name="estimation_prix"
                        onChange={handleChange}
                        style={{
                            height: inputHeight,
                            fontSize: inputFontSize,
                            flexGrow: 1
                        }}
                    />
                    <p style={{ fontSize: 16, marginLeft: 3 }}>€</p>
                </div>
            </div>

            <div></div>
            <div style={{ marginTop: -160 }}>
                <p style={{ fontSize: 16, fontWeight: 600 }}>Signatures :</p>
            </div>
        </div>
    )
}

export default Opinion