import { Box, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addAlert } from "../../alerts/alertsSlice"
import { updatePassword } from "../actions"
import AccountSection from "../components/AccountSection"
import { scssVar } from "../../../app/scssVar"
import FieldControl from "../../../common/components/FieldControl"
import Button from "../../../common/components/Button"

const initialState = {
    old: "",
    new: "",
    oldReveal: false,
    newReveal: false
}

const Password = () => {
    const dispatch = useDispatch()
    const updateLoading = useSelector(state => state.auth.user.updatePasswordLoading)
    const [state, setState] = useState(initialState)

    const notModified = (
        state.old === "" && state.new === ""
    )

    const samePasswordError = (
        state.old && state.new && state.old === state.new
    )

    const emptyField = (
        !state.old.trim().length 
        || !state.new.trim().length
    )

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState({
            ...state,
            [name]: value
        })
    }

    const togglePassword = (name) => {
        setState({ 
            ...state, 
            [name]: !state[name]
        })
    }

    const handleCancel = () => {
        setState(initialState)
    }

    const handleSave = e => {
        e.preventDefault()

        dispatch(
            updatePassword(state.old, state.new)
        )
        .then(() => {
            setState(initialState)
            dispatch({ type: "auth/update_password_success" })
            dispatch(addAlert("Votre mot de passe a bien été modifié", "success"))
        })
        .catch(err => {
            try {
                const errorMessage = err.response.data

                dispatch({ type: "auth/update_password_failure", payload: errorMessage })
                dispatch(addAlert(errorMessage, "error"))

            } catch (unknownErrorException) {
                const errorMessage = "Une erreur est survenue, veuillez réessayer."

                dispatch({ type: "auth/update_password_failure", payload: errorMessage })
                dispatch(addAlert(errorMessage, "error"))
            }
        })
    }

    return (
        <AccountSection
            title="Mot de passe"
        >
            <Box
                sx={{ 
                    flexGrow: 1,
                    mb: "30px"
                }}
                component="form"
                onSubmit={handleSave}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Ancien mot de passe"
                            placeholder="Ancien mot de passe"
                            isSuccess={state.old}
                            isDanger={samePasswordError}
                            disabled={updateLoading}
                            onChange={handleChange}
                            value={state.old || ""}
                            name="old"
                            required
                            type="password"
                            withVisibilityIcons
                            visible={state.oldReveal}
                            onToggleVisibility={() => togglePassword("oldReveal")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Nouveau mot de passe"
                            placeholder="Nouveau mot de passe"
                            isSuccess={state.new}
                            isDanger={samePasswordError}
                            disabled={updateLoading}
                            onChange={handleChange}
                            value={state.new || ""}
                            name="new"
                            required
                            type="password"
                            withVisibilityIcons
                            visible={state.newReveal}
                            onToggleVisibility={() => togglePassword("newReveal")}
                        />
                    </Grid>
                </Grid>

                {samePasswordError && (
                    <Typography sx={{
                        mt: 1,
                        fontSize: "13px",
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.danger
                    }}>
                        Vous ne pouvez pas renseigner un mot de passe identique à l'ancien.
                    </Typography>
                )}

                <Box sx={{ 
                    mt: "35px",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }
                }}>
                    <Button
                        variant="light"
                        type="button"
                        onClick={handleCancel}
                        disabled={notModified}
                        sx={{
                            pl: 7,
                            pr: 7
                        }}
                    >
                        Annuler
                    </Button>
                    <Box sx={{ m: 1 }} />
                    <Button
                        loading={updateLoading}
                        disabled={samePasswordError || emptyField || updateLoading || notModified}
                        sx={{
                            pl: 7,
                            pr: 7
                        }}
                    >
                        Enregistrer
                    </Button>
                </Box>
            </Box>
        </AccountSection>
    )
}

export default Password