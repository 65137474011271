import { useSelector } from "react-redux"
import { selectOrg } from "../../../account/selectors"
import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const TabIntegrationAlertMessage = () => {
    const org = useSelector(selectOrg)

    if (
        !org.loading
        && !org.error
        && (
            !org.address 
            || !org.email 
            || !org.phone
        )
    ) {
        return (
            <Box sx={{ p: 1, bgcolor: scssVar.primary, borderRadius: "8px", color: "white", mb: 3, fontWeight: 500 }}>
                <p>À renseigner dans la page "Mon compte" :</p>
                {!org.address && (<p style={{ marginLeft: 5 }}>- L'adresse de l'organisation</p>)}
                {!org.email && (<p style={{ marginLeft: 5 }}>- L'email de l'organisation</p>)}
                {!org.phone && (<p style={{ marginLeft: 5 }}>- Le téléphone de l'organisation</p>)}
            </Box>
        )
    }

    return null
}

export default TabIntegrationAlertMessage