import { Box, CircularProgress, Collapse, Typography } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"
import Button from "../../../../common/components/Button"
import { numberWithSpaces } from "../../../../common/utils"
import { selectAddress, selectValues } from "../../selectors"
import UsageCost from "./UsageCost"
import { scssVar } from "../../../../app/scssVar"
import LeafletMap from "../../../map/LeafletMap"

const MobileResult = ({ canCompute, showUseCost, onOpenShapley }) => {
    const { rue, codePostal, commune } = useSelector(selectAddress)
    const value = useSelector(selectValues).estimationValue
    const working = useSelector(state => state.estimate.working)
    const saving = useSelector(state => state.estimate.saving)
    const name = useSelector(state => state.estimate.housing.name)
    const currentStep = useSelector(state => state.estimate.estimation.current_step)
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <Box className="shadow1" sx={{
            position: "fixed",
            borderRadius: "24px",
            padding: "12px 16px 12px",
            top: "20px",
            background: "white",
            width: "90%",
            margin: "0 auto",
            left: 0,
            right: 0,
            zIndex: 1000,
            display: { xs: "block", sm: "none" }
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "10px"
            }}>
                <Box sx={{
                    padding: "4px 8px",
                    width: "fit-content",
                    backgroundColor: scssVar.greyLighter,
                    color: scssVar.primary,
                    fontSize: "14px",
                    borderRadius: "100px"
                }}>
                    Prix estimé
                </Box>
                <Button
                    type="button"
                    onClick={handleClick}
                    variant="light"
                    sx={{
                        color: scssVar.greyDark,
                        bgcolor: "transparent",
                        fontSize: 14,
                        width: "fit-content",
                        padding: "4px 0px",
                        height: "unset",
                        "&:hover": {
                            bgcolor: "transparent",
                        }
                    }}
                >
                    Détails
                    <i 
                        className="iconoir-nav-arrow-down"
                        style={{
                            fontSize: 18,
                            color: scssVar.greyDark,
                            transform: `rotate(${open ? "180" : "0"}deg)`,
                            transition: "transform .1s"
                        }}
                    ></i>
                </Button>
            </Box>
            <Box sx={{ height: 35 }}>
                {working && !saving ? (
                    <CircularProgress 
                        size={20} 
                        sx={{ 
                            color: scssVar.primary 
                        }} 
                    />
                ):( canCompute && value > 0.01 ? (
                    <Box>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            fontSize: "36px",
                            fontWeight: 700,
                            color: scssVar.primary,
                            lineHeight: "30px",
                        }}>
                            {numberWithSpaces(value)}€
                        </Typography>
                    </Box>
                ):(
                    <Box sx={{
                        mb: "5px",
                        border: 1,
                        borderColor: scssVar.danger,
                        color: scssVar.danger,
                        height: "100%",
                        p: "0 10px",
                        borderRadius: "18px",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Typography sx={{ 
                            fontFamily: scssVar.fontFamily,
                            fontSize: "11px",
                            flexGrow: 1,
                            lineHeight: "15px"
                        }}>
                            Renseignez la surface habitable et le nombre de pièces afin de lancer le calcul de l'estimation.
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Collapse in={open} timeout="auto" unmountOnExit={false}>
                {canCompute && currentStep === "Step1" && (
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        fontSize: "14px",
                        color: "black",
                    }}>
                        Base référentiel DVF
                    </Typography>
                )}
                {canCompute && (
                    <Button
                        onClick={onOpenShapley}
                        variant="primary-light"
                        sx={{
                            width: "100%",
                            borderRadius: "6px",
                        }}
                    >
                        Voir le détail du prix
                    </Button>
                )}
                {showUseCost && canCompute && (
                    <Box sx={{ mt: "25px" }}>
                        <UsageCost sx={{ wrapper: { border: "1px solid " + scssVar.greyLight } }} />
                    </Box>
                )}

                <Box sx={{ m: 2 }} />
                <LeafletMap height={160} />
                <Box sx={{ m: 2 }} />

                <Box sx={{
                    mt: "20px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <i className="iconoir-pin-alt" style={{ marginRight: 3, color: "black" }}></i>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        fontSize: "14px",
                        color: "black",
                        fontWeight: 500
                    }}>
                        {name}
                    </Typography>
                </Box>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: "14px",
                    color: "black",
                }}>
                    {rue}, {codePostal} {commune}
                </Typography>
            </Collapse>
        </Box>
    )
}

export default MobileResult