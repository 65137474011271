import { scssVar } from "../../../../app/scssVar"
import { appName } from "../../../../common/utils"
import { IconButton } from "@mui/material"
import { CancelRounded } from "@mui/icons-material"

const CostInfo = ({ show, onClose }) => {
    if (show) {
        return (
            <div style={{ 
                position: "relative", 
                width: "100%", 
                padding: "20px 26px",
                background: scssVar.greyLight, 
                borderRadius: "16px", 
                color: scssVar.black, 
                fontSize: 14,
                marginBottom: 30
            }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
                    <i className="iconoir-info-empty" style={{ fontSize: 22, marginRight: 10 }}></i>
                    <p>Informations</p>
                </div>
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
                    <CancelRounded fontSize="inherit" />
                </IconButton>
                <p style={{ marginBottom: 15 }}>
                    Les valeurs par défaut présentées sur cette page ont été
                    calculées à partir des informations renseignées sur les pages
                    précédentes, associées avec des références nationales et
                    ajustées avec l’expertise {appName}.
                </p>
                <p style={{ marginBottom: 15 }}>
                    Les valeurs indiquées le sont à titre informatif, et n'ont aucun caractère 
                    contractuel. Il est nécessaire de confirmer les montants auprès des professionnels.
                </p>
                <p style={{ color: scssVar.greyDarker }}>
                    Tout est modifiable et personnalisable.
                </p>
            </div>
        )
    }

    return null
}

export default CostInfo