import { useSelector } from "react-redux"
import { selectOrg } from "../../account/selectors"

const GreyBox = ({ 
    children, 
    w,
    h,
    p,
    ft,
    style
}) => {
    const userColor = useSelector(selectOrg).color
    const backgroundColor = "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"

    return (
        <div style={Object.assign({}, { 
            backgroundColor,
            width: w || "fit-content",
            height: h || "20px",
            padding: p || "0px",
            fontSize: ft || 13,
            color: "black",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }, style)}>
            {children}
        </div>
    )
}

export default GreyBox