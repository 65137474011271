import { Box } from "@mui/material"
import { Fragment } from "react"
import Export from "./components/Export"
import Finalize from "./components/Finalize"
import Delete from "./components/Delete"
import Duplicate from "./components/Duplicate"
import { useSelector } from "react-redux"
import { selectIsLeadEstimation } from "../../selectors"

const Options = () => {
    const isLeadEstimation = useSelector(selectIsLeadEstimation)

    return (
        <Fragment>
            <Box sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "flex-end" },
                alignItems: "center",
                flexWrap: "wrap",
            }}>
                {!isLeadEstimation && <Delete />}
                <Duplicate />
                <Export />
                <Finalize />
            </Box>
        </Fragment>
    )
}

export default Options
