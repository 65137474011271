import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import { useSelector } from "react-redux"
import { selectApiLongitudinalKpis } from "../../apiOfferSlice"
import StatBox from "../../../../common/components/dashboard/StatBox"

// FORFAIT
// "base_amount_ttc": prix forfaitaire de base du plan (la première ligne de l'encart Plan de la capture que je t'ai envoyée)
// "additionnal_request_unit_amount_ttc": prix à la requete supplémentaire (dernière ligne de l'encart Plan, dans l'exemple $0.01 per additionnal request)

// REQUETES
// "requests_threshold": nombre de requetes inclues dans le plan

const Plan = () => {
    const data = useSelector(selectApiLongitudinalKpis).dataBilling

    return (
        <StatBox 
            bgcolor={scssVar.greyLighter}
            icon="iconoir-euro"
            title="Votre forfait"
        >
            <Box sx={{ flexGrow: 1 }}>
                <p style={{ fontWeight: 800, fontSize: 22, marginBottom: 5 }}>
                    {data.requests_threshold || "0"} requête{data.requests_threshold > 1 && "s"}
                </p>
                <p style={{ fontWeight: 800, fontSize: 22 }}>
                    {data.base_amount_ttc || "0"} € <span style={{ fontSize: 10 }}>TTC</span>
                </p>
                <p style={{ color: scssVar.greyDark, marginTop: -5, marginBottom: 15, fontSize: 14 }}>
                    par cycle
                </p>
                <p style={{
                    fontSize: 14, 
                    lineHeight: "17px", 
                    width: "100%", 
                    padding: "7px", 
                    background: scssVar.primaryLighter, 
                    color: scssVar.primary,
                    borderRadius: "3px",
                    marginBottom: 15
                }}>
                    <span style={{ fontWeight: 600 }}>+ {data.additionnal_request_unit_amount_ttc || "0"} € TTC</span> par requête supplémentaire
                </p>
            </Box>
        </StatBox>
    )
}

export default Plan