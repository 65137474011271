import { Box, Typography } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import ProgressBar1 from "./ProgressBar1"

const ProgressBar2 = ({ label, p, bgcolor, color }) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    fontSize: 14
                }}>
                    {label}
                </Typography>
                <Typography sx={{ 
                    fontSize: 14,
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDark
                }}>
                    {p}%
                </Typography>
            </Box>
            <ProgressBar1
                p={p}
                bgcolor={bgcolor}
                color={color}
            />
        </Box>
    )
}

export default ProgressBar2