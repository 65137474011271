import { List } from "@mui/material"
import { useSelector } from "react-redux"
import AccountSection from "../components/AccountSection"
import AddTeam from "../components/AddTeam"
import Team from "../components/Team"
import { selectTeams } from "../selectors"

const Teams = () => {
    const { items, isLoading, error } = useSelector(selectTeams)

    return (
        <AccountSection
            title="Équipes"
            loading={isLoading}
            error={error?.data ? "Une erreur est survenue lors de la récupération de votre profil, veuillez réessayer." : null}
        >
            <List sx={{ p: 0 }}>
                {items?.data?.map(d => 
                    <Team key={d.team_id} team={d} />
                )}
            </List>
            <AddTeam />
        </AccountSection>
    )
}

export default Teams