import { IconButton } from "@mui/material"
import { scssVar } from "../../../app/scssVar"
import TooltipStyled from "../../../common/components/TooltipStyled"
import { ReactComponent as PdfExportIcon } from '../../../common/assets/pdf-export.svg';

const DownloadIconButton = ({ type, onClick, disabled }) => {
    return (
        <TooltipStyled arrow title="Exporter vers PDF">
            <span>
                <IconButton
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                    sx={{
                        bgcolor: scssVar.greyLight,
                        color: scssVar.primary,
                    }}
                >
                    <PdfExportIcon />
                </IconButton>
            </span>
        </TooltipStyled>
    )
}

export default DownloadIconButton