import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import { scssVar } from '../../../app/scssVar';

const PhotosButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: ${scssVar.greyLighter};
    padding: 0;
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid ${scssVar.greyLighter};
    text-align: start;
    border-radius: 4px;

    &:hover {
        border: 1px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.active} {}

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export default PhotosButton