import { Fragment } from "react"
import { useSelector } from "react-redux"
import { numberWithSpaces, phoneWithSpaces } from "../../../../common/utils"
import { selectOrg } from "../../../account/selectors"
import Margin from "../../components/Margin"
import OrgLogo from "../../components/OrgLogo"
import Empty from "../../components/Empty"

const Mandataire = () => {
    const org = useSelector(selectOrg)
    const userColor = org.color
    const backgroundColor = "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"

    const fontSize = 9.5
    const lineHeight = "11px"

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <p style={{ fontWeight: 600, fontSize: 18 }}>
                Le mandataire
            </p>
            <Margin m={1} />
            <OrgLogo wrapperStyle={{ height: 100 }} />
            <Margin m={3} />
            <div style={{
                background: backgroundColor,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: 95,
                fontSize,
                padding: "10px 5px",
                overflow: "hidden",
            }}>
                <p style={{ lineHeight, textAlign: "center" }}>
                    RCS {org.org_rcs || <Empty>RCS</Empty>} : {org.org_siret || <Empty>SIRET</Empty>}
                </p>
                <p style={{ lineHeight, textAlign: "center" }}>
                    Siège social : {org.address || <Empty>Adresse de l'organisation</Empty>}
                    {" - "}
                    Tél. : {org.phone ? phoneWithSpaces(org.phone) : <Empty>Téléphone de l'organisation</Empty>}
                </p>
                <p style={{ lineHeight, textAlign: "center" }}>
                    Carte professionnelle CCI {org.org_cci} n° {org.tCard || <Empty>Carte T</Empty>}
                </p>
                <p style={{ lineHeight, textAlign: "center" }}>
                    Assurance : {org.assurance_nom || <Empty>Nom de l'assurance</Empty>}
                    {" - "}
                    {org.assurance_adresse || <Empty>Adresse de l'assurance</Empty>}
                </p>
                <p style={{ lineHeight, textAlign: "center" }}>
                    {org.garantie_maniement_fonds === true
                        ? "Avec maniement de fonds" 
                        : org.garantie_maniement_fonds === false ? "Sans maniement de fonds"
                        : <Empty>Avec/sans maniement de fonds</Empty>
                    }
                </p>
                {org.garantie_maniement_fonds && (
                    <Fragment>
                        <p style={{ lineHeight, textAlign: "center" }}>
                            Organisme de caution : {org.garantie_organisme || <Empty>Organisme de caution</Empty>}
                        </p>
                        <p style={{ lineHeight, textAlign: "center" }}>
                            Montant de garantie : {org.garantie_montant_caution ? `${numberWithSpaces(org.garantie_montant_caution)} €` : <Empty>Montant de garantie</Empty>}
                        </p>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default Mandataire