import { Box, Dialog, DialogActions, DialogContent, DialogTitle, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import FieldControl from "../../../common/components/FieldControl"
import { changeInvoicingDay } from "../actions"

const initialState = {
    invoicingDay: "",
    open: false,
}

const InvoicingDay = () => {
    const dispatch = useDispatch()
    const invoicingDay = useSelector(state => state.auth.user.invoicingDay)
    const loading = useSelector(state => state.auth.user.updateInvoicingDayLoading)
    const [state, setState] = useState(initialState)
    const invalid = 
        state.invoicingDay !== null 
        && state.invoicingDay !== "" 
        && (
            Number(state.invoicingDay) <= 0 
            || state.invoicingDay > 28
        )
    const empty = state.invoicingDay === null || state.invoicingDay === ""
    const notModified = state.invoicingDay === invoicingDay

    useEffect(() => {
        if (state.open) {
            setState(s => ({
                ...s,
                invoicingDay: invoicingDay || ""
            }))
        }
    }, [invoicingDay, state.open])

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const handleChange = e => {
        const { value } = e.target
        setState({ ...state, invoicingDay: value })
    }

    const handleSubmit = e => {
        e.preventDefault()

        dispatch(changeInvoicingDay(state.invoicingDay))
        .then(() => {
            setState({ ...state, open: false })
        })
    }

    return (
        <Fragment>
            <ListItemButton
                onClick={handleOpen}
                sx={{
                    borderBottom: 1,
                    borderColor: scssVar.greyLight,
                }}
            >
                <ListItemText
                    primary="Jour de facturation"
                    secondary={`
                        Le ${Number(invoicingDay) === 1 ? "1er" : invoicingDay} de chaque mois
                    `}
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black"
                        },
                        "& .MuiListItemText-secondary": {
                            color: scssVar.greyDark
                        }
                    }}
                />
                <i 
                    className="iconoir-arrow-right"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark
                    }}
                ></i>
            </ListItemButton>
            <Dialog 
                open={state.open} 
                onClose={handleClose} 
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Modifier le jour de facturation
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ 
                        flexGrow: 1, 
                    }}>
                        <FieldControl
                            label="Jour de facturation"
                            placeholder="Jour de facturation"
                            isSuccess={state.invoicingDay}
                            isDanger={invalid}
                            onChange={handleChange}
                            value={state.invoicingDay || ""}
                            required
                            type="number"
                            maxLength={2}
                        />
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDark,
                            mt: 1,
                            fontSize: "13px"
                        }}>
                            Entre le 1er et le 28.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        sx={{
                            width: 150
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        loading={loading}
                        disabled={invalid || notModified || empty || loading}
                        onClick={handleSubmit}
                        sx={{
                            width: 150
                        }}
                    >
                        Enregistrer
                        <i
                            className="iconoir-save-floppy-disk"
                            style={{
                                fontSize: 24,
                                marginLeft: 5,
                            }}
                        ></i>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default InvoicingDay