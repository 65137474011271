import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputSelect from "../../components/InputSelect"
import GreyBox from "../../components/GreyBox"

const Type = ({ readOnly }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const value = useSelector(selectMandatEditor2).mandat_type

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(0)
    }

    if (readOnly) {
        return (
            <GreyBox style={{
                width: "100%",
                fontSize: 25,
                height: 40,
                padding: "0 4px",
                display: "flex",
                alignItems: "center"
            }}>
                {value === "vente" && "MANDAT DE VENTE"}
                {value === "entremise" && "MANDAT D'ENTREMISE"}
            </GreyBox>
        )
    }

    return (
        <InputSelect
            name="mandat_type"
            value={value || ""}
            onChange={handleChange}
            style={{
                width: "100%",
                fontSize: 25,
                height: 40
            }}
        >
            <option value="vente">MANDAT DE VENTE</option>
            <option value="entremise">MANDAT D'ENTREMISE</option>
        </InputSelect>
    )
}

export default Type