import { AddRounded } from "@mui/icons-material"
import { scssVar } from "../../../../../../app/scssVar"
import PhotosButton from "../../../../../photoUploader/components/PhotosButton"

const UploadButton = () => (
    <PhotosButton
        component="span"
        sx={{
            marginLeft: "auto",
            width: 150,
            pl: "6px", 
            height: 40,
            borderRadius: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: scssVar.primary,
            color: "white",
        }}
    >
        Importer
        <AddRounded style={{ fontSize: 20, marginLeft: 1 }} />
    </PhotosButton>
)

export default UploadButton