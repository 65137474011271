import { Box } from "@mui/material"
import { Fragment } from "react"
import { useDispatch } from "react-redux"
import Button from "../../common/components/Button"
import OpinionForm from "./components/OpinionForm"
import { opinionOpened } from "./userOpinionSlice"

const UserOpinion = () => {
    const dispatch = useDispatch()

    const handleOpen = () => {
        dispatch(opinionOpened())
    }
    
    return (
        <Fragment>
            <Box sx={{ display: { xs: "none", sm: "block" }}}>
                <Button
                    onClick={handleOpen}
                    noShadow
                    sx={{
                        position: "fixed",
                        bottom: 200,
                        right: 0,
                        height: "48px",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        transform: "rotate(-90deg)",
                        transformOrigin: "bottom right",
                        fontSize: 16,
                        fontWeight: 600
                    }}
                >
                    Votre avis
                </Button>
            </Box>
            <OpinionForm />
        </Fragment>
    )
}

export default UserOpinion