import Button from "../../../../common/components/Button"

const StartEvalButton = ({ onClick, disabled }) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            sx={{ 
                height: 56,
                width: 150
            }}
        >
            Suivant
            <i 
                className="iconoir-arrow-right"
                style={{
                    fontSize: 24,
                    marginLeft: 5
                }}
            ></i>
        </Button>
    )
}

export default StartEvalButton