import { Backdrop, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../app/scssVar"
import UploadButtonContainer from "../../../common/components/UploadButtonContainer"

const MenuPhoto = ({
    open,
    onClose,
    anchorEl,
    onOpenDisplay,
    onChange,
    newPhotoValue,
    onOpenDelete,
    noUpdate
}) => {
    return (
        <Fragment>
            <Backdrop
                open={open} 
                sx={{ 
                    background: "rgba(20, 20, 20, 0.6)",
                    WebkitBackdropFilter: "blur(4px)",
                    backdropFilter: "blur(4px)",
                    zIndex: 1300
                }} 
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                sx={{
                    "& .MuiList-root": {
                        padding: 0,
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "6px"
                    }
                }}
            >
                <li></li> {/* remove auto focus on phone */}
                <MenuItem
                    onClick={onOpenDisplay}
                    sx={{ 
                        borderBottom: 1, 
                        borderColor: scssVar.grey,
                        width: 215, 
                        padding: "15px", 
                        color: scssVar.primary,
                    }}
                >
                    <ListItemIcon style={{ minWidth: "32px", marginLeft: "3px" }}>
                        <i 
                            className="iconoir-eye-empty"
                            style={{
                                fontSize: 24,
                                color: scssVar.primary
                            }}
                        ></i>
                    </ListItemIcon>
                    <ListItemText primary="Afficher" sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily, 
                            fontWeight: 500
                        }
                    }} />
                </MenuItem>
                {!noUpdate && (
                    <UploadButtonContainer
                        accept="image/*"
                        onChange={onChange}
                        value={newPhotoValue}
                    >
                        <MenuItem 
                            component="span"
                            sx={{ 
                                borderBottom: 1, 
                                borderColor: scssVar.grey,
                                width: 215, 
                                padding: "15px", 
                                color: scssVar.primary,
                            }}
                        >
                            <ListItemIcon style={{ minWidth: "32px", marginLeft: "3px" }}>
                            <i 
                                className="iconoir-edit-pencil"
                                style={{
                                    fontSize: 24,
                                    color: scssVar.primary
                                }}
                            ></i>
                        </ListItemIcon>
                        <ListItemText primary="Modifier" sx={{
                            "& .MuiTypography-root": {
                                fontFamily: scssVar.fontFamily, 
                                fontWeight: 500
                            }
                        }} />
                        </MenuItem>
                    </UploadButtonContainer>
                )}
                <MenuItem 
                    onClick={onOpenDelete}
                    sx={{ 
                        width: 215, 
                        padding: "15px", 
                        color: scssVar.danger,
                    }}
                >
                    <ListItemIcon style={{ minWidth: "32px", marginLeft: "3px" }}>
                        <i 
                            className="iconoir-cancel"
                            style={{
                                fontSize: 24,
                                color: scssVar.danger
                            }}
                        ></i>
                    </ListItemIcon>
                    <ListItemText primary="Supprimer" sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily, 
                            fontWeight: 500
                        }
                    }} />
                </MenuItem>
            </Menu>
        </Fragment>
    )
}

export default MenuPhoto