import Box from '@mui/material/Box';
import StepTopBar from './StepTopBar';
import { useSelector } from "react-redux"
import StepNav from "./StepNav"
import MobileResult from "./MobileResult"
import RightResult from "./RightResult"
import { Navigate, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../../common/components/PageWrapper';
import { Fragment, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
import Shapley from '../../shapley/Shapley';
import BottomBarDrawer from '../../../../common/components/BottomBarDrawer';
import TopBarDrawer from '../../../../common/components/TopBarDrawer';

const StepLayout = ({
    stepName,
    children,
    showUseCost,
    saveForLaterDisabled,
    backDisabled,
    onGoBack,
    nextDisabled,
    onGoNext,
    canCompute
}) => {
    const navigate = useNavigate()
    const noHousingId = useSelector(state => state.estimate.housing.id) === null
    const [openShapley, setOpenShapley] = useState(false)

    const handleOpenShapley = () => {
        setOpenShapley(true)
    }

    const handleCloseShapley = () => {
        setOpenShapley(false)
    }

    if (noHousingId) {
        return <Navigate to="/" />
    }

    return (
        <Fragment>
            <PageWrapper
                variant="steps"
                left={
                    <Fragment>
                        <StepTopBar />
                        <Box sx={{ m: { xs: 4, sm: 4 } }} />
                        {children}
                        <StepNav 
                            onSaveForLater={() => navigate("/mes-evaluations")}
                            saveForLaterDisabled={saveForLaterDisabled}
                            backDisabled={backDisabled}
                            onGoBack={onGoBack}
                            nextDisabled={nextDisabled}
                            onGoNext={onGoNext}
                        />
                    </Fragment>
                }
                right={<RightResult canCompute={canCompute} showUseCost={showUseCost} onOpenShapley={handleOpenShapley} />}
            />
            <MobileResult canCompute={canCompute} showUseCost={showUseCost} onOpenShapley={handleOpenShapley} />
            <SwipeableDrawer
                open={openShapley}
                anchor="bottom"
                onClose={handleCloseShapley}
                onOpen={() => {}}
                disableSwipeToOpen
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: "white",
                        backgroundImage: "none",
                        width: "99vw",
                        maxWidth: 1000,
                        margin: "0 auto",
                        zIndex: 1301,
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                    }
                }}
            >
                <TopBarDrawer />
                <BottomBarDrawer onClose={handleCloseShapley} />
                <div 
                    role="presentation" 
                    style={{
                        maxHeight: window.innerHeight - 20,
                        height: "auto",
                        overflowY: "scroll",
                        width: "100%",
                    }}
                >
                    <div style={{
                        padding: "58px 0 200px",
                        width: "93%",
                        maxWidth: 1300,
                        margin: "0 auto"
                    }}>
                        <Shapley />
                    </div>
                </div>
            </SwipeableDrawer>
        </Fragment>
    )
}

export default StepLayout
