import { configureStore } from '@reduxjs/toolkit'
// old redux
import account from "../features/account/reducer"
import auth from "../features/auth/reducer"
import estimate from "../features/estimate/reducer"
import estimations from "../features/estimations/reducer"
// slice
import appUpdateSlice from "../features/appUpdate/appUpdateSlice"
import alertsSlice from "../features/alerts/alertsSlice"
import fetchedContentSlice from "../features/fetchedContent/fetchedContentSlice"
import userOpinionSlice from "../features/userOpinion/userOpinionSlice"
import historySlice from "../features/history/historySlice"
import mandatSlice from "../features/pdfCreator/mandat/mandatSlice"
import registerOrgSlice from '../features/register/registerOrgSlice'
import registerUserSlice from '../features/register/registerUserSlice'
import photosSlice from '../features/photoUploader/photosSlice'
import visitsSlice from '../features/pdfCreator/visits/visitsSlice'
import buyOfferSlice from '../features/pdfCreator/buy/offer/buyOfferSlice'
import tracfinSlice from '../features/pdfCreator/buy/tracfin/tracfinSlice'
import apiOfferSlice from '../features/apiOffer/apiOfferSlice'

const store = configureStore({
    reducer: {
        auth,
        account,
        estimations,
        estimate,
        mandat: mandatSlice,
        visits: visitsSlice,
        buyOffer: buyOfferSlice,
        tracfin: tracfinSlice,
        apiOffer: apiOfferSlice,
        photos: photosSlice,
        registerOrg: registerOrgSlice,
        registerUser: registerUserSlice,
        appUpdate: appUpdateSlice,
        history: historySlice,
        fetchedContent: fetchedContentSlice,
        userOpinion: userOpinionSlice,
        alerts: alertsSlice
    },
})

export default store