import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMandat, selectMandat, selectMandatEditor2 } from "../mandat/mandatSlice"
import { selectEstimation } from "../../estimate/selectors"
import { Box, CircularProgress } from "@mui/material"
import TracfinPdf from "./tracfin/TracfinPdf"
import OfferPdf from "./offer/OfferPdf"
import { scssVar } from "../../../app/scssVar"
import { Tab, TabPanel, Tabs, a11yProps } from "../../../common/components/Tabs"
import MissingFieldAlertText from "../../../common/components/MissingFieldAlertText"

const BuyPdf = () => {
    const dispatch = useDispatch()
    const estimationId = useSelector(selectEstimation).id
    const { isLoading, error } = useSelector(selectMandat)
    const numberMandat = useSelector(selectMandatEditor2).mandat_id
    const [index, setIndex] = useState(0)

    const emptyNumberMandat = numberMandat === "" || numberMandat === null || numberMandat === undefined

    // pour avoir les infos liés au mandat de l'estimation
    useEffect(() => {
        dispatch(getMandat(estimationId))
    }, [dispatch, estimationId])

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: scssVar.primary }}>Chargement...</p>
                <CircularProgress size={20} sx={{ color: scssVar.primary, ml: 1 }} />
            </Box>
        )
    }

    if (error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer.</p>
    }

    return (
        <Fragment>
            <MissingFieldAlertText visible={emptyNumberMandat} sx={{ mb: 4 }}>
                Veuillez renseigner le <u>numéro de mandat</u> dans l'onglet "Mandat"
            </MissingFieldAlertText>

            <Box sx={{ bgcolor: scssVar.greyLighter, borderRadius: "8px", pb: 6 }}>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                    <Tabs
                        value={index} 
                        onChange={(e, newValue) => setIndex(newValue)} 
                        aria-label="buying tabs"
                        variant="scrollable"
                    >
                        <Tab label="Offre d'achat" {...a11yProps(0)} />
                        <Tab label="Évaluation des risques - Tracfin" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={index} index={0}>
                    <OfferPdf />
                </TabPanel>

                <TabPanel value={index} index={1}>
                    <TracfinPdf />
                </TabPanel>
            </Box>
        </Fragment>
    )
}

export default BuyPdf