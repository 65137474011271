import { Box, Grid } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../../app/scssVar"
import Checkbox from "../../../../common/components/Checkbox"
import FieldControl from "../../../../common/components/FieldControl"

const ErrorMessage = ({ errors }) => errors 
    ? errors.map((d, i) =>
        <p key={i} style={{ color: scssVar.danger, fontSize: 11, lineHeight: "13px", marginTop: 5 }}>{d}</p>
    ) : null

const Step04 = ({ 
    legalDocs,
    userInfos,
    onChange,
    onCheckDoc,
    errorContactField,
    orgColor
}) => {

    const handleCheckDoc = (e, checked) => {
        const newDocs = legalDocs.map(d => d.name === e.target.name ? { ...d, checked } : d)
        onCheckDoc(newDocs)
    }

    return (
        <Fragment>
            <p style={{ fontWeight: 800, textAlign: "start", fontSize: 20 }}>
                Votre projet en quelques mots
            </p>
            <p style={{ textAlign: "start", color: scssVar.greyDarker, marginTop: 10, marginBottom: 25, fontSize: 14, lineHeight: "17px" }}>
                Encore une dernière petite étape ! Nous offrons une estimation de votre bien gratuite et sans obligation 
                basée sur nos algorithmes !
                Pour ce faire, nous avons besoin des informations ci-dessous.
                Si vous souhaitez être contacté, vous serez mis en relation avec nos 
                experts du marché immobilier afin d'optimiser votre projet.
            </p>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FieldControl
                        required
                        value={userInfos.lead_first_name}
                        fieldClassName={`input input-grey ${userInfos.lead_first_name && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${userInfos.lead_first_name && 'has-text-success'}`}
                        name="lead_first_name"
                        onChange={onChange}
                        type="text"
                        placeholder="Prénom"
                        label="Prénom*"
                        isDanger={errorContactField.lead_first_name?.length > 0}
                    />
                    <ErrorMessage errors={errorContactField.lead_first_name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FieldControl
                        required
                        value={userInfos.lead_last_name}
                        fieldClassName={`input input-grey ${userInfos.lead_last_name && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${userInfos.lead_last_name && 'has-text-success'}`}
                        name="lead_last_name"
                        onChange={onChange}
                        type="text"
                        placeholder="Nom"
                        label="Nom*"
                        isDanger={errorContactField.lead_last_name?.length > 0}
                    />
                    <ErrorMessage errors={errorContactField.lead_last_name} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        required
                        value={userInfos.lead_email}
                        fieldClassName={`input input-grey ${userInfos.lead_email && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${userInfos.lead_email && 'has-text-success'}`}
                        name="lead_email"
                        onChange={onChange}
                        type="email"
                        placeholder="Adresse email"
                        label="Adresse email*"
                        isDanger={errorContactField.lead_email?.length > 0}
                    />
                    <ErrorMessage errors={errorContactField.lead_email} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        value={userInfos.lead_phone}
                        fieldClassName={`input input-grey ${userInfos.lead_phone && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${userInfos.lead_phone && 'has-text-success'}`}
                        name="lead_phone"
                        onChange={onChange}
                        type="tel"
                        placeholder="Téléphone"
                        label="Téléphone"
                        isDanger={errorContactField.lead_phone?.length > 0}
                        maxLength={10}
                    />
                    <ErrorMessage errors={errorContactField.lead_phone} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        type="select"
                        label="Votre projet"
                        value={userInfos.project}
                        fieldClassName={`select is-fullwidth select-grey ${userInfos.project && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${userInfos.project && 'has-text-success'}`}
                        name="project"
                        onChange={onChange}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                <option>
                                    Je n'ai aucun projet
                                </option>
                                <option>
                                    Je souhaite vendre ce bien
                                </option>
                                <option>
                                    Je souhaite acheter ce bien pour y vivre
                                </option>
                                <option>
                                    Je souhaite acheter ce bien pour le louer
                                </option>
                            </Fragment>
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        type="select"
                        label="Contact"
                        value={userInfos.contact}
                        fieldClassName={`select is-fullwidth select-grey ${userInfos.contact && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${userInfos.contact && 'has-text-success'}`}
                        name="contact"
                        onChange={onChange}
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                <option>
                                    Je ne souhaite pas être contacté
                                </option>
                                <option>
                                    Je souhaite être contacté pour une estimation plus précise
                                </option>
                                <option>
                                    Je souhaite être informé(e) si des biens similaires sont mis en vente
                                </option>
                            </Fragment>
                        }
                    />
                </Grid>
                {legalDocs.length > 0 && (
                    <Grid item xs={12}>
                        {legalDocs.map((d, i) => 
                            <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                                <Checkbox 
                                    required 
                                    name={d.name}
                                    checked={d.checked} 
                                    onChange={handleCheckDoc} 
                                    color={orgColor.main}
                                />
                                <p>
                                    J'accepte{" "}
                                    <a 
                                        href={d.url} 
                                        target="_blank" 
                                        rel="noreferrer" 
                                        style={{ 
                                            textDecoration: "underline",
                                            color: orgColor.main
                                        }}
                                    >
                                        {d.name}
                                    </a>
                                </p>
                            </Box>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <p style={{ fontSize: 14, lineHeight: "17px", color: scssVar.greyDarker }}>
                        En cliquant sur suivant, vous allez recevoir un code de validation sur votre boîte mail, pensez à vérifier vos spams.
                        Ce code vous servira pour découvrir le résultat de votre estimation.
                    </p>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Step04