import { Grid, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import FieldControl from "../../../../common/components/FieldControl"
import { api, backendUrl } from "../../../../common/setupApi"
import { loadStripe } from "@stripe/stripe-js"
import { addAlert } from "../../../alerts/alertsSlice"
import { useDispatch } from "react-redux"
import { appName } from "../../../../common/utils"

const Email = ({ email, onChange }) => {
    const emptyEmail = !email.trim().length

    return (
        <FieldControl
            required
            label="Adresse email"
            placeholder="Adresse email"
            isSuccess={!emptyEmail}
            value={email}
            onChange={onChange}
            type="email"
            name="email"
            autoComplete="email"
        />
    )
}

const Password = ({ password, onChange }) => {
    const [visible, setVisible] = useState(false)
    const emptyPwd = !password.trim().length

    return (
        <FieldControl
            required
            label="Mot de passe"
            placeholder="Mot de passe"
            isSuccess={!emptyPwd}
            value={password}
            onChange={onChange}
            type="password"
            name="password"
            autoComplete="current-password"
            withVisibilityIcons
            visible={visible}
            onToggleVisibility={() => setVisible(!visible)}
        />
    )
}

const initialState = {
    email: "",
    password: "",
}

const SubForm = ({ setView, offreId, priceId, setErrorPriceId }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const [redirecting, setRedirecting] = useState(false)

    const { email, password } = state

    useEffect(() => {
        return () => {
            setRedirecting(false)
        }
    }, [])

    const handleChange = e => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!priceId) {
            setErrorPriceId(true)

            const el = document.getElementById("sub_choices")
            el.scrollIntoView({ behavior: "smooth" })

            return
        }

        const payload = {
            "username": email,
            "password": password,
            "price_id": priceId
        }

        setErrorPriceId(false)
        setRedirecting(true)

        return api
            .get("v1/stripe/config/")
            .then(res => res.data.publicKey)
            .then(async (publicKey) => {
                return api
                    .post(`v1/stripe/offre-speciale/souscription/${offreId}/`, payload)
                    .then(async (res) => {
                        const stripePromise = loadStripe(publicKey)
                        const sessionId = res.data.id
                        const stripe = await stripePromise

                        return stripe.redirectToCheckout({ sessionId })
                    })
                    .catch(err => {
                        setRedirecting(false)
                        try {
                            const errorMessage = err.response.data.detail
                            dispatch(addAlert(errorMessage, "error"))
        
                        } catch (unknownErrorException) {
                            const errorMessage = "Nos services sont en maintenance, veuillez réessayer ultérieurement."
                            dispatch(addAlert(errorMessage, "error"))
                        }
                    })
            })
            .catch(err => {
                setRedirecting(false)
                const errMsg = err.message || "Nos services sont en maintenance, veuillez réessayer ultérieurement."
                console.log(errMsg)
                dispatch(addAlert(errMsg, "error"))
            })
    }

    return (
        <Fragment>
            <Typography sx={{
                color: scssVar.primary,
                fontFamily: scssVar.fontFamily,
                mb: 3
            }}>
                Vous n'avez pas de compte {appName} ? 
                <span 
                    onClick={setView} 
                    style={{ 
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginLeft: 5
                    }}
                >
                    cliquez ici
                </span>
            </Typography>
            <form method="POST" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Email email={email} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <Password password={password} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            loading={redirecting}
                            disabled={redirecting}
                            sx={{
                                pl: 5,
                                pr: 5
                            }}
                        >
                            Suivant
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <a 
                            href={`${backendUrl}accounts/password-reset/`}
                            style={{
                                fontSize: "0.8em",
                                textDecoration: "underline"
                            }}
                            className="has-text-success"
                        >
                            J'ai oublié mon mot de passe
                        </a>
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    )
}

export default SubForm