import { Fragment } from "react"
import { useSelector } from "react-redux"
import useAuth from "../../../auth/hooks/useAuth"
import { numberWithSpaces, phoneWithSpaces } from "../../../../common/utils"
import { selectEstimation, selectHousing } from "../../../estimate/selectors"
import useBudgetUsage from "../../../estimate/hooks/useBudgetUsage"
import AdDescriptif from "../components/AdDescriptif"
import AdDescription from "../components/AdDescription"
import AdHeader from "../components/AdHeader"
import AdReleasedBy from "../components/AdReleasedBy"
import AdScalePerformance from "../components/AdScalePerformance"
import AdSectionTitle from "../components/AdSectionTitle"
import Margin from "../../components/Margin"
import Wrapper from "../../components/Wrapper"
import ERP from "../components/ERP"
import { selectOrg } from "../../../account/selectors"
import Photos from "../components/Photos"

const Footer = () => {
    const userColor = useSelector(state => state.account.org.color)
    const { user } = useAuth()
    const { firstName, lastName, email, phone } = user

    return (
        <div style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 152,
            background: userColor,
            color: "white",
            paddingLeft: "35px",
            paddingRight: "35px",
            paddingBottom: "35px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end"
        }}>
            <div>
                <p style={{
                    fontSize: 18,
                    fontWeight: 700
                }}>
                    {firstName} {lastName}
                </p>
                {phone && (
                    <Fragment>
                        <Margin m={20} />
                        <p style={{
                            fontSize: 40,
                            fontWeight: 700,
                            whiteSpace: "nowrap"
                        }}>
                            {phoneWithSpaces(phone)}
                        </p>
                    </Fragment>
                )}
            </div>

            <p style={{
                fontWeight: 500,
                fontSize: 26
            }}>
                {email}
            </p>
        </div>
    )
}

const AdPortrait = ({ scale }) => {
    const userColor = useSelector(selectOrg).color
    const typeVente = useSelector(selectHousing).type_vente
    const adReference = useSelector(selectHousing).ad_reference_id
    const chargesCoPro = useSelector(selectEstimation).charge_copro_an
    const coutGlobal = useSelector(selectEstimation).ad_total_value
    const { totalBudgetUsage } = useBudgetUsage(true)

    const typeVenteFormat = typeVente.toLowerCase() === "maison" ? "la maison" : "l'appartement"

    return (
        <Wrapper id="ad_portrait" scale={scale}>
            <AdHeader />

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "58%" }}>
                    <Photos docType="ad_portrait" />
                    <Margin m={15} />
                    <p style={{ fontSize: 18, fontWeight: 700, color: userColor }}>Réf. : #{adReference}</p>
                    <Margin m={15} />
                    <AdSectionTitle>Diagnostics</AdSectionTitle>
                    <Margin m={15} />
                    <div style={{ width: 400 }}>
                        <AdScalePerformance />
                    </div>
                </div>
                <div style={{ width: "39%" }}>
                    <AdSectionTitle>
                        A propos de {typeVenteFormat}
                    </AdSectionTitle>
                    <Margin m={15} />
                    <AdDescription />
                    <Margin m={15} />
                    <AdSectionTitle>
                        Descriptif
                    </AdSectionTitle>
                    <Margin m={13} />
                    <AdDescriptif />
                    <Margin m={13} />
                    <AdSectionTitle>
                        Budget total d’achat (indicatif)
                    </AdSectionTitle>
                    <Margin m={2} />
                    <p style={{
                        fontSize: 9.5
                    }}>
                        (avec frais de notaire, à faire préciser, hors crédit)
                    </p>
                    <Margin m={7} />
                    <p style={{
                        color: userColor,
                        fontSize: 30,
                        fontWeight: 700
                    }}>
                        {numberWithSpaces(coutGlobal)} €
                    </p>
                    <Margin m={22} />
                    <AdSectionTitle>
                        Budget d’usage annuel (indicatif)
                    </AdSectionTitle>
                    <Margin m={6} />
                    <p style={{
                        fontSize: 9.5,
                        lineHeight: "13px"
                    }}>
                        Taxe foncière, charges copro, coût eau,  coût entretien,
                        <br />coût énergie (élec, gaz, bois, fuel....).  Selon les résidants actuels
                    </p>
                    <Margin m={11} />
                    <p style={{
                        color: userColor,
                        fontSize: 30,
                        fontWeight: 700
                    }}>
                        {numberWithSpaces(totalBudgetUsage)} €
                    </p>
                    <p style={{
                        fontSize: 9.5,
                    }}>
                        Dont charges de copropriété : {numberWithSpaces(chargesCoPro)} €
                    </p>
                </div>
            </div>

            <AdReleasedBy />
            <ERP />
            <Footer />
        </Wrapper>
    )
}

export default AdPortrait