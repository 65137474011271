import { CircularProgress, IconButton } from "@mui/material"
import { scssVar } from "../../../app/scssVar"
import { ReactComponent as PohotoIcon } from "../../../common/assets/photo.svg"

const Badge = ({ component, onClick, loading, photoPreview }) => {
    return (
        <IconButton
            component={component}
            onClick={onClick}
            disabled={loading}
            sx={{
                position: "absolute",
                bottom: photoPreview ? -7 : -10,
                right: 15,
                boxShadow: scssVar.shadow2,
                WebkitBoxShadow: scssVar.shadow2,
                width: "26px",
                height: "26px",
                p: 0,
                bgcolor: "white",
                "&:hover": {
                    bgcolor: scssVar.greyLight
                }
            }}
        >
            {loading ? <CircularProgress size={17} sx={{ color: scssVar.primary }} /> : <PohotoIcon />}
        </IconButton>
    )
}

export default Badge