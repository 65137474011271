import { Box, List, ListItemButton, ListItemText, SwipeableDrawer } from "@mui/material"
import { useState } from "react"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import Logo from "../../../common/components/Logo"
import BottomBar from "../../pdfReader/components/BottomBar"
import Link from "../../pdfReader/components/Link"
import Reader from "../../pdfReader/components/Reader"
import Title from "../../pdfReader/components/Title"
import Zoom from "../../pdfReader/components/Zoom"
import usePdfDoc from "../../pdfReader/hooks/usePdfDoc"
import usePdfState from "../../pdfReader/hooks/usePdfState"
import AccountSection from "../components/AccountSection"

const Legal = () => {
    const getPdfDoc = usePdfDoc()
    const {
        pdfState,
        handleDocumentLoadSuccess,
        handlePageLoadSuccess,
        handleZoomIn,
        handleZoomOut
    } = usePdfState()
    const [openDoc, setOpenDoc] = useState(null)

    const handleClose = () => {
        setOpenDoc(null)
    }

    return (
        <AccountSection title="Légal">
            <List>
                <ListItemButton
                    onClick={() => setOpenDoc("cgu")}
                    sx={{
                        borderBottom: 1,
                        borderColor: scssVar.greyLight,
                    }}
                >
                    <ListItemText 
                        primary="Conditions générales d'utilisation" 
                        sx={{
                            "& .MuiTypography-root": {
                                fontFamily: scssVar.fontFamily
                            },
                            "& .MuiListItemText-primary": {
                                color: "black",
                            },
                        }}
                    />
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 24,
                            color: scssVar.greyDark
                        }}
                    ></i>
                </ListItemButton>
                <ListItemButton
                    onClick={() => setOpenDoc("cgv")}
                    sx={{
                        borderBottom: 1,
                        borderColor: scssVar.greyLight,
                    }}
                >
                    <ListItemText 
                        primary="Conditions générales de vente" 
                        sx={{
                            "& .MuiTypography-root": {
                                fontFamily: scssVar.fontFamily
                            },
                            "& .MuiListItemText-primary": {
                                color: "black",
                            },
                        }}
                    />
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 24,
                            color: scssVar.greyDark
                        }}
                    ></i>
                </ListItemButton>
                <ListItemButton
                    onClick={() => setOpenDoc("ppdp")}
                    sx={{
                        borderBottom: 1,
                        borderColor: scssVar.greyLight,
                    }}
                >
                    <ListItemText 
                        primary="Politique de protection de vos données personnelles" 
                        sx={{
                            "& .MuiTypography-root": {
                                fontFamily: scssVar.fontFamily
                            },
                            "& .MuiListItemText-primary": {
                                color: "black",
                            },
                        }}
                    />
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 24,
                            color: scssVar.greyDark
                        }}
                    ></i>
                </ListItemButton>
                <ListItemButton
                    onClick={() => setOpenDoc("ml")}
                    sx={{
                        borderBottom: 1,
                        borderColor: scssVar.greyLight,
                    }}
                >
                    <ListItemText 
                        primary="Mentions légales" 
                        sx={{
                            "& .MuiTypography-root": {
                                fontFamily: scssVar.fontFamily
                            },
                            "& .MuiListItemText-primary": {
                                color: "black",
                            },
                        }}
                    />
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 24,
                            color: scssVar.greyDark
                        }}
                    ></i>
                </ListItemButton>
            </List>
            <SwipeableDrawer
                open={Boolean(openDoc)}
                anchor="bottom"
                onClose={handleClose}
                onOpen={() => {}}
                disableSwipeToOpen
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: "white",
                        backgroundImage: "none",
                        width: "100%",
                        zIndex: 1301,
                    }
                }}
            >
                <BottomBar>
                    <Button
                        variant="light"
                        type="button"
                        onClick={handleClose}
                        sx={{
                            width: 150,
                        }}
                    >
                        Fermer
                    </Button>
                </BottomBar>
                <Zoom
                    onZoomIn={handleZoomIn}
                    onZoomOut={handleZoomOut}
                    scale={pdfState.scale}
                    loadingDoc={pdfState.loadingDoc}
                    loadingPage={pdfState.loadingPage}
                />
                <div
                    role="presentation" 
                    style={{
                        height: window.innerHeight,
                        overflowY: "scroll",
                        width: "100%",
                    }}
                >
                    <Box sx={{ 
                        pt: { xs: 1.8, sm: 8.15 }, 
                        pb: 21
                     }}>
                        <Box sx={{
                            maxWidth: "1341px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px",
                                sm: "0 35px",
                                lg: "0 60px"
                            }
                        }}>
                            <Logo sx={{ wrapper: { mb: 3 } }} />
                            <Title>{getPdfDoc(openDoc).title}</Title>
                            <Link href={getPdfDoc(openDoc).src} />
                        </Box>
                        <Reader
                            onDocumentLoadSuccess={handleDocumentLoadSuccess}
                            onPageLoadSuccess={handlePageLoadSuccess}
                            src={getPdfDoc(openDoc).src}
                            loadingPage={pdfState.loadingPage}
                            numberOfPages={pdfState.numPages}
                            scale={pdfState.scale}
                        />
                    </Box>
                </div>
            </SwipeableDrawer>
        </AccountSection>
    )
}

export default Legal