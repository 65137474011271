import { Fragment } from "react"
import AdsPdf from "../../../../pdfCreator/ads/AdsPdf"
import Publish from "./components/Publish"

const TabAds = () => {
    return (
        <Fragment>
            <Publish />
            <AdsPdf />
        </Fragment>
    )
}

export default TabAds