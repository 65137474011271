import { Grid } from "@mui/material"
import { Fragment } from "react"
import FieldControl from "../../../../common/components/FieldControl"
import { choices_etat_du_bien } from "../../../estimate/steps/components/Step04Form"
import { choices_luminosite } from "../../../estimate/steps/components/Step07Form"
import { choices_attrait } from "../../../estimate/steps/components/Step08Form"

const Step02 = ({
    estimation,
    onChange,
    onCheck,
    orgColor
}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FieldControl
                    label="Votre appréciation"
                    fieldClassName={`select is-fullwidth select-grey ${estimation.etat_du_bien && "is-success"}`}
                    labelClassName={`label has-text-weight-normal ${estimation.etat_du_bien && 'has-text-success'}`}
                    type="select"
                    value={estimation.etat_du_bien || ""}
                    onChange={onChange}
                    name="etat_du_bien"
                    options={
                        <Fragment>
                            <option value="">Sélectionner</option>
                            {choices_etat_du_bien.map((d, i) =>
                                <option
                                    key={i}
                                    value={d.value}
                                >
                                    {d.label}
                                </option>
                            )}
                        </Fragment>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FieldControl
                    label="Luminosité"
                    isSuccess={estimation.luminosite !== null}
                    type="checkbox"
                    choices={[...choices_luminosite].map(d => ({ ...d, colorChecked: orgColor.main, bgChecked: orgColor.light }))}
                    selectedValue={estimation.luminosite}
                    name="luminosite"
                    onChange={onCheck}
                    xs={12}
                    sm={3}
                />
            </Grid>
            <Grid item xs={12}>
                <FieldControl
                    label="Attrait général : bien et quartier"
                    isSuccess={estimation.attrait !== null}
                    type="checkbox"
                    choices={[...choices_attrait].map(d => ({ ...d, colorChecked: orgColor.main, bgChecked: orgColor.light }))}
                    selectedValue={estimation.attrait}
                    name="attrait"
                    onChange={onCheck}
                    xs={12}
                    sm={6}
                />
            </Grid>
        </Grid>
    )
}

export default Step02