import { Grid } from "@mui/material"
import { Fragment } from "react"
import FieldControl from "../../../../common/components/FieldControl"
import { choices_nb_chambres } from "../../../estimate/steps/components/Step01Form"

const Step01 = ({
    housing,
    estimation,
    onChangeHousingValue,
    onChangeEstimationValue,
    isFlat,
    orgColor
}) => {
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FieldControl
                        required
                        fieldClassName={`input input-grey ${housing.surface_reelle_bati > 0 && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${housing.surface_reelle_bati > 0 && 'has-text-success'}`}
                        label="Surface habitable en m²" 
                        onChange={e => {
                            const value = e.target.value
                            if (value < 0 || value === "0") return
                            onChangeHousingValue(e)
                        }}
                        value={housing.surface_reelle_bati || ""}
                        type="number" 
                        placeholder="Surface"
                        name="surface_reelle_bati"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FieldControl 
                        label="Surface du terrain en m²" 
                        toolTip="Surface du lot total bien inclu. Par exemple la surface du rez de chaussée + surface jardin, ou patio, ou veranda..."
                        tooltipStyle={{
                            "& .MuiTooltip-tooltip": {
                                bgcolor: orgColor.main
                            },
                            "& .MuiTooltip-arrow": {
                                color: orgColor.main
                            }
                        }}
                        fieldClassName={`input input-grey ${housing.surface_terrain_totale > 0 && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${housing.surface_terrain_totale > 0 && 'has-text-success'}`}
                        onChange={e => {
                            const value = e.target.value
                            if (value < 0 || value === "0") return
                            onChangeHousingValue(e)
                        }}
                        value={housing.surface_terrain_totale || ""}
                        type="number" 
                        placeholder="Surface"
                        name="surface_terrain_totale"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FieldControl 
                        required
                        toolTip="Une pièce peut être considérée comme telle à partir de 8m² de surface"
                        tooltipStyle={{
                            "& .MuiTooltip-tooltip": {
                                bgcolor: orgColor.main
                            },
                            "& .MuiTooltip-arrow": {
                                color: orgColor.main
                            }
                        }}
                        label="Nombre de pièces"
                        fieldClassName={`select is-fullwidth select-grey ${housing.nombre_pieces_principales && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${housing.nombre_pieces_principales && 'has-text-success'}`}
                        type="select"
                        value={housing.nombre_pieces_principales || ""}
                        onChange={onChangeHousingValue}
                        name="nombre_pieces_principales"
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                {[...Array(50).keys()].map((d, i) => 
                                    <option key={i}>
                                        {d + 1}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FieldControl 
                        label="Nombre de chambres"
                        fieldClassName={`select is-fullwidth select-grey ${estimation.nb_chambres && "is-success"}`}
                        labelClassName={`label has-text-weight-normal ${estimation.nb_chambres && 'has-text-success'}`}
                        type="select"
                        value={estimation.nb_chambres || ""}
                        onChange={onChangeEstimationValue}
                        name="nb_chambres"
                        options={
                            <Fragment>
                                <option value="">Sélectionner</option>
                                {choices_nb_chambres.map((d, i) => 
                                    <option 
                                        key={i} 
                                        value={d.value}
                                    >
                                        {d.label}
                                    </option>
                                )}
                            </Fragment>
                        }
                    />
                </Grid>
                    {isFlat && (
                        <Fragment>
                            <Grid item xs={12} sm={6}>
                                <FieldControl 
                                    toolTip="Indiquez à quel étage se situe l'appartement"
                                    tooltipStyle={{
                                        "& .MuiTooltip-tooltip": {
                                            bgcolor: orgColor.main
                                        },
                                        "& .MuiTooltip-arrow": {
                                            color: orgColor.main
                                        }
                                    }}
                                    label="Étage de l'appartement"
                                    fieldClassName={`select is-fullwidth select-grey ${estimation.etage_bien && "is-success"}`}
                                    labelClassName={`label has-text-weight-normal ${estimation.etage_bien && 'has-text-success'}`}
                                    type="select"
                                    value={estimation.etage_bien || ""}
                                    onChange={onChangeEstimationValue}
                                    name="etage_bien"
                                    options={
                                        <Fragment>
                                            <option value="">Sélectionner</option>
                                            {[...Array(50).keys()].map((d, i) => 
                                                <option 
                                                    key={i} 
                                                    value={d}
                                                    disabled={
                                                        estimation.etages_batiment !== undefined
                                                        && estimation.etages_batiment !== null
                                                        && estimation.etages_batiment !== ""
                                                        && d > estimation.etages_batiment
                                                    }
                                                >
                                                    {d === 0 ? "Rez-de-chaussée" : d}
                                                </option>
                                            )}
                                        </Fragment>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FieldControl 
                                    label="Nombre d'étages du bâtiment"
                                    fieldClassName={`select is-fullwidth select-grey ${estimation.etages_batiment && "is-success"}`}
                                    labelClassName={`label has-text-weight-normal ${estimation.etages_batiment && 'has-text-success'}`}
                                    type="select"
                                    value={estimation.etages_batiment || ""}
                                    onChange={onChangeEstimationValue}
                                    name="etages_batiment"
                                    options={
                                        <Fragment>
                                            <option value="">Sélectionner</option>
                                            {[...Array(50).keys()].map((d, i) => 
                                                <option 
                                                    key={i}
                                                    disabled={
                                                        estimation.etage_bien !== undefined
                                                        && estimation.etage_bien !== null
                                                        && estimation.etage_bien !== ""
                                                        && d < estimation.etage_bien
                                                    }
                                                >
                                                    {d}
                                                </option>
                                            )}
                                        </Fragment>
                                    }
                                />
                            </Grid>
                        </Fragment>
                    )}
            </Grid>
        </Fragment>
    )
}

export default Step01