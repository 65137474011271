import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import RenderTextWithLineBreak from "../../../../common/components/RenderTextWithLineBreak"
import { m2, numberWithSpaces } from "../../../../common/utils"
import { selectOrg } from "../../../account/selectors"
import useBudgetUsage from "../../../estimate/hooks/useBudgetUsage"
import Margin from "../../components/Margin"
import { selectAddress, selectEstimation, selectHousing } from "../../../estimate/selectors"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import InputCheckbox from "../../components/InputCheckbox"
import GreyBox from "../../components/GreyBox"
import InputNumber from "../../components/InputNumber"
import Nature from "./Nature"
import useSaveMandat from "../hooks/useSaveMandat"

export const NatureAndAddress = ({ readOnly }) => {
    const { rue, codePostal, commune } = useSelector(selectAddress)
    return (
        <Fragment>
             <div style={{ display: "flex" }}>
                <Nature readOnly={readOnly} />
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1
                }}>
                    <p style={{
                        fontSize: 14,
                        lineHeight: "14px",
                        marginRight: 3
                    }}>
                        Adresse :
                    </p>
                    <GreyBox style={{ flexGrow: 1, padding: "0 4px" }}>
                        {rue}
                    </GreyBox>
                </div>
            </div>

            <Margin m={3} />

            <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center",  }}>
                    <p style={{
                        fontSize: 14,
                        lineHeight: "14px",
                        marginRight: 3
                    }}>
                        Code postal :
                    </p>
                    <GreyBox style={{ 
                        width: 60,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {codePostal}
                    </GreyBox>
                </div>
                <div style={{ display: "flex", alignItems: "center", flexGrow: 1, marginLeft: 15 }}>
                    <p style={{
                        fontSize: 14,
                        lineHeight: "14px",
                        marginRight: 3,
                    }}>
                        Ville :
                    </p>
                    <GreyBox style={{ flexGrow: 1, padding: "0 4px" }}>
                        {commune}
                    </GreyBox>
                </div>
            </div>
        </Fragment>
    )
}

const HousingInfos = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const surfaceReelleBati = useSelector(selectHousing).surface_reelle_bati
    const surfaceTerrainTotale = useSelector(selectHousing).surface_terrain_totale
    const description = useSelector(selectEstimation).description_estimation
    const surfaceTerrasse = useSelector(selectEstimation).surface_terrasse
    const taxeFonciereAn = useSelector(selectEstimation).taxe_fonciere_an
    const chargeCoproAn = useSelector(selectEstimation).charge_copro_an
    const tantieme = useSelector(selectMandatEditor2).tantieme
    const nbLot = useSelector(selectMandatEditor2).nb_lot
    const plusValue = useSelector(selectMandatEditor2).plus_value
    const isFree = useSelector(selectMandatEditor2).libre_toute_occupation
    const delay = useSelector(selectMandatEditor2).libre_toute_occupation_delai
    const procedureEnCours = useSelector(selectMandatEditor2).copro_difficulte
    const { energie } = useBudgetUsage(true)
    const userColor = useSelector(selectOrg).color
    const backgroundColor = "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"

    const handleChange = (e) => {
        const { name, checked, type, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value: type === "checkbox" ? checked : value }))
        saveMandat(type === "checkbox" ? 0 : 1000)
    }

    return (
        <Fragment>
           <NatureAndAddress />

            <Margin m={3} />
            
            <div style={{ display: "flex" }}>
                <p style={{
                    fontSize: 14,
                    lineHeight: "14px",
                    minWidth: 163,
                    maxWidth: 163,
                    width: 163,
                }}>
                    Description succincte : 
                </p>
                <div style={{
                    background: backgroundColor,
                    maxHeight: 72,
                    overflow: "hidden",
                    flexGrow: 1,
                    color: "black",
                    padding: "3px"
                }}>
                    <RenderTextWithLineBreak 
                        text={description || ""} 
                        sx={{
                            fontSize: "11px",
                            color: "inherit",
                            lineHeight: "13px"
                        }}
                    />
                </div>
            </div>

            <Margin m={3} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Superficie déclarée :
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 50
                }}>
                    {surfaceReelleBati}
                </GreyBox> 
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                    marginRight: 25
                }}>
                    {m2}
                </p>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Surface de terrasse :
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 50
                }}>
                    {surfaceTerrasse || "ND"}
                </GreyBox>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                    marginRight: 25
                }}>
                    {m2}
                </p>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Surface de terrain :
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 50
                }}>
                    {surfaceTerrainTotale || "ND"}
                </GreyBox> 
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                }}>
                    {m2}
                </p>
            </div>

            <Margin m={3} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Nombre de tantièmes :
                </p>
                <div style={{ display: "flex", alignItems: "center", marginRight: 10 }}>
                    <InputNumber
                        required
                        name="tantieme"
                        value={tantieme || ""}
                        onChange={handleChange}
                        style={{
                            width: 55,
                            fontSize: 11
                        }}
                    />
                    <p style={{ margin: "0 3px", fontSize: 11 }}>
                        /
                    </p>
                    <InputNumber
                        required
                        name="nb_lot"
                        value={nbLot || ""}
                        onChange={handleChange}
                        style={{
                            width: 55,
                            fontSize: 11
                        }}
                    />
                </div>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Charges annuelles de copropriété :
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 54,
                    fontSize: 11
                }}>
                    {numberWithSpaces(chargeCoproAn)}
                </GreyBox>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                    marginRight: 15
                }}>
                    €
                </p>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Charges énergies :
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 54,
                    fontSize: 11
                }}>
                    {numberWithSpaces(energie.value)}
                </GreyBox>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                    marginRight: 15
                }}>
                    €
                </p>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                }}>
                    Taxe foncière :
                </p>
                <GreyBox style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 54,
                    fontSize: 11
                }}>
                    {numberWithSpaces(taxeFonciereAn)}
                </GreyBox>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                }}>
                    €
                </p>
            </div>

            <Margin m={3} />

            <div style={{ display: "flex", alignItems: "center" }}>
                <InputCheckbox 
                    name="plus_value" 
                    checked={plusValue || ""} 
                    onChange={handleChange} 
                />
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                    marginRight: 20
                }}>
                    Soumis à la plus-value
                </p>
                <InputCheckbox 
                    checked={isFree === true} 
                    onChange={() => {
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "libre_toute_occupation", value: isFree === true ? "" : true }))
                        dispatch(mandatActions.setField({ name: "libre_toute_occupation_delai", value: "" }))
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 20,
                    marginLeft: 3,
                }}>
                    Libre de toute occupation
                </p>
                <InputCheckbox 
                    checked={isFree === false} 
                    onChange={() => {
                        const checked = isFree === false
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "libre_toute_occupation", value: checked ? "" : false }))
                        if (checked) {
                            dispatch(mandatActions.setField({ name: "libre_toute_occupation_delai", value: "" }))
                        }
                        saveMandat(0)
                    }} 
                />
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 20,
                    marginLeft: 3,
                }}>
                    Occupé/loué
                </p>
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginRight: 3,
                    marginLeft: 20,
                }}>
                    Libre dans :
                </p>
                <InputNumber
                    value={delay || ""}
                    onChange={e => {
                        clearTimeout()
                        dispatch(mandatActions.setField({ name: "libre_toute_occupation_delai", value: e.target.value }))
                        dispatch(mandatActions.setField({ name: "libre_toute_occupation", value: false }))
                        saveMandat(1000)
                    }}
                    style={{
                        width: 35,
                        fontSize: 11
                    }}
                />
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                    marginRight: 20
                }}>
                    mois
                </p>
                <InputCheckbox 
                    name="copro_difficulte" 
                    checked={procedureEnCours || ""} 
                    onChange={handleChange} 
                />
                <p style={{
                    fontSize: 11,
                    lineHeight: "11px",
                    marginLeft: 3,
                }}>
                    Procédure en cours
                </p>
            </div>
        </Fragment>
    )
}

export default HousingInfos