import { List } from "@mui/material"
import { useSelector } from "react-redux"
import AccountSection from "../components/AccountSection"
import CancelSub from "../components/CancelSub"
import CustomerPortal from "../components/CustomerPortal"
import InvoicingDay from "../components/InvoicingDay"

const Sub = () => {
    const loading = useSelector(state => state.auth.user.loading)
    const error = useSelector(state => state.auth.user.error)

    return (
        <AccountSection
            title="Abonnement"
            loading={loading}
            error={error?.data ? "Une erreur est survenue lors de la récupération de votre profil, veuillez réessayer." : null}
        >
            <List sx={{ p: 0 }}>
                <CustomerPortal />
                <InvoicingDay />
                <CancelSub />
            </List>
        </AccountSection>
    )
}

export default Sub