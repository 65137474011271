import { useSelector } from "react-redux"
import { numberWithSpaces } from "../../../../common/utils"
import useChargesAnnuellesItems from "../../../estimate/hooks/useChargesAnnuellesItems"
import useEnergieItems from "../../../estimate/hooks/useEnergieItems"
import Margin from "../../components/Margin"
import SectionTitle from "../components/SectionTitle"
import Wrapper from "../../components/Wrapper"

const ListItem = ({ index, children, style }) => {
    const userColor = useSelector(state => state.account.org.color)

    return (
        <div 
            style={Object.assign({
                background: 
                    !(index % 2) 
                        ? "rgba" + userColor.substring(3, userColor.length - 1) + ", 0.1)"
                        : "none",
                marginBottom: 3
            }, style)}
        >
            {children}
        </div>
    )
}

const Page05 = ({ scale }) => {
    const chargesAnnuellesItems = useChargesAnnuellesItems()
    const energieItems = useEnergieItems()
    const estimation = useSelector(state => state.estimate.estimation)

    return (
        <Wrapper id="synthesis_p5" scale={scale}>

            <Margin m={65} />
            
            <SectionTitle>
                Budget d’usage et budget total
            </SectionTitle>

            <Margin m={50} />

            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div style={{ width: "46%" }}>
                    <p style={{ fontWeight: 600 }}>
                        Budget d’usage annuel estimé 
                    </p>
                    <Margin m={20} />
                    <p>
                        Charges
                    </p>
                    <Margin m={3} />
                    {chargesAnnuellesItems
                        .filter(d => d.displayInPdf)
                        .map((d, i) =>
                            <ListItem key={i} index={i} style={{ paddingLeft: 80 }}>
                                <div style={{
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <div style={{ width: 190 }}>
                                        {d.label} 
                                    </div>
                                    <div>
                                        {d.value !== null && d.value !== "" ? ( 
                                            <b>
                                                {d.unit === "€" ? (
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {numberWithSpaces(d.value)} {d.unit}
                                                    </span>
                                                ): d.value}
                                            </b> 
                                        ):(
                                            <b style={{ whiteSpace: "nowrap" }}>
                                                {d.nd}
                                            </b>
                                        )}
                                    </div>
                                </div>
                            </ListItem>
                        )
                    }
                    <Margin m={20} />
                    <p>
                        Énergie
                    </p>
                    <Margin m={3} />
                    {energieItems.map((d, i) => 
                        <ListItem key={i} index={i} style={{ paddingLeft: 80 }}>
                            <div style={{
                                width: "100%",
                                display: "flex"
                            }}>
                                <div style={{ width: 190 }}>
                                    {d.labelInPdf || d.label} 
                                </div>
                                <div>
                                    {d.value !== null && d.value !== "" ? (
                                        <b>
                                            {d.unit === "€" ? (
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {numberWithSpaces(d.value)} {d.unit}
                                                </span>
                                            ) : d.value}
                                        </b> 
                                    ):(
                                        <b style={{ whiteSpace: "nowrap" }}>
                                            {d.nd}
                                        </b>
                                    )}
                                </div>
                            </div>
                        </ListItem>
                    )}
                </div>
                <div style={{ width: "46%" }}>
                    <p style={{ fontWeight: 600 }}>
                        Budget total
                    </p>
                    <Margin m={20} />
                    <p>
                        Frais de transaction
                    </p>
                    <Margin m={3} />
                    <ListItem index={0} style={{ paddingLeft: 3 }}>
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <div style={{ width: 180 }}>
                                Total frais
                            </div>
                            <div>
                                {estimation.frais_agence_absolu !== null && estimation.frais_agence_absolu !== ""
                                    && estimation.frais_notaire_absolu !== null && estimation.frais_notaire_absolu !== ""
                                    ? (
                                        <b style={{ whiteSpace: "nowrap" }}>
                                            {numberWithSpaces(estimation.frais_agence_absolu + estimation.frais_notaire_absolu)} €
                                        </b>
                                    ):(
                                        <b style={{ whiteSpace: "nowrap" }}>
                                            Non définis
                                        </b>
                                    )
                                }
                            </div>
                        </div>
                    </ListItem>
                    <ListItem index={1} style={{ paddingLeft: 40 }}>
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <div style={{ width: 143 }}>
                                Frais d’agence 
                            </div>
                            <div>
                                {estimation.frais_agence_absolu !== null && estimation.frais_agence_absolu !== "" ? (
                                    <b style={{ whiteSpace: "nowrap" }}>
                                        {numberWithSpaces(estimation.frais_agence_absolu)} €
                                    </b>
                                ):(
                                    <b style={{ whiteSpace: "nowrap" }}>
                                        Non définis
                                    </b>
                                )}
                            </div>
                        </div>
                    </ListItem>
                    <ListItem index={2} style={{ paddingLeft: 40 }}>
                        <div style={{
                            display: "flex",
                            width: "100%",
                        }}>
                            <div style={{ width: 143 }}>
                                Frais de notaire
                            </div>
                            <div>
                                {estimation.frais_notaire_absolu !== null && estimation.frais_notaire_absolu !== "" ? (
                                    <b style={{ whiteSpace: "nowrap" }}>
                                        {numberWithSpaces(estimation.frais_notaire_absolu)} €
                                    </b>
                                ):(
                                    <b style={{ whiteSpace: "nowrap" }}>
                                        Non définis
                                    </b>
                                )}
                            </div>
                        </div>
                    </ListItem>
                </div>
            </div>

            <Margin m={65} />

            <SectionTitle>
                Explication du budget d’usage
            </SectionTitle>

            <Margin m={50} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                Nous vous invitons à aborder le coût d’usage en collectant les données générales liées à la consommation, charges, énergie, taxe, 
                travaux à prévoir et entretien, le tout ayant une incidence sur le reste à vivre. La transparence est primordiale pour attirer vos futurs acquéreurs.
            </p>

            <Margin m={10} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                Cette information leur servira à analyser l’amortissement des frais d’achat (frais de notaire et travaux d’installation) pour maîtriser leur pouvoir 
                d’achat, en lien avec les dépenses liées relatives au logement.
            </p>

            <Margin m={10} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                De leur côté, les vendeurs disposent d’une meilleure lecture du prix de vente face aux biens concurrents sur le marché. Ce qui leur permet de mettre en 
                place une stratégie de vente plus efficace.
            </p>

            <Margin m={10} />

            <p style={{ fontSize: 14.675, lineHeight: "20px" }}>
                Grâce à ces données, vous réduisez le risque de taux de chute des offres d’achat et vous faites gagner du temps à chacun.
            </p>
        </Wrapper>
    )
}

export default Page05