import { useMemo } from "react"
import { useSelector } from "react-redux"

const useVieDeQuartierItems = () => {
    const estimation = useSelector(state => state.estimate.estimation)

    const items = useMemo(() => {
        return [
            {
                name: "proche_boutique",
                checked: estimation.proche_boutique,
                text: "Boutique",
                icon: "iconoir-shop",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M20.485 3H16.4933L16.9933 8C16.9933 8 17.9933 9 19.4933 9C20.5703 9 21.3036 8.48445 21.6316 8.1937C21.7623 8.07782 21.8101 7.90091 21.7814 7.72861L21.0768 3.50136C21.0286 3.21205 20.7783 3 20.485 3Z" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M16.4933 3L16.9933 8C16.9933 8 15.9933 9 14.4933 9C12.9933 9 11.9933 8 11.9933 8V3H16.4933Z" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M11.9933 3V8C11.9933 8 10.9933 9 9.49329 9C7.99329 9 6.99329 8 6.99329 8L7.49329 3H11.9933Z" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M7.49331 3H3.50158C3.20828 3 2.95797 3.21205 2.90975 3.50136L2.2052 7.72862C2.17649 7.90091 2.22432 8.07782 2.35502 8.1937C2.68294 8.48445 3.41626 9 4.49329 9C5.99329 9 6.99331 8 6.99331 8L7.49331 3Z" stroke="currentColor" strokeWidth="1.5"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_ecole",
                checked: estimation.proche_ecole,
                text: "École",
                icon: "iconoir-building",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 9.01L10.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 9.01L14.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 13.01L10.01 12.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 13.01L14.01 12.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 17.01L10.01 16.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 17.01L14.01 16.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 20.4V5.6C6 5.26863 6.26863 5 6.6 5H12V3.6C12 3.26863 12.2686 3 12.6 3H17.4C17.7314 3 18 3.26863 18 3.6V20.4C18 20.7314 17.7314 21 17.4 21H6.6C6.26863 21 6 20.7314 6 20.4Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_parc",
                checked: estimation.proche_parc,
                text: "Parc",
                icon: "iconoir-sun-light",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22 12L23 12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 2V1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 23V22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20 20L19 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20 4L19 5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 20L5 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 4L5 5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 12L2 12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_transports",
                checked: estimation.proche_transports,
                text: "Transports",
                icon: "iconoir-bus-outline",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 16.01L7.01 15.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M17 16.01L17.01 15.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 12H21V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21 8V6C21 3.79086 19.2091 2 17 2H7C4.79086 2 3 3.79086 3 6V8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 8L17 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 20H8V22C8 22.5523 7.55228 23 7 23H5C4.44772 23 4 22.5523 4 22V20Z" fill="currentColor"/>
                        <path d="M16 20H20V22C20 22.5523 19.5523 23 19 23H17C16.4477 23 16 22.5523 16 22V20Z" fill="currentColor"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_loisirs",
                checked: estimation.proche_loisirs,
                text: "Loisirs",
                icon: "iconoir-golf",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 18L12 12M12 12V3.40992C12 2.97917 12.4404 2.68876 12.8364 2.85844L21.2803 6.47729C21.7549 6.68065 21.7681 7.34854 21.302 7.5705L12 12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 22C15.866 22 19 20.433 19 18.5C19 16.567 15.866 15 12 15C8.13401 15 5 16.567 5 18.5C5 20.433 8.13401 22 12 22Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_restauration",
                checked: estimation.proche_restauration,
                text: "Restauration",
                icon: "iconoir-pizza-slice",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 9.01L14.01 8.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 8.01L8.01 7.99889" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 14.01L8.01 13.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 19L2.23626 3.0041C2.13087 2.55618 2.54815 2.16122 2.98961 2.29106L19 7" stroke="currentColor" strokeWidth="1.5"/>
                        <path d="M22.198 8.42467C22.4324 7.48703 21.8623 6.5369 20.9247 6.30249C19.987 6.06808 19.0369 6.63816 18.8025 7.5758C18.4106 9.14318 16.9015 11.6241 14.5753 13.9503C12.2743 16.2513 9.42714 18.1442 6.60672 18.7951C5.66497 19.0124 5.07771 19.952 5.29504 20.8937C5.51236 21.8355 6.45198 22.4227 7.39373 22.2054C11.0734 21.3563 14.4762 18.9991 17.0502 16.4252C19.5989 13.8764 21.5898 10.8573 22.198 8.42467Z" stroke="currentColor" strokeLinecap="round"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_parking",
                checked: estimation.proche_parking,
                text: "Parking",
                icon: "iconoir-parking",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 15.5V12.7M10 12.7C10.4762 12.7 11.7143 12.7 12.8571 12.7C13.5714 12.7 15 12.7 15 10.6C15 8.5 13.5714 8.5 12.8571 8.5L10 8.5V12.7Z" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round"/>
                        <circle cx="12" cy="12" r="10" stroke="currentColor" />
                    </svg>,
                displayed: true
            },
            {
                name: "proche_sport",
                checked: estimation.proche_sport,
                text: "Activité sportive",
                icon: "iconoir-running",
                iconSvg: 
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 7C16.1046 7 17 6.10457 17 5C17 3.89543 16.1046 3 15 3C13.8954 3 13 3.89543 13 5C13 6.10457 13.8954 7 15 7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.6133 8.26691L9.30505 12.4021L13.4403 16.5374L11.3727 21.0861" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.4104 9.5075L9.79728 6.19931L12.6132 8.26692L15.508 11.5752H19.2297" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.89152 15.7103L7.65095 16.5374H4.34277" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true
            },
            {
                name: "proche_bibliotheque",
                checked: estimation.proche_bibliotheque,
                text: "Bibliothèque",
                icon: "iconoir-journal",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 10H18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 14L18 14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 18L18 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M2 21.4V2.6C2 2.26863 2.26863 2 2.6 2H21.4C21.7314 2 22 2.26863 22 2.6V21.4C22 21.7314 21.7314 22 21.4 22H2.6C2.26863 22 2 21.7314 2 21.4Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 18V14H8V18H6Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true
            },
            {
                name: "ecoquartier",
                checked: estimation.ecoquartier,
                text: "Éco-quartier",
                icon: "iconoir-donate",
                iconSvg:
                    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 6.27975C16 6.88118 15.7625 7.45883 15.3383 7.88611C14.3619 8.87007 13.415 9.89605 12.4021 10.8443C12.17 11.0585 11.8017 11.0507 11.5795 10.8268L8.6615 7.88611C7.7795 6.99725 7.7795 5.56225 8.6615 4.67339C9.55218 3.77579 11.0032 3.77579 11.8938 4.67339L11.9999 4.78027L12.1059 4.67345C12.533 4.24286 13.1146 4 13.7221 4C14.3297 4 14.9113 4.24284 15.3383 4.67339C15.7625 5.10073 16 5.67835 16 6.27975Z" stroke="currentColor" strokeLinejoin="round"/>
                        <path d="M18 20L21.8243 16.1757C21.9368 16.0632 22 15.9106 22 15.7515V10.5C22 9.67157 21.3284 9 20.5 9V9C19.6716 9 19 9.67157 19 10.5V15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18 16L18.8581 15.1419C18.949 15.051 19 14.9278 19 14.7994V14.7994C19 14.6159 18.8963 14.4482 18.7322 14.3661L18.2893 14.1447C17.5194 13.7597 16.5894 13.9106 15.9807 14.5193L15.0858 15.4142C14.7107 15.7893 14.5 16.298 14.5 16.8284V20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 20L2.17574 16.1757C2.06321 16.0632 2 15.9106 2 15.7515V10.5C2 9.67157 2.67157 9 3.5 9V9C4.32843 9 5 9.67157 5 10.5V15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 16L5.14187 15.1419C5.05103 15.051 5 14.9278 5 14.7994V14.7994C5 14.6159 5.10366 14.4482 5.26776 14.3661L5.71067 14.1447C6.48064 13.7597 7.41059 13.9106 8.01931 14.5193L8.91421 15.4142C9.28929 15.7893 9.5 16.298 9.5 16.8284V20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>,
                displayed: true
            },
        ]
    }, [estimation])

    return items
}

export default useVieDeQuartierItems