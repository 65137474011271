import { CircularProgress, Grid } from "@mui/material"
import PhotoUploader from "../PhotoUploader"
import { api } from "../../../common/setupApi"
import { useEffect, useState } from "react"
import { photosActions } from "../photosSlice"
import { updateHousing } from "../../estimate/actions"
import { addAlert } from "../../alerts/alertsSlice"
import { defaultErrorMessage } from "../../../common/utils"
import { useDispatch, useSelector } from "react-redux"
import { selectHousing } from "../../estimate/selectors"
import { scssVar } from "../../../app/scssVar"

const Photo = ({ photo, readOnly }) => {
    const dispatch = useDispatch()
    const housing = useSelector(selectHousing)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const housingId = housing.id

    useEffect(() => {
        return () => {
            setDeleteLoading(false)
        }
    }, [])

    const handleDelete = async () => {
        setDeleteLoading(true)
        return api
            .delete(`v1/housing/delete-photos/${housingId}/${photo.id}/`)
            .then(res => {
                dispatch(photosActions.deleteGalleryBlobSucceeded({ housingId, id: photo.id }))
                dispatch(updateHousing({ ...housing, photos_2: res.data }))
                dispatch(addAlert("La photo a bien été supprimée", "success"))
                setDeleteLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert(defaultErrorMessage, "error"))
                setDeleteLoading(false)
            })
    }

    if (readOnly) {
        return <img src={photo.src} alt={photo.id} style={{ width: "100%" }} />
    }

    return (
        <PhotoUploader
            noUpdate
            onDelete={handleDelete}
            loadingBlob={photo.loading}
            deleteLoading={deleteLoading}
            blob={photo.src}
            ButtonEmpty={({ loadingBlob }) => {
                if (loadingBlob) {
                    return (
                        <div style={{ width: "100%", height: "100%", background: "#ececec", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress size={15} sx={{ color: scssVar.primary }} />
                        </div>
                    )
                }
            }}
            ButtonBlob={({ handleOpenMenu, newPhotoLoading, loadingBlob, blob, deleteLoading }) => {
                return (
                    <div style={{ position: "relative" }}>
                        {deleteLoading && (
                            <CircularProgress 
                                size={25} 
                                sx={{ 
                                    color: scssVar.primary,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    margin: "auto"
                                }}
                            />
                        )}
                        <img
                            src={photo.src}
                            onClick={(e) => {
                                if (deleteLoading) return
                                handleOpenMenu(e)
                            }}
                            alt={photo.id}
                            style={{
                                cursor: deleteLoading ? "not-allowed" : "pointer",
                                opacity: deleteLoading ? .5 : 1,
                                width: "100%",
                            }}
                        />
                    </div>
                )
            }}
        />
    )
}

const GalleryLayout = ({ pics, readOnly, docType }) => {
    const existing = pics //.filter(d => d.src !== null)

    const found = (aspect) => existing.find(d => d.aspect === aspect)
    const filter = (aspect) => existing.filter(d => d.aspect === aspect).length

    // console.log(docType)

    if (existing.length === 1) {
        const a = existing[0]
        // console.log(a.aspect)

        if (a.aspect === "3:2") {
            if (docType === "ad_portrait") {
                return <Grid item xs={10.4}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            if (docType === "synthesis") {
                return <Grid item xs={5.5}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            return <Grid item xs={12}><Photo photo={a} readOnly={readOnly} /></Grid>
        }

        if (a.aspect === "3:4") {
            if (docType === "ad_portrait") {
                return <Grid item xs={5.2}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            if (docType === "ad_landscape") {
                return <Grid item xs={6}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            if (docType === "synthesis") {
                return <Grid item xs={2.79}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            return <Grid item xs={6}><Photo photo={a} readOnly={readOnly} /></Grid>
        }

        if (a.aspect === "1:1") {
            if (docType === "ad_portrait") {
                return <Grid item xs={6.75}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            if (docType === "ad_landscape") {
                return <Grid item xs={8}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            if (docType === "synthesis") {
                return <Grid item xs={3.7}><Photo photo={a} readOnly={readOnly} /></Grid>
            }

            return <Grid item xs={6}><Photo photo={a} readOnly={readOnly} /></Grid>
        }
    }

    if (existing.length === 2) {
        const a = existing[0]
        const b = existing[1]
        // console.log(a.aspect, b.aspect)

        if (a.aspect === b.aspect) {
            const aspect = a.aspect

            if (aspect === "3:4") {
                if (docType === "ad_portrait") {
                    return existing.map((d, i) => <Grid key={i} item xs={5}><Photo photo={d} readOnly={readOnly} /></Grid>)
                }

                if (docType === "ad_landscape") {
                    return existing.map((d, i) => <Grid key={i} item xs={6}><Photo photo={d} readOnly={readOnly} /></Grid>)
                }

                if (docType === "synthesis") {
                    return existing.map((d, i) => <Grid key={i} item xs={7.21/2}><Photo photo={d} readOnly={readOnly} /></Grid>)
                }

                return existing.map((d, i) => <Grid key={i} item xs={6}><Photo photo={d} readOnly={readOnly} /></Grid>)
            }

            if (aspect === "1:1") {
                if (docType === "synthesis") {
                    return existing.map((d, i) => <Grid key={i} item xs={8.705/2}><Photo photo={d} readOnly={readOnly} /></Grid>)
                }

                return existing.map((d, i) => <Grid key={i} item xs={6}><Photo photo={d} readOnly={readOnly} /></Grid>)
            }

            // Si aspect === "3:2"
            // Si les aspects ne sont pas attribués (par oubli)
            return existing.map((d, i) => <Grid key={i} item xs={6}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (found("3:2") && found("3:4")) {
            if (docType === "synthesis") {
                const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 5.5 : 2.79 }))
                return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
            }

            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 7.98 : 4.02 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (found("3:2") && found("1:1")) {
            if (docType === "synthesis") {
                const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 5.5 : 3.7 }))
                return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
            }

            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 7.19 : 4.81 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (found("3:4") && found("1:1")) {
            if (docType === "synthesis") {
                const res = existing.map(d => ({ ...d, xs: d.aspect === "3:4" ? 2.79 : 3.7 }))
                return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
            }

            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:4" ? 5.155 : 6.845 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        // Si les aspects ne sont pas attribués (par oubli)
        return existing.map((d, i) => <Grid key={i} item xs={6}><Photo photo={d} readOnly={readOnly} /></Grid>)
    }

    if (existing.length === 3) {
        // console.log(existing[0].aspect, existing[1].aspect, existing[2].aspect)

        if (found("3:2") && found("3:4") && found("1:1")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 5.5 : d.aspect === "3:4" ? 2.79 : 3.7 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("3:2") === 2 && found("1:1")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 8.99/2 : 3.01 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("3:2") === 2 && found("3:4")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:2" ? 9.57/2 : 2.43 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("3:4") === 2 && found("1:1")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:4" ? 7.21/2 : 4.79 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("3:4") === 2 && found("3:2")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "3:4" ? 6.02/2 : 5.98 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("1:1") === 2 && found("3:2")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "1:1" ? 6.87/2 : 5.13 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("1:1") === 2 && found("3:4")) {
            const res = existing.map(d => ({ ...d, xs: d.aspect === "1:1" ? 8.705/2 : 3.29 }))
            return res.map((d, i) => <Grid key={i} item xs={d.xs}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        if (filter("3:2") === 3 || filter("3:4") === 3 || filter("1:1") === 3) {
            return existing.map((d, i) => <Grid key={i} item xs={4}><Photo photo={d} readOnly={readOnly} /></Grid>)
        }

        // Si les aspects ne sont pas attribués (par oubli)
        return existing.map((d, i) => <Grid key={i} item xs={4}><Photo photo={d} readOnly={readOnly} /></Grid>)
    }

    return null
}

export default GalleryLayout