import { Box, Divider } from "@mui/material"
import { Fragment } from "react"
import Code from "./components/Code"
import Preview from "./components/Preview"
import { selectOrg } from "../../account/selectors"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import LoaderGif from "../../../common/components/LoaderGif"

const TabIntegration = () => {
    const org = useSelector(selectOrg)

    if (org.loading) {
        return <LoaderGif />
    }

    if (org.error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer</p>
    }

    return (
        <Fragment>
            <Code />
            <Box sx={{ m: 3 }} />
            <Divider />
            <Box sx={{ m: 3 }} />
            <Preview />
        </Fragment>
    )
}

export default TabIntegration