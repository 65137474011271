import { Box, CircularProgress, Typography } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import FunnelHorizontal from "../../../../common/components/dashboard/funnel/FunnelHorizontal"
import FunnelVertical from "../../../../common/components/dashboard/funnel/FunnelVertical"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { selectApiKpis, selectApiKpisFunnelData } from "../../apiOfferSlice"

const Funnel = () => {
    const data = useSelector(selectApiKpisFunnelData)
    const { isLoading } = useSelector(selectApiKpis)

    return (
        <Fragment>
            <Box sx={{
                bgcolor: scssVar.greyLighter, 
                borderRadius: "12px",
                "@media (max-width: 899px)": {
                    display: "none"
                }
            }}>
                <Typography sx={{ fontSize: 14, p: 2, fontFamily: scssVar.fontFamily, mb: 1 }}>
                    Parcours des utilisateurs
                </Typography>
                {isLoading ? (
                    <CircularProgress size={20} sx={{ color: scssVar.primary, ml: 2, mb: 2 }} />
                ):(
                    <FunnelHorizontal data={data} />
                )}
            </Box>
            <Box sx={{
                display: "none",
                bgcolor: scssVar.greyLighter, 
                borderRadius: "12px",
                width: "100%",
                maxWidth: 600,
                "@media (max-width: 899px)": {
                    display: "block"
                }
            }}>
                <Typography sx={{ fontSize: 14, p: 2, fontFamily: scssVar.fontFamily }}>
                    Parcours des utilisateurs
                </Typography>
                {isLoading ? (
                    <CircularProgress size={20} sx={{ color: scssVar.primary, ml: 2, mb: 2 }} />
                ):(
                    <FunnelVertical data={data} />
                )}
            </Box>
        </Fragment>
    )
}

export default Funnel