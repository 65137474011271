import DesktopNav from "../../common/components/nav/DesktopNav"
import PageWrapper from "../../common/components/PageWrapper"
import MobileNav from "../../common/components/nav/MobileNav"
import SubApiOffer from "./SubApiOffer";
import useAuth from "../auth/hooks/useAuth";
import NoMatch from "../../common/components/NoMatch";
import { isProd } from "../../common/setupApi";

const ApiOffer = () => {
    const { user } = useAuth()
    // si jamais le back n'a pas autorisé (pour le staging)
    const isSubToApiOffer = isProd ? user.api_access : true

    if (!isSubToApiOffer) {
        return <NoMatch />
    }

    return (
        <PageWrapper>
            <MobileNav />
            <DesktopNav />
            <SubApiOffer />
        </PageWrapper>
    )
}

export default ApiOffer