import Step01Form from "../steps/components/Step01Form"
import Step04Form from "../steps/components/Step04Form"
import Step05Form from "../steps/components/Step05Form"
import Step07Form from "../steps/components/Step07Form"
import Step08Form from "../steps/components/Step08Form"
import Step11Form from "../steps/components/Step11Form"
import Step12Form from "../steps/components/Step12Form"

const STEPS = {
    "Step1": 3, // Quand on est à l'étape 1, on affiche le pourcentage de l'étape précédente (Home)

    "Step4": 12,

    "Step5": 18,
    "Step6": 18,

    "Step7": 48,

    "Step8": 54,
    "Step9": 54,
    "Step10": 54,

    "Step11": 75,

    "Step12": 90,
    "Step13": 90,
    "Step14": 90,
}

const useStep = () => {

    const getStep = (step) => {
        switch (step) {
            default:
                return { 
                    path: "step-1", 
                    title: "Surfaces et pièces", 
                    subTitle: "", 
                    progress: STEPS["Step1"], 
                    Component: Step01Form 
                }
                
            case "Step1":
                return { 
                    path: "step-1", 
                    title: "Surfaces et pièces", 
                    subTitle: "", 
                    progress: STEPS[step], 
                    Component: Step01Form 
                }

            case "Step4":
                return { 
                    path: "step-4", 
                    title: "Etat du bien", 
                    subTitle: "", 
                    progress: STEPS[step], 
                    Component: Step04Form 
                }

            case "Step5":
            case "Step6":
                return { 
                    path: "step-5", 
                    title: "Caractéristiques", 
                    subTitle: "", 
                    progress: STEPS[step], 
                    Component: Step05Form 
                }

            case "Step7":
                return { 
                    path: "step-7", 
                    title: "Intérieur et décoration",
                    subTitle: "", 
                    progress: STEPS[step], 
                    Component: Step07Form 
                }

            case "Step8":
            case "Step9":
            case "Step10":
                return { 
                    path: "step-8", 
                    title: "Environnement et services",
                    subTitle: "", 
                    progress: STEPS[step], 
                    Component: Step08Form 
                }

            case "Step11":
                return { 
                    path: "step-11",
                    title: "Dépassez la notion du prix au m²", 
                    subTitle: "Appréciez le coût d’usage via l’impact des charges", 
                    progress: STEPS[step], 
                    Component: Step11Form 
                }

            case "Step12":
            case "Step13":
            case "Step14":
                return { 
                    path: "step-12", 
                    title: "Dépassez la notion du prix au m²", 
                    subTitle: "Appréciez le coût d’usage via l’impact des charges", 
                    progress: STEPS[step], 
                    Component: Step12Form 
                }

            case "Result":
                return { 
                    path: "resultat",
                    progress: 75
                }

            case "ResultFinal":
                return { 
                    path: "resultat-final",
                    progress: 100
                }

            case "Synthesis":
                return { 
                    path: "synthese",
                    progress: 100
                }
        }
    }

    return getStep
}

export default useStep