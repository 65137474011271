import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectAvenantEditor } from "../mandatSlice"
import InputText from "../../components/InputText"
import { SignatureMandant, SignatureMandataire } from "./Signature"
import InputDate from "../../components/InputDate"
import useSaveMandat from "../hooks/useSaveMandat"

const AvenantSignature = ({ id, readOnly }) => {
    const dispatch = useDispatch()
    const editor = useSelector(state => selectAvenantEditor(state, id))
    const { clearTimeout, saveAvenant } = useSaveMandat()

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setAvenant({ id, name, value }))
        saveAvenant(id, 1000)
    }

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "60%" }}>
                <div style={{ display: "flex", alignItems: "center", paddingRight: "10px", marginBottom: 10 }}>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        fontWeight: 600,
                        marginRight: 3,
                        whiteSpace: "nowrap"
                    }}>
                        AVENANT AU MANDAT ÉTABLI EN DEUX EXEMPLAIRES, À :
                    </p>
                    <InputText
                        disabled={readOnly}
                        value={editor.signature_location || ""}
                        name="signature_location"
                        onChange={handleChange}
                        style={{
                            flexGrow: 1
                        }}
                    />
                </div>
                <SignatureMandant />
            </div>
            <div style={{ width: "40%" }}>
                <div style={{ display: "flex", alignItems: "center", paddingLeft: "10px", marginBottom: 10 }}>
                    <p style={{
                        fontSize: 13,
                        lineHeight: "13px",
                        fontWeight: 600,
                        marginRight: 3,
                        whiteSpace: "nowrap"
                    }}>
                        DATE DE SIGNATURE DU MANDAT :
                    </p>
                    <InputDate
                        disabled={readOnly}
                        value={editor.signature_date || ""}
                        name="signature_date"
                        onChange={handleChange}
                    />
                </div>
                <SignatureMandataire />
            </div>
        </div>
    )
}

export default AvenantSignature