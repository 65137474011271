import { Box, CircularProgress } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import Mean from "./Mean"
import { useDispatch, useSelector } from "react-redux"
import { apiOfferActions, selectApiKpis, selectApiKpisProfileStats } from "../../apiOfferSlice"
import StatBox from "../../../../common/components/dashboard/StatBox"
import ProgressBar3 from "../../../../common/components/dashboard/progress/ProgressBar3"
import useAuth from "../../../auth/hooks/useAuth"
import TooltipStyled from "../../../../common/components/TooltipStyled"
import { Fragment } from "react"

const Wrapper = ({ children }) => (
    <Box sx={{ bgcolor: scssVar.greyLighter, p: 2, borderRadius: "12px" }}>
        {children}
    </Box>
)

const ProfileStats = () => {
    const dispatch = useDispatch()
    const { data, selectedMember, options } = useSelector(selectApiKpisProfileStats)
    const { isLoading } = useSelector(selectApiKpis)
    const { isAdmin } = useAuth().user

    if (isLoading) {
        return (
            <Wrapper>
                <CircularProgress size={20} sx={{ color: scssVar.primary }} />
            </Wrapper>
        )
    }

    if (!options.length) {
        return (
            <Wrapper>
                <p>
                    {!isAdmin ? (
                        "Aucune donnée."
                    ):(
                        "Il n'y a aucune donnée sur les membres de votre agence pour cette période."
                    )}
                </p>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            {isAdmin && (
                <Fragment>
                    <Box className="select is-success-primary" sx={{ width: "100%" }}>
                        <select
                            value={selectedMember || ""}
                            onChange={(e) => dispatch(apiOfferActions.setSelectedKpisMember(e.target.value))}
                            style={{ 
                                width: "100%",
                                fontWeight: 600,
                            }}
                        >
                            {options.map((d, i) => <option key={i}>{d}</option>)}
                        </select>
                    </Box>
                    <Box sx={{ m: { xs: 3, lg: 6 } }} />
                </Fragment>
            )}

            <StatBox
                bgcolor={scssVar.greyLighter}
                icon="iconoir-star-outline"
                sx={{ p: 0 }}
                title={
                    !isAdmin ? (
                        `${data.total || "0"} lead${data.total > 1 ? "s" : ""}`
                    ):(
                        <span>
                            <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                                {`${data.total || "0"} lead${data.total > 1 ? "s" : ""} attribué${data.total > 1 ? "s" : ""}`}
                            </span>
                            <TooltipStyled
                                arrow
                                title={`L'onglet "Evaluations" de l'API vous permet d'attribuer un lead à un agent (colonne "Attribué à")`}
                                enterTouchDelay={0}
                                leaveTouchDelay={5000}
                            >
                                <i className="iconoir-info-empty" style={{ fontSize: 20, verticalAlign: "middle", display: "inline-block", }} />
                            </TooltipStyled>
                        </span>
                    )
                }
            />

            <Box sx={{ m: { xs: 3, lg: 6 } }} />

            <StatBox 
                bgcolor={scssVar.greyLighter}
                icon="iconoir-profile-circled"
                sx={{ p: 0 }}
                title={
                    <span>
                        <span style={{ verticalAlign: "middle", marginRight: 5 }}>Vues des leads</span>
                        <TooltipStyled
                            arrow
                            title={`L'onglet "Evaluations" de l'API vous permet de cocher le lead comme vu (colonne "Vu")`}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                        >
                            <i className="iconoir-info-empty" style={{ fontSize: 20, verticalAlign: "middle", display: "inline-block", }} />
                        </TooltipStyled>
                    </span>
                }
            >
                <ProgressBar3
                    label={{ left: "Vus", right: "Non vus" }}
                    value={{ left: data.seen, right: data.unseen }}
                />
            </StatBox>

            <Box sx={{ m: { xs: 3, lg: 6 } }} />

            <Mean mean={data.mean_value} sx={{ p: 0 }} />
        </Wrapper>
    )
}

export default ProfileStats