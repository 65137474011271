import { Box, ClickAwayListener, Tooltip, Typography, tooltipClasses } from "@mui/material"
import { useSelector } from "react-redux"
import { selectShapleyData } from "../../selectors"
import * as d3 from "d3";
import { scssVar } from "../../../../app/scssVar";
import { useState } from "react";
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: scssVar.greyDarker,
        color: "white",
        maxWidth: "300px",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: scssVar.fontFamily,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: scssVar.greyDarker,
    },
}));

const Label = ({ label }) => (
    <Typography sx={{ fontSize: 11, fontFamily: scssVar.fontFamily, fontWeight: 600, color: scssVar.greyDarker, mb: "2px" }}>
        {label}
    </Typography>
)

const Bar = ({ background, children, value }) => {
    const [selected, setSelected] = useState({})
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
        setSelected({})
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ width: value.width }}>
                <HtmlTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    placement="bottom"
                    onClose={handleClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <Box>
                            <Typography sx={{ fontFamily: scssVar.fontFamily, fontWeight: 600 }}>
                                {value.field_explanation}
                            </Typography>
                            <Typography sx={{ fontFamily: scssVar.fontFamily }}>
                                {value.tooltip}
                            </Typography>
                        </Box>
                    }
                >
                    <Box 
                        onClick={() => {
                            setOpen(true)
                            setSelected(value)
                        }} 
                        sx={{
                            cursor: "pointer",
                            position: "relative",
                            width: "100%",
                            height: 37,
                            borderRadius: "10px",
                            background,
                            outline: selected.label === value.label ? `3px solid blue` : "none",
                            transition: "outline .1s"
                        }}
                    >
                        <Typography sx={{
                            position: "absolute",
                            left: value.relative_effect >= 0 ? 7 : undefined,
                            right: value.relative_effect < 0 ? 7 : undefined,
                            top: 7,
                            fontWeight: 600,
                            fontFamily: scssVar.fontFamily
                        }}>
                            {value.displayed_effect}
                        </Typography>
                    </Box>
                </HtmlTooltip>
            </Box>
        </ClickAwayListener>
    )
}

const ShapleyChartMobile = () => {
    const { data } = useSelector(selectShapleyData)
    
    // convertir en valeurs absolues
    const values = data.map(d => Math.abs(d.relative_effect))
    // trouver la valeur max absolue
    const maxValue = d3.max(values)

    // Trier les data
    const sortedData = [...data].sort((a, b) => b.relative_effect - a.relative_effect)
    // déterminer la width des barres
    const items = sortedData.map(d => ({ ...d, width: Math.abs(d.relative_effect) === maxValue ? "100%" : `${Math.abs(d.relative_effect / maxValue) * 100}%` }))

    return (
        <Box sx={{ width: "100%", color: "black" }}>
            {items.filter(d => d.relative_effect >= 0).map((d, i) => 
                <Box key={i} sx={{ mb: "5px" }}>
                    <Label label={d.label} />
                    <Bar value={d} background="linear-gradient(90deg, rgba(209, 255, 163, 1) 0%, rgba(0, 255, 43, 0.58) 19%, rgba(14, 230, 1, 1) 56%)" />
                </Box>
            )}
            {items.filter(d => d.relative_effect < 0).map((d, i) => 
                <Box key={i} sx={{ mb: "5px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <Label label={d.label} /> 
                    <Bar value={d} background="linear-gradient(90deg, rgba(230, 1, 1, 1) 44%, rgba(255, 0, 0, 0.58) 81%, rgba(255, 163, 163, 0.58) 99%)" />
                </Box>
            )}
        </Box>
    )
}

export default ShapleyChartMobile