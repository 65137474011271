import { Box } from "@mui/material"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const StepNav = ({
    onSaveForLater,
    saveForLaterDisabled,
    backDisabled,
    onGoBack,
    nextDisabled,
    onGoNext
}) => {
    const working = useSelector(state => state.estimate.working)

    return (
        <Fragment>
            <Box sx={{
                mt: 6,
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "@media (max-width: 1045px)": {
                    flexDirection: "column",
                    alignItems: "flex-end"
                },
                "@media (max-width: 750px)": {
                    alignItems: "center"
                },
                "@media (max-width: 599px)": {
                    display: "none"
                }
            }}>
                {onSaveForLater && (
                    <Box sx={{ 
                        mr: "15px",
                        "@media (max-width: 1045px)": {
                            mr: 0,
                            mb: "15px"
                        }
                    }}>
                        <Button
                            type="button"
                            variant="light"
                            disabled={saveForLaterDisabled}
                            onClick={onSaveForLater}
                            sx={{
                                pl: 4,
                                pr: 4
                            }}
                        >
                            <i
                                className="iconoir-save-floppy-disk"
                                style={{
                                    fontSize: 24,
                                    marginRight: 5,
                                }}
                            ></i>
                            Revenir plus tard
                        </Button>
                    </Box>
                )}
                <Box sx={{
                    display: "flex",
                    "@media (max-width: 750px)": {
                        flexDirection: "column",
                        alignItems: "center"
                    }
                }}>
                    <Box sx={{
                        mr: "15px",
                        "@media (max-width: 750px)": {
                            mr: 0,
                            mb: "15px"
                        }
                    }}>
                        <Button
                            type="button"
                            variant="light"
                            disabled={backDisabled || working}
                            onClick={onGoBack}
                            sx={{
                                pl: 5,
                                pr: 5
                            }}
                        >
                            <i 
                                className="iconoir-arrow-left"
                                style={{
                                    fontSize: 24,
                                    marginRight: 5
                                }}
                            ></i>
                            Précédent
                        </Button>
                    </Box>
                    <Button
                        disabled={nextDisabled || working}
                        onClick={onGoNext}
                        sx={{
                            pl: 5,
                            pr: 5
                        }}
                    >
                        Suivant
                        <i 
                            className="iconoir-arrow-right"
                            style={{
                                fontSize: 24,
                                marginLeft: 5
                            }}
                        ></i>
                    </Button>
                </Box>
            </Box>

            <Box sx={{
                display: { xs: "block", sm: "none" },
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: scssVar.primaryLighter,
                borderTop: 1,
                borderColor: scssVar.grey,
                zIndex: 1000
            }}>
                <Box sx={{ 
                    width: "90%",
                    margin: "0 auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: `calc(60px + ${scssVar.sab})`,
                }}>
                    <Button
                        variant="light"
                        type="button"
                        sx={{
                            width: 130
                        }}
                        disabled={backDisabled || working}
                        onClick={onGoBack}
                    >
                        <i 
                            className="iconoir-arrow-left"
                            style={{
                                fontSize: 20,
                                marginRight: 1,
                            }}
                        ></i>
                        Précédent
                    </Button>
                    <Button
                        disabled={nextDisabled || working}
                        onClick={onGoNext}
                        sx={{
                            width: 130
                        }}
                    >
                        Suivant
                        <i 
                            className="iconoir-arrow-right"
                            style={{
                                fontSize: 20,
                                marginLeft: 1
                            }}
                        ></i>
                    </Button>
                </Box>
            </Box>
        </Fragment>
    )
}

export default StepNav