import axios from "axios"
import { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { getDepartmentByCode } from "../../../../common/utils"
import { addAlert } from "../../../alerts/alertsSlice"
import TooltipStyled from "../../../../common/components/TooltipStyled"
import InputAutoComplete from "../../../../common/components/InputAutoComplete"

const Address = ({
    filterDepartements,
    value,
    onChange,
    onSelect,
    onReset,
    isOk,
    orgColor
}) => {
    const dispatch = useDispatch()
    const [searching, setSearching] = useState(false)
    const [matches, setMatches] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const timeout = useRef(null)
    
    const filterDepartementsSorted = filterDepartements?.length 
        ? [...filterDepartements].sort((a, b) => Number(a) - Number(b)) 
        : []

    useEffect(() => {
        return () => {
            setSearching(false)
            setMatches([])
            setActiveIndex(0)
            clearTimeout(timeout.current)
        }
    }, [])

    const onSearch = (address) => {
        if (value.length >= 5) {
            timeout.current = setTimeout(() => {
                setSearching(true)

                axios
                    .get("https://api-adresse.data.gouv.fr/search/", { params: { limit: 100, q: address, autocomplete: "1" } })
                    .then(res => {
                        const { features } = res.data

                        if (!features.length) {
                            dispatch(addAlert("Aucune adresse correspondante à votre recherche n'a été trouvée. Veuillez réessayer.", "error"))
                            setSearching(false)
                            setMatches([])
                            return
                        } 

                        // Exclure les VILLES (uniquement les villes, sans adresse), des résultats et les DOM-TOM.
                        const cleaned = features.filter(d => {
                            return d.properties?.label !== d.properties?.city 
                                && Number(d.properties?.postcode?.substring(0, 2)) <= 95
                        })

                        if (!cleaned.length) {
                            dispatch(addAlert("Aucune adresse correspondante à votre recherche n'a été trouvée. Veuillez réessayer.", "error"))
                            setSearching(false)
                            setMatches([])
                            return
                        }

                        let result

                        if (filterDepartementsSorted.length) {
                            result = cleaned.filter(d => filterDepartementsSorted.includes(d.properties.postcode.substring(0, 2)))

                            if (!result.length) {
                                const errMsg = `
                                    Aucune adresse correspondante à votre recherche n'a été trouvée dans 
                                    le${filterDepartementsSorted.length > 1 ? "s" : ""}
                                    département${filterDepartementsSorted.length > 1 ? "s" : ""} : 
                                    ${filterDepartementsSorted.map(
                                        (d, i) => 
                                            `${getDepartmentByCode(d).code} - ${getDepartmentByCode(d).nom}${i < filterDepartementsSorted.length - 1 ? ", " : ""}`
                                    ).join("")}. 
                                    Veuillez réessayer
                                `
                                dispatch(addAlert(errMsg, "error"))
                                setSearching(false)
                                setMatches([])
                                return
                            }
                        } else {
                            result = cleaned
                        }

                        const dataReady = result.slice(0, 5).map(d => ({
                            "adresse": d.properties.label,
                            "code_postal": d.properties.postcode,
                            "code_commune": d.properties.citycode,
                            "nom_commune": d.properties.city,
                            "code_departement": d.properties.postcode.substring(0, 2),
                            "longitude": d.geometry.coordinates[0],
                            "latitude": d.geometry.coordinates[1],
                        }))

                        setSearching(false)
                        setMatches(dataReady)
                    })
                    .catch(err => {
                        console.log(err.response)
                        setSearching(false)
                        dispatch(addAlert("Une erreur est survenue lors de la recherche d'adresses. Veuillez réessayer", "error"))
                    })
            }, [1000])
        }
    }

    const handleChange = (e) => {
        const value = e.target.value
        clearTimeout(timeout.current)
        onChange(value)
        onSearch(value)
    }

    const handleKeyPress = (e) => {
        switch (e.which) {
            case 13: // Enter key
                if (matches.length) {
                    setMatches([])
                    setActiveIndex(0)
                    onChange(matches[activeIndex].adresse)
                    onSelect(matches[activeIndex])
                }
                break
            case 38: // Up arrow
                setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0)
                break
            case 40: // Down arrow
                setActiveIndex(
                    activeIndex < matches.length - 1
                        ? activeIndex + 1
                        : matches.length - 1
                )
                break
            default:
                break
        }
    }

    const handleSelection = (e, selection) => {
        e.preventDefault()
        setMatches([])
        setActiveIndex(0)
        onChange(selection.adresse)
        onSelect(selection)
    }

    const handleResetAddress = () => {
        setSearching(false)
        setMatches([])
        setActiveIndex(0)
        onChange("")
        onReset()
    }

    return (
        <div className="field">
            <label className={`label has-text-weight-normal ${isOk && "has-text-success"}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ fontSize: 14 }}>
                        Adresse
                    </span>
                    {searching ? (
                        <span style={{ fontSize: 11, marginLeft: 4 }}>
                            chargement des adresses...
                        </span>
                    ):(
                        filterDepartementsSorted.length > 0 && (
                            <Fragment>
                                <span style={{ 
                                    marginLeft: 4,
                                    marginRight: 3,
                                    fontSize: 11, 
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                }}>
                                    {"("}{filterDepartementsSorted.map(
                                        (d, i) => 
                                            `${getDepartmentByCode(d).nom}${i < filterDepartementsSorted.length - 1 ? ", " : ""}`
                                    )}{")"}
                                </span>
                                <TooltipStyled
                                    arrow 
                                    enterTouchDelay={0}
                                    leaveTouchDelay={5000}
                                    title={
                                        <p>
                                            Les résultats de la recherche sont limités à 
                                            ce{filterDepartementsSorted.length > 1 ? "s" :""}{" "}
                                            département{filterDepartementsSorted.length > 1 ? "s" :""} :{" "}
                                            {filterDepartementsSorted.map(
                                                (d, i) => 
                                                    `${getDepartmentByCode(d).code} - ${getDepartmentByCode(d).nom}${i < filterDepartementsSorted.length - 1 ? ", " : ""}`
                                            )}
                                            .
                                        </p>
                                    }
                                    sx={{
                                        "& .MuiTooltip-tooltip": {
                                            bgcolor: orgColor.main
                                        },
                                        "& .MuiTooltip-arrow": {
                                            color: orgColor.main
                                        }
                                    }}
                                >
                                    <i className="iconoir-info-empty" style={{ fontSize: 17 }}></i>
                                </TooltipStyled>
                            </Fragment>
                        )
                    )}
                </div>
            </label>
            <InputAutoComplete
                matches={matches}
                isSuccess={isOk}
                onChange={handleChange}
                value={value}
                onKeyDown={handleKeyPress}
                onReset={handleResetAddress}
                activeIndex={activeIndex}
                onSelect={handleSelection}
                className={`input input-grey ${isOk && "is-success"}`}
                dropDownItemStyle={index => ({
                    background: 
                        index === activeIndex 
                            ? orgColor.main
                            : "white"
                })}
            />
        </div>
    )
}

export default Address