import { Box, Grid } from "@mui/material"
import { scssVar } from "../../../../../../app/scssVar"
import { useSelector } from "react-redux"
import { selectValues } from "../../../../selectors"
import { numberWithSpaces } from "../../../../../../common/utils"
import Text from "../../../../../../common/components/Text"

const PriceBox = ({ children }) => (
    <div style={{
        background: "white", 
        width: "100%", 
        height: 100, 
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px",
    }}>
        {children}
    </div>
)

const EstimationValue = () => {
    const value = useSelector(selectValues).estimationValue

    return (
        <Box sx={{
            bgcolor: scssVar.primaryLight,
            borderRadius: "16px",
            padding: "15px 25px 25px 25px",
        }}>
            <Text sx={{ color: scssVar.primary, fontSize: 17, fontWeight: 600, mb: .5 }}>Évaluation</Text>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <PriceBox>
                        <Text sx={{ fontSize: 14 }}>Basse</Text>
                        <Text sx={{ fontSize: 14, fontWeight: 600, color: "#A6A6A6", whiteSpace: "nowrap" }}>
                            {numberWithSpaces(value - (value * 7 / 100))}€
                        </Text>
                    </PriceBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PriceBox>
                        <Text sx={{ fontSize: 14 }}>Médiane</Text>
                        <Text sx={{ 
                            fontSize: 28, 
                            fontWeight: 700, 
                            color: scssVar.primary, 
                            whiteSpace: "nowrap",
                            "@media (min-width: 600px)": {
                                fontSize: 24
                            },
                            "@media (min-width: 670px)": {
                                fontSize: 28
                            }
                        }}>
                            {numberWithSpaces(value)}€
                        </Text>
                    </PriceBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PriceBox>
                        <Text sx={{ fontSize: 14 }}>Haute</Text>
                        <Text sx={{ fontSize: 14, fontWeight: 600, color: "#A6A6A6", whiteSpace: "nowrap" }}>
                            {numberWithSpaces(value + (value * 7 / 100))}€
                        </Text>
                    </PriceBox>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EstimationValue