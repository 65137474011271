import { useEffect } from "react"
import { useDispatch } from "react-redux"
import * as serviceWorker from "../../serviceWorkerRegistration"
import useAuth from "../auth/hooks/useAuth"
import { appVersion } from "../../common/utils"
import { setWaitingWorker, updateAvailable } from "./appUpdateSlice"

const UpdateListener = () => {
    const dispatch = useDispatch()
    const { newAppVersion } = useAuth().user

    // useEffect(() => {
    //     if (window.location.pathname.includes("integration")) {
    //         serviceWorker.unregister()
    //     } else {
    //         serviceWorker.register({ 
    //             onUpdate: (registration) => {
    //                 console.log(
    //                     "%cFrontend: une mise à jour est disponible", 
    //                     "color:lightgreen;font-weight:600"
    //                 )
    //                 dispatch(setWaitingWorker(registration.waiting))
    //             }
    //         })
    //     }
    // }, [dispatch])

    useEffect(() => {
        serviceWorker.register({ 
            onUpdate: (registration) => {
                console.log(
                    "%cFrontend: une mise à jour est disponible", 
                    "color:lightgreen;font-weight:600"
                )
                dispatch(setWaitingWorker(registration.waiting))
            }
        })
    }, [dispatch])

    useEffect(() => {
        if (newAppVersion && newAppVersion !== appVersion) {
            console.log(
                `%cNouvelle version disponible : ${newAppVersion}`, 
                "color:lightgreen;font-weight:600"
            )

            dispatch(updateAvailable(newAppVersion))
        }
    }, [newAppVersion, dispatch])

    return null
}

export default UpdateListener