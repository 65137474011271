import { useSelector } from "react-redux"
import { selectMandatSaving } from "../mandat/mandatSlice"
import useJssInput from "../hooks/useJssInput"
import { selectTracfinSaving } from "../buy/tracfin/tracfinSlice"

const InputSelect = ({ 
    value, 
    onChange, 
    name,
    style,
    children,
    required,
    disabled
}) => {
    const jss = useJssInput()
    const savingMandat = useSelector(selectMandatSaving)
    const savingTracin = useSelector(selectTracfinSaving)

    return (
        <select
            required={required}
            value={value}
            onChange={onChange}
            name={name}
            disabled={savingMandat || savingTracin || disabled}
            style={Object.assign({}, { opacity: 1, }, jss, style)}
        >
            {children}
        </select>
    )
}

export default InputSelect