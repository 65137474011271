import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import * as d3 from "d3"
import { scssVar } from "../../../../app/scssVar";
import LongitudinalStackedChart from "./LongitudinalStackedChart";
import { Box } from "@mui/material";
import { getDateToString, getDateToMonth, getDateToWeek, getMonthToDateString, getWeekToDateString } from "../utils";

const colors = d3.scaleOrdinal().range([
    scssVar.greenDark, // leads vérifiés
    scssVar.success, // leads non vérifiés
    "#C4FBD4", // sans coordonnées
])

const draw = (data, groups, svgRef, {
    width = 1000,
    dateType = "day",
    hoverBarId = `hover_bar_${dateType}_eval_count`,
    gridId = `grid_${dateType}_eval_count`,
    xFormat = dateType,
    setLegend = () => {},
    setHovered = () => {}
} = {}) => {
    // if component remount, remove chart and redraw
    d3.select(svgRef.current).selectAll("*").remove();

    // reset hovered bar
    setHovered({})

    const chart = new LongitudinalStackedChart(scssVar.fontFamily, width)
    const svg = chart.initSvg(svgRef.current)

    const dates = Array.from(new Set(data.map(d => d.date)))

    const dateFormatting = (date) => {
        if (dateType === "day") return date
        else if (dateType === "week") return getWeekToDateString(date)
        else if (dateType === "month") return getMonthToDateString(date)
    }

    const groupBy = (data, {
        getterKey = "name", 
        groups = [], 
        groupKey, 
        valueKey
    }) => {
        const a = Array.from(new Set(data.map(d => d[getterKey])))

        return a.map(d => {
            const res = { [getterKey]: dateFormatting(d) }
    
            groups.forEach(group => {
                const total = data.reduce((acc, val) => {
                    if (val[groupKey] === group && val[getterKey] === d) {
                        return acc + val[valueKey]
                    }
                    return acc
                }, 0)
    
                Object.assign(res, { [group]: total })}
            )
    
            return res
        })
    }

    const dataReady = groupBy(data, { 
        getterKey: "date", 
        groups, 
        groupKey: "subgroup", 
        valueKey: "value"
    })

    const series = d3.stack().keys(groups.filter(t => t !== null))
        // eslint-disable-next-line
        (dataReady).map(d => (d.forEach(v => v.key = d.key), d));

    setLegend(groups.map((d, i) => ({ id: i, label: d, color: colors(d) })))

    const xDomain = dataReady.map(d => new Date(d.date))
    const x = chart.initX(xDomain)

    const minY = 0
    const maxY = d3.max(series[series.length - 1].map(d => d[1]))
    const y = chart.initY(minY, maxY)

    chart.initGrid(gridId)

    chart.initBarWidth(xDomain)

    svg.append("g")
        .attr("class", "bars")
        .selectAll("g")
        .data(series)
        .join("g")
        .attr("fill", d => colors(d.key))
        .style("opacity", 1)
        .selectAll("rect")
        .data(d => d)
        .join("rect")
        // on doit décaler la barre vers la gauche pour qu'elle soit alignée aux x ticks
        .attr("x", (d, i) => x(new Date(d.data.date)) - (chart.updateBarWidth() / 2)) 
        .attr("y", d => y(d[1]))
        .attr("height", d => y(d[0]) - y(d[1]))
        .attr("width", chart.updateBarWidth()); 

    chart.initAxisX()
    chart.updateAxisX(xFormat)
    chart.initAxisY()
    chart.updateAxisY(".2s")

    const zoomCallBack = (lookup) => {
        chart.updateAxisX(xFormat)
        chart.initGrid(gridId)

        svg.selectAll(".bars rect")
            // on doit décaler la barre vers la gauche pour qu'elle soit alignée aux x ticks
            .attr("x", (d, i) => x(new Date(d.data.date)) - (chart.updateBarWidth() / 2))
            .attr("width", chart.updateBarWidth());
        
        updateDataBarFocused(lookup)
    }

    const datesToDate = dates.map(date => new Date(dateFormatting(date)))
    const datesSorted = [...datesToDate].sort((a, b) => a - b)

    chart.initZoom(zoomCallBack, datesSorted)

    const handleMoveCallback = (lookup) => {
        updateDataBarFocused(lookup)
    }

    chart.initHover(hoverBarId, datesSorted, handleMoveCallback)

    const updateDataBarFocused = (lookup) => {
        if (lookup) {
            const res = dataReady.filter(d => new Date(d.date).toLocaleDateString() === lookup.toLocaleDateString())[0]
            setHovered(res)
        }
    }
}

const LongitStacked = ({ data, groups, params }) => {
    const [legend, setLegend] = useState([])
    const [hovered, setHovered] = useState({})
    const svgRef = useRef(null)

    useEffect(() => {
        if (data.length) {
            draw(data, groups, svgRef, { ...params, setLegend, setHovered })
        }
    }, [data, groups, params])

    const date = useMemo(() => {
        if (hovered.date) {
            if (params.dateType === "week") return getDateToWeek(hovered.date, { withYear: true })
            else if (params.dateType === "month") return getDateToMonth(hovered.date, { withYear: true })
            return getDateToString(hovered.date, { withYear: true }) 
        }
        return "---"
    }, [hovered.date, params.dateType])

    const total = useMemo(() => {
        if (hovered.date) {
            const copy = { ...hovered }
            const { date, ...copyWithNoDateKey } = copy
            const res = Object.values(copyWithNoDateKey).reduce((a, b) => a + b, 0)
            return " - " + res + ` requête${res > 1 ? "s" : ""}`
        }
        return ""
    }, [hovered])

    return (
        <Fragment>
            <Box sx={{ 
                p: "5px 10px",
                bgcolor: scssVar.greyLight,
                borderRadius: "10px",
                width: "calc(100% - 280px)",
                "@media (max-width: 700px)": {
                    width: "100%"
                }
            }}>
                <p style={{ color: scssVar.greyDark, fontSize: 14, fontWeight: 600 }}>
                    {date} {total}
                </p>
                <Box sx={{ 
                    display: "flex", 
                    "@media (max-width: 990px)": {
                        flexDirection: "column"
                    }
                }}>
                    {legend.map((d, i) => 
                        <Box key={i} sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                            <Box sx={{ width: 20, height: 13, bgcolor: d.color, mr: 1, borderRadius: "2px" }} />
                            <p style={{ whiteSpace: "nowrap", fontSize: 14, color: scssVar.greyDark }}>
                                {d.label}
                                {hovered.date && (
                                    Object.entries(hovered).map(([key, value], index) => key === d.label && 
                                        <span key={index}> : {value}</span>
                                    )
                                )}
                            </p>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box ref={svgRef} />
        </Fragment>
    )
}

export default LongitStacked