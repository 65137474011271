import { AddRounded } from "@mui/icons-material"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useMediaQuery } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import FieldControl from "../../../../common/components/FieldControl"
import { api } from "../../../../common/setupApi"
import { addAlert } from "../../../alerts/alertsSlice"
import { apiOfferActions, selectApiDomains } from "../../apiOfferSlice"

const Domains = () => {
    const dispatch = useDispatch()
    const domains = useSelector(selectApiDomains)
    const isSmallScreen = useMediaQuery("(max-width: 599px)")
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState(null)
    const [selected, setSelected] = useState(null)
    const [value, setValue] = useState("")

    const handleClose = () => {
        setMode(null)
        setSelected(null)
        setValue("")
    }

    const handleSubmit = async () => {
        let payload

        if (mode === "add") {
            payload = { domains: [...domains, value] }
        }

        if (mode === "edit") {
            payload = { domains: domains.map(d => d === selected ? value : d) }
        } 

        if (mode === "delete") {
            payload = { domains: domains.filter(d => d !== selected) }
        }

        setLoading(true)

        return api
            .patch("v1/api/infos/", payload)
            .then(res => {
                dispatch(apiOfferActions.setDomains(res.data.domains))
                if (mode === "add") {
                    dispatch(addAlert("Le domaine a bien été ajouté", "success"))
                } 
                if (mode === "edit") {
                    dispatch(addAlert("Le domaine a bien été modifié", "success"))
                }
                if (mode === "delete") {
                    dispatch(addAlert("Le domaine a bien été supprimé", "success"))
                }
                setMode(null)
                setSelected(null)
                setValue("")
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                const error = err.response?.data?.domains || []
                if (error.length) {
                    error.forEach(d => dispatch(addAlert(d, "error")))
                } else {
                    dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                }
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <p style={{ fontWeight: 800, marginBottom: 15 }}>
                Votre site web
            </p>
            <p style={{ color: scssVar.greyDarker, marginBottom: 15 }}>
                Pour intégrer l'API sur votre site web, renseignez son nom de domaine.
                Vous pouvez ajouter jusqu'à 5 sites.
                <br/>Cette étape est obligatoire.
            </p>
            {domains.length ? (
                <Fragment>
                    <List sx={{ bgcolor: scssVar.greyLighter, borderRadius: "12px" }}>
                        {domains.map((d, i) => 
                            <ListItem
                                key={i} 
                                sx={{ 
                                    borderBottom: i < domains.length - 1 ? `1px solid ${scssVar.greyLight}` : "none",
                                    pr: "100px"
                                }}
                            >
                                <ListItemText primary={d} sx={{
                                    ".MuiTypography-root": {
                                        fontFamily: scssVar.fontFamily
                                    },
                                    ".MuiListItemText-primary": {
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                    }
                                }} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => {
                                        setMode("edit")
                                        setSelected(d)
                                        setValue(d)
                                    }}>
                                        <i
                                            className="iconoir-edit-pencil edit-icon"
                                            style={{ 
                                                color: scssVar.primary
                                            }}
                                        ></i>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setMode("delete")
                                        setSelected(d)
                                        setValue(d)
                                    }}>
                                        <i
                                            className="iconoir-trash"
                                            style={{ 
                                                color: scssVar.primary
                                            }}
                                        ></i>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    {domains.length < 5 && (
                        <Button onClick={() => setMode("add")} sx={{ ml: "auto", mt: 2 }}>
                            Ajouter un site
                            <AddRounded sx={{ ml: 1 }} />
                        </Button>
                    )}
                </Fragment>
            ):(
                <Fragment>
                    <Box sx={{ p: "10px 20px", bgcolor: scssVar.greyLighter, borderRadius: "8px", mb: "20px" }}>
                        <p style={{ fontWeight: 500 }}>
                            Vous n'avez pas renseigné votre site web.
                        </p>
                    </Box>
                    <Button onClick={() => setMode("add")} sx={{ ml: "auto" }}>
                        Ajouter un site
                        <AddRounded sx={{ ml: 1 }} />
                    </Button>
                </Fragment>
            )}
            <Dialog
                open={mode !== null} 
                onClose={handleClose}
                fullScreen={(mode === "add" || mode === "edit") && isSmallScreen}
                fullWidth
                transitionDuration={0}
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}>
                    {mode === "add" && "Ajouter un site"}
                    {mode === "edit" && `Modifier ${selected}`}
                    {mode === "delete" && `Supprimer ${selected} ?`}
                </DialogTitle>
                {(mode === "add" || mode === "edit") && (
                    <DialogContent>
                        <p style={{ color: scssVar.greyDarker, fontSize: 11, lineHeight: "15px", marginBottom: 10 }}>
                            Exemples de formats autorisés :
                            <br/><span style={{ marginLeft: 15 }}>- mon-site-web.com</span>
                            <br/><span style={{ marginLeft: 15 }}>- www.mon-site-web.fr</span>
                        </p>
                        <FieldControl
                            type="text"
                            placeholder="Exemple: mon-site-web.com"
                            value={value || ""}
                            onChange={e => setValue(e.target.value)}
                        />
                    </DialogContent>
                )}
                <DialogActions>
                    <Button sx={{ width: 150 }} variant="light" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button 
                        variant={mode === "delete" ? "danger" : "primary"}
                        sx={{ width: 150 }} 
                        onClick={handleSubmit} 
                        loading={loading}
                        disabled={loading || ((mode === "add" || mode === "edit") && !value.trim().length)} 
                    >
                        {(mode === "add" || mode === "edit") && "Enregistrer"}
                        {mode === "delete" && "Supprimer"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Domains