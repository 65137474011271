import { Box, CircularProgress, Typography } from "@mui/material"
import { Fragment, useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import StatBox from "../../../common/components/dashboard/StatBox"
import ProgressBar2 from "../../../common/components/dashboard/progress/ProgressBar2"
import ProgressBar3 from "../../../common/components/dashboard/progress/ProgressBar3"

const percents = [
    { label: "Garage", key: "avec_garage", color: "#006380" },
    { label: "Jardin", key: "avec_jardin", color: "#8433AA" },
    { label: "Piscine", key: "avec_piscine", color: "#FFA031" },
]

const EstimationsStats = () => {
    const items = useSelector(state => state.estimations.estimations.filtered)
    const loading = useSelector(state => state.estimations.estimations.loading)

    const sold = useMemo(() => {
        return items.filter(d => d.housing.prix_vente).length
    }, [items])

    const unsold = items.length - sold

    const uniqClients = useMemo(() => {
        const estimationsWithClient = items.filter(d => d.client_potentiel)

        if (estimationsWithClient.length) {
            const clientsList = estimationsWithClient.map(d => d.client_potentiel.toUpperCase())
            const clientsWithDuplicatesRemoved = [...new Set(clientsList)]

            return clientsWithDuplicatesRemoved.length
        }

        return 0
    }, [items])

    const uniqHouse = useMemo(() => {
        const houseIds = items.map(d => d.housing.id)
        const houseIdsWithDuplicatesRemoved = [...new Set(houseIds)]

        return houseIdsWithDuplicatesRemoved.length
    }, [items])

    const getPercent = useCallback((key) => {
        const esti = items.filter(d => d[key])

        if (esti.length) {
            return Math.round(esti.length / items.length * 100)
        }

        return 0
    }, [items])

    return (
        <Box sx={{ width: "100%" }}>
            {loading ? (
                <CircularProgress
                    size={30}
                    sx={{
                        color: scssVar.primary
                    }}
                />
            ):(
                <Fragment>
                    <StatBox
                        icon="iconoir-star-outline"
                        title={`Vente${sold > 1 ? "s" : ""} effectuée${sold > 1 ? "s" : ""}`}
                    >
                        <ProgressBar3
                            label={{ left: "Vendus", right: "Invendus" }}
                            value={{ left: sold, right: unsold }}
                        />
                    </StatBox>

                    <Box sx={{ m: 2 }} />

                    <StatBox
                        icon="iconoir-home-alt-slim"
                        title={`${items.length || "0"} estimation${items.length > 1 ? "s" : ""}`}
                    >
                        <Typography sx={{
                            fontSize: 14,
                            color: scssVar.greyDark,
                            fontFamily: scssVar.fontFamily
                        }}>
                            sélectionnée{items.length > 1 ? "s" : ""} avec les filtres
                        </Typography>
                    </StatBox>

                    <Box sx={{ m: 2 }} />

                    <StatBox
                        icon="iconoir-home-alt-slim"
                        title={`${uniqHouse} bien${uniqHouse > 1 ? "s" : ""} unique${uniqHouse > 1 ? "s" : ""}`}
                    >
                        <Typography sx={{
                            fontSize: 14,
                            color: scssVar.greyDark,
                            fontFamily: scssVar.fontFamily
                        }}>
                            sélectionné{uniqHouse > 1 ? "s" : ""} avec les filtres
                        </Typography>
                    </StatBox>

                    <Box sx={{ m: 2 }} />

                    <StatBox
                        icon="iconoir-profile-circled"
                        title={`${uniqClients} client${uniqClients > 1 ? "s" : ""} unique${uniqClients > 1 ? "s" : ""}`}
                    />

                    <Box sx={{ m: 2 }} />

                    <StatBox
                        icon="iconoir-home-alt-slim"
                        title="Caractéristiques"
                    >
                        {percents.map((d, i) => 
                            <ProgressBar2 
                                label={d.label} 
                                p={getPercent(d.key)} 
                                color={d.color} 
                                key={i}
                            />
                        )}
                    </StatBox>
                </Fragment>
            )}
        </Box>
    )
}

export default EstimationsStats