import { ApartmentRounded, HotelRounded, HouseRounded, MailRounded, MapsHomeWorkRounded, PhoneRounded, Weekend, WhereToVoteRounded } from "@mui/icons-material"
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, useMediaQuery } from "@mui/material"
import { Fragment, useState } from "react"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import { m2, numberWithSpaces } from "../../../../common/utils"

const Step06 = ({ 
    onReset, 
    value,
    street,
    code,
    city,
    sellType,
    surface,
    rooms,
    bedrooms,
    orgName,
    orgLogo,
    orgAddress,
    orgEmail,
    orgPhone,
    orgColor
}) => {
    const isSmallScreen = useMediaQuery("(max-width: 599px)")
    const [openExplanation, setOpenExplanation] = useState(false)

    return (
        <Box sx={{ 
            minHeight: "100%",
            bgcolor: "white",
            p: "25px",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                maxWidth: 700,
                margin: "0 auto",
            }}>
                <Typography sx={{ color: "black", fontFamily: scssVar.fontFamily, fontWeight: 800, fontSize: 22, textAlign: "center" }}>
                    La valeur marchande de votre bien
                </Typography>
                <Typography sx={{ color: "grey", fontFamily: scssVar.fontFamily, textAlign: "center", mb: 2, fontSize: 18 }}>
                    Voici le résultat de notre outil d'estimation en ligne.
                    Cette estimation initiale peut être affinée et précisée par la suite grâce à 
                    l'expertise de nos agents immobiliers qualifiés.
                </Typography>
                <Box sx={{
                    p: 1,
                    borderRadius: "8px",
                    boxShadow: scssVar.shadow2,
                    bgcolor: "white"
                }}>
                    <Typography sx={{ color: "black", fontFamily: scssVar.fontFamily, textAlign: "center" }}>
                        {street}, {code} {city}
                    </Typography>
                    <Box sx={{ 
                        display: "flex",
                        justifyContent: "center",
                        "@media (max-width: 600px)": {
                            flexDirection: "column"
                        }
                    }}>
                        <Box sx={{
                            backgroundColor: scssVar.greyLighter,
                            height: 87,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "33%",
                            mr: "10px",
                            "@media (max-width: 600px)": {
                                mr: 0,
                                mb: "10px",
                                width: "100%"
                            }
                        }}>
                            <p style={{ fontSize: 14 }}>
                                Basse
                            </p>
                            <p style={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: "#A6A6A6"
                            }}>
                                {numberWithSpaces(Math.round(value - (value * 7 / 100)))}€
                            </p>
                        </Box>
                        <Box sx={{
                            backgroundColor: scssVar.greyLighter,
                            height: 87,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "33%",
                            mr: "10px",
                            "@media (max-width: 600px)": {
                                mr: 0,
                                mb: "10px",
                                width: "100%"
                            }
                        }}>
                            <p style={{ fontSize: 14 }}>
                                Médiane
                            </p>
                            <p style={{
                                fontSize: 25,
                                fontWeight: 700,
                                color: orgColor.main || scssVar.primary,
                            }}>
                                {numberWithSpaces(value)}€
                            </p>
                        </Box>
                        <Box sx={{
                            backgroundColor: scssVar.greyLighter,
                            height: 87,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "33%",
                            "@media (max-width: 600px)": {
                                width: "100%"
                            }
                        }}>
                            <p style={{ fontSize: 14 }}>
                                Haute
                            </p>
                            <p style={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: "#A6A6A6"
                            }}>
                                {numberWithSpaces(Math.round(value + (value * 7 / 100)))}€
                            </p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        "@media (max-width: 600px)": {
                            flexDirection: "column"
                        }
                    }}>
                        <p style={{ textAlign: "center", fontSize: 13 }}>
                            Prix net hors frais agence
                        </p>
                        <Button 
                            variant="text"
                            type="button"
                            onClick={() => setOpenExplanation(true)}
                            sx={{
                                textDecoration: "underline",
                                fontSize: 13,
                                ml: 1,
                                color: orgColor.main,
                                "&:hover": {
                                    color: orgColor.main,
                                    filter: "brightness(70%)"
                                },
                                "&.Mui-active": {
                                    color: orgColor.main,
                                    filter: "brightness(40%)"
                                },
                            }}
                        >
                            Comment est-elle calculée ?
                        </Button>
                        <Dialog 
                            open={openExplanation} 
                            onClose={() => setOpenExplanation(false)}
                            fullScreen={isSmallScreen}
                            fullWidth 
                        >
                            <DialogTitle sx={{
                                fontWeight: 700,
                                fontSize: { xs: 18, sm: 24 },
                                fontFamily: scssVar.fontFamily,
                            }}>
                                Méthode d’estimation
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{ fontFamily: scssVar.fontFamily }}>
                                    Estimia, reprend les données sur plusieurs années de la base DVF (Etalab, valeurs foncières communiquées par l’Etat), 
                                    celles des notaires et des ventes renseignées par les professionnels de l’immobilier. Ces data sont mises à jour. 
                                    Pour coller à l’actualité du marché immobilier, sont exclus des bases, les biens exceptionnels ou trop atypiques, 
                                    les valeurs viagères.
                                    <br/>
                                    <br/>
                                    Digipi, l’éditeur d’estimia, a investi plus de 2 années de R&D (Recherche et développement), pour mettre en place ce 
                                    moteur d’estimation fondé sur une approche Maching Learning.
                                    <br/>
                                    <br/>
                                    L’apprentissage, à partir des valeurs existantes, permet l’amélioration continue des base de données. 
                                    Objectif : proposer un écart-type réduit du prix de présentation du bien.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    onClick={() => setOpenExplanation(false)}
                                    sx={{
                                        bgcolor: orgColor.main,
                                        "&:hover": {
                                            bgcolor: orgColor.main,
                                            filter: "brightness(93%)"
                                        },
                                        "&.Mui-active": {
                                            bgcolor: orgColor.main,
                                            filter: "brightness(87%)"
                                        },
                                    }}
                                >
                                    Fermer
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <Box sx={{ 
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        flexWrap: "wrap",
                        color: scssVar.greyDarker,
                    }}>
                        <Box sx={{ display: "flex", alignItems: "center", m: "3px", fontSize: 13, whiteSpace: "nowrap" }}>
                            {sellType === "Appartement" ? (
                                <ApartmentRounded style={{ fontSize: 19 }} />
                            ):(
                                <HouseRounded style={{ fontSize: 19 }} />
                            )}
                            {sellType}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", m: "3px", fontSize: 13, whiteSpace: "nowrap" }}>
                            <MapsHomeWorkRounded style={{ fontSize: 19, marginRight: 4 }} />
                            {surface} {m2}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", m: "3px", fontSize: 13, whiteSpace: "nowrap" }}>
                            <Weekend style={{ fontSize: 19, marginRight: 4 }} />
                            {rooms} pièce{rooms > 1 ? "s" : ""}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", m: "3px", fontSize: 13, whiteSpace: "nowrap" }}>
                            <HotelRounded style={{ fontSize: 19, marginRight: 4 }} />
                            {bedrooms !== "" && bedrooms !== null && bedrooms !== undefined ? bedrooms : "0"} chambre{bedrooms > 1 ? "s" : ""}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ 
                    mt: 2,
                    bgcolor: scssVar.greyLighter,
                    borderRadius: "8px",
                    p: 1,
                    boxShadow: scssVar.shadow2
                }}>
                    <p style={{ textAlign: "center" }}>
                        Affinez dès maintenant votre estimation avec <b>{orgName}</b>
                    </p>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                        {orgAddress && ( 
                            <Box sx={{ display: "flex", alignItems: "center", color: scssVar.greyDarker, margin: "4px" }}>
                                {<WhereToVoteRounded style={{ fontSize: 19, marginRight: 4 }} />}
                                <p style={{ fontSize: 13 }}>{orgAddress}</p>
                            </Box>
                        )}
                        {orgEmail && (
                            <Box sx={{ display: "flex", alignItems: "center", color: scssVar.greyDarker, margin: "4px" }}>
                                {<MailRounded style={{ fontSize: 19, marginRight: 4 }} />}
                                <a href={`mailto:${orgEmail}`} style={{ fontSize: 13, color: "inherit" }}>{orgEmail}</a>
                            </Box>
                        )}
                        {orgPhone && (
                            <Box sx={{ display: "flex", alignItems: "center", color: scssVar.greyDarker, margin: "4px" }}>
                                {<PhoneRounded style={{ fontSize: 19, marginRight: 4 }} />}
                                <a href={`tel:${orgPhone}`} style={{ fontSize: 13, color: "inherit" }}>{orgPhone}</a>
                            </Box>
                        )}
                    </Box>
                </Box>
                {(orgEmail || orgPhone) && (    
                    <Fragment>
                        <p style={{ marginTop: 20, fontWeight: 800, textAlign: "center", fontSize: 20 }}>
                            Vous souhaitez faire une nouvelle estimation ?
                        </p>
                        <a
                            href={orgEmail ? `mailto:${orgEmail}` : `tel:${orgPhone}`}
                            style={{
                                marginTop: 20,
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: orgColor.main,
                                color: "white",
                                height: 40,
                                padding: "0 28px",
                                borderRadius: "40px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            Contacter un agent
                            <i 
                                className="iconoir-arrow-right"
                                style={{
                                    fontSize: 24,
                                }}
                            ></i>
                        </a>
                    </Fragment>
                )}
                {/* <Button 
                    type="button" 
                    onClick={onReset} 
                    sx={{ 
                        mt: 2,
                        ml: "auto",
                        mr: "auto",
                        bgcolor: orgColor.main,
                        "&:hover": {
                            bgcolor: orgColor.main,
                            filter: "brightness(93%)"
                        },
                        "&.Mui-active": {
                            bgcolor: orgColor.main,
                            filter: "brightness(87%)"
                        },
                    }}
                >
                    Nouvelle estimation
                    <i 
                        className="iconoir-arrow-right"
                        style={{
                            fontSize: 24,
                        }}
                    ></i>
                </Button> */}
                {orgLogo && (
                    <img src={orgLogo} alt={orgName} style={{ width: 90, margin: "20px auto" }} />
                )}
            </Box>
        </Box>
    )
}

export default Step06