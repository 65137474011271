import { Fragment, useState } from "react"
import useCoverBlob from "../../../../../photoUploader/hooks/useCoverBlob"
import PhotoUploader from "../../../../../photoUploader/PhotoUploader"
import { scssVar } from "../../../../../../app/scssVar"
import { Box, CircularProgress } from "@mui/material"
import { api } from "../../../../../../common/setupApi"
import { selectHousing } from "../../../../selectors"
import { useDispatch, useSelector } from "react-redux"
import { updateHousing } from "../../../../actions"
import { getCoverBlob, photosActions } from "../../../../../photoUploader/photosSlice"
import { addAlert } from "../../../../../alerts/alertsSlice"
import { defaultErrorMessage } from "../../../../../../common/utils"
import { v4 as uuid } from "uuid"
import { getCompressedImg, getCroppedImg } from "../../../../../photoUploader/settings"
import UploadButton from "./UploadButton"

const Placeholder = ({ children }) => (
    <Box sx={{ 
        position: "relative",
        width: "100%",
        overflow: "hidden",
        // border: "1px solid " + scssVar.grey, 
        background: "#ececec",
        borderRadius: "4px",
        "&:before": {
            content: '""',
            display: "block",
            paddingTop: "50%"
        }
    }}>
        <div style={{
            position: "absolute",
            top: 0, 
            left: 0,
            bottom: 0,
            right: 0,
        }}>
            <div style={{
                display: "table",
                width: "100%",
                height: "100%"
            }}>
                <span style={{
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle"
                }}>
                    {children}
                </span>
            </div>
        </div>
    </Box>
)

const Photo = () => {
    const dispatch = useDispatch()
    const housing = useSelector(selectHousing)
    const { src, loading, id } = useCoverBlob()
    const [deleteLoading, setDeleteLoading] = useState(false)

    const housingId = housing.id

    const handleDelete = async () => {
        setDeleteLoading(true)

        return api
            .delete(`v1/housing/delete-photos/${housingId}/${id}/`)
            .then(res => {
                dispatch(photosActions.deleteCoverBlobSucceeded({ housingId, id }))
                dispatch(updateHousing({ ...housing, photos_2: res.data }))
                dispatch(addAlert("La photo a bien été supprimée", "success"))
                setDeleteLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert(defaultErrorMessage, "error"))
                setDeleteLoading(false)
            })
    }

    if (id === undefined) {
        return <Placeholder>Aucune photo</Placeholder>
    }

    return (
        <PhotoUploader
            noUpdate
            onDelete={handleDelete}
            loadingBlob={loading}
            deleteLoading={deleteLoading}
            blob={src}
            ButtonEmpty={({ loadingBlob }) => {
                if (loadingBlob) {
                    return (
                        <Placeholder>
                            <CircularProgress size={15} sx={{ color: scssVar.primary }} />
                        </Placeholder>
                    )
                }
            }}
            ButtonBlob={({ handleOpenMenu, newPhotoLoading, loadingBlob, blob, deleteLoading }) => {
                return (
                    <div style={{ position: "relative" }}>
                        {deleteLoading && (
                            <CircularProgress 
                                size={25} 
                                sx={{ 
                                    color: scssVar.primary,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    margin: "auto"
                                }}
                            />
                        )}
                        <img
                            src={src}
                            onClick={(e) => {
                                if (deleteLoading) return
                                handleOpenMenu(e)
                            }}
                            alt={id}
                            style={{
                                cursor: deleteLoading ? "not-allowed" : "pointer",
                                opacity: deleteLoading ? .5 : 1,
                                width: "100%",
                            }}
                        />
                    </div>
                )
            }}
        />
    )
}

const CoverPhoto = () => {
    const dispatch = useDispatch()
    const housing = useSelector(selectHousing)
    const { id } = useCoverBlob()
    const [loading, setLoading] = useState(false)

    const housingId = housing.id

    const handleChange = async (cropArea, rotation, aspect, file) => {
        setLoading(true)

        return getCroppedImg(URL.createObjectURL(file), cropArea, rotation)
            .then(async (croppedImg) => {
                console.log(`originalFile size ${croppedImg.size / 1024 / 1024} MB`)

                return getCompressedImg(croppedImg)
                    .then(async (compressedImg) => {
                        console.log(`compressedFile size ${compressedImg.size / 1024 / 1024} MB`)

                        const fileId = uuid()
                        const formData = new FormData()
                        formData.append("housing_id", housingId)
                        formData.append("position", "cover")
                        formData.append("photos", compressedImg, fileId)

                        return api
                            .post("v1/housing/photos/", formData, { headers: { "content-type": "multipart/form-data" } })
                            .then(async (res) => {
                                dispatch(updateHousing({ ...housing, photos_2: res.data }))
                                dispatch(getCoverBlob())
                                dispatch(addAlert("La photo a bien été ajoutée", "success"))
                                setLoading(false)
                            })
                            .catch(err => {
                                console.log(err.response)
                                dispatch(addAlert(defaultErrorMessage, "error"))
                                setLoading(false)
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(addAlert("Une erreur est survenue lors de la compression de l'image, veuillez réessayer", "error"))
                        setLoading(false)
                    })
            })
            .catch(err => {
                console.log(err)
                dispatch(addAlert("Une erreur est survenue lors du redimensionnement de l'image, veuillez réessayer", "error"))
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <p>Photo présente sur la page de couverture de la synthèse PDF et sur les annonces ({id !== undefined ? "1" : "0"}/1)</p>
            <div style={{ margin: 10 }} />
            <Photo />
            {id === undefined && (
                <Fragment>
                    <div style={{ margin: 10 }} />
                    <PhotoUploader
                        aspect={16 / 9}
                        withCropper
                        onSubmit={handleChange}
                        newPhotoLoading={loading}
                        ButtonEmpty={({ newPhotoLoading }) => {
                            if (newPhotoLoading) {
                                return (
                                    <div style={{ width: "fit-content", marginLeft: "auto" }}>
                                        <CircularProgress size={15} sx={{ color: scssVar.primary }} />
                                    </div>
                                )
                            }

                            return <UploadButton />
                        }}
                    />
                </Fragment>
            )}
        </Fragment>
    )
}

export default CoverPhoto