import { Box, CircularProgress, Typography } from "@mui/material"
import { scssVar } from "../../../app/scssVar"
import { Fragment } from "react"

const StatBox = ({
    bgcolor,
    sx,
    icon,
    title,
    children,
    isLoading
}) => {
    return (
        <Box sx={Object.assign({}, {
            display: "flex",
            alignItems: !children ? "center" : "flex-start",
            width: "100%",
            bgcolor: bgcolor || "white",
            borderRadius: "16px",
            p: 2
        }, sx)}>
            <Box sx={{
                bgcolor: scssVar.primaryLighter,
                width: 42,
                minWidth: 42,
                maxWidth: 42,
                height: 42,
                minHeight: 42,
                maxHeight: 42,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
            }}>
                <i className={icon} style={{ fontSize: 22 }}></i>
            </Box>
            <Box sx={{ ml: 1, width: `calc(100% - 42px)` }}>
                {isLoading ? (
                    <CircularProgress size={20} sx={{ color: scssVar.primary, mt: 1.25, ml: 1 }} />
                ):(
                    <Fragment>
                        <Typography sx={{
                            fontWeight: 600,
                            color: "black",
                            fontFamily: scssVar.fontFamily
                        }}>
                            {title}
                        </Typography>
                        {children}
                    </Fragment>
                )}
            </Box>
        </Box>
    )
}

export default StatBox