import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputTextArea from "../../components/InputTextArea"

const ConditionsParticulieres = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const value = useSelector(selectMandatEditor2).conditions_particulieres

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(1000)
    }

    return (
        <div style={{ 
            display: "flex", 
        }}>
            <p style={{
                fontSize: 13,
                lineHeight: "13px",
                fontWeight: 600,
                marginRight: 3
            }}>
                Autres :
            </p>
            <div style={{ 
                flexGrow: 1, 
            }}>
                <InputTextArea
                    name="conditions_particulieres"
                    value={value || ""}
                    onChange={handleChange}
                    style={{
                        width: "100%"
                    }}
                />
            </div>
        </div>
    )
}

export default ConditionsParticulieres