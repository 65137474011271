import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { scssVar } from "../../../../app/scssVar"

//https://observablehq.com/d/641e6efa7f8b3f84

const getAreaGradient = (svg) => {
    const id = "area-gradient-horizontal"

    const gradient = svg.append("svg:defs")
        .append("svg:linearGradient")
        .attr("id", id)
        .attr("x1", "0%")
        .attr("y1", "50%")
        .attr("x2", "100%")
        .attr("y2", "50%")
        .attr("spreadMethod", "pad");

    gradient.append("svg:stop")
        .attr("offset", "0%")
        .attr("stop-color", scssVar.greenDark)
        .attr("stop-opacity", 0.4);

    gradient.append("svg:stop")
        .attr("offset", "55%")
        .attr("stop-color", scssVar.greenDark)
        .attr("stop-opacity", 1)

    return `url(#${id})`
}

const draw = (data, svgRef, {
    x = d => d.step, // given d in data, returns the (quantitative) x-value
    y = (d, i) => i, // given d in data, returns the (ordinal) y-value
    marginTop = 70, // the top margin, in pixels
    marginRight = 26, // the right margin, in pixels
    marginBottom = 26, // the bottom margin, in pixels
    marginLeft = 26, // the left margin, in pixels
    width = 1192, // the outer width of the chart, in pixels
    height, // outer height, in pixels
    xType = d3.scaleUtc, // type of x-scale
    xDomain, // [xmin, xmax]
    xRange = [marginLeft, width - marginRight], // [left, right]
    yPadding = 0.2, // amount of y-range to reserve to separate bars
    yDomain, // an array of (ordinal) y-values
    yRange, // [top, bottom]
    colorSvg = "transparent",
    colorValues = scssVar.greenDark, 
    colorLabels = scssVar.greyDark, 
    colorPercentages = scssVar.greenDark, 
    colorGrid = scssVar.greyLight,
    tooltipId = "funnel_horizontal_tooltip",
    labelId = "label_horiz",
} = {}) => {
    // if component remount, remove chart and redraw
    d3.select(svgRef.current).selectAll("*").remove();
    d3.selectAll(tooltipId).remove()

    const getData2 = () => {
        const ledge = 0.1;
        const result = [];
    
        data.forEach((point, index) => {
            const { step, value } = point;
    
            if (index !== 0) {
                result.push({ step: step - ledge, value });
            }
    
            result.push(point);
    
            if (index !== data.length - 1) {
                result.push({ step: step + ledge, value });
            } else {
                result.push({ step: step + 1, value });
            }
        })
    
        return result;
    };

    const data2 = getData2();

    // Compute values.
    const X = d3.map(data2, x);
    const Y = data.map(d => d.value).concat(data.map(d => -d.value)).sort((a, b) => b - a);

    // Compute default domains.
    if (xDomain === undefined) xDomain = d3.extent(X)
    if (yDomain === undefined) yDomain = d3.extent(Y)

    // Compute the default height.
    if (height === undefined) height = Math.ceil((new d3.InternSet(Y).size + yPadding) * 25) + marginTop + marginBottom;
    if (yRange === undefined) yRange = [height - marginBottom, marginTop];

    // Construct scales but not axes.
    const xScale = xType(xDomain, xRange);
    const yScale = d3.scaleLinear(yDomain, yRange);

    // Init svg
    const svg = d3.select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .style("-webkit-tap-highlight-color", "transparent")
        .style("overflow", "visible")
        .attr("style", `
            max-width: 100%; 
            height: auto; 
            height: intrinsic; 
            background-color: ${colorSvg};
        `)

    const areaGradient = getAreaGradient(svg);
    const curve = d3.curveCatmullRom.alpha(0.999999999);

    svg.append('path')
        .datum(data2)
        .attr('fill', areaGradient)
        .attr('d', d3.area()
            .curve(curve)
            .x(({ step }) => xScale(step))
            .y0(yScale(0))
            .y1(({ value }) => yScale(value))
        );

    svg.append('path')
        .datum(data2)
        .attr('fill', areaGradient)
        .attr('d',  d3.area()
            .curve(curve)
            .x(({ step }) => xScale(step))
            .y0(yScale(0))
            .y1(({ value }) => yScale(-value))
        );

    svg.selectAll('.values')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'values')
        .attr('x', ({ step }, i) => i ? xScale(step) + 10 : marginLeft)
        .attr('y', 20)
        .text(({ value }) => d3.format(',')(value))
        .attr('style', `
            fill: ${colorValues};
            font-size: 22px;
            font-weight: 600;
        `);

    svg.selectAll('.labels')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'labels')
        .attr('id', (_, i) => labelId + i)
        .attr('x', ({ step }, i) => i ? xScale(step) + 10 : marginLeft)
        .attr('y', 40)
        .text(({ label }) => label)
        .attr('style', `
            fill: ${colorLabels};
            font-size: 17px;
            font-weight: 600;
        `)
        
    svg.selectAll('.percentages')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'percentages')
        .attr('x', ({ step }) => xScale(step) + 10)
        .attr('y', 60)
        .text(({ value }, i) => {
            if (i) {
                const p = (value / data[0].value) || 0
                return d3.format('.1%')(p)
            }
            return ""
        })
        .attr('style', `
            fill: ${colorPercentages};
            font-size: 17px;
            font-weight: 600;
        `);

    svg.selectAll('.info-icon')
        .data(data)
        .enter()
        .append("g")
        .attr('class', 'info-icon')
        .attr("transform", ({ step }, i) => {
            const text = d3.select(`#${labelId}` + i)
            const { width } = text.node().getBBox()
            return `translate(${i ? (xScale(step) + width + 15) : (marginLeft + width + 4)},21)`
        })
        .append("path")
        .attr("stroke-width", "1.5")
        .attr("fill", scssVar.greyLighter)
        .attr("color", scssVar.greyDark)
        .attr("stroke", scssVar.greyDark)
        .attr("stroke-linecap", "round")
        .attr("stroke-linejoin", "round")
        .attr("d", "M12 11.5v5M12 7.51l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z")
        .on("pointerenter pointermove mouseover", pointerMoved)
        .on("touchend mouseout pointerleave", pointerLeft)
    
    svg.selectAll('line')
        .data(d3.range(2, data.length + 1))
        .enter()
        .append('line')
        .attr('x1', value => xScale(value))
        .attr('y1', 0)
        .attr('x2', value => xScale(value))
        .attr('y2', height - 10)
        .style('stroke-width', 1)
        .style('stroke', colorGrid)
        .style('fill', 'none');

    // TOOLTIP
    // https://observablehq.com/@d3/line-with-tooltip?collection=@d3/charts
    // https://gist.github.com/d3noob/97e51c5be17291f79a27705cef827da2
    const tooltip = d3.select("body")
        .append("div")
        .attr("id", tooltipId)
        .style("display", "none")
        .style("background", scssVar.greyDarker)
        .style("color", "white")
        .style("position", "absolute")
        .style("padding", "4px 6px")
        .style("pointer-event", "none")
        .style("font-size", "13px")
        .style("line-height", "16px")
        .style("border-radius", "4px")
        .style("border", "1px solid white")
        .style("z-index", 2000)

    function pointerMoved(event, i) {
        tooltip.style("display", null);

        const coords = [event.pageX, event.pageY]

        tooltip
            .html(`<p>${i.tooltip}</p>`)
            .style("left", coords[0] + 10 + "px")
            .style("top", coords[1] + 10 + "px");
    }

    function pointerLeft(i) {
        tooltip.style("display", "none");
    }

    return svg.node()
}

const FunnelHorizontal = ({ data, params }) => {
    const svgRef = useRef(null)

    useEffect(() => {
        draw(data, svgRef, params)
    }, [data, params])

    return <svg ref={svgRef} />
}

export default FunnelHorizontal