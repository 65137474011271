import TabSettings from "./tabSettings/TabSettings"
import { useDispatch, useSelector } from "react-redux";
import { apiOfferActions, getApiConfig, getApiLeads, selectApiTabIndex, getApiLongitudinalKpis, getApiKpis } from "./apiOfferSlice";
import { a11yProps, Tab, TabPanel, Tabs, TabTitle } from "../../common/components/Tabs";
import { Fragment, useEffect } from "react";
import TabLeads from "./tabLeads/TabLeads";
import TabIntegration from "./tabIntegration/TabIntegration";
import TabKpi from "./tabKpi/TabKpi";
import TabBilling from "./tabBilling/TabBilling";
import TabLeadsLabel from "./tabLeads/components/TabLeadsLabel";
import TabPanelLeadsAlertMessage from "./tabLeads/components/TabPanelLeadsAlertMessage";
import TabSettingsLabel from "./tabSettings/components/TabSettingsLabel";
import TabIntegrationLabel from "./tabIntegration/components/TabIntegrationLabel";
import TabIntegrationAlertMessage from "./tabIntegration/components/TabIntegrationAlertMessage";
import TabPanelSettingsAlertMessage from "./tabSettings/components/TabPanelSettingsAlertMessage";
import useAuth from "../auth/hooks/useAuth";

const tabs = [
    { 
        title: "Évaluations", 
        label: TabLeadsLabel,
        alertMessage: TabPanelLeadsAlertMessage,
        component: TabLeads, 
        access: "all"
    },
    { 
        title: "Statistiques", 
        component: TabKpi, 
        access: "all"
    },
    { 
        title: "Configuration", 
        label: TabSettingsLabel,
        component: TabSettings, 
        access: "admin"
    },
    { 
        title: "Intégration", 
        label: TabIntegrationLabel,
        alertMessage: TabIntegrationAlertMessage,
        component: TabIntegration, 
        access: "admin"
    },
    { 
        title: "Consommation", 
        component: TabBilling, 
        access: "admin"
    },
]

const SubApiOffer = () => {
    const dispatch = useDispatch()
    const tabIndex = useSelector(selectApiTabIndex)
    const { isAdmin } = useAuth().user

    useEffect(() => {
        dispatch(getApiLeads())
        if (isAdmin) {
            dispatch(getApiConfig())
        }
        dispatch(getApiKpis())
        dispatch(getApiLongitudinalKpis())
    }, [dispatch, isAdmin])

    const handleChange = (event, newValue) => {
        dispatch(apiOfferActions.setTabIndex(newValue))
    }

    if (!isAdmin) {
        return (
            <Fragment>
                <Tabs 
                    value={tabIndex} 
                    onChange={handleChange} 
                    aria-label="api tabs"
                    variant="scrollable"
                >
                    {tabs.filter(d => d.access === "all").map((d, i) =>
                        <Tab key={i} {...a11yProps(i)} label={d.title} />
                    )}
                </Tabs>
                {tabs.filter(d => d.access === "all").map((d, i) => {
                    const Component = d.component 
                    return (
                        <TabPanel value={tabIndex} index={i} key={i}>
                            <TabTitle title={d.title} />
                            <Component />
                        </TabPanel>
                    )
                })}
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Tabs 
                value={tabIndex} 
                onChange={handleChange} 
                aria-label="api tabs"
                variant="scrollable"
            >
                {tabs.map((d, i) => {
                    const Label = d.label
                    return (
                        <Tab 
                            key={i}
                            {...a11yProps(i)} 
                            label={
                                Label 
                                    ? <Label label={d.title} /> 
                                    : d.title
                            }  
                        />
                    )
                })}
            </Tabs>
            {tabs.map((d, i) => {
                const Component = d.component 
                const AlertMessage = d.alertMessage
                return (
                    <TabPanel value={tabIndex} index={i} key={i}>
                        <TabPanelSettingsAlertMessage />
                        {AlertMessage && <AlertMessage />}
                        <TabTitle title={d.title} />
                        <Component />
                    </TabPanel>
                )
            })}
        </Fragment>
    )
}

export default SubApiOffer