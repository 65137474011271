import { Box, Typography } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { appName, appVersion } from "../../../common/utils"
import AccountSection from "../components/AccountSection"

const initialState = {
    loading: false
}

const Update = () => {
    const newAppVersion = useSelector(state => state.appUpdate.newAppVersion)
    //const newAppVersion = false
    const waitingWorker = useSelector(state => state.appUpdate.waitingWorker)
    //const waitingWorker = true
    const [state, setState] = useState(initialState)
    const isUpdateAvailable = newAppVersion || waitingWorker

    const handleUpdate = () => {
        setState({ ...state, loading: true })

        waitingWorker.postMessage({ 
            type: "SKIP_WAITING" 
        })

        return setTimeout(() => {
            window.location.reload()
        }, [2000])
    }

    return (
        <AccountSection
            title="Mise à jour"
            isNotif={isUpdateAvailable}
        >
            <Box sx={{
                mb: "30px" 
            }}>
                {isUpdateAvailable ? (
                    <Box sx={{
                        textAlign: "center"
                    }}>
                        <Typography sx={{ 
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            fontWeight: 600,
                            mb: 1
                        }}>
                            Une mise à jour d'{appName} est disponible
                        </Typography>

                        {newAppVersion && (
                            <Typography sx={{ 
                                fontFamily: scssVar.fontFamily,
                                color: scssVar.success, 
                                fontWeight: 600,
                                mb: 1
                            }}>
                                Version {newAppVersion}
                            </Typography>
                        )}

                        {waitingWorker ? (
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Button
                                    onClick={handleUpdate}
                                    disabled={state.loading}
                                    loading={state.loading}
                                >
                                    Mettre à jour
                                    <i
                                        className="iconoir-refresh"
                                        style={{
                                            fontSize: 20,
                                            marginLeft: 5
                                        }}
                                    ></i>
                                </Button>
                            </Box>
                        ):(
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                color: "black"
                            }}>
                                Cette mise à jour nécessite un redémarrage de l'application.
                                Pour l'obtenir, fermez complètement l'application puis relancez la.
                            </Typography>
                        )}
                    </Box>
                ):(
                    <Box sx={{
                        textAlign: "center"
                    }}>
                        <Typography sx={{ 
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDark,
                            mb: 1
                        }}>
                            {appName} v{appVersion}
                        </Typography>
                        <Typography sx={{ 
                            fontFamily: scssVar.fontFamily,
                            color: scssVar.greyDark 
                        }}>
                            {appName} est à jour
                        </Typography>
                    </Box>
                )}
            </Box>
        </AccountSection>
    )
}

export default Update