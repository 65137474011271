import { Box, Collapse, Grid, Switch } from "@mui/material"
import RequestsCount from "./components/RequestsCount"
import Plan from "./components/Plan"
import NextBill from "./components/NextBill"
import { useDispatch, useSelector } from "react-redux"
import { apiOfferActions, selectApiLongitudinalKpis } from "../apiOfferSlice"
import LoaderGif from "../../../common/components/LoaderGif"
import { scssVar } from "../../../app/scssVar"
import Longitudinal from "./components/Longitudinal"
import { SignalCellularAltRounded } from "@mui/icons-material"

const Wrapper = ({ children }) => <Box sx={{ width: "100%", maxWidth: { xs: 500, md: "100%" }}}>{children}</Box>

const ToggleDetails = () => {
    const dispatch = useDispatch()
    const { displayBillingDetails } = useSelector(selectApiLongitudinalKpis)

    const handleChange = () => {
        const newValue = !displayBillingDetails

        dispatch(apiOfferActions.setDisplayBillingDetails(newValue))
        
        if (newValue) {
            const el = document.getElementById("details_requests_anchor")

            setTimeout(() => {
                el.scrollIntoView({ behavior: "smooth" })
            }, [500])
        }
    }

    return (
        <Wrapper>
            <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                bgcolor: scssVar.greyLighter, 
                borderRadius: "16px",
                pt: 2,
                pb: 2,
                pl: 2,
                pr: "4px",
            }}>
                <Box sx={{
                    bgcolor: scssVar.primaryLighter,
                    width: 42,
                    minWidth: 42,
                    maxWidth: 42,
                    height: 42,
                    minHeight: 42,
                    maxHeight: 42,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "6px",
                    fontSize: 24,
                    color: scssVar.black,
                    mr: 1
                }}>
                    <SignalCellularAltRounded />
                </Box>
                <p style={{ flexGrow: 1, fontWeight: 600, lineHeight: "19px", fontFamily: scssVar.fontFamily }}>
                    Afficher le détail des requêtes
                </p>
                <Switch
                    checked={displayBillingDetails} 
                    onChange={handleChange} 
                    sx={{ 
                        '& .MuiSwitch-switchBase': {
                            '& + .MuiSwitch-track': {
                            },
                            "&.Mui-checked": {
                                color: scssVar.success,
                                '& + .MuiSwitch-track': {
                                    backgroundColor: scssVar.success,
                                }
                            },
                        }
                    }}
                />
            </Box>
        </Wrapper>
    )
}

const TabBilling = () => {
    const { isLoading, error, displayBillingDetails } = useSelector(selectApiLongitudinalKpis)

    if (isLoading) {
        return <LoaderGif />
    }

    if (error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer</p>
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                <Wrapper>
                    <NextBill />
                </Wrapper>
                <Box sx={{ m: 2 }} />
                <Wrapper>
                    <Plan />
                </Wrapper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <Wrapper>
                    <RequestsCount />
                </Wrapper>
                <Box sx={{ m: 2 }} />
                <ToggleDetails />
            </Grid>
            <Grid item xs={12} id="details_requests_anchor">
                <Collapse in={displayBillingDetails} unmountOnExit timeout={350}>
                    <Longitudinal />
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default TabBilling