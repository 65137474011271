import { useSelector } from "react-redux"
import { selectMandatSaving } from "../mandat/mandatSlice"
import useJssInput from "../hooks/useJssInput"
import { selectTracfinSaving } from "../buy/tracfin/tracfinSlice"

const InputTextArea = ({ 
    style, 
    name, 
    placeholder,
    required,
    onChange,
    value,
    disabled
}) => {
    const jss = useJssInput()
    const savingMandat = useSelector(selectMandatSaving)
    const savingTracin = useSelector(selectTracfinSaving)

    return (
        <textarea
            style={Object.assign({}, jss, {
                resize: "none",
                height: 45,
                fontSize: 11,
                lineHeight: "13px",
                overflow: "hidden",
                padding: 2
            }, style)}
            name={name}
            autoComplete="off"
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            value={value}
            disabled={savingMandat || savingTracin || disabled}
        >
        </textarea>
    )
}

export default InputTextArea