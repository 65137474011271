import { Box, CircularProgress, Divider } from "@mui/material"
import { scssVar } from "../../../app/scssVar"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Fragment } from "react";

const Loading = () => (
    <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }}>
        <CircularProgress
            size={20}
            sx={{
                color: scssVar.primary
            }}
        />
    </Box>
)

const Reader = ({
    src,
    onDocumentLoadSuccess,
    loadingPage,
    numberOfPages,
    scale,
    onPageLoadSuccess
}) => {
    return (
        <Box sx={{
            p: 1,
            mt: 4,
            overflowX: "scroll",
            backgroundColor: scssVar.greyLight,
        }}>
            <Document
                width="100%"
                file={src}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Loading />}
            >
                <Box sx={{
                    width: "fit-content", 
                    margin: "0 auto"
                }}>
                    {loadingPage && ( <Loading /> )}
                    {[...Array(numberOfPages).keys()].map(d => 
                        <Fragment key={d + 1}>
                            <Page
                                renderAnnotationLayer={false}
                                pageNumber={d + 1} 
                                width={1276}
                                scale={scale}
                                loading={""}
                                onLoadSuccess={onPageLoadSuccess}
                            />
                            <Divider />
                        </Fragment>
                    )}
                </Box>
            </Document>
        </Box>
    )
}

export default Reader