const initialState = {
    org: {
        fetched: false,
        loading: false,
        error: null,
        id: null,
        name: null,
        tCard: null,
        email: null,
        color: "rgb(69, 39, 116)",
        address: null,
        phone: null,
        website: null,
        desc: null,
        logo: null,
        //
        org_statut: null,
        org_capital: null,
        org_rcs: null,
        org_siret: null,
        assurance_nom: null,
        assurance_adresse: null,
        garantie_maniement_fonds: null, // Bool
        garantie_organisme: null,
        garantie_montant_caution: null,
        frais_agence_absolu_minimum: null,
        org_cci: null,
        org_directeur_nom: null,
        org_directeur_prenom: null,
        org_directeur_email: null,
        org_mediateur_nom: null,
        org_mediateur_site: null, // Email
        org_rgpd_email: null,

        //
        updateLoading: false,
        updateError: null,
        updateLogoLoading: false,
        updateLogoError: null,
        deleteLogoLoading: false,
        deleteLogoError: null
    },
    teams: {
        loading: false,
        error: null,
        items: {},
        fetched: false,
        addLoading: false,
        addError: false
    },
    customerPortal: {
        loading: false,
        error: null,
    },
    cancelSub: {
        loading: false,
        error: null
    },
}

const account = (state = initialState, action) => {
    switch (action.type) {

        case "account/delete_org_logo_loading":
            return {
                ...state,
                org: {
                    ...state.org,
                    deleteLogoLoading: true,
                    deleteLogoError: null
                }
            }

        case "account/delete_org_logo_success":
            return {
                ...state,
                org: {
                    ...state.org,
                    deleteLogoLoading: false,
                    logo: null
                }
            }

        case "account/delete_org_logo_failure":
            return {
                ...state,
                org: {
                    ...state.org,
                    deleteLogoLoading: false,
                    deleteLogoError: action.payload
                }
            }

        case "account/set_org_logo_loading":
            return {
                ...state,
                org: {
                    ...state.org,
                    updateLogoLoading: true,
                    updateLogoError: null
                }
            }

        case "account/set_org_logo_success":
            return {
                ...state,
                org: {
                    ...state.org,
                    updateLogoLoading: false,
                    logo: action.payload
                }
            }

        case "account/set_org_logo_failure":
            return {
                ...state,
                org: {
                    ...state.org,
                    updateLogoLoading: false,
                    updateLogoError: action.payload
                }
            }

        case "account/update_org_loading":
            return {
                ...state,
                org: {
                    ...state.org,
                    updateLoading: true,
                    updateError: null
                }
            }

        case "account/update_org_success":
            return {
                ...state,
                org: {
                    ...state.org,
                    updateLoading: false,
                    id: action.payload.org_id,
                    name: action.payload.org_name,
                    tCard: action.payload.t_card,
                    email: action.payload.org_email,
                    color: action.payload.org_color || initialState.org.color,
                    address: action.payload.org_address,
                    phone: action.payload.org_phone,
                    website: action.payload.org_website,
                    desc: action.payload.org_description,
                    org_statut: action.payload.org_statut,
                    org_capital: action.payload.org_capital,
                    org_rcs: action.payload.org_rcs,
                    org_siret: action.payload.org_siret,
                    assurance_nom: action.payload.assurance_nom,
                    assurance_adresse: action.payload.assurance_adresse,
                    garantie_maniement_fonds: action.payload.garantie_maniement_fonds, // Bool
                    garantie_organisme: action.payload.garantie_organisme,
                    garantie_montant_caution: action.payload.garantie_montant_caution,
                    frais_agence_absolu_minimum: action.payload.frais_agence_absolu_minimum,
                    org_cci: action.payload.org_cci,
                    org_directeur_nom: action.payload.org_directeur_nom,
                    org_directeur_prenom: action.payload.org_directeur_prenom,
                    org_directeur_email: action.payload.org_directeur_email,
                    org_mediateur_site: action.payload.org_mediateur_site, // Email
                    org_mediateur_nom: action.payload.org_mediateur_nom,
                    org_rgpd_email: action.payload.org_rgpd_email
                }
            }

        case "account/update_org_failure":
            return {
                ...state,
                org: {
                    ...state.org,
                    updateLoading: false,
                    updateError: action.payload
                }
            }

        case "account/get_org_loading":
            return {
                ...state,
                org: {
                    ...state.org,
                    loading: true,
                    error: null,
                    fetched: true
                }
            }

        case "account/get_org_success":
            return {
                ...state,
                org: {
                    ...state.org,
                    loading: false,
                    id: action.payload.org_id,
                    name: action.payload.org_name,
                    tCard: action.payload.t_card,
                    email: action.payload.org_email,
                    color: action.payload.org_color || initialState.org.color,
                    address: action.payload.org_address,
                    phone: action.payload.org_phone,
                    website: action.payload.org_website,
                    desc: action.payload.org_description,
                    logo: action.payload.org_logo,
                    org_statut: action.payload.org_statut,
                    org_capital: action.payload.org_capital,
                    org_rcs: action.payload.org_rcs,
                    org_siret: action.payload.org_siret,
                    assurance_nom: action.payload.assurance_nom,
                    assurance_adresse: action.payload.assurance_adresse,
                    garantie_maniement_fonds: action.payload.garantie_maniement_fonds, // Bool
                    garantie_organisme: action.payload.garantie_organisme,
                    garantie_montant_caution: action.payload.garantie_montant_caution,
                    frais_agence_absolu_minimum: action.payload.frais_agence_absolu_minimum,
                    org_cci: action.payload.org_cci,
                    org_directeur_nom: action.payload.org_directeur_nom,
                    org_directeur_prenom: action.payload.org_directeur_prenom,
                    org_directeur_email: action.payload.org_directeur_email,
                    org_mediateur_site: action.payload.org_mediateur_site, // Email
                    org_mediateur_nom: action.payload.org_mediateur_nom,
                    org_rgpd_email: action.payload.org_rgpd_email
                }
            }

        case "account/get_org_failure":
            return {
                ...state,
                org: {
                    ...state.org,
                    loading: false,
                    error: action.payload,
                    fetched: false
                }
            }

        case "account/add_team_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    addLoading: true,
                    addError: null
                }
            }

        case "account/add_team_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    addLoading: false,
                    addError: null,
                    items: {
                        ...state.teams.items,
                        data: [...state.teams.items.data, {
                            team_id: action.payload.id,
                            team_name: action.payload.name,
                            users: []
                        }]
                    }
                }
            }

        case "account/add_team_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    addLoading: false,
                    addError: action.payload,
                }
            }

        case "account/delete_team_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                deleteLoading: true,
                                deleteError: null
                            } : d
                        )
                    }
                }
            }

        case "account/delete_team_success": {
            const dataWithTeamRemoved = state.teams.items.data.filter(d => d.team_id !== action.payload.teamId)
            const dataWithUsersTransfered = dataWithTeamRemoved.map(d => d.team_id === action.payload.transferTeamId ? {
                ...d,
                users: [...d.users].concat(action.payload.users)
            } :d)

            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: dataWithUsersTransfered
                    }
                }
            }
        }

        case "account/delete_team_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                deleteLoading: false,
                                deleteError: action.payload.error
                            } : d
                        )
                    }
                }
            }

        case "account/add_email":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    ...d.sendEmails,
                                    items: [...d.sendEmails?.items || [], action.payload.email]
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/change_email_value":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    ...d.sendEmails,
                                    items: d.sendEmails.items.map(email => email.id === action.payload.email.id 
                                        ? { ...email, value: action.payload.email.value }
                                        : email
                                    )
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/remove_email":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    ...d.sendEmails,
                                    items: d.sendEmails.items.filter(email => email.id !== action.payload.emailId)
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/reset_emails":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    loading: false,
                                    error: null,
                                    items: []
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/send_emails_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    ...d.sendEmails,
                                    loading: true,
                                    error: null
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/send_emails_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    ...d.sendEmails,
                                    loading: false,
                                    items: []
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/send_emails_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                sendEmails: {
                                    ...d.sendEmails,
                                    loading: false,
                                    error: action.payload.error
                                }
                            } : d
                        )
                    }
                }
            }

        case "account/change_team_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.oldTeamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId ? {
                                    ...u,
                                    changeTeamLoading: true,
                                    changeTeamError: null
                                } : u)
                            } : d
                        )
                    }
                }
            }

        case "account/change_team_success/remove": 
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.oldTeamId 
                            ? { 
                                ...d, 
                                users: d.users.filter(u => u.id !== action.payload.userId)
                            } : d
                        )
                    }
                }
            }

        case "account/change_team_success/add": {
            const { changeTeamLoading, changeTeamError, ...user } = action.payload.user
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.newTeamId 
                            ? { 
                                ...d, 
                                users: [...d.users, user]
                            } : d
                        )
                    }
                }
            }
        }

        case "account/change_team_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.oldTeamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId ? {
                                    ...u,
                                    changeTeamLoading: false,
                                    changeTeamError: action.payload.error
                                } : u)
                            } : d
                        )
                    }
                }
            }

        case "account/delete_user_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId ? {
                                    ...u,
                                    deleteUserLoading: true,
                                    deleteUserError: null
                                } : u)
                            } : d
                        )
                    }
                }
            }

        case "account/delete_user_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    deleteUserLoading: false,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                users: d.users.filter(u => u.id !== action.payload.userId)
                            } : d
                        )
                    }
                }
            }

        case "account/delete_user_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId ? {
                                    ...u,
                                    deleteUserLoading: false,
                                    deleteUserError: action.payload.error
                                } : u)
                            } : d
                        )
                    }
                }
            }

        case "account/activate_user_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId
                                    ? { 
                                        ...u, 
                                        activateUserLoading: true,
                                        activateUserError: null
                                    } : u
                                )
                            } : d
                        )
                    }
                }
            }

        case "account/activate_user_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId
                                    ? { 
                                        ...u, 
                                        active: true,
                                        activateUserLoading: false,
                                    } : u
                                )
                            } : d
                        )
                    }
                }
            }

        case "account/activate_user_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                users: d.users.map(u => u.id === action.payload.userId
                                    ? { 
                                        ...u, 
                                        activateUserLoading: false,
                                        activateUserError: action.payload.error
                                    } : u
                                )
                            } : d
                        )
                    }
                }
            }

        case "account/select_user":
            return {
                ...state,
                selectedUser: action.payload
            }

        case "account/cancel_sub_loading":
            return {
                ...state,
                cancelSub: {
                    ...state.cancelSub,
                    loading: true,
                    error: null
                }
            }

        case "account/cancel_sub_success":
            return {
                ...state,
                cancelSub: {
                    ...state.cancelSub,
                    loading: false,
                }
            }

        case "account/cancel_sub_failure":
            return {
                ...state,
                cancelSub: {
                    ...state.cancelSub,
                    loading: false,
                    error: action.payload
                }
            }

        case "account/get_customer_portal_loading":
            return {
                ...state,
                customerPortal: {
                    ...state.customerPortal,
                    loading: true,
                    error: null,
                }
            }

        case "account/get_customer_portal_success":
            return {
                ...state,
                customerPortal: {
                    ...state.customerPortal,
                    loading: false,
                }
            }

        case "account/get_customer_portal_failure":
            return {
                ...state,
                customerPortal: {
                    ...state.customerPortal,
                    loading: false,
                    error: action.payload,
                }
            }

        case "account/get_teams_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    loading: true,
                    error: null,
                    fetched: true
                }
            }

        case "account/get_teams_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    loading: false,
                    items: action.payload
                }
            }

        case "account/get_teams_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    loading: false,
                    error: action.payload,
                    fetched: false
                }
            }

        case "account/get_register_link_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                getLinkLoading: true,
                                getLinkError: null
                            } : d
                        )
                    }
                }
            }

        case "account/get_register_link_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                getLinkLoading: false,
                                link: action.payload.link
                            } : d
                        )
                    }
                }
            }

        case "account/get_register_link_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                getLinkLoading: false,
                                getLinkError: action.payload.error
                            } : d
                        )
                    }
                }
            }

        case "account/invalidate_link_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                invalidateLoading: true,
                                invalidateError: null
                            } : d
                        )
                    }
                }
            }

        case "account/invalidate_link_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                invalidateLoading: false,
                                link: null
                            } : d
                        )
                    }
                }
            }

        case "account/invalidate_link_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                invalidateLoading: false,
                                invalidateError: action.payload.error
                            } : d
                        )
                    }
                }
            }

        case "account/rename_team_loading":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? {
                                ...d,
                                renameLoading: true,
                                renameError: null
                            } : d
                        )
                    }
                }
            }

        case "account/rename_team_success":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                team_name: action.payload.newTeamName,
                                renameLoading: false,
                            } 
                            : d
                        )
                    }
                }
            }

        case "account/rename_team_failure":
            return {
                ...state,
                teams: {
                    ...state.teams,
                    items: {
                        ...state.teams.items,
                        data: state.teams.items.data.map(d => d.team_id === action.payload.teamId 
                            ? { 
                                ...d, 
                                renameLoading: false,
                                renameError: action.payload.error
                            } 
                            : d
                        )
                    }
                }
            }

        case "auth/logout": 
            return initialState

        default: 
            return state
    }
}

export default account