import Logo from "../../../../common/components/Logo"

const AdReleasedBy = ({ bottom }) => (
    <div style={{
        position: "absolute",
        right: 35,
        bottom: bottom || 170
    }}>
        <p style={{
            textAlign: "center",
            fontSize: 8,
            fontWeight: 600,
        }}>
            Réalisé par
        </p>
        <Logo sx={{ wrapper: { width: 120 } }} />
    </div>
)

export default AdReleasedBy