import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import Margin from "../../components/Margin"
import { mandatActions, selectMandatEditorDiag } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputCheckbox from "../../components/InputCheckbox"

const Diag = () => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const diag = useSelector(selectMandatEditorDiag)

    const choices = [
        { label: "PEB (Plan d'Exposition au bruit)", name: "diag_peb", checked: diag.peb },
        { label: "DPE", name: "diag_dpe", checked: diag.dpe },
        { label: "Amiante (avant 07/97)", name: "diag_amiante", checked: diag.amiante },
        { label: "Plomb (avant janv 1949)", name: "diag_plomb", checked: diag.plomb },
        { label: "Etat antiparasitaire", name: "diag_antiparasitaire", checked: diag.antiparasitaire },
        { label: "Électricité (plus de 15 ans)", name: "diag_elec_15ans", checked: diag.elec },
        { label: "Gaz (plus de 15 ans)", name: "diag_gaz_15ans", checked: diag.gaz },
        { label: "ERP", name: "diag_erp", checked: diag.erp },
        { label: "Raccordement au réseau d’assainissement urbain", name: "diag_assainissement", checked: diag.assain },
        { label: "Mérule", name: "diag_merule", checked: diag.merule },
    ]

    const handleChange = (e) => {
        const { name, checked } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value: checked }))
        saveMandat(0)
    }

    return (
        <Fragment>
            <p style={{
                fontSize: 11.5,
                lineHeight: "11.5px",
            }}>
                Le mandant s’engage à accepter tout acquéreur présenté par le mandataire même avec une condition suspensive de prêt <i>(loi n°79-596 du 13.07.1979)</i>,
            </p>
            <Margin m={3} />
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <p style={{
                    fontSize: 10,
                    lineHeight: "10px",
                }}>
                    accepte d’acquérir aux prix et conditions de ce mandat. Diagnostics obligatoires : 
                </p>
                {choices.map((c, i) => 
                    <div key={i} style={{ display: "flex", alignItems: "center", marginLeft: 3 }}>
                        <InputCheckbox 
                            name={c.name} 
                            checked={c.checked || ""} 
                            onChange={handleChange} 
                        />
                        <p style={{
                            fontSize: 9.5,
                            lineHeight: "9.5px",
                            marginLeft: 3
                        }}>
                            {c.label}
                        </p>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default Diag