import { createSlice } from "@reduxjs/toolkit";

const emptyBuyer = { civilite: "", nom_naissance: "", prenom: "", date_naissance: "", lieu_naissance: "", nationalite: "", profession: "" }

const initialState = {
    offre_type: "prix", // || nego
    acheteur1: emptyBuyer,
    acheteur2: emptyBuyer,
    adresse_domicile: "",
    cp_domicile: "",
    ville_domicile: "",
    societe: "",
    nego_prix_presentation: "",
    nego_prix_net: "",
    nego_frais_agence: "",
    pret_montant: "",
    pret_duree: "",
    pret_taux: "",
    apport_montant: "",
    apport_origine: "",
    condition: "avec_suspensive", // sans_suspensive || achat_comptant
    autre_condition: "",
    versement_montant: "",
    versement_date: "",
    document_lieu: "",
    document_date: "",
    document_validite: "",
    promesse_date: ""
}

const buyOfferSlice = createSlice({
    name: "buyOffer",
    initialState,
    reducers: {
        setField: (state, action) => {
            state[action.payload.name] = action.payload.value
        },
        setBuyer: (state, action) => {
            state[action.payload.id][action.payload.name] = action.payload.value
        },
        reset: () => {
            return initialState
        }
    },
    extraReducers: (builder) => builder.addCase("auth/logout", () => initialState)
})

export const buyOfferActions = buyOfferSlice.actions

export const selectBuyOfferEditor = (state) => state.buyOffer

export default buyOfferSlice.reducer