import { axisBottom, axisLeft, bisectLeft, extent, format, scaleLinear, scaleUtc, select, timeMonday, zoom, pointer, timeMonth } from "d3";
import { addDays, getDateToMonth, getDateToWeek, getDateToString } from "../utils";

export default class LongitudinalStackedChart {
    constructor(fontFamily, width) {
        this.fontFamily = fontFamily
        this.width = width
        this.height = 250
        this.margin = { top: 25, right: 20, bottom: 65, left: 20 }
        this.svg = null
        this.x = null
        this.y = null
        this.xAxis = null
        this.yAxis = null
        this.xDomain = null
        this.xDomainLength = 0
        this.pointer = 0
        this.idHoverBar = null
    }

    initSvg(ref) {
        select(ref).selectAll('*').remove()

        this.svg = select(ref)
            .append("svg")
            .attr("width", this.width)
            .attr("height", this.height)
            .attr("viewBox", `0 0 ${this.width} ${this.height}`)

        return this.svg
    }

    initX(xDomain) {
        this.xDomain = xDomain
        const ext = extent(xDomain)
        const start = addDays(ext[0], -3)
        const end = addDays(ext[1], 3)

        this.x = scaleUtc()
            .domain([start, end])
            .range([this.margin.left, this.width - this.margin.right])

        return this.x
    }

    initAxisX() {
        this.xAxis = this.svg.append("g")
            .attr("class", "x-axis")
            .style("font-family", this.fontFamily)
            .style("color", "black")
            .style("font-size", 9.5)
            .style('font-weight', 600)
            .attr("transform", `translate(0,${this.height - this.margin.bottom})`)
    }

    updateAxisX(formatting) {
        this.xAxis
            .call(axisBottom(this.x)
                .tickSizeOuter(0)
                .ticks(formatting === "month" ? timeMonth : timeMonday)
                .tickFormat(d => {
                    switch (formatting) {
                        default: return d
                        case "day": return getDateToString(d)
                        case "week": return getDateToWeek(d)
                        case "month": return getDateToMonth(d)
                    }
                })
            )

        this.xAxis
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", "-.2em")
            .attr("transform", "rotate(-65)")
    }

    initY(minY, maxY) {
        this.y = scaleLinear()
            .domain([minY, maxY])
            .range([this.height - this.margin.bottom, this.margin.top])

        return this.y
    }

    initAxisY() {
        this.yAxis = this.svg.append("g")
            .attr("class", "y-axis")
            .style("font-family", this.fontFamily)
            .style("color", "black")
            .style("font-size", 9.5)
            .style('font-weight', 600)
            .attr("transform", `translate(${this.margin.left},0)`)
    }

    updateAxisY(formatting) {
        this.yAxis
            .call(axisLeft(this.y)
                .ticks(5)
                .tickFormat(d => {
                    switch (formatting) {
                        default: return format(".0f")(d)
                    }
                })
            )
    }

    initGrid(id) {
        select(`#${id}_grid`).remove()
        this.svg.append("g")
            .attr("id", `${id}_grid`)
            .attr("stroke", "grey")
            .attr("stroke-opacity", .25)
            .selectAll("line")
            .data(this.y.ticks(5))
            .join("line")
            .attr("x1", this.x(this.x.domain()[0]))
            .attr("x2", this.x(this.x.domain()[1]))
            .attr("y1", d => this.y(d))
            .attr("y2", d => this.y(d))
    }

    initBarWidth(xDomain) {
        this.xDomainLength = xDomain.length
    }

    updateBarWidth() {
        const bandWidth = ((this.x.range()[1] - this.x.range()[0]) / this.xDomainLength)
        const padding = bandWidth / 1.25
        const theWidth = bandWidth <= 6 ? 3 : bandWidth - padding
        return theWidth
    }

    bisect(data, date) {
        const i = bisectLeft(data, date);
        const a = data[i - 1], b = data[i];
        return date - a > b - date ? b : a;
    }

    initZoom(zoomCallBack, customXdomain) {
        //const xDomainLength = customXdomain.length
        //const maxZoom = xDomainLength / 13
        const maxZoom = 32

        const handleZoom = (event) => {
            this.x.range([this.margin.left, this.width - this.margin.right]
                .map(d => event.transform.applyX(d))
            )

            const lookup = this.bisect(customXdomain, this.x.invert(this.pointer))

            select(`#${this.idHoverBar}_hover_bar`)
                .attr("display", "none")

            if (lookup) {
                select(`#${this.idHoverBar}_hover_bar`)
                    .attr("display", null)
                    
                select(`#${this.idHoverBar}_hover_bar`)
                    // .attr("x", this.x(lookup) - 1)
                    // .attr("width", 2)
                    // on doit décaler la barre vers la gauche pour qu'elle soit alignée aux x ticks
                    .attr("x", this.x(lookup) - this.updateBarWidth() / 2)
                    .attr("width", this.updateBarWidth())
            } else {
                select(`#${this.idHoverBar}_hover_bar`)
                    .attr("display", "none")
            }

            zoomCallBack(lookup)
        }

        const svgZoom = zoom()
            .scaleExtent([1, maxZoom])
            .extent([[this.margin.left, 0], [this.width - this.margin.right, this.height]])
            .translateExtent([[this.margin.left, - Infinity], [this.width - this.margin.right, Infinity]])
            .on("zoom", handleZoom);

        this.svg.call(svgZoom)
            .on("dblclick.zoom", null)
            .transition()
            .duration(750);
    }

    initHover(id, customXdomain, onMoveCallback) {
        this.idHoverBar = id
        let clickMode = false

        const hover = (svg, x) => {
            const hoverBar = svg.append("g").attr("display", null)

            hoverBar.append("rect")
                .attr("id", `${id}_hover_bar`)
                .attr("fill", "grey")
                .attr("opacity", "0.2")
                .attr("x", -100)
                .attr("y", 5)
                .attr("height", this.height - this.margin.bottom - 5)

                const handleLeave = () => {}

                const handleEnter = () => {
                    select(`#${this.idHoverBar}_hover_bar`)
                        .attr("display", null)
                }
                
                const handleMove = (event) => {
                    if (clickMode) return
                    else {
                        event.preventDefault()

                        const SVGMousePosition = pointer(event)[0]
                        const lookup = this.bisect(customXdomain, x.invert(SVGMousePosition))

                        if (lookup) {
                            select(`#${this.idHoverBar}_hover_bar`)
                                .attr("display", null)
                            hoverBar
                                .select("rect")
                                // .attr("x", x(lookup) - 1)
                                // .attr("width", 2)
                                // on doit décaler la barre vers la gauche pour qu'elle soit alignée aux x ticks
                                .attr("x", x(lookup) - this.updateBarWidth() / 2)
                                .attr("width", this.updateBarWidth())

                            this.pointer = SVGMousePosition
                        }

                        onMoveCallback(lookup)
                    }
                }

                const handleClick = (event) => {
                    event.preventDefault()

                    const SVGMousePosition = pointer(event)[0]
                    const lookup = this.bisect(customXdomain, x.invert(SVGMousePosition))

                    if (lookup) {
                        select(`#${this.idHoverBar}_hover_bar`)
                            .attr("display", null)
                        hoverBar
                            .select("rect")
                            // .attr("x", x(lookup) - 1)
                            // .attr("width", 2)
                            // on doit décaler la barre vers la gauche pour qu'elle soit alignée aux x ticks
                            .attr("x", x(lookup) - this.updateBarWidth() / 2)
                            .attr("width", this.updateBarWidth())

                        clickMode = !clickMode

                        this.pointer = SVGMousePosition
                    }

                    onMoveCallback(lookup)
                }

                if ("ontouchstart" in document) svg
                    .style("-webkit-tap-highlight-color", "transparent")
                    .on("touchstart", handleEnter)
                    .on("touchmove", handleMove)
                    .on("touchend", handleLeave)
                    .on("click", handleClick)
                else svg
                    .on("mouseenter", handleEnter)
                    .on("mousemove", handleMove)
                    .on("mouseleave", handleLeave)
                    .on("click", handleClick)
            }

            this.svg.call(hover, this.x)
    }
}