import { Box, Collapse, IconButton, ListItemButton, ListItemText, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuid } from 'uuid';
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { sendEmails } from "../actions"

const initialState = {
    collapsed: false,
}

const SendEmail = ({ team }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const teamId = team.team_id
    const emails = team.sendEmails?.items || []
    const loading = team.sendEmails?.loading || false

    const handleCollapse = () => {
        setState({ ...state, collapsed: !state.collapsed })
    }

    const handleAdd = () => {
        dispatch({ 
            type: "account/add_email", 
            payload: { 
                teamId,
                email: {
                    id: uuid(), 
                    value: "" 
                }
            }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target

        dispatch({ 
            type: "account/change_email_value", 
            payload: { 
                teamId,
                email: {
                    id: name,
                    value
                }
            }
        })
    }

    const handleRemove = (id) => (event) => {
        dispatch({ 
            type: "account/remove_email", 
            payload: { 
                teamId,
                emailId: id
            }
        })
    }

    const handleCancel = () => {
        dispatch({ 
            type: "account/reset_emails",
            payload: { 
                teamId
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(sendEmails(teamId, emails))
    }

    return (
        <Box>
            <ListItemButton
                component="div"
                onClick={handleCollapse}
                sx={{
                    pl: 0,
                    pr: 0
                }}
            >
                <ListItemText
                    primary="Inviter des utilisateurs"
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black",
                            fontWeight: 700
                        },
                    }}
                />
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark,
                        transform: `rotate(${state.collapsed ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </ListItemButton>
            <Collapse in={state.collapsed} timeout="auto" unmountOnExit>
                <form method="POST" onSubmit={handleSubmit}>
                    <Typography sx={{
                        fontSize: "14px",
                        color: scssVar.greyDark,
                        fontFamily: scssVar.fontFamily,
                        mb: "0.5em"
                    }}>
                        Envoi de liens d'inscriptions par email
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        {emails.map(d => 
                            <Box key={d.id} sx={{ 
                                display: "flex",
                                alignItems: "center",
                                mb: 1
                            }}>
                                <input
                                    value={d.value || ""}
                                    name={d.id}
                                    onChange={handleChange}
                                    type="email"
                                    autoComplete="off"
                                    className={`input ${d.value && "is-success"}`}
                                    required
                                    placeholder="Adresse email"
                                />
                                <IconButton
                                    onClick={handleRemove(d.id)}
                                    sx={{
                                        ml: 2,
                                        bgcolor: scssVar.greyLight,
                                    }}
                                >
                                    <i
                                        className="iconoir-cancel"
                                        style={{
                                            fontSize: 24,
                                        }}
                                    ></i>
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" },
                        flexDirection: { xs: "column", sm: "row" }
                    }}>
                        <Button
                            variant="primary-light"
                            type="button"
                            onClick={handleAdd}
                            sx={{
                                pl: 5,
                                pr: 7
                            }}
                        >
                            <i
                                className="iconoir-plus"
                                style={{
                                    fontSize: 24,
                                    marginRight: 5
                                }}
                            ></i>
                            Email
                        </Button>
                    </Box>
                    <Box sx={{
                        mt: "35px",
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" }
                    }}>
                        <Button
                            variant="light"
                            onClick={handleCancel}
                            disabled={!emails.length}
                            type="button"
                            sx={{
                                pl: 7,
                                pr: 7
                            }}
                        >
                            Annuler
                        </Button>
                        <Box sx={{ m: 1 }} />
                        <Button
                            loading={loading}
                            disabled={!emails.length || loading}
                            sx={{
                                pl: 7,
                                pr: 7
                            }}
                        >
                            Inviter
                        </Button>
                    </Box>
                </form>
            </Collapse>
        </Box>
    )
}

export default SendEmail