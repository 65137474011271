import { Fragment } from "react"
import { useSelector } from "react-redux"
import { numberWithSpaces, phoneWithSpaces } from "../../../common/utils"
import { selectOrg } from "../../account/selectors"
import Empty from "./Empty"

const Footer = () => {
    const org = useSelector(selectOrg)
    const fontSize = 9.5
    const lineHeight = "11px"

    return (
        <div style={{ 
            position: "absolute",
            bottom: 12,
            left: 0,
            right: 0,
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
            fontSize, 
            textAlign: "center",
            fontWeight: 500,
        }}>
            <p style={{ lineHeight }}>
                {org.name}
                {" - "}
                {org.org_statut || <Empty>Statut</Empty>} au capital de {org.org_capital ? `${numberWithSpaces(org.org_capital)} €` : <Empty>capital</Empty>}
                {" - "}
                RCS {org.org_rcs || <Empty>RCS</Empty>} : {org.org_siret || <Empty>Siret</Empty>}
            </p>
            <p style={{ lineHeight }}>
                Siège social :
                {" "}
                {org.address || <Empty>Adresse de l'organisation</Empty>}
                {" - "}
                Tél. : {org.phone ? phoneWithSpaces(org.phone) : <Empty>Téléphone de l'organisation</Empty>}
                {" - "}
                Carte professionnelle CCI {org.org_cci || <Empty>CCI</Empty>} n° {org.tCard || <Empty>Carte T</Empty>}
            </p>
            <p style={{ lineHeight }}>
                Assurance : {org.assurance_nom || <Empty>Nom de l'assurance</Empty>}
                {" - "}
                {org.assurance_adresse || <Empty>Adresse de l'assurance</Empty>}
                {" - "}
                {org.garantie_maniement_fonds === true 
                    ? "Avec maniement de fonds" 
                    : org.garantie_maniement_fonds === false ? "Sans maniement de fonds"
                    : <Empty>Avec/sans maniement de fonds</Empty>
                }
            </p>
            {org.garantie_maniement_fonds && (
                <Fragment>
                    <p style={{ lineHeight }}>
                        Organisme de caution : {org.garantie_organisme || <Empty>Organisme de caution</Empty>}
                        {" - "}
                        Montant de garantie : {org.garantie_montant_caution ? `${numberWithSpaces(org.garantie_montant_caution)} €` : <Empty>Montant de garantie</Empty>}
                    </p>
                </Fragment>
            )}
        </div>
    )
}

export default Footer