import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import RenderTextWithLineBreak from "../../../../common/components/RenderTextWithLineBreak"

const AdDescription = ({ wrapperStyle, textStyle }) => {
    const description = useSelector(state => state.estimate.estimation.description_estimation)

    return (
        <div style={Object.assign({}, {
            overflow: "hidden",
            maxHeight: 385
        }, wrapperStyle)}>
            {description ? (
                <RenderTextWithLineBreak
                    text={description} 
                    sx={Object.assign({}, {
                        fontSize: 13,
                        lineHeight: "16px"
                    }, textStyle)}
                />
            ):(
                <p style={{ 
                    fontSize: textStyle?.fontSize || 13,
                    fontWeight: 600,
                    color: scssVar.danger
                }}>
                    Ajoutez une description dans l'onglet "Fiche du bien"
                </p>
            )}
        </div>
    )
}

export default AdDescription