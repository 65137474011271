import { useMemo } from "react"
import { useSelector } from "react-redux"

const useBudgetUsage = (forceYear) => {
    const estimation = useSelector(state => state.estimate.estimation)
    const periodicityChosenByUser = useSelector(state => state.estimate.periodicity)

    const periodicity = useMemo(() => {
        if (forceYear) {
            return "year"
        }

        return periodicityChosenByUser
    }, [
        periodicityChosenByUser, 
        forceYear
    ])

    const valueStep11 = useMemo(() => {
        if (periodicity === "month") {
            return estimation.total_charge_mois
        }

        return estimation.total_charge_an
    }, [
        periodicity, 
        estimation.total_charge_mois, 
        estimation.total_charge_an
    ])

    const valueStep12 = useMemo(() => {
        if (periodicity === "month") {
            return estimation.total_cout_energetique_mois + estimation.cout_eau_mois
        }

        return estimation.total_cout_energetique_an + estimation.cout_eau_an
    }, [
        periodicity, 
        estimation.total_cout_energetique_mois, 
        estimation.cout_eau_mois,
        estimation.total_cout_energetique_an,
        estimation.cout_eau_an
    ])

    const totalBudgetUsage = useMemo(() => {
        return valueStep11 + valueStep12
    }, [
        valueStep11,
        valueStep12
    ])

    const charges = useMemo(() => {
        return [
            { 
                label: "Charge co-propriété", 
                value: periodicity === "month" ? estimation.charge_copro_mois : estimation.charge_copro_an, 
                unit: "€", 
                nd: "Non définie" 
            },
            { 
                label: "Chauffage compris dans les charges de copropriété", 
                value: estimation.charge_copro_chauffage ? "Oui" : "Non", 
                unit: null, 
                nd: "Non défini" 
            },
            { 
                label: "Travaux entretien", 
                value: periodicity === "month" ? estimation.travaux_entretien_mois : estimation.travaux_entretien_an, 
                unit: "€", 
                nd: "Non définis" 
            },
            { 
                label: "Taxe foncière", 
                value: periodicity === "month" ? estimation.taxe_fonciere_mois : estimation.taxe_fonciere_an, 
                unit: "€", 
                nd: "Non définie" 
            }
        ]
    }, [
        periodicity,
        estimation.charge_copro_mois,
        estimation.charge_copro_an, 
        estimation.charge_copro_chauffage,
        estimation.travaux_entretien_mois,
        estimation.travaux_entretien_an, 
        estimation.taxe_fonciere_mois,
        estimation.taxe_fonciere_an,
    ])

    const coutEau = useMemo(() => {
        return {
            label: "Eau", 
            value: periodicity === "month" ? estimation.cout_eau_mois : estimation.cout_eau_an,
            unit: "€", 
            nd: "Non définie"
        }
    }, [
        periodicity,
        estimation.cout_eau_mois,
        estimation.cout_eau_an
    ])

    const energie = useMemo(() => {
        return { 
            label: "Énergie", 
            value: periodicity === "month" ? estimation.total_cout_energetique_mois : estimation.total_cout_energetique_an, 
            unit: "€", 
            nd: "Non défini",
            detail: [
                {
                    label: "Électricité",
                    value: periodicity === "month" ? estimation.cout_energie_elec_mois : estimation.cout_energie_elec_an,
                    unit: "€", 
                    nd: "Non définie",
                },
                {
                    label: "Gaz",
                    value: periodicity === "month" ? estimation.cout_energie_gaz_mois : estimation.cout_energie_gaz_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Fioul",
                    value: periodicity === "month" ? estimation.cout_energie_fioul_mois : estimation.cout_energie_fioul_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Bois",
                    value: periodicity === "month" ? estimation.cout_energie_bois_mois : estimation.cout_energie_bois_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Pelet",
                    value: periodicity === "month" ? estimation.cout_energie_pelet_mois : estimation.cout_energie_pelet_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Photovoltaïque",
                    value: periodicity === "month" ? estimation.cout_energie_photovoltaique_mois : estimation.cout_energie_photovoltaique_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Aérothermie",
                    value: periodicity === "month" ? estimation.cout_energie_aerothermie_mois : estimation.cout_energie_aerothermie_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Géothermie",
                    value: periodicity === "month" ? estimation.cout_energie_geothermie_mois : estimation.cout_energie_geothermie_an,
                    unit: "€", 
                    nd: "Non défini",
                },
                {
                    label: "Autres",
                    value: periodicity === "month" ? estimation.cout_energie_autre_mois : estimation.cout_energie_autre_an,
                    unit: "€", 
                    nd: "Non défini",
                },
            ]
        }
    }, [
        periodicity,
        estimation.total_cout_energetique_mois,
        estimation.total_cout_energetique_an,
        estimation.cout_energie_elec_an,
        estimation.cout_energie_elec_mois,
        estimation.cout_energie_bois_mois,
        estimation.cout_energie_bois_an,
        estimation.cout_energie_pelet_mois,
        estimation.cout_energie_pelet_an,
        estimation.cout_energie_photovoltaique_mois,
        estimation.cout_energie_photovoltaique_an,
        estimation.cout_energie_aerothermie_mois,
        estimation.cout_energie_aerothermie_an,
        estimation.cout_energie_geothermie_mois,
        estimation.cout_energie_geothermie_an,
        estimation.cout_energie_autre_mois,
        estimation.cout_energie_autre_an,
        estimation.cout_energie_fioul_mois,
        estimation.cout_energie_fioul_an,
        estimation.cout_energie_gaz_mois,
        estimation.cout_energie_gaz_an,
    ])

    return {
        valueStep11,
        valueStep12,
        totalBudgetUsage,
        charges,
        coutEau,
        energie
    }
}

export default useBudgetUsage