import { useSelector } from "react-redux"
import { selectOrg } from "../../../../account/selectors"

const SectionTitle = ({ children }) => {
    const userColor = useSelector(selectOrg).color

    return (
        <p style={{
            textTransform: "uppercase",
            background: userColor,
            color: "white",
            fontWeight: 600,
            fontSize: 16,
            padding: "0 4px"
        }}>
            {children}
        </p>
    )
}

export default SectionTitle