import { Box, Typography } from "@mui/material"
import axios from "axios"
import { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"
import { baseURL } from "../../../../common/setupApi"
import { containsOnlyNumbers } from "../../../../common/utils"
import { addAlert } from "../../../alerts/alertsSlice"

const Step05 = ({ 
    email,
    onGoNext, 
    readOnly, 
    estimationId,
    orgColor
}) => {
    const dispatch = useDispatch()
    const [reSending, setReSending] = useState(false)
    const [values, setValues] = useState({
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    })
    const ref0 = useRef(null)
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)

    useEffect(() => {
        ref0.current.focus()
    }, [])

    const handleReSendCode = () => {
        setReSending(true)

        if (readOnly) {
            return setTimeout(() => {
                setReSending(false)
                dispatch(addAlert("Vous avez reçu un nouveau code de validation : 123456", "success"))
            }, [4000])
        }

        return axios
            .post(`${baseURL}/v1/api/resend-code/`, { estimation_id: estimationId })
            .then(res => {
                setReSending(false)
                dispatch(addAlert("Vous avez reçu un nouveau code de validation, vérifiez votre boîte mail", "success"))
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                setReSending(false)
            })
    }

    return (
        <Fragment>
            <Typography sx={{ color: "black", fontFamily: scssVar.fontFamily, mb: 1 }}>
                Renseignez le code de validation reçu sur <b>{email}</b>
            </Typography>
            <Box sx={{ 
                display: "flex", 
                justifyContent: "space-between",
                "& > input": { 
                    width: "16%",
                    textAlign: "center",
                    fontWeight: 800
                } 
            }}>
                <input
                    className={`input input-grey ${values[0] && "is-success"}`}
                    placeholder="-"
                    type="text" 
                    pattern="\d*"
                    ref={ref0} 
                    value={values[0]} 
                    onChange={e => {
                        const { value } = e.target

                        if (!containsOnlyNumbers(value)) {
                            return
                        }

                        if (value.length < 2) {
                            setValues({ ...values, 0: value })
                        } else {
                            return
                        }
                        
                        if (value !== "") {
                            if (!values[1]) {
                                ref1.current.focus()
                            } else {
                                if (!values[2]) {
                                    ref2.current.focus()
                                } else {
                                    if (!values[3]) {
                                        ref3.current.focus()
                                    } else {
                                        if (!values[4]) {
                                            ref4.current.focus()
                                        } else {
                                            if (!values[5]) {
                                                ref5.current.focus()
                                            } else {
                                                onGoNext(`${value}${values[1]}${values[2]}${values[3]}${values[4]}${values[5]}`)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }} 
                />
                <input 
                    className={`input input-grey ${values[1] && "is-success"}`}
                    placeholder="-"
                    type="text" 
                    pattern="\d*"
                    value={values[1]} 
                    ref={ref1} 
                    onChange={e => {
                        const { value } = e.target

                        if (!containsOnlyNumbers(value)) {
                            return
                        }

                        if (value.length < 2) {
                            setValues({ ...values, 1: value })
                        } else {
                            return
                        }

                        if (value !== "") {
                            if (!values[0]) {
                                ref0.current.focus()
                            } else {
                                if (!values[2]) {
                                    ref2.current.focus()
                                } else {
                                    if (!values[3]) {
                                        ref3.current.focus()
                                    } else {
                                        if (!values[4]) {
                                            ref4.current.focus()
                                        } else {
                                            if (!values[5]) {
                                                ref5.current.focus()
                                            } else {
                                                onGoNext(`${values[0]}${value}${values[2]}${values[3]}${values[4]}${values[5]}`)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }} 
                />
                <input 
                    className={`input input-grey ${values[2] && "is-success"}`}
                    placeholder="-"
                    type="text" 
                    pattern="\d*"
                    value={values[2]} 
                    ref={ref2} 
                    onChange={e => {
                        const { value } = e.target

                        if (!containsOnlyNumbers(value)) {
                            return
                        }

                        if (value.length < 2) {
                            setValues({ ...values, 2: value })
                        } else {
                            return
                        }

                        if (value !== "") {
                            if (!values[0]) {
                                ref0.current.focus()
                            } else {
                                if (!values[1]) {
                                    ref1.current.focus()
                                } else {
                                    if (!values[3]) {
                                        ref3.current.focus()
                                    } else {
                                        if (!values[4]) {
                                            ref4.current.focus()
                                        } else {
                                            if (!values[5]) {
                                                ref5.current.focus()
                                            } else {
                                                onGoNext(`${values[0]}${values[1]}${value}${values[3]}${values[4]}${values[5]}`)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }} 
                />
                <input 
                    className={`input input-grey ${values[3] && "is-success"}`}
                    placeholder="-"
                    type="text" 
                    pattern="\d*"
                    value={values[3]} 
                    ref={ref3} 
                    onChange={e => {
                        const { value } = e.target

                        if (!containsOnlyNumbers(value)) {
                            return
                        }

                        if (value.length < 2) {
                            setValues({ ...values, 3: value })
                        } else {
                            return
                        }

                        if (value !== "") {
                            if (!values[0]) {
                                ref0.current.focus()
                            } else {
                                if (!values[1]) {
                                    ref1.current.focus()
                                } else {
                                    if (!values[2]) {
                                        ref2.current.focus()
                                    } else {
                                        if (!values[4]) {
                                            ref4.current.focus()
                                        } else {
                                            if (!values[5]) {
                                                ref5.current.focus()
                                            } else {
                                                onGoNext(`${values[0]}${values[1]}${values[2]}${value}${values[4]}${values[5]}`)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }} 
                />
                <input 
                    className={`input input-grey ${values[4] && "is-success"}`}
                    placeholder="-"
                    type="text" 
                    pattern="\d*"
                    ref={ref4} 
                    value={values[4]} 
                    onChange={e => {
                        const { value } = e.target

                        if (!containsOnlyNumbers(value)) {
                            return
                        }

                        if (value.length < 2) {
                            setValues({ ...values, 4: value })
                        } else {
                            return
                        }

                        if (value !== "") {
                            if (!values[0]) {
                                ref0.current.focus()
                            } else {
                                if (!values[1]) {
                                    ref1.current.focus()
                                } else {
                                    if (!values[2]) {
                                        ref2.current.focus()
                                    } else {
                                        if (!values[3]) {
                                            ref3.current.focus()
                                        } else {
                                            if (!values[5]) {
                                                ref5.current.focus()
                                            } else {
                                                onGoNext(`${values[0]}${values[1]}${values[2]}${values[3]}${value}${values[5]}`)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }} 
                />
                <input 
                    className={`input input-grey ${values[5] && "is-success"}`}
                    placeholder="-"
                    type="text" 
                    pattern="\d*"  
                    ref={ref5} 
                    value={values[5]} 
                    onChange={e => {
                        const { value } = e.target

                        if (!containsOnlyNumbers(value)) {
                            return
                        }

                        if (value.length < 2) {
                            setValues({ ...values, 5: value })
                        } else {
                            return
                        }

                        if (value !== "") {
                            if (!values[0]) {
                                ref0.current.focus()
                            } else {
                                if (!values[1]) {
                                    ref1.current.focus()
                                } else {
                                    if (!values[2]) {
                                        ref2.current.focus()
                                    } else {
                                        if (!values[3]) {
                                            ref3.current.focus()
                                        } else {
                                            if (!values[4]) {
                                                ref4.current.focus()
                                            } else {
                                                onGoNext(`${values[0]}${values[1]}${values[2]}${values[3]}${values[4]}${value}`)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }} 
                />
            </Box>
            <Box sx={{ m: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <Button 
                    type="button"
                    variant="text" 
                    onClick={handleReSendCode}
                    disabled={reSending}
                    sx={{ 
                        fontSize: 14,
                        color: orgColor.main,
                        "&:hover": {
                            color: orgColor.main,
                            filter: "brightness(70%)"
                        },
                        "&.Mui-active": {
                            color: orgColor.main,
                            filter: "brightness(40%)"
                        },
                    }} 
                >
                    {reSending ? "Envoie d'un nouveau code..." : "Renvoyer le code"}
                </Button>
            </Box>
        </Fragment>
    )
}

export default Step05