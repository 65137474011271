import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ButtonUserProfile from './ButtonUserProfile';
import { Fragment, useState } from 'react';
import useRoutes from '../../hooks/useRoutes';
import MenuNav from './MenuNav';
import LinkTopBar from './LinkTopBar';
import LinkLogo from './LinkLogo';

const DesktopNav = () => {
    const routes = useRoutes()
    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    return (
        <Fragment>
            <AppBar position="static" sx={{
                boxShadow: "none",
                WebkitBoxShadow: "none",
                background: "none",
                color: "black",
                pt: { xs: 0, sm: 2.65 },
            }}>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: { xs: 1, sm: 0 } }}>
                        <LinkLogo />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
                        {routes.map((d, i) => <LinkTopBar key={i} link={d} />)}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <ButtonUserProfile open={Boolean(anchorElUser)} onClick={handleOpenUserMenu} />
                        </Box>
                        <Box sx={{ display: { xs: "block", md: "none" }}}>
                            <ButtonUserProfile small onClick={handleOpenUserMenu} />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <MenuNav anchorEl={anchorElUser} onClose={() => setAnchorElUser(null)} />
        </Fragment>
    )
}

export default DesktopNav
