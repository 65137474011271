import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import ButtonDownload from "./ButtonDownload"
import { useDispatch, useSelector } from "react-redux"
import { addAvenant, selectMandatEditor, selectMandatLastSave, selectMandatSaving } from "../mandatSlice"
import { AddRounded, CheckCircleRounded } from "@mui/icons-material"
import { Fragment, useState } from "react"
import Button from "../../../../common/components/Button"
import { api } from "../../../../common/setupApi"
import { selectEstimation } from "../../../estimate/selectors"
import { addAlert } from "../../../alerts/alertsSlice"
import { defaultErrorMessage } from "../../../../common/utils"

const SavingMessage = () => {
    const isSaving = useSelector(selectMandatSaving)
    const lastSave = useSelector(selectMandatLastSave)

    if (isSaving) {
        return (
            <p style={{ color: scssVar.black, fontSize: 13, lineHeight: "13px" }}>
                <i>Enregistrement...</i>
            </p>
        )
    }

    if (lastSave) {
        const formatDate = new Date(lastSave)
        const time = String(formatDate.getHours()).padStart(2, "0") + ":" + String(formatDate.getMinutes()).padStart(2, "0")

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: 13, lineHeight: "13px", color: scssVar.black, marginRight: 5 }}>Enregistré à {time}</p>
                <CheckCircleRounded style={{ fontSize: 15, color: scssVar.success }} />
            </div>
        )
    }

    return null
}

const AmendmentsCount = () => {
    const mandat = useSelector(selectMandatEditor)
    return (
        <Fragment>
            {mandat.amendments?.length > 0 && (
                <p style={{ color: scssVar.black, fontSize: 13, lineHeight: "13px" }}>
                    {mandat.amendments.length} avenant{mandat.amendments.length > 1 ? "s" : ""}
                </p>
            )}
        </Fragment>
    )
}

const AddAmendments = () => {
    const dispatch = useDispatch()
    const estimationId = useSelector(selectEstimation).id
    const mandat = useSelector(selectMandatEditor)
    const [addAvenantLoading, setAddAvenantLoading] = useState(false)
    const [openAddAvenant, setOpenAddAvenant] = useState(false)

    const handleAddAvenant = async () => {
        setAddAvenantLoading(true)

        return api
            .post("v1/housing/estimation/amendment/", { estimation_id: estimationId })
            .then(res => {
                dispatch(addAvenant(estimationId, res.data.amendments))
                setOpenAddAvenant(false)
                setAddAvenantLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                setAddAvenantLoading(false)
                try {
                    dispatch(addAlert(err.response.data, "error"))
                } catch (otherError) {
                    console.log(otherError)
                    dispatch(addAlert(defaultErrorMessage, "error"))
                }
            })
    }

    return (
        <Fragment>
            <Button
                variant="primary-light"
                onClick={() => setOpenAddAvenant(true)}
                sx={{
                    height: 30,
                    fontSize: 13,
                    lineHeight: "13px",
                    pr: 2,
                    pl: 2,
                    mt: "3px",
                    borderRadius: "6px",
                    border: "1px solid " + scssVar.primary,
                }}
            >
                {mandat.amendments?.length > 0 ? "Ajouter" : "Avenant"}
                <AddRounded sx={{ fontSize: 17, ml: .7 }} />
            </Button>
            <Dialog open={openAddAvenant} onClose={() => setOpenAddAvenant(false)} fullWidth>
                <DialogTitle sx={{ fontWeight: 700, fontSize: { xs: 18, sm: 24 }, fontFamily: scssVar.fontFamily }}>
                    Ajouter un avenant à ce mandat ?
                </DialogTitle>
                <DialogActions>
                    <Button variant="light" onClick={() => setOpenAddAvenant(false)} sx={{ width: 150 }}>Annuler</Button>
                    <Button onClick={handleAddAvenant} loading={addAvenantLoading} disabled={addAvenantLoading} sx={{ width: 150 }}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

const TopBar = ({ loading, onDownload }) => {
    return (
        <Box sx={{
            position: "sticky",
            top: 0,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottom: "1px solid " + scssVar.greyLight,
            bgcolor: scssVar.greyLighter,
            zIndex: 1200,
            p: "8px 0",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: 925, // --> width of portrait PDF (see <Wrapper />)
                margin: "0 auto"
            }}>
                <div style={{ width: "33.33%" }}>
                    <SavingMessage />
                </div>
                <div style={{ width: "33.33%", display: "flex", justifyContent: "center" }}>
                    <ButtonDownload disabled={loading} onClick={onDownload} />
                </div>
                <div style={{ width: "33.33%", display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <AmendmentsCount />
                        <AddAmendments />
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default TopBar