import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../common/setupApi";
import { addAlert } from "../alerts/alertsSlice";
import { loginUser } from "../auth/actions";

export const createFreeTrial = () => async (dispatch, getState) => {
    const state = getState()
    const organization = state.registerOrg.form.org_name
    const lastName = state.registerOrg.form.last_name
    const firstName = state.registerOrg.form.first_name
    const email = state.registerOrg.form.email
    const phone = state.registerOrg.form.phone
    const pwd1 = state.registerOrg.form.pwd1
    const pwd2 = state.registerOrg.form.pwd2
    const priceId = state.registerOrg.form.price_id

    if (pwd1 !== pwd2) {
        const errMsg = "Les mots de passe ne correspondent pas."
        dispatch(registerOrgActions.setErrorFields({ name: "pwdMatch", error: ["Les mots de passe ne correspondent pas."] }))
        dispatch(addAlert(errMsg, "error"))

        return
    }

    if (priceId === null) {
        dispatch(registerOrgActions.setErrorFields({ name: "priceId", error: ["Veuillez sélectionner un abonnement."] }))

        const el = document.getElementById("register_org_sub")
        el.scrollIntoView({ behavior: "smooth" })

        return
    }

    const payload = {
        "org_name": organization,
        "first_name": firstName,
        "last_name": lastName,
        "pwd1": pwd1,
        "pwd2": pwd2,
        "email": email.toLowerCase(),
        "phone": phone,
        "price_id": priceId,
    }

    dispatch(registerOrgActions.createFreeTrialLoading())

    return api
        .post("v1/stripe/create-org-free-trial/", payload)
        .then(() => {
            dispatch(registerOrgActions.createFreeTrialSucceeded())
            dispatch(loginUser(email.toLowerCase(), pwd1))
            // Le reset est géré par le composant à l'unmount
        })
        .catch(err => {
            if (err.response.data) {
                console.log(err.response.data)

                for (const property in err.response.data) {
                    const error = err.response.data[property]

                    dispatch(registerOrgActions.setErrorFields({ name: property, error }))

                    error.forEach(d => dispatch(addAlert(d, "error")))
                }
            } else {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                const errMsg = err.message || "Une erreur est survenue, veuillez réessayer."
                console.log(errMsg)

                dispatch(registerOrgActions.createFreeTrialFailed({ error: errMsg }))

                dispatch(addAlert(errMsg, "error"))
            }
        })
}

const initialState = {
    form: {
        org_name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        pwd1: "",
        pwd2: "",
        price_id: null,
        cgu: false,
    },
    errFields: {
        org_name: [],
        first_name: [],
        last_name: [],
        email: [],
        phone: [],
        pwd1: [],
        pwd2: [],
        priceId: [],
        pwd_match: [],
        // Django ne détecte pas le case où 2 mdp ne correspondent pas 
        pwdMatch: []
    },
    freeTrial: {
        loading: false,
        error: null,
        registered: false
    },
    redirectToCheckout: {
        loading: false,
        error: null
    },
    stripeKey: {
        loading: false,
        error: null,
        value: null
    },
}

const registerOrgSlice = createSlice({
    name: "registerOrg",
    initialState,
    reducers: {
        setField: (state, action) => {
            state.form[action.payload.name] = action.payload.value
        },
        createFreeTrialLoading: (state) => {
            state.freeTrial.loading = true
            state.freeTrial.error = null
            state.errFields = initialState.errFields
        },
        createFreeTrialSucceeded: (state) => {
            state.freeTrial.loading = false
            state.freeTrial.registered = true
        },
        createFreeTrialFailed: (state, action) => {
            state.freeTrial.loading = false
            state.freeTrial.error = action.payload.error
        },
        setErrorFields: (state, action) => {
            state.freeTrial.loading = false
            state.errFields[action.payload.name] = action.payload.error
        },
        reset: () => {
            return initialState
        }
    }
})

export const registerOrgActions = registerOrgSlice.actions

export const selectRegisterOrgForm = (state) => state.registerOrg.form

export const selectRegisterOrgErrFields = (state) => state.registerOrg.errFields

export default registerOrgSlice.reducer


// export const redirectToCheckout = () => async (dispatch, getState) => {
    //     const state = getState()
    //     const organization = state.register.organizationForm.org_name
    //     const lastName = state.register.organizationForm.last_name
    //     const firstName = state.register.organizationForm.first_name
    //     const email = state.register.organizationForm.email
    //     const pwd1 = state.register.organizationForm.pwd1
    //     const pwd2 = state.register.organizationForm.pwd2
    //     const priceId = state.register.organizationForm.price_id
        
    //     let stripePromise 
    
    //     const payload = {
    //         "org_name": organization,
    //         "first_name": firstName,
    //         "last_name": lastName,
    //         "pwd1": pwd1,
    //         "pwd2": pwd2,
    //         "email": email.toLowerCase(),
    //         "price_id": priceId,
    //     }
    
    //     dispatch({ type: "register/get_stripe_key_loading" })
    
    //     return api
    //         .get("v1/stripe/config/")
    //         .then(async res => {
    //             const publicKey = res.data.publicKey
    //             //console.log(publicKey)
    //             stripePromise = loadStripe(publicKey)
    
    //             dispatch({ type: "register/get_stripe_key_success" })
    
    //             dispatch({ type: "register/redirect_to_checkout_loading" })
    
    //             return api
    //                 .post("v1/stripe/checkoutsession", payload)
    //                 .then(async res => {
    //                     const stripe = await stripePromise
    
    //                     dispatch({ type: "register/redirect_to_checkout_success" })
    
    //                     //dispatch({ type: "register/reset_form" })
    
    //                     return stripe.redirectToCheckout({
    //                         sessionId: res.data.id,
    //                     })
    //                 })
    //                 .catch(err => {
    //                     if (err.response.data) {
    //                         console.log(err.response.data)
    //                         for (const property in err.response.data) {
    //                             dispatch({ 
    //                                 type: "register/set_error_fields", 
    //                                 payload: {
    //                                     name: property,
    //                                     error: err.response.data[property]
    //                                 }
    //                             })
    //                         }
    
    //                         dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
    //                     } else {
    //                         // If `redirectToCheckout` fails due to a browser or network
    //                         // error, display the localized error message to your customer
    //                         // using `result.error.message`.
    //                         const errMsg = err.message || "Une erreur est survenue, veuillez réessayer."
    //                         console.log(errMsg)
    
    //                         dispatch({ 
    //                             type: "register/redirect_to_checkout_failure",
    //                             payload: errMsg
    //                         })
    
    //                         dispatch(addAlert(errMsg, "error"))
    //                     }
    //                 })
    //         })
    //         .catch(err => {
    //             const errMsg = err.message || "Une erreur est survenue, veuillez réessayer."
    //             console.log(errMsg)
    
    //             dispatch({ 
    //                 type: "register/get_stripe_key_failure", 
    //                 payload: errMsg 
    //             })
    
    //             dispatch(addAlert(errMsg, "error"))
    //         })
    // }