import { api } from "../../common/setupApi"
import { addAlert } from "../alerts/alertsSlice"

export function getEstimations() {
    return async (dispatch, getState) => {
        const state = getState()
        const fetched = state.estimations.estimations.fetched

        if (fetched) {
            return
        }

        dispatch({ type: "estimations/get_estimations_loading" })

        return api
            .get("v1/housing/estimation")
            .then(res => {
                dispatch({ type: "estimations/get_estimations_success", payload: res.data })
                dispatch(filterEstimations())
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ type: "estimations/get_estimations_failure", payload: err.response?.data })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
            })
    }
}

export function filterEstimations() {
    return async (dispatch, getState) => {
        const state = getState()
        const filters = state.estimations.estimations.filters
        const sortBy = state.estimations.estimations.sortBy
        const items = state.estimations.estimations.items

        const filteredByTypeVente = items.filter(d => {
            if (filters.typeVente === "all") {
                return d
            }

            return d.housing.type_vente === filters.typeVente
        })

        const filteredByNbPieces = filteredByTypeVente.filter(d => {
            return d.housing.nombre_pieces_principales >= filters.nbPiecesPrinc
        })

        const filteredPrices = filteredByNbPieces.filter(d => {
            return d.value >= filters.value
        })

        const filteredByStep = filteredPrices.filter(d => {
            if (filters.step === "notfinish") {
                return d.current_step !== "ResultFinal"
                    && d.current_step !== "Synthesis"
            }

            if (filters.step === "finish") {
                return d.current_step === "ResultFinal"
                    || d.current_step === "Synthesis"
            }
            
            return d
        })

        const filteredBySearch = filteredByStep.filter(d => {
            if (
                filters.search === null
                || filters.search === ""
                || (filters.search.length && !filters.search.trim().length)
            ) {
                return d
            }

            return d.housing.name.toUpperCase().indexOf(filters.search.toUpperCase()) > -1
                || d.housing.adresse.toUpperCase().indexOf(filters.search.toUpperCase()) > -1
        })

        const filteredAndsorted = [...filteredBySearch].sort((a, b) => {
            if (sortBy === "récentes") {
                return new Date(b.created_on) - new Date(a.created_on)
            }

            if (sortBy === "!récentes") {
                return new Date(a.created_on) - new Date(b.created_on)
            }

            if (sortBy === "chères") {
                return b.value - a.value
            }

            if (sortBy === "!chères") {
                return a.value - b.value
            }

            return new Date(a.created_on) - new Date(b.created_on)
        })

        return dispatch({
            type: "estimations/filter_estimations",
            payload: filteredAndsorted
        })
    }
}

export function deleteEstimationsGroup() {
    return async (dispatch, getState) => {
        const state = getState()
        const ids = state.estimations.estimations.selected
        const url = "v1/housing/estimation/delete"
        const requests = ids.map(id => api.post(url, { id }))
        const estimationId = state.estimate.estimation.id
        const deletingCurrentEstimation = ids.includes(estimationId)

        dispatch({ type: "estimations/delete_estimations_loading" })

        return Promise
            .all(requests)
            .then(res => {
                //res.forEach(r => console.log(r.data))

                dispatch({
                    type: "estimations/delete_estimations_success",
                    payload: ids
                })

                dispatch({
                    type: "estimations/delete_unfinished",
                    payload: ids
                })

                dispatch({
                    type: "estimate/delete_estimation",
                    payload: ids
                })

                if (deletingCurrentEstimation) {
                    dispatch({ type: "estimate/reset" })
                }

                dispatch(addAlert(
                    res.length > 1 ? "Ces évaluations ont bien été supprimées !" : "Cette évaluation a bien été supprimée !",
                    "success"
                ))

                dispatch({ type: "estimations/deselect_all" })
            })
            .catch(err => {
                console.log(err.response)
                dispatch({ 
                    type: "estimations/delete_estimations_failure",
                    payload: err.response
                })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
            })
    }
}