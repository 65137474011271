import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { scssVar } from "../../../../../../app/scssVar"
import FieldControl from "../../../../../../common/components/FieldControl"
import Button from "../../../../../../common/components/Button"
import { useDispatch, useSelector } from "react-redux"
import { selectEstimation, selectHousing, selectValues } from "../../../../selectors"
import { useEffect, useState } from "react"
import { api } from "../../../../../../common/setupApi"
import { updateEstimationsList } from "../../../../actions"
import { addAlert } from "../../../../../alerts/alertsSlice"
import { defaultErrorMessage } from "../../../../../../common/utils"

const DialogUpdatePrice = ({ open, onClose }) => {
    const dispatch = useDispatch()
    const { customValue } = useSelector(selectValues)
    const housing = useSelector(selectHousing)
    const estimation = useSelector(selectEstimation)
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!open) {
            setValue(customValue || "")
        }
    }, [open, customValue])

    const handleSubmit = async () => {
        dispatch({ type: "estimate/set_working", payload: true })
        setLoading(true)

        return api
            .post("v1/housing/estimation/compute", { housing, estimation: { ...estimation, value_custom: value } })
            .then(res => {
                dispatch({ type: "estimate/set_estimation", payload: res.data })
                dispatch({ type: "estimate/edit_estimation", payload: res.data })
                dispatch(updateEstimationsList("Synthesis"))
                dispatch(addAlert("Prix modifié !", "success"))
                dispatch({ type: "estimate/set_working", payload: false })
                setLoading(false)
                onClose()
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert(defaultErrorMessage, "error"))
                dispatch({ type: "estimate/set_working", payload: false })
                setLoading(false)
            })
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
            }}>
                Modifier le prix évalué
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    fontFamily: scssVar.fontFamily,
                    fontSize: 13,
                    fontWeight: 600,
                    color: scssVar.greyDark,
                    mb: 1
                }}>
                    Le prix évalué sera ajusté légèrement à la hausse afin d'obtenir un prix de présentation arrondi 
                </DialogContentText>
                <FieldControl
                    label="Nouveau prix"
                    isSuccess={value} 
                    onChange={e => setValue(e.target.value)}
                    value={value || ""}
                    type="number" 
                    placeholder="Nouveau prix" 
                    name="newPrice"
                    icon={{ position: "right", icon: "fas fa-euro-sign" }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="light" onClick={onClose}>Annuler</Button>
                <Button onClick={handleSubmit} loading={loading} disabled={loading}>Enregistrer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogUpdatePrice