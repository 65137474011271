import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../../app/scssVar"
import FieldControl from "../../../../../../common/components/FieldControl"
import RenderTextWithLineBreak from "../../../../../../common/components/RenderTextWithLineBreak"
import { api } from "../../../../../../common/setupApi"
import { addAlert } from "../../../../../alerts/alertsSlice"
import { updateEstimationsList } from "../../../../actions"
import Button from "../../../../../../common/components/Button"

const initialState = {
    openDescription: false,
    newDescription: "",
    loadingDescription: false,
    errorDescription: null,
    openExpertOpinion: false,
    newPrice: "",
    loadingExpertOpinion: false,
    errorExpertOpinion: null
}

const Description = () => {
    const dispatch = useDispatch()
    const working = useSelector(state => state.estimate.working)
    const housing = useSelector(state => state.estimate.housing) 
    const estimation = useSelector(state => state.estimate.estimation)
    const description = estimation.description_estimation
    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (state.openDescription) {
            setState(s => ({
                ...s,
                newDescription: description || ""
            }))
        }
    }, [state.openDescription, description])

    const handleOpenDescription = () => {
        setState({ ...state, openDescription: true })
    }

    const handleCloseDescription = () => {
        setState({ ...state, openDescription: false })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmitDescription = async () => {
        dispatch({ type: "estimate/set_working", payload: true })

        setState({ ...state, loadingDescription: true, errorDescription: null })

        const estimationCopy = {
            ...estimation,
            description_estimation: state.newDescription
        }

        return api
            .post("v1/housing/estimation/compute", { housing, estimation: estimationCopy })
            .then(res => {
                //console.log(res.data.description_estimation)

                dispatch({ 
                    type: "estimate/set_estimation", 
                    payload: res.data 
                })

                dispatch({ 
                    type: "estimate/edit_estimation", 
                    payload: res.data 
                })

                dispatch(updateEstimationsList("Synthesis"))

                dispatch(addAlert("Description modifiée !", "success"))

                dispatch({ type: "estimate/set_working", payload: false })

                setState(initialState)
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
                setState({ ...state, errorDescription: err.response, loadingDescription: false })
            })
    }

    return (
        <Fragment>
            <Box sx={{
                bgcolor: scssVar.greyLighter,
                borderRadius: "16px",
                padding: "25px",
                minHeight: "124px",
                position: "relative",
                mb: 1.1,
                maxWidth: { xs: "100%", md: "calc(100% - 244px)" },
                width: "100%",
                mr: { xs: 0, md: 1.1 }
            }}>
                <Typography sx={{
                    color: scssVar.greyDarker,
                    fontFamily: scssVar.fontFamily,
                    mb: 3,
                }}>
                    Description
                </Typography>
                <IconButton
                    onClick={handleOpenDescription}
                    disabled={working}
                    sx={{
                        fontSize: 24,
                        color: "black",
                        position: "absolute",
                        right: 15,
                        top: 15,
                        bgcolor: scssVar.greyLight
                    }}
                >
                    <i className="iconoir-edit-pencil edit-icon"></i>
                </IconButton>
                <Box sx={{
                    maxHeight: "100px",
                    overflowY: "auto"
                }}>
                    {description 
                        ? (
                            <RenderTextWithLineBreak
                                text={description} 
                                color={scssVar.greyDarker}
                                fontSize="14px"
                            />
                        ):(
                            <Typography sx={{
                                color: scssVar.greyDarker,
                                fontFamily: scssVar.fontFamily,
                                fontSize: "14px"
                            }}>
                                Aucune description pour le moment. Ajoutez maintenant une description concernant ce bien.
                            </Typography>
                        )
                    }
                </Box>
            </Box>
            <Dialog
                open={state.openDescription} 
                onClose={handleCloseDescription}
                fullWidth
            >
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: { xs: 18, sm: 24 },
                    fontFamily: scssVar.fontFamily,
                }}>
                    Ajoutez une description pour ce bien
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.danger,
                        fontSize: 11.5,
                        mb: 2,
                        fontWeight: 500
                    }}>
                        Mention obligatoire à préciser dans cette description pour les biens en 
                        copropriétés : Nombre de lots totaux, la part de tantième. Préciser si nécessaire 
                        s'il y a une procédure en cours.
                    </DialogContentText>
                    <FieldControl
                        label="Description" 
                        isSuccess={state.newDescription} 
                        onChange={handleChange}
                        value={state.newDescription || ""}
                        type="textarea" 
                        placeholder="Décrivez ce bien"
                        name="newDescription"
                        maxLength={600}
                    />
                    <p style={{ textAlign: "end", fontSize: 12 }}>{state.newDescription.length}/600</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="light"
                        onClick={handleCloseDescription}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmitDescription}
                        disabled={state.loadingDescription}
                        loading={state.loadingDescription}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Description