const emptyHousing = {
    id: null,
    name: null,
    adresse: null,
    is_flat: false,
    is_house: false,
    type_vente: null,
    nombre_pieces_principales: null,
    surface_terrain_totale: null,
    surface_reelle_bati: null,
    date_vente: null,
    date_mise_en_vente: null,
    prix_vente: null,
}

export const emptyEstimation = {
    id: null,
    current_step: null,
    housing_id: null,
    nb_chambres: null,
    surface_piece_de_vie: null,
    etage: null,
    etat_du_bien: null,
    orientation: null,
    annee_construction: null,
    avec_jardin: false,
    avec_terrasse: false,
    avec_rooftop: false,
    avec_garage: false,
    avec_balcon: false,
    avec_ascenseur: null,
    avec_cave: false,
    avec_cabanon: false,
    avec_grenier: false,
    avec_piscine: false,
    avec_salledejeux: false,
    avec_salledesport: false,
    avec_spa: false,
    avec_stationnement: false,
    avec_teletravail: false,
    surface_jardin: null,
    surface_terrasse: null,
    surface_rooftop: null,
    localisation_teletravail: null,
    luminosite: null,
    hauteur_sous_plafond: null,
    decoration: null,
    proche_boutique: false,
    proche_ecole: false,
    proche_parc: false,
    proche_transports: false,
    proche_loisirs: false,
    proche_restauration: false,
    proche_parking: false,
    proche_sport: false,
    proche_bibliotheque: false,
    ecoquartier: false,
    installation_numerique: null,
    qualite_numerique: null,
    environnement_sonore: null,
    dpe: null,
    gse: null,
    attrait: null,
}

const initialState = {
    housing: emptyHousing,
    estimation: emptyEstimation,
    isLeadEstimation: false,
    working: false,
    periodicity: "month",
    relativeOrAbsolute: "relative",
    //showInfosUseCost: true,
    saving: false, // ?
    errorSaving: null, // ?
    loadingDefaultValues: false,
    errorDefaultValues: null,
    estimations: {
        loading: false,
        error: null,
        items: []
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case "estimate/set_is_lead_estimation":
            return {
                ...state,
                isLeadEstimation: action.payload
            }

        case "estimate/set_housing_in_estimations_of_housing":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: state.estimations.items.map(d => d.housing_id === action.payload.id ? { ...d, housing: action.payload } : d)
                }
            }

        case "estimate/init_default_values_loading":
            return {
                ...state,
                loadingDefaultValues: true,
                errorDefaultValues: null
            }

        case "estimate/init_default_values_success":
            return {
                ...state,
                loadingDefaultValues: false
            }

        case "estimate/init_default_values_failure":
            return {
                ...state,
                loadingDefaultValues: false,
                errorDefaultValues: action.payload
            }

        case "estimate/save_loading":
            return {
                ...state,
                saving: true,
                errorSaving: null
            }
        
        case "estimate/save_success":
            return {
                ...state,
                saving: false,
            }

        case "estimate/save_failure":
            return {
                ...state,
                saving: false,
                errorSaving: action.payload
            }

        case "estimate/delete_estimation": {
            const items = Array.isArray(action.payload)
                ? state.estimations.items.filter(d => !action.payload.includes(d.id))
                : state.estimations.items.filter(d => d.id !== action.payload)
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items
                }
            }
        }

        // nom, annotations, client potentiel, & description
        case "estimate/edit_estimation":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: state.estimations.items.map(d => d.id === action.payload.id ? action.payload : d)
                }
            }

        case "estimate/duplicate_estimation":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: [...state.estimations.items, action.payload]
                }
            }

        case "estimate/get_estimations_of_housing_loading":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loading: true,
                    error: null
                }
            }

        case "estimate/get_estimations_of_housing_success":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loading: false,
                    items: action.payload
                }
            }

        case "estimate/get_estimations_of_housing_failure":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loading: false,
                    error: action.payload
                }
            }

        case "estimate/set_relative_or_absolute":
            return {
                ...state,
                relativeOrAbsolute: action.payload
            }

        case "estimate/set_periodicity":
            return {
                ...state,
                periodicity: action.payload
            }

        case "estimate/set_step":
            return {
                ...state,
                estimation: {
                    ...state.estimation,
                    current_step: action.payload
                }
            }

        case "estimate/set_working":
            return {
                ...state,
                working: action.payload
            }

        case "estimate/set_housing_value":
            return {
                ...state,
                housing: {
                    ...state.housing,
                    [action.payload.name]: action.payload.value
                }
            }

        case "estimate/set_estimation_value": 
            return {
                ...state,
                estimation: {
                    ...state.estimation,
                    [action.payload.name]: action.payload.value
                }
            }

        case "estimate/set_estimation_costs_value": {
            const isMonth = action.payload.name.includes("mois") // state.periodicity
            return {
                ...state,
                estimation: {
                    ...state.estimation,
                    [action.payload.name]: action.payload.value,
                    [`${action.payload.name}_saisie`]: true,
                    [`${action.payload.name.replace(isMonth ? "mois" : "an", isMonth ? "an" : "mois")}_saisie`]: null,
                }
            }
        }
        case "estimate/set_estimation_costs_chauffage_compris_dans_copro":
            return {
                ...state,
                estimation: {
                    ...state.estimation,
                    charge_copro_chauffage: action.payload.value,
                    charge_copro_chauffage_saisie: true
                }
            }

        case "estimate/set_estimation_costs_acquereur_vendeur":
            return {
                ...state,
                estimation: {
                    ...state.estimation,
                    a_charge_vendeur_ou_acquereur: action.payload.value,
                    a_charge_vendeur_ou_acquereur_saisie: true
                }
            }

        case "estimate/set_estimation_costs_percent_or_absolute": {
            const name = action.payload.name
            const isRel = name.includes("_pourcent") // state.relativeOrAbsolute
            const saisiField = name.replace(isRel ? "_pourcent" : "_absolu", "_saisie_pourcent")
            return {
                ...state,
                estimation: {
                    ...state.estimation,
                    [action.payload.name]: action.payload.value,
                    [saisiField]: isRel
                }
            }
        }

        case "estimate/set_estimation":
            return {
                ...state,
                estimation: action.payload,
                // estimation: Object.assign({}, action.payload, { prevValue: state.estimation.value }) 
            }

        case "estimate/set_housing":
            return {
                ...state,
                housing: action.payload
            }

        case "estimate/reset_housing":
            return {
                ...state,
                housing: initialState.housing
            }

        case "estimate/reset_estimation":
            return {
                ...state,
                estimation: initialState.estimation
            }

        case "estimate/reset":
            return initialState

        case "auth/logout": 
            return initialState

        default: 
            return state
    }
}

export default reducer