import { useEffect, useState } from "react"
import { maxScale, minScale } from "../settings"

const initialState = {
    scale: 1,
    numPages: null,
    loadingPage: true,
    loadingDoc: true
}

const usePdfState = () => {
    const [state, setState] = useState(initialState)

    useEffect(() => {
        setState(s => ({
            ...s,
            scale:
                window.innerWidth < 600
                    ? 0.25
                    : window.innerWidth < 900
                    ? 0.45
                    : window.innerWidth < 1300 
                    ? 0.67
                    : 1
        }))
    }, [])

    const handleDocumentLoadSuccess = ({ numPages }) => { 
        setState({ 
            ...state,
            numPages,
            loadingDoc: false
        })
    }

    const handlePageLoadSuccess = () => {
        setState({
            ...state,
            loadingPage: false
        })
    }

    const handleZoomIn = () => {
        const scale = state.scale + 0.1 > maxScale ? maxScale : state.scale + 0.1
        setState({ ...state, scale })
    }

    const handleZoomOut = () => {
        const scale =  state.scale - 0.1 < minScale ? minScale : state.scale - 0.1
        setState({ ...state, scale })
    }

    return {
        pdfState: state,
        handleDocumentLoadSuccess,
        handlePageLoadSuccess,
        handleZoomIn,
        handleZoomOut
    }
}

export default usePdfState