import { Box, Drawer } from "@mui/material"
import Sab from "./Sab"

const PageWrapper = ({ children, variant, left, right }) => {
    if (variant === "steps") {
        const drawerWidth = { lg: 380, md: 300, sm: 240, xs: 0 }
        return (
            <Box sx={{
                p: { xs: "145px 0 80px", sm: "25px" },
                display: "flex",
                flexDirection: "column",
                width: { xs: "90%", sm: "100%" },
                margin: "0 auto ",
                minHeight: "100%",
            }}>
                <Box sx={{ 
                    display: "flex", 
                    flexGrow: 1,
                }}>
                    <Box sx={{
                        width: {
                            xs: "100%",
                            sm: `calc(100% - ${drawerWidth.sm}px)`,
                            md: `calc(100% - ${drawerWidth.md}px)`,
                            lg: `calc(100% - ${drawerWidth.lg}px)`,
                        },
                        minWidth: {
                            xs: "100%",
                            sm: `calc(100% - ${drawerWidth.sm}px)`,
                            md: `calc(100% - ${drawerWidth.md}px)`,
                            lg: `calc(100% - ${drawerWidth.lg}px)`,
                        },
                        borderRadius: "20px",
                        bgcolor: "white",
                    }}>
                        <Box sx={{
                            maxWidth: "961px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px 20px",
                                sm: "0 35px 35px",
                                lg: "0 60px 60px"
                            }
                        }}>
                            {left}
                        </Box>
                    </Box>
                    <Drawer
                        variant="permanent"
                        anchor="right"
                        sx={{
                            zIndex: 0,
                            width: drawerWidth,
                            minWidth: drawerWidth,
                            flexShrink: 0,
                            display: { xs: "none", sm: "block" },
                            '& .MuiDrawer-paper': {
                                zIndex: 0,
                                width: drawerWidth,
                                minWidth: drawerWidth,
                                boxSizing: 'border-box',
                                bgcolor: "transparent",
                                borderLeft: "none",
                                pt: "25px",
                                pr: "25px",
                            },
                        }}
                    >
                        {right}
                    </Drawer>
                </Box>
                <Sab />
            </Box>
        )
    }

    if (variant === "estimations") {
        const drawerWidth = 320
        return (
            <Box sx={{
                p: { xs: 0, sm: "25px" },
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minHeight: "100%",
            }}>
                <Box sx={{ 
                    display: "flex", 
                    flexGrow: 1,
                }}>
                    <Box sx={{
                        width: {
                            xs: "100%",
                            md: `calc(100% - ${drawerWidth}px)`
                        },
                        minWidth: {
                            xs: "100%",
                            md: `calc(100% - ${drawerWidth}px)`
                        },
                        borderRadius: { xs: 0, sm: "20px" },
                        bgcolor: "white",
                    }}>
                        <Box sx={{
                            maxWidth: "1021px",
                            margin: "0 auto",
                            p: {
                                xs: "0 20px 195px",
                                sm: "0 35px 35px",
                                lg: "0 60px 60px"
                            },
                        }}>
                            {left}
                        </Box>
                    </Box>
                    <Drawer
                        variant="permanent"
                        anchor="right"
                        sx={{
                            zIndex: 0,
                            width: drawerWidth,
                            minWidth: drawerWidth,
                            flexShrink: 0,
                            display: { xs: "none", md: "block" },
                            '& .MuiDrawer-paper': {
                                zIndex: 0,
                                width: drawerWidth,
                                minWidth: drawerWidth,
                                boxSizing: 'border-box',
                                bgcolor: "transparent",
                                borderLeft: "none",
                                pt: "25px",
                                pr: "25px",
                            },
                        }}
                    >
                        {right}
                    </Drawer>
                </Box>
                <Sab />
            </Box>
        )
    }

    if (variant === "result") {
        return (
            <Box sx={{
                p: { 
                    xs: "20px 0 0", 
                    sm: "25px 25px 0" 
                },
                display: "flex",
                flexDirection: "column",
                width: { xs: "90%", sm: "100%" },
                margin: "0 auto ",
                minHeight: "100%",
            }}>
                <Box sx={{
                    height: { 
                        xs: "547px",
                        lg: "447px",
                    },
                    width: "100%",
                    borderRadius: "20px",
                    bgcolor: "white",
                }}>
                    <Box sx={{
                        maxWidth: "1341px",
                        margin: "0 auto",
                        p: {
                            xs: "0 20px 80px",
                            sm: "0 35px 35px",
                            lg: "0 60px 60px"
                        },
                    }}>
                        {children}
                    </Box>
                </Box>
                <Sab />
            </Box>
        )
    }

    return (
        <Box sx={{
            p: { xs: 0, sm: "25px" },
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: "100%",
        }}>
            <Box sx={{
                flexGrow: 1,
                width: "100%",
                borderRadius: { xs: 0, sm: "20px" },
                bgcolor: "white",
            }}>
                <Box sx={{
                    maxWidth: "1341px",
                    margin: "0 auto",
                    p: {
                        xs: "0 20px 80px",
                        sm: "0 35px 35px",
                        lg: "0 60px 60px"
                    }
                }}>
                    {children}
                </Box>
            </Box>
            <Sab />
        </Box>
    )
}

export default PageWrapper