import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar"
import { deleteEstimationsGroup } from "../actions"
import Button from "../../../common/components/Button"

const EstimationsFloatingButtons = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const checkedEstimations = useSelector(state => state.estimations.estimations.selected)
    const loadingDelete = useSelector(state => state.estimations.estimations.loadingDelete)
    const working = useSelector(state => state.estimate.working)
    const [state, setState] = useState({
        openDelete: false
    })

    const handleDeselectAll = () => {
        dispatch({ type: "estimations/deselect_all" })
    }

    const handleOpenDelete = () => {
        setState({ ...state, openDelete: true })
    }

    const handleCloseDelete = () => {
        setState({ ...state, openDelete: false })
    }

    const handleDeleteAll = () => {
        dispatch(deleteEstimationsGroup()).then(() => {
            setState({ ...state, openDelete: false })
        })
    }

    const handleNewEstimation = () => {
        dispatch({ type: "estimate/reset" })
        navigate("/estimer")
    }

    if (checkedEstimations.length) {
        return (
            <Fragment>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: { xs: "white", sm: "white" },
                    maxWidth: 480,
                    width: "90%",
                    position: "fixed",
                    bottom: { 
                        xs: `calc(80px + ${scssVar.sab})`,
                        sm: `calc(40px + ${scssVar.sab})`,
                    },
                    left: 0,
                    right: 0,
                    ml: "auto",
                    mr: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    p: .8,
                    borderRadius: { xs: "25px", sm: "62px"},
                    boxShadow: scssVar.shadow2,
                    WebkitBoxShadow: scssVar.shadow2,
                }}>
                    <Button
                        variant="light"
                        onClick={handleOpenDelete}
                        disabled={working}
                        sx={{
                            pl: "calc(32px + 0.25em)",
                            pr: "calc(32px + 0.25em)",
                        }}
                    >
                        <i
                            className="iconoir-trash"
                            style={{
                                fontSize: 24,
                                marginRight: 10,
                            }}
                        ></i>
                        Supprimer
                    </Button>
                    <Box sx={{ height: { xs: "5px", sm: 0 } }} />
                    <Button
                        variant="light"
                        onClick={handleDeselectAll}
                        sx={{
                            pl: "calc(32px + 0.25em)",
                            pr: "calc(32px + 0.25em)",
                        }}
                    >
                        <i 
                            className="fas fa-times"
                            style={{
                                fontSize: 24,
                                marginRight: 10,
                            }}
                        ></i>
                        Tout déselectionner
                    </Button>
                </Box>
                <Dialog 
                    open={state.openDelete} 
                    onClose={handleCloseDelete} 
                    fullWidth
                >
                    <DialogTitle sx={{
                        fontWeight: 700,
                        fontSize: { xs: 18, sm: 24 },
                        fontFamily: scssVar.fontFamily,
                        display: "flex",
                        alignItems: "center",
                    }}>
                        Voulez-vous supprimer {checkedEstimations.length > 1 ? "les évaluations sélectionnées" : "cette évaluation"} ?
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant="light"
                            onClick={handleCloseDelete}
                        >
                            Annuler
                        </Button>
                        <Button
                            onClick={handleDeleteAll}
                            disabled={loadingDelete}
                            loading={loadingDelete}
                            sx={{ width: 150 }}
                        >
                            Supprimer
                            <i
                                className="iconoir-trash"
                                style={{
                                    fontSize: 24,
                                    marginLeft: 5,
                                }}
                            ></i>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }

    return (
        <Box sx={{
            display: { xs: "block", sm: "none" },
            position: "fixed",
            bottom: `calc(80px + ${scssVar.sab})`,
            width: "256px",
            left: 0,
            right: 0,
            ml: "auto",
            mr: "auto",
        }}>
            <Button
                onClick={handleNewEstimation}
                disabled={working}
                sx={{ pr: "35px" }}
            >
                <i className="iconoir-plus" style={{ fontSize: 24, marginRight: 5 }}></i>
                Nouvelle évaluation
            </Button>
        </Box>
    )
}

export default EstimationsFloatingButtons