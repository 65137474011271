import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import useAuth from "../../features/auth/hooks/useAuth"
import { isProd } from "../setupApi"

const useRoutes = () => {
    const { pathname } = useLocation()
    const { user } = useAuth()
    const isSubToApiOffer = isProd ? user.api_access : true

    return useMemo(() => {
        const routes = [
            { 
                label: "Estimer",
                to: "/estimer",
                isActive: pathname.includes("estimer"),
                icon: "iconoir-home-alt-slim",
                isAuthorized: true
            },
            { 
                label: "Mes évaluations", 
                shortLabel: "Évaluations", 
                to: "/mes-evaluations", 
                isActive: pathname.includes("/mes-evaluations"), 
                icon: "iconoir-stats-report",
                isAuthorized: true
            },
            { 
                label: "API", 
                to: "/offre-api", 
                isActive: pathname.includes("/offre-api"), 
                icon: "iconoir-database-settings",
                isAuthorized: isSubToApiOffer
            },
            // { 
            //     label: "Tableau de bord", 
            //     to: "/tableau-de-bord", 
            //     isActive: pathname.includes("/tableau-de-bord"), 
            //     icon: "far fa-chart-bar" 
            // },
        ]

        return routes.filter(d => d.isAuthorized)
    }, [pathname, isSubToApiOffer])
}

export default useRoutes