import { Backdrop, Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { scssVar } from "../../../app/scssVar"

const LoadingMask = ({ loading }) => {
    return (
        <Fragment>
            <Box sx={{
                position: "fixed",
                width: "fit-content",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 1301,
                margin: "auto",
                height: 20,
                display: loading ? "block" : "none",
            }}>
                {/* ça lague ! <LoaderGif /> */}
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    textAlign: "center"
                }}>
                    Préparation du document...
                </Typography>
            </Box>
            <Backdrop
                open={loading} 
                transitionDuration={{ appear: 0, exit: 1000 }}
                sx={{ 
                    background: "white",
                    transition: "unset",
                    zIndex: 1300
                }} 
            />
        </Fragment>
    )
}

export default LoadingMask