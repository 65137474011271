import { Avatar, Box } from "@mui/material"
import { useSelector } from "react-redux"
import { selectApiLongitudinalKpis } from "../../apiOfferSlice"
import { scssVar } from "../../../../app/scssVar"
import { getDateToString } from "../../../../common/utils"
import { MediationRounded } from "@mui/icons-material"
import { Fragment } from "react"
import ProgressBar1 from "../../../../common/components/dashboard/progress/ProgressBar1"

// PERIODE
// "start" : début du cycle actuel de facturation
// "end": fin du cycle actuel de facturation

// REQUETES
// "usage": nombre de requete du client sur la période actuelle 
// "requests_threshold": nombre de requetes inclues dans le plan
// DONC la progress bar c'est usage/requests_threshold en gros

const Count = ({ n }) => <span style={{ fontWeight: 600 }}>{n}</span>

const RequestsCount = () => {
    const data = useSelector(selectApiLongitudinalKpis).dataBilling
    const usage = data.usage || 0
    const requestsThreshold = data.requests_threshold || 0
    const percent = (usage / requestsThreshold * 100) || 0

    return (
        <Box sx={{ p: 2, bgcolor: scssVar.primary, borderRadius: "12px", color: scssVar.primaryLight }}>
            <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "space-between", 
                pb: "20px", 
                borderBottom: `1px solid ${scssVar.greyDarker}`,
                mb: "20px"
            }}>
                <Avatar sx={{ bgcolor: scssVar.primaryLighter, color: scssVar.primary, mr: 1, borderRadius: "6px" }}>
                    <MediationRounded sx={{ fontSize: 24  }} />
                </Avatar>
                <p style={{ flexGrow: 1, fontWeight: 600 }}>Requêtes</p>
                <p style={{ fontWeight: 800, fontSize: 22 }}>
                    {percent < 1 
                        ? Math.round(percent * 100) / 100
                        : Math.round(percent)
                    }
                    %
                </p>
            </Box>

            <p style={{ fontWeight: 800, fontSize: 22 }}>
                {usage} requête{usage > 1 && "s"} utilisée{usage > 1 && "s"}
            </p>
            <p style={{ fontSize: 14, marginBottom: "17px" }}>
                du {getDateToString(data.start)} au {getDateToString(data.end)}
            </p>

            <ProgressBar1 p={percent} color={scssVar.danger} />

            <Box sx={{ mb: "25px" }} />

            {requestsThreshold > 0 && (
                <p style={{
                    fontSize: 14, 
                    lineHeight: "17px", 
                    width: "100%", 
                    padding: "7px", 
                    background: scssVar.primaryLighter, 
                    color: scssVar.primary,
                    borderRadius: "3px",
                }}>
                    {usage < requestsThreshold && (
                        <Fragment>
                            Il vous en reste{" "}
                            <Count n={requestsThreshold - usage} />{" "}
                            sur votre forfait de{" "}
                            <Count n={requestsThreshold} />{" "}
                            requêtes
                        </Fragment>
                    )}
                    {usage === requestsThreshold && (
                        <Fragment>
                            Vous avez atteint le nombre maximal de votre forfait de{" "}
                            <Count n={requestsThreshold} />{" "}
                            requêtes
                        </Fragment>
                    )}
                    {usage > requestsThreshold && (
                        <Fragment>
                            C'est{" "}
                            <Count n={usage - requestsThreshold} />{" "}
                            de plus que votre forfait de{" "}
                            <Count n={requestsThreshold} />{" "}
                            requêtes
                        </Fragment>
                    )}
                </p>
            )}
        </Box>
    )
}

export default RequestsCount