import { scssVar } from "../../../../app/scssVar"
import StatBox from "../../../../common/components/dashboard/StatBox"
import { numberWithSpaces } from "../../../../common/utils"

const Mean = ({ mean, sx, isLoading }) => {
    return (
        <StatBox
            bgcolor={scssVar.greyLighter}
            icon="iconoir-home-alt-slim"
            title={`${numberWithSpaces(Math.round(mean))} € en moyenne`}
            sx={sx}
            isLoading={isLoading}
        />
    )
}

export default Mean