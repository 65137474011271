import parser from 'ua-parser-js'
import { api, isDev, isProd } from '../../common/setupApi';
import { appVersion } from "../../common/utils";

function getNameOfPath(path) {
    const routes = [
        { path: "/", name: "Home" },
        { path: "/estimer", name: "Estimate" },
        { path: "/estimer/step-1", name: "Step1" },
        { path: "/estimer/step-4", name: "Step4" },
        { path: "/estimer/step-5", name: "Step5" },
        { path: "/estimer/step-7", name: "Step7" },
        { path: "/estimer/step-8", name: "Step8" },
        { path: "/estimer/resultat", name: "Result" },
        { path: "/estimer/step-11", name: "Step11" },
        { path: "/estimer/step-12", name: "Step12" },
        { path: "/estimer/resultat-final", name: "ResultFinal" },
        { path: "/estimer/synthese", name: "Synthesis" },
        { path: "/estimer/synthese-pdf", name: "SynthesisPDF" },
        { path: "/mes-evaluations", name: "MyEvaluations" },
        { path: "/mon-compte", name: "MyAccount" },
        { path: "/login", name: "Login" },
        { path: "/decouvrir", name: "Discover" },
        { path: "/inscription", name: "Register" },
        { path: "/inscription/utilisateur", name: "RegisterUser" },
        { path: "/offre-api", name: "ApiOffer" },
    ]

    try {
        return routes.find(r => r.path === path).name
    } catch (err) {
        return "404 not found"
    }
}

function hasTouchEvents() {
    return window.ontouchstart !== undefined
}

function getDeviceType(screenWidth) {
    let device = "mobile"

    if (screenWidth >= 1024) {
        device = "desktop"
    } else if (screenWidth >= 769) {
        device = "tablet"
    }

    return device
}

function getUserTrackedData() {
    const uaParser = parser()
    const width = parseInt(window.screen.width, 10)
    const height = parseInt(window.screen.height, 10)
    const deviceType = getDeviceType(width)
    
    const payload = {
        screen_height: height,
        screen_width: width,
        window_height: parseInt(window.innerHeight, 10),
        window_width: parseInt(window.innerWidth, 10),
        has_touch_events: hasTouchEvents(),
        device_type: deviceType,
        app_version: appVersion,
        is_staging: !isProd,
        env: process.env.NODE_ENV,
        //browser_major: uaParser.browser.major, // major est déprécié
        browser_name: uaParser.browser.name,
        browser_version: uaParser.browser.version,
        cpu_architecture: uaParser.cpu.architecture,
        device_model: uaParser.device.model,
        ua_device_type: uaParser.device.type,
        device_vendor: uaParser.device.vendor,
        engine_name: uaParser.engine.name,
        engine_version: uaParser.engine.version,
        os_name: uaParser.os.name,
        os_version: uaParser.os.version,
        ua: uaParser.ua
    }

    return payload
}

function onTrack(payload) {
    return async (dispatch, getState) => {
        return api
            .post("v1/core/track", { payload })
            //.then(res => { console.log(res.data) })
            .catch(err => { console.log(err.response) }) 
        }
}

export function trackOnRouteChanged() {
    return async (dispatch, getState) => {
        const trackedData = getUserTrackedData()
        const state = getState()
        const user = state.auth.user
        const from = state.history.from
        const to = state.history.to
        const estimationId = state.estimate.estimation.id
        const housingId = state.estimate.housing.id

        const payload = {
            ...trackedData,
            message: "route changed",
            profile_id: user.id,
            profile_email: user.email,
            estimation_id: estimationId,
            housing_id: housingId,
            current_page: window.location.href,
            from_route_path: from,
            from_route_name: getNameOfPath(from),
            to_route_path: to,
            to_route_name: getNameOfPath(to)
        }

        // console.log("track:", payload.message, payload)

        if (isDev) {
            return
        }

        return dispatch(onTrack(payload))
    }
}

export function trackOnLogin(to) {
    return async (dispatch, getState) => {
        const trackedData = getUserTrackedData()
        const state = getState()
        const user = state.auth.user
        const from = "/login"

        const payload = {
            ...trackedData,
            message: "login",
            profile_id: user.id,
            profile_email: user.email,
            estimation_id: null,
            housing_id: null,
            current_page: window.location.origin + to,
            from_route_path: from,
            from_route_name: getNameOfPath(from),
            to_route_path: to,
            to_route_name: getNameOfPath(to)
        }

        //console.log("track:", payload.message, payload)

        if (isDev) {
            return
        }

        return dispatch(onTrack(payload))
    }
}

export function trackOnLoginSessionNotExpired() {
    return async (dispatch, getState) => {
        const trackedData = getUserTrackedData()
        const state = getState()
        const user = state.auth.user
        const from = state.history.from
        const to = from

        const payload = {
            ...trackedData,
            message: "login (session not expired)",
            profile_id: user.id,
            profile_email: user.email,
            estimation_id: null,
            housing_id: null,
            current_page: window.location.href,
            from_route_path: from,
            from_route_name: getNameOfPath(from),
            to_route_path: to,
            to_route_name: getNameOfPath(to)
        }

        //console.log("track:", payload.message, payload)

        if (isDev) {
            return
        }

        return dispatch(onTrack(payload))
    }
}

export function trackOnLogout() {
    return async (dispatch, getState) => {
        const trackedData = getUserTrackedData()
        const state = getState()
        const user = state.auth.user
        const from = state.history.from
        const to = "/login"
        const estimationId = state.estimate.estimation.id
        const housingId = state.estimate.housing.id

        const payload = {
            ...trackedData,
            message: "logout",
            profile_id: user.id,
            profile_email: user.email,
            estimation_id: estimationId,
            housing_id: housingId,
            current_page: window.location.origin + to,
            from_route_path: from,
            from_route_name: getNameOfPath(from),
            to_route_path: to,
            to_route_name: getNameOfPath(to)
        }

        //console.log("track:", payload.message, payload)

        if (isDev) {
            return
        }

        return dispatch(onTrack(payload))
    }
}