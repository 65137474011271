import { useSelector } from "react-redux"
import { selectOrg } from "../../../account/selectors"
import { InfoRounded } from "@mui/icons-material"
import { scssVar } from "../../../../app/scssVar"

const TabIntegrationLabel = ({ label }) => {
    const org = useSelector(selectOrg)

    if (
        !org.loading
        && !org.error
        && (
            !org.address 
            || !org.email 
            || !org.phone
        )
    ) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {label}
                <InfoRounded sx={{ color: scssVar.danger, ml: .5 }} />
            </span>
        )
    }

    return label
}

export default TabIntegrationLabel