import { Typography } from "@mui/material";
import { scssVar } from "../../app/scssVar";

const Text = ({ children, sx }) => (
    <Typography sx={Object.assign({}, {
        fontFamily: scssVar.fontFamily,
        fontSize: 16
    }, sx)}>
        {children}
    </Typography>
)

export default Text