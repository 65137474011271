import { useDispatch, useSelector } from "react-redux"
import { apiOfferActions, selectApiLongitudinalKpis } from "../../apiOfferSlice"
import { scssVar } from "../../../../app/scssVar"
import { Box, CircularProgress } from "@mui/material"
import LongitStacked from "../../../../common/components/dashboard/longitStacked/LongitStacked"
import { useEffect, useState } from "react"
import Toggle from "../../../../common/components/Toggle"

const Wrapper = ({ children }) => (
    <Box sx={{ 
        bgcolor: scssVar.greyLighter, 
        pt: 2,
        pr: 2,
        pl: 2,
        pb: 0,
        borderRadius: "16px", 
        position: "relative" 
    }}>
        {children}
    </Box>
)

const Longitudinal = () => {
    const dispatch = useDispatch()
    const { groups, dataDaily, dataWeekly, dataMonthly, isLoading, error, selectedStackedType } = useSelector(selectApiLongitudinalKpis)
    const [width, setWidth] = useState(0)

    const handleChange = (newValue) => {
        dispatch(apiOfferActions.setSelectedLongitKpisStackedType(newValue))
    }

    useEffect(() => {
        const resize = () => {
            const w = window.innerWidth
            const lg = 1200
            const md = 900
            const sm = 600

            if (w >= lg) setWidth(1000)
            else if (w >= md) setWidth(w - 150)
            else if (w >= sm) setWidth(w - 150)
            else setWidth(w - 75)
        }

        resize()

        window.addEventListener("resize", resize)

        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])

    if (isLoading) {
        return (
            <Wrapper>
                <CircularProgress size={20} sx={{ color: scssVar.primary }} />
            </Wrapper>
        )
    }

    if (error) {
        return (
            <Wrapper>
                <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer</p>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                flexWrap: "wrap",
                position: "absolute",
                right: 20,
                top: 20,
                "@media (max-width: 700px)": {
                    position: "relative",
                    right: "unset",
                    top: "unset",
                    mb: 2
                }
            }}>
                <Box sx={{ 
                    display: "flex", 
                    width: 250, 
                    border: `1px solid ${scssVar.primary}`, 
                    borderRadius: "4px",
                    "@media (max-width: 700px)": {
                        width: "100%"
                    }
                }}>
                    <Toggle 
                        label="Jour"
                        selectedValue={selectedStackedType}
                        value="day"
                        onClick={handleChange}
                        sx={{
                            color: selectedStackedType === "day" ? scssVar.primary : scssVar.black,
                            height: 25,
                            fontSize: 13,
                            p: 0,
                            width: 250 / 3,
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            border: "none",
                            borderRight: `1px solid ${scssVar.primary}`,
                            "&:hover": {
                                border: "none",
                                borderRight: `1px solid ${scssVar.primary}`,
                            },
                            "@media (max-width: 700px)": {
                                width: "33.33%"
                            }
                        }}
                    />
                    <Toggle 
                        label="Semaine"
                        selectedValue={selectedStackedType}
                        value="week"
                        onClick={handleChange}
                        sx={{
                            color: selectedStackedType === "week" ? scssVar.primary : scssVar.black,
                            height: 25,
                            fontSize: 13,
                            p: 0,
                            width: 250 / 3,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            border: "none",
                            borderRight: `1px solid ${scssVar.primary}`,
                            "&:hover": {
                                border: "none",
                                borderRight: `1px solid ${scssVar.primary}`,
                            },
                            "@media (max-width: 700px)": {
                                width: "33.33%"
                            }
                        }}
                    />
                    <Toggle 
                        label="Mois"
                        selectedValue={selectedStackedType}
                        value="month"
                        onClick={handleChange}
                        sx={{
                            color: selectedStackedType === "month" ? scssVar.primary : scssVar.black,
                            height: 25,
                            fontSize: 13,
                            p: 0,
                            width: 250 / 3,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            border: "none",
                            "&:hover": {
                                border: "none",
                            },
                            "@media (max-width: 700px)": {
                                width: "33.33%"
                            }
                        }}
                    />
                </Box>
            </Box>
            <LongitStacked 
                data={
                    selectedStackedType === "day"
                        ? dataDaily
                        : selectedStackedType === "week"
                            ? dataWeekly
                            : dataMonthly
                } 
                groups={groups}
                params={{ 
                    width,
                    dateType: selectedStackedType
                }} 
            />
        </Wrapper>
    )
}

export default Longitudinal