const ERP = ({ bottom, left }) => (
    <div style={{ 
        fontSize: 7.5,
        lineHeight: "7.5px",
        position: "absolute",
        left: left || 35,
        bottom: bottom || 170
    }}>
        Lien E.R.P. (État des risques et des pollutions) : https://www.georisques.gouv.fr/
    </div>
)

export default ERP