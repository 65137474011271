import { api } from '../../../../../common/setupApi';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert } from '../../../../alerts/alertsSlice';
import { updateHousing } from '../../../actions';
import PhotoUploader from "../../../../photoUploader/PhotoUploader";
import useMainBlob from "../../../../photoUploader/hooks/useMainBlob";
import { setMainBlob, deleteMainBlob, getMainBlob } from "../../../../photoUploader/photosSlice";
import { useState } from 'react';
import { getCompressedImg, getCroppedImg } from '../../../../photoUploader/settings';

const MainPhoto = () => {
    const dispatch = useDispatch()
    const housing = useSelector(state => state.estimate.housing)
    const housingId = housing.id
    const { blob, loading } = useMainBlob()
    const [newPhotoLoading, setNewPhotoLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const handleSubmit = async (cropArea, rotation, aspect, newPhotoFile) => {
        setNewPhotoLoading(true)

        return getCroppedImg(URL.createObjectURL(newPhotoFile), cropArea, rotation)
            .then(async (croppedImg) => {
                console.log(`originalFile size ${croppedImg.size / 1024 / 1024} MB`)

                return getCompressedImg(croppedImg)
                    .then(async (compressedImg) => {
                        console.log(`compressedFile size ${compressedImg.size / 1024 / 1024} MB`)

                        const formData = new FormData()
                        formData.append("housing_id", housingId)
                        formData.append("photo", compressedImg, "main")

                        return api
                            .post("v1/housing/photo/", formData, { headers: { "content-type": "multipart/form-data" } })
                            .then(res => {
                                const updatedHousing = { ...housing, photo: res.data }
                                dispatch(updateHousing(updatedHousing))
                                setNewPhotoLoading(false)
                                if (blob) {
                                    dispatch(setMainBlob(res.data))
                                    dispatch(addAlert("La photo a bien été modifiée", "success"))
                                } else {
                                    dispatch(getMainBlob())
                                    dispatch(addAlert("La photo a bien été ajoutée", "success"))
                                }
                            })
                            .catch(err => {
                                console.log(err.response)
                                setNewPhotoLoading(false)
                                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(addAlert("Une erreur est survenue lors de la compression de l'image, veuillez réessayer", "error"))
                        setNewPhotoLoading(false)
                    })
            })
            .catch(err => {
                console.log(err)
                dispatch(addAlert("Une erreur est survenue lors du redimensionnement de l'image, veuillez réessayer", "error"))
                setNewPhotoLoading(false)
            })
    }

    const handleDelete = async () => {
        setDeleteLoading(true)

        return api
            .delete(`v1/housing/delete-photo/${housingId}/`)
            .then(res => {
                // setDeleteLoading(false)
                dispatch(updateHousing(res.data))
                dispatch(deleteMainBlob())
                dispatch(addAlert("La photo a bien été supprimée", "success"))
            })
            .catch(err => {
                console.log(err.response)
                setDeleteLoading(false)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }

    return (
        <PhotoUploader
            onSubmit={handleSubmit}
            onUpdate={handleSubmit}
            newPhotoLoading={newPhotoLoading}
            onDelete={handleDelete}
            deleteLoading={deleteLoading}
            blob={blob}
            loadingBlob={loading}
            withCropper
            aspect={1}
        />
    )
}

export default MainPhoto