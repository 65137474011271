import { scssVar } from "../../app/scssVar"
import { styled } from '@mui/system';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

const Button = styled(ButtonUnstyled)`
    position: relative;
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    height: 2.5em;
    width: 100%;
    border-radius: 8px;
    color: ${scssVar.greyDark};
    background-color: ${scssVar.greyLighter};
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    text-align: center;

    &:hover {
        border: 1px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.active} {
        border: 2px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const Toggle = ({
    onClick,
    value,
    selectedValue,
    disabled,
    label,
    bg,
    color,
    bgChecked,
    colorChecked,
    sx
}) => {
    const handleClick = () => {
        onClick(value)
    }

    return (
        <Button
            type="button"
            onClick={handleClick}
            disabled={disabled}
            sx={Object.assign({}, {
                bgcolor: 
                    value === selectedValue 
                        ? (bgChecked || scssVar.primaryLight) 
                        : (bg || scssVar.greyLighter),
                color: 
                    value === selectedValue 
                        ? (colorChecked || scssVar.primary) 
                        : (color || scssVar.greyDark)
            }, sx)}
        >
            {label}
        </Button>
    )
}

export default Toggle