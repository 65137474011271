import { Box, Collapse, IconButton, ListItemButton, ListItemText } from "@mui/material"
import { Fragment, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"
import { getRegisterLink, invalidateLink } from "../actions"

const initialState = {
    collapsed: false,
    copied: false
}

const RegisterLink = ({ team }) => {
    const dispatch = useDispatch()
    const linkRef = useRef(null)
    const [state, setState] = useState(initialState)
    const teamId = team.team_id
    const link = team.link
    const getLinkLoading = team.getLinkLoading
    const invalidateLinkLoading = team.invalidateLoading

    const handleCollapse = () => {
        setState({ ...state, collapsed: !state.collapsed })
    }

    const handleGetLink = () => {
        dispatch(getRegisterLink(teamId))
    }

    const handleInvalidateLink = () => {
        dispatch(invalidateLink(teamId))
    }

    const copyToClipboard = () => {
        linkRef.current.select()
        linkRef.current.setSelectionRange(0, 99999) /* For mobile devices */
        navigator.clipboard.writeText(link)
        setState({ ...state, copied: true })
    }

    return (
        <Fragment>
            <ListItemButton
                component="div"
                onClick={handleCollapse}
                sx={{
                    pl: 0,
                    pr: 0
                }}
            >
                <ListItemText
                    primary="Lien d'inscription"
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black",
                            fontWeight: 700
                        },
                    }}
                />
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark,
                        transform: `rotate(${state.collapsed ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </ListItemButton>
            <Collapse in={state.collapsed} timeout="auto" unmountOnExit>
                <Box sx={{ display: "flex" }}>
                    <input
                        ref={linkRef}
                        type="text"
                        className={`input ${link && "is-success"}`}
                        onChange={() => {}}
                        value={link || ""}
                        placeholder="Lien d'inscription"
                        disabled={!link}
                    />
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ml: 1
                    }}>
                        <IconButton
                            disabled={!link}
                            onClick={copyToClipboard}
                            sx={{
                                bgcolor: state.copied ? scssVar.successLight : scssVar.greyLight,
                                color: state.copied ? scssVar.success : scssVar.greyDark
                            }}
                        >
                            <i className="iconoir-copy" style={{ fontSize: 24 }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    mt: "35px",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }
                }}>
                    <Button 
                        variant="light"
                        onClick={handleInvalidateLink}
                        loading={invalidateLinkLoading}
                        disabled={!link || invalidateLinkLoading || getLinkLoading}
                        sx={{
                            pl: 6,
                            pr: 6,
                        }}
                    >
                        Invalider
                    </Button>
                    <Box sx={{ m: 1 }} />
                    <Button 
                        onClick={handleGetLink}
                        loading={getLinkLoading}
                        disabled={getLinkLoading || invalidateLinkLoading}
                        sx={{
                            pl: 6,
                            pr: 6,
                        }}
                    >
                        Générer
                    </Button>
                </Box>
            </Collapse>
        </Fragment>
    )
}

export default RegisterLink