const initialState = {
    estimations: {
        loading: false,
        error: null,
        items: [],
        filtered: [],
        fetched: false,
        filters: {
            typeVente: "all",
            nbPiecesPrinc: 1,
            value: 0,
            step: "all",
            search: null,
        },
        sortBy: "récentes",
        selected: [],
        loadingDelete: false,
        deleteError: null
    },
    unfinished: {
        loading: false,
        error: null,
        items: [],
        fetched: false
    },
}

const estimations = (state = initialState, action) => {
    switch (action.type) {

        case "estimations/set_estimations_of_housing":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: state.estimations.items.map(d => d.housing_id === action.payload.id ? { ...d, housing: action.payload } : d),
                    filtered: state.estimations.filtered.map(d => d.housing_id === action.payload.id ? { ...d, housing: action.payload } : d)
                }
            }

        case "estimations/set_unfinished_of_housing":
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    items: state.unfinished.items.map(d => d.housing_id === action.payload.id ? { ...d, housing: action.payload } : d)
                }
            }

        case "estimations/add_unfinished":
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    items: [action.payload, ...state.unfinished.items]
                }
            }

        case "estimations/set_unfinished":
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    items: state.unfinished.items.map(d => d.id === action.payload.id ? action.payload : d)
                }
            }

        case "estimations/delete_unfinished": {
            const items = Array.isArray(action.payload) 
                ? state.unfinished.items.filter(d => !action.payload.includes(d.id)) 
                : state.unfinished.items.filter(d => d.id !== action.payload)
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    items
                }
            }
        }

        case "estimations/delete_estimation":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: state.estimations.items.filter(d => d.id !== action.payload),
                    filtered: state.estimations.filtered.filter(d => d.id !== action.payload)
                }
            }

        case "estimations/set_estimation":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: state.estimations.items.map(d => d.id === action.payload.id ? action.payload : d),
                    filtered: state.estimations.filtered.map(d => d.id === action.payload.id ? action.payload : d),
                }
            }

        case "estimations/add_estimation": 
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    items: [...state.estimations.items, action.payload]
                }
            }

        case "estimations/delete_estimations_loading":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loadingDelete: true,
                    deleteError: null
                }
            }

        case "estimations/delete_estimations_success":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loadingDelete: false,
                    items: state.estimations.items.filter(d => !action.payload.includes(d.id)),
                    filtered: state.estimations.filtered.filter(d => !action.payload.includes(d.id)),
                }
            }

        case "estimations/delete_estimations_failure":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loadingDelete: false,
                    deleteError: action.payload
                }
            }

        case "estimations/set_sort":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    sortBy: action.payload.value
                }
            }

        case "estimations/filter_estimations":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    filtered: action.payload
                }
            }

        case "estimations/set_filters":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    filters: {
                        ...state.estimations.filters,
                        [action.payload.name]: action.payload.value
                    }
                }
            }

        case "estimations/deselect_all":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    selected: []
                }
            }

        case "estimations/select":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    selected: action.payload.checked 
                        ? [...state.estimations.selected, action.payload.id]
                        : state.estimations.selected.filter(d => d !== action.payload.id)
                }
            }

        case "estimations/get_unfinished_loading":
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    loading: true,
                    error: null,
                    fetched: true
                }
            }

        case "estimations/get_unfinished_success":
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    loading: false,
                    items: action.payload
                }
            }

        case "estimations/get_unfinished_failure":
            return {
                ...state,
                unfinished: {
                    ...state.unfinished,
                    loading: false,
                    error: action.payload
                }
            }

        case "estimations/get_estimations_loading":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loading: true,
                    error: null,
                    fetched: true
                }
            }

        case "estimations/get_estimations_success":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loading: false,
                    items: action.payload,
                    //filtered: action.payload,
                }
            }

        case "estimations/get_estimations_failure":
            return {
                ...state,
                estimations: {
                    ...state.estimations,
                    loading: false,
                    error: action.payload
                }
            }

        case "auth/logout":
            return initialState

        default:
            return state
    }
}

export default estimations