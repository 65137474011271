import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOrgBlob, selectOrgBlob } from "../photosSlice"

const useOrgBlob = () => {
    const dispatch = useDispatch()
    const { blob, loading } = useSelector(selectOrgBlob)

    useEffect(() => {
        dispatch(getOrgBlob())
    }, [dispatch])

    return { loading, blob }
}

export default useOrgBlob