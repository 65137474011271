import { useSelector } from "react-redux"
import { capitalizeFirstLetter, m2, numberWithSpaces } from "../../../../common/utils"
import { selectAddress } from "../../../estimate/selectors"

const AdDescriptif = ({ style }) => {
    const housing = useSelector(state => state.estimate.housing)
    const estimation = useSelector(state => state.estimate.estimation)
    const { commune } = useSelector(selectAddress)
    const typeVente = housing.type_vente.toLowerCase()
    const surfaceReelleBati = housing.surface_reelle_bati
    const nbPieces = housing.nombre_pieces_principales
    const nbChambres = estimation.nb_chambres
    const surfacePieceDeVie = estimation.surface_piece_de_vie
    const etatDuBien = estimation.etat_du_bien

    return (
        <p style={Object.assign({}, {
            fontSize: 13.5,
            lineHeight: "21px"
        }, style)}>
            <b>Type de bien : </b>{capitalizeFirstLetter(typeVente)}
            <br />
            <b>Ville : </b>{commune}
            <br />
            <b>Superficie du bien : </b>{surfaceReelleBati} {m2}
            <br />
            <b>Nombre de pièces : </b>{nbPieces} pièce{nbPieces > 1 ? "s" : ""}
            <br />
            <b>Nombre de chambres : </b>{nbChambres !== "" && nbChambres !== null && nbChambres !== undefined ? (
                nbChambres
            ):(
                "Non défini"
            )}
            <br />
            <b>Surface de la pièce de vie : </b>{surfacePieceDeVie !== "" && surfacePieceDeVie !== null && surfacePieceDeVie !== undefined ? (
                surfacePieceDeVie === "8 à 15" 
                    || surfacePieceDeVie === "16 à 20" 
                    || surfacePieceDeVie === "21 à 30" 
                        ? `${numberWithSpaces(surfacePieceDeVie)} ${m2}`
                        : surfacePieceDeVie === "31 et plus" 
                            ? `31 ${m2} et plus`
                            : surfacePieceDeVie
            ):(
                "Non définie"
            )}
            <br />
            <b>Etat du bien : </b>
            {etatDuBien !== "" 
            && etatDuBien !== null 
            && etatDuBien !== undefined 
            && surfacePieceDeVie !== null 
            && surfacePieceDeVie !== undefined 
            && surfacePieceDeVie !== "" ? (
                capitalizeFirstLetter(etatDuBien)
            ):(
                "Non défini"
            )}
        </p>
    )
}

export default AdDescriptif