import { Box, Typography } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const HeaderPreview = ({
    onGoBack,
    loading,
    scale,
    setScale
}) => {
    return (
        <Box sx={{
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            bgcolor: "white",
            borderBottom: 1,
            borderColor: scssVar.grey,
            zIndex: 1300,
        }}>
            <Box sx={{
                maxWidth: "1341px",
                margin: "0 auto",
                p: {
                    xs: "0 20px 0",
                    sm: "0 35px 0",
                    lg: "0 60px 0"
                },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "62px",
            }}>
                <Button
                    variant="light" 
                    onClick={onGoBack}
                    sx={{
                        pl: 2
                    }}
                >
                    <i 
                        className="iconoir-arrow-left"
                        style={{
                            fontSize: 24
                        }}
                    ></i>
                    Retour
                </Button>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <Button
                        variant="light"
                        disabled={loading}
                        onClick={() => {
                            const newScale = scale <= 0.2 ? 0.1 : scale - 0.1
                            setScale(newScale)
                        }}
                        sx={{
                            width: 45,
                            height: 45,
                            borderRadius: "45px",
                            p: 0,
                            mr: "10px"
                        }}
                    >
                        <i
                            className="iconoir-minus"
                            style={{
                                fontSize: 24
                            }}
                        ></i>
                    </Button>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.greyDarker,
                        mr: "10px"
                    }}>
                        {(scale * 100).toFixed(0)}%
                    </Typography>
                    <Button
                        variant="light"
                        disabled={loading}
                        onClick={() => {
                            const newScale = scale >= 0.9 ? 1 : scale + 0.1
                            setScale(newScale)
                        }}
                        sx={{
                            width: 45,
                            height: 45,
                            borderRadius: "45px",
                            p: 0
                        }}
                    >
                        <i
                            className="iconoir-plus"
                            style={{
                                fontSize: 24
                            }}
                        ></i>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default HeaderPreview