import { Box, IconButton, Typography } from "@mui/material"
import { scssVar } from "../../../../../../app/scssVar"
import { numberWithSpaces } from "../../../../../../common/utils"
import { useSelector } from "react-redux"
import { selectIsWorking, selectValues } from "../../../../selectors"
import Button from "../../../../../../common/components/Button"
import { Fragment, useState } from "react"
import DialogUpdatePrice from "./DialogUpdatePrice"

const PrixNetVendeur = () => {
    const { prixNetVendeur, customValue } = useSelector(selectValues)
    const working = useSelector(selectIsWorking)
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <Box sx={{
                bgcolor: scssVar.greyLighter,
                borderRadius: "16px",
                p: "25px",
                height: "100%"
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: scssVar.greyDarker,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                }}>
                    Prix net vendeur suggéré
                </Typography>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    fontSize: "28px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                }}>
                    {numberWithSpaces(prixNetVendeur)} €
                </Typography>

                <div style={{ margin: 8 }} />

                <Typography sx={{
                    color: "black",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    fontFamily: scssVar.fontFamily,
                }}>
                    Mon avis d'expert
                </Typography>

                {customValue ? (
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap"
                    }}>
                        <Typography sx={{
                            color: "black",
                            fontSize: "28px",
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                            fontFamily: scssVar.fontFamily,
                            mr: 1
                        }}>
                            {numberWithSpaces(customValue)} €
                        </Typography>
                        <IconButton
                            onClick={() => setOpen(true)}
                            disabled={working}
                            sx={{
                                fontSize: 24,
                                color: "black",
                                bgcolor: scssVar.greyLight
                            }}
                        >
                            <i className="iconoir-edit-pencil edit-icon"></i>
                        </IconButton>
                    </Box>
                ):(
                    <Button
                        variant="primary-light"
                        onClick={() => setOpen(true)}
                        disabled={working}
                        sx={{
                            mt: "7px",
                            borderRadius: "8px",
                            pl: 0,
                            pr: 0,
                            width: "100%",
                            maxWidth: 230,
                            height: 48,
                            mr: 2,
                            fontSize: 16,
                            bgcolor: "white",
                            color: scssVar.greyDark,
                            "& i": {
                                color: scssVar.black,
                                fontSize: 24,
                                ml: "10px",
                                "@media (min-width: 900px)": {
                                    ml: "3px",
                                    fontSize: 20,
                                },
                                "@media (min-width: 970px)": {
                                    ml: "10px",
                                    fontSize: 24
                                }
                            },
                            "&:hover": {
                                color: scssVar.primary
                            },
                            "@media (min-width: 900px)": {
                                fontSize: 13
                            },
                            "@media (min-width: 970px)": {
                                fontSize: 16
                            }
                        }}
                    >
                        Modifier le prix évalué
                        <i className="iconoir-edit-pencil edit-icon"></i>
                    </Button>
                )}
            </Box>
            <DialogUpdatePrice open={open} onClose={() => setOpen(false)} />
        </Fragment>
    )
}

export default PrixNetVendeur