import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    newAppVersion: null,
    waitingWorker: null,
}

export const appUpdateSlice = createSlice({
    name: "appUpdate",
    initialState,
    reducers: {
        updateAvailable: (state, action) => {
            state.newAppVersion = action.payload
        },
        setWaitingWorker: (state, action) => {
            console.log(action.payload)
            state.waitingWorker = action.payload
        }
    },
    // rien en cas de logout
})

export const { updateAvailable, setWaitingWorker } = appUpdateSlice.actions

export default appUpdateSlice.reducer

export const selectIsUpdateAvailable = (state) => {
    const newAppVersion = state.appUpdate.newAppVersion
    const waitingWorker = state.appUpdate.waitingWorker
    return newAppVersion || waitingWorker
}