import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"

const Surfacturation = ({ open, onClose, onSubmit, loading }) => {
    const additionalPrice = useSelector(state => state.account.teams.items.new_user_price)
    const proraPrice = useSelector(state => state.account.teams.items.new_user_prorated_price)

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            fullWidth
        >
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
            }}>
                Mise à jour d'abonnement
            </DialogTitle>
            <DialogContent>
                L'ajout de cet utilisateur engendrera un coût supplémentaire de {additionalPrice}€ par mois.
                Le montant proratisé de {proraPrice}€, pour le mois en cours, sera reporté sur votre prochaine facture.
            </DialogContent>
            <DialogActions>
                <Button
                    variant="light"
                    onClick={onClose}
                    sx={{
                        width: 150
                    }}
                >
                    Annuler
                </Button>
                <Button
                    loading={loading}
                    onClick={onSubmit}
                    disabled={loading}
                    sx={{
                        width: 150
                    }}
                >
                    Continuer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Surfacturation