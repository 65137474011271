import { Badge, IconButton } from "@mui/material"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import useAuth from "../../../features/auth/hooks/useAuth"
import { styled } from '@mui/material/styles';
import { selectIsUpdateAvailable } from "../../../features/appUpdate/appUpdateSlice";
import Button from "../Button";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: 3,
        right: 3,
        padding: '0 4px',
        background: scssVar.danger,
        color: "white",
        border: `1px solid white`,
    },
}))

const Big = ({ onClick, open }) => {
    const { user } = useAuth()
    const isUpdateAvailable = useSelector(selectIsUpdateAvailable)

    return (
        <StyledBadge badgeContent={isUpdateAvailable ? "1" : null}>
            <Button
                variant="light"
                id="profile-button"
                onClick={onClick}
                sx={{
                    width: "fit-content",
                    padding: "5px 15px"
                }}
            >
                <i 
                    className="iconoir-profile-circled"
                    style={{
                        fontSize: 24,
                        marginRight: 5,
                        color: scssVar.primary
                    }}
                ></i>
                <span style={{
                    width: "fit-content",
                    maxWidth: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: scssVar.primary
                }}>
                    {user.firstName}
                </span>
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.primary,
                        transform: `rotate(${open ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </Button>
        </StyledBadge>
    )
}

const Small = ({ onClick }) => {
    const isUpdateAvailable = useSelector(selectIsUpdateAvailable)
    return (
        <StyledBadge badgeContent={isUpdateAvailable ? "1" : null}>
            <IconButton onClick={onClick} sx={{ p: 0 }}>
                <i className="iconoir-profile-circled" style={{ fontSize: 30, color: scssVar.primary }}></i>
            </IconButton>
        </StyledBadge>
    )
}

const ButtonUserProfile = ({ small, open, onClick }) => {
    if (small) {
        return <Small onClick={onClick} />
    }
    return <Big onClick={onClick} open={open} />
}

export default ButtonUserProfile