const UploadButtonContainer = ({
    disabled,
    children,
    value,
    multiple,
    onChange,
    accept
}) => (
    <label>
        {children}
        <input 
            type="file" 
            style={{ display: "none" }}
            value={value || ""}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            accept={accept}
        />
    </label>
)

export default UploadButtonContainer