import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateEstimation } from "../../actions"
import useCharacteristicItems from "../../hooks/useCharacteristicItems"
import StepTitle from "./StepTitle"
import FieldControl from "../../../../common/components/FieldControl"
import { m2 } from "../../../../common/utils"
import { selectEstimation, selectIsWorking } from "../../selectors"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const choices_localisation_teletravail = [
    { label: "Inconnue", value: "Inconnue" },
    { label: "Espace aménagé", value: "Espace aménagé" },
    { label: "Pièce indépendante", value: "Pièce indépendante" },
    { label: "Bâtiment indépendant", value: "Bâtiment indépendant" },
]

function getCheckedDetailField(name) {
    return name === "avec_terrasse" ? "surface_terrasse" 
        : name === "avec_jardin" ? "surface_jardin"
        : name === "avec_rooftop" ? "surface_rooftop"
        : name === "avec_teletravail" ? "localisation_teletravail" 
        : undefined
}

const initialState = {
    open: false,
    field: "",
    checkedField: "",
    checkedDetailField: ""
}

const DetailModal = ({
    open,
    onClose,
    title,
    children,
    onSubmit,
    disabledSubmit,
}) => {
    const working = useSelector(state => state.estimate.working)

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle sx={{
                fontWeight: 700,
                fontSize: { xs: 18, sm: 24 },
                fontFamily: scssVar.fontFamily,
                display: "flex",
                justifyContent: "space-between",
            }}>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    fontSize: 14,
                    fontFamily: scssVar.fontFamily
                }}>
                    Ajoutez des précisions
                </DialogContentText>
                <Box sx={{ 
                    mt: "20px",
                    width: "100%"
                }}>
                    {children}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="light" onClick={onClose}>Annuler</Button>
                <Button onClick={onSubmit} disabled={disabledSubmit || working} loading={working}>Envoyer</Button>
            </DialogActions>
        </Dialog>
    )
}

const Step05Form = ({ stepName, noProgressBarDisplay }) => {
    const dispatch = useDispatch()
    const estimation = useSelector(selectEstimation)
    const working = useSelector(selectIsWorking)
    const items = useCharacteristicItems(estimation)
    const [state, setState] = useState(initialState) 

    const handleChange = (name, value) => {
        const checked = value
        const checkedDetailField = getCheckedDetailField(name)

        if (checkedDetailField) {
            if (checked) {
                setState({
                    ...state,
                    checkedField: name,
                    checkedDetailField
                })

                return
            } else {
                dispatch({
                    type: "estimate/set_estimation_value",
                    payload: { 
                        name: checkedDetailField, 
                        value: null 
                    }
                })
            }
        }

        dispatch({
            type: "estimate/set_estimation_value",
            payload: { 
                name, 
                value: checked
            }
        })

        dispatch(updateEstimation(stepName))
    }

    const handleChangeFieldInModal = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        dispatch({
            type: "estimate/set_estimation_value",
            payload: { name, value }
        })
    }

    const handleCloseFieldModal = () => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { 
                name: state.checkedDetailField, 
                value: null
            }
        })
        setState(initialState)
    }

    const handleSubmitFieldModal = () => {
        dispatch({
            type: "estimate/set_estimation_value",
            payload: { 
                name: state.checkedField, 
                value: true
            }
        })
        dispatch(updateEstimation(stepName))
        setState(initialState)
    }

    return (
        <Fragment>
            <StepTitle title="Caractéristiques" step={stepName} noProgressBarDisplay={noProgressBarDisplay} />
            <div style={{ marginBottom: 50 }} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FieldControl
                        label="Espaces extérieurs"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "ext")}
                        onChange={(name, checked) => handleChange(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Dépendances"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "depend")}
                        onChange={(name, checked) => handleChange(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Installations"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "install")}
                        onChange={(name, checked) => handleChange(name, checked)}
                        xs={6}
                        md={4} 
                        lg={12/5}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Équipements"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "equip")}
                        onChange={(name, checked) => handleChange(name, checked)}
                        xs={6}
                        md={4}
                        lg={3}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldControl
                        label="Environnement"
                        type="checkbox-big"
                        choices={items.filter(d => d.category === "env")}
                        onChange={(name, checked) => handleChange(name, checked)}
                        xs={6}
                        md={4}
                        lg={3}
                        disabled={working}
                    />
                </Grid>
            </Grid>
            <DetailModal
                open={state.checkedField === "avec_jardin"}
                title={`Surface du jardin en ${m2}`}
                disabledSubmit={estimation.surface_jardin < 1}
                onSubmit={handleSubmitFieldModal}
                onClose={handleCloseFieldModal}
            >
                <FieldControl 
                    label="Surface du jardin en m²" 
                    isSuccess={estimation.surface_jardin >= 1} 
                    value={estimation.surface_jardin || ""}
                    name="surface_jardin"
                    placeholder="Surface"
                    onChange={handleChangeFieldInModal}
                    type="number" 
                />
            </DetailModal>
            <DetailModal
                open={state.checkedField === "avec_terrasse"}
                title={`Surface de la terrasse en ${m2}`}
                disabledSubmit={estimation.surface_terrasse < 1}
                onSubmit={handleSubmitFieldModal}
                onClose={handleCloseFieldModal}
            >
                <FieldControl 
                    label="Surface de la terrasse en m²" 
                    isSuccess={estimation.surface_terrasse >= 1} 
                    value={estimation.surface_terrasse || ""}
                    name="surface_terrasse"
                    placeholder="Surface"
                    onChange={handleChangeFieldInModal}
                    type="number" 
                />
            </DetailModal>
            <DetailModal
                open={state.checkedField === "avec_rooftop"}
                title={`Surface du rooftop en ${m2}`}
                disabledSubmit={estimation.surface_rooftop < 1}
                onSubmit={handleSubmitFieldModal}
                onClose={handleCloseFieldModal}
            >
                <FieldControl 
                    label="Surface du rooftop en m²" 
                    isSuccess={estimation.surface_rooftop >= 1} 
                    value={estimation.surface_rooftop || ""}
                    name="surface_rooftop"
                    placeholder="Surface"
                    onChange={handleChangeFieldInModal}
                    type="number" 
                />
            </DetailModal>
            <DetailModal
                open={state.checkedField === "avec_teletravail"}
                title="Localisation de l’espace de télétravail"
                disabledSubmit={!estimation.localisation_teletravail}
                onSubmit={handleSubmitFieldModal}
                onClose={handleCloseFieldModal}
            >
                <FieldControl 
                    label="Localisation de l'espace de télétravail" 
                    isSuccess={estimation.localisation_teletravail} 
                    value={estimation.localisation_teletravail || ""}
                    name="localisation_teletravail"
                    onChange={handleChangeFieldInModal}
                    type="select" 
                    options={
                        <Fragment>
                            <option value="">Sélectionner</option>
                            {choices_localisation_teletravail.map((d, i) => 
                                <option key={i} value={d.value}>
                                    {d.label}
                                </option>
                            )}
                        </Fragment>
                    }
                />
            </DetailModal>
        </Fragment>
    )
}

export default Step05Form