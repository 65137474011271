import { Fragment } from "react"
import { scssVar } from "../../../../app/scssVar"
import { appName } from "../../../../common/utils"

const tagStyle = {
    color: scssVar.primary
}

const attributeStyle = {
    color: scssVar.primary,
    fontWeight: 600,
    marginLeft: 25
}

const valueStyle = {
    color: scssVar.success
}

export const ApiIframeCodeSample = () => (
    <pre style={{ 
        whiteSpace: "pre-wrap",
        backgroundColor: scssVar.greyLighter,
        borderRadius: "12px"
    }}>
        <code>
            <span style={tagStyle}>&lt;iframe</span>
            <br />
            <span style={attributeStyle}>title=</span>
            <span style={valueStyle}>"{appName}"</span>
            <br />
            <span style={attributeStyle}>src=</span>
            <span style={valueStyle}>"{window.location.origin}/integration"</span>
            <br />
            <span style={attributeStyle}>width=</span>
            <span style={valueStyle}>"100%"</span>
            <br />
            <span style={attributeStyle}>height=</span>
            <span style={valueStyle}>"600px"</span>
            <br />
            <span style={tagStyle}>&gt;&lt;/iframe&gt;</span>
        </code>
    </pre>
)

const Code = () => {
    return (
        <Fragment>
            <p style={{ fontWeight: 800, marginBottom: 15 }}>Code à intégrer sur votre site web</p>
            <ApiIframeCodeSample />
            <p style={{ color: scssVar.greyDark, marginTop: 15 }}>
                <span style={{ color: scssVar.primary, fontWeight: 600 }}>width </span>
                et
                <span style={{ color: scssVar.primary, fontWeight: 600 }}> height </span>
                correspondent aux dimensions de l'iframe. Vous pouvez les configurer à votre guise.
                <br />
                <span style={{ color: scssVar.primary, fontWeight: 600 }}>width </span>
                correspond à la largeur de l'iframe, nous vous conseillons <span style={{ color: scssVar.success }}>100%</span>, pour une adaptation
                à toutes les largeurs d'écrans possibles.
                <br />
                <span style={{ color: scssVar.primary, fontWeight: 600 }}>height </span>
                correspond à la hauteur de l'iframe, nous vous conseillons <span style={{ color: scssVar.success }}>600 pixels</span>.
            </p>
        </Fragment>
    )
}

export default Code