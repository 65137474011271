import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import InputSelect from "../../../components/InputSelect"
import useSaveTracfin from "../hooks/useSaveTracfin"
import { selectTracfinEditor, tracfinActions } from "../tracfinSlice"

const Quote = () => {
    const dispatch = useDispatch()
    const { clearTimeout, saveTracfin } = useSaveTracfin()
    const editor = useSelector(selectTracfinEditor)

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(tracfinActions.setField({ name, value }))
        saveTracfin(0)
    }

    return (
        <div style={{ color: scssVar.danger, display: "flex", alignItems: "center" }}>
            <p style={{ marginRight: 3, fontSize: 13, lineHeight: "15px" }}>Il est utile d'insérer une cotation du risque de 0 à 5 :</p>
            <InputSelect 
                value={editor.tf_cotation_risque || ""}
                name="tf_cotation_risque"
                onChange={handleChange}
                style={{ width: 30, textAlign: "center", fontWeight: 600 }}
            >
                <option value="" />
                {[...Array(6).keys()].map((d, i) =>
                    <option key={i}>{d}</option>
                )}
            </InputSelect>
            <p style={{ marginLeft: 3, fontSize: 13, lineHeight: "15px" }}>/ 5</p>
        </div>
    )
}

export default Quote