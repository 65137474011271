import { Box } from "@mui/material"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import LoadingMask from "../components/LoadingMask"
import ButtonDownloadSynthesis from "./components/ButtonDownloadSynthesis"
import HeaderPreview from "./components/HeaderPreview"
import Page01 from "./pages/Page01"
import Page02 from "./pages/Page02"
import Page03 from "./pages/Page03"
import Page04 from "./pages/Page04"
import Page05 from "./pages/Page05"
import Page06 from "./pages/Page06"
import Page07 from "./pages/Page07"
import Page08 from "./pages/Page08"
import Page09 from "./pages/Page09"
import Page10 from "./pages/Page10"

const SynthesisPdf = () => {
    const navigate = useNavigate()
    const noHousingId = useSelector(state => state.estimate.housing.id) === null
    const estimationName = useSelector(state => state.estimate.estimation.name)
    const [loading, setLoading] = useState(false)
    const [scale, setScale] = useState(0.4)
    const title = estimationName + ".pdf"
    const currentScale = loading 
        ? 1.0 
        : scale
        // : 0.665 // --> Lecteur PDF Google : 77%

    useEffect(() => {
        const screenSize = window.innerWidth
        
        if (screenSize >= 1000) {
            setScale(1)
        }

        if (screenSize < 1000 && screenSize > 600) {
            setScale(0.6)
        }

        return () => {
            setScale(0.4)
            setLoading(false)
        }
    }, [])

    const handleDownload = () => {
        const pdf = new jsPDF("p", "mm", "a4", true)

        async function generatePage(html, addPage) {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true // Pour la map leaflet
            })
            .then(canvas => {
                const img = canvas.toDataURL("img/png", 1.0)
                const imgWidth = 210
                const imgHeight = canvas.height * imgWidth / canvas.width + 3
    
                pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST")
    
                if (addPage) {
                    pdf.addPage()
                }
            })
        }

        setLoading(true)

        setTimeout(async () => {
            const page1 = document.getElementById("synthesis_p1")
            const page2 = document.getElementById("synthesis_p2")
            const page3 = document.getElementById("synthesis_p3")
            const page4 = document.getElementById("synthesis_p4")
            const page5 = document.getElementById("synthesis_p5")
            const page6 = document.getElementById("synthesis_p6")
            const page7 = document.getElementById("synthesis_p7")
            const page8 = document.getElementById("synthesis_p8")
            const page9 = document.getElementById("synthesis_p9")
            const page10 = document.getElementById("synthesis_p10")
    
            await generatePage(page1, true)
            await generatePage(page2, true)
            await generatePage(page3, true)
            await generatePage(page4, true)
            await generatePage(page5, true)
            await generatePage(page6, true)
            await generatePage(page7, true)
            await generatePage(page8, true)
            await generatePage(page9, true)
            await generatePage(page10, false)

            setLoading(false)
    
            return pdf.save(title)
        }, [1500])
    }

    const handleGoBack = () => {
        navigate("/estimer/synthese")
    }

    if (noHousingId) {
        return <Navigate to="/" />
    }

    return (
        <Fragment>
            <LoadingMask loading={loading} />
            <HeaderPreview
                onGoBack={handleGoBack}
                loading={loading}
                scale={scale}
                setScale={setScale}
            />
            <ButtonDownloadSynthesis
                onClick={handleDownload}
                loading={loading}
            />
            <Box sx={{
                pt: "80px",
                pb: { xs: "80px", sm: "35px", lg: "60px" },
                margin: "0 auto",
                width: `calc(925px * ${currentScale})`, // 925px = width of <Wrapper />
                transition: "width .2s ease"
            }}>
                <Page01 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page02 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page03 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page04 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page05 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page06 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page07 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page08 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page09 scale={currentScale} />
                <Box sx={{ m: 2 }} />
                <Page10 scale={currentScale} />
            </Box>
        </Fragment>
    )
}

export default SynthesisPdf