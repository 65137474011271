import { CircularProgress, IconButton } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../../../app/scssVar"
import TooltipStyled from "../../../../../common/components/TooltipStyled"
import { api } from "../../../../../common/setupApi"
import { addAlert } from "../../../../alerts/alertsSlice"
import { filterEstimations } from "../../../../estimations/actions"

const Duplicate = () => {
    const dispatch = useDispatch()
    const estimation = useSelector(state => state.estimate.estimation)
    const working = useSelector(state => state.estimate.working)
    const loadingEstimations = useSelector(state => state.estimate.estimations.loading)
    const [state, setState] = useState({
        loading: false
    })

    const handleDuplicate = async () => {
        dispatch({ type: "estimate/set_working", payload: true })

        setState({ ...state, loading: true })

        return api
            .post("v1/housing/estimation/duplicate", estimation)
            .then(res => {
                dispatch({ type: "estimate/set_estimation", payload: res.data })
                dispatch({ type: "estimate/duplicate_estimation", payload: res.data })
                dispatch({ type: "estimations/add_estimation", payload: res.data })
                dispatch({ type: "estimate/set_working", payload: false })
                dispatch(filterEstimations())
                setState({ ...state, loading: false })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                dispatch({ type: "estimate/set_working", payload: false })
                setState({ ...state, loading: false })
            })
    }

    if (state.loading) {
        return (
            <CircularProgress
                size={30} 
                sx={{ 
                    color: scssVar.primary,
                    mr: 2 
                }} 
            />
        )
    }

    return (
        <TooltipStyled arrow title="Dupliquer">
            <span style={{ marginRight: 16 }}>
                <IconButton 
                    onClick={handleDuplicate}
                    disabled={working || loadingEstimations}
                    sx={{
                        bgcolor: scssVar.greyLighter,
                        color: scssVar.primary,
                        "&:hover": {
                            bgcolor: scssVar.greyLight,
                        }
                    }}
                >
                    <i className="iconoir-copy"></i>
                </IconButton>
            </span>
        </TooltipStyled>
    )
}

export default Duplicate