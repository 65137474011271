import { Typography } from "@mui/material"
import { scssVar } from "../../app/scssVar"
import { styled } from '@mui/system';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

const Button = styled(ButtonUnstyled)`
    position: relative;
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    height: 96px;
    width: 100%;
    border-radius: 8px;
    color: black;
    background-color: ${scssVar.greyLighter};
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        border: 1px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.active} {
        border: 2px solid ${scssVar.grey};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;


const CheckedIcon = () => (
    <svg 
        width="16" 
        height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg" 
        style={{
            position: "absolute",
            top: 2,
            right: 2
        }}
    >
        <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#DEF4E4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4182 3.58172 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58172 12.4182 0 8 0ZM1.11628 8C1.11628 4.19822 4.19822 1.11628 8 1.11628C11.8017 1.11628 14.8837 4.19823 14.8837 8C14.8837 11.8017 11.8017 14.8837 8 14.8837C4.19823 14.8837 1.11628 11.8017 1.11628 8ZM11.6869 6.02022C11.8821 5.82496 11.8821 5.50838 11.6869 5.31311C11.4916 5.11785 11.175 5.11785 10.9798 5.31311L6.66667 9.62623L5.02022 7.97978C4.82496 7.78452 4.50838 7.78452 4.31311 7.97978C4.11785 8.17504 4.11785 8.49162 4.31311 8.68689L6.31311 10.6869C6.50838 10.8821 6.82496 10.8821 7.02022 10.6869L11.6869 6.02022Z" fill="#002029"/>
    </svg>
)

const Icon = ({ icon }) => (
    <i 
        className={icon}
        style={{
            fontSize: 24,
            color: "inherit",
            marginBottom: "10px"
        }} 
    ></i>
)

const Text = ({ text }) => (
    <Typography sx={{
        color: "inherit",
        fontFamily: scssVar.fontFamily,
        fontSize: { xs: "12px", sm: "14px" },
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textAlign: "center"
    }}>
        {text}
    </Typography>
)

const BigCheckbox = ({ 
    onClick,
    name,
    checked,
    value,
    disabled,
    icon, 
    text,
    bg,
    bgChecked,
    color,
    colorChecked
}) => {
    const handleClick = () => {
        onClick(name, !checked, value)
    }

    return (
        <Button
            type="button"
            onClick={handleClick} 
            disabled={disabled}
            sx={{
                bgcolor: 
                    checked
                        ? (bgChecked || scssVar.primaryLight) 
                        : (bg || scssVar.greyLighter),
                color: 
                    checked
                        ? (colorChecked || scssVar.primary) 
                        : (color || scssVar.black)
            }}
        >
            {checked && <CheckedIcon />}
            <Icon icon={icon} />
            <Text text={text} />
        </Button>
    )
}

export default BigCheckbox