import ButtonCheckboxImage from "./ButtonCheckboxImage"
import scssVar from "../../app/App.scss"
import { v4 as uuid } from 'uuid';
import TooltipStyled from "./TooltipStyled"
import { Fragment } from "react"
import { Grid } from "@mui/material";
import Toggle from "./Toggle";
import BigCheckbox from "./BigCheckbox";

const InputPassword = ({
    className,
    isSuccess,
    isDanger,
    autoComplete,
    withVisibilityIcons,
    visible,
    onToggleVisibility,
    ...rest
}) => {
    const inputCL = className || `input ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    if (withVisibilityIcons) {
        const fieldId = `pwd_field_${uuid()}`
        const btnId = `btn_reveal_${uuid()}`

        return (
            <div className="control has-icons-right">
                <input 
                    className={inputCL}
                    type={visible ? "text" : "password"}
                    autoComplete={autoComplete || "off"}
                    id={fieldId}
                    {...rest}
                />
                <span 
                    id={btnId}
                    toggle={`#${fieldId}`}
                    onClick={onToggleVisibility}
                    className="icon is-small is-right" 
                    style={{ 
                        cursor: "pointer", 
                        pointerEvents: "all", 
                        color: "black" 
                    }}
                >
                    <i className={`fa ${visible ? "fa-eye-slash" : "fa-eye"}`}></i>
                </span>
            </div>
        )
    }

    return (
        <input 
            className={inputCL}
            type="password" 
            autoComplete={autoComplete || "off"}
            {...rest}
        />
    )
}

const InputTel = ({
    className,
    isSuccess,
    isDanger,
    autoComplete,
    ...rest
}) => {
    const inputCL = className || `input ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    return (
        <input 
            className={inputCL}
            type="tel" 
            autoComplete={autoComplete || "off"}
            {...rest}
        />
    )
}

const InputEmail = ({
    className,
    isSuccess,
    isDanger,
    autoComplete,
    ...rest
}) => {
    const inputCL = className || `input ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    return (
        <input 
            className={inputCL}
            type="email" 
            autoComplete={autoComplete || "off"}
            {...rest}
        />
    )
}

const InputDate = ({
    className,
    isSuccess,
    isDanger,
    ...rest
}) => {
    const inputCL = className || `input ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    return (
        <input
            type="date"
            className={inputCL}
            {...rest}
        />
    )
}

const InputTextArea = ({
    className,
    isSuccess,
    isDanger,
    ...rest
}) => {
    const inputCL = className || `textarea ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    return (
        <textarea
            className={inputCL}
            autoComplete="off"
            {...rest}
        />
    )
}

const InputText = ({
    className,
    isSuccess,
    isDanger,
    autoComplete,
    variant,
    ...rest
}) => {

    const inputCL = className || `input ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    return (
        <input 
            className={inputCL}
            type="text" 
            autoComplete={autoComplete || "off"}
            {...rest}
        />
    )
}

const InputNumber = ({
    className,
    isSuccess,
    isDanger,
    icon,
    autoComplete,
    ...rest
}) => {

    const inputCL = className || `input ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    if (icon?.position) {
        return (
            <div className={`control has-icons-${icon.position}`}>
                <input 
                    className={inputCL}
                    type="number" 
                    autoComplete={autoComplete || "off"}
                    {...rest}
                />
                <span className={`icon is-small is-${icon.position}`}>
                    <i className={icon.icon} style={{ color: scssVar.greyDark }}></i>
                </span>
            </div>
        )
    }

    return (
        <input 
            className={inputCL}
            type="number" 
            autoComplete={autoComplete || "off"}
            {...rest}
        />
    )
}

const InputSelect = ({
    className,
    options,
    isSuccess,
    isDanger,
    ...rest
}) => {

    const inputCL = className || `select is-fullwidth ${isDanger ? "is-danger" : isSuccess ? "is-success" : ""}`

    return (
        <div className={inputCL}>
            <select {...rest}>
                {options}
            </select>
        </div>
    )
}

const InputToggles = ({
    choices,
    selectedValue,
    name,
    onChange,
    xs,
    sm,
    md,
    lg,
    disabled,
    disallowDeselect,
}) => {
    const handleClick = (newValue) => {
        if (selectedValue === newValue && disallowDeselect) {
            return
        }

        onChange(
            name, 
            selectedValue === newValue ? null : newValue
        )
    }

    return (
        <Grid container spacing={0} sx={{ bgcolor: scssVar.greyLighter, borderRadius: "8px" }}>
            {choices.map((d, i) => 
                <Grid key={i} item xs={xs} sm={sm} md={md} lg={lg} sx={{ padding: "3px" }}>
                    <Toggle
                        onClick={handleClick}
                        selectedValue={selectedValue}
                        disabled={disabled}
                        value={d.value}
                        label={d.label}
                        bg={d.bg}
                        color={d.color}
                        bgChecked={d.bgChecked}
                        colorChecked={d.colorChecked}
                    />
                </Grid>
            )}
        </Grid>
    )
}

const InputBigCheckboxes = ({ 
    choices,
    onClick, 
    xs,
    sm,
    md,
    lg,
    disabled
}) => {
    return (
        <Grid container spacing={2}>
            {choices.filter(d => d.displayed).map((d, i) => 
                <Grid key={i} item xs={xs} sm={sm} md={md} lg={lg}>
                    <BigCheckbox
                        onClick={onClick}
                        name={d.name}
                        checked={d.checked}
                        value={d.value}
                        disabled={disabled}
                        icon={d.icon}
                        text={d.text}
                        bg={d.bg}
                        color={d.color}
                        bgChecked={d.bgChecked}
                        colorChecked={d.colorChecked}
                    />
                </Grid>
            )}
        </Grid>
    )
}

const FieldControl = ({ 
    toolTip,
    tooltipStyle,
    label,
    isSuccess,
    labelClassName,
    fieldClassName,
    type,
    value,
    name,
    placeholder,
    options,
    onChange,
    choices,
    selectedValue,
    xs,
    sm,
    md,
    lg,
    icon,
    disabled,
    required,
    autoComplete,
    minLength,
    maxLength,
    withVisibilityIcons,
    visible,
    onToggleVisibility,
    isDanger,
    disallowDeselect,
    variant,
    id,
}) => {

    const labelCL = labelClassName || `label has-text-weight-normal ${isDanger ? "has-text-danger" : isSuccess ? 'has-text-success' : ""}`

    return (
        <Fragment>
            {label && (
                <label 
                    className={labelCL}
                    style={{
                        fontSize: 14,
                        verticalAlign: toolTip ? "middle" : "none"
                    }}
                >
                    {label}
                    {toolTip && (
                        <TooltipStyled 
                            arrow
                            title={toolTip}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            sx={tooltipStyle}
                        >
                            <i 
                                className="iconoir-question-mark-circle"
                                style={{ 
                                    fontSize: 18,
                                    marginLeft: 3,
                                    color: "inherit",
                                    verticalAlign: "middle",
                                    display: "inline-block",
                                }}
                            ></i>
                        </TooltipStyled>
                    )}
                </label>
            )}
            {type === "password" && (
                <InputPassword
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    minLength={minLength}
                    maxLength={maxLength}
                    withVisibilityIcons={withVisibilityIcons}
                    visible={visible}
                    onToggleVisibility={onToggleVisibility}
                    isDanger={isDanger}
                />
            )}
            {type === "tel" && (
                <InputTel
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    minLength={minLength}
                    maxLength={maxLength}
                    isDanger={isDanger}
                />
            )}
            {type === "email" && (
                <InputEmail
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    minLength={minLength}
                    maxLength={maxLength}
                    isDanger={isDanger}
                />
            )}
            {type === "date" && (
                <InputDate
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    required={required}
                    isDanger={isDanger}
                />
            )}
            {type === "textarea" && (
                <InputTextArea
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    required={required}
                    minLength={minLength}
                    maxLength={maxLength}
                    isDanger={isDanger}
                />
            )}
            {type === "text" && (
                <InputText
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    minLength={minLength}
                    maxLength={maxLength}
                    isDanger={isDanger}
                    variant={variant}
                    id={id}
                />
            )}
            {type === "number" && (
                <InputNumber 
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    icon={icon}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    minLength={minLength}
                    maxLength={maxLength}
                    isDanger={isDanger}
                />
            )}
            {type === "select" && (
                <InputSelect
                    isSuccess={isSuccess}
                    className={fieldClassName}
                    onChange={onChange}
                    value={value}
                    name={name}
                    options={options}
                    disabled={disabled}
                    required={required}
                    isDanger={isDanger}
                />
            )}
            {type === "checkbox" && (
                <InputToggles
                    choices={choices}
                    selectedValue={selectedValue}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    disallowDeselect={disallowDeselect}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                />
            )}
            {type === "checkbox-big" && (
                <InputBigCheckboxes
                    choices={choices}
                    onClick={onChange}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                    disabled={disabled}
                />
            )}
            {type === "checkbox-image" && (
                <ButtonCheckboxImage
                    choices={choices}
                    selectedValue={selectedValue}
                    name={name}
                    onChange={onChange}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                    disabled={disabled}
                />
            )}
        </Fragment>
    )
}

export default FieldControl