import { useDispatch, useSelector } from "react-redux"
import InputSelect from "../../components/InputSelect"
import InputTel from "../../components/InputTel"
import InputText from "../../components/InputText"
import Margin from "../../components/Margin"
import { selectVisitsState, visitsActions } from "../visitsSlice"

const inputHeight = 25
const inputFontSize = 15

const Visitor = ({ id, pageId }) => {
    const dispatch = useDispatch()
    const visitor = useSelector(state => selectVisitsState(state, pageId))[id] || {}

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(visitsActions.setVisitor({ name, value, pageId, visitor: id }))
    }

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <InputSelect
                value={visitor.civilite || ""}
                name="civilite"
                onChange={handleChange}
                style={{
                    width: 90,
                    height: inputHeight,
                    fontSize: inputFontSize
                }}
            >
                <option value="">Titre</option>
                <option value="mme">Madame</option>
                <option value="m">Monsieur</option>
                <option value="ind">Indifférent</option>
            </InputSelect>
            <InputText 
                value={visitor.nom_naissance || ""}
                name="nom_naissance"
                onChange={handleChange}
                placeholder="Nom" 
                style={{
                    width: "calc(50% - 50px)",
                    height: inputHeight,
                    fontSize: inputFontSize
                }}
            />
            <InputText 
                value={visitor.prenom || ""}
                name="prenom"
                onChange={handleChange}
                placeholder="Prénom" 
                style={{
                    width: "calc(50% - 50px)",
                    height: inputHeight,
                    fontSize: inputFontSize
                }}
            />
        </div>
    )
}

const Visitors = ({ pageId }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => selectVisitsState(state, pageId))

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(visitsActions.setField({ pageId, name, value }))
    }

    return (
        <div>
            <Visitor id="visiteur1" pageId={pageId} />
            <Margin m={4} />
            <Visitor id="visiteur2" pageId={pageId} />
            <Margin m={4} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3, fontSize: 16 }}>Demeurant :</p>
                <InputText 
                    value={state.adresse_domicile}
                    name="adresse_domicile"
                    onChange={handleChange}
                    placeholder="Rue" 
                    style={{ 
                        marginRight: 3, 
                        flexGrow: 1,
                        height: inputHeight,
                        fontSize: inputFontSize
                    }}
                />
                <p style={{ marginRight: 3, fontSize: 16 }}>CP :</p>
                <InputText 
                    value={state.cp_domicile}
                    name="cp_domicile"
                    onChange={handleChange}
                    style={{ 
                        width: 75,
                        height: inputHeight,
                        fontSize: inputFontSize
                    }}
                />
            </div>
            <Margin m={4} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3, fontSize: 16 }}>Ville :</p>
                <InputText 
                    value={state.ville_domicile}
                    name="ville_domicile"
                    onChange={handleChange}
                    placeholder="Ville"
                    style={{ 
                        marginRight: 3, 
                        flexGrow: 1,
                        height: inputHeight,
                        fontSize: inputFontSize
                    }}
                />
                <p style={{ marginRight: 3, fontSize: 16 }}>Tel :</p>
                <InputTel
                    value={state.tel}
                    name="tel"
                    onChange={handleChange}
                    style={{ 
                        width: 105,
                        height: inputHeight,
                        fontSize: inputFontSize
                    }}
                />
            </div>
            <Margin m={4} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 3, fontSize: 16 }}>Email :</p>
                <InputText 
                    value={state.email}
                    name="email"
                    onChange={handleChange}
                    style={{ 
                        flexGrow: 1,
                        height: inputHeight,
                        fontSize: inputFontSize
                    }}
                />
            </div>
        </div>
    )
}

export default Visitors