import { Box, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import { scssVar } from "../../app/scssVar"
import { addAlert } from "../alerts/alertsSlice"
import { logoutUser } from "../auth/actions"
import { api } from "../../common/setupApi"
import { appName } from "../../common/utils"
import Button from "../../common/components/Button"
import Logo from "../../common/components/Logo"
import Title from "../pdfReader/components/Title"
import Link from "../pdfReader/components/Link"
import Reader from "../pdfReader/components/Reader"
import Zoom from "../pdfReader/components/Zoom"
import BottomBar from "../pdfReader/components/BottomBar"
import usePdfDoc from "../pdfReader/hooks/usePdfDoc"
import usePdfState from "../pdfReader/hooks/usePdfState"

const initialState = {
    loading: false,
    error: null,
}

const CguChanged = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const firstPdf = usePdfState()
    const secondPdf = usePdfState()
    const getPdfDoc = usePdfDoc()

    const handleClose = () => {
        dispatch(logoutUser())
    }

    const handleSubmit = async () => {
        setState({ ...state, loading: true, error: null })

        // return setTimeout(() => {
        //     setState({ ...state, loading: false })
        //     dispatch({ type: "auth/user_accepts_cgu" })
        //     dispatch(addAlert("Vous avez accepté les conditions générales d'utilisations", "success"))
        // }, [1000])

        const payload = {
            cgu: true
        }

        return api
            .patch("v1/profile/me/", payload)
            .then(res => {
                console.log(res.data)
                setState({ ...state, loading: false })
                dispatch({ type: "auth/user_accepts_cgu" })
                dispatch(addAlert("Vous avez accepté les conditions générales d'utilisations", "success"))
            })
            .catch(err => {
                console.log(err.response)
                setState({ ...state, loading: false, error: err.response })
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
            })
    }

    return (
        <Fragment>
            <Box sx={{
                p: { xs: 0, sm: "25px 0" },
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minHeight: "100%",
                bgcolor: "white",
            }}>
                <Box sx={{
                    flexGrow: 1,
                    width: "100%",
                    borderRadius: { xs: 0, sm: "20px" },
                    bgcolor: "white",
                }}>
                    <Box sx={{
                        maxWidth: "1341px",
                        margin: "0 auto",
                        p: {
                            xs: "0 20px 0",
                            sm: "0 35px 0",
                            lg: "0 60px 0"
                        }
                    }}>
                        <Logo sx={{ 
                            wrapper: { 
                                mb: 3,
                                pt: { xs: 1.8, sm: 5 },
                            } 
                        }} />
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: { xs: 18, sm: 24 },
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                        }}>
                            Les termes et conditions générales d'{appName} ont changé.
                        </Typography>
                        <Typography sx={{
                            fontFamily: scssVar.fontFamily,
                            color: "black",
                            mb: 1
                        }}>
                            Pour continuer de bénéficier de l'ensemble des services d'{appName}, vous devez les accepter.
                        </Typography>
                        <Title>{getPdfDoc("cgu").title}</Title>
                        <Link href={getPdfDoc("cgu").src} />
                    </Box>
                    <Box sx={{ m: 1 }} />
                    <Reader
                        onDocumentLoadSuccess={firstPdf.handleDocumentLoadSuccess}
                        onPageLoadSuccess={firstPdf.handlePageLoadSuccess}
                        src={getPdfDoc("cgu").src}
                        loadingPage={firstPdf.pdfState.loadingPage}
                        numberOfPages={firstPdf.pdfState.numPages}
                        scale={firstPdf.pdfState.scale}
                    />
                    <Box sx={{ m: 3 }} />
                    <Box sx={{
                        maxWidth: "1341px",
                        margin: "0 auto",
                        p: {
                            xs: "0 20px 0",
                            sm: "0 35px 0",
                            lg: "0 60px 0"
                        }
                    }}>
                        <Title>{getPdfDoc("cgv").title}</Title>
                        <Link href={getPdfDoc("cgv").src} />
                    </Box>
                    <Box sx={{ m: 1 }} />
                    <Reader
                        onDocumentLoadSuccess={secondPdf.handleDocumentLoadSuccess}
                        onPageLoadSuccess={secondPdf.handlePageLoadSuccess}
                        src={getPdfDoc("cgv").src}
                        loadingPage={secondPdf.pdfState.loadingPage}
                        numberOfPages={secondPdf.pdfState.numPages}
                        scale={firstPdf.pdfState.scale}
                    />
                    <Box sx={{ m: 22 }} />
                </Box>
                <Box sx={{ height: scssVar.sab }} />
            </Box>
            <Zoom
                sx={{
                    wrapper: {
                        position: "fixed"
                    }
                }}
                onZoomIn={firstPdf.handleZoomIn}
                onZoomOut={firstPdf.handleZoomOut}
                scale={firstPdf.pdfState.scale}
                loadingDoc={firstPdf.pdfState.loadingDoc || secondPdf.pdfState.loadingDoc}
                loadingPage={firstPdf.pdfState.loadingPage || secondPdf.pdfState.loadingPage}
            />
            <BottomBar sx={{
                wrapper: {
                    position: "fixed"
                }
            }}>
                <Button
                    onClick={handleClose}
                    variant="light"
                    sx={{
                        width: 150,
                        mr: "10px"
                    }}
                >
                    Fermer
                </Button>
                <Button
                    loading={state.loading}
                    disabled={state.loading}
                    onClick={handleSubmit}
                    sx={{
                        width: 150
                    }}
                >
                    J'accepte
                </Button>
            </BottomBar>
        </Fragment>
    )
}

export default CguChanged