import { Box, CircularProgress } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import Doughnut from "../../../../common/components/dashboard/doughnut/Doughnut"
import { useSelector } from "react-redux"
import { selectApiKpis, selectApiKpisHouseType, selectApiKpisHouseTypeLeads } from "../../apiOfferSlice"
import DoughnutLegend from "../../../../common/components/dashboard/doughnut/DoughnutLegend"
import { Fragment } from "react"

const HouseType = () => {
    const all = useSelector(selectApiKpisHouseType)
    const leads = useSelector(selectApiKpisHouseTypeLeads)
    const { isLoading } = useSelector(selectApiKpis)

    const data = [
        {
            name: "Maisons", 
            value: all.houses, 
            percent: Math.round(all.houses / (all.houses + all.flats) * 100), 
            color: scssVar.greenDark
        }, 
        {
            name: "Appartements", 
            value: all.flats, 
            percent: Math.round(all.flats / (all.houses + all.flats) * 100), 
            color: scssVar.yellowDark
        }
    ]

    const dataLeads = [
        {
            name: "Maisons", 
            value: leads.houses, 
            percent: Math.round(leads.houses / (leads.houses + leads.flats) * 100), 
            color: scssVar.greenDark
        }, 
        {
            name: "Appartements", 
            value: leads.flats, 
            percent: Math.round(leads.flats / (leads.houses + leads.flats) * 100), 
            color: scssVar.yellowDark
        }
    ]

    return (
        <Box sx={{ bgcolor: scssVar.greyLighter, p: 2, borderRadius: "12px" }}>
            <p style={{ fontWeight: 600, marginBottom: 17 }}>
                Types de bien
            </p>
            {isLoading ? (
                <CircularProgress size={20} sx={{ color: scssVar.primary }} />
            ):(
                <Fragment>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}>
                        <DoughnutLegend title={`Toutes les requêtes (${all.houses + all.flats})`} data={data} />
                        <Doughnut data={data} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <DoughnutLegend title={`Dont les leads (${leads.houses + leads.flats})`} data={dataLeads} />
                        <Doughnut data={dataLeads} />
                    </Box>
                </Fragment>
            )}
        </Box>
    )
}

export default HouseType