import { useDispatch, useSelector } from "react-redux"
import { buyOfferActions, selectBuyOfferEditor } from "../buyOfferSlice"
import InputSelect from "../../../components/InputSelect"

const OfferType = () => {
    const dispatch = useDispatch()
    const offerType = useSelector(selectBuyOfferEditor).offre_type

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(buyOfferActions.setField({ name, value }))
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <p style={{
                fontSize: 20,
                fontWeight: 600,
                marginRight: 10
            }}>
                <u>OFFRE D’ACHAT</u>
            </p>
            <InputSelect
                value={offerType}
                onChange={handleChange}
                name="offre_type"
                style={{
                    fontSize: 17,
                    height: 25,
                    flexGrow: 1
                }}
            >
                <option value="prix">Au prix du mandat</option>
                <option value="nego">Valant avenant - Avec négociation du prix</option>
            </InputSelect>
        </div>
    )
}

export default OfferType