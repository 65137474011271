import { Box, Divider } from "@mui/material"
import { scssVar } from "../../../../../app/scssVar"
import CoverPhoto from "./components/CoverPhoto"
import GalleryPhotos from "./components/GalleryPhotos";

const TabPhotos = () => {
    return (
        <Box sx={{
            bgcolor: { xs: "transparent", sm: scssVar.greyLighter },
            pl: { xs: 0, sm: 3 },
            pr: { xs: 0, sm: 3 },
            borderRadius: "10px"
        }}>
            <Box sx={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 700,
                pt: { xs: 0, sm: 4 },
                pb: { xs: 0, sm: 4 }
            }}>
                <CoverPhoto />
                <Box sx={{ m: 5 }} />
                <Divider sx={{ borderColor: "#efecec" }} />
                <Box sx={{ m: 5 }} />
                <GalleryPhotos />
            </Box>
        </Box>
    )
}

export default TabPhotos