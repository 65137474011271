import { NavLink } from "react-router-dom"
import Logo from "../Logo"

const LinkLogo = () => {
    return (
        <NavLink to="/" style={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            marginRight: "20px"
        }}>
            <Logo />
        </NavLink>
    )
}

export default LinkLogo