import { scssVar } from "../../../../app/scssVar"
import useStep from "../../hooks/useStep"

const StepsProgress = ({ 
    width, 
    step, 
    isCostDisplay, 
    noValue 
}) => {
    const getStep = useStep()
    const { progress } = getStep(step)

    if (isCostDisplay) {
        return (
            <div style={{ 
                width: 200,
                height: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{
                    width: "49%",
                    height: "100%",
                    borderRadius: "4px",
                    backgroundColor: scssVar.primary
                }}></div>
                <div style={{
                    width: "49%",
                    height: "100%",
                    borderRadius: "4px",
                    backgroundColor: step === "Step12" ? scssVar.primary : scssVar.grey
                }}></div>
            </div>
        )
    }

    return (
        <div style={{ 
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 200
        }}>
            <progress
                className="progress is-small is-primary"
                style={{ 
                    height: 6,
                    width: width || 160,
                    marginBottom: 0, 
                }}
                value={progress}
                max="100"
            ></progress>
            {!noValue && (
                <span className="has-text-primary" style={{ fontSize: 15 }}>
                    {progress}%
                </span>
            )}
        </div>
    )
}

export default StepsProgress