import { getLocalParam } from "../../common/utils"

const initialState = {
    loading: false,
    error: null,
    accessToken: getLocalParam("accessToken"),
    refreshToken: getLocalParam("refreshToken"),
    logoutLoading: false,
    user: {
        loading: true, // true par défaut, permet d'afficher un chargement au lancement de l'app
        error: null,
        firstName: null,
        lastName: null,
        email: null,
        id: null,
        isTester: null,
        invoicingDay: null,
        isAdmin: false,
        orgId: null,
        orgName: "",
        team: "",
        cgu: false,
        subError: null,
        canceledOn: null,
        newAppVersion: null,
        firstVisit: false,
        fetched: false,
        updateLoading: false,
        updateError: null,
        updatePasswordLoading: false,
        updatePasswordError: null,
        updateOrgNameLoading: false,
        updateOrgNameError: null,
        updateInvoicingDayLoading: false,
        updateInvoicingDayError: null,
        color: "rgb(92, 162, 82)",
        gender: null,
        status: null,
        phone: null,
        rsac: null
    },
}

// export const authSlice = createSlice({
//     name: "auth",
//     initialState,
//     reducers: {
//         user_finish_first_visit: (state) => {
//             state.user.firstVisit = false
//         }
//     }
// })

const auth = (state = initialState, action) => {
    switch (action.type) {

        case "auth/user_finish_first_visit":
            return {
                ...state,
                user: {
                    ...state.user,
                    firstVisit: false
                }
            }

        case "auth/user_accepts_cgu":
            return {
                ...state,
                user: {
                    ...state.user,
                    cgu: true
                }
            }

        case "auth/set_invoicing_day_loading":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateInvoicingDayLoading: true,
                    updateInvoicingDayError: null
                }
            }

        case "auth/set_invoicing_day_success":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateInvoicingDayLoading: false,
                    invoicingDay: action.payload
                }
            }

        case "auth/set_invoicing_day_failure":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateInvoicingDayLoading: false,
                    updateInvoicingDayError: action.payload
                }
            }

        case "auth/set_org_name_loading":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateOrgNameLoading: true,
                    updateOrgNameError: null
                }
            }

        case "auth/set_org_name_success":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateOrgNameLoading: false,
                    orgName: action.payload
                }
            }

        case "auth/set_org_name_failure":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateOrgNameLoading: false,
                    updateOrgNameError: action.payload
                }
            }

        case "auth/login_loading":
            return {
                ...state,
                loading: true,
                error: null
            }

        case "auth/login_success":
            return {
                ...state,
                loading: false,
                accessToken: action.payload.access,
                refreshToken: action.payload.refresh,
            }

        case "auth/login_failure":
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case "auth/get_user_infos_loading":
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: true,
                    error: null,
                    fetched: true
                }
            }

        case "auth/get_user_infos_success":
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                    loading: false,

                    // ? pourquoi avoir fait ça
                    firstName: action.payload.first_name,
                    lastName: action.payload.last_name,
                    isTester: action.payload.is_tester,
                    invoicingDay: action.payload.invoicing_day,
                    isAdmin: action.payload.is_admin,
                    orgId: action.payload.org_id,
                    orgName: action.payload.org_name,
                    subError: action.payload.sub_error,
                    canceledOn: action.payload.canceled_on,
                    newAppVersion: action.payload.app_version,
                    firstVisit: action.payload.display_onboarding,
                }
            }

        case "auth/get_user_infos_failure": 
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: false,
                    error: action.payload,
                }
            }

        case "auth/refresh_token_success":
            return {
                ...state,
                accessToken: action.payload.access,
                refreshToken: action.payload.refresh,
            }

        case "auth/update_profile_loading":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateLoading: true,
                    updateError: null
                }
            }

        case "auth/update_profile_success":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateLoading: false,
                    firstName: action.payload.first_name,
                    lastName: action.payload.last_name,
                    email: action.payload.email,
                    gender: action.payload.gender,
                    status: action.payload.status,
                    phone: action.payload.phone,
                    rsac: action.payload.rsac
                }
            }

        case "auth/update_profile_failure":
            return {
                ...state,
                user: {
                    ...state.user,
                    updateLoading: false,
                    updateError: action.payload
                }
            }

        case "auth/update_password_loading":
            return {
                ...state,
                user: {
                    ...state.user,
                    updatePasswordLoading: true,
                    updatePasswordError: null
                }
            }

        case "auth/update_password_success":
            return {
                ...state,
                user: {
                    ...state.user,
                    updatePasswordLoading: false,
                }
            }

        case "auth/update_password_failure":
            return {
                ...state,
                user: {
                    ...state.user,
                    updatePasswordLoading: false,
                    updatePasswordError: action.payload
                }
            }

        case "auth/logout_loading":
            return {
                ...state,
                logoutLoading: true
            }

        case "auth/logout": 
            return {
                ...initialState,
                accessToken: null,
                refreshToken: null,
            }

        default: return state
    }
}

export default auth