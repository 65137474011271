import { Box } from "@mui/material"
import loaderGif from "../assets/loader.gif"

const LoaderGif = ({
    containerStyle,
    imageStyle
}) => {
    return (
        <Box sx={Object.assign({}, {
            width: { xs: "50px", sm: "100px" },
            margin: "0 auto"
        }, containerStyle)}>
            <img
                src={loaderGif}
                alt="Chargement..."
                style={Object.assign({}, {
                    width: "100%"
                }, imageStyle)}
            />
        </Box>
    )
}

export default LoaderGif