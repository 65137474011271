import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const Result3Buttons = ({
    onClickNewEstimation, 
    onClickGoSynthesis,
    onClickPrev,
}) => {
    const working = useSelector(state => state.estimate.working)

    return (
        <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            alignItems: "center",
            bgcolor: "white",
            borderRadius: { xs: "12px", lg: "50px"},
            boxShadow: scssVar.shadow2,
            WebkitBoxShadow: scssVar.shadow2,
            p: "8px",
            mb: "20px",
            width: { 
                xs: "100%", 
                sm: "100%", 
                md: "780px", 
                lg: "890px" 
            },
        }}>
            <Box sx={{
                mr: { xs: 0, lg: "10px" },
                width: { xs: "100%", lg: "20%" },
                mb: { xs: "10px", lg: 0 }
            }}>
                <Button
                    variant="light"
                    onClick={onClickPrev}
                    disabled={working}
                    sx={{
                        width: "100%",
                        pl: 0,
                        pr: 0,
                        fontSize: { xs: 15, sm: 16 }
                    }}
                >
                    <i 
                        className="iconoir-arrow-left"
                        style={{ 
                            fontSize: 24, 
                            color: scssVar.primary 
                        }}
                    ></i>
                    Précédent
                </Button>
            </Box>
            <Box sx={{
                mr: { xs: 0, lg: "10px" },
                width: { xs: "100%", lg: "40%" },
                mb: { xs: "10px", lg: 0 }
            }}>
                <Button
                    variant="light"
                    onClick={onClickNewEstimation}
                    disabled={working}
                    sx={{
                        width: "100%",
                        pl: 0,
                        pr: 0,
                        fontSize: { xs: 15, sm: 16 }
                    }}
                >
                    Faire une nouvelle évaluation
                </Button>
            </Box>
            <Box sx={{
                width: { xs: "100%", lg: "40%" }
            }}>
                <Button
                    onClick={onClickGoSynthesis}
                    disabled={working}
                    sx={{
                        width: "100%",
                        pl: 0,
                        pr: 0,
                        fontSize: { xs: 15, sm: 16 }
                    }}
                >
                    Afficher le détail de l’évaluation
                    <i 
                        className="iconoir-arrow-right"
                        style={{ 
                            fontSize: 24,
                            color: "white"
                        }}
                    ></i>
                </Button>
            </Box>
        </Box>
    )
}

export default Result3Buttons