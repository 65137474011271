import { Box, Typography } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"

const TopBar = ({ 
    street, 
    city, 
    code,
    orgLogo,
    orgName,
    housingType
}) => {
    return (
        <Box sx={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            bgcolor: "white",
            boxShadow: scssVar.shadow2,
            zIndex: 1301
        }}>
            <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                height: 60, 
                width: "90%",
                margin: "0 auto"
            }}>
                <Typography sx={{
                    fontFamily: scssVar.fontFamily,
                    color: "black",
                    fontSize: { xs: 11, sm: 15 },
                    flexGrow: 1,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    lineHeight: { xs: "14px", sm: "18px" }
                }}>
                    <b>{housingType}</b> {street},
                    <br />{code} {city}
                </Typography>
                {orgLogo && (
                    <Box sx={{
                        width: { xs: 65, sm: 90 },
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                    }}>
                        <img src={orgLogo} alt={orgName} style={{ width: "100%" }} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default TopBar