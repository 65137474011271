import { timeMonday } from "d3";
import { MONTHS_SHORT } from "../../utils"

export const getDateToMonth = (date, params) => {
    const formatDate = new Date(date)
    const month = MONTHS_SHORT[formatDate.getMonth()]
    const year = params?.withYear ? " " + formatDate.getFullYear() : ""
    return month + year
}

export const getDateToWeek = (date, params) => {
    const formatDate = new Date(date)
    const start = formatDate.getDate()
    const a = new Date(formatDate.setDate(formatDate.getDate() + 7))
    const end = a.getDate() + " " + MONTHS_SHORT[a.getMonth()]
    return `${start}-${end[0] === "0" ? end.substring(1) : end}` + (params?.withYear ? " " + formatDate.getFullYear() : "")
}

export function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const getDateToString = (date, params) => {
    const theDate = new Date(date)
    const day = theDate.getDate()
    const month = MONTHS_SHORT[theDate.getMonth()]
    const year = theDate.getFullYear()
    return day + " " + month + (params?.withYear ? " " + year : "")
}

const getMondayOfTheWeek = (y, w) => {
    return timeMonday(new Date(y, 0, 1 + w * 7))
}

export const getWeekToDateString = (week) => {
    const theDate = getMondayOfTheWeek(week.substring(0,4), week.substring(5,8));
    const yyyy = theDate.getFullYear();
    const mm = String(theDate.getMonth()+1).padStart(2, '0');
    const dd = String(theDate.getDate()).padStart(2, '0');
    return yyyy + "-" + mm + "-" + dd;
}

export const getMonthToDateString = (month) => {
    const theDate = new Date(month.substring(0,4), month.substring(5,8), 1)
    const yyyy = theDate.getFullYear();
    const mm = String(theDate.getMonth()).padStart(2, '0');
    const dd = String(theDate.getDate()).padStart(2, '0');
    return yyyy + "-" + mm + "-" + dd;
}