import { useSelector } from "react-redux"
import { selectApiLeads, selectApiLeadsMissingAssignments } from "../../apiOfferSlice"
import { Box } from "@mui/material"
import { scssVar } from "../../../../app/scssVar"
import { selectTeams } from "../../../account/selectors"

const TabPanelLeadsAlertMessage = () => {
    const missingAssignments = useSelector(selectApiLeadsMissingAssignments)
    const leads = useSelector(selectApiLeads)
    const teams = useSelector(selectTeams)

    if (
        missingAssignments > 0
        && !leads.isLoading 
        && !leads.error 
        && !teams.isLoading 
        && !teams.error
    ) {
        return (
            <Box sx={{ p: 1, bgcolor: scssVar.primary, borderRadius: "8px", color: "white", mb: 3, fontWeight: 500 }}>
                {missingAssignments === 1 
                    ? "Un lead est en attente d'attribution" 
                    : `${missingAssignments} leads sont en attente d'attribution`
                }
            </Box>
        )
    }
    
    return null
}

export default TabPanelLeadsAlertMessage