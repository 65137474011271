import { Box, Grid } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../common/components/Button"
import FieldControl from "../../../common/components/FieldControl"
import useAuth from "../../auth/hooks/useAuth"
import { createFreeTrial, registerOrgActions, selectRegisterOrgErrFields, selectRegisterOrgForm } from "../registerOrgSlice"
import CguCheckbox from "./CguCheckbox"
import ErrorHandler from "./ErrorHandler"

const Organization = () => {
    const dispatch = useDispatch()
    const organization = useSelector(selectRegisterOrgForm).org_name
    const organizationError = useSelector(selectRegisterOrgErrFields).org_name
    const organizationEmpty = !organization.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="text"
                label="Organisation"
                placeholder="Organisation"
                onChange={handleChange}
                value={organization}
                name="org_name"
                isSuccess={!organizationEmpty}
                isDanger={organizationError.length}
                required
                maxLength={100}
            />
            <ErrorHandler errors={organizationError} />
        </Box>
    )
}

const FirstName = () => {
    const dispatch = useDispatch()
    const firstName = useSelector(selectRegisterOrgForm).first_name
    const firstNameError = useSelector(selectRegisterOrgErrFields).first_name
    const firstNameEmpty = !firstName.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="text"
                label="Prénom"
                placeholder="Prénom"
                onChange={handleChange}
                value={firstName}
                name="first_name"
                isSuccess={!firstNameEmpty}
                isDanger={firstNameError.length}
                required
                autoComplete="given-name"
                maxLength={25}
            />
            <ErrorHandler errors={firstNameError} />
        </Box>
    )
}

const LastName = () => {
    const dispatch = useDispatch()
    const lastName = useSelector(selectRegisterOrgForm).last_name
    const lastNameError = useSelector(selectRegisterOrgErrFields).last_name
    const lastNameEmpty = !lastName.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="text"
                label="Nom"
                placeholder="Nom"
                onChange={handleChange}
                value={lastName}
                name="last_name"
                isSuccess={!lastNameEmpty}
                isDanger={lastNameError.length}
                required
                autoComplete="family-name"
                maxLength={25}
            />
            <ErrorHandler errors={lastNameError} />
        </Box>
    )
}

const Email = () => {
    const dispatch = useDispatch()
    const email = useSelector(selectRegisterOrgForm).email
    const emailError = useSelector(selectRegisterOrgErrFields).email
    const emailEmpty = !email.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="email"
                label="Adresse email"
                placeholder="Adresse email"
                onChange={handleChange}
                value={email}
                name="email"
                isSuccess={!emailEmpty}
                isDanger={emailError.length}
                required
                autoComplete="email"
                maxLength={50}
            />
            <ErrorHandler errors={emailError} />
        </Box>
    )
}

const Phone = () => {
    const dispatch = useDispatch()
    const phone = useSelector(selectRegisterOrgForm).phone
    const phoneError = useSelector(selectRegisterOrgErrFields).phone
    const phoneEmpty = !phone.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="tel"
                label="Téléphone"
                placeholder="Téléphone"
                onChange={handleChange}
                value={phone}
                name="phone"
                isSuccess={!phoneEmpty}
                isDanger={phoneError.length}
                autoComplete="off"
                maxLength={20}
            />
            <ErrorHandler errors={phoneError} />
        </Box>
    )
}

const Password1 = () => {
    const dispatch = useDispatch()
    const pwd1 = useSelector(selectRegisterOrgForm).pwd1
    const pwd1Error = useSelector(selectRegisterOrgErrFields).pwd1
    const pwdMatchError = useSelector(selectRegisterOrgErrFields).pwd_match
    const pwdMatchError2 = useSelector(selectRegisterOrgErrFields).pwdMatch
    const [visible, setVisible] = useState(false)
    const pwd1Empty = !pwd1.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="password"
                label="Mot de passe"
                placeholder="Mot de passe"
                onChange={handleChange}
                value={pwd1}
                name="pwd1"
                isSuccess={!pwd1Empty}
                isDanger={pwd1Error.length || pwdMatchError.length || pwdMatchError2.length}
                required
                autoComplete="new-password"
                maxLength={25}
                minLength={8}
                withVisibilityIcons
                visible={visible}
                onToggleVisibility={() => setVisible(!visible)}
            />
            <ErrorHandler errors={pwd1Error} />
        </Box>
    )
}

const Password2 = () => {
    const dispatch = useDispatch()
    const pwd2 = useSelector(selectRegisterOrgForm).pwd2
    const pwd2Error = useSelector(selectRegisterOrgErrFields).pwd2
    const pwdMatchError = useSelector(selectRegisterOrgErrFields).pwd_match
    const pwdMatchError2 = useSelector(selectRegisterOrgErrFields).pwdMatch
    const [visible, setVisible] = useState(false)
    const pwd2Empty = !pwd2.trim().length

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(registerOrgActions.setField({ name, value }))
    }

    return (
        <Box>
            <FieldControl
                type="password"
                label="Confirmation mot de passe"
                placeholder="Confirmation mot de passe"
                onChange={handleChange}
                value={pwd2}
                name="pwd2"
                isSuccess={!pwd2Empty}
                isDanger={pwd2Error.length || pwdMatchError.length || pwdMatchError2.length}
                required
                autoComplete="new-password"
                maxLength={25}
                minLength={8}
                withVisibilityIcons
                visible={visible}
                onToggleVisibility={() => setVisible(!visible)}
            />
            <ErrorHandler errors={pwd2Error} />
        </Box>
    )
}

const CGU = () => {
    const dispatch = useDispatch()
    const cgu = useSelector(selectRegisterOrgForm).cgu

    const handleChange = (e) => {
        const { name, checked } = e.target
        dispatch(registerOrgActions.setField({ name, value: checked }))
    }

    const handleAcceptCgu = () => {
        dispatch(registerOrgActions.setField({ name: "cgu", value: true }))
    }

    return (
        <CguCheckbox 
            checked={cgu}
            onChange={handleChange}
            onAccept={handleAcceptCgu}
        />
    )
}

// const PwdMatchError = () => {
//     const pwdMatchError = useSelector(selectRegisterOrgErrFields).pwd_match
//     const pwdMatchError2 = useSelector(selectRegisterOrgErrFields).pwdMatch

//     if (pwdMatchError.length || pwdMatchError2.length) {
//         return (
//             <Grid item xs={12}>
//                 <Box>
//                     <ErrorHandler errors={pwdMatchError} />
//                     <ErrorHandler errors={pwdMatchError2} />
//                 </Box>
//             </Grid>
//         )
//     }

//     return null
// }

const SubmitButton = () => {
    const createFreeTrialLoading = useSelector(state => state.registerOrg.freeTrial.loading)
    const authLoading = useAuth().loading

    return (
        <Box sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            mt: { xs: 2, sm: 0 }
        }}>
            <Button
                loading={createFreeTrialLoading || authLoading}
                disabled={createFreeTrialLoading || authLoading}
                sx={{
                    pl: 6,
                    pr: 6
                }}
            >
                S'inscrire
            </Button>
        </Box>
    )
}

// const RegisteredDialog = () => {
//     const dispatch = useDispatch()
//     const navigate = useNavigate()
//     const registered = useSelector(state => state.registerOrg.freeTrial.registered)

//     const handleGoLoginWhenRegistered = () => {
//         dispatch(registerOrgActions.reset())
//         navigate("/login")
//     }

//     return (
//         <Dialog
//             open={registered} 
//             onClose={() => {}} 
//             fullWidth
//         >
//             <DialogTitle sx={{
//                 fontWeight: 700,
//                 fontSize: { xs: 18, sm: 24 },
//                 fontFamily: scssVar.fontFamily,
//                 display: "flex",
//                 alignItems: "center",
//             }}>
//                 Vous êtes inscrit !
//             </DialogTitle>
//             <DialogContent>
//                 <DialogContentText sx={{
//                     fontFamily: scssVar.fontFamily
//                 }}>
//                     Vous pouvez désormais vous connecter
//                 </DialogContentText>
//             </DialogContent>
//             <DialogActions>
//                 <Button
//                     variant="success"
//                     onClick={handleGoLoginWhenRegistered}
//                 >
//                     Se connecter
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     )
// }
 
const OrganizationForm = () => {
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(createFreeTrial())
    }

    return (
        <Fragment>
            <form action="POST" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Organization />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FirstName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LastName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Email />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Phone />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Password1 />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Password2 />
                    </Grid>
                    {/* <PwdMatchError /> */}
                    <Grid item xs={12}>
                        <CGU />
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitButton />
                    </Grid>
                </Grid>
            </form>
            {/* <RegisteredDialog /> */}
        </Fragment>
    )
}

export default OrganizationForm