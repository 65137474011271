import { Box, Grid, Typography } from "@mui/material"
import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import { filterEstimations, getEstimations } from "../actions"

const types_vente = [
    { value: "all", label: "Type de bien" },
    { value: "Appartement", label: "Appartement" },
    { value: "Maison", label: "Maison" },
]

const nombre_pieces_principales = [
    { value: 1, label: "Pièces 1+" },
    { value: 2, label: "Pièces 2+" },
    { value: 3, label: "Pièces 3+" },
    { value: 4, label: "Pièces 4+" },
    { value: 5, label: "Pièces 5+" },
]

const prices_min = [
    { value: 0, label: "Prix" },
    { value: 100000, label: "100 000€ +" },
    { value: 200000, label: "200 000€ +" },
    { value: 300000, label: "300 000€ +" },
    { value: 400000, label: "400 000€ +" },
    { value: 500000, label: "500 000€ +" },
    { value: 600000, label: "600 000€ +" },
    { value: 700000, label: "700 000€ +" },
    { value: 800000, label: "800 000€ +" },
]

const step_choices = [
    { value: "all", label: "Statut" },
    { value: "notfinish", label: "Non terminées" },
    { value: "finish", label: "Terminées" },
]

const sorting_choices = [
    { value: "récentes", label: "+ récentes" },
    { value: "!récentes", label: "- récentes" },
    { value: "chères", label: "Prix décroissants" },
    { value: "!chères", label: "Prix croissants" },
]

const TypeBienFilter = () => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.estimations.estimations.filters)

    const handleChange = e => {
        const { name, value } = e.target

        dispatch({
            type: "estimations/set_filters",
            payload: { name, value }
        })

        dispatch(filterEstimations())
    }

    return (
        <Box 
            className={`select ${filters.typeVente !== "all" && "is-success-primary"}`}
            sx={{ width: "100%" }}
        >
            <select
                value={filters.typeVente}
                onChange={handleChange}
                name="typeVente"
                style={{ width: "100%" }}
            >
                {types_vente.map((d, i) => 
                    <option
                        key={i}
                        value={d.value}
                    >
                        {d.label}
                    </option>
                )}
            </select>
        </Box>
    )
}

const NbPiecesFilter = () => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.estimations.estimations.filters)

    const handleChange = e => {
        const { name, value } = e.target

        dispatch({
            type: "estimations/set_filters",
            payload: { name, value }
        })

        dispatch(filterEstimations())
    }

    return (
        <Box 
            className={`select ${filters.nbPiecesPrinc > 1 && "is-success-primary"}`}
            sx={{ width: "100%" }}
        >
            <select
                value={filters.nbPiecesPrinc}
                onChange={handleChange}
                name="nbPiecesPrinc"
                style={{ width: "100%" }}
            >
                {nombre_pieces_principales.map((d, i) => 
                    <option
                        key={i}
                        value={d.value}
                    >
                        {d.label}
                    </option>
                )}
            </select>
        </Box>
    )
}

const PriceFilter = () => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.estimations.estimations.filters)

    const handleChange = e => {
        const { name, value } = e.target

        dispatch({
            type: "estimations/set_filters",
            payload: { name, value }
        })

        dispatch(filterEstimations())
    }

    return (
        <Box 
            className={`select ${filters.value > 0 && "is-success-primary"}`}
            sx={{ width: "100%" }}
        >
            <select
                value={filters.value}
                onChange={handleChange}
                name="value"
                style={{ width: "100%" }}
            >
                {prices_min.map((d, i) => 
                    <option
                        key={i}
                        value={d.value}
                    >
                        {d.label}
                    </option>
                )}
            </select>
        </Box>
    )
}

const StepFilter = () => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.estimations.estimations.filters)

    const handleChange = e => {
        const { name, value } = e.target

        dispatch({
            type: "estimations/set_filters",
            payload: { name, value }
        })

        dispatch(filterEstimations())
    }

    return (
        <Box 
            className={`select ${filters.step !== "all" && "is-success-primary"}`} 
            sx={{ width: "100%" }}
        >
            <select
                value={filters.step}
                onChange={handleChange}
                name="step"
                style={{ width: "100%" }}
            >
                {step_choices.map((d, i) => 
                    <option
                        key={i}
                        value={d.value}
                    >
                        {d.label}
                    </option>
                )}
            </select>
        </Box>
    )
}

const SearchField = ({ sx }) => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.estimations.estimations.filters)
    const isSuccess = filters.search !== null && filters.search.trim().length

    const handleChangeSearch = (e) => {
        const { name, value } = e.target

        dispatch({
            type: "estimations/set_filters",
            payload: { name, value }
        })

        dispatch(filterEstimations())
    }

    const handleResetSearch = () => {
        dispatch({
            type: "estimations/set_filters",
            payload: { 
                name: "search", 
                value: "" 
            }
        })

        dispatch(filterEstimations())
    }

    return (
        <Box 
            className="control has-icons-left has-icons-right"
            sx={Object.assign({}, sx)}
        >
            <input 
                className={`input ${isSuccess && "is-success-primary"}`}
                type="text" 
                autoComplete="off"
                value={filters.search || ""} 
                onChange={handleChangeSearch}
                name="search"
                placeholder="Rech. nom/adresse"
                id="search_field"
            />
            <span className={`icon is-small is-left`}>
                <i className="fas fa-search" style={{ color: scssVar.greyDark }}></i>
            </span>
            {filters.search && (
                <span 
                    id="btn_delete_search" 
                    toggle="#search_field" 
                    className="icon is-small is-right" 
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={handleResetSearch}
                >
                    <i className="delete" style={{ backgroundColor: "black" }}></i>
                </span>
            )}
        </Box>
    )
}

const Sort = () => {
    const dispatch = useDispatch()
    const sortBy = useSelector(state => state.estimations.estimations.sortBy)

    const handleSort = (e) => {
        const { value } = e.target

        dispatch({
            type: "estimations/set_sort",
            payload: { value }
        })

        dispatch(filterEstimations())
    }

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            width: "100%"
        }}>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: scssVar.greyDark,
                fontSize: "14px",
                marginRight: "7px",
                whiteSpace: "nowrap",
                lineHeight: 0
            }}>
                Trier par :
            </Typography>
            <Box 
                className={`select ${sortBy !== "récentes" && "is-success-primary"}`} 
                sx={{ width: "100%" }}
            >
                <select
                    value={sortBy}
                    onChange={handleSort}
                    name="sortBy"
                    style={{ width: "100%" }}
                >
                    {sorting_choices.map((d, i) => 
                        <option
                            key={i}
                            value={d.value}
                        >
                            {d.label}
                        </option>
                    )}
                </select>
            </Box>
        </Box>
    )
}

const EstimationsFilters = () => {
    const dispatch = useDispatch()
    const working = useSelector(state => state.estimate.working)

    useEffect(() => {
        if (!working) {
            dispatch(getEstimations())
        }
    }, [working, dispatch])

    return (
        <Fragment>
            <Box sx={{
                display: { xs: "none", sm: "block" }
            }}>
                <Grid container spacing={1}>
                    <Grid item sm={3} md={3} lg={1.5} xl={1.5}>
                        <TypeBienFilter />
                    </Grid>
                    <Grid item sm={3} md={3} lg={1.5} xl={1.5}>
                        <NbPiecesFilter />
                    </Grid>
                    <Grid item sm={3} md={3} lg={1.5} xl={1.5}>
                        <PriceFilter />
                    </Grid>
                    <Grid item sm={3} md={3} lg={1.5} xl={1.5}>
                        <StepFilter />
                    </Grid>
                    <Grid item sm={6} md={6} lg={3} xl={3}>
                        <SearchField />
                    </Grid>
                    <Grid item sm={6} md={6} lg={3} xl={3}>
                        <Sort />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                display: { xs: "block", sm: "none" },
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                bgcolor: "white",
                zIndex: 1000,
            }}>
                <Box sx={{
                    width: "100%",
                    p: "20px 20px 5px"
                }}>
                    <SearchField />
                </Box>
                <Box sx={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    p: "5px 0 10px",
                    display: "flex",
                    alignItems: "center",
                    "& > *": {
                        flexShrink: 0,
                        mr: "10px"
                    },
                }}>
                    <Box sx={{ width: "157px", ml: "20px" }}>
                        <TypeBienFilter />
                    </Box>
                    <Box sx={{ width: "131px" }}>
                        <NbPiecesFilter />
                    </Box>
                    <Box sx={{ width: "151px" }}>
                        <PriceFilter />
                    </Box>
                    <Box sx={{ width: "163px" }}>
                        <StepFilter />
                    </Box>
                    <Box sx={{ width: "222px", mr: "20px" }}>
                        <Sort />
                    </Box>
                    <Box sx={{ padding: ".1px" }} />
                </Box>
            </Box>
        </Fragment>
    )
}

export default EstimationsFilters