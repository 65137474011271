import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { setHistory } from "./historySlice"

const History = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location

    useEffect(() => {
        dispatch(setHistory(pathname))
    }, [dispatch, pathname])

    return null
}

export default History