import { createSelector, createSlice } from "@reduxjs/toolkit"
import { selectOrg } from "../account/selectors"
import { selectHousing } from "../estimate/selectors"
import { getBlob } from "./settings"
import { selectMandatEditor } from "../pdfCreator/mandat/mandatSlice"

// ORG
export const getOrgBlob = () => async (dispatch, getState) => {
    const state = getState()
    const url = selectOrg(state).logo
    const blob = selectOrgBlob(state).blob
    const loading = selectOrgBlob(state).loading

    if (url && !blob && !loading) {
        dispatch(photosActions.getOrgBlobLoading())

        return getBlob(url)
            .then(blob => dispatch(photosActions.getOrgBlobSucceeded(URL.createObjectURL(blob))))
            .catch(err => dispatch(photosActions.getOrgBlobSucceeded(url)))
    }

    return
}

export const setOrgBlob = (newUrl) => async (dispatch, getState) => {
    dispatch(photosActions.getOrgBlobLoading())

    return getBlob(newUrl)
        .then(blob => dispatch(photosActions.getOrgBlobSucceeded(URL.createObjectURL(blob))))
        .catch(err => dispatch(photosActions.getOrgBlobSucceeded(newUrl)))
}

export const deleteOrgBlob = () => async (dispatch, getState) => {
    dispatch(photosActions.deleteOrgBlob())
}
// ========================================================================================================================
// MAIN
export const getMainBlob = () => async (dispatch, getState) => {
    const state = getState()
    const housingId = selectHousing(state).id
    const url = selectHousing(state).photo
    const { blob, loading } = selectMainBlob(state)

    if (url && !blob && !loading) {
        dispatch(photosActions.getMainBlobLoading({ 
            loading: true, 
            housingId, 
            url: null 
        }))

        return getBlob(url)
            .then(blob => dispatch(photosActions.getMainBlobSucceeded({
                loading: false,
                housingId,
                url: URL.createObjectURL(blob)
            })))
            .catch(err => dispatch(photosActions.getMainBlobSucceeded({
                loading: false,
                housingId,
                url
            })))
    }

    return
}

export const setMainBlob = (newUrl) => async (dispatch, getState) => {
    const state = getState()
    const housingId = selectHousing(state).id

    dispatch(photosActions.setMainBlobLoading({ housingId }))

    return getBlob(newUrl)
        .then(blob => dispatch(photosActions.setMainBlobSucceeded({
            housingId,
            url: URL.createObjectURL(blob)
        })))
        .catch(err => dispatch(photosActions.setMainBlobSucceeded({
            housingId,
            url: newUrl
        })))
}

export const deleteMainBlob = () => async (dispatch, getState) => {
    const state = getState()
    const housingId = selectHousing(state).id
    dispatch(photosActions.deleteMainBlob({ housingId }))
}
// ========================================================================================================================
// COMMON
// const fakeHousingPhotos = {
//     // photo de couverture
//     0: { id: 313, photo: "https://d2jne3r7rh2pg3.cloudfront.net/media/00ii7aVC2Ea887bCCBShgP5yxBq0jI4EiNur0u8xFSuL27u8VCO06C727O1b.png" }, 
//     // les 3 autres photos
//     1: [
//         { id: 303, aspect: "3:4", photo: "https://d2jne3r7rh2pg3.cloudfront.net/media/1aIf0o9lCQHqXCks5iGwycqFISI9qtYun5Yvgd6SFMlXxxGRxX1Xa6g1hVVGE4M0sybP35bbqISRWf6UYlsiNA0ry8CazJhzRRO.png" },
//         // { id: 306, aspect: "3:4", photo: "https://d2jne3r7rh2pg3.cloudfront.net/media/2GLkrtLfEgNQVuR0C0vOQ57PeAGOyvTxxneLtTFFni6r8moGtJboizL.png" },
//         { id: 314, aspect: "16:9", photo: "https://d2jne3r7rh2pg3.cloudfront.net/media/EYTtvSj90IZCRKpIeLOA4D5i4ZjaWsD1N8ORv0oIGacgtSZ4slL9SUbbhJgykh07mzqkm3N8b8uq0wxyVv.png" }
//     ], 
// }
// COVER
export const getCoverBlob = () => async (dispatch, getState) => {
    const state = getState()
    const housingId = selectHousing(state).id
    const blob = selectCoverBlob(state)
    const photo = selectHousing(state).photos_2.cover || {}

    if (photo.photo && !blob.loading && !blob.src) {
        dispatch(photosActions.getCoverBlobLoading({ 
            housingId, 
            loading: true, 
            id: photo.id,
            src: null 
        }))

        return getBlob(photo.photo)
            .then(res => {
                dispatch(photosActions.getCoverBlobSucceeded({
                    housingId, 
                    loading: false, 
                    id: photo.id,
                    src: URL.createObjectURL(res)
                }))
            })
            .catch(err => {
                console.log(err)
                dispatch(photosActions.getCoverBlobSucceeded({
                    housingId, 
                    loading: false, 
                    id: photo.id,
                    src: photo.photo
                }))
            })
    }

    return
}

// GALLERY
export const getGalleryBlobs = () => async (dispatch, getState) => {
    const state = getState()
    const housingId = selectHousing(state).id
    const blobs = selectGalleryBlobs(state)
    const photos = selectHousing(state).photos_2.gallery || []

    photos.forEach(photo => {
        const blob = blobs.find(d => d.id === photo.id) || {}

        if (photo.photo && !blob.loading && !blob.src) {
            dispatch(photosActions.getGalleryBlobLoading({ 
                housingId, 
                loading: true, 
                id: photo.id,
                aspect: photo.aspect,
                src: null 
            }))
    
            return getBlob(photo.photo)
                .then(res => {
                    dispatch(photosActions.getGalleryBlobSucceeded({
                        housingId, 
                        loading: false, 
                        id: photo.id,
                        aspect: photo.aspect,
                        src: URL.createObjectURL(res)
                    }))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(photosActions.getGalleryBlobSucceeded({
                        housingId, 
                        loading: false, 
                        id: photo.id,
                        aspect: photo.aspect,
                        src: photo.photo
                    }))
                })
        }

        return
    })
}
// ========================================================================================================================
// MANDAT
// const fakeMandatPhotos = [
//     {id: 301, photo: "https://d2jne3r7rh2pg3.cloudfront.net/media/2Ki95ZwS5hxaaGNVqVWx7MxE14NLFP1bMVjJUtbD8arE4StQithlydQyLt7uq.png"},
//     {id: 302, photo: "https://d2jne3r7rh2pg3.cloudfront.net/media/dnmouzELgo5CPDeQTW8wbJOnzowRf4bR9ntqnzf82SW5DzrQKrdhVXV5DsZOsi.png"},
// ]
export const getMandatBlobs = () => async (dispatch, getState) => {
    const state = getState()
    const housingId = selectHousing(state).id
    const blobs = selectMandatBlobs(state)
    const photos = selectMandatEditor(state).photos_2

    photos.forEach(async (photo) => {
        const blob = blobs.find(d => d.id === photo.id) || {}

        if (photo.photo && !blob.loading && !blob.src) {
            dispatch(photosActions.getMandatBlobLoading({ 
                housingId, 
                loading: true, 
                id: photo.id,
                src: null 
            }))

            return getBlob(photo.photo)
                .then(res => {
                    dispatch(photosActions.getMandatBlobSucceeded({
                        housingId, 
                        loading: false, 
                        id: photo.id,
                        src: URL.createObjectURL(res)
                    }))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(photosActions.getMandatBlobSucceeded({
                        housingId, 
                        loading: false, 
                        id: photo.id,
                        src: photo.photo
                    }))
                })
        }

        return
    })
}
// ========================================================================================================================

const initialState = {
    org: { loading: false, blob: null },
    main: [],
    common: {
        cover: [],
        gallery: []
    },
    mandat: [],
}

const photosSlice = createSlice({
    name: "photos",
    initialState,
    reducers: {
        // ORG LOGO
        getOrgBlobLoading: (state) => {
            state.org.loading = true
        },
        getOrgBlobSucceeded: (state, action) => {
            state.org.loading = false
            state.org.blob = action.payload
        },
        deleteOrgBlob: (state) => {
            state.org.blob = null
        },
        // ========================================================================================================================
        // MAIN
        getMainBlobLoading: (state, action) => {
            state.main.push(action.payload)
        },
        getMainBlobSucceeded: (state, action) => {
            state.main = state.main.map(d => d.housingId === action.payload.housingId ? action.payload : d)
        },
        deleteMainBlob: (state, action) => {
            state.main = state.main.filter(d => d.housingId !== action.payload.housingId)
        },
        setMainBlobLoading: (state, action) => {
            state.main = state.main.map(d => d.housingId === action.payload.housingId ? { ...d, loading: true }: d)
        },
        setMainBlobSucceeded: (state, action) => {
            state.main = state.main.map(d => d.housingId === action.payload.housingId ? { ...d, loading: false, url: action.payload.url }: d)
        },
        // ========================================================================================================================
        // COMMON
        // COVER
        getCoverBlobLoading: (state, action) => {
            state.common.cover.push(action.payload)
        },
        getCoverBlobSucceeded: (state, action) => {
            state.common.cover = state.common.cover.map(d => d.housingId === action.payload.housingId && d.id === action.payload.id ? action.payload : d)
        },
        deleteCoverBlobSucceeded: (state, action) => {
            state.common.cover = state.common.cover.filter(d => {
                if (d.housingId === action.payload.housingId) {
                    if (d.id === action.payload.id) {
                        return false
                    }
                    return true
                }
                return true
            })
        },
        // GALLERY
        getGalleryBlobLoading: (state, action) => {
            state.common.gallery.push(action.payload)
        },
        getGalleryBlobSucceeded: (state, action) => {
            state.common.gallery = state.common.gallery.map(d => d.housingId === action.payload.housingId && d.id === action.payload.id ? action.payload : d)
        },
        deleteGalleryBlobSucceeded: (state, action) => {
            state.common.gallery = state.common.gallery.filter(d => {
                if (d.housingId === action.payload.housingId) {
                    if (d.id === action.payload.id) {
                        return false
                    }
                    return true
                }
                return true
            })
        },
        // ========================================================================================================================
        // MANDAT
        getMandatBlobLoading: (state, action) => {
            state.mandat.push(action.payload)
        },
        getMandatBlobSucceeded: (state, action) => {
            state.mandat = state.mandat.map(d => d.housingId === action.payload.housingId && d.id === action.payload.id ? action.payload : d)
        },
        deleteMandatBlob: (state, action) => {
            state.mandat = state.mandat.filter(d => {
                if (d.housingId === action.payload.housingId) { 
                    if (d.id === action.payload.id) {
                        return false
                    }
                    return true
                }
                return true
            })
        },
        // ========================================================================================================================
    },
    extraReducers: (builder) => builder.addCase("auth/logout", () => initialState)
})

export const photosActions = photosSlice.actions

export const selectOrgBlob = (state) => state.photos.org

// memo ok
export const selectMainBlob = createSelector([
    (state) => selectHousing(state).id,
    (state) => state.photos.main,
], (housingId, blobs) => {
    const blob = blobs.find(d => d.housingId === housingId)?.url
    const loading = blobs.find(d => d.housingId === housingId)?.loading
    return { blob, loading }
})

// memo ok
export const selectCoverBlob = createSelector([
    (state) => selectHousing(state).id,
    (state) => state.photos.common.cover
], (housingId, blobs) => {
    const blob = blobs.find(d => d.housingId === housingId) || {}
    const { src, loading, id } = blob
    return { src, loading, id }
})

// memo ok
export const selectGalleryBlobs = createSelector([
    (state) => selectHousing(state).id,
    (state) => state.photos.common.gallery
], (housingId, blobs) => blobs.filter(d => d.housingId === housingId) || [])

// memo ok
export const selectMandatBlobs = createSelector([
    (state) => selectHousing(state).id,
    (state) => state.photos.mandat,
], (housingId, blobs) => blobs.filter(d => d.housingId === housingId) || [])

export default photosSlice.reducer