import * as d3 from "d3";
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar";
import { selectShapleyData } from "../../selectors"

const getGradientPositive = (svg) => {
    const id = "gradientPositive"

    const gradient = svg.append("svg:defs")
        .append("svg:linearGradient")
        .attr("id", id)
        .attr("x1", "0%")
        .attr("y1", "50%")
        .attr("x2", "100%")
        .attr("y2", "50%")
        .attr("spreadMethod", "pad");

    gradient.append("svg:stop")
        .attr("offset", "0%")
        .attr("stop-color", "rgb(209,255,163)")
        .attr("stop-opacity", 1);

    gradient.append("svg:stop")
        .attr("offset", "19%")
        .attr("stop-color", "rgb(0,255,43)")
        .attr("stop-opacity", 0.58);

    gradient.append("svg:stop")
        .attr("offset", "56%")
        .attr("stop-color", "rgb(14,230,1)")
        .attr("stop-opacity", 1)

    return `url(#${id})`
}

const getGradientNegative = (svg) => {
    const id = "gradientNegative"

    const gradient = svg.append("svg:defs")
        .append("svg:linearGradient")
        .attr("id", id)
        .attr("x1", "0%")
        .attr("y1", "50%")
        .attr("x2", "100%")
        .attr("y2", "50%")
        .attr("spreadMethod", "pad");

    gradient.append("svg:stop")
        .attr("offset", "44%")
        .attr("stop-color", "rgb(230,1,1)")
        .attr("stop-opacity", 1);

    gradient.append("svg:stop")
        .attr("offset", "81%")
        .attr("stop-color", "rgb(255,0,0)")
        .attr("stop-opacity", 0.58);

    gradient.append("svg:stop")
        .attr("offset", "99%")
        .attr("stop-color", "rgb(255,163,163)")
        .attr("stop-opacity", 0.58);

    return `url(#${id})`
}

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/horizontal-bar-chart
const draw = (data, svgRef, {
    x = d => d, // given d in data, returns the (quantitative) x-value
    y = (d, i) => i, // given d in data, returns the (ordinal) y-value
    title, // given d in data, returns the title text
    marginTop = 60, // the top margin, in pixels
    marginRight = 20, // the right margin, in pixels
    marginBottom = 10, // the bottom margin, in pixels
    marginLeft = 20, // the left margin, in pixels
    width = 640, // the outer width of the chart, in pixels
    height, // outer height, in pixels
    xType = d3.scaleLinear, // type of x-scale
    xDomain, // [xmin, xmax]
    xRange = [marginLeft, width - marginRight], // [left, right]
    xFormat, // a format specifier string for the x-axis
    xLabel, // a label for the x-axis
    yPadding = 0.2, // amount of y-range to reserve to separate bars
    yDomain, // an array of (ordinal) y-values
    yRange, // [top, bottom]
    color = "currentColor", // bar fill color
    titleColor = "white", // title fill color when atop bar
    titleAltColor = "black", // title fill color when atop background
  } = {}) => {
    // if component remount, remove chart and redraw
    d3.select(svgRef.current).selectAll("*").remove()
    d3.selectAll("#shapley_tooltip").remove()

    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);

    // Compute default domains, and unique the y-domain.
    // const xMin = d3.min(X) < 0 ? d3.min(X) - 0.01 : 0;
    const xMin = -0.2;
    // const xMax = d3.max(X) > 0 ? d3.max(X) + 0.01 : 0;
    const xMax = 0.2;
    if (xDomain === undefined) xDomain = [xMin, xMax];
    if (yDomain === undefined) yDomain = Y;
    yDomain = new d3.InternSet(yDomain);
  
    // Omit any data not present in the y-domain.
    const I = d3.range(X.length).filter(i => yDomain.has(Y[i]));
  
    // Compute the default height.
    if (height === undefined) height = Math.ceil((yDomain.size + yPadding) * 25) + marginTop + marginBottom;
    if (yRange === undefined) yRange = [marginTop, height - marginBottom];

    // Construct scales and axes.
    const xScale = xType(xDomain, xRange);
    const yScale = d3.scaleBand(yDomain, yRange).padding(yPadding);
    const xAxis = d3.axisTop(xScale).ticks(width / 80, xFormat);
    const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);
  
    // Compute titles.
    if (title === undefined) {
        // const formatValue = xScale.tickFormat(100, xFormat);
        title = i => data[i].displayed_effect
    } else {
        const O = d3.map(data, d => d);
        const T = title;
        title = i => T(O[i], i, data);
    }

    const tooltipTitle = i => [data[i].field_explanation, data[i].tooltip]

    // Init svg
    const svg = d3.select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .style("-webkit-tap-highlight-color", "transparent")
        .style("overflow", "visible")
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    // Draw Y
    svg.append("g")
        .attr("transform", `translate(0,0)`)
        .call(yAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll("line").remove())
        .call(g => g.selectAll("text")
            .attr("fill", "black")
            .attr("font-size", 16)
            .attr("font-weight", 600)
            .style("font-family", scssVar.fontFamily)
            .attr("text-anchor", d => {
                const val = data.find(t => t.label === d)?.relative_effect || 0
                if (val <= 0) return "start"
                return "end"
            })
            .attr("x", d => {
                const val = data.find(t => t.label === d)?.relative_effect || 0
                if (val <= 0) return xScale(0) + 7
                return xScale(0) - 7
            })
        )
  
    // Draw X
    svg.append("g")
        .attr("transform", `translate(0,${marginTop})`)
        .call(xAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll("line")
            .attr("stroke", "black")
            .attr("stroke-opacity", 0)
        )
        .call(g => g.selectAll(".tick line").clone()
            .attr("y2", height - marginTop - marginBottom)
            .attr("stroke-opacity", 0.1)
            .attr("id", "grid")
        )
        .call(g => g.selectAll("text")
            .attr("fill", "black")
            .attr("font-size", 16)
            .attr("font-weight", 600)
            .style("font-family", scssVar.fontFamily)
        )
        .call(g => g.append("text")
            .attr("x", width - marginRight)
            .attr("y", -32)
            .attr("fill", "black")
            .attr("text-anchor", "end")
            .attr("font-size", 16)
            .attr("font-weight", 600)
            .style("font-family", scssVar.fontFamily)
            .text(xLabel)
        );
  
    // Get Bars colors gradients
    const gradientPositive = getGradientPositive(svg)
    const gradientNegative = getGradientNegative(svg)
    // Hovered color bars
    const hoveredPositive = "#C0F1D4"
    const hoveredNegative = "pink"

    // Draw Bars
    svg.append("g")
        .selectAll("rect")
        .data(I)
        .join("rect")
            .attr("fill", i => X[i] > 0 ? gradientPositive : gradientNegative)
            .attr("rx", 4)
            .attr("x", i => X[i] > 0 ? xScale(0) : xScale(X[i]))
            .attr("y", i => yScale(Y[i]))
            .attr("width", i => X[i] > 0 ? xScale(X[i]) - xScale(0) : xScale(0) - Math.abs(xScale(X[i])))
            .attr("height", yScale.bandwidth())
            .on("pointerenter pointermove mouseover", pointerMoved)
            .on("touchend mouseout pointerleave", pointerLeft)
  
    // Draw Texts bars
    svg.append("g")
        .attr("fill", titleColor)
        .attr("font-size", 16)
        .attr("font-weight", 600)
        .style("font-family", scssVar.fontFamily)
        .selectAll("text")
        .data(I)
        .join("text")
            .attr("text-anchor", i => X[i] <= 0 ? "start" : "end")
            .attr("x", i => xScale(X[i]))
            .attr("y", i => yScale(Y[i]) + yScale.bandwidth() / 2)
            .attr("dy", "0.35em")
            .attr("dx", i => X[i] <= 0 ? 4 : -4)
            .text(title)
            // short bars
            .call(text => text.filter(i => Math.abs(xScale(X[i]) - xScale(0)) < 80) 
                .attr("dx", i => X[i] <= 0 ? -57 : 4)
                .attr("fill", titleAltColor)
                .attr("text-anchor", "start")
            );

    // Draw Top Line
    svg.append("g")
        .append("line")
        .attr("stroke", "black")
        .attr("stroke-opacity", 0.1)
        .attr("y1", marginTop)
        .attr("y2", marginTop)
        .attr("width", 2)
        .attr("x1", 0)
        .attr("x2", width)

    // Draw Bottom Line
    svg.append("g")
        .append("line")
        .attr("stroke", "black")
        .attr("stroke-opacity", 0.1)
        .attr("y1", height - marginBottom)
        .attr("y2", height - marginBottom)
        .attr("width", 2)
        .attr("x1", 0)
        .attr("x2", width)

    // TOOLTIP
    // https://observablehq.com/@d3/line-with-tooltip?collection=@d3/charts
    // https://gist.github.com/d3noob/97e51c5be17291f79a27705cef827da2
    const tooltip = d3.select("body")
        .append("div")
        .attr("id", "shapley_tooltip")
        .style("display", "none")
        .style("background", scssVar.greyDarker)
        .style("color", "white")
        .style("position", "absolute")
        .style("padding", "4px 6px")
        .style("pointer-event", "none")
        .style("font-size", "14px")
        .style("line-height", "19px")
        .style("border-radius", "4px")
        .style("z-index", 2000)

    function pointerMoved(event, i) {
        d3.select(this).attr("fill", i => X[i] > 0 ? hoveredPositive : hoveredNegative);

        tooltip.style("display", null);

        // const coords = d3.pointer(event)
        const coords = [event.pageX, event.pageY]

        tooltip.html(
            "<p style='font-weight:600'>"
            + tooltipTitle(i)[0]
            + "</p>"
            + "<p>"
            + tooltipTitle(i)[1]
            + "</p>"
        )
        .style("left", coords[0] + 10 + "px")
        .style("top", coords[1] + 10 + "px");
    }

    function pointerLeft(i) {
        d3.select(this)
            .transition().duration(250)
            .attr("fill", i => X[i] > 0 ? gradientPositive : gradientNegative);

        tooltip.style("display", "none");
    }
  
    return svg.node();
}

const ShapleyChartDesktop = () => {
    const svgRef = useRef(null)
    const { data } = useSelector(selectShapleyData)
    const dataReady = data
        //.filter(d => d.relative_effect > 0.04 || d.relative_effect < -0.04)

    useEffect(() => {
        draw(dataReady, svgRef, {
            x: d => d.relative_effect,
            y: d => d.label,
            yDomain: d3.groupSort(dataReady, ([d]) => -d.relative_effect, d => d.label), // sort by descending frequency
            xFormat: ".0%",
            xLabel: "Variation de prix",
            width: 900
        })
    }, [dataReady])

    return <svg ref={svgRef} />
}

export default ShapleyChartDesktop