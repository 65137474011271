import { createSelector, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../common/setupApi";
import { addAlert } from "../../alerts/alertsSlice";
import { selectEstimation, selectHousing } from "../../estimate/selectors";
import { updateEstimationsList } from "../../estimate/actions";
import { defaultErrorMessage } from "../../../common/utils";

export const saveMandat = () => async (dispatch, getState) => {
    const state = getState()
    const estimationId = selectEstimation(state).id
    const editor = selectMandatEditor(state)
    const { estimation_id, ...payload } = editor

    dispatch(mandatActions.saveLoading())

    return api
        .patch(`v1/housing/estimation/mandat/${estimationId}/`, payload)
        .then(res => {
            dispatch(mandatActions.saveSucceeded({ data: res.data }))
        })
        .catch(err => {
            console.log(err.response)
            dispatch(mandatActions.saveFailed({ error: err.response?.data }))
            dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
        })
}

export const getMandat = (estimationId) => async (dispatch, getState) => {
    const state = getState()
    const sellerLastName = selectHousing(state).nom_vendeur
    const sellerFirstName = selectHousing(state).prenom_vendeur
    const alreadySelected = state.mandat.mandats.items.find(d => d.estimation_id === estimationId)

    if (alreadySelected !== undefined) {
        //console.log("fetched", alreadySelected)
        dispatch(mandatActions.initEditor(alreadySelected))
        return
    }

    //console.log("fetching...")

    dispatch(mandatActions.getLoading())

    return api
        .get(`v1/housing/estimation/mandat/${estimationId}/`)
        .then(res => {
            const data = res.data

            // if (!data.type_bien_detail) {
            //     data.type_bien_detail = isHouse ? "maison" : "appartement"
            // }

            if (!data.mandants.length) {
                // console.log("init empty mandants")
                data.mandants = [
                    { 
                        id_ordre: 0,
                        civilite: "",
                        nom_naissance: sellerLastName,
                        prenom: sellerFirstName,
                        date_naissance: "",
                        nationalite: "",
                        lieu_naissance: "",
                        adresse_domicile: "",
                        cp_domicile: "",
                        ville_domicile: ""
                    }, 
                    Object.assign({ id_ordre: 1 }, emptyMandant)
                ]
            }

            if (!data.sites_annonces.length) {
                //console.log("init empty annonces")
                data.sites_annonces = [...Array(5)].map((_, i) => ({ id: i, value: "" }))
            }

            if (!data.mandat_type) {
                data.mandat_type = "vente"
            }

            if (!data.mandat_type_exclusivite) {
                data.mandat_type_exclusivite = "exclu"
            }

            if (!data.duree_mandat) {
                data.duree_mandat = "6"
            }

            dispatch(mandatActions.initEditor(data))
            dispatch(mandatActions.getSucceeded({ data }))
        })
        .catch(err => {
            console.log(err.response)
            dispatch(mandatActions.getFailed({ error: err.response }))
            dispatch(addAlert("Une erreur est survenue, veuillez réessayer.", "error"))
        })
}

export const saveAvenant = (id) => async (dispatch, getState) => {
    const state = getState()
    const estimationId = selectEstimation(state).id
    const editor = selectAvenantEditor(state, id)

    const payload = { ...editor, estimation_id: estimationId }

    dispatch(mandatActions.saveLoading())

    return api
        .patch(`v1/housing/estimation/amendment/${id}/`, payload)
        .then(res => {
            dispatch(mandatActions.saveAvenantSucceeded({ estimationId, data: res.data.amendments.find(d => d.id === id) }))

            const estimation = selectEstimation(state)

            const updatedEstimation = {
                ...estimation,
                ad_displayed_value: res.data.ad_displayed_value,
                ad_net_value: res.data.ad_net_value,
                ad_fee_rates: res.data.ad_fee_rates,
                ad_fee_type: res.data.ad_fee_type,
                ad_total_value: res.data.ad_total_value
            }
            
            dispatch({ type: "estimate/set_estimation", payload: updatedEstimation })
            dispatch({ type: "estimate/edit_estimation", payload: updatedEstimation })
            dispatch(updateEstimationsList("Synthesis"))
        })
        .catch(err => {
            console.log(err.response)
            dispatch(mandatActions.saveFailed({ error: err.response?.data }))
            try {
                dispatch(addAlert(err.response.data, "error"))
            } catch (otherError) {
                console.log(otherError)
                dispatch(addAlert(defaultErrorMessage, "error"))
            }
        })
}

const emptyMandant = {
    "civilite": "", // -> || null ?
    "nom_naissance": "", // -> || null ?
    "prenom": "", // -> || null ?
    "date_naissance": "", // -> || null ?
    "nationalite": "", // -> || null ?
    "lieu_naissance": "", // -> || null ?
    "adresse_domicile": "", // -> || null ?
    "cp_domicile": "", // -> || null ?
    "ville_domicile": "", // -> || null ?
}

const initialState = {
    editor: {},
    save: {
        saving: false,
        error: null,
        last: null
    },
    mandats: {
        loading: false,
        error: null,
        items: [],
        status: "idle",
    }
}

const mandatSlice = createSlice({
    name: "mandat",
    initialState,
    reducers: {
        setField: (state, action) => {
            state.editor[action.payload.name] = action.payload.value
        },
        setMandantInfos: (state, action) => {
            state.editor.mandants =
                state.editor.mandants.map(d => d.id_ordre === action.payload.id ? { ...d, [action.payload.name]: action.payload.value } : d)
        },
        setAdSite: (state, action) => {
            const { name, value } = action.payload
            state.editor.sites_annonces = state.editor.sites_annonces.map(d => d.id === name ? { ...d, value } : d)
        },
        setPhotos: (state, action) => {
            state.editor.photos_2 = action.payload.data
            state.mandats.items = state.mandats.items.map(d => d.estimation_id === action.payload.estimationId ? { ...d, photos_2: action.payload.data } : d)
        },
        initEditor: (state, action) => {
            state.editor = action.payload
            state.save.error = null
            state.save.last = null
            state.save.saving = false
        },
        getLoading: (state) => {
            state.mandats.status = "pending"
            state.mandats.error = null
        },
        getSucceeded: (state, action) => {
            state.mandats.status = "fulfilled"
            state.mandats.items.push(action.payload.data)
        },
        getFailed: (state, action) => {
            state.mandats.status = "rejected"
            state.mandats.error = action.payload.error
        },
        saveLoading: (state) => {
            state.save.saving = true
            state.save.error = null
        },
        saveSucceeded: (state, action) => {
            state.save.last = String(new Date())
            state.save.saving = false
            state.mandats.items = state.mandats.items.map(d => d.estimation_id === action.payload.data.estimation_id ? action.payload.data : d)
        },
        saveAvenantSucceeded: (state, action) => {
            state.save.last = String(new Date())
            state.save.saving = false
            // on update new_net_value
            state.editor.amendments = state.editor.amendments.map(d => d.id === action.payload.data.id ? action.payload.data : d)
            state.mandats.items = state.mandats.items.map(d => d.estimation_id === action.payload.estimationId ? { 
                ...d, 
                amendments: d.amendments.map(a => a.id === action.payload.data.id ? action.payload.data : a)
            }: d)
        },
        saveFailed: (state, action) => {
            state.save.saving = false
            state.save.error = action.payload.error
        },
        addAvenant: (state, action) => {
            state.editor.amendments = action.payload.data
            state.mandats.items = state.mandats.items.map(d => d.estimation_id === action.payload.estimationId ? { 
                ...d, 
                amendments: action.payload.data 
            } : d)
        },
        setAvenant: (state, action) => {
            const { id, name, value } = action.payload
            state.editor.amendments = state.editor.amendments.map(d => d.id === id ? { ...d, [name]: value }: d)
        }
    },
    extraReducers: (builder) => builder.addCase("auth/logout", () => initialState)
})

export const mandatActions = mandatSlice.actions

export const addAvenant = (estimationId, amendments) => async (dispatch, getState) => {
    dispatch(mandatActions.addAvenant({ estimationId, data: amendments }))

    function getEl() {
        const index = selectMandatEditor(getState()).amendments.length - 1
        return document.getElementById(`avenant-anchor-${index}`)
    }

    const interval = setInterval(() => {
        const el = getEl()
        if (el) {
            el.scrollIntoView({ behavior: "smooth" })
            clearInterval(interval)
        }
    }, [100])
}

export const selectMandat = (state) => {
    const data = state.mandat.mandats.items
    const status = state.mandat.mandats.status
    const error = state.mandat.mandats.error
    const isLoading = status === "pending"
    return { data, status, error, isLoading }
}

export const selectMandatEditor = (state) => state.mandat.editor
export const selectMandatEditor2 = (state) => state.mandat.editor

export const selectMandatEditorDiag = (state) => {
    const editor = selectMandatEditor(state)
    return {
        peb: editor.diag_peb,
        dpe: editor.diag_dpe,
        amiante: editor.diag_amiante,
        plomb: editor.diag_plomb,
        antiparasitaire: editor.diag_antiparasitaire,
        elec: editor.diag_elec_15ans,
        gaz: editor.diag_gaz_15ans,
        erp: editor.diag_erp,
        assain: editor.diag_assainissement,
        merule: editor.diag_merule,
    }
}

export const selectMandatEditorActionsSpe = (state) => {
    const editor = selectMandatEditor(state)
    return {
        proxim: editor.action_spe_proximite,
        offMarket: editor.action_spe_offmarket,
        panneau: editor.action_spe_panneau,
    }
}

export const selectMandatSaving = (state) => state.mandat.save.saving

export const selectMandatLastSave = (state) => state.mandat.save.last

// memo ok
export const selectFirstMandant = createSelector([
    state => selectMandatEditor(state)
], (editor) => {
    const mandants = editor.mandants || []
    const firstMandant = mandants[0] || {}
    const lastName = firstMandant.nom_naissance || ""
    const firstName = firstMandant.prenom || ""
    return lastName + " " + firstName
})

// memo ok
export const selectAvenantEditor = createSelector([
    (state) => selectMandatEditor(state).amendments,
    (_, id) => id, // passing argument from Component
], (amendments, id) => amendments.find(d => d.id === id))

export default mandatSlice.reducer