import { useSelector } from "react-redux"
import LeadsGrid from "./components/LeadsGrid"
import { selectApiLeads } from "../apiOfferSlice"
import { selectTeams } from "../../account/selectors"
import { scssVar } from "../../../app/scssVar"
import LoaderGif from "../../../common/components/LoaderGif"

const TabLeads = () => {
    const leads = useSelector(selectApiLeads)
    const teams = useSelector(selectTeams)

    if (leads.isLoading || teams.isLoading) {
        return <LoaderGif />
    }

    if (leads.error || teams.error) {
        return <p style={{ color: scssVar.danger }}>Une erreur est survenue, veuillez réessayer</p>
    }

    return <LeadsGrid />
}

export default TabLeads