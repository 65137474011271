import { useSelector } from "react-redux"

const useInterieurEtDecoItems = () => {
    const luminosite = useSelector(state => state.estimate.estimation.luminosite)
    const hauteurSousPlafond = useSelector(state => state.estimate.estimation.hauteur_sous_plafond)
    const decoration = useSelector(state => state.estimate.estimation.decoration)
    //const etatInterieur = useSelector(state => state.estimate.estimation.etat_interieur)

    return [
        { label: "Luminosité", value: luminosite, nd: "Non définie" },
        { label: "Hauteur sous plafond", value: hauteurSousPlafond, nd: "Non définie" },
        { label: "Style de décoration", value: decoration, nd: "Non défini" },
        //{ label: "État intérieur", value: etatInterieur, nd: "Non défini" },
    ]
}

export default useInterieurEtDecoItems