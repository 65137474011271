import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { apiOfferActions, selectApiLeads, selectApiLeadsFilters } from '../../apiOfferSlice';
import { capitalizeFirstLetter, getDateToString, m2, numberWithSpaces } from '../../../../common/utils';
import { DoneRounded, VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { scssVar } from '../../../../app/scssVar';
import TooltipStyled from '../../../../common/components/TooltipStyled';
import { api } from '../../../../common/setupApi';
import { selectAllTeamsUsers } from '../../../account/selectors';
import { addAlert } from '../../../alerts/alertsSlice';
import { useEffect, useMemo, useState } from 'react';
import { Switch } from '@mui/material';
import useAuth from '../../../auth/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const selectedRowColor = "rgba(69, 39, 116, .035)"
const selectedRowColorHover = "rgba(69, 39, 116, .085)"
const dense = true

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function requestSearch(field, comparator) {
    return field.toUpperCase().indexOf(comparator.toUpperCase()) > -1
}

const EnhancedTableHead = ({ 
    headCells,
    onSelectAllClick, 
    order, 
    orderBy, 
    numSelected, 
    rowCount, 
    onRequestSort, 
    loading 
}) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" sx={{ 
                    "&.MuiTableCell-root.MuiTableCell-paddingCheckbox": {
                        pl: "52.5px" 
                    }
                }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        disabled={loading}
                        sx={{
                            color: scssVar.primary,
                            '&.Mui-checked': {
                                color: scssVar.primary
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: scssVar.primary
                            }
                        }}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            disabled={loading}
                            sx={{
                                fontFamily: scssVar.fontFamily,
                                whiteSpace: "nowrap"
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = ({ 
    numSelected, 
    selected, 
    search, 
    onSearch, 
    loading,
    onChangeSeen,
    onChangeAssign
}) => {
    const users = useSelector(selectAllTeamsUsers)
    const { isAdmin } = useAuth().user
    const [assignedTo, setAssignedTo] = useState("")

    useEffect(() => {
        setAssignedTo("")
    }, [selected])

    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            pt: numSelected > 0 ? 1 : 1.30,
            pb: numSelected > 0 ? 1 : 1.30,
            pl: 2,
            pr: 1,
            width: "100%",
            overflowX: "auto",
            bgcolor: numSelected > 0 ? selectedRowColor : "transparent",
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px",
        }}>
            {loading ? (
                <p style={{ flex: 1, fontSize: 14, color: scssVar.greyDarker }}>
                    Chargement...
                </p>
            ): numSelected > 0 ? (
                <p style={{ flex: 1, fontWeight: 700, whiteSpace: "nowrap", fontFamily: scssVar.fontFamily, marginRight: 10 }}>
                    {numSelected} sélectionné{numSelected > 1 ? "s" : ""}
                </p>
            ):(
                <p style={{ flex: 1, fontFamily: scssVar.fontFamily, fontWeight: 700, fontSize: 20, marginRight: 10 }} id="tableTitle">
                    Leads
                </p>
            )}

            {numSelected > 0 ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TooltipStyled arrow title="Marquer comme vu">
                        <span>
                            <IconButton onClick={() => onChangeSeen(selected, true)} disabled={loading}>
                                <VisibilityRounded sx={{ color: scssVar.primary }} />
                            </IconButton>
                        </span>
                    </TooltipStyled>
                    <TooltipStyled arrow title="Marquer comme non vu">
                        <span>
                            <IconButton onClick={() => onChangeSeen(selected, false)} disabled={loading}>
                                <VisibilityOffRounded sx={{ color: scssVar.primary }} />
                            </IconButton>
                        </span>
                    </TooltipStyled>
                    {isAdmin && (
                        <Box className={`select ${assignedTo && "is-success-primary"}`} sx={{ width: 150, ml: 1, fontSize: 14 }}>
                            <select
                                value={assignedTo} 
                                onChange={e => {
                                    setAssignedTo(e.target.value)
                                    onChangeAssign(selected, e.target.value)
                                }}
                                disabled={loading}
                                style={{ 
                                    width: "100%",
                                    borderColor: scssVar.greyDarker,
                                }}
                            >
                                <option value="" disabled>Attribuer à</option>
                                <option value="nobody">Ne pas attribuer</option>
                                {users.map((d, i) => <option value={d.id} key={i}>{d.name}</option>)}
                            </select>
                        </Box>
                    )}
                </Box>
            ):(
                <Box className="control has-icons-left has-icons-right" style={{ fontSize: 14 }}>
                    <input 
                        className={`input ${search && "is-success-primary"}`}
                        type="text" 
                        autoComplete="off"
                        value={search || ""} 
                        onChange={(e) => onSearch(e.target.value)}
                        placeholder="Recherche"
                        id="search_field"
                        disabled={loading}
                        style={{
                            borderColor: scssVar.grey,
                            fontSize: 14
                        }}
                    />
                    <span className={`icon is-small is-left`}>
                        <i className="fas fa-search" style={{ color: scssVar.greyDark }}></i>
                    </span>
                    {search && (
                        <span 
                            id="btn_delete_search" 
                            toggle="#search_field" 
                            className="icon is-small is-right" 
                            style={{ cursor: "pointer", pointerEvents: "all" }}
                            onClick={() => onSearch("")}
                        >
                            <i className="delete" style={{ backgroundColor: "black" }}></i>
                        </span>
                    )}
                </Box>
            )}
        </Box>
    );
}

const LeadsGrid = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const data = useSelector(selectApiLeads).data
    const users = useSelector(selectAllTeamsUsers)
    const { order, orderBy, search, selected, page, rowsPerPage } = useSelector(selectApiLeadsFilters)
    const { isAdmin } = useAuth().user
    const [loading, setLoading] = useState(false);

    const rows = useMemo(() => {
        const cleaned = data.map(d => ({ 
            ...d, 
            created_on: new Date(d.created_on),
            contact_ok: d.contact && d.contact !== "Je ne souhaite pas être contacté"
        }))

        if (search) {
            return cleaned.filter(d => {
                return requestSearch(d.adresse, search)
                    || requestSearch(d.lead_first_name, search)
                    || requestSearch(d.lead_last_name, search)
                    || requestSearch((d.lead_first_name + " " + d.lead_last_name), search)
                    || requestSearch(d.lead_email, search)
                    || requestSearch(d.profile || "", search)
                    || requestSearch(d.type_vente, search)
                    || requestSearch(String(d.value_displayed), search)
                    || requestSearch(getDateToString(d.created_on), search)
            })
        }

        return cleaned
    }, [data, search])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(apiOfferActions.setLeadsOrder({ order: isAsc ? 'desc' : 'asc', orderBy: property }))
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.pk);
            dispatch(apiOfferActions.setLeadsSelected(newSelected))
            return;
        }
        dispatch(apiOfferActions.setLeadsSelected([]))
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        dispatch(apiOfferActions.setLeadsSelected(newSelected));
    };

    const handleChangePage = (event, newPage) => {
        dispatch(apiOfferActions.setLeadsPage(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(apiOfferActions.setLeadsRowsPerPage(parseInt(event.target.value, 10)));
        dispatch(apiOfferActions.setLeadsPage(0));
    };

    const handleSearch = (newValue) => {
        dispatch(apiOfferActions.setLeadsSearch(newValue));
        if (page !== 0) {
            dispatch(apiOfferActions.setLeadsPage(0));
        }
    }

    const handleChangeSeen = async (pk, value) => {
        setLoading(true)
        return api
            .patch("v1/api/leads/", { pk, seen_by_profile: value })
            .then(() => {
                dispatch(apiOfferActions.setLeads({ pk, name: "seen_by_profile", value }))
                dispatch(addAlert(
                    pk.length > 1 
                        ? "Les leads ont bien été modifiés"
                        : "Le lead a bien été modifié", 
                    "success"
                ))
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                setLoading(false)
            })
    }

    const handleChangeAssign = async (pk, value) => {
        const cleanValue = value === "nobody" ? "" : value

        setLoading(true)

        return api
            .patch("v1/api/leads/", { pk, profile: cleanValue })
            .then(() => {
                dispatch(apiOfferActions.setLeads({ pk, name: "profile", value: cleanValue }))
                dispatch(addAlert(
                    pk.length > 1 
                        ? "Les leads ont bien été modifiés"
                        : "Le lead a bien été modifié", 
                    "success"
                ))
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                setLoading(false)
            })
    }

    const handleRedirectSynthesis = async (leadEstimation) => {
        setLoading(true)

        return api
            .get("v1/housing/estimation?housing_id=" + leadEstimation.housing_id)
            .then(async (res) => {
                const estimation = res.data[0]
                const housing = estimation.housing

                return api
                    .get("v1/housing/estimation/init", { params: { pk: leadEstimation.pk } })
                    .then(estimationWithCostsValuesRes => {

                        dispatch({ type: "estimate/set_estimation", payload: { ...estimationWithCostsValuesRes.data, current_step: "Synthesis" } })
                        dispatch({ type: "estimate/set_housing", payload: housing })
                        dispatch({ type: "estimate/set_is_lead_estimation", payload: true })
                        dispatch(apiOfferActions.setSelectedLead(leadEstimation.pk))

                        setLoading(false)

                        navigate("/estimer/synthese")
                    })
                    .catch(err => {
                        console.log(err.response)
                        dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                        setLoading(false)
                    })
            })
            .catch(err => {
                console.log(err.response)
                dispatch(addAlert("Une erreur est survenue, veuillez réessayer", "error"))
                setLoading(false)
            })
    }

    const headCells = [
        {
            id: 'created_on',
            disablePadding: true,
            label: 'Date',
            displayed: true
        },
        {
            id: 'value_displayed',
            disablePadding: false,
            label: 'Valeur',
            displayed: true
        },
        {
            id: 'adresse',
            disablePadding: false,
            label: 'Adresse',
            displayed: true
        },
        {
            id: 'profile',
            disablePadding: false,
            label: 'Attribué à',
            displayed: isAdmin
        },
        {
            id: 'seen_by_profile',
            disablePadding: false,
            label: 'Vu',
            displayed: true
        },
        {
            id: 'lead_email',
            disablePadding: false,
            label: 'Email',
            displayed: true
        },
        {
            id: 'lead_last_name',
            disablePadding: false,
            label: 'Nom',
            displayed: true
        },
        {
            id: 'lead_phone',
            disablePadding: false,
            label: 'Téléphone',
            displayed: true
        },
        {
            id: 'type_vente',
            disablePadding: false,
            label: 'Type de bien',
            displayed: true
        },
        {
            id: 'contact_ok',
            disablePadding: false,
            label: 'Contact',
            displayed: true
        }
    ].filter(d => d.displayed)

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', mb: 2, bgcolor: scssVar.greyLighter, borderRadius: "12px" }}>
                <EnhancedTableToolbar 
                    numSelected={selected.length} 
                    selected={selected}
                    search={search}
                    onSearch={handleSearch}
                    loading={loading}
                    onChangeSeen={handleChangeSeen}
                    onChangeAssign={handleChangeAssign}
                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            loading={loading}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.pk);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            sx={{
                                                "&.MuiTableRow-root.Mui-selected": {
                                                    backgroundColor: selectedRowColor,
                                                    "&:hover": {
                                                        backgroundColor: selectedRowColorHover
                                                    },
                                                },
                                            }}
                                        >
                                            <TableCell sx={{ p: "0 12px 0 16px" }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <TooltipStyled arrow title={`Affiner l'estimation de ${capitalizeFirstLetter(row.lead_first_name)} ${capitalizeFirstLetter(row.lead_last_name)}`}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => handleRedirectSynthesis(row)}
                                                                disabled={loading}
                                                                size="small"
                                                                sx={{ 
                                                                    bgcolor: scssVar.primary, 
                                                                    color: "white",
                                                                    "&:hover": {
                                                                        bgcolor: "#5a3299"
                                                                    }
                                                                }}
                                                            >
                                                                <i
                                                                    className="iconoir-edit-pencil"
                                                                    style={{
                                                                        fontSize: "inherit"
                                                                    }}
                                                                ></i>
                                                            </IconButton>
                                                        </span>
                                                    </TooltipStyled>
                                                    <Checkbox
                                                        onChange={(event) => handleClick(event, row.pk)}
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        disabled={loading}
                                                        sx={{
                                                            color: scssVar.primary,
                                                            '&.Mui-checked': {
                                                                color: scssVar.primary
                                                            },
                                                        }}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                sx={{
                                                    fontFamily: scssVar.fontFamily,
                                                    whiteSpace: "nowrap"
                                                }}
                                            >
                                                {getDateToString(row.created_on)}
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily, whiteSpace: "nowrap" }}>
                                                {numberWithSpaces(row.value_displayed)} €
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily, whiteSpace: "nowrap" }}>
                                                {row.adresse}
                                            </TableCell>
                                            {isAdmin && (
                                                <TableCell>
                                                    <Box className={`select ${row.profile && "is-success-primary"}`} sx={{ width: 125 }}>
                                                        <select
                                                            value={row.profile || ""} 
                                                            onChange={(e) => handleChangeAssign([row.pk], e.target.value)}
                                                            disabled={loading}
                                                            style={{ 
                                                                width: "100%",
                                                                borderColor: scssVar.grey
                                                            }}
                                                        >
                                                            <option value="" />
                                                            {users.map((d, i) => <option value={d.id} key={i}>{d.name}</option>)}
                                                        </select>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                <Switch 
                                                    checked={row.seen_by_profile} 
                                                    onChange={() => handleChangeSeen([row.pk], !row.seen_by_profile)} 
                                                    disabled={loading}
                                                    sx={{ 
                                                        marginLeft: "-10px",
                                                        '& .MuiSwitch-switchBase': {
                                                            color: scssVar.danger,
                                                            '& + .MuiSwitch-track': {
                                                                backgroundColor: scssVar.danger,
                                                            },
                                                            "&.Mui-checked": {
                                                                color: scssVar.success,
                                                                '& + .MuiSwitch-track': {
                                                                    backgroundColor: scssVar.success,
                                                                }
                                                            },
                                                            "&.Mui-checked.Mui-disabled": {
                                                                color: scssVar.grey,
                                                            },
                                                            "&.Mui-disabled": {
                                                                color: scssVar.grey,
                                                            },
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily, whiteSpace: "nowrap" }}>
                                                <p style={{ display: "flex", alignItems: "center" }}>
                                                    {!row.verified ? (
                                                        <TooltipStyled 
                                                            arrow 
                                                            title={`${capitalizeFirstLetter(row.lead_first_name)} ${capitalizeFirstLetter(row.lead_last_name)} n'a pas vu le résultat final de l'estimation`}
                                                            enterTouchDelay={0}
                                                            leaveTouchDelay={5000}
                                                        >
                                                            <i
                                                                className="iconoir-info-empty"
                                                                style={{
                                                                    color: scssVar.danger,
                                                                    fontSize: 18,
                                                                    marginRight: 5
                                                                }}
                                                            ></i>
                                                        </TooltipStyled>
                                                    ):(
                                                        <DoneRounded sx={{ color: scssVar.success, fontSize: 18, mr: "5px" }} />
                                                    )}
                                                    <a
                                                        href={`mailto:${row.lead_email}`}
                                                        style={{
                                                            color: "black",
                                                        }}
                                                    >
                                                        {row.lead_email}
                                                    </a>
                                                </p>
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily, whiteSpace: "nowrap" }}>
                                                {capitalizeFirstLetter(row.lead_last_name)} {capitalizeFirstLetter(row.lead_first_name)}
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily, whiteSpace: "nowrap" }}>
                                                <a
                                                    href={`tel:${row.lead_phone}`}
                                                    style={{
                                                        color: "black",
                                                    }}
                                                >
                                                    {row.lead_phone}
                                                </a>
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily, whiteSpace: "nowrap" }}>
                                                {row.type_vente} - {row.surface_reelle_bati} {m2} - {row.nombre_pieces_principales} pièce{row.nombre_pieces_principales > 1 ? "s" : ""}
                                            </TableCell>
                                            <TableCell sx={{ fontFamily: scssVar.fontFamily }}>
                                                {row.contact_ok ? (
                                                    <TooltipStyled 
                                                        arrow
                                                        enterTouchDelay={0}
                                                        leaveTouchDelay={5000}
                                                        title={
                                                            <Box>
                                                                <p>- {row.contact}</p>
                                                                <p>- {row.project || "Projet non renseigné"}</p>
                                                            </Box>
                                                        }
                                                    >
                                                        <Box sx={{ position: "relative", width: 62 }}>
                                                            <Box sx={{ bgcolor: scssVar.success, color: "white", p: 0.4, borderRadius: "4px", width: "100%" }}>
                                                                Oui
                                                            </Box>
                                                            <i
                                                                className="iconoir-info-empty"
                                                                style={{
                                                                    background: "white",
                                                                    borderRadius: "50%",
                                                                    position: "absolute",
                                                                    bottom: -8,
                                                                    right: -8,
                                                                    color: scssVar.success,
                                                                    fontSize: 18,
                                                                }}
                                                            ></i>
                                                        </Box>
                                                    </TooltipStyled>
                                                ):(
                                                    <Box sx={{ bgcolor: scssVar.danger, color: "white", p: 0.4, borderRadius: "4px", width: 62 }}>
                                                        Non
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 51 : 71) * emptyRows }}>
                                    <TableCell colSpan={headCells.length + 1} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Lignes par page :"
                    labelDisplayedRows={({ from, to, count }) => `${from}–${to} sur ${count !== -1 ? count : `plus que ${to}`}`}
                    getItemAriaLabel={(type) => `Aller à la page ${type === "next" ? "suivante" : "précédente"}`}
                    sx={{
                        ".MuiTablePagination-selectLabel": {
                            fontFamily: scssVar.fontFamily
                        },
                        ".MuiTablePagination-select": {
                            fontFamily: scssVar.fontFamily
                        },
                        ".MuiTablePagination-displayedRows": {
                            fontFamily: scssVar.fontFamily
                        }
                    }}
                />
            </Box>
        </Box>
    );
}

export default LeadsGrid