export const appName = "Estimia"
export const appVersion = "3.29.4"
export const appCompany = "Digipi"
console.log(appVersion)

const publicUrl = process.env.PUBLIC_URL || window.location.origin
export const cguUrl = `${publicUrl}/legal/conditions-generales-d-utilisation-estimia.pdf`
export const cgvUrl = `${publicUrl}/legal/conditions-generales-de-vente-estimia.pdf`
export const ppdpUrl = `${publicUrl}/legal/politique-de-protection-de-vos-donnees-personnelles.pdf`
export const mlUrl = `${publicUrl}/legal/mentions-legales-estimia.pdf`

export const faviconUrl = `${publicUrl}/logo192.png`

export const defaultErrorMessage = "Une erreur est survenue, veuillez réessayer."

export const getBackendDateFormat = (date) => {
    const converted = new Date(date)
    const dd = String(converted.getDate()).padStart(2, '0');
    const mm = String(converted.getMonth() + 1).padStart(2, '0');
    const yyyy = String(converted.getFullYear());
    return yyyy + "-" + mm + "-" + dd
}

export function containsOnlyNumbers(str) {
    if (!str) return true
    return /^[0-9]+$/.test(str);
}

export function phoneWithSpaces(val) {
    let res = ""

    for (let i = 0; i < val.length; i ++) {
        if (i % 2 === 0) {
            res = res + val[i]
        } else {
            res = res + val[i] + " "
        }
    }

    return res
}

export function numberWithSpaces(x) {
    if (!x) return "0"
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function capitalizeFirstLetter(string) {
    if (!string) return ""
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const DAYS_SHORT = ["lu", "ma", "me", "je", "ve", "sa", "di"]

export const MONTHS_SHORT = [
    "jan",
    "fév",
    "mars",
    "avr",
    "mai",
    "juin",
    "juil",
    "août",
    "sept",
    "oct",
    "nov",
    "déc"
]

export const MONTHS = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre"
]

// https://stackoverflow.com/questions/54399084/change-locale-in-react-datepicker
export const FR_DATE_FNS = {
    localize: {
        day: n => capitalizeFirstLetter(DAYS_SHORT[n]),
        month: n => capitalizeFirstLetter(MONTHS[n])
    },
    formatLong: {
        date: () => "dd/mm/yyyy"
    }
}

export function getDateToString(date, format, withTime) {
    const formatDate = new Date(date)
    const d = formatDate.getDate()
    const m = 
        format === "numeric" 
            ? (formatDate.getMonth() + 1) 
            : MONTHS[formatDate.getMonth()]
    const y = formatDate.getFullYear()
    const res = 
        format === "numeric" 
            ? (String(d).padStart(2, "0") + "/" + String(m).padStart(2, "0") + "/" + y) 
            : (d + " " + m + " " + y)

    const time = String(formatDate.getHours()).padStart(2, "0") + ":" + String(formatDate.getMinutes()).padStart(2, "0")
    
    return res + (withTime ? " - " + time : "")
}

export const m2 = "m²"
 
export function isInStandaloneMode() { 
    return ('standalone' in window.navigator) && (window.navigator.standalone)
}

export const isPwaInstalled = isInStandaloneMode()

function getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
  
    if (/android/i.test(userAgent)) {
        return "Android";
    }
  
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
  
    return "unknown";
}

export const device = getMobileOperatingSystem()

function getIsInIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const isInIframe = getIsInIframe()

// eslint-disable-next-line
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}

// eslint-disable-next-line
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//setCookie("accessToken", "123456789", 1)

export function getLocalParam(name) {
    return localStorage.getItem(name) || null
}

export function setLocalParam(name, value) {
    localStorage.setItem(name, value)
}

export const franceDepartments = [
    {"nom":"Ain","code":"01","codeRegion":"84"},
    {"nom":"Aisne","code":"02","codeRegion":"32"},
    {"nom":"Allier","code":"03","codeRegion":"84"},
    {"nom":"Alpes-de-Haute-Provence","code":"04","codeRegion":"93"},
    {"nom":"Hautes-Alpes","code":"05","codeRegion":"93"},
    {"nom":"Alpes-Maritimes","code":"06","codeRegion":"93"},
    {"nom":"Ardèche","code":"07","codeRegion":"84"},
    {"nom":"Ardennes","code":"08","codeRegion":"44"},
    {"nom":"Ariège","code":"09","codeRegion":"76"},
    {"nom":"Aube","code":"10","codeRegion":"44"},
    {"nom":"Aude","code":"11","codeRegion":"76"},
    {"nom":"Aveyron","code":"12","codeRegion":"76"},
    {"nom":"Bouches-du-Rhône","code":"13","codeRegion":"93"},
    {"nom":"Calvados","code":"14","codeRegion":"28"},
    {"nom":"Cantal","code":"15","codeRegion":"84"},
    {"nom":"Charente","code":"16","codeRegion":"75"},
    {"nom":"Charente-Maritime","code":"17","codeRegion":"75"},
    {"nom":"Cher","code":"18","codeRegion":"24"},
    {"nom":"Corrèze","code":"19","codeRegion":"75"},
    {"nom":"Corse","code":"20","codeRegion":"94"},
    {"nom":"Côte-d'Or","code":"21","codeRegion":"27"},
    {"nom":"Côtes-d'Armor","code":"22","codeRegion":"53"},
    {"nom":"Creuse","code":"23","codeRegion":"75"},
    {"nom":"Dordogne","code":"24","codeRegion":"75"},
    {"nom":"Doubs","code":"25","codeRegion":"27"},
    {"nom":"Drôme","code":"26","codeRegion":"84"},
    {"nom":"Eure","code":"27","codeRegion":"28"},
    {"nom":"Eure-et-Loir","code":"28","codeRegion":"24"},
    {"nom":"Finistère","code":"29","codeRegion":"53"},
    {"nom":"Gard","code":"30","codeRegion":"76"},
    {"nom":"Haute-Garonne","code":"31","codeRegion":"76"},
    {"nom":"Gers","code":"32","codeRegion":"76"},
    {"nom":"Gironde","code":"33","codeRegion":"75"},
    {"nom":"Hérault","code":"34","codeRegion":"76"},
    {"nom":"Ille-et-Vilaine","code":"35","codeRegion":"53"},
    {"nom":"Indre","code":"36","codeRegion":"24"},
    {"nom":"Indre-et-Loire","code":"37","codeRegion":"24"},
    {"nom":"Isère","code":"38","codeRegion":"84"},
    {"nom":"Jura","code":"39","codeRegion":"27"},
    {"nom":"Landes","code":"40","codeRegion":"75"},
    {"nom":"Loir-et-Cher","code":"41","codeRegion":"24"},
    {"nom":"Loire","code":"42","codeRegion":"84"},
    {"nom":"Haute-Loire","code":"43","codeRegion":"84"},
    {"nom":"Loire-Atlantique","code":"44","codeRegion":"52"},
    {"nom":"Loiret","code":"45","codeRegion":"24"},
    {"nom":"Lot","code":"46","codeRegion":"76"},
    {"nom":"Lot-et-Garonne","code":"47","codeRegion":"75"},
    {"nom":"Lozère","code":"48","codeRegion":"76"},
    {"nom":"Maine-et-Loire","code":"49","codeRegion":"52"},
    {"nom":"Manche","code":"50","codeRegion":"28"},
    {"nom":"Marne","code":"51","codeRegion":"44"},
    {"nom":"Haute-Marne","code":"52","codeRegion":"44"},
    {"nom":"Mayenne","code":"53","codeRegion":"52"},
    {"nom":"Meurthe-et-Moselle","code":"54","codeRegion":"44"},
    {"nom":"Meuse","code":"55","codeRegion":"44"},
    {"nom":"Morbihan","code":"56","codeRegion":"53"},
    {"nom":"Moselle","code":"57","codeRegion":"44"},
    {"nom":"Nièvre","code":"58","codeRegion":"27"},
    {"nom":"Nord","code":"59","codeRegion":"32"},
    {"nom":"Oise","code":"60","codeRegion":"32"},
    {"nom":"Orne","code":"61","codeRegion":"28"},
    {"nom":"Pas-de-Calais","code":"62","codeRegion":"32"},
    {"nom":"Puy-de-Dôme","code":"63","codeRegion":"84"},
    {"nom":"Pyrénées-Atlantiques","code":"64","codeRegion":"75"},
    {"nom":"Hautes-Pyrénées","code":"65","codeRegion":"76"},
    {"nom":"Pyrénées-Orientales","code":"66","codeRegion":"76"},
    {"nom":"Bas-Rhin","code":"67","codeRegion":"44"},
    {"nom":"Haut-Rhin","code":"68","codeRegion":"44"},
    {"nom":"Rhône","code":"69","codeRegion":"84"},
    {"nom":"Haute-Saône","code":"70","codeRegion":"27"},
    {"nom":"Saône-et-Loire","code":"71","codeRegion":"27"},
    {"nom":"Sarthe","code":"72","codeRegion":"52"},
    {"nom":"Savoie","code":"73","codeRegion":"84"},
    {"nom":"Haute-Savoie","code":"74","codeRegion":"84"},
    {"nom":"Paris","code":"75","codeRegion":"11"},
    {"nom":"Seine-Maritime","code":"76","codeRegion":"28"},
    {"nom":"Seine-et-Marne","code":"77","codeRegion":"11"},
    {"nom":"Yvelines","code":"78","codeRegion":"11"},
    {"nom":"Deux-Sèvres","code":"79","codeRegion":"75"},
    {"nom":"Somme","code":"80","codeRegion":"32"},
    {"nom":"Tarn","code":"81","codeRegion":"76"},
    {"nom":"Tarn-et-Garonne","code":"82","codeRegion":"76"},
    {"nom":"Var","code":"83","codeRegion":"93"},
    {"nom":"Vaucluse","code":"84","codeRegion":"93"},
    {"nom":"Vendée","code":"85","codeRegion":"52"},
    {"nom":"Vienne","code":"86","codeRegion":"75"},
    {"nom":"Haute-Vienne","code":"87","codeRegion":"75"},
    {"nom":"Vosges","code":"88","codeRegion":"44"},
    {"nom":"Yonne","code":"89","codeRegion":"27"},
    {"nom":"Territoire de Belfort","code":"90","codeRegion":"27"},
    {"nom":"Essonne","code":"91","codeRegion":"11"},
    {"nom":"Hauts-de-Seine","code":"92","codeRegion":"11"},
    {"nom":"Seine-Saint-Denis","code":"93","codeRegion":"11"},
    {"nom":"Val-de-Marne","code":"94","codeRegion":"11"},
    {"nom":"Val-d'Oise","code":"95","codeRegion":"11"},
    {"nom":"Guadeloupe","code":"971","codeRegion":"01"},
    {"nom":"Martinique","code":"972","codeRegion":"02"},
    {"nom":"Guyane","code":"973","codeRegion":"03"},
    {"nom":"La Réunion","code":"974","codeRegion":"04"},
    {"nom":"Mayotte","code":"976","codeRegion":"06"}
]

export const franceDepartmentsSuggestions = franceDepartments
    .filter(d => Number(d.code) <= 95)
    .map(d => ({ ...d, label: `${d.code} - ${d.nom}` }))

export const getDepartmentByCode = (code) => {
    return franceDepartmentsSuggestions.find(d => d.code === code) || {}
}