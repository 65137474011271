import { useSelector } from "react-redux"
import { numberWithSpaces } from "../../../../common/utils"
import { selectEstimation } from "../../../estimate/selectors"
import GreyBox from "../../components/GreyBox"

const Remuneration = () => {
    const fraisAgence = useSelector(selectEstimation).frais_agence_absolu
    const acquereurVendeur = useSelector(selectEstimation).a_charge_vendeur_ou_acquereur
    const acquereurVendeurText = 
        acquereurVendeur === "acquereur"
            ? "de l'acquéreur"
            : "du vendeur"

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{
                fontSize: 13,
                lineHeight: "13px",
                marginRight: 3
            }}>
                Il est entendu que ladite rémunération sera mise contractuellement à la charge
            </p>
            <GreyBox style={{ 
                padding: "0 4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                {acquereurVendeurText}
            </GreyBox>
            <p style={{
                fontSize: 13,
                lineHeight: "13px",
                marginRight: 3,
                marginLeft: 40
            }}>
                Honoraires :
            </p>
            <GreyBox style={{ 
                padding: "0 4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                {numberWithSpaces(fraisAgence)} €
            </GreyBox>
        </div>
    )
}

export default Remuneration