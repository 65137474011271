import { scssVar } from "../../app/scssVar"
import Button from "./Button"

const BottomBarDrawer = ({ onClose }) => {
    return (
        <div style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 1301,
            backgroundColor: "white"
        }}>
            <div style={{
                width: "100%",
                height: `calc(65px + ${scssVar.sab})`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Button
                    variant="light"
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </div>
        </div>
    )
}

export default BottomBarDrawer