import { useState } from "react"
import { useDispatch } from "react-redux"
import Screen1 from "./Screen1"
import Screen2 from "./Screen2"

const FirstVisitScreens = () => {
    const dispatch = useDispatch()
    const [currentScreen, setCurrentScreen] = useState(0)

    const handleGoToScreen2 = () => {
        setCurrentScreen(1)
    }

    const handleClickOnContinue = () => {
        dispatch({ type: "auth/user_finish_first_visit" })
    }

    if (currentScreen === 1) {
        return <Screen2 onClick={handleClickOnContinue} />
    }

    return <Screen1 onClick={handleGoToScreen2} />
}

export default FirstVisitScreens