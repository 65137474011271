import { Box } from "@mui/material"
import MobileNav from "../../common/components/nav/MobileNav"
import EstimationsFilters from "./components/EstimationsFilters"
import EstimationsItems from "./components/EstimationsItems"
import EstimationsFloatingButtons from "./components/EstimationsFloatingButtons"
import EstimationsStats from "./components/EstimationsStats"
import EstimationsTitle from "./components/EstimationsTitle"
import EstimationsTopBar from "./components/EstimationsTopBar"
import { Fragment } from "react"
import PageWrapper from "../../common/components/PageWrapper"
import { useSelector } from "react-redux"

const Estimations = () => {
    const checkedEstimations = useSelector(state => state.estimations.estimations.selected)

    return (
        <Fragment>
            <PageWrapper
                variant="estimations"
                left={
                    <Fragment>
                        <Box sx={{ m: { xs: 15, sm: 0 } }} />
                        <EstimationsTopBar />
                        <Box sx={{ m: { xs: 2, sm: 3 } }} />
                        <EstimationsTitle />
                        <EstimationsFilters />
                        <Box sx={{ m: { xs: 0, sm: 4 } }} />
                        <EstimationsItems />
                        <EstimationsFloatingButtons />

                        {Boolean(checkedEstimations.length) && (
                            <Box sx={{ 
                                m: { 
                                    xs: 0,
                                    sm: 6,
                                    lg: 3
                                } 
                            }} />
                        )}
                    </Fragment>
                }
                right={<EstimationsStats />}
            />
            <MobileNav />
        </Fragment>
    )
}

export default Estimations