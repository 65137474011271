import { Box, Grid } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../common/components/Button"
import FieldControl from "../../../common/components/FieldControl"
import useAuth from "../../auth/hooks/useAuth"
import { updateProfile } from "../actions"
import AccountSection from "../components/AccountSection"

const Profile = () => {
    const dispatch = useDispatch()
    const updateLoading = useSelector(state => state.auth.user.updateLoading)
    const loading = useSelector(state => state.auth.user.loading)
    const error = useSelector(state => state.auth.user.error)
    const { user } = useAuth()
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        status: "",
        phone: "",
        rsac: ""
    })

    // const isEqual = (field) => state[field] === user[field]
    // const isNull = (field) => user[field] === null

    const notModified = (
        state.firstName === user.firstName
        && state.lastName === user.lastName
        && state.email === user.email
        && state.gender === user.gender
        && state.status === user.status
        && state.phone === user.phone
        && state.rsac === user.rsac
    )

    const emptyField = (
        !state.firstName.trim().length
        || !state.lastName.trim().length
        || !state.email.trim().length
    )

    useEffect(() => {
        setState(s => ({
            ...s,
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email || "",
            gender: user.gender || "",
            status: user.status || "",
            phone: user.phone || "",
            rsac: user.rsac || ""
        }))
    }, [user.firstName, user.lastName, user.email, user.gender, user.status, user.phone, user.rsac])

    const handleChange = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState({
            ...state,
            [name]: value
        })
    }

    const handleCancel = () => {
        setState(s => ({
            ...s,
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email || "",
            gender: user.gender || "",
            status: user.status || "",
            phone: user.phone || "",
            rsac: user.rsac || ""
        }))
    }

    const handleSave = (e) => {
        e.preventDefault()

        dispatch(updateProfile(
            user.id,
            state.firstName,
            state.lastName,
            state.email,
            user.isTester,
            state.gender,
            state.status,
            state.phone,
            state.rsac
        ))
    }

    return (
        <AccountSection 
            title="Votre profil"
            loading={loading}
            error={error?.data ? "Une erreur est survenue lors de la récupération de votre profil, veuillez réessayer." : null}
        >
            <Box 
                sx={{ 
                    flexGrow: 1, 
                    mb: "30px" 
                }}
                component="form"
                onSubmit={handleSave}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Prénom"
                            placeholder="Prénom"
                            isSuccess={state.firstName}
                            onChange={handleChange}
                            value={state.firstName || ""}
                            name="firstName"
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Nom"
                            placeholder="Nom"
                            isSuccess={state.lastName}
                            onChange={handleChange}
                            value={state.lastName || ""}
                            name="lastName"
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Adresse email"
                            placeholder="Adresse email"
                            isSuccess={state.email}
                            onChange={handleChange}
                            value={state.email || ""}
                            name="email"
                            required
                            type="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Titre"
                            isSuccess={state.gender}
                            onChange={handleChange}
                            value={state.gender || ""}
                            name="gender"
                            type="select"
                            options={
                                <Fragment>
                                    <option value="">Titre</option>
                                    <option value="male">M</option>
                                    <option value="female">Mme</option>
                                </Fragment>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Statut"
                            isSuccess={state.status}
                            onChange={handleChange}
                            value={state.status || ""}
                            name="status"
                            type="select"
                            options={
                                <Fragment>
                                    <option value="">Statut</option>
                                    <option value="agent_immo">Agent immobilier</option>
                                    <option value="entr_indiv">Entrepreneur individuel</option>
                                    <option value="nego_sal">Négociateur salarié</option>
                                </Fragment>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Téléphone mobile"
                            placeholder="Téléphone mobile"
                            isSuccess={state.phone}
                            onChange={handleChange}
                            value={state.phone || ""}
                            name="phone"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FieldControl
                            label="Numéro RSAC"
                            placeholder="Numéro RSAC"
                            isSuccess={state.rsac}
                            onChange={handleChange}
                            value={state.rsac || ""}
                            name="rsac"
                            type="number"
                        />
                    </Grid>
                </Grid>
                <Box sx={{ 
                    mt: "35px",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }
                }}>
                    <Button
                        variant="light"
                        onClick={handleCancel}
                        type="button"
                        disabled={notModified}
                        sx={{
                            pl: 7,
                            pr: 7
                        }}
                    >
                        Annuler
                    </Button>
                    <Box sx={{ m: 1 }} />
                    <Button
                        loading={updateLoading}
                        disabled={updateLoading || notModified || emptyField}
                        sx={{
                            pl: 7,
                            pr: 7
                        }}
                    >
                        Enregistrer
                    </Button>
                </Box>
            </Box>
        </AccountSection>
    )
}

export default Profile