import { useDispatch, useSelector } from "react-redux"
import { getGalleryBlobs, selectGalleryBlobs } from "../photosSlice"
import { useEffect } from "react"

const useGalleryBlobs = () => {
    const dispatch = useDispatch()
    const pics = useSelector(selectGalleryBlobs)

    useEffect(() => {
        dispatch(getGalleryBlobs())
    }, [dispatch])

    return pics
}

export default useGalleryBlobs