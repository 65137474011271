import { scssVar } from "../../../../app/scssVar"
import { useSelector } from "react-redux"
import { selectApiKpis, selectApiKpisAttributionLeads } from "../../apiOfferSlice"
import StatBox from "../../../../common/components/dashboard/StatBox"
import ProgressBar3 from "../../../../common/components/dashboard/progress/ProgressBar3"

const AttributionLeads = () => {
    const { attributed, unattributed } = useSelector(selectApiKpisAttributionLeads)
    const { isLoading } = useSelector(selectApiKpis)

    return (
        <StatBox
            bgcolor={scssVar.greyLighter}
            icon="iconoir-profile-circled"
            title="Attribution des leads"
            isLoading={isLoading}
        >
            <ProgressBar3
                label={{ left: "Attribués", right: "Non attribués" }}
                value={{ left: attributed, right: unattributed }}
                percent={{ left: attributed / (attributed + unattributed) * 100, right: unattributed / (attributed + unattributed) * 100 }}
            />
        </StatBox>
    )
}

export default AttributionLeads