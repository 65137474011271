const InputAutoComplete = ({
    matches,
    isSuccess,
    onChange,
    value,
    onKeyDown,
    onReset,
    activeIndex,
    onSelect,
    working,
    className,
    dropDownItemStyle = () => {},
}) => {
    const inputCL = className || `input ${isSuccess && "is-success"}`

    return (
        <div className="control has-icons-right">
            <div className={`dropdown ${matches.length > 0 && "is-active"}`} style={{ width: "100%" }}>
                <div className="dropdown-trigger" style={{ width: "100%" }}>
                    <input
                        type="text"
                        className={inputCL}
                        onChange={onChange}
                        value={value || ""}
                        onKeyDown={onKeyDown}
                        disabled={working}
                        placeholder="Entrez l'adresse du bien"
                        autoComplete="off"
                        id="address-field" 
                        style={{ width: "100%" }}
                    />
                    {(value || isSuccess) && !working && (
                        <span 
                            id="clear-address-search" 
                            toggle="#address-field" 
                            className="icon is-small is-right" 
                            onClick={onReset}
                            style={{ cursor: "pointer", pointerEvents: "all" }}
                        >
                            <i className="delete" style={{ backgroundColor: "black" }}></i>
                        </span>
                    )}
                </div>
                <div className="dropdown-menu" style={{ width: "100%" }}>
                    {matches.length > 0 && (
                        <div className="dropdown-content" style={{ width: "100%" }}>
                            {matches.map((match, index) => (
                                <a
                                    key={index}
                                    href="/"
                                    onClick={event => onSelect(event, match)}
                                    className={`dropdown-item ${index === activeIndex && "is-active"}`}
                                    style={Object.assign({}, { 
                                        whiteSpace: "normal"
                                    }, dropDownItemStyle(index) || {})}
                                >
                                    <span style={{ fontSize: 14 }}>
                                        {match.adresse}
                                    </span>
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default InputAutoComplete