import { useSelector } from "react-redux"
import { getDateToString, numberWithSpaces } from "../../../../common/utils"
import { selectCosts, selectValues } from "../../../estimate/selectors"
import Margin from "../../components/Margin"
import Title from "../components/Title"
import Wrapper from "../../components/Wrapper"

const Page06 = ({ scale }) => {
    const userColor = useSelector(state => state.account.org.color)
    const estimation = useSelector(state => state.estimate.estimation)
    const { prixNetVendeur } = useSelector(selectValues)
    const { prixPresentation, coutGlobal } = useSelector(selectCosts)

    const name = estimation.name
    const createdOn = estimation.created_on
    const budgetAn = estimation.total_charge_an + estimation.total_cout_energetique_an + estimation.cout_eau_an
    const budgetMois = estimation.total_charge_mois + estimation.total_cout_energetique_mois + estimation.cout_eau_mois
    
    return (
        <Wrapper id="synthesis_p6" scale={scale}>

            <Margin m={55} />

            <Title>
                NOTRE ÉVALUATION
            </Title>

            <Margin m={55} />

            <p style={{
                lineHeight: "32px",
                fontSize: 23
            }}>
                <span style={{ color: "black" }}>
                    Sur la base des données entrées,
                </span>
                <b style={{ color: "black" }}>
                    {" "} nous estimons la valeur du bien
                </b>
                <b style={{ color: userColor }}>
                    {" "}«{name}»
                </b>
                <span style={{ color: "black" }}>
                    {" "}en date du
                </span>
                <span style={{ color: userColor }}>
                    {" "}{getDateToString(createdOn)}
                </span>
                <span style={{ color: "black" }}>
                    , à la somme de :
                </span>
            </p>

            <Margin m={80} />

            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    width: "fit-content",
                    padding: "30px 50px 44px",
                    textAlign: "center",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "20px"
                }}>
                    <p style={{
                        fontSize: 21,
                        color: "black",
                        marginBottom: 35,
                    }}>
                        Prix Net Vendeur
                    </p>
                    <p style={{
                        fontSize: 65,
                        fontWeight: 700,
                        color: userColor
                    }}>
                        {numberWithSpaces(prixNetVendeur)} €
                    </p>
                </div>
            </div>

            <Margin m={86} />

            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "22px",
            }}>
                <div style={{
                    border: "1px solid grey",
                    borderRadius: "20px",
                    padding: "28px 24px",
                    fontSize: 24,
                    textAlign: "center"
                }}>
                    <p style={{
                        marginBottom: 8,
                        lineHeight: "32px"
                    }}>
                        Prix de présentation
                        <br/>suggéré
                    </p>
                    <p style={{
                        fontWeight: 700,
                        color: userColor,
                        marginBottom: 25,
                        fontSize: 25
                    }}>
                        {numberWithSpaces(prixPresentation)} €
                    </p>
                    <p style={{
                        marginBottom: 8
                    }}>
                        Budget total acheteur
                    </p>
                    <p style={{
                        fontWeight: 700,
                        color: userColor,
                        fontSize: 25
                    }}>
                        {numberWithSpaces(coutGlobal)} €
                    </p>
                </div>
                <div style={{
                    border: "1px solid grey",
                    borderRadius: "20px",
                    padding: "28px 24px",
                    fontSize: 24,
                    textAlign: "center"
                }}>
                    <p style={{
                        marginBottom: 12
                    }}>
                        Budget d’Usage Estimé
                    </p>
                    <p style={{
                        fontSize: 16,
                        marginBottom: 4
                    }}>
                        Par an
                    </p>
                    <p style={{
                        fontWeight: 700,
                        marginBottom: 12,
                        color: userColor,
                    }}>
                        {numberWithSpaces(budgetAn)} €
                    </p>
                    <p style={{
                        fontSize: 16,
                        marginBottom: 4
                    }}>
                        Par mois
                    </p>
                    <p style={{
                        fontWeight: 700,
                        color: userColor,
                    }}>
                        {numberWithSpaces(budgetMois)} €
                    </p>
                </div>
            </div>

            {/* <Margin m={70} />

            <div style={{
                display: "flex",
                alignItems: "center",
                padding: "25px 40px",
                background: "#f8f8f8",
            }}>
                <div style={{
                    marginRight: 10,
                }}>
                    <svg 
                        width="65" 
                        height="65"
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 139.58 131.16"
                    >
                        <g fill={userColor}>
                            <path d="M67.71,125A60.44,60.44,0,1,0,7.28,64.58,60.42,60.42,0,0,0,67.71,125" />
                            <path 
                                fill="#fff"
                                d="M92.75,51.56c2.9-2,4.08-4.94,3.27-8a7.24,7.24,0,0,0-6-5.27,6.79,6.79,0,0,0-7,3.46c5.37,1.11,8.59,4.4,9.71,9.78M59.38,80.7c.19-.83-.83-.44-1.43-.45-3.32-.1-3.69-.46-3.7-3.79a.55.55,0,0,0,0-.18c-.19-.63.52-1.86-.81-1.74s-2.85-1-3.55,1-1.31,3.87-2.12,5.74a1.85,1.85,0,0,0,.88,2.76,6.87,6.87,0,0,1,2.54,3c.19.37.29.88.86.71,2-.59,3.91-1.19,5.85-1.82.37-.11.5-.5.58-.86.27-1.3.54-2.59.91-4.39M54.47,70c.36.32.8.16,1.19.17,2.22.09,2.86.75,2.89,3a13.81,13.81,0,0,1,0,1.44c-.1,1,.21,1.35,1.28,1.39,4.31.13,4.31.17,4.24,4.7,2.15-2.19,4.11-4.2,6.09-6.19,5.4-5.41,10.82-10.79,16.2-16.22A6.8,6.8,0,0,0,88.16,51a6.92,6.92,0,0,0-5.72-4.9c-2.82-.5-5,.79-6.91,2.71L55.06,69.15c-.24.23-.62.4-.59.85m46-24.5a10.56,10.56,0,0,1-3.05,7.68Q79.75,71,62,88.7a4.48,4.48,0,0,1-2,1.13c-5.81,1.76-11.61,3.52-17.41,5.32-1.1.34-2.12.55-3-.4s-.54-1.88-.17-2.88Q43,82.14,46.48,72.39a5.85,5.85,0,0,1,1.46-2.23Q64.41,53.81,80.87,37.45a11.42,11.42,0,0,1,19.64,8"
                            />
                        </g>
                    </svg>
                </div>
                <div>
                    <p style={{
                        fontSize: 15
                    }}>
                        Vous pouvez évidemment toujours aller modifier les paramètres subjectifs de
                        <br/>
                        votre choix et ainsi affiner les résultats.
                    </p>
                </div>
            </div> */}
        </Wrapper>
    )
}

export default Page06