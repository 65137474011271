import { scssVar } from "../../app/scssVar";
import { styled } from '@mui/material/styles';
import { Tab as MuiTab, Tabs as MuiTabs, Box, Typography } from "@mui/material";

export const Tabs = styled(MuiTabs)({
    "& .MuiTabs-scrollButtons": {
        display: "none",
    },
    borderBottom: `1px solid ${scssVar.primaryLighter}`,
    '& .MuiTabs-indicator': {
        backgroundColor: scssVar.primary,
    },
});
  
export const Tab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 400,
    color: scssVar.greyDark,
    fontFamily: scssVar.fontFamily,
    fontSize: "16px",
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: "20px",
    '&:hover': {
        color: scssVar.primary,
        opacity: 1,
    },
    '&.Mui-selected': {
        color: scssVar.primary,
        fontWeight: 600
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

export const TabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box sx={{ p: "30px 0 0" }}>
                {children}
            </Box>
        )}
    </div>
)

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export const TabTitle = ({ title, subTitle, bottomBarColor }) => {
    return (
        <Box sx={{
            position: "relative",
            mb: 5
        }}>
            <Box sx={{
                height: "8px",
                bgcolor: bottomBarColor || "#D5C6EC",
                width: "104px",
                borderRadius: "50px",
                position: "absolute",
                top: "26px",
            }}></Box>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: "black",
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: 700,
                position: "relative"
            }}>
                {title}
            </Typography>
            <Typography sx={{
                mt: 1,
                fontFamily: scssVar.fontFamily,
                color: scssVar.greyDark,
                fontSize: "16px",
                lineHeight: "24px",
            }}>
                {subTitle}
            </Typography>
        </Box>
    )
}