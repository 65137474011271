import { Fragment, useEffect, useState } from "react"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import { Dialog, DialogContent } from "@mui/material"
import { useSelector } from "react-redux"
import { selectAddress, selectDVF, selectHousing, selectHousingPosition, selectValues } from "../estimate/selectors"
import { divIcon } from 'leaflet';
import { scssVar } from "../../app/scssVar"
import { getDateToString, m2, numberWithSpaces } from "../../common/utils"
import { ClearRounded, FullscreenRounded } from "@mui/icons-material"
import "./LeafletMap.css"

function formatPrice(price) {
    const priceToString = String(price)
    if (price >= 1000000) {
        const a = priceToString.slice(0, -5)
        const b = a[0]
        const c = a[1]
        return b + (c !== "0" ? ("," + c) : "") + "M€"
    } else {
        return priceToString.slice(0, -3) + "K€"
    }
}

const markerShadow = "0 2px 4px 1px rgba(0,0,0,0.18)"

const createClusterStyle = (cluster, clicked) => {
    const isClicked = JSON.stringify(cluster) === JSON.stringify(clicked)
    const background = isClicked ? "rgba(241, 95, 18, 0.8)" : "rgba(69, 39, 116, 0.8)"
    const scale = isClicked ? 1.2 : 1

    return `
        <div style='
            font-family: ${scssVar.fontFamily}; 
            box-shadow: ${markerShadow};
            -webkit-box-shadow: ${markerShadow};
            background: ${background};
            scale: ${scale};
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            border: 1px solid white;
            color: white;
            font-size: 18px;
        '>
            <b>${cluster.features.length}</b>
        </div>
    `
}

const createStyle = (feature, clicked) => {
    const { isCurrentHousing, value, surface } = feature
    const isClicked = JSON.stringify(feature) === JSON.stringify(clicked)

    const background = 
        isClicked 
            ? "rgba(241, 95, 18, 0.8)"
            : isCurrentHousing
                ? scssVar.success
                : "rgba(69, 39, 116, 0.8)"

    const scale = isClicked ? 1.2 : 1

    return `
        <div 
            class=${isCurrentHousing ? "custom-current-housing" : "custom-feature"} 
            style='
                font-family: ${scssVar.fontFamily}; 
                box-shadow: ${markerShadow};
                -webkit-box-shadow: ${markerShadow};
                background: ${background};
                scale: ${scale};
                border: 1px solid white;
                color: white;
                width: 61px; 
                height: 16px; 
                border-radius: 16px; 
                display: flex; 
                align-items: center; 
                justify-content: center; 
            '
        >
            <b style='margin: 0; font-size: 8px; line-height: 8px'>${value ? formatPrice(value) : "0€"}</b>
            <p style='margin: 0; font-size: 7px; line-height: 7px'> ・ </p>
            <i style='margin: 0; font-size: 5px; line-height: 5px'>${surface || 0}${m2}</i>
        </div>
    `
}

const MarkerFeature = ({ 
    position, 
    eventHandlers, 
    iconHtml, 
    isDefault, 
    children, 
    zIndex 
}) => {
    if (isDefault) {
        return <Marker position={position} />
    }

    return (
        <Marker
            position={position} 
            eventHandlers={eventHandlers}
            icon={divIcon({ className: "fake-class-to-remove-default-square", html: iconHtml })} 
            zIndexOffset={zIndex}
        >
            {children}
        </Marker>
    )
}

const PopupTitle = ({ title }) => {
    return (
        <p style={{ margin: "0 0 8px 5px", fontWeight: 600, color: scssVar.black, fontSize: 12 }}>
            {title}
        </p>
    )
}

const PopupContent = ({ properties, style }) => {
    return (
        <div style={Object.assign({}, {
            padding: 5,
            background: scssVar.primaryLight,
            color: scssVar.primary,
            borderRadius: 8,
        }, style?.wrapper)}>
            {properties.record_date ? (
                <p style={{ margin: "0 0 3px" }}>
                    <span style={{ fontWeight: 700 }}>{properties.address}</span>
                    {" "}
                    {`(vendu en ${getDateToString(properties.record_date).substring(1)})`}
                </p>
            ):(
                <p style={{ margin: "0 0 3px", fontWeight: 700 }}>{properties.address}</p>
            )}
            <p style={{ margin: 0 }}>
                <span style={{ background: scssVar.primary, color: "white", fontWeight: 600, padding: "0 5px", borderRadius: 10 }}>
                    {properties.value ? numberWithSpaces(properties.value) : "0"} € 
                </span>
                {" "}
                <span style={{ background: scssVar.primary, color: "white", fontWeight: 600, padding: "0 5px", borderRadius: 10 }}>
                    {properties.rooms || "0"} pièce{properties.rooms > 1 ? "s" : ""}
                </span>
                {" "}
                <span style={{ background: scssVar.primary, color: "white", fontWeight: 600, padding: "0 5px", borderRadius: 10 }}>
                    {properties.surface || "0"} {m2}
                </span>
            </p>
        </div>
    )
}

const PopupMarker = ({ children }) => {
    return (
        <Popup closeButton={false} autoPan={false}>
            <div style={{
                padding: 10,
                fontFamily: scssVar.fontFamily,
                maxHeight: 310,
                maxWidth: 300,
                overflowY: "auto"
            }}>
                {children}
            </div>
        </Popup>
    )
}

 // Fonction pour calculer la distance entre deux points en coordonnées géographiques
 function distance(point1, point2) {
    const lon1 = point1.coordinates[0];
    const lat1 = point1.coordinates[1];
    const lon2 = point2.coordinates[0];
    const lat2 = point2.coordinates[1];

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344; // Convertir en kilomètres

    return dist;
}

// Fonction pour regrouper les points en clusters
function clusterPoints(geojsonData, maxClusterDistance) {
    const clusters = [];

    geojsonData.features?.forEach(function (feature) {
        let clustered = false;

        for (let i = 0; i < clusters.length; i++) {
            const cluster = clusters[i];

            if (distance(cluster.center, feature.geometry) <= maxClusterDistance) {
                cluster.features.push(feature);
                clustered = true;
                break;
            }
        }

        if (!clustered) {
            clusters.push({
                center: feature.geometry,
                features: [feature],
            });
        }
    });

    return clusters;
}

// retourne la distance en kms
const getDistance = (zoom) => {
    if (zoom >= 15) return .1
    else if (zoom < 15 && zoom >= 14) return .3
    else if (zoom < 14 && zoom >= 13) return .5
    else return 1 // < 13
}

const Features = () => {
    const map = useMap()
    const housingPosition = useSelector(selectHousingPosition)
    const { rue, codePostal, commune } = useSelector(selectAddress)
    const housingRooms = useSelector(selectHousing).nombre_pieces_principales
    const housingSurface = useSelector(selectHousing).surface_reelle_bati
    const { estimationValue } = useSelector(selectValues)
    const dvf = useSelector(selectDVF)
    const [clicked, setClicked] = useState(null)
    const [clustered, setClustered] = useState([])
    const [distance, setDistance] = useState(.3) // le zoom est de base à 14 donc .3

    const currentHousingProperties = {
        isCurrentHousing: true,
        address: `${rue} ${codePostal} ${commune}`,
        value: estimationValue,
        rooms: housingRooms,
        surface: housingSurface,
    }

    useEffect(() => {
        setClustered(clusterPoints(dvf, distance))
    }, [dvf, distance])

    map.on("zoom", (e) => {
        setDistance(getDistance(e.target.getZoom()))
    })

    map.on("popupclose", () => {
        setClicked(null)
    })

    return (
        <Fragment>
            <MarkerFeature
                zIndex={1000}
                position={housingPosition} 
                iconHtml={createStyle(currentHousingProperties, clicked)}
                eventHandlers={{
                    click: () => {
                        setClicked(currentHousingProperties)
                    },
                }}
            >
                <PopupMarker>
                    <PopupTitle title="Votre estimation" />
                    <PopupContent properties={currentHousingProperties} />
                </PopupMarker>
            </MarkerFeature>
            {clustered.filter(d => d.features.length > 1).map(d => {
                return (
                    <MarkerFeature
                        key={JSON.stringify(d.features)}
                        position={[d.center.coordinates[1], d.center.coordinates[0]]} 
                        iconHtml={createClusterStyle(d, clicked)}
                        eventHandlers={{
                            click: () => {
                                setClicked(d)
                            },
                        }}
                    >
                        <PopupMarker>
                            <PopupTitle title={`${d.features.length} biens dans un rayon de ${(distance * 1000) / 2} m`} />
                            {d.features.map((f, j) => 
                                <PopupContent 
                                    key={j} 
                                    properties={f.properties} 
                                    style={{
                                        wrapper: {
                                            marginBottom: j + 1 < d.features.length ? "10px" : 0
                                        }
                                    }}
                                />
                            )}
                        </PopupMarker>
                    </MarkerFeature>
                )
            })}
            {clustered.filter(d => d.features.length === 1).map(d => {
                const properties = d.features[0].properties
                return (
                    <MarkerFeature
                        key={JSON.stringify(properties)}
                        position={[d.center.coordinates[1], d.center.coordinates[0]]} 
                        iconHtml={createStyle(properties, clicked)}
                        eventHandlers={{
                            click: () => {
                                setClicked(properties)
                            },
                        }}
                    >
                        <PopupMarker>
                            <PopupContent properties={properties} />
                        </PopupMarker>
                    </MarkerFeature>
                )
            })}
        </Fragment>
    )
}

function getControls() {
    return document.querySelector(".leaflet-control-container")
}

function getMarker() {
    return document.querySelector(".leaflet-marker-icon")
}

const Map = ({ 
    height,
    isFullscreen,
    setIsFullscreen,
    withFeatures,
    readOnly
}) => {
    const housingPosition = useSelector(selectHousingPosition)

    useEffect(() => {
        let interval

        if (readOnly) {
            interval = setInterval(() => {
                const controls = getControls()
                const marker = getMarker()
                if (controls && marker) {
                    controls.style.display = "none"
                    marker.style.cursor = "default"
                    clearInterval(interval)
                }
            }, [100])
        }
        
        return () => {
            clearInterval(interval)
        }
    }, [readOnly])

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen)
    }

    return (
        <div style={{ position: "relative", height, width: "100%" }}>
            {!readOnly && (
                <button onClick={toggleFullscreen} className="custom-fullscreen-button">
                    {isFullscreen ? <ClearRounded /> : <FullscreenRounded />}
                </button>
            )}
            <MapContainer
                center={housingPosition} 
                zoom={14} 
                minZoom={12}
                scrollWheelZoom={false} 
                style={{ 
                    height,
                    pointerEvents: readOnly ? "none" : "initial"
                }} 
            >
                <TileLayer 
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                    // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                />
                {withFeatures ? (
                    <Features />
                ):(
                    <MarkerFeature isDefault position={housingPosition} />
                )}
            </MapContainer>
        </div>
    )
}

const LeafletMap = ({ height, withFeatures, readOnly }) => {
    const [isFullscreen, setIsFullscreen] = useState(false)

    const _height = height || 500

    if (isFullscreen) {
        return (
            <Fragment>
                <div style={{ width: "100%", height: _height }} />
                <Dialog open fullScreen>
                    <DialogContent style={{ margin: 0, padding: 0 }}>
                        <Map 
                            isFullscreen
                            setIsFullscreen={setIsFullscreen} 
                            height="100%"
                            withFeatures
                        />
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    }

    return (
        <Map 
            isFullscreen={false} 
            setIsFullscreen={setIsFullscreen}
            height={_height}
            withFeatures={withFeatures}
            readOnly={readOnly}
        />
    )
}

export default LeafletMap