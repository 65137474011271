import { Avatar, Collapse, List, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { Fragment, useState } from "react"
import { scssVar } from "../../../app/scssVar"
import ActivateUser from "./ActivateUser"
import ChangeUserTeam from "./ChangeUserTeam"
import DeleteUser from "./DeleteUser"

const initialState = {
    collapsed: false
}

const UserTeam = ({ user, team, usersCount }) => {
    const [state, setState] = useState(initialState)

    const handleCollapse = () => {
        setState({ ...state, collapsed: !state.collapsed })
    }

    return (
        <Fragment>
            <ListItemButton
                onClick={handleCollapse}
                sx={{
                    borderBottom: 1,
                    borderColor: scssVar.greyLight,
                    pl: { xs: 0, sm: 4 },
                    pr: { xs: 0, sm: 4 },
                }}
            >
                <ListItemAvatar>
                    <Avatar sx={{
                        bgcolor: user.active ? scssVar.primary : scssVar.grey,
                        color: "white"
                    }}>
                        <i className="iconoir-profile-circled" style={{ fontSize: 24 }} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary={user.name}
                    secondary={user.email} 
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black",
                        },
                        "& .MuiListItemText-secondary": {
                            color: scssVar.greyDark
                        }
                    }}
                />
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark,
                        transform: `rotate(${state.collapsed ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </ListItemButton>
            <Collapse in={state.collapsed} timeout="auto" unmountOnExit>
                <List sx={{ 
                    pt: 0,
                    pb: 0,
                    pl: { xs: 1, sm: 6 },
                    pr: { xs: 1, sm: 6 },
                }}>
                    {!user.active && (
                        <ActivateUser user={user} team={team} usersCount={usersCount} />
                    )}
                    <ChangeUserTeam user={user} team={team} />
                    <DeleteUser user={user} team={team} />
                </List>
            </Collapse>
        </Fragment>
    )
}

export default UserTeam