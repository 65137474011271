import MobileNav from "../../../common/components/nav/MobileNav"
import { ReactComponent as SmileyContent } from "../../../common/assets/smiley_content.svg"
import scssVar from "../../../app/App.scss"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { Fragment, useState } from "react"
import DesktopNav from "../../../common/components/nav/DesktopNav"
import { saveProgress } from "../actions"
import { Box, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import ResultTitle from "./components/ResultTitle"
import ResultSubtitle from "./components/ResultSubtitle"
import ResultCard from "./components/ResultCard"
import UsageCost from "../steps/components/UsageCost"
import StepTotalCostForm from "../steps/components/StepTotalCostForm"
import Result3Buttons from "./components/Result3Buttons"
import PageWrapper from "../../../common/components/PageWrapper"
import Button from "../../../common/components/Button"

const ResultFinal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const noHousingId = useSelector(state => state.estimate.housing.id) === null
    const working = useSelector(state => state.estimate.working)
    const [state, setState] = useState({
        openTotalCost: false
    })
    const stepName = "ResultFinal"

    const handleNewEstimation = () => {
        navigate("/estimer")
        dispatch({ type: "estimate/reset" })
    }

    const handleGoPrev = () => {
        navigate("/estimer/step-12")
        dispatch(saveProgress("Step12"))
    }

    const handleGoSynthesis = () => {
        navigate("/estimer/synthese")
        dispatch(saveProgress("Synthesis"))
    }

    if (noHousingId) {
        return <Navigate to="/" />
    }

    return (
        <Fragment>
            <PageWrapper variant="result">
                <MobileNav />
                <Box sx={{ m: { xs: 1, sm: 0 } }} />
                <DesktopNav />
                <Box sx={{ m: { xs: 4, sm: 4 } }} />
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}>
                    <ResultTitle title="Votre évaluation finale" />
                    <ResultSubtitle
                        subtitle="Vous avez répondu à la totalité du formulaire !"
                        smiley={<SmileyContent />}
                        text="L’évaluation est complète à"
                        percent="100%"
                        color={scssVar.green}
                    />
                    <Result3Buttons
                        onClickNewEstimation={handleNewEstimation}
                        onClickGoSynthesis={handleGoSynthesis}
                        onClickPrev={handleGoPrev}
                    />
                    <ResultCard />
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        maxWidth: { 
                            xs: "100%", 
                            sm: "100%", 
                            md: "780px", 
                            lg: "890px"
                        },
                        "@media (max-width: 750px)": {
                            flexDirection: "column"
                        }
                    }}>
                        <Box sx={{ 
                            width: "50%",
                            "@media (max-width: 750px)": {
                                width: "100%"
                            }
                        }}>
                            <UsageCost yearly />
                        </Box>
                        <Box sx={{
                            width: "50%",
                            padding: "24px",
                            bgcolor: "white",
                            borderRadius: "16px",
                            ml: 2,
                            "@media (max-width: 750px)": {
                                ml: 0,
                                mt: 2,
                                width: "100%"
                            }
                        }}>
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                color: scssVar.greyDarker,
                                mb: 1
                            }}>
                                Budget total
                            </Typography>
                            <Button
                                onClick={() => setState({ ...state, openTotalCost: true })}
                                variant="success"
                                disabled={working}
                                sx={{
                                    width: "100%",
                                    maxWidth: 270,
                                    pl: 0,
                                    pr: 0
                                }}
                            >
                                Afficher le budget total
                                <i 
                                    className="iconoir-arrow-right"
                                    style={{
                                        fontSize: 24,
                                        marginLeft: 5
                                    }}
                                ></i>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </PageWrapper>
            <Dialog 
                open={state.openTotalCost} 
                onClose={() => setState({ ...state, openTotalCost: false })}
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                    <StepTotalCostForm stepName={stepName} />
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="light"
                        onClick={() => setState({ ...state, openTotalCost: false })}
                    >
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ResultFinal