import { Box, Typography } from "@mui/material"
import { scssVar } from "../../../app/scssVar"

const SectionTitle = ({ n, children, id, isDanger }) => {
    return (
        <Box id={id} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{
                width: 32,
                minWidth: 32,
                maxWidth: 32,
                height: 32,
                minHeight: 32,
                maxHeight: 32,
                bgcolor: isDanger ? scssVar.danger : scssVar.primary,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 15,
                fontWeight: 600,
                borderRadius: "32px",
                mr: 1
            }}>
                {n}
            </Box>
            <Typography sx={{
                fontFamily: scssVar.fontFamily,
                color: isDanger ? scssVar.danger : "black",
                fontSize: { xs: 22, sm: 25 },
            }}>
                {children}
            </Typography>
        </Box>
    )
} 

export default SectionTitle