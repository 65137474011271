import { useSelector } from "react-redux"
import useAuth from "../../../auth/hooks/useAuth"
import { phoneWithSpaces } from "../../../../common/utils"

const Footer = () => {
    const { user } = useAuth()
    const userColor = useSelector(state => state.account.org.color)
    const orgName = useSelector(state => state.account.org.name)
    const { firstName, lastName, email, phone } = user

    return (
        <div style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            background: userColor,
            width: "100%",
            height: 92,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
            padding: "0 10px"
        }}>
            <p style={{
                marginTop: 15,
                textTransform: "uppercase",
                fontWeight: 700,
                fontSize: 18,
            }}>
                {orgName}
            </p>
            <p style={{
                fontWeight: 500,
                fontSize: 17
            }}>
                <span style={{ whiteSpace: "nowrap" }}>
                    {lastName} {firstName}
                </span>
                <span style={{ whiteSpace: "nowrap" }}>
                    {" "}• {email}
                </span>  
                {phone && (
                    <span style={{ whiteSpace: "nowrap" }}>
                        {" "}• {phoneWithSpaces(phone)}
                    </span>
                )}
            </p>
        </div>
    )
}

export default Footer