import { Box, SwipeableDrawer, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { appName, device, faviconUrl, isInIframe, isPwaInstalled } from "../../common/utils"
import iosInstallPwaGif from "../../common/assets/ios_install_pwa.gif"
import { scssVar } from "../../app/scssVar"
import Sab from "../../common/components/Sab"
import Button from "../../common/components/Button"

const InstallIOS = () => {
    const [open, setOpen] = useState(false)
    const timeout = useRef(null)

    useEffect(() => {
        if (
            device === "iOS" 
            && !isInIframe
            && !isPwaInstalled
            && !localStorage.getItem("ios_install_response")
        ) {
            timeout.current = setTimeout(() => { 
                setOpen(true) 
            }, [1000])
        } else {
            setOpen(false)
        }
    }, [])

    useEffect(() => {
        return () => {
            clearTimeout(timeout.current)
        }
    }, [])

    const handleClose = () => {
        setOpen(false)
        localStorage.setItem("ios_install_response", "closed")
    }

    return (
        <SwipeableDrawer
            open={open}
            anchor="bottom"
            onClose={handleClose}
            onOpen={() => {}}
            disableSwipeToOpen
            sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiPaper-root": {
                    backgroundColor: "white",
                    backgroundImage: "none",
                    width: "99vw",
                    maxWidth: 1000,
                    margin: "0 auto",
                    zIndex: 1301,
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                }
            }}
        >
            <div 
                role="presentation" 
                style={{
                    width: "100%",
                }}
            >
                <Box sx={{
                    p: "5px 10px"
                }}>
                     <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: { xs: 18, sm: 24 },
                            fontFamily: scssVar.fontFamily,
                        }}>
                            {appName}
                        </Typography>
                        <img 
                            src={faviconUrl} 
                            alt="icône"
                            style={{
                                width: 50,
                                height: 50,
                            }}
                        />
                    </Box>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        fontSize: "12px"
                    }}>
                        Installez {appName} en 5 secondes. Ajoutez une icône sur l'écran d'accueil pour accéder facilement à l'application.
                    </Typography>
                    <Box sx={{
                        width: "80%",
                        margin: "4px auto 0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <img 
                            src={iosInstallPwaGif}
                            alt="install guide"
                            style={{
                                width: "100%",
                            }}
                        />
                        <Button
                            variant="light"
                            onClick={handleClose}
                            sx={{
                                mt: "10px",
                                mb: "10px",
                            }}
                        >
                            Fermer
                        </Button>
                    </Box>
                </Box>
                <Sab />
            </div>
        </SwipeableDrawer>
    )
}

export default InstallIOS