import { Box, Dialog, DialogActions, DialogContent, IconButton, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import TooltipStyled from "../../../common/components/TooltipStyled"
import { ReactComponent as PdfExportIcon } from '../../../common/assets/pdf-export.svg';
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import useOrgBlob from "../../photoUploader/hooks/useOrgBlob"
import AdPortrait from "../../pdfCreator/ads/pages/AdPortrait"
import AdLandscape from "../../pdfCreator/ads/pages/AdLandscape"
import LoadingMask from "../../pdfCreator/components/LoadingMask"
import MissingFieldAlertText from "../../../common/components/MissingFieldAlertText"
import Button from "../../../common/components/Button"
import useGalleryBlobs from "../../photoUploader/hooks/useGalleryBlobs"

const AdsPdf = () => {
    const working = useSelector(state => state.estimate.working)
    const loadingEstimations = useSelector(state => state.estimate.estimations.loading)
    const description = useSelector(state => state.estimate.estimation.description_estimation)
    const adReference = useSelector(state => state.estimate.housing.ad_reference_id)
    const orgBlob = useOrgBlob()
    const galleryBlobs = useGalleryBlobs()
    const [loading, setLoading] = useState(false)
    const [openErrorDesc, setOpenErrorDesc] = useState(false)
    const [scale, setScale] = useState({
        portrait: .5,
        landscape: .3,
    })

    const currentPortraitScale = loading ? 1.0 : scale.portrait
    const currentLandscapeScale = loading ? 1.0 : scale.landscape
    const emptyDescription = description === "" || description === null
    const loadingGalleryBlobs = galleryBlobs.filter(d => d.loading === true).length > 0

    const handleExportPortrait = () => {
        const pdf = new jsPDF("p", "mm", "a4", true)

        async function generatePage(html, addPage) {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true // Pour la map leaflet
            })
                .then(canvas => {
                    const img = canvas.toDataURL("img/png", 1.0)

                    const imgProps = pdf.getImageProperties(img)
                    const pdfWidth = pdf.internal.pageSize.getWidth()
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width + 3
        
                    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST")
        
                    if (addPage) {
                        pdf.addPage()
                    }
                })
        }

        setLoading(true)

        setTimeout(async () => {
            const page1 = document.getElementById("ad_portrait")
    
            await generatePage(page1, false)

            setLoading(false)
    
            return pdf.save("annonce_" + adReference + "_verticale")
        }, [1500])
    }

    const handleCloseErrorDesc = () => {
        setOpenErrorDesc(false)
    }

    const handleExportLandscape = () => {
        const pdf = new jsPDF("l", "mm", "a4", true)

        async function generatePage(html, addPage) {
            // Fix bug iPad ?
            // https://github.com/niklasvh/html2canvas/issues/838#issuecomment-289195025
            html.style.fontFeatureSettings = '"liga" 0' 
            
            return html2canvas(html, {
                allowTaint: true, // Pour la map leaflet
                useCORS: true // Pour la map leaflet
            })
                .then(canvas => {
                    const img = canvas.toDataURL("img/png", 1.0)
                    
                    const imgProps = pdf.getImageProperties(img)
                    const pdfWidth = pdf.internal.pageSize.getWidth()
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width - 3
        
                    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST")
        
                    if (addPage) {
                        pdf.addPage()
                    }
                })
        }

        setLoading(true)

        setTimeout(async () => {
            const page1 = document.getElementById("ad_landscape")
    
            await generatePage(page1, false)

            setLoading(false)
    
            return pdf.save("annonce_" + adReference + "_horizontale")
        }, [1500])
    }

    useEffect(() => {
        if (window.innerWidth >= 1200) {
            setScale(s => ({
                ...s,
                portrait: 0.5,
                landscape: 0.4
            }))
        }

        if (window.innerWidth < 1200) {
            setScale(s => ({
                ...s,
                portrait: 0.7,
                landscape: 0.55
            }))
        }

        if (window.innerWidth < 900) {
            setScale(s => ({
                ...s,
                portrait: 0.47,
                landscape: 0.35
            }))
        }

        if (window.innerWidth < 600) {
            setScale(s => ({
                ...s,
                portrait: 0.32,
                landscape: 0.23
            }))
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1200) {
                setScale(s => ({
                    ...s,
                    portrait: 0.5,
                    landscape: 0.4
                }))
            }
    
            if (window.innerWidth < 1200) {
                setScale(s => ({
                    ...s,
                    portrait: 0.7,
                    landscape: 0.55
                }))
            }
    
            if (window.innerWidth < 900) {
                setScale(s => ({
                    ...s,
                    portrait: 0.47,
                    landscape: 0.35
                }))
            }
    
            if (window.innerWidth < 600) {
                setScale(s => ({
                    ...s,
                    portrait: 0.32,
                    landscape: 0.23
                }))
            }
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <Fragment>
            <LoadingMask loading={loading} />
            <Box sx={{ 
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: "center",
                bgcolor: scssVar.greyLighter,
                pt: 3,
                pb: 5,
                pl: { xs: 2, lg: 0 },
                pr: { xs: 2, lg: 0 },
                borderRadius: "8px"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRight: { xs: 0, lg: 1 },
                    borderBottom: { xs: 1, lg: 0 },
                    borderColor: { xs: scssVar.greyLight, lg: scssVar.greyLight },
                    pr: { xs: 0, lg: 2 },
                    pb: 7
                }}>
                    <TooltipStyled arrow title="Exporter vers PDF">
                        <span style={{ marginRight: 16 }}>
                            <IconButton
                                onClick={() => {
                                    if (emptyDescription) {
                                        setOpenErrorDesc(true)

                                        return
                                    }

                                    handleExportPortrait()
                                }}
                                disabled={
                                    loading
                                    || working 
                                    || loadingEstimations 
                                    || orgBlob.loading 
                                    || loadingGalleryBlobs
                                }
                                sx={{
                                    bgcolor: scssVar.greyLight,
                                    color: scssVar.primary,
                                }}
                            >
                                <PdfExportIcon />
                            </IconButton>
                        </span>
                    </TooltipStyled>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        mb: 2
                    }}>
                        PDF format vertical
                    </Typography>
                    <AdPortrait scale={currentPortraitScale} />
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    pl: { xs: 0, lg: 2 },
                    pt: { xs: 5, lg: 0 },
                }}>
                    <TooltipStyled arrow title="Exporter vers PDF">
                        <span style={{ marginRight: 16 }}>
                            <IconButton
                                onClick={() => {
                                    if (emptyDescription) {
                                        setOpenErrorDesc(true)

                                        return
                                    }

                                    handleExportLandscape()
                                }}
                                disabled={
                                    loading
                                    || working 
                                    || loadingEstimations 
                                    || orgBlob.loading 
                                    || loadingGalleryBlobs
                                }
                                sx={{
                                    bgcolor: scssVar.greyLight,
                                    color: scssVar.primary,
                                }}
                            >
                                <PdfExportIcon />
                            </IconButton>
                        </span>
                    </TooltipStyled>
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: "black",
                        mb: 2
                    }}>
                        PDF format horizontal
                    </Typography>
                    <AdLandscape scale={currentLandscapeScale} />
                </Box>
            </Box>
            <Dialog
                open={openErrorDesc}
                onClose={handleCloseErrorDesc}
                fullWidth
            >
                <DialogContent sx={{ pb: 0 }}>
                    <MissingFieldAlertText visible>
                        Votre annonce n'est pas finalisée ! Veuillez renseigner une <u>description</u> pour ce bien (onglet "Fiche du bien")
                    </MissingFieldAlertText>
                </DialogContent>
                <DialogActions>
                    <Button variant="light" onClick={handleCloseErrorDesc}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default AdsPdf