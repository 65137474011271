import { Badge, BottomNavigation, BottomNavigationAction } from "@mui/material"
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"
import { scssVar } from "../../../app/scssVar";
import { styled } from '@mui/material/styles';
import { selectIsUpdateAvailable } from "../../../features/appUpdate/appUpdateSlice";
import useRoutes from "../../hooks/useRoutes";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: 4,
        padding: '0 4px',
        background: scssVar.danger,
        border: `1px solid white`,
        color: "white"
    },
}))

const NavMobile = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const isUpdateAvailable = useSelector(selectIsUpdateAvailable)
    const routes = useRoutes()

    return (
        <Box sx={{ 
            display: { xs: "block", sm: "none" },
            zIndex: 1001,
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            bgcolor: "white",
            boxShadow: "0px 2px 8px rgba(0, 39, 51, 0.2)",
            WebkitBoxShadow: "0px 2px 8px rgba(0, 39, 51, 0.2)",
        }}>
            <BottomNavigation
                showLabels
                sx={{
                    bgcolor: "transparent",
                    height: 66,
                    p: "4px 20px 0", // correspond à PageWrapper
                    "& .MuiBottomNavigationAction-root": {
                        borderRadius: "6px",
                        height: 50,
                        maxWidth: "25%",
                        minWidth: "25%",
                        width: "25%",
                        "& i": { // icon
                            fontSize: 24
                        },
                    },
                    "& .MuiBottomNavigationAction-label": {
                        fontSize: 8,
                        fontFamily: scssVar.fontFamily,
                        mt: "3px"
                    }
                }}
            >
                {routes.map((d, i) => 
                    <BottomNavigationAction 
                        key={i}
                        disableRipple
                        disableTouchRipple
                        label={d.shortLabel || d.label}
                        onClick={() => navigate(d.to)}
                        icon={<i className={d.icon}></i>}
                        sx={{
                            color: d.isActive ? scssVar.primary : scssVar.greyDark,
                            background: d.isActive ? scssVar.primaryLighter : "none",
                        }}
                    />
                )}
                <BottomNavigationAction 
                    disableRipple
                    disableTouchRipple
                    label="Compte"
                    onClick={() => navigate("/mon-compte")}
                    icon={
                        <StyledBadge badgeContent={isUpdateAvailable ? "1" : null}>
                            <i className="iconoir-user"></i>
                        </StyledBadge>
                    }
                    sx={{
                        color: pathname.includes("/mon-compte") ? scssVar.primary : scssVar.greyDark,
                        background: pathname.includes("/mon-compte") ? scssVar.primaryLighter : "none",
                    }}
                />
            </BottomNavigation>
            <Box sx={{ height: scssVar.sab }} />
        </Box>
    )
}

export default NavMobile