import { Box, Grid, IconButton, Typography } from "@mui/material"
import { Fragment, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateEstimation } from "../../actions"
import CostInfo from "./CostInfo"
import StepTitle from "./StepTitle"
import DialogEnergyCostsEditor from "./DialogEnergyCostsEditor"
import { periodicityChoices } from "./Step11Form"
import FieldControl from "../../../../common/components/FieldControl"
import { scssVar } from "../../../../app/scssVar"
import Button from "../../../../common/components/Button"

const initialState = {
    open: false,
    showInfos: false
}

const Step12Form = ({ stepName, noProgressBarDisplay }) => {
    const timeout = useRef(null)
    const dispatch = useDispatch()
    const estimation = useSelector(state => state.estimate.estimation)
    const periodicity = useSelector(state => state.estimate.periodicity)
    const working = useSelector(state => state.estimate.working)
    const [state, setState] = useState(initialState)

    const onUpdateEstimation = () => {
        timeout.current = setTimeout(() => {
            dispatch(updateEstimation(stepName))
        }, [1000])
    }

    const toggleInfos = () => {
        setState({ ...state, showInfos: !state.showInfos })
    }

    const handleChangePeriodicity = (name, newValue) => {
        dispatch({ type: "estimate/set_periodicity", payload: newValue })
    }

    const handleChange = (e) => {
        clearTimeout(timeout.current)

        const target = e.target
        const name = target.name
        const value = target.value

        dispatch({
            type: "estimate/set_estimation_costs_value",
            payload: { name, value: Number(value) }
        })

        onUpdateEstimation()
    }

    const handleOpen = () => {
        setState({ ...state, open: true })
    }

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    return (
        <Fragment>
            <StepTitle
                title={
                    <span style={{ display: "flex", alignItems: "center" }}>
                        Dépassez la notion du prix au m²
                        <IconButton onClick={toggleInfos}>
                            <i className="iconoir-info-empty" style={{ fontSize: 20, color: scssVar.black }}></i>
                        </IconButton>
                    </span>
                }
                subTitle="Appréciez le budget d’usage via l’impact des charges"
                step={stepName} 
                isCostDisplay
                noProgressBarDisplay={noProgressBarDisplay}
            />
            <div style={{ marginBottom: 50 }} />
            <CostInfo show={state.showInfos} onClose={toggleInfos} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FieldControl
                        label="Périodicité"
                        type="checkbox"
                        disallowDeselect
                        choices={periodicityChoices}
                        selectedValue={periodicity}
                        onChange={handleChangePeriodicity}
                        xs={6}
                    />
                </Grid>
                <Grid item xs={0} md={6} sx={{ display: { xs: "none", md: "block" } }}>

                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldControl 
                        label="Eau" 
                        isSuccess={periodicity === "month" ? estimation.cout_eau_mois : estimation.cout_eau_an}
                        onChange={handleChange}
                        name={periodicity === "month" ? "cout_eau_mois" : "cout_eau_an"}
                        value={periodicity === "month" ? (estimation.cout_eau_mois || "") : (estimation.cout_eau_an || "") }
                        type="number"
                        placeholder="Montant"
                        icon={{ position: "right", icon: "fas fa-euro-sign" }}
                        disabled={working}
                    />
                </Grid>
                <Grid item xs={0} md={6} sx={{ display: { xs: "none", md: "block" } }}>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        display:"flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        minHeight: "75px"
                    }}>
                        <label className="label has-text-weight-normal" style={{
                            fontSize: 14
                        }}>
                            Coût énergétique global
                        </label>
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            bgcolor: scssVar.greyLighter,
                            borderRadius: "8px",
                            paddingLeft: "25px",
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                color: "black",
                                fontWeight: 600
                            }}>
                                {periodicity === "month" && (
                                    estimation.total_cout_energetique_mois
                                )}
                                {periodicity === "year" && (
                                    estimation.total_cout_energetique_an
                                )}
                                €
                            </Typography>
                        </Box>
                    </Box>
                    {/* <FieldControl 
                        label="Coût énergétique global" 
                        isSuccess={periodicity === "month" ? estimation.total_cout_energetique_mois : estimation.total_cout_energetique_an}
                        onChange={handleChange}
                        name={periodicity === "month" ? "total_cout_energetique_mois" : "total_cout_energetique_an"}
                        value={periodicity === "month" ? (estimation.total_cout_energetique_mois || "") : (estimation.total_cout_energetique_an || "") }
                        type="number"
                        placeholder="Montant"
                        icon={{ position: "right", icon: "fas fa-euro-sign" }}
                        disabled={working}
                    /> */}
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ display: "flex", alignItems: "flex-end", height: "100%", width: "100%" }}>
                        <Button
                            onClick={handleOpen}
                            variant="primary-light"
                            sx={{
                                width: "100%",
                                "@media (max-width: 1000px)": {
                                    fontSize: 14
                                }
                            }}
                        >
                            Afficher le détail des énergies
                        </Button>
                        <DialogEnergyCostsEditor 
                            open={state.open} 
                            onClose={handleClose} 
                            onChange={handleChange}
                        />
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Step12Form