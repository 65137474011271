import { Box, Collapse, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import scssVar from "../../../app/App.scss"
import LoaderGif from "../../../common/components/LoaderGif"

const AccountSection = ({ 
    title,
    children,
    loading,
    error,
    isNotif
}) => {
    const [collapsed, setCollapsed] = useState(false)

    const toggleCollapse = () => {
        setCollapsed(!collapsed)
    }

    return (
        <Fragment>
            <ListItemButton
                onClick={toggleCollapse}
                component="div"
                disableGutters
            >
                <ListItemText 
                    primary={title} 
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black",
                            fontSize: { xs: "25px", sm: "32px"},
                            fontWeight: 700,
                        },
                    }}
                />
                {isNotif && (
                    <Box sx={{
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "24px",
                        bgcolor: scssVar.danger,
                        color: "white",
                        fontWeight: 600,
                        fontSize: "14px",
                        mr: 1
                    }}>
                        1
                    </Box>
                )}
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.primary,
                        transform: `rotate(${collapsed ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </ListItemButton>
            <Collapse in={collapsed} timeout="auto" unmountOnExit>
                {loading ? (
                    <LoaderGif />
                ): error ? (
                    <Typography sx={{
                        fontSize: "13px",
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.danger
                    }}>
                        {error}
                    </Typography>
                ): children}
            </Collapse>
        </Fragment>
    )
}

export default AccountSection