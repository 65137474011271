import { Dialog, DialogContent, IconButton } from "@mui/material"
import useWindowSize from "../hooks/useWindowSize"

const DialogDisplayPhoto = ({ 
    open, 
    onClose,
    blob
}) => {
    const { windowWidth, windowHeight } = useWindowSize()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    bgcolor: "rgba(0, 0, 0, 0.85)"
                },
                "& .MuiPaper-root": {
                    borderRadius: 0,
                    m: 0,
                    maxWidth: "100%",
                    maxHeight: "100%",
                    bgcolor: "rgba(0, 0, 0, 0)",
                    boxShadow: "none",
                    WebkitBoxShadow: "none",
                }
            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    position: "fixed",
                    top: 0,
                    right: 0
                }}
            >
                <i
                    className="iconoir-cancel"
                    style={{
                        color: "white",
                        fontSize: 30
                    }}
                >
                </i>
            </IconButton>
            <DialogContent sx={{ 
                p: 0, 
                overflow: "hidden", 
            }}>
                <img
                    src={blob}
                    alt="display"
                    style={{
                        height: "auto",
                        maxHeight: windowHeight,
                        width: "auto",
                        maxWidth: windowWidth,
                    }}
                />
            </DialogContent>
        </Dialog>
    )
}

export default DialogDisplayPhoto