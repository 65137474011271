import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { scssVar } from '../../app/scssVar';

const primary = {
    500: scssVar.primary,
    600: "#5a3299",
    700: "#2b1749",
};

const PrimaryButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: ${primary[500]};
    padding: 0 28px;
    height: 40px;
    border-radius: 40px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: ${primary[600]};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${primary[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;


const success = {
    500: scssVar.success,
    600: "#4ca458",
    700: "#3c8846",
}

const SuccessButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: ${success[500]};
    padding: 0 24px;
    height: 40px;
    border-radius: 40px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: ${success[600]};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${success[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const light = {
    500: scssVar.greyLighter,
    600: "#f4f2f7",
    700: "#efebf5" 
}

const LightButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: ${light[500]};
    padding: 0 24px;
    height: 40px;
    border-radius: 40px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: ${light[600]};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${light[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const primaryLight = {
    500: scssVar.primaryLight,
    600: "#e9e2f5",
    700: "#e2d1fc"
}

const PrimaryLightButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: ${primaryLight[500]};
    padding: 0 24px;
    height: 40px;
    border-radius: 40px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: ${primaryLight[600]};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${primaryLight[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const danger = {
    500: scssVar.danger,
    600: "#fc0029",
    700: "#e50025"
}

const DangerButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: ${danger[500]};
    padding: 0 24px;
    height: 40px;
    border-radius: 40px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: ${danger[600]};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${danger[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const TextButton = styled(ButtonUnstyled)`
    font-family: ${scssVar.fontFamily};
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    padding: 0;
    color: ${primary[500]};
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        color: ${primary[600]};
    }

    &.${buttonUnstyledClasses.active} {
        color: ${primary[700]};
    }

    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
        outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export default function Button({
    variant, 
    loading,
    id,
    type,
    sx,
    onClick,
    children,
    disabled,
    style,
    noShadow,
    component,
}) {
    const props = {
        id,
        type,
        onClick,
        disabled,
        component,
        style: Object.assign({}, style),
    }

    const ColoredCircularProgress = ({ color }) => (
        <CircularProgress 
            size={17} 
            sx={{
                color,
                position: "absolute",
                display: loading ? "block" : "none",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto",
            }}
        /> 
    )

    switch (variant) {
        default: case "primary": {
            const primaryProps = {
                ...props,
                sx: Object.assign({}, {
                    position: "relative",
                    color: loading ? "transparent" : "white",
                    boxShadow: noShadow ? "unset" : scssVar.shadow2,
                    WebkitBoxShadow: noShadow ? "unset" : scssVar.shadow2,
                }, sx),
            }

            return (
                <PrimaryButton {...primaryProps}>
                    {children}
                    <ColoredCircularProgress color="white" />
                </PrimaryButton>
            )
        }

        case "success": {
            const successProps = {
                ...props,
                sx: Object.assign({}, {
                    position: "relative",
                    color: loading ? "transparent" : "white",
                    boxShadow: noShadow ? "unset" : scssVar.shadow2,
                    WebkitBoxShadow: noShadow ? "unset" : scssVar.shadow2,
                }, sx),
            }

            return (
                <SuccessButton {...successProps}>
                    {children}
                    <ColoredCircularProgress color="white" />
                </SuccessButton>
            )
        }

        case "danger":
            const dangerProps = {
                ...props,
                sx: Object.assign({}, {
                    position: "relative",
                    color: loading ? "transparent" : "white",
                    boxShadow: noShadow ? "unset" : scssVar.shadow2,
                    WebkitBoxShadow: noShadow ? "unset" : scssVar.shadow2,
                }, sx),
            }

            return (
                <DangerButton {...dangerProps}>
                    {children}
                    <ColoredCircularProgress color="white" />
                </DangerButton>
            )

        case "light": {
            const lightProps = {
                ...props,
                sx: Object.assign({}, {
                    position: "relative",
                    color: loading ? "transparent" : scssVar.primary,
                }, sx),
            }

            return (
                <LightButton {...lightProps}>
                    {children}
                    <ColoredCircularProgress color={scssVar.primary} />
                </LightButton>
            )
        }

        case "primary-light":
            const primaryLightProps = {
                ...props,
                sx: Object.assign({}, {
                    position: "relative",
                    color: loading ? "transparent" : scssVar.primary,
                }, sx),
            }

            return (
                <PrimaryLightButton {...primaryLightProps}>
                    {children}
                    <ColoredCircularProgress color={scssVar.primary} />
                </PrimaryLightButton>
            )

        case "text":
            const textProps = {
                ...props,
                sx: Object.assign({}, {
                    position: "relative",
                    color: loading ? "transparent" : scssVar.primary,
                }, sx),
            }

            return (
                <TextButton {...textProps}>
                    {children}
                    <ColoredCircularProgress color={scssVar.primary} />
                </TextButton>
            )
    }
}