import { useDispatch, useSelector } from "react-redux"
import Alert from '@mui/material/Alert';
import scssVar from "../../app/App.scss"
import { clearAlert } from "./alertsSlice";
import { defaultErrorMessage } from "../../common/utils";
import ErrorBoundary from "../../common/components/ErrorBoundary";

const AlertItem = ({ alert }) => {
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(clearAlert(alert.id))
    }

    return (
        <Alert
            severity={alert.level}
            onClose={handleClose}
            sx={{ 
                mb: 1,
                "& .MuiAlert-message": {
                    fontFamily: scssVar.fontFamily
                }
            }}
        >
            {alert.text}
        </Alert>
    )
}

const Alerts = () => {
    const alerts = useSelector(state => state.alerts)

    // const alerts = [
    //     {level:"success",text:"Your session has been added", id: 1},
    //     {level:"error",text:"Your session has been added", id: 2},
    //     {level:"warning",text:"Your session has been added", id: 3},
    // ]
    
    if (alerts.length) {
        return (
            <div style={{
                zIndex: 3000,
                width: 340,
                maxWidth: "98%",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: "50px",
                margin: "0 auto",
            }}>
                {alerts.map((d, i) => 
                    <ErrorBoundary key={i} C={<AlertItem alert={{ ...d, text: defaultErrorMessage }} />}>
                        <AlertItem alert={d} />
                    </ErrorBoundary>
                )}
            </div>
        )
    }

    // return null ?
    return <div style={{ display: "none" }} id="alerts" />
}

export default Alerts