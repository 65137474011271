import { Box, Collapse, ListItemButton, ListItemText } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { renameTeam } from "../actions"
import FieldControl from "../../../common/components/FieldControl"
import { scssVar } from "../../../app/scssVar"
import Button from "../../../common/components/Button"

const initialState = {
    collapsed: false,
    newTeamName: ""
}

const TeamName = ({ team }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const teamId = team.team_id
    const teamName = team.team_name
    const loading = team.renameLoading || false
    const notModified = state.newTeamName === teamName
    const emptyField = !state.newTeamName.trim().length

    useEffect(() => {
        setState(s => ({ ...s, newTeamName: teamName }))
    }, [teamName])

    const handleCollapse = () => {
        setState({ ...state, collapsed: !state.collapsed })
    }

    const handleChange = e => {
        const { value } = e.target
        setState({
            ...state,
            newTeamName: value
        })
    }

    const handleCancel = () => {
        setState({ ...state, newTeamName: teamName })
    }

    const handleSubmit = e => {
        e.preventDefault()

        dispatch(renameTeam(teamId, state.newTeamName))
    }

    return (
        <Fragment>
            <ListItemButton
                component="div"
                onClick={handleCollapse}
                sx={{
                    pl: 0,
                    pr: 0
                }}
            >
                <ListItemText
                    primary="Renommer"
                    sx={{
                        "& .MuiTypography-root": {
                            fontFamily: scssVar.fontFamily
                        },
                        "& .MuiListItemText-primary": {
                            color: "black",
                            fontWeight: 700
                        },
                    }}
                />
                <i 
                    className="iconoir-nav-arrow-down"
                    style={{
                        fontSize: 24,
                        color: scssVar.greyDark,
                        transform: `rotate(${state.collapsed ? "180" : "0"}deg)`,
                        transition: "transform .1s"
                    }}
                ></i>
            </ListItemButton>
            <Collapse in={state.collapsed} timeout="auto" unmountOnExit>
                <Box 
                    sx={{ 
                        flexGrow: 1, 
                    }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <FieldControl
                        placeholder="Nom de l'agence"
                        isSuccess={state.newTeamName}
                        onChange={handleChange}
                        value={state.newTeamName || ""}
                        type="text"
                        required
                    />
                    <Box sx={{
                        mt: "35px",
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" }
                    }}>
                        <Button 
                            variant="light"
                            onClick={handleCancel}
                            type="button"
                            disabled={notModified}
                            sx={{
                                pl: 6,
                                pr: 6,
                            }}
                        >
                            Annuler
                        </Button>
                        <Box sx={{ m: 1 }} />
                        <Button 
                            loading={loading}
                            disabled={notModified || emptyField || loading}
                            sx={{
                                pl: 6,
                                pr: 6,
                            }}
                        >
                            Enregistrer
                        </Button>
                    </Box>
                </Box>
            </Collapse>
        </Fragment>
    )
}

export default TeamName