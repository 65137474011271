import { useSelector } from "react-redux"

const useChargesAnnuellesItems = () => {
    const estimation = useSelector(state => state.estimate.estimation)
    
    return [
        { 
            label: "Charge co-propriété", 
            value: estimation.charge_copro_an, 
            unit: "€", 
            nd: "Non définie",
            displayInPdf: true
        },
        { 
            label: "Chauffage compris dans les charges de copropriété", 
            value: estimation.charge_copro_chauffage ? "Oui" : "Non", 
            unit: null, nd: "Non défini",
            displayInPdf: false
        },
        { 
            label: "Travaux entretien", 
            value: estimation.travaux_entretien_an, 
            unit: "€", 
            nd: "Non définis",
            displayInPdf: true
        },
        { 
            label: "Taxe foncière", 
            value: estimation.taxe_fonciere_an, 
            unit: "€", 
            nd: "Non définie",
            displayInPdf: true
        },
    ]
}

export default useChargesAnnuellesItems