import { Box } from "@mui/material"

const WrapperStep = ({ 
    children, 
    form, 
    onGoNext
}) => {
    if (form) {
        return (
            <form
                method="POST"
                onSubmit={e => {
                    e.preventDefault()
                    onGoNext()
                }}
                style={{
                    minHeight: "100%",
                    backgroundColor: "white",
                    padding: "80px 0"
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "90%",
                    margin: "0 auto",
                }}>
                    {children}
                </Box>
            </form>
        )
    }

    return (
        <Box sx={{ 
            minHeight: "100%",
            bgcolor: "white",
            p: "80px 0"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "90%",
                margin: "0 auto",
            }}>
                {children}
            </Box>
        </Box>
    )
}

export default WrapperStep