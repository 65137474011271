import { useDispatch, useSelector } from "react-redux"
import { mandatActions, selectMandatEditor2 } from "../mandatSlice"
import useSaveMandat from "../hooks/useSaveMandat"
import InputSelect from "../../components/InputSelect"
import GreyBox from "../../components/GreyBox"

const choices = [
    { label: "Mandat EXCLUSIF", value: "exclu" },
    { label: "Mandat SEMI EXCLUSIF", value: "semi" },
    { label: "Mandat SIMPLE", value: "simple" },
]

const Exclusivity = ({ style, readOnly }) => {
    const { clearTimeout, saveMandat } = useSaveMandat()
    const dispatch = useDispatch()
    const value = useSelector(selectMandatEditor2).mandat_type_exclusivite

    const handleChange = (e) => {
        const { name, value } = e.target
        clearTimeout()
        dispatch(mandatActions.setField({ name, value }))
        saveMandat(0)
    }

    if (readOnly) {
        return (
            <GreyBox style={{
                width: 260,
                height: 30,
                fontSize: 18,
                padding: "0 4px",
                display: "flex",
                alignItems: "center"
            }}>
                {choices.find(d => d.value === value).label}
            </GreyBox>
        )
    }

    return (
        <InputSelect
            name="mandat_type_exclusivite"
            value={value || ""}
            onChange={handleChange}
            style={Object.assign({}, {
                width: 260,
                height: 30,
                fontSize: 18
            }, style)}
        >
            {choices.map((c, i) => 
                <option key={i} value={c.value}>
                    {c.label}
                </option>
            )}
        </InputSelect>
    )
}

export default Exclusivity