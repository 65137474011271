import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { selectApiKpis, selectApiKpisAttributionLeads, selectApiKpisHouseType } from "../../apiOfferSlice"
import { scssVar } from "../../../../app/scssVar"
import StatBox from "../../../../common/components/dashboard/StatBox"
import ProgressBar1 from "../../../../common/components/dashboard/progress/ProgressBar1"

const LeadsCount = () => {
    const { attributed, unattributed } = useSelector(selectApiKpisAttributionLeads)
    const { houses, flats } = useSelector(selectApiKpisHouseType)
    const { isLoading } = useSelector(selectApiKpis)
    const totalLeads = attributed + unattributed
    const totalUsers = houses + flats
    const percentLeads = Math.round(totalLeads / totalUsers * 100) || 0

    return (
        <StatBox 
            bgcolor={scssVar.greyLighter}
            icon="iconoir-star-outline" 
            title={`${totalLeads} lead${totalLeads > 1 ? "s" : ""} (${percentLeads}%)`}
            isLoading={isLoading}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ mt: "5px" }} />  
                <ProgressBar1 p={percentLeads} />
                <Box sx={{ mb: "8px" }} />
                <p style={{ fontSize: 14, color: scssVar.greyDark, lineHeight: "17px" }}>
                    Sur un total de {totalUsers} requête{totalUsers > 1 && "s"}
                </p>
            </Box>
        </StatBox>
    )
}

export default LeadsCount