import { Box, Grid, Typography } from "@mui/material"
import UserForm from "./components/UserForm"
import WrapperRegisterForm from "./components/WrapperRegisterForm"
import illustration from "../../common/assets/Group39.png"
import { scssVar } from "../../app/scssVar"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { registerUserActions } from "./registerUserSlice"

const RegisterUser = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(registerUserActions.reset())
        }
    }, [dispatch])

    return (
        <WrapperRegisterForm>
            <Typography sx={{ 
                fontSize: { xs: "25px", md: "36px" }, 
                fontWeight: 700, 
                color: "black",
                fontFamily: scssVar.fontFamily,
                mb: 4
            }}>
                Créer un compte
            </Typography>
            <Box sx={{
                width: "100%",
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7} lg={7}>
                        <UserForm />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} sx={{
                        display: { xs: "none", md: "flex" },
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}>
                        <img
                            src={illustration}
                            alt="register illustration"
                            style={{
                                width: "90%",
                                maxWidth: "390px"
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </WrapperRegisterForm>
    )
}

export default RegisterUser