import { Fragment } from "react"
import useAuth from "../../../auth/hooks/useAuth"
import GreyBox from "../../components/GreyBox"

const MandataireName = () => {
    const { firstName, lastName } = useAuth().user

    return (
        <Fragment>
            <p style={{
                fontWeight: 600,
                marginRight: 3,
                fontSize: 14,
                whiteSpace: "nowrap"
            }}>
                Mandataire : 
            </p>
            <GreyBox style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 172,
                whiteSpace: "nowrap",
                padding: "0 2px",
                marginRight: 3
            }}>
                {lastName} {firstName}
            </GreyBox>
        </Fragment>
    )
}

export default MandataireName