import { IconButton } from "@mui/material"
import { useSelector } from "react-redux"
import { scssVar } from "../../../../app/scssVar"
import TooltipStyled from "../../../../common/components/TooltipStyled"
import { ReactComponent as PdfExportIcon } from '../../../../common/assets/pdf-export.svg';
import { selectMandatSaving } from "../mandatSlice";
import useOrgBlob from "../../../photoUploader/hooks/useOrgBlob";
import useMandatBlobs from "../../../photoUploader/hooks/useMandatBlobs";

const ButtonDownload = ({ disabled, onClick }) => {
    const working = useSelector(state => state.estimate.working)
    const loadingEstimations = useSelector(state => state.estimate.estimations.loading)
    const saving = useSelector(selectMandatSaving)
    const orgBlob = useOrgBlob()
    const mandatBlobs = useMandatBlobs()

    const isLoadingMandatBlobs = mandatBlobs.filter(d => d.loading === true).length > 0

    return (
        <TooltipStyled arrow title="Exporter vers PDF">
            <span>
                <IconButton
                    type="submit"
                    onClick={onClick}
                    disabled={
                        disabled
                        || working 
                        || loadingEstimations 
                        || saving
                        || orgBlob.loading
                        || isLoadingMandatBlobs
                    }
                    sx={{
                        bgcolor: scssVar.greyLight,
                        color: scssVar.primary,
                    }}
                >
                    <PdfExportIcon />
                </IconButton>
            </span>
        </TooltipStyled>
    )
}

export default ButtonDownload